/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertsTab.js
 * @author Prakash // on 05/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listObservabilityAlertGroups } from '../../../actions/aiops/ObservabilityAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import Search from '../../common/SearchComponent'

class AlertsTab extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            alertListLoader: true,
            
            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,
        }
    }

	componentDidMount = () => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            this.setState({ 
                start_time: this.props.leftSectionSelection.start_time,
                end_time: this.props.leftSectionSelection.end_time,
                brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                selectedShowBy: this.props.leftSectionSelection.selectedShowBy ? this.props.leftSectionSelection.selectedShowBy : "",
                columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                selectedField: this.props.leftSectionSelection.selectedField ? this.props.leftSectionSelection.selectedField : "",
                allFields: this.props.leftSectionSelection.allFields ? this.props.leftSectionSelection.allFields : [],
                selectedGroup: this.props.leftSectionSelection.selectedGroup ? this.props.leftSectionSelection.selectedGroup : "",
                selectedSeverity: this.props.leftSectionSelection.selectedGroup && this.props.leftSectionSelection.selectedGroup ? "Critical/High" : "",
                pageType: this.props.leftSectionSelection.pageType ? this.props.leftSectionSelection.pageType : "",
                alertListLoader: true,
            },
                () => {
                    this.listObservabilityAlertGroups()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.leftSectionSelection && Object.entries(this.props.leftSectionSelection).length) {
            if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
                this.setState({ 
                    start_time: this.props.leftSectionSelection.start_time,
                    end_time: this.props.leftSectionSelection.end_time,
                    brushStartTime: this.props.leftSectionSelection.brushStartTime ? this.props.leftSectionSelection.brushStartTime : this.props.leftSectionSelection.start_time,
                    brushEndTime: this.props.leftSectionSelection.brushEndTime ? this.props.leftSectionSelection.brushEndTime : this.props.leftSectionSelection.end_time,
                    selectedShowBy: this.props.leftSectionSelection.selectedShowBy ? this.props.leftSectionSelection.selectedShowBy : "",
                    columnValue: this.props.leftSectionSelection.columnValue ? this.props.leftSectionSelection.columnValue : "",
                    selectedField: this.props.leftSectionSelection.selectedField ? this.props.leftSectionSelection.selectedField : "",
                    allFields: this.props.leftSectionSelection.allFields ? this.props.leftSectionSelection.allFields : [],
                    selectedGroup: this.props.leftSectionSelection.selectedGroup ? this.props.leftSectionSelection.selectedGroup : "",
                    selectedSeverity: this.props.leftSectionSelection.selectedGroup && this.props.leftSectionSelection.selectedGroup ? "Critical/High" : "",
                    pageType: this.props.leftSectionSelection.pageType ? this.props.leftSectionSelection.pageType : "",
                    alertListLoader: true,
                    graphData: {},
                },
                    () => {
                        this.listObservabilityAlertGroups()
                    }
                )
            } else if(this.props.dashboardDetails && Object.entries(this.props.dashboardDetails).length) {
                if(!_.isEqual(prevProps.dashboardDetails, this.props.dashboardDetails)) {
                    this.setState({ 
                        start_time: this.props.dashboardDetails.start_time,
                        end_time: this.props.dashboardDetails.end_time,
                        brushStartTime: this.props.dashboardDetails.brushStartTime ? this.props.dashboardDetails.brushStartTime : this.props.dashboardDetails.start_time,
                        brushEndTime: this.props.dashboardDetails.brushEndTime ? this.props.dashboardDetails.brushEndTime : this.props.dashboardDetails.end_time,
                        alertListLoader: true,
                    },
                        () => {
                            if(this.state.columnValue) {
                                this.listObservabilityAlertGroups()
                            }
                        }
                    )
                }
            }
        } else if(!_.isEqual(prevProps.leftSectionSelection, this.props.leftSectionSelection)) {
            this.setState({ 
                columnValue: "",
                alertListLoader: true
            },
                () => {
                    this.listObservabilityAlertGroups()
                }
            )
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listObservabilityAlertGroups = () => {
        let params = {}
        // params.alerts_case_id = '2408-1028-7602'
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:00')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:00')
        // params.duration = "2w"
        if(this.state.selectedShowBy === "Assets") {
            params.asset_id = this.state.columnValue
        } else {
            params.resource_type = this.state.columnValue
        }
        params.aggregate_by = "severity"        
        
        if(this.state.selectedField !== "") {
            params.severity  = this.state.selectedField
        } else {
            params.severity = this.state.selectedSeverity.split('/')
        }
        if(this.state.columnValue && this.state.columnValue !== "") {
            this.props.listObservabilityAlertGroups(params, (promise, response) => {
                if(promise) {
                    let data = response.results && response.results.details ? response.results.details : []
                    this.setState({ alertResponse: data, alertListLoader: false },
                        () => {
                            this.structureAlerts()
                            this.props.menuDisabled()
                        }    
                    )
                } else {
                    this.setState({ alertsList: [], alertListLoader: false })
                    this.props.menuDisabled()
                }
            })
        } else {
            this.setState({ alertsList: [], filteredArray: [], alertListLoader: false })
            this.props.menuDisabled()
        }
    }

    structureAlerts = () => {
        let listAllAlerts = []
        let totalCount = 0
        let alertsCategory = []
        if(this.state.alertResponse && this.state.alertResponse.length) {
            totalCount = this.state.alertResponse.reduce( function(a, b){ return a + b.count;}, 0);
            this.state.alertResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                alertsCategory.push(dataRow)
                item.alerts.forEach(alt => {
                    listAllAlerts.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        alertsCategory.push(dataRow)

        this.setState({ listAllAlerts, alertsList: listAllAlerts, alertsCategory: _.orderBy(alertsCategory, ['orderBy'], ['asc'])},
            () => this.getCategoryAlerts()
        )
    }

    getCategoryAlerts = () => {
        let filterdCategoryAlerts = this.state.listAllAlerts

        let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], ['desc'])

        let totalPages = 1
		if(orderedArray.length > this.state.perPage) {
			totalPages = Math.ceil(orderedArray.length / this.state.perPage)
		}
        this.setState({ totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedAlert: "", selectedAlertId: "", totalPages, startRecord: 0, currentPage: 1 },
            () => this.formHeaders()
        )
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    getAlertDetails = (alertItemDetails) => {
        this.setState({ 
            selectedAlertId: alertItemDetails.event_id === this.state.selectedAlertId ? "" : alertItemDetails.event_id, 
            selectedAlert: alertItemDetails.event_id === this.state.selectedAlertId ? "" : alertItemDetails,
            expandAlertDetails: true,
            expandAssetDetails: false
        })
    }
    
    handleChange = (event) => {
        let groupedBy = this.state.groupedBy
        if(event.target.value === "Application" && groupedBy !== "severity" && groupedBy!== "event_name") {
            groupedBy = "severity"
        }
        this.setState({ alertType: event.target.value, groupedBy, selectedAlert: "", selectedAlertId: "", alertListLoader: true },
            () => {
                this.listObservabilityAlertGroups()
            }    
        )
    }

    handleClickOutside(event) {
        // if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
        //     this.setState({ showPageTypeOptions: false })
        // } else {
        //     this.setState({ showPageTypeOptions: true })
        // }
    }

	render() {
		return (
            <div className="" onClick={(event) => this.handleClickOutside(event)}>
                <div className="d-flex mt-2">
                    <div className="rounded bg-dark p-0 col-sm-12">
                        <div className="d-flex mb-2 justify-content-between">
                            <div className="d-flex mr-2">
                                <p className="d-flex flex-wrap m-0 align-self-center pt-1">
                                    <span className={`small ${(this.state.selectedGroup === "severity" || this.state.selectedGroup === "risk") ? "mt-2": ""}`}>Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length} alerts(s) with </span>
                                    
                                    {(this.state.selectedGroup === "severity" || this.state.selectedGroup === "risk") ?
                                        this.state.selectedField && this.state.selectedField !== "" ? 
                                            <span className={`small m1-1 mt-2 d-flex align-self-center risk-${this.state.selectedField.toLowerCase()}`}>{this.state.selectedField}</span> 
                                        : 
                                            <div class={`transparentSelectBorderBottom ml-1 align-self-center`}>
                                                <select class="form-control" 
                                                onChange={e => this.setState({ selectedSeverity: e.target.value }, () => this.listObservabilityAlertGroups())}
                                                value={this.state.selectedSeverity}
                                                >
                                                    <option value="Critical/High/Medium/Low">All</option>
                                                    <option value="Critical/High">Critical/High</option>
                                                    <option value="Critical">Critical</option>
                                                    <option value="High">High</option>
                                                    <option value="Medium">Medium</option>
                                                    <option value="Low">Low</option>
                                                </select>
                                            </div>
                                            // this.state.allFields && this.state.allFields.length ?
                                            //     this.state.allFields.map((sev, i) => {
                                            //         return(
                                            //             <span className={`ml-1 risk-${sev.toLowerCase()}`}>{sev} {!i ? <span className="text-primary-color"> and</span> : "" }</span>
                                            //         )
                                            //     })
                                            // : null
                                    : 
                                        this.state.selectedField && this.state.selectedField !== "" ? 
                                            <span className={`ml-1 text-white`}>{this.state.selectedField}</span> 
                                        : null
                                    }
                                    <span className={`small ml-1 text-primary-color ${(this.state.selectedGroup === "severity" || this.state.selectedGroup === "risk") ? "mt-2": ""}`}>{this.state.selectedGroup && this.state.selectedGroup !== "" ? this.state.selectedGroup.replace("_", " ") : ""}</span> 
                                    <span className={`small align-self-center text-info ml-1 ${(this.state.selectedGroup === "severity" || this.state.selectedGroup === "risk") ? "mt-2": ""}`}>between </span>
                                    <span className={`small align-self-center text-info ml-1 ${(this.state.selectedGroup === "severity" || this.state.selectedGroup === "risk") ? "mt-2": ""}`}>{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</span>
                                </p>
                            </div>
                            <div className="d-flex">
                                <CSVLink 
                                    data={this.state.filteredArray ? this.state.filteredArray : []} 
                                    headers={this.state.headers ? this.state.headers : []}
                                    filename={"resource-alerts.csv"}
                                    className={"mt-2"}
                                    target="_blank"
                                >
                                    <span className="d-flex text-primary-color mr-2"><i className="fas fa-download text-primary-color mr-2"></i> 
                                    {/* csv */}
                                    </span>
                                </CSVLink>
                                {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                    <div className={`justify-content-end w-100 mb-n2`}>
                                        <Search
                                            data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                            searchClassName={'px-2 f12'}
                                            searchIconColor={'text-gray5 f12'}
                                            searchPlaceHolder={'Search....'}
                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                            filteredData={(filteredArray) => this.setState({ filteredArray })}
                                        />
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12">
                                                <span className="d-flex mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                {/* <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span> */}
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                : null}
                            </div>
                        </div>
                        
                        
                        <div className="d-flex">
                            <div className={`w-100`}>
                                <div className={`bg-dark3 py-3 pl-2 obsMessageSectionXL`}>
                                    {this.state.alertListLoader ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    : this.state.filteredArray && this.state.filteredArray.length ?
                                        // this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                        this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`px-2 mb-2 pb-1 border-bottom-black5 ${this.state.selectedAlertId && this.state.selectedAlertId === item.event_id ? "bg-muted" : ""}`}> 
                                                    <div className="d-flex cursorPointer" onClick={() => this.getAlertDetails(item)}>
                                                        <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                            {item.severity ? 
                                                                (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                            : 'U'}
                                                        </span>
                                                        <p className="mb-0">
                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                            {/* <span className="f12 mb-0 mr-2 text-purple-2"> {item.total_alerts+ (item.total_alerts>1 ?' alerts':' alert')}</span>  */}
                                                            <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                                {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                                {item.event_name ? ' : ' + item.event_name : ""}
                                                                {item.event_type ? ' : '+ item.event_type : ""}</span>
                                                            {/* <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.event_type ? item.event_type : ""}</span> */}
                                                            {/* <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.event_name ? item.event_name : ""}</span> */}
                                                            {item.asset_name ?
                                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                            : null}
                                                            <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                            {item.account_id ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                            : null}
                                                            {item.region ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                            : null}
                                                            {item.resource_type ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                            : null}
                                                            {item.service_name ?
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                            : null}
                                                            {item.description ?
                                                                <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                            : null}
                                                        </p>
                                                    </div>
                                                    {this.state.selectedAlertId && this.state.selectedAlertId === item.event_id ?
                                                        <div className="rounded bg-dark3 p-3">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className={`card bg-dark border-0 mb-2`}>
                                                                    <div id={'heading_event'} onClick={() => this.setState({ expandAlertDetails: !this.state.expandAlertDetails })}>
                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                                            <div className="text-white"><span className={`far ${this.state.expandAlertDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Alert Details</div>
                                                                            {/* <div className="text-info">Showing event details</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div id={'collapse_event'} className={`collapse ${this.state.expandAlertDetails ? 'show' : ""}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                                                        <div className="card-body">
                                                                            <div className="d-flex justify-content-between mb-2 pb-2">
                                                                                <div>
                                                                                    <p className="mb-0 f16 text-white">{this.state.selectedAlert.event_name ? this.state.selectedAlert.event_name : ""}</p>
                                                                                    <p className="mb-0 f12">{this.state.selectedAlert.description ? this.state.selectedAlert.description : ""}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <p className="b-block mb-0">Severity</p>
                                                                                    <span className={`mr-2 badge ${this.state.selectedAlert.severity ? 'risk-badge-'+this.state.selectedAlert.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                                                        {this.state.selectedAlert.severity ? capitalizeFirstLetter(this.state.selectedAlert.severity) : 'Unknown'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mb-1 border-top">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Alert Time</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.event_time ? 
                                                                                            momentConvertionUtcToLocalTime(this.state.selectedAlert.event_time, 'DD MMM YYYY HH:mm')
                                                                                        : this.state.selectedAlert.created_at ? 
                                                                                            momentConvertionUtcToLocalTime(this.state.selectedAlert.created_at, 'DD MMM YYYY HH:mm')
                                                                                        : null}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Alert Metrics</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.event_source ? this.state.selectedAlert.event_source : ""}
                                                                                        {this.state.selectedAlert.event_type ? (' : ')+this.state.selectedAlert.event_type : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                            <div className="d-flex mb-1 border-top">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Threshold / Statistic / Period</p>
                                                                                    <p className="mb-0 text-warning">
                                                                                        {this.state.selectedAlert.threshold && this.state.selectedAlert.threshold}{this.state.selectedAlert.unit ? (this.state.selectedAlert.unit ? (this.state.selectedAlert.unit.toLowerCase() === 'percent' ? ' %' : " "+this.state.selectedAlert.unit) : "") : ""}
                                                                                        {this.state.selectedAlert.statistic ? (' / ')+this.state.selectedAlert.statistic : ""}
                                                                                        {this.state.selectedAlert.period ? (' / ')+this.state.selectedAlert.period : ""}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Provider : Account : Region </p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.provider ? this.state.selectedAlert.provider.toUpperCase() : ""}
                                                                                        {this.state.selectedAlert.account_id ? (' : ')+this.state.selectedAlert.account_id : ""}
                                                                                        {this.state.selectedAlert.region ? (' : ')+this.state.selectedAlert.region : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>                                                                        
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className={`card bg-dark border-0 mb-2`}>
                                                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                            <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                            {/* <div className="text-info">Showing asset details</div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                        <div className="card-body">
                                                                            <div className="d-flex mb-2">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Name</p>
                                                                                    <p className="mb-0 text-white">{this.state.selectedAlert.asset_name ? this.state.selectedAlert.asset_name : ""}</p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Id</p>
                                                                                    <p className="mb-0 text-white">{this.state.selectedAlert.asset_id ? this.state.selectedAlert.asset_id : ""}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mb-2 border-top">
                                                                                <div className="py-1 w-50">
                                                                                    <p className="b-block mb-0">Resource</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.provider ? this.state.selectedAlert.provider.toUpperCase() : ""}
                                                                                        {this.state.selectedAlert.account_id ? (" : ")+this.state.selectedAlert.account_id : ""}
                                                                                        {this.state.selectedAlert.region ? (" : ")+this.state.selectedAlert.region : ""}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="py-1 w-50 pl-3">
                                                                                    <p className="b-block mb-0">Services</p>
                                                                                    <p className="mb-0 text-white">
                                                                                        {this.state.selectedAlert.resource_type ? this.state.selectedAlert.resource_type : ""}
                                                                                        {this.state.selectedAlert.service_name ? (" : ")+this.state.selectedAlert.service_name : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>                                                        
                                                                            <div className="d-flex mb-2 border-top">
                                                                                <div className="py-1 w-100">
                                                                                    <p className="b-block mb-0">ARN</p>
                                                                                    <p className="mb-0 text-white">{this.state.selectedAlert.asset_arn ? this.state.selectedAlert.asset_arn : ""}</p>
                                                                                </div>
                                                                            </div>
                                                                            {this.state.selectedAlert && this.state.selectedAlert.category ? 
                                                                                <div className="d-flex mb-2 border-top">
                                                                                    <div className="py-1 w-100">
                                                                                        <p className="b-block mb-0">Category:</p>
                                                                                        <div className={`d-flex flex-wrap`}>
                                                                                            {Array.isArray(this.state.selectedAlert.category) ?
                                                                                                this.state.selectedAlert.category.map(item => {
                                                                                                    return (
                                                                                                        <small className={`mr-1 mb-1 badge badge-light`}>{item}</small>
                                                                                                    )
                                                                                                })
                                                                                            : 
                                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{this.state.selectedAlert.category}</small>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AlertsTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        leftSectionSelection: state.assets.resourcesPropsDetails.leftSectionSelection ? state.assets.resourcesPropsDetails.leftSectionSelection : {},
        dashboardDetails: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.dashboardDetails ? state.assets.resourcesPropsDetails.dashboardDetails : {},
    }
}

export default connect(mapStateToProps, {
    listObservabilityAlertGroups
})(withRouter(AlertsTab))