/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AnomalyDashboard.js
 * @author Prakash // on 23/03/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { setCostPropsDetails, listCostAnomalyResults, getCostAnomalyTotalCount, getCostTopAnomalies } from '../../../actions/cost/CostAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import Search from '../../common/SearchComponent'

import RightSection from './RightSection'                       
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

class AnomalyDashboard extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            showLoading: true,
            minMaxAnomalySectionHeight: 500,
            jumpDate: '',
            details: {},
            dynamicTableColumn: [],

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,

            groupType: 'All',
            groupedBy: 'severity',
            selectedCategory: 'Important / All',
            pageType: "Anomalies",
            showRightSide: true
        }
    }

	componentDidMount = () => {
        if(this.props.costCommonPageFilter && Object.entries(this.props.costCommonPageFilter).length) {
            this.setState({ 
                start_time: this.props.costCommonPageFilter.start_time,
                end_time: this.props.costCommonPageFilter.end_time,
                brushStartTime: this.props.costCommonPageFilter.brushStartTime ? this.props.costCommonPageFilter.brushStartTime : this.props.costCommonPageFilter.start_time,
                brushEndTime: this.props.costCommonPageFilter.brushEndTime ? this.props.costCommonPageFilter.brushEndTime : this.props.costCommonPageFilter.end_time,
                showRightSide: false,                
                anomalyListLoader: true
            },
                () => {
                    this.setState({ showRightSide: true })
                    this.listCostAnomalyResults()
                }
            )
        }
        this.getWindowSize()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 100
        let filterHeight = 300
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - filterHeight) + "px"

		this.setState({ outerHeight, minMaxAnomalySectionHeight: outerHeight })
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.costCommonPageFilter && Object.entries(this.props.costCommonPageFilter).length) {
            if(!_.isEqual(prevProps.costCommonPageFilter, this.props.costCommonPageFilter)) {
                this.setState({ 
                    start_time: this.props.costCommonPageFilter.start_time,
                    end_time: this.props.costCommonPageFilter.end_time,
                    brushStartTime: this.props.costCommonPageFilter.brushStartTime ? this.props.costCommonPageFilter.brushStartTime : this.props.costCommonPageFilter.start_time,
                    brushEndTime: this.props.costCommonPageFilter.brushEndTime ? this.props.costCommonPageFilter.brushEndTime : this.props.costCommonPageFilter.end_time,
                    showRightSide: false,
                    anomalyListLoader: true
                },
                    () => {
                        this.setState({ showRightSide: true })
                        this.listCostAnomalyResults()
                    }
                )
            }
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listCostAnomalyResults = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.aggregate_by = this.state.groupedBy
        if(this.state.groupType !== 'All') {
            params.anomaly_type = this.state.groupType
        }

        this.props.listCostAnomalyResults(params, (promise, response) => {
            if(promise) {
                let data = response.results && response.results.details ? response.results.details : []
                this.setState({ anomalyResponse: data, showGroupLoading: false },
                    () => {
                        this.structureAnomalyData()
                        // this.filterData()
                    }    
                )
            } else {
                this.setState({ anomalyResponse: [], showGroupLoading: false, anomalyListLoader: false })
            }
        })
    }

    structureAnomalyData = () => {
        let groupAllAnomaly = []

        let totalCount = 0
        
        let groupAnomalycategory = []
        if(this.state.anomalyResponse && this.state.anomalyResponse.length) {
            totalCount = this.state.anomalyResponse.reduce( function(a, b){ return a + (b.count ? b.count : (b.details ? b.details.length : 0))}, 0);
            this.state.anomalyResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.details ? item.details.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((dataRow.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                groupAnomalycategory.push(dataRow)
                item.events.forEach(alt => {
                    groupAllAnomaly.push(alt)
                })
            })
        }
        
        let groupAnomalyTotalCount = this.state.anomalyResponse.total_count ? this.state.anomalyResponse.total_count : 0
        let groupAnomalyfilteredCount = this.state.anomalyResponse.filtered_count ? this.state.anomalyResponse.filtered_count : 0
        
        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupAnomalycategory.push(dataRow)

        let orderedArray = _.orderBy(groupAnomalycategory, ['orderBy'], ['asc'])

        this.setState({ groupAllAnomaly, groupAnomaly: groupAllAnomaly, groupAnomalycategory: orderedArray, groupAnomalyTotalCount, groupAnomalyfilteredCount, anomalyListLoader: false },
            () => this.filterData()    
        )
    }

    getCategoryEvents = () => {
        let filterdCategoryAnomaly = this.state.groupAllAnomaly
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryAnomaly = this.state.anomalyResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryAnomaly && filterdCategoryAnomaly.length) {
                filterdCategoryAnomaly = filterdCategoryAnomaly[0].events ? filterdCategoryAnomaly[0].events : []
            }
        }

        this.setState({ groupAnomaly: filterdCategoryAnomaly, selectedAnomalyId: '' },
            () => {
                this.filterData()
                // this.redirectToAnalysis()
                let scroll = document.getElementById('scrollTop');
                scroll.scrollIntoView();
            }
        )
    }

    filterData = () => {
        let data = this.state.groupAnomaly ? this.state.groupAnomaly : []
        data = _.orderBy(data, ['anomaly_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        let priceUnit = ""
        if(data.length) {
            priceUnit = data[0].additional_details && data[0].additional_details.pricing_unit ? data[0].additional_details.pricing_unit : ""
        }

        this.setState({ totalFilteredArray: data, filteredArray: data, priceUnit, totalPages },
            () => {
                this.formHeaders()
                this.formDynamicHeaders()
                if(this.state.filteredArray.length) {
                    this.getAnomlayDetails(this.state.filteredArray[0])
                }
            }    
        )
    }

    formDynamicHeaders = () => {
        let ExpectedCost = "Expected Cost "+(<span className="text-primary-color f12">{this.state.priceUnit}</span>)
        let dynamicTableColumn = []
        let dataRow = {}

        dataRow.Header = ' '
        dataRow.accessor = 'severity'
        dataRow.Cell = cellInfo => (
            <span className={`badge ${
                cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ?
                    'risk-badge-'+cellInfo.row.original.severity.toLowerCase()
                : 'badge-secondary'
                }`}
            >
                {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
            </span>
        )
        dataRow.width = 55
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Date'
        dataRow.accessor = 'anomaly_time'
        dataRow.width = 150
        dataRow.HeaderUnit = "local"
        dataRow.Cell = cellInfo => (
            <span>{cellInfo.row.original.anomaly_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.anomaly_time, 'DD MMM YYYY HH:mm:ss') : ''}</span>
        )
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Duration'
        dataRow.accessor = d => d.additional_details && d.additional_details.duration
        dataRow.Cell = cellInfo => (<div className="d-flex"><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.duration ? cellInfo.row.original.additional_details.duration : ''}</span><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.duration_unit ? cellInfo.row.original.additional_details.duration_unit : ''}</span></div>)
        dataRow.width = 140				
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Impact Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_impact_cost
        dataRow.Cell = cellInfo => (<div className="d-flex"><span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_impact_cost ? cellInfo.row.original.additional_details.total_impact_cost : ''}</span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_impact_percentage ? <span className="mr-1 text-success f12 align-self-center">({cellInfo.row.original.additional_details.total_impact_percentage}%)</span> : ""}</div>)
        dataRow.HeaderUnit = this.state.priceUnit
        dataRow.width = 160
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        // dataRow.Header = "Actual Cost "+(<span className="text-primary-color f12">({this.state.priceUnit})</span>)
        dataRow.Header = "Actual Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_actual_cost
        dataRow.Cell = cellInfo => (<span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_actual_cost ? cellInfo.row.original.additional_details.total_actual_cost : ''}</span>)
        dataRow.HeaderUnit = this.state.priceUnit
        dataRow.width = 160
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Expected Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_expected_cost
        dataRow.Cell = cellInfo => (<span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_expected_cost ? cellInfo.row.original.additional_details.total_expected_cost : ''}</span>)
        dataRow.HeaderUnit = this.state.priceUnit
        dataRow.width = 180
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Description"
        dataRow.accessor = d => d.anomaly_message ? d.anomaly_message : ""
        dataRow.className = "whiteSpaceUnset"
        dataRow.width = 450
        dynamicTableColumn.push(dataRow)

        this.setState({ dynamicTableColumn })
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string" && key !== "event_data") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    getAnomlayDetails = (selectedRecord) => {
        
        this.setState({ selectedAnomalyId: selectedRecord.anomaly_id })

        let obj = {}
        obj.selectedTab = 'Details'
        obj.details = selectedRecord
        obj.merticsAssetNameInvolved = selectedRecord.asset_id ? [selectedRecord.asset_id] : []
        obj.brushStartTime = this.state.brushStartTime
        obj.brushEndTime = this.state.brushEndTime
        obj.pageType = this.state.pageType
        this.props.setCostPropsDetails('tabOptions', obj)
    }
    
    handleChange = (event) => {

        this.setState({ groupType: event.target.value, showGroupLoading: true, selectedAnomalyId: '', anomalyListLoader: true },
            () => {
                if(this.state.groupType !== "All" && this.state.groupedBy === "anomaly_type") {
                    this.setState({ groupedBy: "severity"},
                        () => this.listCostAnomalyResults()
                    )
                } else {
                    this.listCostAnomalyResults()
                }
            }    
        )
    } 

    handleClickOutside(event) {}
    
	render() {
		return (
            <div className="" id="scrollTop" onClick={(event) => this.handleClickOutside(event)}>
                <div className="w-100">
                    <div className={`d-flex mt-2 ml-1 ${this.state.anomalyListLoader ? 'disabled' : ''}`}>
                        <p className="m-0 align-self-end small mr-2">Show </p>
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                            onChange={this.handleChange}
                            value={this.state.groupType}
                            >
                                <option key="All" value="All">ALL</option>
                                <option key="metric" value="metric">Metric</option>
                                <option key="lifecycle" value="lifecycle">Lifecycle</option>
                                <option key="apicalls" value="apicalls">API Calls</option>
                                <option key="slow_query" value="slow_query">Slow Queries</option>
                                <option key="logs" value="logs">Logs</option>
                            </select>
                        </div> 
                        <p className="m-0 align-self-end small mx-2">anomalies groupd by</p>
                        {/* <p className="m-0 align-self-end small mx-2">Showing Anomalies groupd by</p> */}
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                                onChange={(event) => {
                                    this.setState({ groupedBy: event.target.value, anomalyListLoader: true, selectedCategory: "Important / All", selectedAnomalyId: '' },
                                        () => {
                                            // this.redirectToAnalysis()
                                            this.listCostAnomalyResults()
                                        }
                                    )
                                }}
                                value={this.state.groupedBy}                            
                                >
                                    <option key="severity" value="severity">Severity</option>
                                    <option key="asset_name" value="asset_name">Asset</option>
                                    <option key="service_name" value="service_name">Service</option>
                                    {(this.state.groupType === "All" || this.state.groupType === "logs") ? 
                                        <option key="anomaly_type" value="anomaly_type">Anomaly Type</option>
                                    : null}
                            </select>
                        </div>
                    </div>
                    
                    <div className="d-flex mt-1">
                        <div className={`${this.state.expandRIghtSection ? 'w-100' : 'w-100'} mr-1`}>
                            <div className="rounded bg-dark p-3">
                                <div className="d-flex mb-2 justify-content-between">
                                    <div className="d-flex mr-2 w-60">
                                        <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length}{this.state.totalFilteredArray && this.state.totalFilteredArray.length > 1 ? ' anomalies' : ' anomalies'}
                                        <span className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}</span><span className="ml-2">(local time)</span></p>
                                    </div>
                                    <div className="d-flex justify-content-end w-40 mb-n2">
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <CSVLink 
                                                data={this.state.filteredArray} 
                                                headers={this.state.headers ? this.state.headers : []}
                                                filename={"cost-anomalies.csv"}
                                                className={"align-self-center mr-2"}
                                                target="_blank"
                                            >
                                                <i className="fas fa-download text-primary-color"></i>
                                                {/* <span className="text-primary-color mx-2">csv</span> */}
                                            </CSVLink>
                                        : null}
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <Search
                                                data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-black5 align-self-center w-80 mr-1 border-gray5 rounded-5 f12'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-gray5 f12'}
                                                searchPlaceHolder={'Search anomaly....'}
                                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                filteredData={(filteredArray) => {
                                                    this.setState({ filteredArray, errorDetails: {}, merticsAssetNameInvolved: [] },
                                                        () => {
                                                            if(this.state.filteredArray.length) {
                                                                this.getAnomlayDetails(this.state.filteredArray[0])
                                                            }
                                                        }    
                                                    )
                                                }}
                                            />
                                        : null}
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12 displayNone">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span>
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                
                                <div className="d-flex">
                                    {this.state.groupAnomalycategory ? 
                                        <div className="pr-3 categoryBoxSection">
                                            <div class="bg-muted px-2 pt-2 select-bgg">
                                                {this.state.groupedBy === "severity" ?
                                                    this.state.groupAnomalycategory.map(item => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <div class="d-flex">
                                                                        {item.totalBars.map(bar => {
                                                                            return(
                                                                            <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                            )
                                                                        })}
                                                                        <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                            <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    })                                            
                                                : 
                                                    this.state.groupAnomalycategory.map((item, index) => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                        <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                        <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                        <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                                    </div>
                                                                </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    : null}
                                    <div className={`${this.state.groupAnomalycategory ? 'w-100' : 'w-100'}`}>
                                        <div className={`bg-dark3 py-3 pl-2 overflowYAuto`} style={{minHeight: this.state.minMaxAnomalySectionHeight, maxHeight: this.state.minMaxAnomalySectionHeight}}> 
                                            {this.state.anomalyListLoader ? 
                                                <div className='d-flex justify-content-center m-4'>
                                                    <Spinner className='text-center' color='white' size='lg' />
                                                </div>
                                            : this.state.filteredArray && this.state.dynamicTableColumn.length && this.state.filteredArray.length ?
                                                <React.Fragment>
                                                {/* // <ResizeableDarkThemeTable
                                                //     columns={[
                                                //         {
                                                //             Header: ' ',
                                                //             accessor: 'severity',
                                                //             Cell: cellInfo => (
                                                //                 <span className={`badge risk-badge-${
                                                //                     cellInfo.row.original.severity ?
                                                //                         cellInfo.row.original.severity.toLowerCase()
                                                //                     : ''
                                                //                     } aiopsTableBadgeWidth`}
                                                //                 >
                                                //                     {cellInfo.row.original.severity ?
                                                //                         (cellInfo.row.original.severity.toLowerCase() === 'critical' ? 
                                                //                             'C' 
                                                //                         : cellInfo.row.original.severity.toLowerCase() === 'high' ? 
                                                //                             'H'
                                                //                         : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 
                                                //                             'M'
                                                //                         : cellInfo.row.original.severity.toLowerCase() === 'low' ? 
                                                //                             'L' 
                                                //                         : '') 
                                                //                     : 'U'}
                                                //                 </span>
                                                //             ),
                                                //             width: 55
                                                //         },
                                                //         {
                                                //             Header: "Date (local)",
                                                //             accessor: "anomaly_time",
                                                //             Cell: cellInfo => (<span>{cellInfo.row.original.anomaly_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.anomaly_time, 'DD MMM YYYY HH:mm')  : ''}</span>),
                                                //         },
                                                //         {
                                                //             Header: "Duration",
                                                //             // accessor: "asset_id"
                                                //             Cell: cellInfo => (<div className="d-flex"><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.duration ? cellInfo.row.original.additional_details.duration : ''}</span><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.duration_unit ? cellInfo.row.original.additional_details.duration_unit : ''}</span></div>),
                                                //             width: 100
                                                //         },
                                                //         {
                                                //             Header: "Impact Cost "+this.state.priceUnit,
                                                //             // accessor: "asset_id"
                                                //             Cell: cellInfo => (<span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_impact_cost ? cellInfo.row.original.additional_details.total_impact_cost : ''}</span>),
                                                //             width: 140
                                                //         },
                                                //         {
                                                //             Header: "Actual Cost",
                                                //             // accessor: "asset_id"
                                                //             Cell: cellInfo => (<span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_actual_cost ? cellInfo.row.original.additional_details.total_actual_cost : ''}</span>),
                                                //             width: 140
                                                //         },
                                                //         {
                                                //             Header: "Expected Cost",
                                                //             // accessor: "asset_id"
                                                //             Cell: cellInfo => (<div className="d-flex"><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_expected_cost ? cellInfo.row.original.additional_details.total_expected_cost : ''}</span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_impact_percentage ? <span className="mr-1 text-pimary-color f12">({cellInfo.row.original.additional_details.total_impact_percentage})</span> : ""}</div>),
                                                //             width: 140
                                                //         },
                                                //         {
                                                //             Header: "Description",
                                                //             accessor: d => d.anomaly_message ? d.anomaly_message : "",
                                                //             className:"whiteSpaceUnset",
                                                //             width: 800
                                                //         }
                                                //     ]}
                                                //     data={this.state.filteredArray}
                                                //     perPage={10}
                                                //     dashboard={true}
                                                //     sortByColumn={"anomaly_time"}
                                                //     riskTooltip={[]}
                                                //     onClickRow={tableRow => {}}
                                                // /> */}

                                                <ResizeableDarkThemeTable
                                                    columns={this.state.dynamicTableColumn}
                                                    data={this.state.filteredArray}
                                                    perPage={10}
                                                    dashboard={true}
                                                    sortByColumn={"anomaly_time"}
                                                    riskTooltip={[]}
                                                    onClickRow={tableRow => {}}
                                                />                                                
                                                </React.Fragment>
                                            :
                                                <div className='d-flex justify-content-center m-4'>
                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {!this.state.anomalyListLoader ? 
                            <div className={`${this.state.expandRIghtSection ? 'w-40' : 'w-40'}`}>
                                <RightSection
                                    updateDetail = {() => this.setState({ selectedAnomalyId: '' }, () => this.props.setCostPropsDetails('tabOptions', {}))}
                                    pageType={this.state.pageType}
                                />
                            </div>
                        : null} */}
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AnomalyDashboard.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        costCommonPageFilter: state.cost.costPropsDetails && state.cost.costPropsDetails.costCommonPageFilter ? state.cost.costPropsDetails.costCommonPageFilter : {},
    }
}

export default connect(mapStateToProps, {
    setCostPropsDetails,
    listCostAnomalyResults
})(withRouter(AnomalyDashboard))