/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ChecksSearch.js
 * @author Rajasekar // on 07/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../../common/AppWrapper'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import ApexChart from 'react-apexcharts'
import { URL_PATH } from '../../../config/urlPath'
import { CSVLink } from "react-csv";

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Col, Row, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import _ from 'lodash'

import { myLog, capitalizeFirstLetter, getAccountNameFromId, subDays, subHours, momentDateTime, momentFullDateTime, datestartEndTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'
import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../../utils/constants'

import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getSearchMetaResults } from '../../../actions/commonAction'
import { listAllSuppressors } from '../../../actions/detectors/suppressorsAction'
import { listAllIdentifiers } from '../../../actions/detectors/configurationsAction'
import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import {
	listAllDetectors,
	getTotalCount,
	getDayWiseCount,
	getEventInfo,
	getAssetDetails,
	getRelatedIssues,
	getBillingSnapshot,
	getMonitoringSnapShot,
	getEventByBoundary,
	resolveIssue
} from '../../../actions/detectors/detectorsAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchComponent'
import DetailsSection from './DetailsSection'
import SupressSection from './SupressSection'
import ConfigureSection from './ConfigureSection'
import CloseForm from './CloseForm'

import moment from 'moment'

import AdvancedSearch from '../../common/AdvancedSearch'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import DropdownTreeSelect from 'react-dropdown-tree-select'

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import 'react-dropdown-tree-select/dist/styles.css'

const checksStatus = [
	{ value: 'All', label: 'All'},
	{ value: 'pass', label: 'Pass'},
	{ value: 'fail', label: 'Fail'}
];

const riskDropdown = [
	{ value: 'All', label: 'All'},
	{ value: 'Low', label: 'Low'},
	{ value: 'Medium', label: 'Medium'},
	{ value: 'High', label: 'High'},
	{ value: 'Critical', label: 'Critical'},
];

const actionOptions = ["Fix", "Suppress", "Configure"]

class ChecksSearch extends Component {
	constructor(props) {
		super(props)
		this.searchComponent = React.createRef()
		this.tabRef = React.createRef()
		this.providerRef = React.createRef()
		this.dayPickerRef = React.createRef()
		this.durationRef = React.createRef()
		this.accountRef = React.createRef()
		this.regionRef = React.createRef()
		this.serviceRef = React.createRef()
		this.checksStatusRef = React.createRef()
		this.riskRef = React.createRef()
		this.searchRef = React.createRef()

		this.handleDayClick = this.handleDayClick.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.state = this.getInitialState();

		this.state = {			
			checked: [],
			expanded: [],
			showSearchOptions: false,
			keyupTimer: undefined,
			selectedTab: 'summary_details',
			series: [],
			options: [],
			showLoading: false,
			showTabListDropdown: false,
			isActionDropdownOpen: false,
			showSupressInDetailsSection: false,
			showConfigureSection: false,
			showCloseSection: false,
			
			showDetailsSummarySectionLoading: false,
			showDetailsAssetSectionLoading: false,
			showDetailsMonitoringSectionLoading: false,
			showDetailsBillingSectionLoading: false,
			showDetailsEventsSectionLoading: false,
			showDetailsRelatedIssueLoading: false,
			showSupressSectionLoading: false,
			showDetailsAvailSectionLoading: false,
			
			supressRules: [],
			showDetailsSection: false,
			showSupressForm: false,
			providers: [],
			providerIsOpen: false,
			allAccounts: [],
			accounts: [],
			regions: [],
			services: [],
			showDropdownTree: false,
			serviceResourceArray: [],
			filteredServiceResourceArray: [],
			
			selectedProvider: 'All',
			
			selectedAccount: [],
			isAccountOpen: false,
			
			selectedRegion: [],
			isRegionOpen: false,
			
			selectedChecksStatus: ['fail'],
			selectedStatusArray: [{value:'fail', label:'Fail'}],
			isChecksStatusOpen: false,

			selectedRisk: [],
			isRiskOpen: false,
			
			selectedService: [],
			selectedServiceContent: 'All',
			isServiceOpen: false,
			
			selectedComplicance: '',
			authorityMajorControl: '',
			selectedCategory: [],
			previousCustomDays: moment().subtract('180', 'days'),
			showDatepicker: false,
			durationType: '',
			selectedDuration: '+60d',
			relatedIssueDurtaion: '+60d',
			start_time : '',
			end_time: '',
			selectedControls: '',
			filteredArray: [],
			selectedTableRow: {},
			statusCounts: [],
			statusCountArrays: [],
			categoryCounts: [],
			riskCounts: [],
			riskCountArrays: [],

			eventsData: {},

			detailsTabNames: [
				{ name: 'Summary', value: 'summary_details' },
				{ name: 'Resource', value: 'asset_details' },
				{ name: 'Issues', value: 'related_issues' },
				{ name: 'Events', value: 'events' },
				{ name: 'Monitoring', value: 'monitoring_details' },
				// { name: 'Billing', value: 'billing' },
				// { name: 'Availability', value: 'availability' },
			],
			selectedTableTab: -1,
			selectedAdvancedSearch: {},
			selectedDetailsTab: 0,
			showDetailsTabListDropdown: false,
			onClickSupressCreateButton: false,
			selectedRowEventInfo: [],
			selectedRowAssetDetails: [],
			selectedRowBillingDetails: {},
			selectedRowMonitoringSnapshot: {},

			dayWiseCountGraphData: {},
			relatedIssuesData: [],
			filteredRelatedIssuesData: [],
			assetConfigrationsList: [],
			summaryGraphData: {},

			tabItemsToShow: 0,

			detailsTabItemsToShow: 2,
			searchText: '',
			newSearchText: '',
			showDropdown: false,
			//searchResultState: [],
			selectedMonitoringDuration: '2h',
			showAdvancedSearchModal: false,
			resetForm: 1,

			tableRowRegions: [],
			recentOptions: [],
			recentSeries: [],
			errorOccurred: false,
			
			modalHeader: 'Close Issue',
			modalClose: false,
			modalAlert: false,
			
			closeLoading: false,
			closeStatus: 'resolved',
			closeStatusType: '',
			closeAttachment: '',
			closeAttachmentKey: false,
			
			
			responseMessage: '',
			showMessage: false,
			hasErrorInRuleForm: false,
		}
		this.toggleCloseModal = this.toggleCloseModal.bind(this)	
		
		this.onChangeServiceDropdown = this.onChangeServiceDropdown.bind(this);
	}
	componentDidCatch(error, info) {
		// Display fallback UI
		// this.setState({ errorOccurred: true });
		// You can also log the error to an error reporting service
		//logErrorToMyService(error, info);
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		//this.getAllCounts(true)
		this.setState({ showLoading: true })
		this.props.listAllProviders((promise, response) => {
			if (promise) {
				// let providers = []
				// if (response.length) {
				// 	let dataRow = {}
				// 	dataRow.provider_id = "All"
				// 	dataRow.provider_name = "All"
				// 	providers.push(dataRow);
				// }
				// providers = providers.concat(response)
				this.setState({ providers: response, selectedProvider: response.length ? response[0].provider_name : '', showLoading: true },
					() => {						
						if (this.props.location && this.props.location.state && this.props.location.state) {
							this.loadFromLoctionAction()
						} else {
							this.filterIdentifiers('provider')
						}
					}
					//, () => this.addTestAzureProvider()
				)
			} else {
				this.setState({ providers: [] })
			}
		})
		const _this = this
		window.addEventListener('resize', () => {
			_this.setTabs()
		})

		//this.getSearchMetaResultsOnLoad()
	}

	getSearchMetaResultsOnLoad = () => {		
		this.props.getSearchMetaResults(
			{
				size: 5,
				module_name: 'identifier',
				default: true,
			},
			() => {
				this.setState({ searchResultState: this.props.searchNewResults, onSearchResultState: this.state.searchResultState, isLoading: false })
			}
		)
	}
	
	componentWillUnmount() {
		document.removeEventListener('keydown', this._handleKeyDown)
	}	

	loadFromLoctionAction = () => {
		if (this.props.location && this.props.location.state && this.props.location.state) {
			this.setState({
				selectedAccount: this.state.selectedAccount.length ? this.state.selectedAccount : this.props.location.state.selectedAccount !== 'all' && this.props.location.state.selectedAccount !== ''  ? [this.props.location.state.selectedAccount] : [],
				selectedProvider: this.state.selectedProvider !== 'All' ? this.state.selectedProvider : this.props.location.state.selectedProvider,
				selectedDuration: this.state.selectedDuration !== '' ? this.state.selectedDuration : this.props.location.state.selectedDuration,
				fromUrl:this.props.location.state.from.pathname,

				//for redirect previous page purpose
				redirectAccount: this.props.location.state.selectedAccount,
				redirectAccountArray: this.props.location.state.selectedAccountName, 
				redirectProvider: this.props.location.state.selectedProvider,
				redirectDuration: this.props.location.state.selectedDuration,

				selectedComplicance: this.props.location.state.selectedComplicance ? this.props.location.state.selectedComplicance : "",
				authorityMajorControl: this.props.location.state.authorityMajorControl ? this.props.location.state.authorityMajorControl : "",

				//for redirect previous page purpose
				redirectComplicance: this.props.location.state.selectedComplicance ? this.props.location.state.selectedComplicance : "",
				selectedCategory: this.props.location.state.selectedCategory ? this.props.location.state.selectedCategory : ""
			},
				() => this.filterIdentifiers("provider")
			)
			// if(this.props.location.state.selectedComplicance) {
			// 	this.setState({
			// 		//selectedControls: this.props.location.state.controls
			// 		selectedComplicance: this.props.location.state.selectedComplicance,
			// 		authorityMajorControl: this.props.location.state.authorityMajorControl,

			// 		//for redirect previous page purpose
			// 		redirectComplicance: this.props.location.state.selectedComplicance,
			// 	})
			// }

			// 	if(this.props.location.state.selectedCategory) {
			// 		this.setState({
			// 			//selectedControls: this.props.location.state.controls
			// 			selectedCategory: this.props.location.state.selectedCategory
			// 		})
			// 	}
		}
	}

	filterIdentifiers = from => {
		if(from !== 'advancedSearch') {			
			this.setState({ selectedAdvancedSearch: {}})
		}
		// && this.state.selectedAccount !== this.props.location.state.selectedProvider && this.state.selectedProvider !== this.props.location.state.selectedProvider && this.props.location.state.selectedDuration === selectedDuration
		
		this.setState({ showLoading: true, filteredArray: [], selectedTab: 'summary_details' })
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedService.length && !this.state.selectedService.includes('All')) {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedChecksStatus.length) {
			if(this.state.selectedChecksStatus[0] === 'All') {
				params.status = 'all'
			} else {
				params.status = this.state.selectedChecksStatus
			}
		}
		if (this.state.selectedRisk.length && !this.state.selectedRisk.includes('All')) {
		//if (this.state.selectedRisk !== '' && this.state.selectedRisk !== 'all') {
			params.risk = this.state.selectedRisk
		}
		
		if (this.state.selectedCategory.length) {
			params.category = this.state.selectedCategory
		}

		if (from === 'provider') {
			this.setState({ providerIsOpen: false })
			delete params['account_id']
			delete params['region']
			delete params['service_name']
			delete params['provider']
			delete params['risk']
			if(!this.props.accounts.length) {
				this.props.listAllAccounts(params, (promise, response) => {
					if (promise) {
						let allAccounts = []
						if(response.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							allAccounts.push(accRow);
						}
						allAccounts = allAccounts.concat(response)
						//this.setState({ accounts: accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())})
						this.setState({ accounts: allAccounts }
						)
					} else {
						this.setState({ allAccounts: [], accounts: [] })
					}
				})
			} else {
				let filterAccounts = this.props.accounts
				if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
					filterAccounts = filterAccounts.filter(item => item.provider === this.state.selectedProvider.toLowerCase())
					let accounts = []
					if(filterAccounts.length) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						accounts.push(accRow);
						
						filterAccounts = accounts.concat(filterAccounts)
					}
				}
				this.setState({ accounts: filterAccounts })
			}

			if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
				params.provider = this.state.selectedProvider.toLowerCase()
			}
			delete params['status']
			delete params['category']
			if(this.state.selectedProvider !== '') {
				if (this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
					params.account_id = this.state.selectedAccount
				}
				this.props.listAllRegions(params, (promise, regions) => {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let regions = []
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						regions.push(provider_regions_row);
						providerRegions = regions.concat(providerRegions)
					}
					if (promise) {
						this.setState({ regions: providerRegions, tableRowRegions: providerRegions })
					} else {
						this.setState({ regions: [], tableRowRegions: [] })
					}
				})
			}
			this.props.listAllServices(params, (promise, services) => {
				if (promise) {
					let serviceResourceArray = []
					let serviceArray = []
					services && services.length &&
					_.chain(services)
					.groupBy('resource_type')
					.map((value, key) => ({ label: key, sub: value }))
					.value()
					.forEach((service, i) => {
						if(i === 0) {
							let dataRow = {}							
							dataRow.category = 'All'
							dataRow.default_region = 'All'
							dataRow.provider_name = 'All'
							dataRow.resource_type = 'All'
							dataRow.service_id = 'All'
							dataRow.service_name = 'All'
							serviceArray.push(dataRow)

							let serRow = {}
							serRow.label = 'All'
							serRow.value = 'All'
							serRow.expanded = true
							let serArray = []
							serRow.children = serArray
							serviceResourceArray.push(serRow)
						}
						let serRow = {}
						serRow.label = service.label
						serRow.value = service.label
						serRow.expanded = true
						let serArray = []
						//serviceResourceArray.push(service)
						service.sub.forEach((item, i) => {
							let serItem = {}
							serItem.label = item.service_name
							serItem.value = item.service_name
							serItem.expanded = true
							serArray.push(serItem)
							serviceArray.push(item)
						})
						serRow.children = serArray
						serviceResourceArray.push(serRow)
					})
					this.setState({ services, serviceArray, serviceResourceArray, filteredServiceResourceArray: serviceResourceArray })
				} else {
					this.setState({ services: [] })
				}
			})
		}
		let identifierParams = {}
		if(this.state.selectedAdvancedSearch && this.state.selectedAdvancedSearch.parameters && Object.entries(this.state.selectedAdvancedSearch.parameters).length) {
			identifierParams = this.state.selectedAdvancedSearch.parameters
		} else {
			
			if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
				identifierParams.provider = this.state.selectedProvider.toLowerCase()
			}
			if (this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
				identifierParams.account_id = this.state.selectedAccount
			}
			if (this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
				identifierParams.region = this.state.selectedRegion
			}
			if (this.state.selectedService.length && !this.state.selectedService.includes('All')) {
				identifierParams.service_name = this.state.selectedService
			}
			if (this.state.selectedChecksStatus.length) {
				if(this.state.selectedChecksStatus[0] === 'All') {
					identifierParams.status = 'all'
				} else {
					identifierParams.status = this.state.selectedChecksStatus
				}
			}
			if (this.state.selectedRisk.length && !this.state.selectedRisk.includes('All')) {
				identifierParams.risk = this.state.selectedRisk
			}
			if (this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
				if(this.state.selectedDuration === 'custom')  {
					identifierParams.start_time = this.state.start_time
					identifierParams.end_time = this.state.end_time
				} else {
					identifierParams.duration = this.state.selectedDuration
				}
			}
			if (this.state.selectedCategory.length) {
				identifierParams.category = this.state.selectedCategory
			}
			if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
				if(this.state.authorityMajorControl !== '') {
					identifierParams.compliance = [{"complianceId":this.state.selectedComplicance, "authorityMajorControl": this.state.authorityMajorControl}]
				} else {
					identifierParams.compliance = [{ "complianceId":this.state.selectedComplicance }]
				}
			}
		}

		this.getAllCounts(true)
		this.props.listAllDetectors(identifierParams, () => {
			if (this.props.detectors && this.props.detectors.length) {
				let diffrence = '';

				if(this.props.detectors[0] && this.props.detectors[0].resolution_status_info) {
					let a = moment(moment().format('M/D/YYYY'), 'M/D/YYYY')
					let b = moment([moment(this.props.detectors[0].resolution_status_info.timestamp).format('M/D/YYYY')], 'M/D/YYYY')
					diffrence = a.diff(b, 'days');
				}

				this.setState({
					showLoading: false,
					filteredArray: this.props.detectors,
					selectedTableRow: this.props.detectors[0],
					daysFromCreated: diffrence,
					showDetailsSection: true,
					selectedDetailsTab: 0,
				}, 
					() => {
						this.getProviderRegion()
						this.formHeaders()
					}
				)
				this.setTabs()
				if (this.searchComponent.current) {
					this.searchComponent.current._handleSearch()
				}
			} else {
				this.setState({ showLoading: false, showDropdownTree: false },
					() => this.getProviderRegion()
				)
			}
			if (
				Object.entries(this.state.selectedTableRow).length !== 0 &&
				this.state.selectedTableRow.constructor === Object
			) {
				this.getAssetSupressList(this.state.selectedTableRow)
				this.getAssetConfigurationsList(this.state.selectedTableRow)
				this.getEventInfo(this.state.selectedTableRow)
				this.getAssetDetails(this.state.selectedTableRow)
				// this.getRelatedIssues(this.state.selectedTableRow)
				// this.getMonitoringSnap(this.state.selectedTableRow)
				// this.getBillingSnap(this.state.selectedTableRow)
			}
		})

	}

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

	addTestAzureProvider = () => {
		let dataRow = {}
		dataRow.provider_id = "Azure"
		dataRow.provider_name = "Azure"
		this.setState(prevState => ({
			providers: [...prevState.providers, dataRow]
		}))
	}

	_handleKeyDown = event => {
		switch (event.keyCode) {
			case 27:
				this.setState({ showAdvancedSearchModal: false })
				break
			default:
				break
		}
	}

	handleMultiSelectChange = (field, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
		let selectedValue = []
		if(field === 'selectedAccount' || field === 'selectedRegion' || field === 'selectedRisk' || field === 'selectedChecksStatus') {
			let prevState = this.state[field]
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					if(field === "selectedAccount") {
						this.state.accounts.forEach(item => {
							selectedValue.push(item.account_id)
						})
					} else if(field === "selectedRegion") {
						this.state.regions.forEach(item => {
							selectedValue.push(item.region)
						})
					} else if(field === "selectedRisk") {
						riskDropdown.forEach(item => {
							selectedValue.push(item)
						})
					} else if(field === "selectedChecksStatus") {
						checksStatus.forEach(item => {
							selectedValue.push(item)
						})
					}
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(field === "selectedAccount") {
					if(!selectedValue.includes('All') && selectedValue.length === (this.state.accounts.length -1)) {
						selectedValue.push('All')
					}
				} else if(field === "selectedRegion") {
					if(!selectedValue.includes('All') && selectedValue.length === (this.state.regions.length -1)) {
						selectedValue.push('All')
					}
				} else if(field === "selectedRisk") {
					if(!selectedValue.includes('All') && selectedValue.length === (riskDropdown.length -1)) {
						selectedValue.push('All')
					}
				} else if(field === "selectedChecksStatus") {
					if(!selectedValue.includes('All') && selectedValue.length === (checksStatus.length -1)) {
						selectedValue.push('All')
					}
				}
			}
			this.setState({ [field]: selectedValue },
				() => this.filterIdentifiers()
			)
		}		
	}

	getMultiSelectedCount = (type, array) => {
		// if(type === 'status') {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		// } else {
		// 	return array.length && array.includes('All') ? array.length - 1 +' Selected' :  array.length ? array.length +' Selected' : 'All'
		// }
	}

	onChangeServiceDropdown  = (currentNode, selectedNodes) => {
		let filteredServiceResourceArray = []	
		let selectedTreeService = []
		let selectedService = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.parent = 1
					selectedTreeService.push(child)
					this.state.serviceResourceArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedService.push(child.label)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.parent = 0
					selectedTreeService.push(child)
					selectedService.push(item.label)
				}
			})

			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					if(selectedTreeService.length) {					
						selectedTreeService.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredServiceResourceArray.push(dataRow)
			})
		} else if(currentNode.label === 'All') {
			selectedTreeService= []
			selectedService = []
			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		} else {
			filteredServiceResourceArray = this.state.serviceResourceArray
		}
		let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
		this.setState({ filteredServiceResourceArray, selectedService, selectedServiceContent },
			() => {
				if(selectedNodes.length || (!selectedNodes.length && currentNode.label !== 'All')) {
					this.filterIdentifiers('services')
				}
			}
		)
	}
	
	handleClickOutside(event) {
		//if (this.serviceRef && !this.serviceRef.current.contains(event.target)) {

			if(this.durationRef && !this.durationRef.current.contains(event.target)) {
				if (this.dayPickerRef && !this.dayPickerRef.current.contains(event.target)) {
					this.setState({ showDatepicker: false })
					this.handleResetClick()
				}
			}
			
			if (this.providerRef && !this.providerRef.current.contains(event.target)) {
				this.setState({ providerIsOpen: false })
			} else {
				this.setState({ providerIsOpen: true })
			}
			
			if (this.accountRef && !this.accountRef.current.contains(event.target)) {
				this.setState({ isAccountOpen: false })
			} else {
				this.setState({ isAccountOpen: true })
			}
			
			if (this.tagRef && !this.tagRef.current.contains(event.target)) {
				this.setState({ isTagOpen: false })
			} else {
				this.setState({ isTagOpen: true })
			}
	
			if (this.regionRef && !this.regionRef.current.contains(event.target)) {
				this.setState({ isRegionOpen: false })
			} else {
				this.setState({ isRegionOpen: true })
			}
	
			
			if (this.checksStatusRef && !this.checksStatusRef.current.contains(event.target)) {
				this.setState({ isChecksStatusOpen: false })
			} else {
				this.setState({ isChecksStatusOpen: true })
			}
			
			if (this.riskRef && !this.riskRef.current.contains(event.target)) {
				this.setState({ isRiskOpen: false })
			} else {
				this.setState({ isRiskOpen: true })
			}

			if (this.searchRef && !this.searchRef.current.contains(event.target)) {
				this.setState({ showDropdown: false })
			}

			this.setState({ showDropdownTree: false })

		// } else {
		// 	//this.setState({ showDropdownTree: false })
		// }
	}

	/**
	 * Method to get all status, risk and category counts
	 */
	getAllCounts = getCategory => {
		let params = {}
		if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
			params.provider = this.state.selectedProvider.toLowerCase()
		}
		if (this.state.selectedService.length && !this.state.selectedService.includes('All')) {
			params.service_name = this.state.selectedService
		}
		if (this.state.selectedRegion.length && !this.state.selectedRegion.includes('All')) {
			params.region = this.state.selectedRegion
		}
		if (this.state.selectedChecksStatus.length) {
			if(this.state.selectedChecksStatus[0] === 'All') {
				params.status = 'all'
			} else {
				params.status = this.state.selectedChecksStatus
			}
		}
		//if (this.state.selectedRisk !== '' && this.state.selectedRisk !== 'all') {
		if (this.state.selectedRisk.length && !this.state.selectedRisk.includes('All')) {
			params.risk = this.state.selectedRisk
		}
		if (this.state.selectedCategory.length && this.state.selectedCategory[0] !== 'all') {
			params.category = this.state.selectedCategory
		}
		if (this.state.selectedAccount.length && !this.state.selectedAccount.includes('All')) {
			params.account_id = this.state.selectedAccount
		}
		if (!this.state.selectedDuration !== '' && this.state.selectedDuration !== 'all') {
			//params.duration = this.state.selectedDuration
			if(this.state.selectedDuration === 'custom')  {
				params.start_time = this.state.start_time
				params.end_time = this.state.end_time
			} else {
				params.duration = this.state.selectedDuration
			}
		}
		if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
			if(this.state.authorityMajorControl !== '') {
				params.compliance = [{"complianceId":this.state.selectedComplicance, "authorityMajorControl": this.state.authorityMajorControl}]
			} else {
				params.compliance = [{ "complianceId":this.state.selectedComplicance }]
			}
		}

		if(this.state.selectedAdvancedSearch && this.state.selectedAdvancedSearch.parameters && Object.entries(this.state.selectedAdvancedSearch.parameters).length) {
			params = this.state.selectedAdvancedSearch.parameters
			params.aggregate_by = 'status'
		} else {
			params.aggregate_by = 'status'
			delete params['status']
		}

		this.props.getTotalCount(params, (promise, statusCounts) => {
			let statusCountArrays = []
			let passRow = {}
			let failRow = {}

			if (promise) {
				let statusTotalCounts = statusCounts
				if(statusTotalCounts.length) {
					Object.values(statusTotalCounts).forEach(value =>  {
						if(value.status === 'pass') {
							passRow.NA_count= value.NA_count
							passRow.duration= value.duration
							passRow.fail_count= value.fail_count
							passRow.pass_count= value.pass_count
							passRow.pending_count= value.pending_count
							passRow.reoccurred_count= value.reoccurred_count
							passRow.resolved_count= value.resolved_count
							passRow.status= value.status
						}
	
						if(value.status === 'fail') {
							failRow.NA_count= value.NA_count
							failRow.duration= value.duration
							failRow.fail_count= value.fail_count
							failRow.pass_count= value.pass_count
							failRow.pending_count= value.pending_count
							failRow.reoccurred_count= value.reoccurred_count
							failRow.resolved_count= value.resolved_count
							failRow.status= value.status
						}
					})
				}
				
				this.setState({ statusCounts })
			} else {
				this.setState({ statusCounts: [] })
			}

			if(Object.keys(passRow).length === 0) {
				passRow.NA_count= 0
				passRow.duration= this.state.selectedDuration
				passRow.fail_count= 0
				passRow.pass_count= 0
				passRow.pending_count= 0
				passRow.reoccurred_count= 0
				passRow.resolved_count= 0
				passRow.status= 'pass'
			}
			if(Object.keys(failRow).length === 0) {
				failRow.NA_count= 0
				failRow.duration= this.state.selectedDuration
				failRow.fail_count= 0
				failRow.pass_count= 0
				failRow.pending_count= 0
				failRow.reoccurred_count= 0
				failRow.resolved_count= 0
				failRow.status= 'fail'
			}

			statusCountArrays.push(passRow)
			statusCountArrays.push(failRow)

			this.setState({ statusCountArrays })

		})

		
		if(this.state.selectedAdvancedSearch && this.state.selectedAdvancedSearch.parameters && Object.entries(this.state.selectedAdvancedSearch.parameters).length) {
			params = this.state.selectedAdvancedSearch.parameters
			params.aggregate_by = 'risk'
		} else {
			params.aggregate_by = 'risk'
		}
		this.props.getTotalCount(params, (promise, riskCounts) => {
			if (promise) {
				let riskTotalCounts = riskCounts
				let riskCountArrays = []
				if(riskTotalCounts.length > 0) {
					Object.values(riskTotalCounts).forEach(value =>  {
						value.orderBy = value.risk === 'Critical' ? 1 :value.risk === 'High' ? 2 : value.risk === 'Medium' ? 3 : 4
						riskCountArrays.push(value)
					})
				}

				if(!riskCountArrays.filter(e => e.risk === 'Critical').length) {
					let dataRow = {}
					dataRow.NA_count = 0
					dataRow.duration = this.state.selectedDuration
					dataRow.fail_count = 0
					dataRow.pass_count = 0
					dataRow.pending_count = 0
					dataRow.reoccurred_count = 0
					dataRow.resolved_count = 0
					dataRow.risk = 'Critical'
					dataRow.orderBy = 1
					riskCountArrays.push(dataRow)
				}
				if(!riskCountArrays.filter(e => e.risk === 'High').length) {
					let dataRow = {}
					dataRow.NA_count = 0
					dataRow.duration = this.state.selectedDuration
					dataRow.fail_count = 0
					dataRow.pass_count = 0
					dataRow.pending_count = 0
					dataRow.reoccurred_count = 0
					dataRow.resolved_count = 0
					dataRow.risk= 'High'
					dataRow.orderBy = 2
					riskCountArrays.push(dataRow)
				}
				if(!riskCountArrays.filter(e => e.risk === 'Medium').length) {
					let dataRow = {}
					dataRow.NA_count = 0
					dataRow.duration = this.state.selectedDuration
					dataRow.fail_count = 0
					dataRow.pass_count = 0
					dataRow.pending_count = 0
					dataRow.reoccurred_count = 0
					dataRow.resolved_count = 0
					dataRow.risk= 'Medium'
					dataRow.orderBy = 3
					riskCountArrays.push(dataRow)
				}
				if(!riskCountArrays.filter(e => e.risk === 'Low').length) {
					let dataRow = {}
					dataRow.NA_count = 0
					dataRow.duration = this.state.selectedDuration
					dataRow.fail_count = 0
					dataRow.pass_count = 0
					dataRow.pending_count = 0
					dataRow.reoccurred_count = 0
					dataRow.resolved_count = 0
					dataRow.risk= 'Low'
					dataRow.orderBy = 4
					riskCountArrays.push(dataRow)
				}
				this.setState({ riskCounts, riskCountArrays: _.orderBy(riskCountArrays, ['orderBy'], ['asc']) })
			} else {
				this.setState({ riskCounts: [], riskCountArrays: [] })
			}
		})

		if (getCategory) {
			if(this.state.selectedAdvancedSearch && this.state.selectedAdvancedSearch.parameters && Object.entries(this.state.selectedAdvancedSearch.parameters).length) {
				params = this.state.selectedAdvancedSearch.parameters
				params.aggregate_by = 'category'
			} else {
				params.aggregate_by = 'category'
			}
			this.props.getTotalCount(params, (promise, categoryCounts) => {
				if (promise) {
					this.setState({ categoryCounts, tabItemsToShow: categoryCounts.length })
				} else {
					this.setState({ categoryCounts: [] })
				}
				this.setTabs()
			})
		}
		/**
		 * Get summary graph data
		 */
		//delete params['aggregate_by']
		if(this.state.selectedAdvancedSearch && this.state.selectedAdvancedSearch.parameters && Object.entries(this.state.selectedAdvancedSearch.parameters).length) {
			params = this.state.selectedAdvancedSearch.parameters
			//params.aggregate_by = 'category'
			delete params['aggregate_by']
			delete params['status']
		} else {
			delete params['aggregate_by']
			delete params['status']
		}
		
		this.props.getDayWiseCount(params, (promise, summaryGraphData) => {
			if (promise) {
				this.setState({ summaryGraphData, series: [], options: [] },
					() =>  this.dayWiseCountGraphApex()
				)	
			}
			this.setTabs()
		})
	}

	setTabs = () => {
		if (this.tabRef.current) {
			// myLog(this.tabRef.current)
			// myLog(this.tabRef.current.clientWidth)
			// myLog(this.tabRef.current.childNodes.length)
			// myLog(this.tabRef.current.childNodes)
			// myLog(this.tabRef.current.childNodes[0].clientWidth)
			// myLog(this.tabRef.current.childNodes[1].clientWidth)
			const maxTabWidth = this.tabRef.current.clientWidth - 130
			let reqTabWidth = 0
			let allowedTabLength = -1
			for (let i = 0; i < this.tabRef.current.childNodes.length; i++) {
				reqTabWidth += this.tabRef.current.childNodes[i].clientWidth
				if (reqTabWidth < maxTabWidth) {
					allowedTabLength++
				}
			}
			// myLog(maxTabWidth, reqTabWidth, allowedTabLength)
			this.setState({ tabItemsToShow: allowedTabLength })
		}
	}

	pickCustomDuration = () => {
		
	}
	/**
	 * Filter identifiers based on the filter selection
	 */
	advancedSearchBuilder = () => {
		this.setState({ showAdvancedSearchModal: false })
		let asbAccount = []
		let asbAccountArray = []
		let asbRisk = []
		let asbCategory = []

		// let asbChecksStatus = []
		// let asbIssueStatus = []
		// let asbStatus = []
		// let asbRegion = []
		// let asbServices = []
		this.state.filterValue.forEach((filter, i) =>{
			if(filter.label === 'Account') {
				asbAccount.push(filter.value)
				let dataRow = {}
				dataRow.value = filter.value
				dataRow.label = filter.valueLabel
				asbAccountArray.push(dataRow)
			}
			if(filter.label === 'Risk') {
				asbRisk.push(filter.value)
			}
			if(filter.label === 'Category') {
				asbCategory.push(filter.value)
			}
			if(filter.label === 'Duration') {
				if(filter.selectedDuration === 'custom') {
					this.setState({ start_time: filter.start_time, end_time: filter.end_time, durationType: moment(filter.start_time).format('DD MMM') + ' - ' + moment(filter.end_time).format('DD MMM'), selectedDuration: filter.selectedDuration, relatedIssueDurtaion: filter.selectedDuration })
				} else {
					this.setState({ selectedDuration: filter.selectedDuration, relatedIssueDurtaion: filter.selectedDuration })
				}
			}
		})

		this.setState({ 
			selectedAccount: asbAccount,
			selectedRisk: asbRisk,
			selectedCategory: asbCategory
		},
			() => this.filterIdentifiers()
		)
	}

	getProviderRegion = () => {
		if(this.state.selectedProvider === '') {
			let params = {
				provider: this.state.selectedTableRow.provider && this.state.selectedTableRow.provider.toLowerCase()
			}
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					this.setState({ tableRowRegions: regions[0] && regions[0].provider_regions })
				} else {
					this.setState({ tableRowRegions: [] })
				}
			})
		}
	}

	/**
	 * Method to return total fail counts
	 */
	getTotalFailCounts = () => {
		let count = 0
		if (this.state.statusCounts && this.state.statusCounts.length > 1) {
			this.state.statusCounts.forEach(item => {
				if (item.status === 'fail') {
					count = item.fail_count
				}
			})
		}
		if (this.state.statusCounts && this.state.statusCounts.length === 1) {
			this.state.statusCounts.forEach(item => {
				count = item.fail_count
			})
		}
		return count
	}
	/**
	 * Method to return total category fail counts
	 */
	getTotalCategoryFailCount = () => {
		let count = 0
		if (this.state.categoryCounts && this.state.categoryCounts.length && this.state.statusCounts.length) {
			this.state.categoryCounts.forEach(item => {
				count += item.fail_count
			})
		}
		return count
	}
	/**
	 * toggles tab list dropdown
	 */
	toggleShowTabListDropdown = () => {
		this.setState(prevState => ({
			showTabListDropdown: !prevState.showTabListDropdown,
		}))
	}
	/**
	 * toggles action dropdown
	 */
	toggleActionDropdown = () => {
		this.setState(prevState => ({
			isActionDropdownOpen: !prevState.isActionDropdownOpen,
		}))
	}
	/**
	 * toggles details tab dropdown
	 */
	toggleDetailsTabListDropdown = () => {
		this.setState(prevState => ({
			showDetailsTabListDropdown: !prevState.showDetailsTabListDropdown,
		}))
	}
	/**
	 * Handles on click tab dropdown
	 */
	onClickDropDownItem = (item, index) => {
		let tempArray = this.state.categoryCounts
		tempArray.splice(index, 1)
		tempArray.splice(this.state.tabItemsToShow - 2, 0, item)
		let selectedCategory = this.state.selectedCategory
		selectedCategory[0] = item.category
		this.setState({
			categoryCounts: tempArray,
			selectedTableTab: this.state.tabItemsToShow - 2,
			selectedCategory,
		})
		this.getAllDetectorsOnTabChange(item.category)
	}
	/**
	 * Get all detectors on change the tab
	 */
	getAllDetectorsOnTabChange = category => {
		if(category) {
			// let selectedCategory = this.state.selectedCategory
			let selectedCategory = [category]
			this.setState({ selectedCategory, selectedTableTab: 0 }, 
				() => this.filterIdentifiers()
			)
		} else {
			this.setState({ selectedCategory: [] }, 
				() => this.filterIdentifiers()
			)
		}	
	}
	/**
	 * Handles on click details tab dropdown
	 */
	onClickDetailsTabDropDownItem = (item, index) => {
		let tempArray = this.state.detailsTabNames
		tempArray.splice(index, 1)
		// tempArray.splice(0, 0, item)
		tempArray.splice(this.state.detailsTabItemsToShow - 1, 0, item)
		this.setState({ detailsTabNames: tempArray })
		this.setState({ selectedDetailsTab: this.state.detailsTabItemsToShow - 1 })
	}
	

	getRelatedTableData = (category, selectedResourceStatus, status) => {
		
		let filteredRelatedIssuesData = this.state.relatedIssuesData
		if(category !== '' && category !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.category.includes(category))
		} 
		if(selectedResourceStatus !== '' && selectedResourceStatus !== undefined) {
			filteredRelatedIssuesData = 
			filteredRelatedIssuesData.filter(item => item.resolution_status_info ? 
				item.resolution_status_info.status === selectedResourceStatus ? 
				item.resolution_status_info.status === selectedResourceStatus :
					item.resolution_status_info.status === item.resolution_status_info.sub_status ? 
					item.resolution_status_info.status === selectedResourceStatus : 
					item.resolution_status_info.sub_status === selectedResourceStatus 
			: item.status === ''
				// ? selectedResourceStatus === 'reoccured' ? 
				// 	item.status === '' : 
				// 	selectedResourceStatus === 'resolved' ? 
				// : item.status === 'pass'
			)
		}
		if(status !== '' && status !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.status === status)
		} 

		this.setState({ filteredRelatedIssuesData })
	}

	
	
    toggleCloseModal = (event) => {
        if(event.keyCode !== 27)  {
            this.setState({
                modalClose: !this.state.modalClose
            })
        }
	}

	openCloseModal = (closeRow, relatedIssueDurtaion) => {
		this.setState({
			modalClose: !this.state.modalClose, selectedRelativeIssue: closeRow, relatedIssueDurtaion, closeLoading: false
		})
	}
    
    onModalSubmit = () => {

		this.setState({ closeLoading: true, responseMessage: '', disableOnSubmit: true })
		let params = {}
		params.status = this.state.closeStatus
		params.sub_status =  this.state.closeStatusType
		params.identifiers = [{"checksum": this.state.selectedRelativeIssue.checksum,
		"identifier_id": this.state.selectedRelativeIssue.identifier_id}]
		params.resolve_mode = 'mannual'
		params.reason = 'solved manually'
		
		const data = new FormData() 
		if(this.state.closeAttachment) {
			data.append('proof', this.state.closeAttachment);
		}
		data.append('request', JSON.stringify(params));

		if(!this.state.hasErrorInRuleForm) {
			this.props.resolveIssue(data, (promise, response) => {
                if(promise) {
                    this.setState({
                        closeLoading: false,
                        closeStatusType: '',
                        closeReason: '',
                        closeAttachment: '',
                        responseMessage: response.data.message,
                        showMessage: true,
                        showLoading: false,
                        hasErrorInRuleForm: false,
                        responseStatus: response.data.status,
                    }, 
                        () => this.hideMessage()
                    )
                } else {
                    this.setState({ 
                        disableOnSubmit: false, 
                        closeLoading: false,
                        showMessage: true,
                        showLoading: false,
                        responseMessage: response.message,
                        responseStatus: response.status,
                    })
                }
			})
		}
	}

    hideMessage = () => {
        setTimeout(() => {
            this.setState({
                modalClose: !this.state.modalClose,
                showMessage: false,
            }, 
                this.getRelatedIssues(this.state.selectedTableRow, this.state.relatedIssueDurtaion)
            );
        }, 5000)
	}

	getEventInfo = asset => {
		this.setState({ showDetailsSummarySectionLoading: true })
		if(asset.event_id && asset.event_id !== '') {	
			let params = {}
			params.service_name = asset.service_name
			params.account_id = asset.account_id
			params.asset_id = asset.asset_id
			params.event_id = asset.event_id
			params.provider = asset.provider.toLowerCase()
			// if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
			// 	params.compliance = [{"complianceId":this.state.selectedComplicance}]
			// }
			this.props.getEventInfo(params, (promise, eventInfo) => {
				if (promise) {
					this.setState({
						selectedRowEventInfo: eventInfo,
						showDetailsSummarySectionLoading: false
					})
				} else {
					this.setState({
						selectedRowEventInfo: [],
						showDetailsSummarySectionLoading: false
					})
				}
			})
		} else {
			this.setState({ selectedRowEventInfo: [], showDetailsSummarySectionLoading: false })
		}
	}
	/**
	 * Get asset details of the detector
	 */
	getAssetDetails = asset => {
		this.setState({ showDetailsAssetSectionLoading: true, showSupressSectionLoading: true })
		
		let params = {}
		params.service_name = asset.service_name
		params.asset_id = asset.asset_id
		// if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
		// 	params.compliance = [{"complianceId":this.state.selectedComplicance}]
		// }

		this.props.getAssetDetails(params, (promise, assetDetails) => {
			if (!promise) {
				this.setState({
					selectedRowAssetDetails: [],
					showDetailsAssetSectionLoading: false,
					showSupressSectionLoading: false,
				})
			} else {
				this.setState({
					selectedRowAssetDetails: assetDetails,
					showDetailsAssetSectionLoading: false,
					showSupressSectionLoading: false,
				})
			}
		})
	}

	/**
	 * Get related details of the detector
	 */
	getRelatedIssues = (asset, relatedIssueDurtaion) => {
		this.setState({ showDetailsRelatedIssueLoading: true, relatedIssuesData: [], relatedIssueDurtaion })
		
		let params = {}

		params.asset_id = asset.asset_id
		if(relatedIssueDurtaion === 'custom') {
			params.start_time = this.state.start_time
			params.end_time = this.state.end_time
		} else {
			params.duration = relatedIssueDurtaion
		}
		// params.risk = asset.risk
		// params.status = asset.status
		
		// if(asset.risk !== '' && asset.risk !== 'all') {
		// 	params.risk = asset.risk
		// }
		// if(asset.status !== '' && asset.status !== 'all') {
		// 	params.status = asset.status
		// }
		// if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
		// 	params.compliance = [{"complianceId":this.state.selectedComplicance}]
		// }
		this.props.getRelatedIssues(params, (promise, relatedIssuesData) => {
			if (promise) {
				this.setState({ showLoading: false, relatedIssuesData, filteredRelatedIssuesData: relatedIssuesData })
			} else {
				this.setState({ relatedIssuesData: [], filteredRelatedIssuesData: [] })
			}
		})
		
		const countParams = {}
		countParams.asset_id = asset.asset_id
		if(relatedIssueDurtaion === 'custom') {
			countParams.start_time = this.state.start_time
			countParams.end_time = this.state.end_time
		} else {
			countParams.duration = relatedIssueDurtaion
		}
		// if(asset.risk !== '' && asset.risk !== 'all') {
		// 	countParams.risk = asset.risk
		// }
		// if(asset.status !== '' && asset.status !== 'all') {
		// 	countParams.status = asset.status
		// }
		// if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
		// 	countParams.compliance = [{"complianceId":this.state.selectedComplicance}]
		// }

		this.props.getDayWiseCount(countParams, (promise, dayWiseCountGraphData) => {
			if (promise) {
				this.setState({ dayWiseCountGraphData },
					() => this.showApexAssetRecentGraph()
				)
			}
			this.setState({ showDetailsRelatedIssueLoading: false })
		})
	}

	showApexAssetRecentGraph = () => {
		let graphLabel = []
		let createdGraphData = []
		let modifiedGraphData = []
		let fontColors = []
		let startEndLabels = []
		this.state.dayWiseCountGraphData.labels.forEach((item, x) => {
			graphLabel.push(moment(item).format('DD MMM'))
			if(x === 0 || x === (this.state.dayWiseCountGraphData.labels.length - 1)) {
				startEndLabels.push(moment(this.state.dayWiseCountGraphData.labels[x]).format('DD MMM'))
			} 

		})
		let data1 = []	
		this.state.dayWiseCountGraphData.data.pass.forEach((item, x) => {
			createdGraphData.push(item)
			let dataItems1 = {}
			dataItems1.x = moment(this.state.dayWiseCountGraphData.labels[x]).format('DD MMM')
			dataItems1.y  = item
			data1.push(dataItems1)

			fontColors.push('#EDEDED')
		})

		let data2 = []
		this.state.dayWiseCountGraphData.data.fail.forEach((item, x) => {
			modifiedGraphData.push(item)
			let dataItems2 = {}
			dataItems2.x = moment(this.state.dayWiseCountGraphData.labels[x]).format('DD MMM')
			dataItems2.y  = item
			data2.push(dataItems2)
		})

		let series = []
		let dataRow = {}
		dataRow.data = data1
		dataRow.name = 'pass'
		dataRow.labels = graphLabel
		series.push(dataRow)

		dataRow = {}
		dataRow.data = data2
		dataRow.name = 'fail'
		dataRow.labels = graphLabel
		series.push(dataRow)
		
		let options = {
			chart: {
			height: 100,
			type: 'area',
			zoom: {
				enabled: false
			},
			sparkline: {
				enabled: false
			},
			toolbar: {
				show: false,
			}
			},
			legend: {
				show: false
			},
			dataLabels: {
			enabled: false
			},
			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'butt',
				colors: undefined,
				width: 2,
				dashArray: 0,      
			},
			title: {
			text: ''
			},
			grid: {
				xaxis: {
					lines: {
						show: false
					},
				},
				yaxis: {
					lines: {
						show: false
					}
				},
			},			
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: fontColors
					},
					formatter: function(value) {
						return parseInt(value)
					}
				},
				tickAmount: 1
			},
			labels: graphLabel,
			xaxis: {
				Categories: [graphLabel],
				show: true,
				labels: {
					rotate: 0,
					show: true,
					formatter: function(value) {
						if(value === startEndLabels[0] || value === startEndLabels[1]) {
							return value
						}
					},
					style: {
						colors: fontColors
					},
					axisTicks: {
						show: false
					}
				},
				tooltip: {
					enabled: false
				},
				// min: min,
				// max: max,
			},
			colors: ['#FFD200', '#B96422'],
			// colors: ['#09D261', '#F44336'],
			// fill: {
			// 	colors: ["#CEFBFF"],
			// 	opacity: 0.7,
			// 	type: 'solid',
			// 	//opacity: 0.5,
			// },
			tooltip: {
				enabled: true,
				enabledOnSeries: false,
				shared: false,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					// let val  = series[seriesIndex][dataPointIndex];
					// //let val  = series[seriesIndex][dataPointIndex];
					// let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
					// //let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
					// //val = Math.abs(Math.round(val))
					// //let labelName = w.globals.categoryLabels[dataPointIndex]
					// //let val = str.replace('-',series[seriesIndex][dataPointIndex]);
					// return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
					// '</div>'

					let labelName = w.globals.initialSeries[0].labels[dataPointIndex]

					let returnData = '<div class="metricsDetailCurrentTooltip">'
					if(w.globals.initialSeries && w.globals.initialSeries.length) {
						w.globals.initialSeries.forEach((item, index) => {
							if(!index) {
								returnData += '<div class="apexcharts-tooltip-title f12 border-bottom-black5">'+labelName+'</div>'
							}
							returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+item.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+item.data[dataPointIndex].y+'</span></div></div></div>'
						})
					}

					returnData += '</div>'
					return returnData
				},
				fillSeriesColor: false,
				theme: false,
				style: {
				fontSize: '12px',				
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				x: {
					show: false,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: false,
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				marker: {
					show: false,
				}
			}

		}
		this.setState({ recentSeries: series, recentOptions: options });
	
	}

	/**
	 * Get billing details of the detector
	 */
	getBillingSnap = asset => {
		this.setState({ showDetailsBillingSectionLoading: true })
		let params = {}
		params.resource_id = asset.resource_id
		params.provider = asset.provider.toLowerCase()
		params.type = 'extended'
		params.current_month = true
		params.last_month = true
		params.duration = '3m'
		params.aggregate = 'daily'
		// if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
		// 	params.compliance = [{"complianceId":this.state.selectedComplicance}]
		// }
		// let params = {
		// 	provider: 'aws',
		// 	duration: '3M',
		// 	resource_id: 'i-0a757a4fa146db2b8',
		// 	type: 'extended',
		// 	aggregate: 'daily',
		// 	current_month: true,
		// 	last_month: true,
		// 	last_3_month: true,
		// }
		this.props.getBillingSnapshot(params, billingDetails => {
			this.setState({ selectedRowBillingDetails: billingDetails, showDetailsBillingSectionLoading: false })
		})
	}
	/**
	 * Get asset events
	 */
	getAssetEvents = (asset, duration) => {
		this.setState({ showDetailsEventsSectionLoading: true })
		if(asset.event_id && asset.event_id !== '') {
			const params = {}
			params.provider = asset.provider.toLowerCase()
			params.account_id = asset.account_id
			params.service_name = asset.service_name
			params.asset_id = asset.asset_id
			params.event_id = asset.event_id
			params.boundary = '1h'
			this.props.getEventByBoundary(params, (promise, eventsData) => {
				if (promise) {
					this.setState({ eventsData, showDetailsEventsSectionLoading: false })
				} else {
					this.setState({ showDetailsEventsSectionLoading: false })
				}
			})
		} else {
			this.setState({ eventsData: {}, showDetailsEventsSectionLoading: false })
		}
		
	}

	/**
	 * Get monitoring details of the detector
	 */
	getMonitoringSnap = (asset, duration) => {
		this.setState({
			showDetailsMonitoringSectionLoading: true,
			selectedRowMonitoringSnapshot: {},
			selectedMonitoringDuration: duration ? duration : "2h"
		})
		// let params = {}
		// params.provider = asset.provider.toLowerCase()
		// params.account_id = asset.account_id
		// params.region = asset.region
		// params.servicename = asset.service_name
		// params.resource_id = asset.asset_name
		// params.timestamp = asset.asset_time
		// params.aggregated_by = 'Hour'
		// params.anamoly = 2
		// params.boundary = duration ? duration : '1h'
		
		// let findHour = duration ? duration : Object.keys(this.state.selectedTableRow).length && this.state.selectedTableRow.action_date ? '1h' : '2h'		
		// let dayType = findHour[findHour.length - 1]
		// let hour = findHour.substring(0, findHour.length - 1);
		// let startDate = addSubTime(asset.action_date, 'sub', dayType, hour)
		// let endDate = addSubTime(asset.action_date, 'add', dayType, hour)

		// let params = {
		// 	provider: asset.provider.toLowerCase(),
		// 	start_date: startDate,
		// 	end_date: endDate,
		// 	resource_id: asset.resource_id,
		// 	servicename: asset.service_name,
		// 	anamoly: 2,
		// 	account_id: asset.account_id,
		// 	region: asset.region,
		// 	cache: true,
		// 	//resource_id: asset.asset_id,
		// 	// timestamp: asset.asset_time,
		// 	// aggregated_by: 'Hour',
		// 	//boundary: duration ? duration : '1h',
		// }
		// // if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
		// // 	params.compliance = [{"complianceId":this.state.selectedComplicance}]
		// // }
		
		// this.props.getMonitoringSnapShot(params, dayType, snapshot => {
		// 	if(snapshot === false) {
		// 		snapshot = []
		// 	}
		// 	this.setState({
		// 		selectedRowMonitoringSnapshot: snapshot,
		// 		showDetailsMonitoringSectionLoading: false,
		// 		showDetailsSection: true,
		// 	})
		// })
		

		let findHour = duration ? duration : '2h'
		let dayType = findHour[findHour.length - 1]
		let hour = findHour.substring(0, findHour.length - 1);
		let startTime = subDays(asset.asset_time, hour)
		// let endTime = addDays(asset.asset_time, hour)
		let endTime = asset.asset_time
		if(dayType === 'h') {
			startTime = subHours(asset.asset_time, hour)
			// endTime = addHours(asset.asset_time, hour)
		}
		let params = {}
        params.provider = asset.provider.toLowerCase()
        params.start_time = startTime
        params.end_time = endTime
        params.asset_id = asset.asset_id
        params.top = 5
    
        this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
            if(promise) {
				let obj = {}
				obj.start_time = startTime
				obj.end_time = endTime
				obj.response = response
                this.setState({ 
					selectedRowMonitoringSnapshot: obj,
					showDetailsMonitoringSectionLoading: false,
					showDetailsSection: true
				})
            }
        })
	}

	/**
	 * Get suppress details of the detector
	 */
	getAssetSupressList = asset => {
		let params = {}
		params.module_name = 'identifier'
		params.module_id = asset.asset_id
		
		// if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
		// 	params.compliance = [{"complianceId":this.state.selectedComplicance}]
		// }
		/**
		 * List of rules against each identifier
		 */
		this.props.listAllSuppressors(params, supressRules => {
			this.setState({ supressRules })
			myLog(supressRules, 'supressRules')
		})
	}

	/**
	 * Get configuration details of the asset
	 */
	getAssetConfigurationsList = asset => {
		let params = {}
		params.service_id = asset.service_id
		// if (this.state.selectedComplicance !== '' && this.state.selectedComplicance !== 'all') {
		// 	params.compliance = [{"complianceId":this.state.selectedComplicance}]
		// }
		/**
		 * List of configs against each a identifier
		 */
		this.props.listAllIdentifiers(params, configurations => {
			this.setState({ assetConfigrationsList: configurations ? configurations.results : [] })
		})
	}

	/**
	 * Handles loading toggle from details section screen
	 */
	handleLoadingToggle = (from, boolean) => {
		if (from === 'summary_details') this.setState({ showDetailsSummarySectionLoading: boolean })
		if (from === 'asset_details') this.setState({ showDetailsAssetSectionLoading: boolean })
		if (from === 'related_issues') this.setState({ showDetailsRelatedIssueLoading: boolean })
		if (from === 'events') this.setState({ showDetailsEventsSectionLoading: boolean })
		if (from === 'monitoring_details') this.setState({ showDetailsMonitoringSectionLoading: boolean })
		if (from === 'billing') this.setState({ showDetailsBillingSectionLoading: boolean })
		if (from === 'availability') this.setState({ showDetailsAvailSectionLoading: boolean })

		this.setState({ selectedTab: from })
	}

	dayWiseCountGraphApex = () => {		
		let graphData = this.state.summaryGraphData;
		//let passData = [];
		//let failData = [];
		let labels = [];
		let startEndLabels = []
		//let passSum = 0;
		//let failSum = 0;	
		let data1 = []	
		let data2 = []	
		let fontColors = []
		if(graphData){
			for(let x=0;x< graphData.labels.length;x++) {
				let dataItems1 = {}
				dataItems1.x = moment(graphData.labels[x]).format('DD MMM')
				dataItems1.y  = graphData.data.pass[x]
				data1.push(dataItems1)
				
				let dataItems2 = {}
				dataItems2.x = moment(graphData.labels[x]).format('DD MMM')
				dataItems2.y  = graphData.data.fail[x]
				data2.push(dataItems2)
				labels.push(moment(graphData.labels[x]).format('DD MMM'))
				if(x === 0 || x === (graphData.labels.length - 1)) {
					startEndLabels.push(moment(graphData.labels[x]).format('DD MMM'))
				} 
				fontColors.push('#fff')
			}		
			//passData =  graphData.data.pass;
			//failData =  graphData.data.fail;
			
			// passSum = passData.reduce(function(a, b){
			// 	return a + b;
			// }, 0);

			// failSum = failData.reduce(function(a, b){
			// 	return a + b;
			// }, 0);
		}
		// let count = -1
		let series = []
		let dataRow = {}
		dataRow.data = data1
		dataRow.name = 'pass'
		dataRow.labels = labels
		series.push(dataRow)
		dataRow = {}
		dataRow.data = data2
		dataRow.name = 'fail'
		dataRow.labels = labels
		series.push(dataRow)
		let options = {
			chart: {
			  height: 100,
			  type: 'area',
			  zoom: {
				enabled: false
			  },
			  sparkline: {
				  enabled: false
			  },
			  toolbar: {
				show: false,
			  }
			},
			legend: {
				show: false
			},
			dataLabels: {
			  enabled: false
			},
			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'butt',
				colors: undefined,
				width: 2,
				dashArray: 0,      
			},
			title: {
			  text: ''
			},
			grid: {
			  	xaxis: {
					lines: {
						show: false
					},
				},
				yaxis: {
					lines: {
						show: false
					}
				},
			},			
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: fontColors
					},
					formatter: function(value) {
						return parseInt(value)
					}
				},
				tickAmount: 2
			},
			labels: labels,
			xaxis: {
				Categories: [labels],
				show: true,
				labels: {
					rotate: 0,
					show: true,
					formatter: function(value) {
						if(value === startEndLabels[0] || value === startEndLabels[1]) {
							return value
						}
					},
					style: {
						colors: fontColors
					},
					axisTicks: {
						show: false
					}
				},
				tooltip: {
					enabled: false
				},
				// min: min,
				// max: max,
			},
			// colors: ['#09D261', '#F44336'],
			colors: ['#FFD200', '#B96422'],
			// fill: {
			// 	colors: ["#CEFBFF"],
			// 	opacity: 0.7,
			// 	type: 'solid',
			// 	//opacity: 0.5,
			// },
			tooltip: {
				enabled: true,
				enabledOnSeries: false,
				shared: false,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
					
					// let val  = series[seriesIndex][dataPointIndex];
					// let name = w.globals.seriesNames[seriesIndex]
					// return '<div class="arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'

					let returnData = '<div class="metricsDetailCurrentTooltip">'
					if(w.globals.initialSeries && w.globals.initialSeries.length) {
						w.globals.initialSeries.forEach((item, index) => {
							if(!index) {
								returnData += '<div class="apexcharts-tooltip-title f12 border-bottom-black5">'+labelName+'</div>'
							}
							returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+item.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+item.data[dataPointIndex].y+'</span></div></div></div>'
						})
					}

					returnData += '</div>'
					return returnData
				},
				fillSeriesColor: false,
				theme: false,
				style: {
				  fontSize: '12px',				
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				x: {
					show: false,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: false,
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				marker: {
					show: false,
				}
			}
		}
		
		this.setState({ series, options });
	}


	onClickAdvancedSearhBox = () => {
		if(this.state.newSearchText === '') {
			this.setState({ isLoading: true, showDropdown: true, searchResultState: this.state.onSearchResultState },
				this.getSearchMetaResultsOnLoad()
			)
		}
	}
	/**
	 * Method to get meta data
	 */
	getSearchMetaResults = () => {		
		if (this.state.keyupTimer) {
			clearTimeout(this.state.keyupTimer)
		}
		if(this.state.newSearchText.length > 2) {
			this.setState({ showDropdown: true, searchResultState: [] })
			let keyupTimer = setTimeout(() => {
				this.setState({ isLoading: true })
				this.props.getSearchMetaResults(
					{
						search_input: this.state.newSearchText,
						size: 5,
						module_name: 'identifier',
						favourites: false,
					},
					() => {
						this.setState({ searchResultState: this.props.searchNewResults, isLoading: false })
					}
				)
			}, 500)
			this.setState({
				keyupTimer,
			})
		} else if(this.state.newSearchText.length === '') {
			this.setState({ showDropdown: true, searchResultState: this.state.onSearchResultState })
		} else {
			this.setState({ showDropdown: false })
		}
		
	}

	getInitialState() {
		return {
		  from: undefined,
		  to: undefined,
		};	
	}

	handleResetClick = () => {
		this.setState(() => this.getInitialState());
	}

	handleDayClick(day) {
		if(this.state.from === undefined) {
			const range = DateUtils.addDayToRange(day, this.state);
			this.setState(
				{ 
					range, 
					from: day, 
					durationTypeFrom: moment(range.from).format('DD MMM'), 
					start_time: datestartEndTime(day, 'start') 
				}
			);
		} else {
			const newRange = {
				from: this.state.from,
				to: day
			}
			var Difference_In_Time = day.getTime() - this.state.from.getTime(); 			
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			// if(Difference_In_Days < 6) {
			// 	alert('Please select 7 days for better view')
			// 	return;
			// }
			// let durtaion = '+'+(Difference_In_Days+1)+'d';
			if(Difference_In_Days > 0) {
				const range = DateUtils.addDayToRange(day, newRange);
				this.setState(
					{ 
						range, 
						to: day,
						showDatepicker: false, 
						selectedDuration: 'custom', 
						relatedIssueDurtaion: 'custom',
						durationType: this.state.durationTypeFrom + ' - ' + moment(range.from).format('DD MMM'),
						end_time: datestartEndTime(day, 'end')
					},
					() => this.filterIdentifiers('duration')
				);
			}
		}
	}

	// openProivderMenu = () => {
	// 	this.setState({ providerIsOpen: this.state.providerIsOpen ? false : true })
	// }


	resetFilter = () => {
		this.setState(
			{
				selectedProvider: 'All',
				selectedAccount: [],
				selectedRegion: [],
				selectedService: [],
				selectedServiceContent: 'All',
				filteredServiceResourceArray: [],
				selectedChecksStatus: ['fail'],
				selectedStatusArray: [{value:'fail', label:'Fail'}],
				selectedRisk: [],
				selectedCategory: [],
				selectedDuration: '+14d',
				relatedIssueDurtaion: '+14d',
				newSearchText: '',
				selectedAdvancedSearch: {},
				selectedTableTab: -1,
			},
			() => this.filterIdentifiers()
		)
	}

	/**
	 * erers detectors Dashboard
	 */

	redirectCheckDetails = (identifier) => {
		// console.log("identifier", identifier)
		if(identifier !== '') {
			this.props.history.push({
				pathname: URL_PATH.CHECKS_DETAILS,
				state: { identifier: identifier }
			})
		}
	}

	actionFunction = () => {
		if(this.state.selectedAction === "Fix") {
			// this.redirectCheckDetails(this.state.selectedTableRow ? this.state.selectedTableRow : {})
			console.log("this.state.selectedTableRow", this.state.selectedTableRow)
			this.props.history.push({
				pathname: URL_PATH.CHECKS_REMEDIATION,
				state: { identifier: this.state.selectedTableRow }
			})
		} else if(this.state.selectedAction === "Suppress") {
			this.setState({ showSupressInDetailsSection: true, showDetailsSection: true, showCloseSection: false })
		} else if(this.state.selectedAction === "Configure") {
			this.setState({
				showSupressInDetailsSection: false,
				showConfigureSection: true,
				showDetailsSection: true,
				showCloseSection: false,
			})
		}
		
	}

	render(){
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		return (
			this.state.errorOccurred ?
				// You can render any custom fallback UI
				<Redirect to={{pathname: URL_PATH.SETTINGS}} />
			:
				<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={ (event) => { this.handleClickOutside(event) } }>
					{this.state.showAdvancedSearchModal ? (
						<AdvancedSearch
							resetForm={this.state.resetForm}
							selectedDuration={this.state.selectedDuration}
							selectedProvider={this.state.selectedProvider}
							selectedAccount={this.state.selectedAccount}
							categoryCounts={this.state.categoryCounts}
							start_time={this.state.start_time}
							end_time={this.state.end_time}
							searchFrom='checks'
							onCloseModal={() => this.setState({ showAdvancedSearchModal: false })}
							getSearchBuilderList={(filterValue) => 
								this.setState({
										filterValue
									},
									() => this.advancedSearchBuilder()
								)
							}
							getFilteredList={(adParams, searchTitle) => {
								this.setState({
									showAdvancedSearchModal: false,
									showLoading: true,
									selectedDuration: adParams.duration ? adParams.duration : this.state.selectedDuration,
									selectedChecksStatus: adParams.status ? [adParams.status] : ['fail'],
									selectedStatusArray: adParams.status ? [{value:adParams.status, label:capitalizeFirstLetter(adParams.status)}] : [{value:'fail', label:'Fail'}],
									selectedRisk: adParams.risk ? [adParams.risk] : [],
									selectedCategory: adParams.category ? adParams.category : [],
									/* selectedAccount : adParams.account_id,
									selectedService : adParams.resource_type,
									selectedRegion : adParams.region, */
									//selectedTableTab: this.state.categoryCounts.findIndex(item => item.category === adParams.category[0]),
									selectedTableTab: adParams.category ? this.state.categoryCounts.findIndex(item => item.category === adParams.category[0]) : 0,
									selectedAdvancedSearch: searchTitle
								}, 
									//() => this.getAllDetectorsOnTabChange(params.category ? params.category : params.parameters && params.parameters.category ? params.parameters.category[0] : '')
									() => this.filterIdentifiers('advancedSearch')
								)
							}}
						/>
					) : null}
					<div className='row h-100'>
						<div
							className={
								!this.state.showLoading && this.state.filteredArray && this.state.filteredArray.length && this.state.showDetailsSection 
									? 'col-sm-8 col-lg-9 p-0'
									: 'p-0 col-sm-12'
							}
						>
							<div className='title-section-new'>
								<div className='row'>
									{/* <div className='col-lg-4 d-flex align-self-center'> */}
									<div className='col-lg-4 d-flex'>
										{this.state.fromUrl !== undefined ?
											<div className='mr-3'>
												<h6 className='m-0 text-white'>
													<Link
														className='text-white far fa-arrow-left'
														to={{
															pathname:this.state.fromUrl,
															state:{ 
																selectedAccount: this.state.redirectAccount, 
																selectedAccountName: this.state.redirectAccountName, 
																selectedProvider: this.state.redirectProvider, 
																selectedDuration: this.state.redirectDuration, 
																selectedComplicance: this.state.redirectComplicance
															}
														}}
													>
													</Link>
												</h6>
											</div>
										: null
										}
										<div>
											<div className='d-flex'>
												<h6 className='text-white m-0'>Search Checks</h6>
											</div>
											<p className='text-white m-0 mb-2'>
												Consolidated view of Checks performed on your Cloud
											{/* Search Results of the checks done in your cloud. */}
											</p>
										</div>
									</div>
									<div className={`col-lg-8 text-right ${this.state.showLoading ? 'disabled' : ''}`}>
										<div className="input-group search-drop dark-feild mb-2 mr-sm-2">
											<div className="input-group-prepend">
												<div className="input-group-text bg-transparent">
													<i className="fal fa-search"></i>
												</div>
											</div>
											<input
												ref={this.searchRef}
												type='text'
												value={this.state.newSearchText}
												onChange={e => {
													this.setState({ newSearchText: e.target.value, searchResultState: [], isLoading: true }, () => this.getSearchMetaResults())
												}}
												className='form-control form-control-sm'
												placeholder='Search'
												onClick={(e => this.onClickAdvancedSearhBox())}
											/>
											
											<div className={`search-suggestion`}>
												{this.state.showDropdown && 
													this.state.searchResultState && this.state.searchResultState.length ? 
													this.state.searchResultState.map((item, index) => {
														return (
															<div key={index} className="d-flex justify-content-between cursorPointer border-bottom p-2"
																onClick ={() =>
																	this.setState({
																		showLoading: true,
																		selectedDuration: item.parameters && item.parameters.duration ? item.parameters.duration : this.state.selectedDuration,
																		selectedChecksStatus: item.parameters && item.parameters.status ? [item.parameters.status] : ['fail'],
																		selectedStatusArray: item.parameters && item.parameters.status ?  [{value: item.parameters.status, label:capitalizeFirstLetter(item.parameters.status)}] : [{value:'fail', label:'Fail'}],
																		selectedRisk: item.parameters && item.parameters.risk ? [item.parameters.risk] : [],
																		selectedCategory: item.parameters.category ? item.parameters.category : [],
																		/* selectedAccount : adParams.account_id,
																		selectedService : adParams.resource_type,
																		selectedRegion : adParams.region, */
																		//selectedTableTab: this.state.categoryCounts.findIndex(item => item.category === adParams.category[0]),
																		selectedTableTab: item.parameters && item.parameters.category ? this.state.categoryCounts.findIndex(arr => arr.category === item.parameters.category) : 0,
																		selectedAdvancedSearch: item,
																		showDropdown: false
																	}, 
																		//() => this.getAllDetectorsOnTabChange(params.category ? params.category : params.parameters && params.parameters.category ? params.parameters.category[0] : '')
																		() => this.filterIdentifiers('advancedSearch')
																	)
																}
															>
																<div className="w-70">
																	<p className='font-weight-bold m-0 text-dark text-left p-0'>{item.title ? item.title : ''}</p>
																	{/* <p className='small m-0 text-dark text-left p-0'>{item.description ? item.description : ''}</p> */}
																</div>
																<div className="w-20 align-self-center">
																	<span className="badge badge-outline-secondary">{item.parameters && item.parameters.status ? capitalizeFirstLetter(item.parameters.status) : ''}</span>
																</div>
																{/* <div className="w-20 align-self-center">
																	<span className="badge badge-outline-success mr-1">123</span>
																	<span className="badge badge-outline-danger">123</span>
																</div> */}
																<div className="w-10 align-self-center">
																	<i class={`fal fa-star ${item.favourite ? 'fillStar' : 'displayNone' } `}></i>
																</div>
															</div>
														)
													})
												: 
													this.state.showDropdown && this.state.isLoading && (
														<div className='d-flex justify-content-center m-4'>
															<Spinner className='text-center' color='dark' size='sm' />
														</div>
													) 
												}
													

												{this.state.showDropdown && this.state.searchResultState && !this.state.isLoading ?
													<div className="d-flex justify-content-between cursorPointer border-bottom p-2 bg-light">
														<p className="m-0 text-info" onClick={() =>
														this.setState({ resetForm: this.state.resetForm + 1, showAdvancedSearchModal: true, showDropdown: false, newSearchText: '' })}>
														<span className="fal fa-search mr-1"></span> Advanced Search</p>
													</div>
												: null
												}
											</div>
										</div>
										<div className='justify-content-between'>
											<button
												className='btn btn-link mr-5 p-0 fs-13'
												onClick={() => this.resetFilter() }
											>
												Reset Filter
											</button>
											<button
												className='btn btn-link p-0 fs-13'
												onClick={() =>
													this.setState({ resetForm: this.state.resetForm + 1, showAdvancedSearchModal: true })
												}
											>
												Advanced Search
											</button>
										</div>
									</div>
								</div>
								<div className='row mt-1'>
									<div className={`col-md-12 d-flex flex-wrap bd-highlight ${this.state.showLoading ? 'disabled' : ''}`}>
										<div
											className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.providerRef}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={'All'}
												menuIsOpen={this.state.providerIsOpen}
												isSearchable={false}
												//onFocus={this.openProivderMenu}
												className='f13 p-0 colorBlack reactSelectDrop'
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>	
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event =>{
													if(this.state.selectedProvider !== event.value) {
														this.setState(
															{
																selectedProvider: event.value,
																showLoading: true,
																selectedAccount: [],
																selectedRegion: [],
																selectedService: [],
																selectedServiceContent: 'All'
															},
															() => {
																this.filterIdentifiers('provider')
															}
														)
													}
												}}
											/>
										</div>
										<div
											className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.accountRef}>
											<label className="mb-1">Account</label>
											<div className={`reduceSelectedLength filterMultiSelectDropdown ${this.state.accounts && this.state.accounts.length > 10 ? '' : 'removeDropdownSearchBar'}`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
													menuIsOpen ={this.state.isAccountOpen}
													value={this.state.selectedAccount.map(acc => ({
														value: acc
													}))}
													onChange={arr => {
													this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
													options={this.state.accounts && this.state.accounts.map(account => ({
														value: account.account_id,
														label: account.account_name,
													}))}
												/>
											</div>
										</div>

										<div
											className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers
												.length}`} ref={this.regionRef}>
											<label className="mb-1">Region</label>
											<div className={`filterMultiSelectDropdown ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
													menuIsOpen ={this.state.isRegionOpen}
													value={this.state.selectedRegion.map(reg => ({
														value: reg
													}))}
													onChange={arr => {
													this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
													options={this.state.regions && this.state.regions.map(region => ({
														value: region.region,
														label: region.name,
													}))}
												/>
											</div>
										</div>
										<div
											className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 text-dark serviceFilterDropdown`} ref={this.serviceRef}>
											<label className="mb-2 text-white w-100">Service</label>
											{/* <label className="text-white w-100 f13 font-weight-normal" onClick={() => this.setState({ showDropdownTree: true }) } >{this.state.selectedService && this.state.selectedService.length ? this.state.selectedService.length ===  1 ? this.state.selectedService : this.state.selectedService.length +' Selected' : 'All' }</label> */}
											<div className={`serviceTreeDropdown position-absolute mt-n1 ${this.state.filteredServiceResourceArray.length ?'': 'disabled'}`}>
												<DropdownTreeSelect 
													texts={{ placeholder: this.state.selectedServiceContent }}
													data={this.state.filteredServiceResourceArray}
													onChange={this.onChangeServiceDropdown}
													className="bootstrap-demo"
													keepTreeOnSearch={true}
													keepChildrenOnSearch={true}
												/>
											</div>
											{/* onChange={this.onChangeDropdownTreeSelect()} 
													onNodeToggle={event => this.onNodeToggleDropdownTreeSelect(event)}
											onNodeToggle={this.onNodeToggleDropdownTreeSelect()} */}

											{/* <CheckboxTree
												nodes={this.state.serviceResourceArray}
												checked={this.state.checked}
												expanded={this.state.expanded}
												onCheck={checked => this.setState({ checked })}
												onExpand={expanded => this.setState({ expanded })}
											/> */}
										</div>
										<div
											className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.checksStatusRef}>
											<label className="mb-1">Status</label>
											<div className={`filterMultiSelectDropdown removeDropdownSearchBar`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="Select"
													getDropdownButtonLabel={() => this.getMultiSelectedCount('status', this.state.selectedChecksStatus)}
													menuIsOpen ={this.state.isChecksStatusOpen}
													value={this.state.selectedChecksStatus.map(item => ({
														value: item
													}))}
													onChange={arr => {
													this.handleMultiSelectChange('selectedChecksStatus', arr ? arr : []) }}
													options={checksStatus.map(status => ({
														value: status.value,
														label: status.label,
													}))}
												/>
											</div>
										</div>
										<div
											className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.riskRef}>
											<label className="mb-1">Risk</label>
											<div className={`filterMultiSelectDropdown removeDropdownSearchBar`}>
												<ReactMultiSelectCheckboxes						
													placeholderButtonLabel="All"
													getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedRisk', this.state.selectedRisk)}
													menuIsOpen ={this.state.isRiskOpen}
													value={this.state.selectedRisk.map(risk => ({
														value: risk
													}))}
													onChange={arr => {
													this.handleMultiSelectChange('selectedRisk', arr ? arr : []) }}
													options={riskDropdown.map(risk => ({
														value: risk.value,
														label: risk.label,
													}))}
												/>
											</div>
										</div>
										<div className='form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild' ref={this.durationRef}>
											<label className="w-100">Duration
												{this.state.durationType !== '' ? <small className="pl-3">{this.state.durationType}</small> : ''}
											</label>
											<select
												className='form-control'
												value={this.state.selectedDuration}
												onChange={event => {
													if(event.target.value !== 'custom') {
														this.setState(
															{
																durationType: '',
																selectedDuration: event.target.value,
																relatedIssueDurtaion: event.target.value,
																showDatepicker: false,
															},
															() => {
																this.filterIdentifiers('duration')
																this.handleResetClick()
															}
														)
													} else {
														this.setState({
															showDatepicker: true
														})
													}
												}}
												onClick={event => {
													if(event.target.value === 'custom') {
														this.setState({
															showDatepicker: true
														})
													}
												}}
											>
												{CUSTOM_DURATION_FILTER_OPTIONS.map((item, index) => {
													return (
														<option key={index} value={item.value}>
															{item.option}
														</option>
													)
												})}
											</select>
										</div>
									</div>
								</div>
								<div className={`RangeExample customDayPicker ${this.state.showDatepicker ? '' : 'displayNone'}`} ref={this.dayPickerRef}>
									<DayPicker
									toMonth={new Date()}
									disabledDays={[
										{
											after: new Date(),
											before: (new Date(this.state.previousCustomDays))
										},
									]}
									className="Selectable"
									numberOfMonths= {3}																			
									selectedDays={[from, { from, to }]}
									modifiers={modifiers}
									onDayClick={this.handleDayClick}
									tabIndex={0}
									/>								
									<div class="col-sm-12">
										<button class="resetBtn f15 float-right mb-1" onClick={this.handleResetClick}>Reset</button>
									</div>
								</div>
								<div className='row mt-2'>
									<div className='col-lg-3 col-md-6'>
										{this.state.statusCounts && this.state.statusCounts.length && this.state.riskCountArrays.length ? <p className='text-white m-0'>Risk Count</p> : null}
										<div className='d-flex bd-highlight flex-wrap'>
											{this.state.statusCounts.length && this.state.riskCountArrays.length
												? this.state.riskCountArrays.map((item, index) => {
													return (
													<div className='pr-2 mb-1 w-50' key={index}>
														<div className='bd-highlight d-flex w-100'>
															<small className='text-white bd-highlight flex-grow-1'>{item.risk}</small>
															<small className='text-white bd-highlight ml-auto'>{item.fail_count}</small>
														</div>
														<div className='progress'>
															<div
																className={`risk-bg-${item.risk ? item.risk.toLowerCase() : ''} progress-bar`}
																role='progressbar'
																style={{ width: `${(item.fail_count ? item.fail_count / this.getTotalFailCounts() : 0) * 100}%` }}
																aria-valuenow={item.fail_count}
																aria-valuemin={item.fail_count}
																aria-valuemax={this.getTotalFailCounts()}
															/>
														</div>
													</div>
													)
												})
												: null}
										</div>
									</div>
									<div className='col-lg-4 col-md-6 align-self-center'>
										{this.state.statusCounts && this.state.statusCounts.length ? (
											<React.Fragment>
												<div className='mt-1 d-flex'>
													<div className='count border-warning ml-3 mr-3 text-nowrap'>
														{this.state.statusCounts.length > 1 &&
															this.state.statusCounts.map((item, i) => {
																if (item.status === 'pass')
																	return (
																		<h4 key={i} className='m-0'>
																			{item.pass_count}
																		</h4>
																	)
																return null
															})}
														{this.state.statusCounts.length === 1 &&
															this.state.statusCounts.map((item, i) => {
																return (
																	<h4 key={i} className='m-0'>
																		{item.pass_count}
																	</h4>
																)
															})}
														<p className='m-0'>Checks Passed</p>
													</div>
													<div className='count border-color-dark-theme-orange text-nowrap'>
														{this.state.statusCounts.length > 1 &&
															this.state.statusCounts.map((item, i) => {
																if (item.status === 'fail')
																	return (
																		<h4 key={i} className='m-0'>
																			{item.fail_count}
																		</h4>
																	)
																return null
															})}
														{this.state.statusCounts.length === 1 &&
															this.state.statusCounts.map((item, i) => {
																return (
																	<h4 key={i} className='m-0'>
																		{item.fail_count}
																	</h4>
																)
															})}
														<p className='m-0'>Checks Failed</p>
													</div>
												</div>
											</React.Fragment>
										) : null}
									</div>
									<div className={`col-lg-5 transparentTooltip ${this.state.statusCounts.length ? '' : 'displayNone'}`}>
										{!this.state.showLoading && this.state.series && this.state.series.length ? (
											<div className='mt-n4 mb-n4'>
												<ApexChart type="area" series={this.state.series} options={this.state.options} height={120}/>
											</div>
										) : null}
									</div>
									
									{/* <div className={`col-md-3 ${this.state.statusCounts.length ? '' : 'displayNone'} align-self-center`}>
										<p className='text-white m-0'>
											Officia amet eiusmod eu sunt tempor voluptate laboris velit mpor voluptate laboris velit nisi amet
											enim
										</p>
									</div> */}
								</div>
							</div>

							<div className='box custom-margin bg-dark3'>
								{this.state.categoryCounts && this.state.categoryCounts.length ? (
									<div>
										{this.getTotalCategoryFailCount() !== 0 ?
											<ul
												ref={this.tabRef}
												className={`nav nav-tabs border-bottom bg-dark3 ${this.state.showLoading ? 'disabled' : ''}`}
												id='myTab'
												role='tablist'
											>
												<li className='nav-item'>
													<Link
														className={`nav-link custom-link text-white ${this.state.selectedTableTab === -1 ? 'active' : ''}`}
														id='home-tab'
														data-toggle='tab'
														// to=''
														/* onClick={() => {
															const params = {
																duration: this.state.selectedDuration,
															}
															let selectedCategory = this.state.selectedCategory
															selectedCategory[0] = 'all'
															this.setState({ selectedCategory })
															this.setState({ showLoading: true })
															this.getAllCounts(true)
															this.props.listAllDetectors(params, () => {
																if (this.props.detectors && this.props.detectors.length) {
																	this.setState({
																		showLoading: false,
																		filteredArray: this.props.detectors,
																		selectedTableRow: this.props.detectors[0],
																		showDetailsSection: true,
																	})
																	this.setTabs()
																}
															})
															this.setState({ selectedTableTab: -1 })
														}} */
														onClick={() => this.getAllDetectorsOnTabChange()}
														role='tab'
														aria-selected='true'
													>
														All
														<span className='badge badge-outline-light d-table m-auto font-weight-bold'>{this.getTotalCategoryFailCount()}</span>
													</Link>
												</li>
												{this.state.categoryCounts &&
													this.state.categoryCounts.length &&
													this.getTotalCategoryFailCount() !== 0 &&
													this.state.categoryCounts.map((item, index) => {
														if (index < this.state.tabItemsToShow && item.fail_count !== 0)
															return (
																<li
																	className='nav-item'
																	key={index}
																	onClick={() => this.getAllDetectorsOnTabChange(item.category)}
																>
																	<Link
																		className={`nav-link custom-link text-white ${
																			this.state.selectedTableTab === index ? 'active' : ''
																			}`}
																		id='profile-tab'
																		data-toggle='tab'
																		// to=''
																		onClick={() => this.setState({ selectedTableTab: index })}
																		role='tab'
																		aria-selected='false'
																	>
																		{item.category}
																		<span className='badge badge-outline-light d-table m-auto font-weight-bold'>{item.fail_count}</span>
																	</Link>
																</li>
															)
														return null
													})}
												{/* <li className="align-self-center"> 
													{this.state.selectedCategory.length ?
														<span className={`ml-2 textInfo ${this.state.showLoading ? 'disabled' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: [], selectedTableTab: -1 }, () => this.filterIdentifiers() )}><i className='far fa-redo'></i><small className="ml-2">Reset</small></span>
													:
														<small>&nbsp;</small>
													}
												</li> */}
												<li
													className={`nav-item ml-auto bd-highlight align-self-center ${
														this.state.categoryCounts.length > this.state.tabItemsToShow ? '' : 'd-none'
														}`}
												>
													<ButtonDropdown
														isOpen={this.state.showTabListDropdown}
														toggle={() => this.toggleShowTabListDropdown()}
													>
														<DropdownToggle
															tag='a'
															className={`nav-link custom-link ${
																!this.state.categoryCounts.length && this.getTotalCategoryFailCount() === 0 ? 'd-none' : ''
																}`}
														>
															<i className='fal fa-angle-double-right cursor-pointer' />
														</DropdownToggle>
														<DropdownMenu
															right
															modifiers={{
																setMaxHeight: {
																	enabled: true,
																	order: 890,
																	fn: data => {
																		return {
																			...data,
																			styles: {
																				...data.styles,
																				overflow: 'auto',
																				maxHeight: 300,
																			},
																		}
																	},
																},
															}}
														>
															{this.state.categoryCounts &&
																this.getTotalCategoryFailCount() !== 0 &&
																this.state.categoryCounts.map((item, index) => {
																	if (index >= this.state.tabItemsToShow && item.fail_count !== 0)
																		return (
																			<DropdownItem key={index} onClick={() => this.onClickDropDownItem(item, index)}>
																				{item.category}
																			</DropdownItem>
																		)
																	return null
																})}
														</DropdownMenu>
													</ButtonDropdown>
												</li>
											</ul>
										: null }
									</div>
								) : null}

								{this.props.detectors && this.props.detectors.length && !this.state.showLoading ? (
									<div className="container-fluid">
										<div className="row mt-2">
											<div className="col-sm-7 align-self-center">
												{this.state.selectedAdvancedSearch && Object.entries(this.state.selectedAdvancedSearch).length !== '' ? 
													<React.Fragment>
														<p className='font-weight-bold mb-0'>{this.state.selectedAdvancedSearch.title}</p>
														{/* <p className='mb-0 small'>{this.state.selectedAdvancedSearch.description}</p> */}
													</React.Fragment>
												: null
												}
											</div>
											{this.props.detectors && this.props.detectors.length ?
												<div className={`d-flex col-sm-5 justify-content-end`}>
													<CSVLink 
														data={this.state.filteredArray} 
														headers={this.state.headers ? this.state.headers : []}
														filename={"checks-search.csv"}
														className={"align-self-center mr-2"}
														target="_blank"
													>
														<i className="fas fa-download text-primary-color"></i>
														{/* <span className="text-primary-color mx-2">csv</span> */}
													</CSVLink>
													<Search
														data={this.props.detectors && this.props.detectors}
														// applyTags={false}
														// applyLiteDarkTags={true}
														filteredData={filteredArray => {
															if (filteredArray.length) {
																this.setState({ filteredArray, selectedTableRow: filteredArray[0], selectedTab: 'summary_details' })
															} else {
																this.setState({ filteredArray: [] })
															}
														}}
														// ref={this.searchComponent}
														// searchText={this.state.searchText}
														// className='form-control-sm bg-transparent textboxBorder'
														// topClassName={'bg-gray1'}
														// searchClassName={'textboxBorder displayNone'}
														// searchIconColor={'text-dark'}
													/>
												</div>
											: null}
											{/* <div class="col-sm-1"><a href="" class="btn btn-sm btn-link float-right">Export
												<i class="fas fa-download ml-1"></i>
											</a></div> */}
										</div>
									</div>
								)
								: null
								}
								<div className='tab-content px-3 py-1' id='myTabContent'>								
									<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
										{this.state.filteredArray && this.state.filteredArray.length && !this.state.showLoading ? (
											<ResizeableDarkThemeTable
												columns={[
													{
														Header: 'Risk',
														accessor: 'risk',
														Cell: cellInfo => (
															<span className={`badge risk-badge-${
																cellInfo.row.original.risk ?
																	cellInfo.row.original.risk.toLowerCase()
																: ''
																} aiopsTableBadgeWidth`}
															>
																{cellInfo.row.original.risk ?
																	(cellInfo.row.original.risk.toLowerCase() === 'critical' ? 
																		'C' 
																	: cellInfo.row.original.risk.toLowerCase() === 'high' ? 
																		'H'
																	: cellInfo.row.original.risk.toLowerCase() === 'medium' ? 
																		'M'
																	: cellInfo.row.original.risk.toLowerCase() === 'low' ? 
																		'L' 
																	: '') 
																: 'U'}
															</span>
														),
														width: 80
													},
													// {
													// 	Header: ' ',
													// 	accessor: 'risk',
													// 	Cell: cellInfo => (
													// 		<div className={`risk-line risk-bg-${cellInfo.row.original.risk ? cellInfo.row.original.risk.toLowerCase() : ''}`} />
													// 	),
													// 	width: 10
													// },
													{
														Header: 'Date',
														// accessor: 'original.resolution_status_info.timestamp',
														//accessor: request => displayDate(request.requestTime),
														accessor: 'timestamp',
														Cell: cellInfo => (
															<span>
																{cellInfo.row.original.status && cellInfo.row.original.status !== 'pass' ? cellInfo.row.original.resolution_status_info && cellInfo.row.original.resolution_status_info.timestamp && momentDateTime(cellInfo.row.original.resolution_status_info.timestamp) : momentDateTime(cellInfo.row.original.timestamp)}
															</span>
														),
														width: 180
													},
													{
														Header: 'Provider / Service',
														accessor: d =>
															d.provider + ' : ' + getAccountNameFromId(d.account_id, this.state.accounts) + ' : ' + d.region,
														Cell: cellInfo => (
															<React.Fragment>
															<span className=''>
																{cellInfo.row.original.provider + ' : ' + getAccountNameFromId(cellInfo.row.original.account_id, this.state.accounts) + ' : ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region) + ' / '+ cellInfo.row.original.service_name}
															</span>
															</React.Fragment>
														),
														width: 250
													},
													{
														Header: 'Resource',
														accessor: 'resource_id',
														width: 200
													},
													{
														Header: 'Status',
														accessor: 'status',
														Cell: cellInfo => (
															<span
																className={`badge status-badge badge-outline-${
																	cellInfo.row.original.status === 'pass' ? 'pass' : 'fail'
																	} badge-pill`}
															>
																{capitalizeFirstLetter(cellInfo.row.original.status)}
															</span>
														),
														width: 100,
													},
													{
														Header: 'Check',
														accessor: 'title',
														width: 230
													},
													{
														Header: 'Category',
														accessor: d =>
															Array.isArray(d.category) ? 
																d.category.map((item, index) => (d.category.length - 1 === index ? item : item + ', '))
															: d.category,
														width: 200
													},
													{
														Header: ' ',
														accessor: ' ',
														width:1
													}
												]}
												data={this.state.filteredArray}
												onClickRow={tableRow => {
													let diffrence = '';
													if(tableRow && tableRow.status ==='fail') {
														let a = moment(moment().format('M/D/YYYY'), 'M/D/YYYY')
														let b = moment([tableRow.resolution_status_info && moment(tableRow.resolution_status_info.timestamp).format('M/D/YYYY')], 'M/D/YYYY')
														diffrence = a.diff(b, 'days');
													}
													this.setState({
														selectedTableRow: tableRow,
														selectedMonitoringDuration: '2h',
														daysFromCreated: diffrence,
														selectedDetailsTab: 0,
														selectedTab: 'summary_details',
														relatedIssueDurtaion: this.state.selectedDuration
													}, 
														() => this.getProviderRegion()
													)
													this.getAssetDetails(tableRow)
													this.getAssetSupressList(tableRow)
												}}
												selectedRecord={this.state.selectedTableRow}
												perPage={20}
												dashboard={this.state.filteredArray && this.state.filteredArray.length ? true : false}
												sortByColumn={"timestamp"}
												tooltipFor={[2,3,5]}
												riskTooltip={[0]}
												tableBody={"bg-dark"}
												rounded={true}
												// selectedColumn={'bg-light'}
												// columnFontSize={'f12'}
											/>
										) : (!this.state.showLoading && this.state.filteredArray.length) ||
											(this.state.filteredArray && !this.state.filteredArray.length && !this.state.showLoading) ? (
												<div className='d-flex justify-content-center m-4'>
													<p>There are no data on this criteria. Please try adjusting your filter.</p>
												</div>
											) 
										: null}
										{this.state.showLoading ? (
											<div className='d-flex justify-content-center m-4'>
												<Spinner className='text-center' color='white' size='lg' />
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
						{!this.state.showLoading && this.state.filteredArray && this.state.filteredArray.length ?
							<div className='col-lg-3 col-sm-4 p-0 bg-dark3'>
								{this.state.showConfigureSection ? (
									<ConfigureSection
										data={this.state.assetConfigrationsList}
										onClickBackButton={() => this.setState({ showConfigureSection: false, showDetailsSection: true, selectedAction: "" })}
									/>
								) : null}

								<div
									className={
										this.state.filteredArray &&
											this.state.filteredArray.length &&
											Object.keys(this.state.selectedTableRow).length &&
											!this.state.showConfigureSection
											? 'detail'
											: 'd-none'
									}
								>
									<div className='detail-title bg-dark3'>
										<div className='clearfix'>
											<div className="col-sm-12 m-0 p-0 mb-2 float-left">
												<div className='col-sm-12 dropdown d-flex justify-content-end m-0 p-0'>
													<div className="select-sm-bg curvedDropdown minWidth120" toggle={() => this.toggleActionDropdown()}>
														<div className="dropdown">
															<span className="dropdown-toggle d-flex justify-content-between px-2 text-white cursorPointer" onClick={() => this.setState({ isActionDropdownOpen: !this.state.isActionDropdownOpen })}>
																{this.state.selectedAction && this.state.selectedAction !== "" ? this.state.selectedAction : <span className="placeholder">Action</span>}
																<span className="caret"></span>
																{/* <i className="fas fa-angle-down"></i> */}
															</span>
															<ul className={`dropdown-menu cursorPointer p-0 ${this.state.isActionDropdownOpen ? "show" : ''}`}>
																{actionOptions.map(item => {
																	return (
																		<li 
																			onClick={() => this.setState({ 
																				selectedAction: item, 
																				isActionDropdownOpen: false
																			},
																				() => {
																					this.actionFunction()
																				}
																			)}
																			className={`${this.state.selectedAction === item ? "selected" :  ""}`}
																		>
																			{item}
																		</li>
																	)
																})}
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div className='dropdown mb-2 displayNone'>
												<ButtonDropdown isOpen={this.state.isActionDropdownOpen} toggle={() => this.toggleActionDropdown()} />
											</div>
										</div>
										<div className='d-xl-flex'>
											<div
												className={`risk-bg-${this.state.selectedTableRow.risk ? this.state.selectedTableRow.risk.toLowerCase() : ''} risk-status d-flex flex-column mr-3 justify-content-end`}
											>
												{this.state.selectedTableRow.risk}
											</div>
											<div className='align-self-center text-white'>
												<table>
													<tbody>
														<tr>
															<td className='text-right pr-2 align-top'>{this.state.selectedTableRow.provider ? this.state.selectedTableRow.provider : ''}&nbsp;: </td>
															<td>
																{this.state.selectedTableRow.region === 'NA' ? 'Global' : 
																this.state.tableRowRegions && this.state.tableRowRegions.length
																? 
																	this.state.tableRowRegions.filter(region => region.region === this.state.selectedTableRow.region).length
																	? 
																		this.state.tableRowRegions.filter(region => region.region === this.state.selectedTableRow.region)[0].name
																	: this.state.selectedTableRow.region
																: this.state.selectedTableRow.region}
															</td>
														</tr>
														<tr>
															<td className='text-right pr-2 align-top'>Account&nbsp;: </td>
															<td>
																{this.state.accounts && this.state.accounts.length && Object.keys(this.state.selectedTableRow).length
																	? getAccountNameFromId(this.state.selectedTableRow.account_id, this.state.accounts)
																	: '-'}
															</td>
														</tr>
														<tr>
															<td className='text-right pr-2 align-top'>Date&nbsp;: </td>
															<td> {this.state.selectedTableRow && this.state.selectedTableRow.resolution_status_info && this.state.selectedTableRow.resolution_status_info.status ?
																this.state.selectedTableRow.resolution_status_info.status.toLowerCase() !== "resolved" ?
																	this.state.selectedTableRow.resolution_status_info.timestamp ? momentFullDateTime(this.state.selectedTableRow.resolution_status_info.timestamp) : ""
																:
																	this.state.selectedTableRow.resolution_status_info.end_time ? momentFullDateTime(this.state.selectedTableRow.resolution_status_info.end_time) : ""
															: null}
															</td>
														</tr>
													</tbody>
												</table>
											</div>	
											<span className={`badge d-block align-self-start ${this.state.selectedTableRow.status && this.state.selectedTableRow.status === 'fail' ? 'badge-outline-fail' : 'badge-outline-pass'}`}>
												{this.state.selectedTableRow.status !== undefined && capitalizeFirstLetter(this.state.selectedTableRow.status)}
											</span>									
										</div>
										<span className='badge badge-pill badge-secondary p-2 mt-2 text-wrap text-white'>
											{this.state.selectedTableRow &&this.state.selectedTableRow.service_id +' '}
											{this.state.selectedTableRow && this.state.selectedTableRow.resolution_status_info &&
												this.state.selectedTableRow.resolution_status_info.status === 'resolved' ? 
													'' 
												: 
													this.state.daysFromCreated !== '' ? 
														this.state.daysFromCreated + ' days old ' 
													: 
														momentDateTime(this.state.selectedTableRow.timestamp)}
											{this.state.selectedTableRow && this.state.selectedTableRow.resolution_status_info ?
												this.state.selectedTableRow.resolution_status_info.status !== 'resolved' ? 
													'('+capitalizeFirstLetter(this.state.selectedTableRow.resolution_status_info.status)+')'
												:
													'('+capitalizeFirstLetter(this.state.selectedTableRow.resolution_status_info.status)+')' 
											: ''}
										</span>
										<p className='mt-2 mb-0'>
											{/* <small className='text-white mt-2'>
												{this.state.selectedTableRow.status === 'pass'
													? this.state.selectedTableRow.description
													: this.state.selectedTableRow.detail}
											</small>
											<small className='text-white mt-2'>{this.state.selectedTableRow.additional_details}</small> */}
											<small className='text-white mt-2'>
												{this.state.selectedTableRow && this.state.selectedTableRow.description ? this.state.selectedTableRow.description : ''}
											</small>
										</p>
									</div>
									<div className='detail-content bg-dark3'>
										{/* View Configuration */}
										{this.state.showSupressInDetailsSection ? (
											<SupressSection
												onClickSupressBackButton={() => {
													this.setState({
														showSupressInDetailsSection: false,
														showDetailsSection: true,
														onClickSupressCreateButton: false,
														selectedAction: ""
														// selectedDetailsTab: 0,
													})
												}}
												supressRules={this.state.supressRules}
												showLoading={this.state.showSupressSectionLoading}
												providersData={this.state.providers}
												selectedRowAssetDetails={this.state.selectedRowAssetDetails}
												showSupressForm={this.state.onClickSupressCreateButton}
												onClickSupressCreateButton={bool => this.setState({ onClickSupressCreateButton: bool })}
											/>
										) : null}

										{this.state.showCloseSection ? (
											<CloseForm
												selectedStatus={this.state.selectedChecksStatus}
												selectedRisk={this.state.selectedRisk}
												selectedDuration={this.state.selectedDuration}
												selectedTableRow={this.state.selectedTableRow}
												//selectedTableRow =  {this.state.selectedTableRow && this.state.selectedTableRow[0]}
												onClickCloseBackButton={() => {
													this.setState({
														showCloseSection: false, 
														showDetailsSection: true
													})
												}}
											/>
										) : null}

										{this.state.showDetailsSection ? (
											<DetailsSection
												selectedTableRow={this.state.selectedTableRow}
												detailsTabNames={this.state.detailsTabNames}
												detailsTabItemsToShow={this.state.detailsTabItemsToShow}
												showDetailsTabListDropdown={this.state.showDetailsTabListDropdown}
												selectedDetailsTab={this.state.selectedDetailsTab}
												selectedRowAssetDetails={this.state.selectedRowAssetDetails}
												selectedRowMonitoringSnapshot={this.state.selectedRowMonitoringSnapshot}
												selectedRowBillingDetails={this.state.selectedRowBillingDetails}
												selectedTab={this.state.selectedTab}
												showDetailsSummarySectionLoading={this.state.showDetailsSummarySectionLoading}
												showDetailsAssetSectionLoading={this.state.showDetailsAssetSectionLoading}
												showDetailsAvailSectionLoading={this.state.showDetailsAvailSectionLoading}
												showDetailsRelatedIssueLoading={this.state.showDetailsRelatedIssueLoading}
												showDetailsBillingSectionLoading={this.state.showDetailsBillingSectionLoading}
												showDetailsMonitoringSectionLoading={this.state.showDetailsMonitoringSectionLoading}
												showDetailsEventsSectionLoading={this.state.showDetailsEventsSectionLoading}
												toggleDetailsSectionLoading={(from, bool) => this.handleLoadingToggle(from, bool)}
												getEventInfo={item => this.getEventInfo(item)}
												selectedRowEventInfo={this.state.selectedRowEventInfo}
												getAssetDetails={item => this.getAssetDetails(item)}
												openCloseModal={(item, relatedIssueDurtaion) => this.openCloseModal(item, relatedIssueDurtaion)}
												getRelatedIssues={(asset, relatedIssueDurtaion) => this.getRelatedIssues(asset, relatedIssueDurtaion)}
												getBillingSnap={item => this.getBillingSnap(item)}
												getAssetEvents={(item, duration) => this.getAssetEvents(item, duration)}
												getMonitoringSnap={(item, duration) => this.getMonitoringSnap(item, duration)}
												dayWiseCountGraphData={this.state.dayWiseCountGraphData}
												recentSeries = {this.state.recentSeries}
												recentOptions = {this.state.recentOptions}
												toggleDetailsTabListDropdown={() => this.toggleDetailsTabListDropdown()}
												onClickDetailsTabDropDownItem={(item, index) => this.onClickDetailsTabDropDownItem(item, index)}
												setSelectedDetailsTabIndex={index => this.setState({ selectedDetailsTab: index })}
												relatedIssuesData={this.state.relatedIssuesData}
												filteredRelatedIssuesData={this.state.filteredRelatedIssuesData}
												getRelatedTableData={(category, selectedResourceStatus, status) => this.getRelatedTableData(category, selectedResourceStatus, status)}
												selectedDuration={this.state.selectedDuration}
												start_time={this.state.start_time ? this.state.start_time: ''}
												end_time={this.state.end_time ? this.state.end_time: ''}
												relatedIssueDurtaion={this.state.relatedIssueDurtaion}
												durationType={this.state.durationType}
												selectedMonitoringDuration={this.state.selectedMonitoringDuration}
												categoryCounts={this.state.categoryCounts}
												eventsData={this.state.eventsData}
											/>
										) : null}
									</div>
								</div>
							</div>
						: null }
					</div>
					
					<React.Fragment>
						<Modal className="closeIssue" backdrop="static" isOpen={this.state.modalClose} toggle={this.toggleCloseModal}>
							<ModalHeader toggle={this.toggleCloseModal}>  
								{this.state.modalHeader} 
							</ModalHeader>
							<ModalBody>
								<Form>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label>Status</Label>
												<Input
													disabled={true}
													type='select'
													className="form-control-small"
													value={this.state.closeStatus}
													onChange={event => {
														this.setState(
															{
																closeStatus: event.target.value
															}
														)
													}}
												>
													<option value='resolved'>Resolved</option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label>Status Type</Label>
												<Input
													type='select'
													className="form-control-small"
													value={this.state.closeStatusType}
													onChange={event => {
														this.setState(
															{
																closeStatusType: event.target.value
															}
														)
													}}
												>
													<option value=''>Select</option>
													<option value='resolved_verified'>Resolved & Verified</option>
													<option value='not_issue'>Not an Issue</option>
													<option value='duplicate'>Duplicate</option>
												</Input>
												{this.state.hasErrorInRuleForm && this.state.selectedStatusType === '' ? (
													<p className='text-danger'>Status Type is required.</p>
												) : null}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={12}>
											<FormGroup>
												<Label>Reason</Label>
												<textarea className="w-100 borderGray" value={this.state.closeReason} onChange={event => this.setState({ closeReason: event.target.value })} rows="2" placeholder='Reason for closing'>
													
												</textarea>
												{this.state.hasErrorInRuleForm && this.state.closeReason === '' ? (
													<p className='text-danger'>Reason is required.</p>
												) : null}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={12}>
											<FormGroup>
												<Label>Proof <span className={`f11 resetBtn ${this.state.closeAttachment !== '' ? 'cursorPointer' : 'displayNone' }`} onClick={()=> this.setState({ closeAttachmentKey: true, closeAttachment: '', hasErrorInRuleForm: false })}>Clear </span></Label>
												<Input
													type='file'
													//accept="image/jpg, image/png"
													className="form-control-sm"
													id="closeAttachmentId"
													key={this.state.closeAttachmentKey &&	this.state.closeAttachment}
													onChange={event => { this.fileChangedHandler(event) }}
												/>
												{this.state.hasErrorInRuleForm && this.state.closeAttachment === '' ? (
													<p className='text-danger f11'>{this.state.attachmentMessage}</p>
												) : (
													''
												)}
											</FormGroup>
										</Col>
										{/* <Col md={2}>
											<button className={`f11 rfes 'info cursorPointer' : 'displayNone'} `} onClick={()=> this.setState({ closeAttachmentKey: true, closeAttachment: '' })}>Clear </button>
										</Col> */}
									</Row>
								</Form>
							</ModalBody>
							<ModalFooter className="col-sm-12 m-0 p-0">
								<div className="col-sm-5 m-0 p-0">
									{this.state.showMessage ?
										<p className={`m-0 p-0 align-self-center ${this.state.responseStatus ? 'text-success' : 'text-danger' }`}>{this.state.responseMessage}</p> 
									: ''}
								</div>
								<div className={`col-sm-6 float-right text-right ${this.state.disableOnSubmit ? 'disabled' : ''}`}>
									<button className='btn btn-sm btn-light mr-3' onClick={this.toggleCloseModal}>
										Back
									</button>
									<button
										className={`btn btn-sm btn-primary ${this.state.closeLoading ? 'disabled' : ''}`}
										onClick={() => this.onModalSubmit()}
										>
										{this.state.closeLoading ? (
											<React.Fragment>
												{'submitting'}
												<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
											</React.Fragment>
										) : 'Submit'
										}
									</button>
								</div>
							</ModalFooter>
						</Modal>
						
					</React.Fragment>
				</div>

		)
		
	}
}

/**
 * Type of the props used in the component
 */
ChecksSearch.propTypes = {
	// listAllProviders: PropTypes.func,
	// listAllAccounts: PropTypes.func,
	// listAllRegions: PropTypes.func,
	// listAllServices: PropTypes.func,
	listAllDetectors: PropTypes.func,
	getTotalCount: PropTypes.func,
	getDayWiseCount: PropTypes.func,
	getMonitoringSnapShot: PropTypes.func,
	listAllSuppressors: PropTypes.func,
	listAllIdentifiers: PropTypes.func,
	getEventInfo: PropTypes.func,
	getAssetDetails: PropTypes.func,
	getBillingSnapshot: PropTypes.func,
	getRelatedIssues: PropTypes.func,
	detectors: PropTypes.array,
	location: PropTypes.object,
	getEventByBoundary: PropTypes.func,
	getSearchMetaResults: PropTypes.func,
	searchNewResults: PropTypes.array,
	resolveIssue: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		detectors: state.detectors.detectors.results,
		searchNewResults: state.filters.searchMetaList,
	}
}

export default AppWrapper(ChecksSearch, mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllServices,
	listAllRegions,
	listAllDetectors,
	getAssetDetails,
	getEventInfo,
	getBillingSnapshot,
	getRelatedIssues,
	getMonitoringSnapShot,
	listAllSuppressors,
	listAllIdentifiers,
	getTotalCount,
	getDayWiseCount,
	getEventByBoundary,
	getSearchMetaResults,
	resolveIssue,
	getMonitoringMetricsByTrend
})




