
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Notify.js
 * @author Prakash // on 14/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Input } from 'reactstrap'
import { setAutomationPropsDetails } from '../../actions/commonAction'
import { capitalizeFirstLetter } from '../../utils/utility';

const channelType = ["slack", "email"]
const messageOption = ["Json", "Template"]

class Notify extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeSection: this.props.activeSection,
            messageArray: [0],
            channelArray: [0]

        }
    }

    componentDidMount = () => {
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let exist = obj.actions.filter(e => e.section === this.state.activeSection)
            if(exist.length) {
                let input = exist[0].input ? exist[0].input : []
                let getMessageOption = input.filter(e => e.key === "messaging_option")
                let message_option = ""
                if(getMessageOption.length) {
                    message_option = getMessageOption[0].value
                }
                this.setState({ messaging_option: message_option },
                    () => {
                        input.forEach(item => {
                            if(item.key === "message" && message_option !== "Template") {
                                let messageArray = []
                                item.value_object.key.forEach((objKey, i) => {
                                    messageArray.push(i)
                                })
                                if(!messageArray.length) {
                                    messageArray = [0]
                                }
                                this.setState({ messageArray },
                                    () => {
                                        item.value_object.key.forEach((objKey, i) => {
                                            let dynamic = false
                                            if(item.value_object.value[i].dynamic) {
                                                dynamic = true
                                            }
                                            this.setState({
                                                ["message_key_"+i]: item.value_object.key[i],
                                                ["message_value_"+i]: item.value_object.value[i].value,
                                                ["dynamic_message_value_"+i]: dynamic
                                            },
                                                () => {
                                                    this.onLoadDropFields("message_value", item.value_object.value[i].value, i)
                                                    this.checkAllDataFilled()
                                                }
                                            )
                                        })                                        
                                    }
                                )                                
                            } else if(item.key === "notification_distribution") {
                                let channelArray = []
                                item.value_object_list.forEach((list, i) => {
                                    channelArray.push(i)
                                    list.forEach(lst => {
                                        this.setState({
                                            [lst.key+"_"+i]: lst.value
                                        })
                                    })
                                })
                                this.setState({ channelArray },
                                    () => this.checkAllDataFilled()    
                                )
                            } else if((item.key === "subject_template" || item.key === "message_template" || item.key === "multiplyMessages") && message_option === "Template") {
                                this.setState({ [item.key]: item.value },
                                    () => {
                                        this.checkAllDataFilled()
                                        this.onLoadDropFields(item.key, item.value, "")
                                    }
                                )
                            } else {
                                this.setState({ [item.key]: item.value },
                                    () => {
                                        this.checkAllDataFilled()
                                    }
                                )
                            }
                        }) 
                    }
                )               
            }

            let actions = this.props.automationPropsDetails.actions ? this.props.automationPropsDetails.actions : []
            let previousAutomations = actions.filter(e => e.section < this.state.activeSection)

            this.setState({ previousAutomations, previousAutomationsSearchResult: previousAutomations })
        }
    }

    componentDidUpdate = (prevProps) => {}

    onActionSelect = (action) => {
        this.setState({ 
            selectedAction: action, 
            showActionResults: false 
        },
            // () => this.formInputProps(["action_"+this.state.activeSection], "action", this.state.selectedAction)
        )
    }

    formInputProps = (section, field, value) => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        let objIndex = actions.findIndex((e => e.section === section))
        let dataRow = {}
        if(field === "message_key" || field === "message_value") {
            field = "message"
        } 
        if(field === "message" && this.state.messaging_option && this.state.messaging_option === "Json") {
            let key = []
            let value = []
            this.state.messageArray.forEach(item => {
                if(this.state["message_key_"+item] && this.state["message_key_"+item] !== "" && this.state["message_value_"+item] && this.state["message_value_"+item] !== "") {
                    key.push(this.state["message_key_"+item])
                    let valueRow = {}
                    valueRow.value = this.state["message_value_"+item]
                    if(this.state["dynamic_"+item]) {
                        valueRow.dynamic = true
                    }
                    value.push(valueRow)
                }
            })
            let messageRow = {}
            messageRow.key = key
            messageRow.value = value            

            dataRow.key = field
            dataRow.value_object = messageRow
        } else if((field === "subject_template" || field === "message_template" || field === "multiplyMessages") && this.state.messaging_option && this.state.messaging_option === "Template") {
            dataRow = {}
            dataRow.value = this.state[field]
            dataRow.dynamic = this.state["dynamic_"+field] ? true : false
            dataRow.key = field
        } else if(field === 'channel_type' || field === 'channel_id') {
            let arrayList = []
            this.state.channelArray.forEach(item => {
                let dataArray = [
                    {key: "channel_type", value: this.state["channel_type_"+item] ?  this.state["channel_type_"+item] : ""},
                    {key: "channel_id", value: this.state["channel_id_"+item] ?  this.state["channel_id_"+item] : ""}
                ]

                arrayList.push(dataArray)
            })
            field = "notification_distribution"
            dataRow.key = 'notification_distribution'
            dataRow.value_object_list = arrayList
            
        } else {
            dataRow.key = field
            dataRow.value = value
        }

        let input = actions[objIndex].input ? actions[objIndex].input : []
        let inputIndex = input.findIndex((e => e.key === field))
        if(inputIndex > -1) {
            input[inputIndex] = dataRow
        } else {
            input.push(dataRow)
        }
        actions[objIndex].input = input
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)

        this.checkAllDataFilled()        
    }

    checkAllDataFilled = () => {
        
        let hasError = false
        // let errorMessage = ""
        if(!this.state.messaging_option || this.state.messaging_option === "") {
            hasError = true
        }
        if(this.state.messaging_option === "Json") {
            this.state.messageArray.forEach(item => {
                if(!this.state["message_key_"+item] || this.state["message_key_"+item] === "" || !this.state["message_value_"+item] || this.state["message_value_"+item] === "") {
                    hasError = true
                    // errorMessage = "Please fill minimum one key value"
                }
            })
        }

        if(!hasError) {
            this.state.channelArray.forEach(item => {
                if(!this.state["channel_id_"+item] || this.state["channel_id_"+item] === "" || !this.state["channel_type_"+item] || this.state["channel_type_"+item] === "") {
                    // hasError = true
                    // errorMessage = "Please fill minimum channel details"
                }
            })
        }

        // && this.state.attachments && this.state.attachments !== ""
        if(!hasError && this.state.messageArray && this.state.messageArray.length && this.state.channelArray && this.state.channelArray.length) {
            this.props.actionCompleted(true)
        } else {
            this.props.actionCompleted(false)
        }
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList },
            // () => this.checkAllDataFilled()    
        )
    }

    removeMessage = (item, i) => {
        this.setState({ ["message_key"+item]: "", ["message_value_"+item]: "" },
            () => {
                let rowList = this.state.messageArray;
                rowList.splice(i, 1);
                this.setState({ messageArray: rowList })
            }
        );
    }

    removeChannel = (item, i) => {
        this.setState({ ["channel_type_"+item]: "", ["channel_id_"+item]: "" },
            () => {
                let rowList = this.state.channelArray;
                rowList.splice(i, 1);
                this.setState({ channelArray: rowList })
            }
        );
    }

    checkEnteredCharacter = (e, val, field, index) => {
        // console.log(e.keyCode)
        // var editable = document.querySelectorAll('div[contentEditable]');
        let className = field+"_"+this.state.activeSection
        let fieldState = field
        if(index !== "") {
            fieldState = field+"_"+index
            className = field+"_"+this.state.activeSection+"_"+index
        }
        let innerHtml = document.getElementsByClassName(className)[0].innerHTML
        innerHtml = innerHtml.replaceAll('<span class="text-white">&nbsp;</span>', "")
        innerHtml = innerHtml.replaceAll('<span class="text-info">', "")
        innerHtml = innerHtml.replaceAll('<span class="text-white">', "")
        innerHtml = innerHtml.replaceAll('</span>', "")

        var re = /<div>(.*?)<\/div>/;
        var result = innerHtml.split(re);        
        let stringToSave = ""
        if(result.length) {
            let visit = 0
            result.forEach((item, rIndex) => {
                if(item !== "") {
                    
                    let br = ""
                    // if(rIndex && visit) {
                    //     br = "/n"
                    // }
                    // if(result.length - 1 !== rIndex) {
                    //     br = "/n"
                    // }
                    if(item === "<br>") {
                        item = "/n"
                    } else if(rIndex && visit) {
                        br = "/n"
                    }
                    stringToSave += br+""+item
                    visit++;
                }
            })
        }

        let position = stringToSave.indexOf("$$");
        if(position >= 0) {
            this.setState({ ["show_"+field+"_drop_option_"+index]: true, [fieldState]: stringToSave })
        } else {
            this.setState({ ["show_"+field+"_drop_option_"+index]: false, [fieldState]: stringToSave },
                () => this.checkFieldDynamic(field, index)
            )
        }
        this.setState({ [fieldState]: stringToSave },
            () => {
                this.formInputProps(this.state.activeSection, field, this.state.messageArray)
            }
        )
    }

    selectedpreviousAutomation = (field, index, actionDetails, childIndex, childDetails) => {
        this.state.previousAutomations.forEach((item, i) => {
            this.setState({ ["showChild_"+i]: false })
        })

        //if there is child it will be confiigured when real data is available for child section
        // this.setState({ ["showChild_"+childIndex]: !this.state["showChild_"+childIndex] })

        //if there is no child
        this.selectedField(field, index, actionDetails, childIndex, childDetails)
    }

    selectedField = (field, index, actionDetails, childIndex, childDetails) => {
        let className = field+"_"+this.state.activeSection
        let fieldState = field
        if(index !== "") {
            fieldState = field+"_"+index
            className = field+"_"+this.state.activeSection+"_"+index
        }

        let stringToSave = this.state[fieldState]
        let res = ""
        if(actionDetails !== "") {
            res = "${"+actionDetails.name+".}"
            if(childDetails !== "") {
                res = "${"+actionDetails.name+"."+childDetails.name+"}"
            }
            stringToSave = stringToSave.replace("$$", res)
        }
        //form html from the string to show in the contenteditable        
        let innertHtmlData = ""
        let splitString = stringToSave.split("/n")
        let re = /{(.*?)}/;
        splitString.forEach(str => {
            innertHtmlData += "<div>"
            if(str !== "") {
                let result = str.split(re);
                let previousdata = ""
                result.forEach((item, i) => {
                    let lastTwoLetters = item.substring(item.length, item.length-2)
                    let lastLetter = item.charAt(item.length-1)
                    if(previousdata === "$") {
                        innertHtmlData += '<span class="text-info">${'+item+'}</span> <span class="text-white">&nbsp;</span>'
                    } else if(item !== "$") {
                        if(lastLetter === '$' && item !== "$$" && lastTwoLetters !== "$$") {
                            item = item.substring(0, item.length - 1)
                        }
                        innertHtmlData += '<span class="text-white">'+item+'</span>'
                    }
    
                    previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                })
            } else {
                innertHtmlData += "<br>"    
            }

            innertHtmlData += "</div>"
        })

        // let position = stringToSave.indexOf(res)
        // console.log(stringToSave, position + res.length)

        document.getElementsByClassName(className)[0].innerHTML = innertHtmlData

        this.setState({ ["show_"+field+"_drop_option_"+index]: false, [fieldState]: stringToSave },
            () => {
                this.formInputProps(this.state.activeSection, field, this.state.messageArray)
                this.checkFieldDynamic(field, index)
                document.getElementById(className).focus();
            }
        )
    }

    checkFieldDynamic = (field, index) => {
        let dynamic = false
        let fieldState = field
        let dynamicField = "dynamic_"+field 
        if(index !== "") {            
            fieldState = field+"_"+index
            dynamicField = "dynamic_"+field+"_"+index
        }

        let stringToSave = this.state[fieldState]
        //form html from the string to show in the contenteditable
        let splitString = stringToSave.split("/n")
        let re = /{(.*?)}/;
        splitString.forEach(str => {
            if(str !== "") {
                let result = str.split(re);
                let previousdata = ""
                result.forEach((item, i) => {
                    let lastTwoLetters = item.substring(item.length, item.length-2)
                    let lastLetter = item.charAt(item.length-1)
                    if(previousdata === "$") {
                        dynamic = true
                    }
    
                    previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                })
            }
        })
        this.setState({ [dynamicField]: dynamic },
            () => this.formInputProps(this.state.activeSection, field, this.state.messageArray)    
        )
    }

    onLoadDropFields = (key, value, index) => {
        let className = key+"_"+this.state.activeSection
        if(index !== "") {
            className = key+"_"+this.state.activeSection+"_"+index
        }

        let stringToSave = value ? value : ""

        let removeHtml = stringToSave.replace(/<\/?[^>]+(>|$)/g, ""); //remove the html tag

        stringToSave = removeHtml
        
        //form html from the string to show in the contenteditable        
        if(stringToSave && stringToSave !== "") {
            let innertHtmlData = ""
            let splitString = stringToSave.split("/n")
            let re = /{(.*?)}/;
            splitString.forEach(str => {
                innertHtmlData += "<div>"
                if(str !== "") {
                    let result = str.split(re);
                    let previousdata = ""
                    result.forEach((item, i) => {
                        let lastTwoLetters = item.substring(item.length, item.length-2)
                        let lastLetter = item.charAt(item.length-1)
                        if(previousdata === "$") {
                            innertHtmlData += '<span class="text-info">${'+item+'}</span> <span class="text-white">&nbsp;</span>'
                        } else if(item !== "$") {
                            if(lastLetter === '$' && item !== "$$" && lastTwoLetters !== "$$") {
                                item = item.substring(0, item.length - 1)
                            }
                            innertHtmlData += '<span class="text-white">'+item+'</span>'
                        }

                        previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                    })
                } else {
                    innertHtmlData += "<br>"
                }
                innertHtmlData += "</div>"
            })
            // if(document.getElementsByClassName(className) && document.getElementsByClassName(className)[0]  && document.getElementsByClassName(className)[0].innerHTML) {
                document.getElementsByClassName(className)[0].innerHTML = innertHtmlData
            // }
        }
        // this.setState({ [fieldState]: stringToSave })

        // this.formInputProps(this.state.activeSection, field, stringToSave)
    }

    onChangeMessageFormat = () => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        let objIndex = actions.findIndex((e => e.section === this.state.activeSection))
        let input = actions[objIndex].input ? actions[objIndex].input : []

        let dataRow = {}
        dataRow.key = "messaging_option"
        dataRow.value = this.state.messaging_option

        let inputIndex = input.findIndex((e => e.key === "messaging_option"))
        if(inputIndex > -1) {
            input[inputIndex] = dataRow
        } else {
            input.push(dataRow)
        }
        
        if(this.state.messaging_option && this.state.messaging_option === "Json") {
            input = input.filter(e => e.key !== "multiplyMessages" && e.key !== "message_template" && e.key !== "subject_template")
            let key = []
            let value = []
            this.state.messageArray.forEach(item => {
                if(this.state["message_key_"+item] && this.state["message_key_"+item] !== "" && this.state["message_value_"+item] && this.state["message_value_"+item] !== "") {
                    key.push(this.state["message_key_"+item])
                    let valueRow = {}
                    valueRow.value = this.state["message_value_"+item]
                    if(this.state["dynamic_"+item]) {
                        valueRow.dynamic = true
                    }
                    value.push(valueRow)
                    if(this.state["message_value_"+item] && this.state["message_value_"+item] !== "") {
                        this.onLoadDropFields("message_value", this.state["message_value_"+item], item)
                    }
                }
            })
            let messageRow = {}
            messageRow.key = key
            messageRow.value = value

            let dataRow = {}
            dataRow.key = "message"
            dataRow.value_object = messageRow

            let inputIndex = input.findIndex((e => e.key === "message"))
            if(inputIndex > -1) {
                input[inputIndex] = dataRow
            } else {
                input.push(dataRow)
            }
        } else if(this.state.messaging_option && this.state.messaging_option === "Template") {
            input = input.filter(e => e.key !== "message")
            let dataRow = {}
            dataRow.value = this.state.subject_template
            dataRow.dynamic = this.state["dynamic_subject_template"] ? true : false
            dataRow.key = "subject_template"
            // this.selectedField("subject_template", "", "", "", "")
            if(this.state.subject_template && this.state.subject_template !== "") {
                this.onLoadDropFields(dataRow.key, dataRow.value, "")
            }

            let inputIndex = input.findIndex((e => e.key === "subject_template"))
            if(inputIndex > -1) {
                input[inputIndex] = dataRow
            } else {
                input.push(dataRow)
            }

            dataRow = {}
            dataRow.value = this.state.message_template
            dataRow.dynamic = this.state["dynamic_message_template"] ? true : false
            dataRow.key = "message_template"
            // this.selectedField("message_template", "", "", "", "")
            if(this.state.message_template && this.state.message_template !== "") {
                this.onLoadDropFields(dataRow.key, dataRow.value, "")
            }

            inputIndex = input.findIndex((e => e.key === "message_template"))
            if(inputIndex > -1) {
                input[inputIndex] = dataRow
            } else {
                input.push(dataRow)
            }

            
            dataRow = {}
            dataRow.value = this.state.multiplyMessages
            dataRow.dynamic = this.state["dynamic_multiplyMessages"] ? true : false
            dataRow.key = "multiplyMessages"
            if(this.state.multiplyMessages && this.state.multiplyMessages !== "") {
                this.onLoadDropFields(dataRow.key, dataRow.value, "")
            }

            inputIndex = input.findIndex((e => e.key === "multiplyMessages"))
            if(inputIndex > -1) {
                input[inputIndex] = dataRow
            } else {
                input.push(dataRow)
            }
        }   

        actions[objIndex].input = input
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)
    }

    render() {
        return (
            <div className="">
                <div className="d-flex mt-3">
                    <div className="w-50">
                        <label className="mb-1">Messaging Format</label>
                        <Select 
                            options={messageOption.map(item => ({
                                value: item,
                                label: item,
                            }))}
                            value={({
                                value: this.state.messaging_option && this.state.messaging_option,
                                label: this.state.messaging_option ? this.state.messaging_option : <span className="placeholder">Select</span>
                            })}
                            onChange={event => 
                                this.setState({ messaging_option: event.value },
                                    () => {
                                        this.onChangeMessageFormat()                                        
                                    }
                                )
                            }
                        />
                    </div>
                </div>
                {this.state.messaging_option && this.state.messaging_option === "Template" ? 
                    <React.Fragment>
                    <div className="mt-3">
                        <div className="d-flex">
                            <div className="position-relative d-inline-block w-100">
                                <label className="mb-1">Subject Template</label>
                                <div contenteditable="true" 
                                    className={`form-control inputTextbox contentEditable h-auto f14 minHeight75px subject_template_${this.state.activeSection}`}
                                    placeholder="subject template..."
                                    id={`subject_template_${this.state.activeSection}`}
                                    onKeyUp={e => this.checkEnteredCharacter(e, e.currentTarget.textContent, "subject_template", "")}
                                    // onInput={e => this.setState({ subject_template: e.currentTarget.textContent })}
                                    // onPaste={e => this.selectedField("subject_template", "", "", "", "")}
                                    >
                                </div>
                                {this.state["show_subject_template_drop_option_"] && this.state.previousAutomations && this.state.previousAutomations.length ? 
                                    <div className={`dropdownOptions topAuto mt-2`}>
                                        {this.state.previousAutomations.map((prevAct, j) => {
                                            return (
                                                <React.Fragment>
                                                    <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("subject_template", "", prevAct, j, "")}>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <div>
                                                                <p className="mb-0 text-left">{prevAct.name}</p>
                                                                <p className="mb-0 small description">{prevAct.action_name}</p>
                                                            </div>
                                                            {/* if there is child it will be confiigured when real data is available for child section */}
                                                            {/* {this.state["showChild_"+j] ? 
                                                                <i className="fas fa-angle-up mr-2 f18"></i>
                                                            :
                                                                <i className="fas fa-angle-down mr-2 f18"></i>
                                                            } */}
                                                            
                                                        </div>
                                                    </button>
                                                    {/* {this.state["showChild_"+j] ? 
                                                        this.state.previousAutomations.map(child => {
                                                            return(
                                                            <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("multiply_with", index, prevAct, j, child)}>
                                                                <p className="mb-0 f12 label">{child.action_name}</p>
                                                            </button>
                                                            )
                                                        })
                                                    : null} */}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="d-flex">
                            <div className="position-relative d-inline-block w-100">
                                <label className="mb-1">Message Template</label>
                                <div contenteditable="true" 
                                    className={`form-control inputTextbox contentEditable h-auto f14 minHeight75px message_template_${this.state.activeSection}`}
                                    placeholder="message template..."
                                    id={`message_template_${this.state.activeSection}`}
                                    onKeyUp={e => this.checkEnteredCharacter(e, e.currentTarget.textContent, "message_template", "")}
                                    // onInput={e => this.setState({ message_template: e.currentTarget.textContent })}
                                    // onPaste={e => this.selectedField("message_template", "", "", "", "")}
                                    >
                                </div>
                                {this.state["show_message_template_drop_option_"] && this.state.previousAutomations && this.state.previousAutomations.length ? 
                                    <div className={`dropdownOptions topAuto mt-2`}>
                                        {this.state.previousAutomations.map((prevAct, j) => {
                                            return (
                                                <React.Fragment>
                                                    <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("message_template", "", prevAct, j, "")}>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <div>
                                                                <p className="mb-0 text-left">{prevAct.name}</p>
                                                                <p className="mb-0 small description">{prevAct.action_name}</p>
                                                            </div>
                                                            {/* if there is child it will be confiigured when real data is available for child section */}
                                                            {/* {this.state["showChild_"+j] ? 
                                                                <i className="fas fa-angle-up mr-2 f18"></i>
                                                            :
                                                                <i className="fas fa-angle-down mr-2 f18"></i>
                                                            } */}
                                                            
                                                        </div>
                                                    </button>
                                                    {/* {this.state["showChild_"+j] ? 
                                                        this.state.previousAutomations.map(child => {
                                                            return(
                                                            <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("multiply_with", index, prevAct, j, child)}>
                                                                <p className="mb-0 f12 label">{child.action_name}</p>
                                                            </button>
                                                            )
                                                        })
                                                    : null} */}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="d-flex">
                            <div className="position-relative d-inline-block w-100">
                                <label className="mb-1">Multiply messages</label>
                                <div contenteditable="true" 
                                    className={`form-control inputTextbox contentEditable h-auto f14 minHeight75px multiplyMessages_${this.state.activeSection}`}
                                    placeholder="multiply messages..."
                                    id={`multiplyMessages_${this.state.activeSection}`}
                                    onKeyUp={e => this.checkEnteredCharacter(e, e.currentTarget.textContent, "multiplyMessages", "")}
                                    // onInput={e => this.setState({ multiplyMessages: e.currentTarget.textContent })}
                                    onPaste={e => this.selectedField("multiplyMessages", "", "", "", "")}
                                    >
                                </div>
                                {this.state["show_multiplyMessages_drop_option_"] && this.state.previousAutomations && this.state.previousAutomations.length ? 
                                    <div className={`dropdownOptions topAuto mt-2`}>
                                        {this.state.previousAutomations.map((prevAct, j) => {
                                            return (
                                                <React.Fragment>
                                                    <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("multiplyMessages", "", prevAct, j, "")}>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <div>
                                                                <p className="mb-0 text-left">{prevAct.name}</p>
                                                                <p className="mb-0 small description">{prevAct.action_name}</p>
                                                            </div>
                                                            {/* if there is child it will be confiigured when real data is available for child section */}
                                                            {/* {this.state["showChild_"+j] ? 
                                                                <i className="fas fa-angle-up mr-2 f18"></i>
                                                            :
                                                                <i className="fas fa-angle-down mr-2 f18"></i>
                                                            } */}
                                                            
                                                        </div>
                                                    </button>
                                                    {/* {this.state["showChild_"+j] ? 
                                                        this.state.previousAutomations.map(child => {
                                                            return(
                                                            <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("multiplyMessages", index, prevAct, j, child)}>
                                                                <p className="mb-0 f12 label">{child.action_name}</p>
                                                            </button>
                                                            )
                                                        })
                                                    : null} */}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    </React.Fragment>
                : this.state.messaging_option && this.state.messaging_option === "Json" ? 
                    <div className="d-flex mt-3">
                        <div className="w-100">
                            <label className="mb-1">Message to send in notification.Enter as json</label>
                            {this.state.messageArray.map((index, i) => {
                                return(
                                    <div className="w-100">
                                        <div className={`d-flex flex-wrap ${!i ? "" : "mt-3"}`}>
                                            <Input
                                                type="text"
                                                placeholder="key"
                                                className={`inputTextbox w-30 mr-3 ${this.state.hasError && (!this.state["message_key_"+index] || this.state["message_key_"+index] === "") ? "border-danger" : ""}`}
                                                value={this.state["message_key_"+index] ? this.state["message_key_"+index] : ""}
                                                onChange={e => this.setState({ ["message_key_"+index]: e.target.value },
                                                    () => this.formInputProps(this.state.activeSection, "message_key", this.state.messageArray)
                                                )}
                                            />
                                            <div className="position-relative d-inline-block w-50">
                                                <div className="position-relative d-inline-block w-100">
                                                    <div contenteditable="true" 
                                                        className={`form-control inputTextbox contentEditable h-auto f14 message_value_${this.state.activeSection}_${index} ${this.state.hasError && (!this.state["message_value_"+index] || this.state["message_key_"+index] === "") ? "border-danger" : ""}`}
                                                        placeholder="value..."
                                                        id={`message_value_${this.state.activeSection}_${index}`}
                                                        onKeyUp={e => this.checkEnteredCharacter(e, e.currentTarget.textContent, "message_value", index)}
                                                        // onInput={e => this.setState({ ["message_value_state_"+index]: e.currentTarget.textContent })}
                                                    >
                                                    </div>
                                                    {this.state["show_message_value_drop_option_"+index] && this.state.previousAutomations && this.state.previousAutomations.length ? 
                                                        <div className={`dropdownOptions topAuto mt-2`}>
                                                            {this.state.previousAutomations.map((prevAct, j) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("message_value", index, prevAct, j, "")}>
                                                                            <div className="d-flex justify-content-between w-100">
                                                                                <div>
                                                                                    <p className="mb-0 text-left">{prevAct.name}</p>
                                                                                    <p className="mb-0 small description">{prevAct.action_name}</p>
                                                                                </div>
                                                                                {/* if there is child it will be confiigured when real data is available for child section */}
                                                                                {/* {this.state["showChild_"+j] ? 
                                                                                    <i className="fas fa-angle-up mr-2 f18"></i>
                                                                                :
                                                                                    <i className="fas fa-angle-down mr-2 f18"></i>
                                                                                } */}
                                                                                
                                                                            </div>
                                                                        </button>
                                                                        {/* {this.state["showChild_"+j] ? 
                                                                            this.state.previousAutomations.map(child => {
                                                                                return(
                                                                                <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("template_data", index, prevAct, j, child)}>
                                                                                    <p className="mb-0 f12 label">{child.action_name}</p>
                                                                                </button>
                                                                                )
                                                                            })
                                                                        : null} */}
                                                                    </React.Fragment>
                                                                )   
                                                            })}
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                            
                                            {this.state.messageArray.length > 1 ?
                                                <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeMessage(index, i)}></span>
                                            : null}
                                            {(this.state.messageArray.length - 1) ===  i ?
                                                <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("messageArray")}></span>
                                            : null}
                                        </div>
                                    </div>
                                )
                            })}
                            
                            {this.state.hasError && this.state.errorMessage !== "" ?
                                <p className="m-0 text-danger align-self-end mb-1">{this.state.errorMessage}</p>
                            : null}
                        </div>
                    </div>
                : null}
                <div className="d-flex mt-3">
                    <div className="w-50">
                        <label className="mb-1">Attachment</label>
                        <div className="col-sm-12 p-0">
                            <Input
                                type="text"
                                placeholder="attachment"
                                className="inputTextbox"
                                value={this.state.attachments}
                                id={"attachments"}
                                onChange={event =>
                                    this.setState({ attachments: event.target.value },
                                        () => {
                                            this.formInputProps(this.state.activeSection, "attachments", this.state.attachments)
                                        }
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <label className="mb-1 text-white">Destinations</label>
                    {this.state.channelArray.map((index, i) => {
                        return(
                            <div className="w-100">
                                <div className={`d-flex flex-wrap ${!i ? "" : "mt-3"}`}>
                                    <div className="w-45">
                                        <label className="mb-1">Channel Type</label>
                                        <Select 
                                            options={channelType.map(item => ({
                                                value: item,
                                                label: capitalizeFirstLetter(item),
                                            }))}
                                            value={({
                                                value: this.state['channel_type_'+index] && this.state['channel_type_'+index],
                                                label: this.state['channel_type_'+index] ? capitalizeFirstLetter(this.state['channel_type_'+index]) : <span className="placeholder">Select channel type</span>
                                            })}
                                            onChange={event => 
                                                this.setState({ ['channel_type_'+index]: event.value },
                                                    () => {
                                                        this.formInputProps(this.state.activeSection, "channel_type", this.state.channelArray)
                                                    }
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="pl-3 w-45">
                                        <label className="mb-1">Channel Id</label>                        
                                        <Input
                                            type="text"
                                            placeholder="channel id"
                                            className="inputTextbox"
                                            value={this.state['channel_id_'+index]}
                                            onChange={event => 
                                                this.setState({ ['channel_id_'+index]: event.target.value },
                                                    () => this.formInputProps(this.state.activeSection, "channel_id", this.state.channelArray)
                                                )
                                            }
                                        />
                                    </div>
                                    
                                    {this.state.channelArray.length > 1 ?
                                        <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4 mt-4`} onClick={() => this.removeChannel(index, i)}></span>
                                    : null}
                                    {(this.state.channelArray.length - 1) ===  i ?
                                        <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4 mt-4`} onClick={() => this.addSection("channelArray")}></span>
                                    : null}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
Notify.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("Notify",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
})(withRouter(Notify))        