/*************************************************
 * Tvastar
 * @exports
 * @file ApexTrendStackedBarChart.js
 * @author Prakash // on 07/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { momentConvertionUtcToLocalTime, momentDateGivenFormat } from '../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexTrendStackedBarChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphDetails = this.props.graphData;
        if(graphDetails){
            let graphLabels = graphDetails.labels
            let graphData = graphDetails.data
            let colors = this.props.colors ? this.props.colors : ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
            let totalCount = 0
            let series = []
            if(graphData) {
                let dataArray = []
                graphData.forEach((val, index) => {
                    let dataItems = {}
                    let time = momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                    dataItems.x = time
                    dataItems.y = val
                    dataArray.push(dataItems)
                })

                if(graphDetails.start_time && graphDetails.start_time !== "" && !graphLabels.includes(momentDateGivenFormat(graphDetails.start_time, 'YYYY-MM-DD HH:mm:00'))) {
                    let dataItems = {}
                    dataItems.x = momentConvertionUtcToLocalTime(graphDetails.start_time, 'YYYY-MM-DD HH:mm:00')
                    dataItems.y = 0 
                    dataArray.push(dataItems) //to initialize the start time in the graph
                }
                
                if(graphDetails.end_time && graphDetails.end_time !== "" && !graphLabels.includes(momentDateGivenFormat(graphDetails.end_time, 'YYYY-MM-DD HH:mm:00'))) {
                    let dataItems = {}
                    dataItems.x = momentConvertionUtcToLocalTime(graphDetails.end_time, 'YYYY-MM-DD HH:mm:00')
                    dataItems.y = 0
                    dataArray.push(dataItems) //to initialize the end time in the graph
                }

                let seriesRow = {}
                seriesRow.name = ""
                seriesRow.data = dataArray
                
                series = [seriesRow]
            }
            
            // let backgroundColors = {}
            // if(this.props.showBackgroundBarColors) {
            //     backgroundColors = {
            //         ranges: [{
            //             from: 1,
            //             to: 0.9,
            //             color: 'red'
            //         }],
            //         // backgroundBarColors: this.props.backgroundBarColors ? this.props.backgroundBarColors : ['#EDEDED'],
            //         backgroundBarOpacity: 0,
            //         // backgroundBarRadius: this.props.backgroundBarShape && this.props.backgroundBarShape === 'rounded' ? (this.props.barRadius ? this.props.barRadius : 10) : 0,
            //         backgroundBarRadius: 0
            //     }
            // }

            let eventFunction = {}
            if(this.props.getZoomPeriod) {
                eventFunction.zoomed = (chartContext, { xaxis, yaxis }) => {
                    this.zoomData(xaxis)
                }
            }

            console.log("eventFunction", eventFunction)
            
            let options = {
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val  = series[seriesIndex][dataPointIndex];
                        let labelName = w.globals.initialSeries[seriesIndex].name
                        return '<div class="arrow_box">' +
                            '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, 'DD MMM YYYY HH:mm') + 
                            ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                            '</div>'
                    },
                    style: {
                        fontSize: '9px',
                    },
                },
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: this.props.zoomEnabled ? true : false,
                    },
                    sparkline: {
                        enabled: this.props.sparkline
                    },
                    animations: {
                        enabled: false,
                    },
                    type: 'column',
                    height: 150,
                    stacked: this.props.stacked,
                    
                    events: eventFunction
                },   
                colors: colors,             
                plotOptions: {
                    bar: {
                        horizontal: this.props.horizontal,
                        barHeight: this.props.barHeight,
                        borderRadius: this.props.barEndShape && this.props.barEndShape === 'rounded' ? (this.props.barRadius ? this.props.barRadius : 10) : 0,
                        startingShape: 'flat',
                        endingShape: this.props.barEndShape ? this.props.barEndShape : 'flat',
                        columnWidth: this.props.columnWidth ? this.props.columnWidth : '60%',
                        distributed: false,
                        // rangeBarOverlap: false,
                        // colors: backgroundColors
                                
                    },       
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -5
                    },
                },                
                xaxis: {
                    show: this.props.xaxis,
                    tooltip: {
                        enabled: false
                    },
                    axisTicks: {
                        show: false
                    },
                    type: 'datetime',
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    labels: {
                        datetimeUTC: false,
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                    },
                    // offsetX: 15,
                },                

                yaxis: {
                    show: this.props.yaxis,
                    tickAmount: this.props.yaxisTickAmount ? this.props.yaxisTickAmount : null,
                    axisBorder: {
                        show: false,
                        color: '#434B5E',
                    },
                    labels: {
                        offsetX: -10,
                        style: {
                            colors: this.props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                        formatter: function(val, index) {
                            if ((val / 1000) >= 1) {
                                val = val / 1000
                            }
                            if(val % 1 !== 0) {
                                val = val.toFixed(1);
                                return val;
                            }
                            return val
                        },
                    },
                },
                legend: {
                    show: this.props.legend,
                    markers: {
                        radius: 12,
                    },
                    labels: {
                        colors: colors,
                        useSeriesColors: true
                    },
                },
            }
            this.setState({ series, options, totalCount });
        }
    }

    zoomData = (xaxis) => {
        var startDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD HH:mm:ss')
        var endDate = momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD HH:mm:ss')
        this.props.zoomPeriod(startDate, endDate)
    }

    render() {
        return(
            <div className={`${this.props.className}`}>
                {/* <div className={`${this.props.graphRiskCountSection ? '' : 'displayNone'}`}>
                    <p className="mb-1 f16 text-white">Risk Count</p>
                    <div class="d-flex">
                        {this.state.series && this.state.series.length > 0 && this.state.series.map((riskDetails, i) => {
                            let text = riskDetails.name
                            let color = riskDetails.color
                            return (
                                <div key={i} class="mr-4" style={{color: color}}>
                                    <h6 class="m-0">{riskDetails.count}</h6>
                                    <p class="m-0 text-nowrap small">{text}</p>
                                </div>
                                // <div key={i} className={`d-flex riskcout flex-column text-left`} style={{color: color}}>
                                //     <h5 className="mb-0"></h5>
                                //     <p className="mt-1 mb-0"></p>
                                // </div>
                            )
                        })}
                    </div>
                </div> */}
                {this.state.series ?
                    <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} />
                : null}
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
ApexTrendStackedBarChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexTrendStackedBarChart))