/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getAccountNameFromId, getRegionName, datestartEndTime } from '../../../utils/utility'
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import { CUSTOM_DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import { Spinner } from 'reactstrap'

import { 
	listMasterReports,
	getBucketsPrefixes,
    insertReports, 
    updateReports,
	generateReport,
} from '../../../actions/dlp/reportAction'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const reportTemplate = [
	{id: 'HIPAA 0001', category: 'HIPAA', title: 'Show all files which are not accessed'},
	{id: 'PHI 0001', category: 'PHI', title: 'Show all files which have PII/PHI and Sensitive data'},
	{id: 'HIPAA 0002', category: 'HIPAA', title: 'Show all files which are accessible to Public but Bucket is closed'},
	{id: 'PHI 0002', category: 'PHI', title: 'Show all Sensitive/PHI/PII files accessed by cross account/Cross org/ public'},
	{id: 'PHI 0003', category: 'PHI', title: 'Show all Sensitive/PHI/PII files which are redacted/not redacted'},
	{id: 'HIPAA 0003', category: 'HIPAA', title: 'Show all files accessed from Non compliant geo, blocked IP'},
	{id: 'HIPAA 0004', category: 'HIPAA', title: 'Show prefix/bucket which exhibit unusual behavior patterns'},
	{id: 'HIPAA 0005', category: 'HIPAA', title: 'Show files frequently accessed from Infrequent storage'},
]

const schedulePeriodOptions = [
	{value: '+1d', label: 'Daily'},
	{value: '+30d', label: 'Monthly'}
]

const prefixList = [
	{bucket: 'Bucket 0', prefix: 'Bucket 0'},
	{bucket: 'Bucket 1', prefix: 'Bucket 1'},
	{bucket: 'Bucket 2', prefix: 'Bucket 2'},
	{bucket: 'Bucket 3', prefix: 'Bucket 3'},
	{bucket: 'Bucket 4', prefix: 'Bucket 4'},
	{bucket: 'Bucket 5', prefix: 'Bucket 5'},
	{bucket: 'Bucket 6', prefix: 'Bucket 6'},
	{bucket: 'Bucket 7', prefix: 'Bucket 7'},
]

class SidePanel extends Component {
	constructor(props) {
		super(props)
		this.accountRef = React.createRef()
		this.regionRef = React.createRef()
		this.bucketRef = React.createRef()
		this.prefixRef = React.createRef()
		this.dayPickerRef = React.createRef()

		this.handleDayClick = this.handleDayClick.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		
		this.state = this.getInitialState();
		this.state = {
			activeTab: 0,
			showLoading: false,

			accounts: [],
			selectedAccount: '',
			isAccountOpen: false,
			
			regions: [],
			selectedRegion: [],
			selectedRegionName: [],
			isRegionOpen: false,

			report_id: '',
			report_title: '',

			selectedBucket: [],
			// selectedBucketName: [],
			isBucketOpen: false,

			selectedPrefixArray: [],
			isPrefixOpen: [],

			pervious90Days: moment().subtract('365', 'days'),
			showDatepicker: false,
			durationType: '',
			durationFilter: [],
		}
	}

	componentDidMount = () => {
		let durationFilter = CUSTOM_DURATION_FILTER_OPTIONS
		if(this.props.pageType === 'create' || this.props.pageType === 'edit') {
			durationFilter = DURATION_FILTER_OPTIONS
		}

		this.setState({ durationFilter })

		this.props.listMasterReports({}, (promise, result) => {
			if(promise) {
				if(!result.error) {
					this.setState({ masterReports: result, saveActionLoading: false },					
						() => {
							if(this.props.pageType !== 'create') {
								this.fetchFormData()
							}
						}
					)
				} else {
					if(this.props.pageType !== 'create') {
						this.fetchFormData()
					}
				}
			}
		})

		this.props.getBucketsPrefixes({}, (promise, result) => {
			if(promise) {
				result.unshift('All');
				this.setState({ bucketsList: result })
			}
		})

		// if(this.props.regions && this.props.regions.length) {
		// 	let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
		// 	this.setState({ regions: providerRegions },
		// 		() => {
		// 			if(this.props.pageType !== 'create') {
						
		// 			}
		// 		}
		// 	)
		// }
	}

	fetchFormData = () => {
		let selectedRecord = this.props.selectedRecord
		let selectedAccount = ''
		let selectedRegion = []
		let selectedRegionName = []
		let selectedBucket = []
		// let selectedBucketName= []
		let selectedDuration = ''
		let selectedDurationLabel = ''
		let is_favourite = false
		let is_scheduled = false
		let schedulePeriod = ''
		let schedulePeriodLable = ''
		let is_enabled = true
		let bucket_name = ''
		let prefix_name = ''
		let customizable_fields = selectedRecord.customizable_fields ? selectedRecord.customizable_fields : []
		let static_fields = selectedRecord.static_fields ? selectedRecord.static_fields : []

		if(selectedRecord && Object.entries(selectedRecord).length) {
			selectedAccount = selectedRecord.account_id
			
			selectedRegion = selectedRecord.regions
			if(selectedRegion.length && !selectedRegion.includes('All')) {
				selectedRegion.forEach(reg => {
					let regionRow = {}
					regionRow.value = reg
					regionRow.label = getRegionName(reg, this.state.regions)
					selectedRegionName.push(regionRow)
				})
			}

			selectedBucket = selectedRecord.bucket_name_with_prefix

			// if(selectedBucket.length && !selectedBucket.includes('All')) {
			// 	selectedBucket.forEach(buck => {
			// 		let bucketRow = {}
			// 		bucketRow.value = buck
			// 		bucketRow.label = buck
			// 		selectedBucketName.push(bucketRow)
			// 	})
			// }
			
			selectedDuration = selectedRecord.duration
			this.state.durationFilter.forEach(item => {
				if(item.value === selectedDuration) {
					selectedDurationLabel = item.option
				}
			})
			
			is_favourite = selectedRecord.is_favourite
			is_scheduled = selectedRecord.is_scheduled
			schedulePeriod = selectedRecord.schedule
			if(is_scheduled) {
				schedulePeriodOptions.forEach(sch => {
					if(sch.value === schedulePeriod) {
						schedulePeriodLable = sch.label
					}
				})
			}
			
			is_enabled = selectedRecord.mandatory

			let storageConfig = selectedRecord.storage_config
			bucket_name = storageConfig ? storageConfig.bucket_name : ''
			prefix_name = storageConfig ? storageConfig.bucket_prefix : ''
		}
		this.setState({ 
			master_report_id: selectedRecord.master_report_id,
			master_report_title: selectedRecord.master_report_title,
			compliance_id: selectedRecord.compliance_id,
			report_id: selectedRecord.report_id,
			service_id: selectedRecord.service_id ? selectedRecord.service_id : '',
			service_name: selectedRecord.service_name ? selectedRecord.service_name : '',
			report_title: selectedRecord.report_title,
			customizable_fields,
			selectedBucket,
			static_fields,
			selectedAccount,
			selectedRegion,
			selectedRegionName,
			selectedDuration,
			selectedDurationLabel,
			is_scheduled,
			is_favourite,
			schedulePeriod,
			schedulePeriodLable,
			is_enabled,
			bucket_name,
			prefix_name,
			showInputFields: true
		})
	}
	
	getInitialState() {
		return {
			from: undefined,
			to: undefined,
		};	
	}

	handleResetClick = () => {
		this.setState(() => this.getInitialState());
	}

	handleDayClick(day) {
		if(this.state.from === undefined) {
			const range = DateUtils.addDayToRange(day, this.state);
			this.setState({ range, 
				from: day, 
				durationTypeFrom: moment(range.from).format('DD MMM'),
				start_time: datestartEndTime(day, 'start')
			});
		} else {
			const newRange = {
				from: this.state.from,
				to: day,
			}
			var Difference_In_Time = day.getTime() - this.state.from.getTime(); 			
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			// if(Difference_In_Days < 6) {
			// 	alert('Please select 7 days for better view')
			// 	return;
			// }
			// let durtaion = '+'+(Difference_In_Days+1)+'d';
			if(Difference_In_Days > 0) {
				const range = DateUtils.addDayToRange(day, newRange);
				this.setState(
					{ 
						range, 
						to: day, 
						showDatepicker: false,
						selectedDuration: 'custom',
						durationType: this.state.durationTypeFrom + ' - ' + moment(range.from).format('DD MMM'),
						end_time: datestartEndTime(day, 'end')
					}
				);
			}
		}
	}

	handleMultiSelectChange = (childKey, arrayValue) => {
		let value = arrayValue.map(item => item.value)
		let label = arrayValue.map(item => item)	
		if(childKey === 'selectedRegion') {
			let prevState = this.state.selectedRegion
			let selectedRegionName = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.regions.forEach(reg => {
						if(reg.region === item) {
							let label = {}
							label.value = reg.region
							label.label = reg.name
							array.push(label)
						}
					})
				})
				selectedRegionName = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedRegionName = []
			}

			if(value.length && value.includes("All")) {
				let selectedRegion = []
				selectedRegionName = []
				this.state.regions.forEach(reg => {
					let label = {}
					label.value = reg.region
					label.label = reg.name

					selectedRegion.push(label.value)
					selectedRegionName.push(label)
				})
				this.setState({ selectedRegion, selectedRegionName  })
			} else {
				this.setState({ selectedRegion: value, selectedRegionName  })
			}
		} else if(childKey === 'selectedBucket') {
			let selectedValue = []
			let prevState = this.state.selectedBucket
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					this.state.bucketsList.forEach(buck => {
						selectedValue.push(buck)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (this.state.bucketsList.length -1)) {
					selectedValue.push('All')
				}
			}
			this.setState({ selectedBucket: selectedValue })
		}  else if(childKey === 'prefix') {
			let prevState = this.state.selectedPrefix
			let selectedPrefixArray = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					reportTemplate.forEach(pref => {
						if(pref.id === item) {
							let label = {}
							label.value = pref.id
							label.label = pref.title
							array.push(label)
						}
					})
				})
				selectedPrefixArray = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedPrefixArray = []
			}

			if(value.length && value.includes("All")) {
				let selectedPrefix = []
				selectedPrefixArray = []
				label = {}
				label.value = 'All'
				label.label = 'All'
				selectedPrefix.push(label.value)
				selectedPrefixArray.push(label)
				this.setState({ selectedPrefix, selectedPrefixArray },
					() => {
						if(!prevState.length && value.includes("All")) {
							this.setState({ isRegionOpen: false })
						}
					}
				)
			} else {
				this.setState({ selectedPrefix: value, selectedPrefixArray },
					() => {
						if(prevState.includes("All") && !value.length) {
							this.setState({ isPrefixOpen: false })
						}
					}
				)
			}
		}
	}

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}
	
	handleClickOutside(event) {	
		if(this.durationRef && !this.durationRef.current.contains(event.target)) {
			if (this.dayPickerRef && !this.dayPickerRef.current.contains(event.target)) {
				this.setState({ showDatepicker: false })
				this.handleResetClick()
			}
		}
		
		if (this.accountRef && this.accountRef.current && !this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: false })
		} else if (this.accountRef && this.accountRef.current && this.accountRef.current.contains(event.target)) {
			this.setState({ isAccountOpen: true })
		}
		
		if (this.regionRef && this.regionRef.current && !this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: false })
		} else if (this.regionRef && this.regionRef.current && this.regionRef.current.contains(event.target)) {
			this.setState({ isRegionOpen: true })
		}

		if (this.bucketRef && this.bucketRef.current && !this.bucketRef.current.contains(event.target)) {
			this.setState({ isBucketOpen: false })
		} else if (this.bucketRef && this.bucketRef.current && this.bucketRef.current.contains(event.target)) {
			this.setState({ isBucketOpen: true })
		}

		// if (this.prefixRef && !this.prefixRef.current.contains(event.target)) {
		// 	this.setState({ isPrefixOpen: false })
		// } else {
		// 	this.setState({ isPrefixOpen: true })
		// }
	}

	removeSelectedOption = (section, index, option) => {
		let sectionState = []
		let sectionStateArray = []
		if(section === 'region') {
			sectionState = this.state.selectedRegion
			sectionStateArray = this.state.selectedRegionName
		}
		
		if(section === 'region') {
			let details = sectionState.filter(e => e !== option.value)
			let detailsArray = sectionStateArray.filter(e => e.label !== option.label)
			this.setState({ selectedRegion: details, selectedRegionName: detailsArray})
		}	
		if(section === 'bucket') {

			let details = this.state.selectedBucket.filter(e => e !== option)
			this.setState({ selectedBucket: details })
		}
		
	}
	
	resetForm = () => {
		if(this.props.pageType === 'create') {
			this.setState({ 
				selectedAccount: '', 
				selectedRegion: ['All'], 
				selectedRegionName: [{label:'All', value:'All'}],
				selectedBucket: [], 
				// selectedBucketName: [{label:'All', value:'All'}],
				report_title: this.state.master_report_title,
				selectedDuration: '',
				selectedDurationLabel: '',
				is_favourite: false,
				is_scheduled: false,
				schedulePeriod: '',
				schedulePeriodLable: '',
			})
		} else {
			this.fetchFormData()
		}
	}

	reportActions = (action) => {
		this.setState({ saveActionLoading: true })
		let hasError = false
		if(!this.state.report_title || this.state.report_title === '' || !this.state.bucket_name || this.state.bucket_name === '') {
			hasError = true
		}
		if(!this.state.selectedAccount || this.state.selectedAccount === '') {
			hasError = true
		}

		if(!this.state.selectedDuration || this.state.selectedDuration === '') {
			hasError = true
		}

		if(this.state.is_scheduled && (!this.state.schedulePeriod || this.state.schedulePeriod === '')) {
			hasError = true
		}

		if(!hasError) {
			let params = {}
			if(this.props.pageType !== 'create') {
				params = this.props.selectedRecord
			} else {
				if(this.state.masterData && Object.entries(this.state.masterData).length) {
					Object.entries(this.state.masterData).forEach(([key, value]) => {
						params[key] = value
					})
				} else {
					// params.master_report_id = this.state.master_report_id
					// params.master_report_title = this.state.master_report_title
				}
			}
			
			params.report_title = this.state.report_title
			params.account_id = this.state.selectedAccount
			params.bucket_name_with_prefix = this.state.selectedBucket.filter(e => e !== 'All')
			params.duration = this.state.selectedDuration
			if(this.props.pageType !== 'generate') {
				params.is_favourite = this.state.is_favourite ? this.state.is_favourite : false
				params.is_scheduled = this.state.is_scheduled ? this.state.is_scheduled : false
				params.schedule = this.state.is_scheduled ? this.state.schedulePeriod : '1m'
			}
			let storageConfig = {}
			storageConfig.bucket_name = this.state.bucket_name
			storageConfig.bucket_prefix = this.state.prefix_name
			storageConfig.service_name = 's3'

			params.storage_config = storageConfig

			params.job_type = 'dlp_reports'
			if(this.props.pageType === 'create') {
				params.mandatory = true
				this.props.insertReports(params, (promise, result) => {
					if(promise) {
						// result.status.report_id[0]
						// result.status.agent_id
						// result.status.message === 'inserted'
						// result.ok === true
						// result.total
						setTimeout(() => {
							this.props.refreshReportList()
						}, 1000)
						if(action === 'generate') {
							//here we will call the generate report api
						}
					} else {
						this.setState({ saveActionLoading: false })
					}
				})
			} else if(this.props.pageType === 'edit') {
				params.report_id = this.state.report_id
				params.service_id = this.state.service_id
				params.service_name = this.state.service_name				
				params.mandatory = this.state.is_enabled ? this.state.is_enabled : false

				this.props.updateReports(params, (promise, result) => {
					if(promise) {
						setTimeout(() => {
							this.props.refreshReportList()
						}, 1000)
						if(action === 'generate') {
							//here we will call the generate report api
						}
					} else {
						this.setState({ saveActionLoading: false })
					}
				})
			} else {
				if(this.state.selectedDuration === 'custom') {
					params.duration = ''
					params.start_time = this.state.start_time
					params.end_time = this.state.end_time
				}
				this.props.generateReport(params, (promise, result) => {
					if(promise) {
						setTimeout(() => {
							this.props.refresGenerateReportList()
						}, 500)
					} else {
						this.setState({ saveActionLoading: false })
					}
				})
			}
		} else {
			this.setState({ saveActionLoading: false, hasError })
		}
	}

	render() {
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		return (
			<div className="advanced-search" onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className="search-content">
					<div className="header-search">
						<div className="w-70">
							<h6>{this.props.pageType === 'create' ? 'Create Report' : this.props.pageType === 'edit' ? 'Edit Report' : 'Generate Report'}</h6>
						</div>
						<div className="text-right flex-fill w-10">
							<i className="far fa-times" onClick={() => this.props.closeViewSidePanel()}></i>
						</div>
					</div>
					<div className="overflow-auto vh-100">
						{!this.state.showLoading ?
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="assets" role="tabpanel"
								aria-labelledby="dlp-malware-dash">

								{
									this.state.activeTab === 0 &&
									<div className="col-sm-12">
										<div className=''>
											<p className='mt-2 mb-2'><b>Report</b></p>
											<p className="mb-0 f11">Results of Top 10 Resources that are contributing to most failed checks in your cloud infrastructure. Status by Fail and Risk count are shown
											</p>
										</div>
										<div className={`mt-3`}>
											<div className={`form-group`}>
												<div className="d-flex dlpReportSelectStyle">
													<label className="align-self-center m-0 mr-3 text-right w-10">Template</label>
													<Select
														placeholder={'Select'}
														// menuIsOpen={this.state.providerIsOpen}
														isSearchable={false}
														//onFocus={this.openProivderMenu}
														className={`f13 p-0 minMaxWidth300 z1000 ${this.props.pageType === 'create' ? '' : 'disabled' }`}
														// ' colorBlack reactSelectDrop'
														value={({
															value: this.state.master_report_id ? this.state.master_report_id : 'Select',
															label: this.state.master_report_title ? this.state.master_report_title : 'Select'
														})}
														options={this.state.masterReports && this.state.masterReports.map(item => ({
															value: item.master_report_id,
															label: item.master_report_title,
															data: item
														}))}
														onChange={event =>  
															this.setState(
																{
																	master_report_id: event.value,
																	master_report_title: event.label,
																	masterData: event.data,
																	report_title: event.label,
																	compliance_id: event.data.compliance_id,
																	customizable_fields: event.data.customizable_fields,
																	selectedAccount: '',
																	selectedRegion: [], 
																	selectedRegionName: [],
																	showInputFields: true
																},

															)
														}
													/>
												</div>
											</div>

											{this.state.showInputFields ? 
												<React.Fragment>
												<div className={`form-group ${this.props.pageType === 'generate' ? 'disabled' : ''}`}>
													<div className="d-flex">
														<label className="align-self-center m-0 mr-3 text-right w-10">Title</label>
														<input 
															type="text" 
															className="form-control col-sm-6"
															value={this.state.report_title ? this.state.report_title : ''}
															onChange={e => this.setState({ report_title: e.target.value })}
														/>
													</div>
													{this.state.hasError && (!this.state.report_title || this.state.report_title === '') ? 
														<div className="">
															<label className="mr-3 w-10">&emsp;</label>
															<small className='text-danger'>Title is required.</small>
														</div>
													: null}
												</div>
												
												{this.state.customizable_fields && this.state.customizable_fields.includes('accounts') ?
													<div className={`form-group`}>
														<div className="d-flex dlpReportSelectStyle">
															<label className="align-self-center m-0 mr-3 text-right w-10">Account</label>
															<Select
																placeholder={'Select'}
																// menuIsOpen={this.state.providerIsOpen}
																isSearchable={false}
																className={`f13 p-0 minMaxWidth300 z997`}
																value={({
																	value: this.state.selectedAccount ? this.state.selectedAccount : 'Select',
																	label: this.state.selectedAccount ? getAccountNameFromId(this.state.selectedAccount, this.props.accounts) : 'Select'
																})}
																options={this.props.accounts && this.props.accounts.map(acc => ({
																	value: acc.account_id,
																	label: acc.account_name,
																}))}
																onChange={event => { this.setState({ selectedAccount: event.value }) }}
															/>
														</div>
														{this.state.hasError && (!this.state.selectedAccount || this.state.selectedAccount === '') ? 
															<div className="">
																<label className="mr-3 w-10">&emsp;</label>
																<small className='text-danger'>Account is required.</small>
															</div>
														: null}
													</div>
												: null}
												{this.state.customizable_fields && this.state.customizable_fields.includes('regions') ?
													<div className={`form-group`}>
														<div className="d-flex">
															<label className="align-self-center m-0 mr-3 text-right w-10">Region</label>
															<div className={`governanceServiceMultiSelect align-self-center mr-2 z998 ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.regionRef}>
																<ReactMultiSelectCheckboxes						
																	placeholderButtonLabel="All"
																	getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
																	// menuIsOpen ={this.state.isRegionOpen}
																	value={this.state.selectedRegionName.map(selected => ({
																		value: selected.value,
																		label: selected.label
																	}))}
																	onChange={arr => {
																	this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
																	options={this.state.regions && this.state.regions.map(region => ({
																		value: region.region,
																		label: region.name,
																	}))}
																/>
															</div>
														</div>
														{this.state.selectedRegionName.length ?
															<div className="d-flex">
																<label className="mr-3 w-10">&emsp;</label>
																<div className="flex-grow align-self-center">
																	{this.state.selectedRegionName.map((item, index) => {
																		return(
																			item.label !== 'All' ?
																				<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
																					{item.label}
																					<i onClick={ () => this.removeSelectedOption('region', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
																				</span>
																			:
																			null
																		)
																	})}
																</div>
															</div>
														: null}
													</div>
												: null}
												<div className={`form-group`}>
													<div className="d-flex dlpReportSelectStyle">
														<label className="align-self-center m-0 mr-3 text-right w-10">Duration</label>
														<Select
															placeholder={'Select'}
															// menuIsOpen={this.state.providerIsOpen}
															isSearchable={false}
															//onFocus={this.openProivderMenu}
															className={`f13 p-0 minMaxWidth300 z996`}
															// ' colorBlack reactSelectDrop'
															value={({
																value: this.state.selectedDuration ? this.state.selectedDuration : 'Select',
																label: this.state.selectedDurationLabel ? this.state.selectedDurationLabel : 'Select'
															})}
															options={this.state.durationFilter.map(item => ({
																value: item.value,
																label: item.option,	
															}))}
															onChange={event => {
																if(event.value !== 'custom') {													
																	this.handleResetClick()
																	this.setState({ 
																		selectedDuration: event.value,
																		selectedDurationLabel: event.label,
																		showDatepicker: false, 
																		selectedDatePicker: false, 
																		durationType: '' 
																	})
																} else { 
																	this.setState({
																		showDatepicker: true,
																		selectedDuration: event.value,
																	})
																}
															}}
														/>
													</div>
													{this.state.hasError && (!this.state.selectedDuration || this.state.selectedDuration === '') ? 
														<div className="">
															<label className="mr-3 w-10">&emsp;</label>
															<small className='text-danger'>Duration is required.</small>
														</div>
													: null}
													{this.state.durationType !== '' ?
														<div className="flex-grow align-self-center mt-2">
															<label className="mr-2 w-10">&emsp;</label>
															<span className="badge badge-outline-secondary f14 mr-2 mb-1">{this.state.start_time}</span>
															<span className="badge badge-outline-secondary f14 mr-2 mb-1">{this.state.end_time}</span>
														</div>
													: null}
												</div>
												<div className={`RangeExample customDayPicker z996 ${this.state.showDatepicker ? '' : 'displayNone'}`} ref={this.dayPickerRef}>
													<DayPicker
														toMonth={new Date()}
														disabledDays={[
															{
																after: new Date(),
																before: (new Date(this.state.pervious90Days))
															},
														]}
														className="Selectable"
														numberOfMonths= {3}																			
														selectedDays={[from, { from, to }]}
														modifiers={modifiers}
														onDayClick={this.handleDayClick}
														tabIndex={0}
													/>								
													<div className="col-sm-12">
														<button className="resetBtn f15 float-right mb-1" onClick={this.handleResetClick}>Reset</button>
													</div>
												</div>
												{this.state.customizable_fields && this.state.customizable_fields.includes('bucket_name_with_prefix') ?
													<div className={`form-group`}>
														<div className="d-flex">
															<label className="align-self-center m-0 mr-3 text-right w-10">Bucket</label>
															<div className={`governanceServiceMultiSelect align-self-center mr-2 z994 ${this.bucketsList && this.bucketsList.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.bucketRef}>
																<ReactMultiSelectCheckboxes						
																	placeholderButtonLabel="All"
																	getDropdownButtonLabel={() => this.getMultiSelectedCount('bucket', this.state.selectedBucket)}
																	// menuIsOpen ={this.state.isBucketOpen}
																	value={this.state.selectedBucket.map(selected => ({
																		value: selected
																	}))}
																	onChange={arr => {
																	this.handleMultiSelectChange('selectedBucket', arr ? arr : []) }}
																	options={this.state.bucketsList && this.state.bucketsList.map(item => ({
																		value: item,
																		label: item,
																	}))}
																/>
															</div>
														</div>
														{this.state.selectedBucket.length ?
															<div className="d-flex">
																<label className="mr-3 w-10">&emsp;</label>
																<div className="flex-grow align-self-center">
																	{this.state.selectedBucket.map((item, index) => {
																		return(
																			item !== 'All' ?
																				<span key={index} className="badge badge-light f14 mr-1 mb-1 mt-1" >
																					{item}
																					<i onClick={ () => this.removeSelectedOption('bucket', index, item) } className='ml-1 fal fa-times cursorPointer'></i>
																				</span>
																			:
																			null
																		)
																	})}
																</div>
															</div>
														: null}
													</div>
												: null}
												<div className='form-group displayNone'>
													<div className="d-flex">
														<label className="align-self-center m-0 mr-3 text-right w-10">Prefix</label>
														<div className={`governanceServiceMultiSelect align-self-center mr-2 z995 ${reportTemplate && reportTemplate.length > 10 ? '' : 'removeDropdownSearchBar' } `} ref={this.prefixRef}>
															<ReactMultiSelectCheckboxes						
																placeholderButtonLabel="All"
																getDropdownButtonLabel={() => this.getMultiSelectedCount('prefix', this.state.selectedPrefix)}
																// menuIsOpen ={this.state.isPrefixOpen}
																value={this.state.selectedPrefixArray.map(selected => ({
																	value: selected.value,
																	label: selected.label
																}))}
																onChange={arr => {
																this.handleMultiSelectChange('prefix', arr ? arr : []) }}
																options={prefixList.map(region => ({
																	value: region.prefix,
																	label: region.prefix,
																}))}
															/>
														</div>
													</div>
												</div>
												<div className={`form-group ${this.props.pageType === 'generate' ? 'displayNone' : ''}`}>
													<div className="form-check">
														<label className="mr-3 w-10">&emsp;</label>
														<input type="checkbox" className={`form-check-input`} onClick={() => this.setState({ is_favourite: !this.state.is_favourite })} id="is_favourite" 
														checked={this.state.is_favourite ? true : false}
														/>
														<label className="form-check-label" for="is_favourite">Favourite</label>
													</div>
												</div>
												{/* {this.state.customizable_fields && this.state.customizable_fields.includes('scheduling') && this.props.pageType !== 'generate' ? */}
												<div className={`form-group ${this.props.pageType === 'generate' ? 'displayNone' : ''}`}>
													<div className="form-check">
														<label className="mr-3 w-10">&emsp;</label>
														<input type="checkbox" className={`form-check-input`} onClick={() => this.setState({ is_scheduled: !this.state.is_scheduled })} id="is_scheduled" 
														checked={this.state.is_scheduled ? true : false}
														/>
														<label className="form-check-label" for="is_scheduled">Scheduling</label>
													</div>
												</div>

												{this.state.is_scheduled?
													<div className={`form-group ${this.props.pageType === 'generate' ? 'displayNone' : ''}`}>
														<div className="d-flex dlpReportSelectStyle">
															<label className="align-self-center m-0 mr-3 text-right w-10">Schedule Period</label>
															<Select
																placeholder={'Select'}
																isSearchable={false}
																className={`f13 p-0 minMaxWidth300 z993 `}
																// ' colorBlack reactSelectDrop'
																value={({
																	value: this.state.schedulePeriod ? this.state.schedulePeriod : 'Select',
																	label: this.state.schedulePeriodLable ? this.state.schedulePeriodLable : 'Select'
																})}
																options={schedulePeriodOptions && schedulePeriodOptions.map(item => ({
																	value: item.value,
																	label: item.label
																}))}
																onChange={event =>  
																	this.setState({ schedulePeriod: event.value, schedulePeriodLable: event.label })
																}
															/>
														</div>
														{this.state.hasError && (!this.state.schedulePeriod || this.state.schedulePeriod === '') ? 
															<div className="">
																<label className="mr-3 w-10">&emsp;</label>
																<small className='text-danger'>Schedule period is required.</small>
															</div>
														: null}
													</div>
												: null}
												<div className="d-flex">
													{/* <label className="align-self-center m-0 mr-3 text-right w-10">&nbsp;</label> */}
													<label className="align-self-center">Store data in</label>
												</div>
												<div className={`form-group`}>
													<div className="d-flex">
														<label className="align-self-center mr-3 text-right w-10">Bucket</label>
														<input
															type="text" 
															className="form-control col-sm-6"
															placeholder='Bucket name'
															value={this.state.bucket_name ? this.state.bucket_name : ''}
															onChange={e => this.setState({ bucket_name: e.target.value })}
														/>		
													</div>
													{this.state.hasError && (!this.state.bucket_name || this.state.bucket_name === '') ? 
														<div className="">
															<label className="mr-3 w-10">&emsp;</label>
															<small className='text-danger'>Bucket is required.</small>
														</div>
													: null}
												</div>
												<div className={`form-group d-flex`}>
													<label className="align-self-center m-0 mr-3 text-right w-10">Prefix</label>
													<input 
														type="text"
														className="form-control col-sm-6"
														placeholder='prefix'
														value={this.state.prefix_name ? this.state.prefix_name : ''}
														onChange={e => this.setState({ prefix_name: e.target.value })}
													/>
												</div>

												{this.state.is_scheduled && this.props.pageType === 'edit' ?
													<div className={`form-group`}>
														<div className="form-check">
															<label className="mr-3 w-10">&emsp;</label>
															<input type="checkbox" className={`form-check-input`} onClick={() => this.setState({ is_enabled: !this.state.is_enabled })} id="is_enabled" 
															checked={this.state.is_enabled ? true : false}
															/>
															<label className="form-check-label" for="is_favourite">Enable</label>
														</div>
													</div>
												: null}
												
												{this.state.saveActionLoading ?
													<div className="mt-3 pt-3 border-top">
														<button className='btn btn-sm btn-dark mt-3 border-top'>
															<Spinner className='align-self-center mr-2' color='white' size='sm' /> 
															{this.props.pageType === 'create' ?  'Inserting Report...'
															: this.props.pageType === 'edit' ? 'Updating Report...' 
															: 'Generating Report'}
														</button>
													</div>
												:
													<div className="mt-3 pt-3 border-top">
														<button className='btn btn-sm btn-dark align-self-center mr-3' onClick={() => this.reportActions('insert')}>
															{this.props.pageType === 'create' ? 'Create' : this.props.pageType === 'edit' ? 'Update' : 'Generate'}
														</button>	
														{(this.props.pageType === 'create' || this.props.pageType === 'edit') ?		
															<button className='btn btn-sm btn-dark align-self-center mr-3' onClick={() => this.reportActions('generate')}>
																{this.props.pageType === 'create' ? 'Create & Generate' : 'Update & Generate'}
															</button>
														: null}
														<button className='btn btn-sm btn-light align-self-center' onClick={() => this.resetForm()}>
															Reset
														</button>
													</div>
												}
												</React.Fragment>
											: null }
										</div>
									</div>
								}
								</div>
							</div>
						:
							<div className='text-center mt-5' ><Spinner color='dark' size='lg' /></div>
						}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
SidePanel.propTypes = {
	listMasterReports: PropTypes.func,
	getBucketsPrefixes: PropTypes.func,
    insertReports: PropTypes.func,
    updateReports: PropTypes.func,
	generateReport: PropTypes.func
}

const mapStateToProps = state => {
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default connect(mapStateToProps, {
	listMasterReports,
	getBucketsPrefixes,
    insertReports,
    updateReports,
	generateReport,
})(withRouter(SidePanel))