/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { 
	getThreatDetails,
	downloadChecksResults,
} from '../../../actions/dlp/threatAction'
import { Spinner, UncontrolledTooltip, UncontrolledPopover, PopoverBody } from 'reactstrap'
import AiopsResizeableTable from '../../aiops/AiopsResizeableTable'

import HierarchicalBarChart from '../../common/charts/HierarchicalBarChart'

class SidePanel extends Component {
	hierarichalBucketBarChartRef = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			activeTab: 0,
			selectedType: 'All',
			showLoading: true,
		}
	}

	componentDidMount = () => {
		
		window.addEventListener('resize', () => {
            this.setState({ showHierarchicalBarChart: false },
                () => this.HierarchicalBarChartData()    
            )
        })
		this.getThreatDetails()
	}

    getThreatDetails = () => {
		let params = {}
		params.compliance = [{"complianceId": "dc v1.0"}]
		params.majorControls = [this.props.majorControls.control]
		params.provider = this.props.selectedProvider.toLowerCase()
		params.start_time = this.props.start_time
		params.end_time = this.props.end_time
	
		this.props.getThreatDetails(params, (promise, result) => {
			if(promise) {
				
				let topBuckets = []
				if(result && result.length && result[0].top_buckets) {
					let dataRow = {}
					dataRow.totalCount = result[0].top_buckets.reduce(function(a, b){ return a + b['item_count']; }, 0);
					dataRow.label = "Buckets"
					dataRow.data = result[0].top_buckets
					topBuckets.push(dataRow)
				}

				let topStats = []
				if(result && result.length && result[0].top_stats) {
					result[0].top_stats.forEach(item => {
						let dataRow = {}
						dataRow.totalCount = item.items.reduce( function(a, b){ return a + b['item_count']; }, 0)
						dataRow.label = item.label 
						dataRow.data = item.items
						topStats.push(dataRow)
					})
				}
				this.setState({ showDlpThreatDetails: true, threatDetails: result && result.length ? result[0] : {}, showLoading: false, topBuckets, topStats }, 
					() => {
						let subActions = false
						let countCategory = ''
						if(this.state.threatDetails && this.state.threatDetails.controls && this.state.threatDetails.controls.length) {
							if(this.state.threatDetails.controls[0] && this.state.threatDetails.controls[0].overall_count_sub_action && this.state.threatDetails.controls[0].overall_count_sub_action.length) {
								subActions = true
							}
							if(this.state.threatDetails.controls[0] && this.state.threatDetails.controls[0].count_category) {
								countCategory = this.state.threatDetails.controls[0].count_category
							}
						}

						this.setState({ subActions, countCategory },
							() => {
								this.formTableData()
								this.HierarchicalBarChartData()
							}	
						)
					}
				)

			}
		})
    }

	HierarchicalBarChartData = () => {
		let data = {}
		data.name = "Bucket"
		let children = []
		let buckets = []
		this.state.threatDetails && this.state.threatDetails.controls && this.state.threatDetails.controls.length && 
		this.state.threatDetails.controls.forEach((ctrl, index) => {
			ctrl.bucket_wise_count && ctrl.bucket_wise_count.forEach((item, x) => {
				let childRow = {}
				childRow.breadCrumb = "Bucket"
				if (buckets.includes(item.bucket_name)) {
					children.forEach(cld => {
						if(cld.name === item.bucket_name) {
							item.prefix_wise_count && item.prefix_wise_count.forEach((prefix, i) => {
								let childRow2 = {}
								childRow2.breadCrumb = "Bucket_Prefix"
								childRow2.name = prefix.bucket_prefix ? prefix.bucket_prefix+"_"+index : ''
								childRow2.description = prefix.count_description ? prefix.count_description: ''
								childRow2.value = 0

								let subChildren = []
								prefix.prefix_wise_sub_action && Object.entries(prefix.prefix_wise_sub_action).length && Object.entries(prefix.prefix_wise_sub_action).forEach(([key, value]) => {
									let subAction = {}
									subAction.breadCrumb = "Bucket_Prefix_Action"
									subAction.name = key
									subAction.value = value
									subChildren.push(subAction)
								})
								if(subChildren.length) {
									childRow2.children = subChildren
								}

								cld.children.push(childRow2)
							})
						}
					})
				} else {
					buckets.push(item.bucket_name)
					childRow.name = item.bucket_name ? item.bucket_name : ''
					childRow.value = 0

					let childArray2 = []
					if(item.prefix_wise_count && item.prefix_wise_count.length) {
						item.prefix_wise_count.forEach((prefix, i) => {
							let childRow2 = {}
							childRow2.breadCrumb = "Bucket_Prefix"
							childRow2.name = prefix.bucket_prefix ? prefix.bucket_prefix+"_"+index : ''
							childRow2.value = 0

							let subChildren = []
							prefix.prefix_wise_sub_action && Object.entries(prefix.prefix_wise_sub_action).length && Object.entries(prefix.prefix_wise_sub_action).forEach(([key, value]) => {
								let subAction = {}
								subAction.breadCrumb = "Bucket_Prefix_Action"
								subAction.name = key
								subAction.value = value
								subChildren.push(subAction)
							})
							if(subChildren.length) {
								childRow2.children = subChildren
							}
							
							childArray2.push(childRow2)
						})
					} else {
						childRow.value = item.count
					} 
				
					if(childArray2.length || Object.entries(childRow).length) {
						childRow.children = childArray2
						children.push(childRow)
					}
				}
			})
		})
		data.children = children
		this.setState({ hirericalData: data, showHierarchicalBarChart: true})

	}

	formTableData = () => {
		let bucketArray = []
		let dlpTableTopActionText = ''
		let overallSubAction = ''
		if(this.state.threatDetails && this.state.threatDetails.controls && this.state.threatDetails.controls.length) {			
			
			overallSubAction = this.state.threatDetails.controls[0].overall_sub_action
			let overall_sub_action = ''
			if(this.state.threatDetails.controls[0].overall_sub_action) {
				overall_sub_action = this.bucketWiseActionMapping(this.state.threatDetails.controls[0].overall_sub_action)
			}
			let category = this.state.threatDetails.controls[0].count_category ? this.state.threatDetails.controls[0].count_category : ''
			let overall_count = this.state.threatDetails.controls[0].overall_count ? this.state.threatDetails.controls[0].overall_count : 0

			dlpTableTopActionText = overall_count+' '+category+' '+overall_sub_action
			let orderBy = 0
			this.state.threatDetails.controls[0].bucket_wise_count && this.state.threatDetails.controls[0].bucket_wise_count.forEach(item => {
				let parentRow = {}
				if(!item.prefix_wise_count || !item.prefix_wise_count.length) {
					orderBy += 1
					parentRow.type = 'Bucket'
					parentRow.detectors = this.state.threatDetails.controls[0].detectors ? this.state.threatDetails.controls[0].detectors[0] : ''
					parentRow.category = this.state.threatDetails.controls[0].count_category ? this.state.threatDetails.controls[0].count_category : ''
					parentRow.bucket_name = item.bucket_name ? item.bucket_name : ''
					parentRow.bucket_prefix = ''
					parentRow.name = item.bucket_name ? item.bucket_name : ''
					parentRow.bucket_count = item.count ? item.count : ''
					parentRow.bucket_wise_sub_action = item.bucket_wise_sub_action ? item.bucket_wise_sub_action : {}+')'
					parentRow.orderBy = orderBy
					bucketArray.push(parentRow)
				} else {
					item.prefix_wise_count.forEach(prefix => {
						orderBy += 1
						parentRow = {}
						parentRow.type = 'Prefix'
						parentRow.detectors = this.state.threatDetails.controls[0].detectors ? this.state.threatDetails.controls[0].detectors[0] : ''
						parentRow.category = this.state.threatDetails.controls[0].count_category ? this.state.threatDetails.controls[0].count_category : ''
						parentRow.bucket_name = item.bucket_name ? item.bucket_name : ''
						parentRow.bucket_prefix = prefix.bucket_prefix ? prefix.bucket_prefix : ''
						parentRow.name = (item.bucket_name ? (item.bucket_name+' : ') : '')+(prefix.bucket_prefix ? prefix.bucket_prefix : '')
						parentRow.search_name = (item.bucket_name ? (item.bucket_name+' ') : '')+(prefix.bucket_prefix ? prefix.bucket_prefix : '')+(item.bucket_name ? (item.bucket_name+'') : '')+(prefix.bucket_prefix ? prefix.bucket_prefix : '')
						parentRow.bucket_count = prefix.count ? prefix.count : ''
						parentRow.bucket_wise_sub_action = prefix.prefix_wise_sub_action ? prefix.prefix_wise_sub_action : {}
						parentRow.orderBy = orderBy
						bucketArray.push(parentRow)
					})
				}


				// parentRow.prefix_wise_count = item.prefix_wise_count ? item.prefix_wise_count : []
				// parentRow.prefix_count = parentRow.prefix_wise_count.length
				
			}) 
			bucketArray = _.orderBy(bucketArray, ['orderBy'], ['asc'])
		}
		this.setState({ bucketArray, filteredArray: bucketArray, dlpTableTopActionText, overallSubAction })
	}

	downloadBucket = (type) => {
		let filters = []
		let detectors = ''
		if(this.state.filteredArray && this.state.filteredArray.length) {
			this.state.filteredArray.forEach(item => {
				detectors = item.detectors
				let filtersRow = {}
				if(item.type === 'Prefix') {
					filtersRow.bucket_name = item.bucket_name
					filtersRow.prefix = item.bucket_prefix
					filters.push(filtersRow)
				}
			})
		} 
		if(filters.length) {
			let params = {}
			params.detectors = detectors
			params.filters = filters
			params.response_format = type
			this.props.downloadChecksResults(params, (promise, result) => {
				console.log(result)
			})
		}
	}

	bucketWiseActionMapping = (entries) => {
		let string = ''
		Object.entries(entries).forEach(([key,value], index) => {
			if(!index) {
				string += '( '
			}
			string += key+' : '+value
			if(Object.entries(entries).length > (index+1)) {
				string += ', '
			} else {
				string += ' )'
			}
		})
		// let data = Object.entries(entries, ([k, v]) => `${k}: ${v}`).join(", ");
		return string
	}

	filterBucketData = () => {
		let searchData = this.state.bucketArray
		let filteredArray = this.state.bucketArray
		if(this.state.selectedType !== 'All') {
			filteredArray = filteredArray.filter(arr => arr.type === this.state.selectedType)
			searchData = filteredArray
		}

		if (this.state.searchText && this.state.searchText !== '') {
			filteredArray =
				filteredArray &&
				filteredArray.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}
		this.setState({ filteredArray, searchData })
	}

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

	render() {		
		return (
			<div className="advanced-search">
				<div className="search-content">
					<div className="header-search flex-column bd-highlight">
						<div class="w-100 d-flex">
							<div class="flex-fill bd-highlight">
								<h5 class="f20 font-weight-bold">{this.state.threatDetails && this.state.threatDetails.controls && this.state.threatDetails.controls[0] && this.state.threatDetails.controls[0].control ? this.state.threatDetails.controls[0].control : ''}</h5>
							</div>
							<div class="text-right flex-fill bd-highlight">
								<i className="far fa-times" onClick={() => this.props.closeViewSidePanel()}></i>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<div class="d-flex text-white justify-content-start">
									<p className={`badge badge-${this.props.caseDetails ? (this.props.caseDetails.severity ? (this.props.caseDetails && this.props.caseDetails.severity === 'Critical' ? 'danger' : this.props.caseDetails.severity === 'High' ? 'orange' : this.props.caseDetails.severity === 'Medium' ? 'purple' : this.props.caseDetails.severity === 'Low' ? 'success' : this.props.caseDetails.severity === 'Warning' ? 'warning ' : 'secondary') : 'secondary') : 'secondary'}`}>
										{this.props.caseDetails && this.props.caseDetails.severity ? this.props.caseDetails.severity : 'Unknown'}
									</p>
									<p className="m-0 ml-3 font-weight-bold">Checks:  {this.state.threatDetails && this.state.threatDetails.controls && this.state.threatDetails.controls[0] && this.state.threatDetails.controls[0].detectors && this.state.threatDetails.controls[0].detectors[0] ? this.state.threatDetails.controls[0].detectors[0] : ''}</p>
									<p className="m-0 ml-3 font-weight-bold">Category:  {this.state.threatDetails && this.state.threatDetails.controls && this.state.threatDetails.controls[0] && this.state.threatDetails.controls[0].count_category ? this.state.threatDetails.controls[0].count_category : ''}</p>
									{this.state.threatDetails && this.state.threatDetails.controls && this.state.threatDetails.controls[0] && this.state.threatDetails.controls[0].count_description ? 
										<p className="text-blue m-0 ml-3 font-weight-bold">{this.state.threatDetails.controls[0].count_description}</p>
									: null}
								</div>
							</div>
						</div>
					</div>
					
					<div className="container-fluid overflow-auto">
						{!this.state.showLoading ?
							this.state.activeTab === 0 ?
								<React.Fragment>
								<div class="row bg-gray6">
									<div class="col-12">
										<div class="px-2 py-2 my-2 rounded counts_threads">
											<div class="row">
												<div className={`col-sm-7 text-white align-self-center borderMiddleBlue`}>
													<p class="small mb-0 mt-2">
														Buckets
														<p class="text-gray4 small">(adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
													</p>
													<div ref={this.hierarichalBucketBarChartRef}>
														{this.state.showHierarchicalBarChart ? 
															<HierarchicalBarChart
																ID={'hierarichalBucketBarChart'}
																width={this.hierarichalBucketBarChartRef.current.offsetWidth}
																hirericalData={this.state.hirericalData}
																height={250}	
																margin={{top: 50, right: 30, bottom: 0, left: 100}}
																backgroundColor={'#515358'}
																backBtnColor={'#DEE2E6'}
																breadCrumbColor={'text-white4'}
																barTextColor={'#DEE2E6'}
																gradient={true}																
																gradientColors={['#039BE5', '#5F5BA2']}
															/>
														: null}
													</div>
												</div>
												<div className={`col-sm-5 text-white`}>
													{/* <p className='mt-2 mb-2'><b>Actions</b></p> */}
													<p class="small mb-0 mt-2">
														Actions
														<p class="text-gray4 small">(adipiscing elit, sed do eiusmod tempor incididunt ut)</p>
													</p>
													<div className="align-self-center">
														<div class="bar-label">
															{this.state.topBuckets ?
																this.state.topBuckets.map((item, index) => {
																	return(
																	<React.Fragment>
																	<p class="mb-1 small"><b>{item.label}</b></p>
																	<div class="w-100">
																		<div class="progress mb-2 bg-transparent">
																			{item.data.length ?
																				item.data.map((res,i) => {
																					return (
																						<div class={`progress-bar progress-bg-${i < 11 ? i : (i-11)}`} id={'topBuckets_'+index+'_'+i} role="progressbar" style={{width: parseFloat(res.item_count*100/item.totalCount)+'%'}}
																						aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
																							<UncontrolledTooltip placement='top' target={'topBuckets_'+index+'_'+i}>{res.item_name+': '+res.item_count}</UncontrolledTooltip>
																						</div>
																					)
																				})
																			: null}
																		</div>
																	</div>
																	</React.Fragment>
																	)
																})
															: null}
														</div>
														{this.state.topStats ?
															this.state.topStats.map((item, index) => {
																return(
																	<div class="bar-label-min">
																		<p class="mb-1 small"><b>{item.label}</b></p>
																		<div class="w-100">
																			<div class="progress mb-2 bg-transparent">
																				{item.data.length ?
																					item.data.map((res,i) => {
																						return(
																							<div class={`progress-bar progress-bg-${i < 11 ? i : (i-11)}`} id={'topStats_'+index+'_'+i} style={{width: parseFloat(res.item_count*100/item.totalCount)+'%'}} role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
																								<UncontrolledTooltip placement='top' target={'topStats_'+index+'_'+i}>{res.item_name+': '+res.item_count}</UncontrolledTooltip>
																							</div>
																						)
																					})
																				: null}
																			</div>
																		</div>
																	</div>
																)
															})
														: null}
													</div>
												</div>
											</div>
										</div>
									</div>		
								</div>
								<div className="row mt-3">
									<div class="col-sm-12">
										<div class="mb-2">
											<p class="mb-0 f16"><b>Bucket List</b></p>
											<p class="small mb-0">Showing the bucket list</p>
										</div>
										<div className="rounded bg-white4 p-3">
											<div className="d-flex justify-content-between mb-1">
												<select className="form-control f12 align-self-cent w-20" onChange={(event) => this.setState({ selectedType: event.target.value }, () => this.filterBucketData())}>
													<option value="All">All</option>
													<option value="Bucket">Bucket</option>
													<option value="Prefix">Prefix</option>
												</select>	
																												
												<span className="align-self-center f12 dlpTableTopActionText" id="dlpTableTopActionText">{this.state.dlpTableTopActionText ? this.state.dlpTableTopActionText: ''}</span>
												{this.state.overallSubAction ?
													<UncontrolledPopover trigger="hover" placement="top" target="dlpTableTopActionText">
														<PopoverBody>
															<p className="font-weight-bold mb-1">Actions</p>
															{Object.entries(this.state.overallSubAction).map(([key,value]) => {
																return(
																	<p className="mb-1 text-left">{key} <span className="font-weight-bold mb-0 text-success text-left">{value}</span></p>
																)
															})}
														</PopoverBody>
													</UncontrolledPopover>
												: null}
												<div className="d-flex align-self-center">
													{/* <div className="mb-0">
														<Search
															data={this.state.filterBucketData && this.state.filterBucketData.length ? this.state.filterBucketData : (this.state.bucketArray ? this.state.bucketArray : [])}
															fitlerValue={this.state.selectedType ? this.state.selectedType : ''}
															fitlerLabel='type'
															applyLiteDarkTags={true}
															// resetSearchBox={this.state.resetSearchBox}
															className="form-control-sm"
															filteredData={filteredArray => {
																this.setState({ filteredArray })
															}}
														/>
													</div> */}
														{this.state.bucketArray && this.state.bucketArray.length && (!this.state.searchData || this.state.searchData.length) ?
														<div className={`input-group mr-sm-2`}>
															<div className='input-group-prepend'>
																<div className='input-group-text bg-transparent'>
																	<i className='fal fa-search' />
																</div>
															</div>
															<input
																value={this.state.searchText}
																onChange={e => {
																	this.setState({ searchText: e.target.value }, () => this.filterBucketData())
																}}
																type='text'
																className={`form-control form-control-sm`}
																placeholder='Search'
															/>
														</div>
													: null}
													<div className="ml-2 btn-group dropDown" onClick={() => this.setState({ showDownloadOptions: !this.state.showDownloadOptions })}>
														<i className="far fa-arrow-alt-circle-down text-black f15 align-self-center cursorPointer"></i>
														<div className={`dropdown-menu dropdown-menu-right bg-white2 cursorPointer ${this.state.showDownloadOptions ? 'show active' : ''}`}>
															<button className="dropdown-item" type="button" onClick={() => this.downloadBucket('csv')}>CSV</button>
															<button className="dropdown-item" type="button" onClick={() => this.downloadBucket('json')}>JSON</button>
														</div>
													</div>
												</div>
											</div>
											
											<div className={`dlpControlDetails`}>
												<AiopsResizeableTable
													columns={[
														{
															Header: 'Type',
															accessor: 'type',
															width: 100,
														},
														{
															Header: 'Name',
															accessor: 'name',
															width: 200,
														},
														// {
														// 	Header: 'Count',
														// 	accessor: 'bucket_count'
														// },
														// {
														// 	Header: 'Total Prefix',
														// 	accessor: 'prefix_count'
														// },
														{
															Header: 'Details',
															accessor: 'bucket_wise_sub_action',
															Cell: cellInfo => (
																<div className="d-flex">
																	<span className="mr-1">{cellInfo.row.original.bucket_count+''+cellInfo.row.original.category}</span>
																	<div className="d-flex">
																		{this.bucketWiseActionMapping(cellInfo.row.original.bucket_wise_sub_action)}
																	</div>
																</div>
																// 	{Object.entries(cellInfo.row.original.bucket_wise_sub_action).map(([key,value]) => {
																// 		${k}: ${v}`)
																// 		.join("")
																// 	})}
																// </div>
															),
															// width: '250'
														},
														{
															Header: ' ',
															accessor: '',
															width: '5'
														}
													]}
													data={(this.state.filteredArray ? this.state.filteredArray : [])}
													perPage={10}
													dashboard={(this.state.filteredArray && this.state.filteredArray.length) ? true : false}
													sortByColumn={''}
													riskTooltip={[]}
													onClickRow={tableRow => {}}
												/>
											</div>
										</div>
									</div>
								</div>
								</React.Fragment>
							: null
						:
							<div className='text-center mt-5' ><Spinner color='dark' size='lg' /></div>
						}

					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
SidePanel.propTypes = {
    getThreatDetails: PropTypes.func,
	downloadChecksResults: PropTypes.func
}

const mapStateToProps = state => {
	return {		
	}
}

export default connect(mapStateToProps, {
	getThreatDetails,
	downloadChecksResults
})(withRouter(SidePanel))



