/*************************************************
 * Tvastar
 * @exports
 * @file WatermarkAction.js
 * @author Prakash // on 13/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	DLPPOLICYENDPOINT,
	DLP_CONFIGURATION_LIST_WATERMARK,
	DLP_CONFIGURATION_CREATE_WATERMARK,
    DLP_CONFIGURATION_UPDATE_WATERMARK,
    DLP_CONFIGURATION_DELETE_WATERMARK,
} from '../../config'
import { ACTION_TYPES } from '../types'

/**
 * Action to list Watermark
 * @param {Object} body
 * @param {Function} callback
 */

export const listWatermark = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_LIST_WATERMARK, { body })
			dispatch({
				type: ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_WATERMAEK,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_DLP_CONFIGURATION_LIST_WATERMAEK,
				payload: [],
			})
			callback(false, {})
		}
	}
}

/**
 * Action to create watermark
 * @param {Object} body
 * @param {Function} callback
 */

export const createWatermark = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_CREATE_WATERMARK, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to Update watermark
 * @param {Object} body
 * @param {Function} callback
 */

export const updateWatermark = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_UPDATE_WATERMARK, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to Delete Watermark
 * @param {Object} body
 * @param {Function} callback
 */

export const deleteWatermark = (body, callback) => {
    return async dispatch => {
		try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_DELETE_WATERMARK, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}
