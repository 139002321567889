/*************************************************
 * Tvastar
 * @exports
 * @file types.js
 * @author Rajasekar // on 23/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
export const ACTION_TYPES = {

	//Resources
	SET_RESOURCES_PROPS_DETAILS: 'SET_RESOURCES_PROPS_DETAILS',

	//Resource 


	SET_AUTH_TOKENS: 'SET_AUTH_TOKENS',
	SET_SUPPRESSION_LIST: 'SET_SUPPRESSION_LIST',
	SET_PROVIDERS_LIST: 'SET_PROVIDERS_LIST',
	SET_SERVICES_LIST: 'SET_SERVICES_LIST',
	SET_REGIONS_LIST: 'SET_REGIONS_LIST',
	SET_ACCOUNTS_LIST: 'SET_ACCOUNTS_LIST',
	SET_PROFILE_DETAILS: 'SET_PROFILE_DETAILS',
	SET_USER_MENU: 'SET_USER_MENU',
	SET_CATEGORIES_LIST: 'SET_CATEGORIES_LIST',
	SET_ACCOUNTS_DETAILS_LIST: 'SET_ACCOUNTS_DETAILS_LIST',
	SET_SEARCH_META_LIST: 'SET_SEARCH_META_LIST',
	SET_RESOURCE_SEARCH_META_LIST: 'SET_RESOURCE_SEARCH_META_LIST',
	SET_TAG_KEYS: 'SET_TAG_KEYS',
	

	SET_PROVIDER_COUNT: 'SET_PROVIDERS_COUNT',
	SET_STATUS_COUNT: 'SET_STATUS_COUNT',
	SET_ASSET_COUNT: 'SET_ASSET_COUNT',
	SET_REGION_COUNT: 'SET_REGION_COUNT',
	SET_CATEGORY_COUNT: 'SET_CATEGORY_COUNT',
	SET_SCORE_COUNT: 'SET_SCORE_COUNT',
	SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
	SET_SELECTED_GOVERNANCE_FILTER: 'SET_SELECTED_GOVERNANCE_FILTER',
	SET_EDIT_RULE_DETAILS: 'SET_EDIT_RULE_DETAILS',

	SET_EDIT_RULE_PREVIEW_DETAILS: 'SET_EDIT_RULE_PREVIEW_DETAILS',

	SET_EDIT_TAG_GROUP_DETAILS_TO_INITIAL: 'SET_EDIT_TAG_GROUP_DETAILS_TO_INITIAL',

	SET_EDIT_TAG_GROUPS_DETAILS: 'SET_EDIT_TAG_GROUPS_DETAILS',
	SET_INITIAL_RULE_DETAILS: 'SET_INITIAL_RULE_DETAILS',

	SET_SELECTED_TABLE_ROW: 'SET_SELECTED_TABLE_ROW',
	SET_ADVANCED_SELECTED_TABLE_ROW: 'SET_ADVANCED_SELECTED_TABLE_ROW',
	SET_GOVERNANCE_SELECTED_TABLE_ROW: 'SET_GOVERNANCE_SELECTED_TABLE_ROW',
	SET_GOVERNANCE_CATEGORY_LIST: 'SET_GOVERNANCE_CATEGORY_LIST',
	SET_GOVERNANCE_CATEGORY_LIST_NEW: 'SET_GOVERNANCE_CATEGORY_LIST_NEW',
	SET_GOVERNANCE_PROVIDER_COUNT_LIST: 'SET_GOVERNANCE_PROVIDER_COUNT_LIST',
	SET_GOVERNANCE_PRIORITY_COUNT_LIST: 'SET_GOVERNANCE_PRIORITY_COUNT_LIST',
	SET_GOVERNANCE_RESOURCE_COUNT_LIST: 'SET_GOVERNANCE_RESOURCE_COUNT_LIST',
	SET_GOVERNANCE_SIZING_EC2: 'SET_GOVERNANCE_SIZING_EC2',
	SET_GOVERNANCE_BLOCKSTORE: 'SET_GOVERNANCE_BLOCKSTORE',
	SET_GOVERNANCE_LOGTYPE: 'SET_GOVERNANCE_LOGTYPE',
	SET_GOVERNANCE_ENGINE_RDS: 'SET_GOVERNANCE_ENGINE_RDS',
	SET_GOVERNANCE_PROPS_DETAILS: 'SET_GOVERNANCE_PROPS_DETAILS',

	SET_LIST_OPERATING_SYSTEM: 'SET_LIST_OPERATING_SYSTEM',
	
	SET_ASSETS_BY_TAG_OPERATIONS: 'SET_ASSETS_BY_TAG_OPERATIONS',
	SET_NON_COMPLAINT_ASSETS: 'SET_NON_COMPLAINT_ASSETS',
	SET_TAG_GROUPS: 'SET_TAG_GROUPS',
	SET_TAG_GROUPS_SELECTED_TABLE_ROW: 'SET_TAG_GROUPS_SELECTED_TABLE_ROW',
	SET_TAG_COMPLIANCE_AGGREGATION_COUNT: 'SET_TAG_COMPLIANCE_AGGREGATION_COUNT',
	SET_TAG_COMPLIANCE_TOTAL_COUNT: 'SET_TAG_COMPLIANCE_TOTAL_COUNT',
	SET_TAG_OPERATION_COUNT: 'SET_TAG_OPERATION_COUNT',
	SET_APPLIED_TAGS_DAYWISE_COUNT: 'SET_APPLIED_TAGS_DAYWISE_COUNT',
	SET_GOVERNANCE_CONFIG_LIST: 'SET_GOVERNANCE_CONFIG_LIST',
	SET_ASSETS_LIST: 'SET_ASSETS_LIST',
	SET_ADVANCED_ASSETS_LIST: 'SET_ADVANCED_ASSETS_LIST',
	SET_RULES_LIST: 'SET_RULES_LIST',

	SET_NEW_RULES_LIST: 'SET_NEW_RULES_LIST',
	SET_NEW_EDIT_RULE_DETAILS: 'SET_NEW_EDIT_RULE_DETAILS',

	SET_POLICY_LIST: 'SET_POLICY_LIST',
	SET_POLICY_SELECTED_TABLE_ROW: 'SET_POLICY_SELECTED_TABLE_ROW',
	SET_IAM_USERS_ASSETS_LIST: 'SET_IAM_USERS_ASSETS_LIST',
	SET_IAM_EVENTS_CONDITIONS: 'SET_IAM_EVENTS_CONDITIONS',
	SET_IAM_ROLES_ASSETS_LIST: 'SET_IAM_ROLES_ASSETS_LIST',
	SET_CREATE_POLICY_DETAILS: 'SET_CREATE_POLICY_DETAILS',
	SET_EDIT_CATEGORY_SELECTED: 'SET_EDIT_CATEGORY_SELECTED',
	SET_CREATE_POLICY_DETAILS_EMPTY: 'SET_CREATE_POLICY_DETAILS_EMPTY',
	SET_IAM_EVENTS_CONDITIONS_EMPTY: 'SET_IAM_EVENTS_CONDITIONS_EMPTY',

	SET_ASSET_DETAILS: 'SET_ASSET_DETAILS',
	SET_ASSET_NETWORK_DETAILS: 'SET_ASSET_NETWORK_DETAILS',
	SET_ASSET_STORAGE_DETAILS: 'SET_ASSET_STORAGE_DETAILS',
	SET_ASSET_SG_DETAILS: 'SET_ASSET_SG_DETAILS',
	SET_IDENTIFIERS_LIST: 'SET_IDENTIFIERS_LIST',
	SET_FILTERED_IDENTIFIERS_LIST: 'SET_FILTERED_IDENTIFIERS_LIST',
	SET_DETECTORS_LIST: 'SET_DETECTORS_LIST',
	SET_CONFIGURATION_LIST: 'SET_CONFIGURATION_LIST',
	SET_ALL_TAGS: 'SET_ALL_TAGS',
	SET_ALL_MASTER_TAG_GROUPS: 'SET_ALL_MASTER_TAG_GROUPS',
	SET_TAG_GROUP_ID: 'SET_TAG_GROUP_ID',
	SET_ASSET_TAG_GROUP_CONDITION_ID: 'SET_ASSET_TAG_GROUP_CONDITION_ID',
	SET_ASSET_TAG_GROUP_CONDITIONS: 'SET_ASSET_TAG_GROUP_CONDITIONS',
	SET_APPLIED_TAG_ASSETS: 'SET_APPLIED_TAG_ASSETS',
	SET_TO_INITIAL_CREATE: 'SET_TO_INITIAL_CREATE',


	//DLP start
	SET_SELECTED_DLP_FILTER: 'SET_SELECTED_DLP_FILTER',
	SET_DLP_PROFILE_OVERALL_DISTRIBUTION: 'SET_DLP_PROFILE_OVERALL_DISTRIBUTION',
	SET_CROSS_ACCOUNT_DISTRIBUTION: 'SET_CROSS_ACCOUNT_DISTRIBUTION',
	SET_METADATA_TOTAL_COUNT: 'SET_METADATA_TOTAL_COUNT',
	SET_METADATA_RANGE_DISTRIBUTION: 'SET_METADATA_RANGE_DISTRIBUTION',
	SET_METADATA_OVERALL_DISTRIBUTION: 'SET_METADATA_OVERALL_DISTRIBUTION',
	SET_DLP_FILTER_PROPERTIES: 'SET_DLP_FILTER_PROPERTIES',
	SET_START_DAILY_TREND: 'SET_START_DAILY_TREND',
	SET_DATA_DAILY_TREND: 'SET_DATA_DAILY_TREND',
	
	SET_DLP_POLICIES_PRIVACY_LIST: 'SET_DLP_POLICIES_PRIVACY_LIST',
	SET_DLP_POLICIES_SECURITY_LIST: 'SET_DLP_POLICIES_SECURITY_LIST',

	SET_DLP_CONFIGURATION_LIST_WATERMAEK: 'SET_DLP_CONFIGURATION_LIST_WATERMAEK',
	SET_DLP_CONFIGURATION_LIST_ENGINE_EVENTS: 'SET_DLP_CONFIGURATION_LIST_ENGINE_EVENTS',
	SET_DLP_JOBS_LIST: 'SET_DLP_JOBS_LIST',
	SET_DLP_PROPS_DETAILS: 'SET_DLP_PROPS_DETAILS',
	SET_DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT: 'SET_DLP_CONFIGURATION_LIST_CUSTOMER_ACCOUNT',
	SET_DLP_CONFIGURATION_LIST_SOURCE_DATA: 'SET_DLP_CONFIGURATION_LIST_SOURCE_DATA',

	//DLP end


	//AIOps Start
	SET_AIOPS_COVERED_RESOURCES: 'SET_AIOPS_COVERED_RESOURCES',
	SET_AIOPS_REMEDIATION_ESTIMATE: 'SET_AIOPS_REMEDIATION_ESTIMATE',
	SET_REMEDIATION_DETAILS: 'SET_REMEDIATION_DETAILS',
	SET_ARTIFACT_DETAILS: 'SET_ARTIFACT_DETAILS',
	SET_REMEDIATION_STATUS: 'SET_REMEDIATION_STATUS',	
	SET_LIST_CASE_DETAILS: 'SET_LIST_CASE_DETAILS',
	SET_GET_CASE_DETAILS: 'SET_GET_CASE_DETAILS',
	SET_REMEDIATION_COUNT: 'SET_REMEDIATION_COUNT',
	SET_AIOPS_ACCOUNT_CASES_TOTAL_COUNT: 'SET_AIOPS_ACCOUNT_CASES_TOTAL_COUNT',
	SET_AIOPS_REGION_CASES_TOTAL_COUNT: 'SET_AIOPS_REGION_CASES_TOTAL_COUNT',
	SET_AIOPS_RESOURCE_CASES_TOTAL_COUNT: 'SET_AIOPS_RESOURCE_CASES_TOTAL_COUNT',
	SET_AIOPS_SEVERITY_CASES_TOTAL_COUNT: 'SET_AIOPS_SEVERITY_CASES_TOTAL_COUNT',
	SET_AIOPS_STATUS_CASES_TOTAL_COUNT: 'SET_AIOPS_STATUS_CASES_TOTAL_COUNT',
	SET_AIOPS_SERVICE_CASES_TOTAL_COUNT: 'SET_AIOPS_SERVICE_CASES_TOTAL_COUNT',
	SET_AIOPS_REMEDIATION_TREND: 'SET_AIOPS_REMEDIATION_TREND',
	SET_TOTAL_REMEDIATION_COUNT: 'SET_TOTAL_REMEDIATION_COUNT',
	SET_AIOPS_EVENT_DETAILS: 'SET_AIOPS_EVENT_DETAILS',
	SET_ALERT_EVENT_DETAILS: 'SET_ALERT_EVENT_DETAILS',
	SET_AIOPS_TOTAL_ALERT_COUNT: 'SET_AIOPS_TOTAL_ALERT_COUNT',
	SET_AIOPS_DAILY_TREND: 'SET_AIOPS_DAILY_TREND',
	SET_AIOPS_PROPS_DETAILS: 'SET_AIOPS_PROPS_DETAILS',
	SET_AIOPS_DAILY_TREND_BY_ASSETS: 'SET_AIOPS_DAILY_TREND_BY_ASSETS',
	SET_AIOPS_DIAGNOSTICS_LIST: 'SET_AIOPS_DIAGNOSTICS_LIST',
	SET_AIOPS_DIAGNOSTICS_DETAILS: 'SET_AIOPS_DIAGNOSTICS_DETAILS',
	SET_AIOPS_DIAGNOSTICS_TEMPLATE_LIST: 'SET_AIOPS_DIAGNOSTICS_TEMPLATE_LIST',
	SET_AIOPS_RCA_LIST: 'SET_AIOPS_RCA_LIST',
	SET_AIOPS_RCA_DETAILS: 'SET_AIOPS_RCA_DETAILS',
	SET_AUTOMATION_PROPS_DETAILS: 'SET_AUTOMATION_PROPS_DETAILS',
	SET_LIST_AUTOMATION_ACTIONS: 'SET_LIST_AUTOMATION_ACTIONS',
	//AIOPs End

	//Diagnostcis Start
	SET_DIAGNOSTICS_PROPS_DETAILS: 'SET_DIAGNOSTICS_PROPS_DETAILS',
	SET_DIAGNOSTICS_TROUBLESHOOT_DETAILS: 'SET_DIAGNOSTICS_TROUBLESHOOT_DETAILS',
	SET_DIAGNOSTICS_LIST: 'SET_DIAGNOSTICS_LIST',
	SET_DIAGNOSTICS_DETAILS: 'SET_DIAGNOSTICS_DETAILS',
	//Diagnostcis End
	
	//Microservices Start
	SET_MICROSERVICES_PROPS_DETAILS: 'SET_MICROSERVICES_PROPS_DETAILS',
	SET_OBSERVABILITY_MICROSERVCIES_RCA_DETAILS: 'SET_OBSERVABILITY_MICROSERVCIES_RCA_DETAILS',
	//Microservices End

	//Observability start
	SET_OBSERVABILITY_ALERT_TREND: 'SET_OBSERVABILITY_ALERT_TREND',
	SET_OBSERVABILITY_EVENT_TREND: 'SET_OBSERVABILITY_EVENT_TREND',
	SET_OBSERVABILITY_ANOMALY_TREND: 'SET_OBSERVABILITY_ANOMALY_TREND',
	SET_OBSERVABILITY_ERROR_TREND: 'SET_OBSERVABILITY_ERROR_TREND',
	//Observability end

	//observability query start
	SET_OBSERVABILITY_QUERY_RESULT: 'SET_OBSERVABILITY_QUERY_RESULT',
	SET_OBSERVABILITY_QUERY_LIST: 'SET_OBSERVABILITY_QUERY_LIST',
	//observability query end


	//cost start
	SET_COST_PROPS_DETAILS: 'SET_COST_PROPS_DETAILS',
	SET_COST_TAG_KEYS: 'SET_COST_TAG_KEYS',
	//cost end

}
