/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file EventAnalysis.js
 * @author Prakash // on 08/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
*************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import _ from 'lodash'
import { momentConvertionLocalToUtcTime } from '../../../../utils/utility'

import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getObservabilityTopEvents, getObservabilityEventsTotalCount } from '../../../../actions/aiops/ObservabilityAction'

import ApexDonutChart from '../../../common/charts/ApexDonutChart'
import ApexBarChart from '../../../common/charts/ApexBarChart'
import ApexTrendBarChart from '../../../common/charts/ApexTrendBarChart'

class EventAnalysis extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            selectedTab: 'Analysis',
            selectedTopErrorBy: 'service_asset',
            start_time: this.props.aiopsCommonPageFilter.start_time ? this.props.aiopsCommonPageFilter.start_time : this.props.start_time,
            end_time: this.props.aiopsCommonPageFilter.end_time ? this.props.aiopsCommonPageFilter.end_time : this.props.end_time,
        }
    }

    componentDidMount = () => {
        if(this.props.start_time && this.props.start_time !== "") {
            this.onSearch()
        } else if(this.props.aiopsCommonPageFilter && this.props.aiopsCommonPageFilter.start_time && this.props.aiopsCommonPageFilter.start_time !== "") {
            this.onSearch()
        }

        if(this.props.eventsTrend) {
            let graphData = {
                start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
            }
            let graphDetails = this.props.eventsTrend.results            
            if(graphDetails && graphDetails.data && graphDetails.data.count) {
                graphData = {
                    data: graphDetails.data.count,
                    labels: graphDetails.labels,
                    start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                    end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
                }
            }
            this.setState({ graphData })
        }
    }

    resetEventGraph = () => {
        let graphData = this.state.graphData
        this.setState({ graphData: {} },
            () => {
                this.setState({ graphData })
            }
        )
    }

    onSearch = () => {
        this.setState({
            showDonut: false,
            topEvents: {}, topAssets: {}
        }, () => {
            this.getObservabilityEventsTotalCount()
            this.getObservabilityTopEvents()
        })
    }

    getObservabilityTopEvents = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'top': 5,
            'aggregate_by': [["event_name", "asset_name"]]
            //'aggregate_by': [["importance","lifecycle","assets.resource_type","category"]]
        }

        this.props.getObservabilityTopEvents(params, (promise, response) => {
            if(promise) {
                let res = response.results && response.results.length && response.results[0].data ? response.results[0].data : {}
                this.setState({ topAssetEvents: res },
                    () => this.topEventsSectionGraph()
                )
            }
        }) 
    }

    topEventsSectionGraph = () => {
        if(this.state.topAssetEvents.event_name) {
            let list = this.state.topAssetEvents.event_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topEvents: topStatusData })
        }

        if(this.state.topAssetEvents.asset_name) {
            let list = this.state.topAssetEvents.asset_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topAssets: topStatusData })
        }        
    }

    getObservabilityEventsTotalCount = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'radial': true,
            'aggregate_by': [["resource_type", "lifecycle", "importance", "asset_name"]]
        }

        this.props.getObservabilityEventsTotalCount(params, (promise, response) => {
            if(promise) {
                this.setState({ rightSideTotalSection: response.results && response.results.length && response.results[0].data ? response.results[0].data : {} },
                    () => this.rigthSideTopSection()    
                )
            }
        }) 
    }

    rigthSideTopSection = () => { 
        //services Donut start
        let services = []
        let services_total_count = 0
        this.state.rightSideTotalSection.resource_type && Object.entries(this.state.rightSideTotalSection.resource_type).forEach(([key, value]) => {
            let donutRow = {}
            donutRow.item_count = value
            donutRow.item_name = key
            services.push(donutRow)
            services_total_count += value 
        })

        services = _.orderBy(services, ['item_count'], ['desc'])
        services = services.slice(0,10)

        let serviceDonutData = {
            "items": services,
            "label": "Services",
            "total_count": services_total_count
        }

        //services Donut start

        //lifeCycle dont start
        let lifeCycle = []
        let lifeCycle_total_count = 0
        this.state.rightSideTotalSection.lifecycle && Object.entries(this.state.rightSideTotalSection.lifecycle).forEach(([key, value]) => {
            let donutRow = {}
            donutRow.item_count = value
            donutRow.item_name = key
            lifeCycle.push(donutRow)
            lifeCycle_total_count += value 
        })

        lifeCycle = _.orderBy(lifeCycle, ['item_count'], ['desc'])
        lifeCycle = lifeCycle.slice(0,10)

        let lifeCycleDonutData = {
            "items": lifeCycle,
            "label": "LifeCycle",
            "total_count": lifeCycle_total_count
        }
        //lifeCycle dont end

        //eventTyp bar start
        let eventTypeProgressBar = []
        if(this.state.rightSideTotalSection.asset_name && Object.values(this.state.rightSideTotalSection.asset_name).length) {
            let total = Object.values(this.state.rightSideTotalSection.asset_name).reduce((a, b) => a + b);
            Object.entries(this.state.rightSideTotalSection.asset_name).forEach(([key, value]) => {
                let dataRow = {}
                dataRow.label = key
                dataRow.value = value
                dataRow.width = ((value * 100) / total) +'%'
                eventTypeProgressBar.push(dataRow)
            })
        }
        eventTypeProgressBar = _.orderBy(eventTypeProgressBar, ['value'], ['desc'])
        //eventTyp bar end

        //eventTyp bar start
        let imprtanceTypeProgressBar = []
        if(this.state.rightSideTotalSection.importance && Object.values(this.state.rightSideTotalSection.importance).length) {
            let total = Object.values(this.state.rightSideTotalSection.importance).reduce((a, b) => a + b);
            Object.entries(this.state.rightSideTotalSection.importance).forEach(([key, value]) => {
                let dataRow = {}
                dataRow.label = key
                dataRow.value = value
                dataRow.width = ((value * 100) / total) +'%'
                imprtanceTypeProgressBar.push(dataRow)
            })
        }
        imprtanceTypeProgressBar = _.orderBy(imprtanceTypeProgressBar, ['value'], ['desc'])
        //eventTyp bar end

        // if(this.state.rightSideTotalSection.asset_name) {
        //     let list = this.state.rightSideTotalSection.asset_name
        //     let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

        //     let topStatusData = {}
        //     let data = []
        //     labels.slice(0, 5).forEach(item => {
        //         data.push(list[item])
        //     })
            
        //     for(let i=labels.length; i<5; i++) {
        //         data.push(0)
        //         labels.push('empty')
        //     }

        //     topStatusData.data = data
        //     topStatusData.labels = labels
        //     this.setState({ topAssets: topStatusData })
        // }

        this.setState({ serviceDonutData, showDonut: true, lifeCycleDonutData, eventTypeProgressBar, imprtanceTypeProgressBar })
    }

    render() {
        return (
            <div className={`${this.props.start_time ? "p-3 bg-muted" : ""}`} style={{maxHeight: "100%"}}>{/* errorSectionScroll */}
                <div className="col-sm-12 mt-2">
                    <div className="row">
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.lifeCycleDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.lifeCycleDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.serviceDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.serviceDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}                                
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                    </div>
                </div>
                <div className="rounded counts_threads p-3 mt-2">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Events</label>
                                {this.state.topEvents && Object.entries(this.state.topEvents).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.topEvents}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            paddingLeft={-10}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="col-lg-6 pl-0">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Assets</label>
                                {this.state.topAssets && Object.entries(this.state.topAssets).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.topAssets}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            paddingLeft={-10}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <div className="bg-dark p-3 rounded">
                                <div className="d-flex justify-content-between">
                                    <label>Total Events Trend </label>
                                    <i className='far fa-redo cursorPointer' onClick={()=> this.resetEventGraph()}></i>
                                </div>
                                {this.state.graphData && Object.keys(this.state.graphData).length ?
                                    <div className="mt-n2"> 
                                        <ApexTrendBarChart
                                            graphData={this.state.graphData}
                                            graphRiskCountSection={false}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            yaxisTickAmount={2}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            legend={false}
                                            stacked={false}
                                            height={220}
                                            horizontal={false}
                                            barHeight={'40%'}
                                            barEndShape={'flat'}
                                            columnWidth={'0%'}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={true}
                                            className={"transparentTooltip"}
                                            colors={['#3DAFE8']}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
EventAnalysis.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},        
        eventsTrend: state.observability.observabilityEventTrend
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getObservabilityTopEvents,
    getObservabilityEventsTotalCount,
})(withRouter(EventAnalysis))