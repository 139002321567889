/*************************************************
 * Tvastar
 * @exports
 * @file ErrorAction.js
 * @author parakash // 17/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	ERRORENDPOINT,
	ERRORS_GET_DAILY_COUNT,
	ERRORS_GET_TOTAL_COUNT,
	ERRORS_LIST_ERROR_RESULTS,
	ERRORS_GET_TOP_ERRORS_WITH_MORE_SEVERITY,
	ERRORS_GET_TOP_ERRORS,
	ERRORS_GET_FIRST_SEEN_ERRORS,
	ERRORS_GET_LAST_SEEN_ERRORS,
	DLP_CONFIGURATION_GET_SOURCE_DATA_CATALOG_BASE_PARAMS,
} from '../../config'

import { ACTION_TYPES } from '../types'

/**
 * Action to get daily count
 * @param {Object} body
 * @param {Function} callback
 */
export const getDailyCount = (body, callback) => {
	return async dispatch => {
		try {
			const result = await API.post(ERRORENDPOINT, ERRORS_GET_DAILY_COUNT, { body })
			callback(true, result)
		} catch(err) {
			callback(false, {})
		}
	}
}

/**
 * Action to get daily count
 * @param {Object} body
 * @param {Function} callback
 */
 export const getObservabilityDailyTrend = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(ERRORENDPOINT, ERRORS_GET_DAILY_COUNT, { body })
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_ERROR_TREND,
				payload: response,
			})
			callback(true, response)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_OBSERVABILITY_ERROR_TREND,
				payload: {},
			})
			callback(false, {})
		}
	}
}

/**
 * Action to get total count
 * @param {Object} body
 * @param {Function} callback
 */
 export const getTotalCount = (body, callback) => {
	return async () => {
		await API.post(ERRORENDPOINT, ERRORS_GET_TOTAL_COUNT, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}
/**
 * Action to list error results
 * @param {Object} body
 * @param {Function} callback
 */
 export const listErrorResults = (body, callback) => {
	return async () => {
		await API.post(ERRORENDPOINT, ERRORS_LIST_ERROR_RESULTS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}

/**
 * Action to get top errors
 * @param {Object} body
 * @param {Function} callback
 */
 export const getTopErrors = (body, callback) => {
	return async () => {
		await API.post(ERRORENDPOINT, ERRORS_GET_TOP_ERRORS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			callback(false)
		})
    }
}