/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Remediation.js
 * @author Prakash // on 10/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import { apiToDevCall } from '../../../actions/commonAction'
import { 
    getRemediationDetails,
    getArtifactDetails,
    getRemediationStatus,
    listRemediation,
    startRemediation,
} from '../../../actions/aiops/RemediationAction'
import { setAiopsPropsDetails, getCaseDetails } from '../../../actions/aiops/AiopsAction'
import { capitalizeFirstLetter, onlyNumeric, momentDateGivenFormat } from '../../../utils/utility'
import _ from 'lodash'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import moment from 'moment'
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";


// const testHTML = `<div class="col-md-12 mt-2 mb-2"><h6 class="font-weight-bold text-primary">Overview</h6><div class="row mb-2"><div class="col-md-12"><label class="mb-0 font-weight-bold"> What is this Remediation?</label></div><div class="col-md-12 mb-2"><small class="mb-2">LOREMIPSUM LOREMIPSUM LOREMIPSUM LOREMIPSUM LOREMIPSUM LOREMIPSUM</small></div><div class="col-md-12"><label class="mb-0 font-weight-bold"> What steps involved in this Remediation?</label></div><div class="col-md-12"><small>Log in to your Windows instance using Remote Desktop. For more information, see Connect to your Windows instance.</small></div><div class="col-md-12"><small>2.Start the Disk Management utility. On the taskbar, open the context (right-click) menu for the Windows logo and choose Disk Management.</small></div><div class="col-md-12"><small>3.Bring the volume online. In the lower pane, open the context (right-click) menu for the left panel for the disk for the EBS volume. Choose Online.</small></div><div class="col-md-12 my-2"><small><img src="/static/media/aiops_architecture_diagram.db344755.png" class="img-fluid" alt="Acrhcitecture Diagram"></small></div><div class="col-md-12 my-2"><h6>Recommended Video</h6><small><div class=""><iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="640" height="360" src="https://www.youtube.com/embed/5Peo-ivmupE?enablejsapi=1&amp;origin=http%3A%2F%2Flocalhost%3A3000&amp;widgetid=1" id="widget2"></iframe></div></small></div></div></div>

//     <p>Link to <a href="https://www.google.com">Google.com</a></p>
// `;

const instanceType = [
    'Mac',
    'T4g',
    'T3'
]

const paramaters = [
    
    {
        "label": "Increase by",
        "name": "IncreasePercentage",
        "component": "textbox",
        "mandatory": true,
        "validation": {
            "data_type": "number",
            "min": 0,
            "max": 100
        },
        "unit": "Percent",
        "apiToCall": {
            "api": "commonServices",
            "name": "/list-log-type",
            "method": "post",
            "body": {
                "keys": [{
                        "key": "provider",
                        "value": "provider"
                    },
                    {
                        "key": "service",
                        "value": "service_name"
                    }
                ]
            }
        }
    },
    {
        "label": "List of files/directories to remove",
        "helper_text": "Eg:/home/ec2-user/dir1*",
        "name": "objects",
        "component": "multiple_textbox",
        "mandatory": false,
        "validation": {
            "data_type": "string_list",
            "min_array_length": 1,
            "min_str_length": 1
        }
    },
    {
        "label": "Date",
        "helper_text": "Eg:/home/ec2-user/dir1*",
        "name": "date",
        "component": "date",
        "mandatory": true,
        "validation": {
            "data_type": "date",
            "future_date": true,
        }
    },
    {
        "label": "Date Range",
        "helper_text": "Eg:/home/ec2-user/dir1*",
        "name": "date_range",
        "component": "date_range",
        "mandatory": true,
        "validation": {
            "data_type": "date_range",
            "past_date": true,
        }
    },
    {
        "label": "Select Option",
        "helper_text": "Eg:/home/ec2-user/dir1*",
        "name": "select",
        "component": "select",
        "mandatory": true,
        "validation": {
            "data_type": "select",
            "past_date": true,
        }
    },
    {
        "label": "Multi Select Option",
        "helper_text": "Eg:/home/ec2-user/dir1*",
        "name": "multi_select",
        "component": "multi_select",
        "mandatory": true,
        "validation": {
            "data_type": "multi_select",
            "past_date": true,
        }
    }
    
]


const today = moment();
const disableFutureDt = current => {
  return current.isBefore(today)
}
const yesterday = moment().subtract(1, 'day');
const disablePastDt = current => {
    return current.isAfter(yesterday)
}

class RemediationLeftSection extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        this.sliderWrap = React.createRef()
        
        this.state = {
            showLoading: false,
            
            remediationList: [],
            filteredRemediationList: [],
            selectedRecord: {},
            selectedRecordId: '',

            remediationDetails: {},
            showDetailLoading: false,
            
            remediationStared: false,
            remediationProcessed: false,

            progress: 30,
            remediationStatus: {},
            instanceType: instanceType,
            loop: 0,

            totalItemShowing: 0,
            showingLeftIcon: false,
            showingRightIcon: true,
			showingOffStart: 1,
            showingOff: 0,
            caseId: this.props.caseId,

            validationError: false,
            smapleState: [true]
        }   

        this.detailSteps = 0;
        
    }

    // handleDateTimeChange  = this.handleDateTimeChange.bind(this)

	componentDidMount = () => {
        this.setState({ showLoading: true })
        this.listRemediation()
        
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsPropsDetails && Object.entries(this.props.aiopsPropsDetails).length) {
            if(prevProps.aiopsPropsDetails.remediation_status_change && prevProps.aiopsPropsDetails.remediation_status_change !== this.props.aiopsPropsDetails.remediation_status_change && this.props.aiopsPropsDetails.remediation_status_change !== '')  {
                this.refreshListRemediation()
                this.updateCaseDetails()
            } else if(!prevProps.aiopsPropsDetails.remediation_status_change && this.props.aiopsPropsDetails.remediation_status_change && this.props.aiopsPropsDetails.remediation_status_change !== '') {
                this.refreshListRemediation()
                this.updateCaseDetails()
            }
        }
    }

    updateCaseDetails = () => {
        let caseDetailParams = {}
        caseDetailParams.case_id = this.state.caseId
        caseDetailParams.alerts = true
        caseDetailParams.assets = true
        this.props.getCaseDetails(caseDetailParams, (promise, result) => {})
    }

    refreshListRemediation = () => {
        let remList = {}
        remList.case_id = this.state.caseId
        this.props.listRemediation(remList, (promise, result) => {
            if(promise) {

                let selectedRecord = []
                let filteredRemediationList = []
                let filteredResult = result.filter(arr => arr.asset_name === this.props.selectedRemediation.asset_name)
                let selectedRecordStatus = filteredResult.status ? filteredResult.status : ' '
                let selectedAsset = filteredResult.asset_name
                let selectedAccountId = filteredResult.account_id
                let selectedRegion = filteredResult.region
                let selectedRecordId = filteredResult.remediation_id

                this.setState({ remediationList: result, filteredRemediationList, selectedAsset, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus })
                
            } else {
                this.setState({ remediationList: [] })
            }
        })
    }
    
    listRemediation = () => {
        let remList = {}
        remList.case_id = this.state.caseId
        
        this.props.listRemediation(remList, (promise, result) => {
            if(promise) {
                let selectedRecord = []
                let selectedRecordId = ''
                let filteredRemediationList = []
                let selectedRecordStatus = result[0].status ? result[0].status : ' '
                let selectedAsset = result[0].asset_name
                let selectedAccountId = result[0].account_id
                let selectedRegion = result[0].region

                if(result.length && (!result[0].status || result[0].status === 'Open')) {
                    filteredRemediationList = _.orderBy(result[0].remediations, ['recommended'])
                    selectedRecord = filteredRemediationList[0]
                    selectedRecordId = filteredRemediationList[0].processor_rule_id
                    let obj = {}
                    obj.asset_name = selectedAsset
                    obj.remediation_id = selectedRecordId
                    obj.account_id = selectedAccountId
                    obj.region = selectedRegion
                    obj.selectedRecord = selectedRecord
                    obj.sideMenu = 'Overview'
                    this.props.setAiopsPropsDetails('selected_remediation', obj)
                    if(filteredRemediationList.length) {
                        this.setState({ showLoading: false, remediationList: result, filteredRemediationList, selectedAsset, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus },
                            () => {
                                this.checkParamaters(selectedRecord)
                                this.getRemediationDetails()
                                this.slideItemRight('initiate')
                            }
                        )
                    } 
                } else {
                    selectedRecordId = result[0].remediation_id

                    let obj = {}
                    obj.asset_name = selectedAsset
                    obj.remediation_id = selectedRecordId
                    obj.account_id = selectedAccountId
                    obj.region = selectedRegion
                    obj.selectedRecord = selectedRecord
                    obj.sideMenu = 'Execution'
                    this.props.setAiopsPropsDetails('selected_remediation', obj)

                    this.setState({ showLoading: false, remediationList: result, filteredRemediationList, selectedAsset, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus },
                        () => {
                            this.getRemediationStatus(selectedRecordId)
                            this.slideItemRight('initiate')
                        }
                    )
                }
                
            } else {
                this.setState({ showLoading: false, remediationList: [], filteredRemediationList: [] })
            }
        })
    }

    onClickAsset = (asset_name) => {
        this.props.setAiopsPropsDetails('remediationStatus', {})
        if(this.state.selectedAsset !== asset_name) {
            this.setState({ selectedViewRecord: {}, viewSelectedParameters: [], remediationStatus: {}, validationError: false, remediationDetails: {} })
            let selectedAssetDetails = this.state.remediationList.filter(arr => arr.asset_name === asset_name)
            let selectedRecord = []
            let selectedRecordId = ''
            let filteredRemediationList = []
            let selectedRecordStatus = selectedAssetDetails[0].status ? selectedAssetDetails[0].status : ' '
            let selectedAsset = selectedAssetDetails[0].asset_name    
            let selectedAccountId = selectedAssetDetails[0].account_id
            let selectedRegion = selectedAssetDetails[0].region
            
            if(selectedAssetDetails.length && (!selectedAssetDetails[0].status || selectedAssetDetails[0].status === 'Open')) {
                filteredRemediationList = _.orderBy(selectedAssetDetails[0].remediations, ['recommended'])
                if(selectedAssetDetails[0].remediations && selectedAssetDetails[0].remediations.length) {
                    selectedRecord = filteredRemediationList[0]
                    selectedRecordId = filteredRemediationList[0].processor_rule_id

                    let obj = {}
                    obj.asset_name = selectedAsset
                    obj.remediation_id = selectedRecordId
                    obj.account_id = selectedAccountId
                    obj.region = selectedRegion
                    obj.selectedRecord = selectedRecord
                    obj.sideMenu = 'Overview'
                    this.props.setAiopsPropsDetails('selected_remediation', obj)

                    this.setState({ filteredRemediationList, selectedAsset, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus, remediationStatus: [] },
                        () => {
                            this.getRemediationDetails()
                        }
                    )
                } else {
                    this.setState({ noRemediationForselectedAsset: true, showLoading: false })
                }
            } else {
                selectedRecordId = selectedAssetDetails[0].remediation_id 

                let obj = {}
                obj.asset_name = selectedAsset
                obj.remediation_id = selectedRecordId
                obj.account_id = selectedAccountId
                obj.region = selectedRegion
                obj.selectedRecord = selectedRecord
                obj.sideMenu = 'Execution'
                this.props.setAiopsPropsDetails('selected_remediation', obj)

                this.setState({ filteredRemediationList, selectedAsset, selectedAccountId, selectedRegion, selectedRecord, selectedRecordId, selectedRecordStatus },
                    () => {
                        this.getRemediationStatus(selectedRecordId)
                    }
                )
            }
        }
    }

    getRemediationStatus = (processorId) => {
        let remStatus = {}
        remStatus.provider = Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider
        remStatus.account_id =  this.state.selectedAccountId
        remStatus.region = this.state.selectedRegion
        remStatus.processor_id = processorId

        this.props.getRemediationStatus(remStatus, (promise, result) => {
            if(promise) {
                let selectedRecordId = result.overall_status && result.overall_status.processor_rule_id ? result.overall_status.processor_rule_id : ''
                let selectedViewRecord = result.overall_status && result.overall_status.request && result.overall_status.request.parameters ? result.overall_status.request.parameters : {}
                this.setState({ remediationStatus: result, executionStatus: result.overall_status.status, selectedRecordId, selectedViewRecord, validationError: false },
                    () => this.getRemediationDetails()
                )
            } else {
                this.setState({ remediationStatus: [], validationError: false })
            }
        })
    }

    getRemediationDetails = () => {
        this.setState({ showDetailLoading: true, remediationDetails: {}, remediationProcessed: false, remediationStared: false, acknowledgment: false })
        let remDetails = {}
        remDetails.processor_rule_id =  this.state.selectedRecordId
        
        this.props.getRemediationDetails(remDetails, (promise, result) => {
            if(promise) {
                this.setState({ remediationDetails: result.details }, 
                    () => {
                        if(this.state.selectedViewRecord && Object.entries(this.state.selectedViewRecord).length) {
                            this.formViewParameters()
                        } else {
                            this.setState({ showLoading: false, showDetailLoading: false })
                        }
                    }
                )
            } else {
                this.setState({ showDetailLoading: false, remediationDetails: [] })
            }
        })

        let artifactDetails = {}
        artifactDetails.remediation_id =  this.state.selectedRecordId

        this.props.getArtifactDetails(artifactDetails, (promise, result) => {})
    }

    formViewParameters = () => {
        let selectedViewRecord = JSON.parse(this.state.selectedViewRecord)
        // let parameters = this.state.remediationDetails && this.state.remediationDetails.parameters

        let array = []
        this.state.remediationDetails && this.state.remediationDetails.parameters && this.state.remediationDetails.parameters.forEach((item, index) => {
            let dataRow = {}
            dataRow.label = item.label
            dataRow.value = selectedViewRecord[item.name]
            // Object.entries(selectedViewRecord).map(([key, value]) => {
            //     alert(key)
            //     alert(value)
            //     if(key === item.name) {
            //         dataRow.value = value
            //     }
            // })
            array.push(dataRow)
        })

        this.setState({ viewSelectedParameters: array, showLoading: false, showDetailLoading: false })
    }

    handleMultiSelectChange = (name, arrayValue) => {
        // let value = arrayValue.map(item => item.value)
        let label = arrayValue.map(item => item)
        let currentState = arrayValue

        let selectedOptions = arrayValue
        let prevState = this.state[name] ? this.state[name] : []

        // let value = arrayValue.map(item => item.value)
        // let label = arrayValue.map(item => item)

        prevState = prevState.filter(obj => obj.value === 'All')
        currentState = arrayValue.filter(obj => obj.value === 'All')
        if(prevState.length && currentState.length) {
            arrayValue = arrayValue.filter(obj => obj.value !== 'All')
            selectedOptions = []
            arrayValue.forEach(item => {
                instanceType.forEach(ins => {
                    if(ins === item) {
                        label = {}
                        label.value = ins
                        label.label = ins
                        selectedOptions.push(label)
                    }
                })
            })
        } else if(prevState.length && !currentState.length) {
            selectedOptions = []
        } else if(!prevState.length && !currentState.length) {
            selectedOptions = arrayValue
        } else if(!prevState.length && currentState.length) {
            selectedOptions = []
            instanceType.forEach(ins => {
                label = {}
                label.value = ins
                label.label = ins
                selectedOptions.push(label)
            })
        }

        this.setState({ [name]: selectedOptions })
    }

    getMultiSelectedCount = (type, array) => {
        return array.length && array.filter(obj => obj.value === 'All').length ? 'All' :  array.length ? array.length +' Selected' : 'All'
    }

    handleClickGroup = (val) => {
        this.setState({ selectedTableDrop: this.state.selectedTableDrop === val ? '' : val })
    }

    getCmdResult = (event) => {
        if(event.charCode === 13 || event.keyCode === 13) {
            let cmdResult = [
                {
                    "taskStartTime": "2021-02-15 12:58:43",
                    "processorId": "07e5faf3-5c84-4f4e-8301-45bede259b3a",
                    "eventName": "StopEC2Instance",
                    "type": "loop",
                    "taskStatus": "completed",
                    "taskDuration": "0:01:07",
                    "index": null,
                    "processor_rule_id": null,
                    "output": "{\"status\": \"Success\", \"data\": {\"WAIT60\": [{}], \"DescribeInstanceState\": [{\"id\": \"i-0dfbaa84a60153f25\", \"message\": \"Current State and Desired state is stopped\", \"status\": \"stopped\"}]}}",
                    "sequence": 4,
                    "taskMessage": "{}",
                    "taskName": "loop-sequence",
                    "taskId": "InstanceStopStateDecider",
                    "taskEndTime": "2021-02-15 12:59:50"
                },
                {
                    "taskStartTime": "2021-02-15 12:58:47",
                    "processorId": "07e5faf3-5c84-4f4e-8301-45bede259b3a",
                    "eventName": "StopEC2Instance",
                    "type": "task",
                    "taskStatus": "completed",
                    "taskDuration": "0:01:01",
                    "index": 0,
                    "processor_rule_id": null,
                    "output": "[{\"id\": \"i-0dfbaa84a60153f25\", \"message\": \"Current State and Desired state is stopped\", \"status\": \"stopped\"}]",
                    "sequence": 4,
                    "taskMessage": "{}",
                    "taskName": "DescribeInstanceState",
                    "taskId": "DescribeInstanceState",
                    "taskEndTime": "2021-02-15 12:59:48"
                }
            ]

            this.setState({ cmdResult })
        }
    }

    scrollBottom = () => {
        // console.log(this.scrollRef, 'his.scrollRef.current')
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' })
        // window.scrollTo(0, this.scrollRef);
    }
	
	slideLeft() {
		let move = 0
		move = parseInt(this.state.totalItemShowing)
		this.setState({ totalItemShowing: this.state.totalItemShowing - 1 })
		
        const totalAssets = this.state.remediationList.length
		let reqTabWidth = 0
		for (let i = 0; i < totalAssets; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
		}
		const averageSliderItemWidth = reqTabWidth/totalAssets
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
		const totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
		if(move > totalItemShowing) {
			if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
				this.sliderWrap.current.scrollLeft -= (this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)	
				this.setState({ showingRightIcon: true, showingOff: parseInt(this.state.showingOff - 1), showingOffStart: this.state.showingOffStart < 1 ? 1 : parseInt(this.state.showingOffStart-1)})
			} else {
				this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
			}
		} else {
			this.setState({ showingLeftIcon: false, totalItemShowing: totalItemShowing })
		}
	}

	slideItemRight(item) {
        const totalAssets = this.state.remediationList.length
		let reqTabWidth = 0
		for (let i = 0; i < totalAssets; i++) {
			reqTabWidth += this.sliderWrap.current.childNodes[i].getBoundingClientRect().width
        }
		const averageSliderItemWidth = reqTabWidth/totalAssets
		const sliderWrapWidth = this.sliderWrap.current.getBoundingClientRect().width
        let totalItemShowing = sliderWrapWidth/averageSliderItemWidth;

        if(totalItemShowing > Object.entries(this.state.remediationList).length) {
            totalItemShowing = Object.entries(this.state.remediationList).length
        }

		let move = 0
		if(this.state.totalItemShowing === 0) {
			this.setState({ totalItemShowing: parseInt(totalItemShowing) })
			move = parseInt(totalItemShowing)
		} else {
			move = parseInt(this.state.totalItemShowing + 1)
			this.setState({ totalItemShowing: this.state.totalItemShowing + 1 })
        }
        
		if (this.sliderWrap.current.childNodes[move-1] && this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {

            if(item === '') {
                this.sliderWrap.current.scrollLeft += (this.sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)
            }
            
			if(!this.state.showingOff && this.state.showingOff < totalItemShowing) {
				this.setState({ showingOff: parseInt(totalItemShowing), showingLeftIcon: true })
			} else{
				this.setState({ showingOffStart: (this.state.showingOffStart + 1), showingOff: parseInt(this.state.showingOff + 1), showingLeftIcon: true })
			}
		} else {
			this.setState({ showingRightIcon: false, showingOff: this.props.assetsList.length, totalItemShowing: this.props.assetsList.length })
		}
    }
    
    validationProcess = (index, value, mandatory, validation, name, label) => {
        let string = value ? value : ''
        let validationError = false
        let validationErrorMessage = ''
        if(validation.data_type) {
            if(validation.data_type === 'number' && string.length > 0) {
                string = onlyNumeric(string)

                if(validation.min && parseInt(string) < validation.min) {
                    validationError = true
                } 

                if(validation.max && parseInt(string) > validation.max) {
                    validationError = true
                }

                if(validation.min && validation.max && validationError === true) {
                    validationErrorMessage = label +' should be greater than '+ validation.min +' and lesser than '+ validation.max
                } else if(validation.min && !validation.max && validationError === true) {
                    validationErrorMessage = label +' should be greater than '+ validation.min
                } else if(!validation.min && validation.max && validationError === true) {
                    validationErrorMessage = label +' should be lesser than '+ validation.max
                }
            } 
        }
        this.setState({ [name+'_'+index]: string, validationError, [name+'_'+index+'_validationErrorMessage']: validationErrorMessage })
    }

    startRemediationProcess = () => {
        let validationError = false
        let obj = {}
        this.state.selectedRecord && this.state.selectedRecord.parameters && Object.entries(this.state.selectedRecord.parameters).length &&
            this.state.selectedRecord.parameters.forEach((item, index) => {
            if(item.component) {
                let validationErrorMessage = ''
                if(item.component === 'textbox') {
                    let number = this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : ''
                    if(item.validation.data_type && item.validation.data_type === 'number') {
                        number = onlyNumeric(number)

                        number = parseInt(number)
        
                        if(item.validation.min && number < item.validation.min) {
                            validationError = true
                        }
        
                        if(item.validation.max && number > item.validation.max) {
                            validationError = true
                        }

                        
        
                        if(item.validation.min && item.validation.max && validationError === true) {
                            validationErrorMessage = item.label +' should be greater than '+ item.validationmin +' and lesser than '+ item.validationmax
                        } else if(item.validation.min && !item.validation.max && validationError === true) {
                            validationErrorMessage = item.label +' should be greater than '+ item.validation.min
                        } else if(!item.validation.min && item.validation.max && validationError === true) {
                            validationErrorMessage = item.label +' should be lesser than '+ item.validation.max
                        }
                    }

                    if(item.mandatory) {
                        if(number.length === 0) {
                            validationError = true
                            validationErrorMessage = item.label +' is required'
                        }
                    }

                    obj[item.name] = number
                    this.setState({ [item.name+'_'+index]: number, validationError, [item.name+'_'+index+'_validationErrorMessage']: validationErrorMessage })
                }

                if(item.component === 'checkbox') {
                    obj[item.name] = this.state[item.name+'_'+index]
                }

                validationErrorMessage = ''
                // validationError = false
                if(item.component === 'multiple_textbox' && (item.validation.data_type && item.validation.data_type === 'string_list')) {
                    let length = 1
                    if(this.state[item.name+'_'+index+'_length']) {
                        length = this.state[item.name+'_'+index+'_length']
                    }
                    let input = this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : []
                    for(let i=0;i<length;i++) {
                        let string = this.state[item.name+'_'+index+'_'+i] ? this.state[item.name+'_'+index+'_'+i] : []
                        input.push(string)
                    }

                    if(item.mandatory) {
                        if(item.validation.min_array_length) {
                            if(input.length < item.validation.min_array_length) {
                                validationError = true
                                validationErrorMessage = 'Please add minimium of '+item.validation.min_array_length+' '+item.label
                            }
                        } else if(!input.length) {
                            validationError = true
                            validationErrorMessage = 'Please enter '+item.label
                        }
                    }
                    obj[item.name] = input
                    this.setState({ [item.name+'_'+index]: input, validationError, [item.name+'_'+index+'_validationErrorMessage']: validationErrorMessage })

                }

                validationErrorMessage = ''
                // validationError = false
                if(item.component === 'date') {
                    let date = ""
                    if(this.state[item.name+'_'+index]) {
                        date = this.state[item.name+'_'+index]
                    }

                    if(item.mandatory) {
                        if(date === "") {
                            validationError = true
                            validationErrorMessage = item.label +' is required'
                        }
                    }

                    this.setState({ [item.name+'_'+index]: date, validationError, [item.name+'_'+index+'_validationErrorMessage']: validationErrorMessage })
                }

                validationErrorMessage = ''
                if(item.component === 'date_range') {
                    let startDate = ""
                    if(this.state[item.name+'_start_date_'+index]) {
                        startDate = this.state[item.name+'_start_date_'+index]
                    }

                    let endDate = ""
                    if(this.state[item.name+'_end_date_'+index]) {
                        endDate = this.state[item.name+'_end_date_'+index]
                    }

                    if(startDate !== "" && endDate !== "") {
                        if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
                            validationError = true
                            validationErrorMessage = 'End date should be greater than start date'
                        }
                    }

                    if(item.mandatory) {
                        if(startDate === "" && endDate === "") {
                            validationError = true
                            validationErrorMessage = 'start and End date is required'
                        } else if(startDate === "") {
                            validationError = true
                            validationErrorMessage = 'Start date is required'
                        } else if(endDate === "") {
                            validationError = true
                            validationErrorMessage = 'End date is required'
                        }
                    } else {
                        if(startDate !== "" || endDate !== "") {
                            if(startDate === "") {
                                validationError = true
                                validationErrorMessage = 'Start date is required'
                            } else if(endDate === "") {
                                validationError = true
                                validationErrorMessage = 'End date is required'
                            }
                        }
                    }
                    

                    this.setState({ [item.name+'_start_date_'+index]: startDate, [item.name+'_end_date_'+index]: endDate, validationError, [item.name+'_'+index+'_validationErrorMessage']: validationErrorMessage })
                }

                validationErrorMessage = ''
                validationError = false
                if(item.component === 'select') {
                    let data = ""
                    if(this.state[item.name+'_'+index]) {
                        data = this.state[item.name+'_'+index]
                    }

                    if(item.mandatory) {
                        if(data === "") {
                            validationError = true
                            validationErrorMessage = item.label +' is required'
                        }
                    }

                    this.setState({ [item.name+'_'+index]: data, validationError, [item.name+'_'+index+'_validationErrorMessage']: validationErrorMessage }
                    )
                }

                validationErrorMessage = ''
                // validationError = false
                if(item.component === 'multi_select') {
                    let data = []
                    if(this.state[item.name+'_'+index]) {
                        data = this.state[item.name+'_'+index]
                    }
                    if(item.mandatory) {
                        if(!data.length) {
                            validationError = true
                            validationErrorMessage = item.label +' is required'
                        }
                    }

                    this.setState({ [item.name+'_'+index]: data, validationError, [item.name+'_'+index+'_validationErrorMessage']: validationErrorMessage })
                }                
            }
        })

        if(!validationError) {
            let remStart = {}
            remStart.provider = Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0].toLowerCase() : this.props.caseDetails.provider
            remStart.accountId = this.state.selectedAccountId
            remStart.region = this.state.selectedRegion
            remStart.processor_rule_id =  this.state.selectedRecordId
            remStart.asset_id = this.state.selectedAsset
            remStart.source = "aiops"
            remStart.source_id = this.state.caseId
            remStart.parameters = obj

            // console.log('remStart', remStart)

            this.props.setAiopsPropsDetails('remediation_status_change', '')
            if(remStart.accountId !== '' && remStart.accountId !== '') {
                this.props.startRemediation(remStart, (promise, result) => {
                    if(promise) {
                        this.setState({ startRemediation: result, remediationStared: false, remediationProcessed: true },
                            () => {
                                this.callRemediationStatus(result.processor_id)
                            }
                        )
                    } else {
                        this.setState({ startRemediation: [], remediationStared: false, remediationProcessed: false })
                    }
                })
            }

        } else {
            this.setState({ remediationStared: false })
        }
    }

    callRemediationStatus = (processor_id) => {
        setTimeout(() => {
            let obj = {}
            obj.asset_name = this.state.selectedAsset
            obj.remediation_id = processor_id
            obj.account_id = this.state.selectedAccountId
            obj.region = this.state.selectedRegion
            obj.selectedRecord = this.state.selectedRecord
            obj.sideMenu = 'Execution'
            obj.startExection = 'true'
            this.props.setAiopsPropsDetails('selected_remediation', obj)
        }, 5000)
	}

    addTextbox = (name, label, index) => {
        let rowList = [true]
        if(this.state[name+'_'+index+'_length']) {
            rowList = [...this.state[name+'_'+index+'_length']];
            rowList.push(true);
        } else {
            rowList = [...this.state.smapleState];
            rowList.push(true);
        }
        
        this.setState({ [name+'_'+index+'_length']: rowList })
        // <div className="d-flex">
        // <input 
        //     type="text" 
        //     className="form-control mr-1" 
        //     placeholder={`Enter ${label}`}
        //     value={this.state[name+'_'+index] ? this.state[name+'_'+index] : ''}
        // />
    }
  
    handleDateTimeChange(date, name) {
        // let startDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
        // let endDate = this.state.endDate
        // if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
        // endDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD hh:mm A')
        // }
        // this.setState({ startDate, endDate })
    }

    handleDateChange = (date, name, index) => {
        date = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
        this.setState({ [name+'_'+index]: date })
    }

    handelDateRangeChange = (date, name, index, type) => {
        let validationError = this.state.validationError
        let validationErrorMessage = ''
        if(type === 'start_date') {
            let startDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
            let endDate = this.state[name+'_end_date_'+index] ? this.state[name+'_end_date_'+index] : ''
            if(endDate !== '') {
                if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
                    // endDate = startDate
                    validationError = true
                    validationErrorMessage = 'End date should be greater than start date'
                }

            }
            this.setState({ [name+'_start_date_'+index]: startDate, validationError, [name+'_'+index+'_validationErrorMessage']: validationErrorMessage },
                //() => this.checkState()
            )
        } else {
            let endDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
            let startDate = this.state[name+'_start_date_'+index] ? this.state[name+'_start_date_'+index] : ''
            if(startDate !== '') {
                if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
                    // startDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD hh:mm A')
                    validationError = true
                    validationErrorMessage = 'Start date should be lesset than end date'
                }
            }
            this.setState({ [name+'_end_date_'+index]: endDate, validationError, [name+'_'+index+'_validationErrorMessage']: validationErrorMessage })
        }
    }

    validateDateFunction = (validation) => {
        // alert(1)
        if(validation) {
            if(validation.future_date) {
                return disablePastDt
            } else if(validation.past_date) {
                return disableFutureDt
            } else {
                return true
            }
        } else {
            return true
        }
    }

    checkParamaters = (selectedRecord) => {
        if(selectedRecord.parameters && Object.entries(selectedRecord.parameters).length) {
            this.state.selectedRecord.parameters.forEach((item, index) => {
                if(item.component === 'select' && item.apiToCall && Object.entries(item.apiToCall).length) {
                    // this.funcitonApiCall(item, index)
                    let params = {}
                    let keyRequest =  item.apiToCall.body && item.apiToCall.body.keys
                    keyRequest && keyRequest.forEach(item => {
                        params[item.key] = item.value
                    })
        
                    let valueRequest =  item.apiToCall.body && item.apiToCall.body.values
                    valueRequest && valueRequest.forEach(item => {
                        params[item.key] = item.value
                    })

                    let response_field = item.apiToCall.response_field ? item.apiToCall.response_field : ''
                    let name = item.apiToCall.name
        
                    if(!this.state[item.name+'_apiCall_'+index]) {
                        this.props.apiToDevCall(name, params, (success, result) => {
                            if (success) {
                                let dropdowntValues = []
                                result.length && result[0].results && result[0].results.forEach(res => {
                                    // let str = response_field
                                    let split = response_field.split('.')
                                    // split = this.strToObj(res, split)
                                    dropdowntValues.push(this.strToObj(res, split))
                                })
                                this.setState({ [item.name+'_apiCall_'+index]: dropdowntValues })
                            }
                        })
                    }
                }
            })
        }                                                                     
    }

    strToObj = (res, split) => {
        for (var i = 0; i < split.length; i++)
            res = res[split[i]];
        return res;
        // let obj = {}
        // var parts = string.split('.');
        // var newObj = obj[parts[0]];
        // if (parts[1]) {
        //   parts.splice(0, 1);
        //   var newString = parts.join('.');
        //   return this.strToObj(newObj, newString);
        // }
        // return newObj;
     }

	render() {
		return (
            <div className='col-md-12'>
                <div className='tab-content p-0' id='myTabContent'>                                        
                    <div className='tab-pane fade show active'>
                        <p className="mb-0"><b>Remediation</b></p>
                        <div className="d-flex justify-content-between">
                            <p className="small">Showing the remediation options</p>
                            <div className="d-flex">
                                <small className="mr-3">
                                    Showing 
                                    {this.state.remediationList && Object.entries(this.state.remediationList).length ?  
                                        ' '+(this.state.showingOffStart) +' - '+ (this.state.showingOff ? this.state.showingOff : Object.entries(this.state.remediationList).length) +' ('+Object.entries(this.state.remediationList).length+') '
                                    : ' 0'
                                    } 
                                </small>
                                <span className={`far fa-chevron-left mr-3 text-muted cursorPointer ${this.state.showingLeftIcon ? '' : 'displayNone'}`} onClick={() => this.slideLeft()}></span>
                                <span className={`far fa-chevron-right cursorPointer ${this.state.showingRightIcon ? '' : 'displayNone'}`}  onClick={() => this.slideItemRight('')}></span>
                            </div>
                        </div>
                        <div className="container-fluid mt-3">
                            <div className={`row ${!this.state.showLoading ? '' : 'disabled'}`}>
                                <div className="col-12 d-flex flex-nowrap overflow-hidden" ref={this.sliderWrap}>
                                    {this.state.remediationList && this.state.remediationList.length ? 
                                        this.state.remediationList.map(item => {
                                            return(
                                            <div onClick={() => this.setState({ showLoading: this.state.selectedAsset !== item.asset_name ? true : false, noRemediationForselectedAsset: false }, () => this.onClickAsset(item.asset_name))} 
                                                className={`alert alert-${item.status ? ((item.status === 'completed' || item.status === 'Success') ? 'success' : (item.status === 'Failed' || item.status === 'failed') ? 'danger' : item.status === 'InProgress' ? 'warning' : 'secondary') : 'secondary'}
                                                font-weight-bold mr-3 remediationListWidth cursorPointer 
                                                ${item.asset_name === this.state.selectedAsset && item.status ? ((item.status === 'completed' || item.status === 'Success') ? 'success success-border' : (item.status === 'Failed' || item.status === 'failed') ? 'danger border-danger' : item.status === 'InProgress' ? 'warning warning-border' : 'border-dark') : (item.asset_name === this.state.selectedAsset ? 'border-dark' : '')}`}>
                                                <div className="d-flex">
                                                    <div>
                                                        <i className="mr-2 fa fa-check-circle"></i>
                                                    </div>
                                                    <div>
                                                        {item.asset_name}
                                                    </div>
                                                </div>
                                                {item.resource_type ?
                                                    <div className="d-flex">
                                                        <div className={`text-${item.resource_type && item.status ? ((item.status === 'completed' || item.status === 'Success') ? 'success' : (item.status === 'Failed' || item.status === 'failed') ? 'danger' : item.status === 'InProgress' ? 'warning' : 'secondary') : 'tranparent'}`}>
                                                            <i className="mr-2 fa fa-circle f10"></i>
                                                        </div>
                                                        <div>
                                                            {item.resource_type}
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                            )
                                        })
                                    : null
                                    }
                                </div>
                            </div>
                            {this.state.remediationList && this.state.remediationList.length && !this.state.showLoading && !this.state.noRemediationForselectedAsset ?
                                <div className="row">
                                    <div className="col-md-5 vh-border-right">
                                        <small className="font-weight-bold">Remediations</small>
                                        {this.state.filteredRemediationList.length ? 
                                            this.state.filteredRemediationList.map(item => {
                                                return (
                                                    <div className={`d-flex rounded p-2 mb-3 ${this.state.selectedRecordId === item.processor_rule_id ? 'bg-light disabled' :''} ${this.state.remediationStared ? 'disabled' : ''} `}  
                                                    onClick={() => this.setState({ showDetailLoading: true, selectedRecordId: item.processor_rule_id, selectedRecord: item, showApplyBtn: false },
                                                        () => {
                                                            this.checkParamaters(item)
                                                            this.getRemediationDetails()
                                                            let obj = {}
                                                            obj.asset_name = this.state.selectedAsset
                                                            obj.remediation_id = this.state.selectedRecordId
                                                            obj.account_id = this.state.selectedAccountId
                                                            obj.region = this.state.selectedRegion
                                                            obj.selectedRecord = this.state.selectedRecord
                                                            obj.sideMenu = 'Overview'
                                                            this.props.setAiopsPropsDetails('selected_remediation', obj)
                                                        }
                                                        )}>

                                                        {this.state.selectedRecordId === item.processor_rule_id ?
                                                            <div className="align-self-center mr-2">
                                                                <i className="fad fa-check-circle text-success"></i>
                                                            </div>
                                                        : 
                                                            <div className="align-self-center mr-2">
                                                                <i className="fad fa-circle text-light"></i>
                                                            </div>
                                                        }
                                                        <div>
                                                            <p className="mb-0">{item.processor_rule_id}</p>
                                                            <small>{item.description}</small>
                                                        </div>
                                                            {item.recommended ?
                                                                <span className="badge badge-primary align-self-center ml-auto text-white">
                                                                Recommended
                                                                </span>
                                                            : this.state.selectedRecordId === item.processor_rule_id ?
                                                                <span className="badge badge-success align-self-center ml-auto text-white">
                                                                Selected
                                                                </span>
                                                            : null
                                                            }
                                                    </div>
                                                )
                                            })
                                        : 
                                            this.state.remediationDetails && Object.entries(this.state.remediationDetails).length ?
                                                <div className={`d-flex rounded p-2 mb-3 bg-light`}>
                                                    <div className="align-self-center mr-2">
                                                        <i className="fad fa-check-circle text-success"></i>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0">{this.state.remediationDetails.processor_rule_id}</p>
                                                        <small>{this.state.remediationDetails.description}</small>
                                                    </div>
                                                    <span className="badge badge-success align-self-center ml-auto text-white">
                                                        Selected
                                                    </span>
                                                </div>
                                            : null
                                        }
                                    </div>
                                    <div className="col-md-7">
                                        {this.state.remediationDetails && Object.entries(this.state.remediationDetails).length ?
                                            <div className="rounded bg-white4 p-3 mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="mb-0"><b>Tvastr Remediation</b></p>
                                                        <p className="small mb-0">Why this is recommended <span className="f14" id="whyRecommended">?</span></p>
                                                    </div>
                                                    <UncontrolledPopover className="recommendedPopup" trigger="hover" placement="top" target='whyRecommended'>
                                                        <PopoverHeader>
                                                            <label className="mb-0 font-weight-bold"> Why is this recommended ?</label>
                                                        </PopoverHeader>
                                                        <PopoverBody>
                                                            <div className="col-md-12">
                                                                <small>Log in to your Windows instance using Remote Desktop. For more information, see Connect to your Windows instance.</small>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <small>2.Start the Disk Management utility. On the taskbar, open the context (right-click) menu for the Windows logo and choose Disk Management.</small>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <small>3.Bring the volume online. In the lower pane, open the context (right-click) menu for the left panel for the disk for the EBS volume. Choose Online.</small>
                                                            </div>
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </div>
                                                
                                                <div className="row mt-2">
                                                    <div className="col-md-6">
                                                        <p className="mb-2">Title: <b>{this.state.remediationDetails.title}</b></p>
                                                        <p className="mb-2">ID: <b>{this.state.remediationDetails.processor_rule_id}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="mb-2">Description: <b>{this.state.remediationDetails.description}</b></p>
                                                    </div>
                                                    {Object.entries(this.state.selectedRecord).length ?
                                                        <div className="col-md-12">
                                                            <p className="mb-2">Configuration Required: <small className={`mb-1 badge ${this.state.selectedRecord.parameters && Object.entries(this.state.selectedRecord.parameters).length ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.state.selectedRecord.parameters && Object.entries(this.state.selectedRecord.parameters).length ? 'Yes' : 'No'}</small></p>
                                                        </div>
                                                    : 
                                                    null }
                                                </div>
                                                {this.state.remediationStatus && this.state.remediationStatus.overall_status && this.state.remediationStatus.overall_status.status ?
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p class="mb-2">Status:
                                                            <small className={`ml-1 badge badge-outline-${(this.props.remediationStatus.overall_status.status === 'completed' || this.props.remediationStatus.overall_status.status === 'Success') ? 'success' : this.props.remediationStatus.overall_status.status === 'Failed' ?'danger' : 'warning' }-black pt-2`}>
                                                                {capitalizeFirstLetter(this.props.remediationStatus.overall_status.status)}
                                                            </small>
                                                            </p>
                                                        </div>
                                                    </div>                                                
                                                : null}
                                            </div>
                                        : null }
                                        {this.state.remediationStatus && !this.state.remediationStatus.overall_status ?
                                            this.props.caseDetails.status !== 'Closed' && this.state.remediationDetails && !this.state.remediationProcessed && this.state.selectedRecord.parameters && Object.entries(this.state.selectedRecord.parameters).length ?
                                                <div className="rounded bg-white4 p-3 mb-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="mb-0"><b>Configure Remediation</b></p>
                                                            {/* <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p> */}
                                                        </div>
                                                    </div>
                                                    {this.state.selectedRecord.parameters.map((item, index) => {
                                                        return(
                                                            <div className="row mt-2">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="d-flex justify-content-between">
                                                                            {item.component !== 'checkbox' ? 
                                                                                <label>{item.label}</label>
                                                                            : null}
                                                                            {item.component === 'multiple_textbox' ?
                                                                                <small className="textInfo cursorPointer" onClick={() => this.addTextbox(item.name, item.label, index) }>Add New Row</small>
                                                                            : null }
                                                                        </div>
                                                                        {item.component === 'textbox' ?
                                                                            <React.Fragment>
                                                                                <div className="d-flex">
                                                                                    <input 
                                                                                        type="text" 
                                                                                        className={`form-control mr-1 f12 ${item.validation && item.validation.data_type === 'number' ? 'w-25': 'w-75'}`}
                                                                                        placeholder={`Enter ${item.label}`}
                                                                                        value={this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : ''}
                                                                                        onChange={e => this.validationProcess(index, e.target.value, item.mandatory, item.validation, item.name, item.label)}
                                                                                    />                                                        
                                                                                
                                                                                    {item.unit ?
                                                                                        <small className="align-self-center">{item.unit}</small>
                                                                                    : null
                                                                                    }
                                                                                </div>
                                                                                {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                                    <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                                                ) : null}
                                                                            </React.Fragment>
                                                                        : item.component === 'multiple_textbox' ?
                                                                            <React.Fragment>
                                                                                {this.state[item.name+'_'+index+'_length'] ? 
                                                                                    this.state[item.name+'_'+index+'_length'].map((add, i) => {
                                                                                        return (
                                                                                            <div className="d-flex">
                                                                                                <input 
                                                                                                    type="text" 
                                                                                                    className="form-control mr-1 mb-2" 
                                                                                                    placeholder={`Enter ${item.label}`}
                                                                                                    value={this.state[item.name+'_'+index+'_'+i] ? this.state[item.name+'_'+index+'_'+i] : ''}
                                                                                                    onChange={e => this.setState({ [item.name+'_'+index+'_'+i]: e.target.value })}
                                                                                                />   
                                                                                                {item.unit ?
                                                                                                    <small className="align-self-center">{item.unit}</small>
                                                                                                : null
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                : 
                                                                                    <div className="d-flex">
                                                                                        <input 
                                                                                            type="text" 
                                                                                            className="form-control mr-1 mb-2" 
                                                                                            placeholder={`Enter ${item.label}`}
                                                                                            value={this.state[item.name+'_'+index+'_0'] ? this.state[item.name+'_'+index+'_0'] : ''}
                                                                                            onChange={e => this.setState({ [item.name+'_'+index+'_0']: e.target.value })}
                                                                                        />   
                                                                                        {item.unit ?
                                                                                            <small className="align-self-center">{item.unit}</small>
                                                                                        : null
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                                                            
                                                                                {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                                    <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                                                ) : null}
                                                                            
                                                                            </React.Fragment>
                                                                        : item.component === 'select' ?
                                                                            <React.Fragment>
                                                                                <Select
                                                                                    placeholder={'All'}
                                                                                    isSearchable={false}
                                                                                    className='f13 p-0 form-control reactSelectDrop'
                                                                                    value={({
                                                                                        value: this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : 'Select',
                                                                                        label: this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : 'Select',
                                                                                    })}
                                                                                    options={this.state[item.name+'_apiCall_'+index] && this.state[item.name+'_apiCall_'+index].map(item => ({
                                                                                        value: item,
                                                                                        label: item,	
                                                                                    }))}
                                                                                    onChange={event =>  
                                                                                        this.setState({[item.name+'_'+index]: event.value})
                                                                                    }
                                                                                />
                                                                                {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                                    <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                                                ) : null}
                                                                            </React.Fragment>
                                                                        : item.component === "checkbox" ? 
                                                                            <React.Fragment>
                                                                                <div className="d-flex">
                                                                                    <div className="form-group form-check">
                                                                                        <input type="checkbox" 
                                                                                            onChange={() => this.setState({ [item.name+'_'+index]: !this.state[item.name+'_'+index]})} 
                                                                                            className="form-check-input" 
                                                                                        />
                                                                                        
                                                                                        <label className="form-check-label">{item.label ? item.label : ''}</label>
                                                                                    </div>
                                                                                </div>
                                                                                {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                                    <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                                                ) : null}
                                                                            </React.Fragment>
                                                                        : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group form-check">
                                                                <input type="checkbox" checked={this.state.acknowledgment} onClick={() => this.setState({ acknowledgment: !this.state.acknowledgment, showApplyBtn: !this.state.showApplyBtn })} className="form-check-input" />
                                                                <label className="form-check-label">I here by confirm to apply the above choosen remediation</label>
                                                            </div>
                                                            {this.state.remediationStared ?
                                                                <button className="btn btn-success">
                                                                    Applying <Spinner size='sm' color='light' className='ml-2' />
                                                                </button>
                                                            : 
                                                                <button className={`btn ${this.state.showApplyBtn ? 'btn-success' : 'disabled btn-light'}`} 
                                                                onClick={ () => this.setState({ remediationStared: true }, () => this.startRemediationProcess())}>Apply</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            : this.props.caseDetails.status !== 'Closed' && !this.state.remediationProcessed && this.state.selectedRecordStatus && this.state.selectedRecordStatus === ' ' ?
                                                <div class="rounded bg-white4 p-3 mb-3">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="mb-0"><b>Configure Remediation</b></p>
                                                            {/* <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p> */}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group form-check">
                                                                <input type="checkbox" onClick={() => this.setState({ showApplyBtn: !this.state.showApplyBtn })} class="form-check-input" />
                                                                <label class="form-check-label">I here by confirm to apply the above choosen remediation</label>
                                                            </div>
                                                            {this.state.remediationStared ?
                                                                <button className="btn btn-success">
                                                                    Applying <Spinner size='sm' color='light' className='ml-2' />
                                                                </button>
                                                            : 
                                                                <button class={`btn ${this.state.showApplyBtn ? 'btn-success' : 'disabled btn-light'}`} 
                                                                onClick={ () => this.setState({ remediationStared: true }, () => this.startRemediationProcess())}>Apply</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>  
                                            : null        
                                        : null }   
                                        {this.state.viewSelectedParameters && this.state.viewSelectedParameters.length ?
                                            <div className="rounded bg-white4 p-3 mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="mb-0"><b>Configure Remediation</b></p>
                                                        {/* <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p> */}
                                                    </div>
                                                </div>
                                                {this.state.viewSelectedParameters.map((item, index) => {
                                                    return(
                                                        <div className="row mt-2">
                                                            <div className="col-md-12">
                                                                <p className="mb-2">{item.label}: <b>{item.value}</b></p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        : null          
                                        }
                                    </div>
                                </div>
                            : this.state.remediationList && !this.state.remediationList.length && !this.state.showLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            : this.state.noRemediationForselectedAsset ? 
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no Remediations. Please try adjusting your filter.</p>
                                </div>
                            : this.state.showLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='dark' size='lg' />
                                </div>
                            : null
                            }
                            <div className="rounded bg-white4 p-3 mb-3 w-50 float-right displayNone">
                                {paramaters.map((item, index) => {
                                    return (
                                        <div className="row mt-2">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="d-flex justify-content-between">
                                                        <label>{item.label}</label>
                                                        {item.component === 'multiple_textbox' ?
                                                            <small className="textInfo cursorPointer" onClick={() => this.addTextbox(item.name, item.label, index) }>Add New Row</small>
                                                        : null }
                                                    </div>
                                                    {item.component === 'textbox' ?
                                                        <React.Fragment>
                                                            <div className="d-flex">
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control mr-1" 
                                                                    placeholder={`Enter ${item.label}`}
                                                                    value={this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : ''}
                                                                    onChange={e => this.validationProcess(index, e.target.value, item.mandatory, item.validation, item.name, item.label)}
                                                                />                                                        
                                                            
                                                                {item.unit ?
                                                                    <small className="align-self-center">{item.unit}</small>
                                                                : null
                                                                }
                                                            </div>
                                                            {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                            ) : null}
                                                        </React.Fragment>
                                                    : item.component === 'multiple_textbox' ?
                                                        <React.Fragment>
                                                            {this.state[item.name+'_'+index+'_length'] ? 
                                                                this.state[item.name+'_'+index+'_length'].map((add, i) => {
                                                                    return (
                                                                        <div className="d-flex">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control mr-1 mb-2" 
                                                                                placeholder={`Enter ${item.label}`}
                                                                                value={this.state[item.name+'_'+index+'_'+i] ? this.state[item.name+'_'+index+'_'+i] : ''}
                                                                                onChange={e => this.setState({ [item.name+'_'+index+'_'+i]: e.target.value })}
                                                                            />   
                                                                            {item.unit ?
                                                                                <small className="align-self-center">{item.unit}</small>
                                                                            : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            : 
                                                                <div className="d-flex">
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control mr-1 mb-2" 
                                                                        placeholder={`Enter ${item.label}`}
                                                                        value={this.state[item.name+'_'+index+'_0'] ? this.state[item.name+'_'+index+'_0'] : ''}
                                                                        onChange={e => this.setState({ [item.name+'_'+index+'_0']: e.target.value })}
                                                                    />   
                                                                    {item.unit ?
                                                                        <small className="align-self-center">{item.unit}</small>
                                                                    : null
                                                                    }
                                                                </div>
                                                            }
                                                                                                         
                                                            {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                            ) : null}
                                                        
                                                        </React.Fragment>
                                                    : item.component === 'date' ?
                                                        <React.Fragment>
                                                        <Datetime 
                                                            value={this.state[item.name+'_'+index] ? momentDateGivenFormat(this.state[item.name+'_'+index], 'DD MMM YYYY hh:mm A') : 'Pick Date'}
                                                            /* onChange={this.handleDateTimeChange(this, item.name)} */
                                                            onChange={(e) => this.handleDateChange(e._d, item.name, index)}
                                                            dateFormat={'DD MMM YYYY'}
                                                            timeFormat={'hh:mm A'}
                                                            inputProps={{ readOnly: true }}
                                                            className="w-100 mr-2 aiopsMetricsDatepicker"
                                                            //maxDate={new Date().getTime()}
                                                            isValidDate={this.validateDateFunction(item.validation)}
                                                        />
                                                                                                         
                                                        {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                            <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                        ) : null}
                                                        </React.Fragment>
                                                    : item.component === 'date_range' ?
                                                        <React.Fragment>
                                                            <div className='d-flex'>
                                                                <Datetime 
                                                                    value={this.state[item.name+'_start_date_'+index] && this.state[item.name+'_start_date_'+index] !== '' ? momentDateGivenFormat(this.state[item.name+'_start_date_'+index], 'DD MMM YYYY hh:mm A') : 'Pick Start Date'}
                                                                    onChange={(e) => this.handelDateRangeChange(e._d, item.name, index, 'start_date')}
                                                                    dateFormat={'DD MMM YYYY'}
                                                                    timeFormat={'hh:mm A'}
                                                                    inputProps={{ readOnly: true }}
                                                                    className="w-100 mr-2 aiopsMetricsDatepicker"
                                                                    //maxDate={new Date().getTime()}
                                                                    isValidDate={this.validateDateFunction(item.validation)}
                                                                />
                                                                <Datetime
                                                                    value={this.state[item.name+'_end_date_'+index] && this.state[item.name+'_end_date_'+index] !== '' ? momentDateGivenFormat(this.state[item.name+'_end_date_'+index], 'DD MMM YYYY hh:mm A') : 'Pick End Date'}
                                                                    onChange={(e) => this.handelDateRangeChange(e._d, item.name, index, 'end_date')}
                                                                    dateFormat={'DD MMM YYYY'}
                                                                    timeFormat={'hh:mm A'}
                                                                    inputProps={{ readOnly: true }}
                                                                    className="w-100 aiopsMetricsDatepicker"
                                                                    //minDate={new Date(this.state.sliderStartDate).getTime()}
                                                                    //maxDate={new Date()}
                                                                    isValidDate={this.validateDateFunction(item.validation)}
                                                                />
                                                            </div>
                                                            {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                            ) : null}
                                                        </React.Fragment>
                                                    : item.component === 'select' ?
                                                        <React.Fragment>
                                                            <Select
                                                                placeholder={'All'}
                                                                isSearchable={false}
                                                                className='f13 p-0 form-control reactSelectDrop'
                                                                value={({
                                                                    value: this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : 'Select',
                                                                    label: this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : 'Select',
                                                                })}
                                                                options={instanceType.map(item => ({
                                                                    value: item,
                                                                    label: item,	
                                                                }))}
                                                                onChange={event =>  
                                                                    this.setState({[item.name+'_'+index]: event.value})
                                                                }
                                                            />
                                                            {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                            ) : null}
                                                        </React.Fragment>
                                                    : item.component === "checkbox" ? 
                                                        <React.Fragment>
                                                            <div className="d-flex">
                                                                <div className="form-group form-check">
                                                                    <input type="checkbox" 
                                                                        onChange={() => this.setState({ [item.name+'_'+index]: !this.state[item.name+'_'+index]})} 
                                                                        className="form-check-input" 
                                                                    />
                                                                    
                                                                    <label className="form-check-label">{item.unit ? item.unit : ''}</label>
                                                                </div>
                                                            </div>
                                                            {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                            ) : null}
                                                        </React.Fragment>
                                                        
                                                    : item.component === 'multi_select' ?
                                                        <React.Fragment>
                                                            <ReactMultiSelectCheckboxes						
                                                                placeholderButtonLabel="Select"
                                                                className='f13 p-0 form-control'
                                                                getDropdownButtonLabel={() => this.getMultiSelectedCount('multi_select', this.state[item.name+'_'+index] ? this.state[item.name+'_'+index] : [])}
                                                                /* menuIsOpen ={this.state.isMultiSelec} */
                                                                value={this.state[item.name+'_'+index] && this.state[item.name+'_'+index].map(acc => ({
                                                                    value: acc.value,
                                                                    label: acc.label
                                                                }))}
                                                                //setState={this.state.selectedAccountArray}
                                                                onChange={arr => {
                                                                this.handleMultiSelectChange(item.name+'_'+index, arr ? arr : []) }}
                                                                options={instanceType.map(ins => ({
                                                                    value: ins,
                                                                    label: ins,
                                                                }))}
                                                            />
                                                            {this.state.validationError && this.state[item.name+'_'+index+'_validationErrorMessage'] !=='' ? (
                                                                <small className='text-danger'>{this.state[item.name+'_'+index+'_validationErrorMessage']}</small>
                                                            ) : null}
                                                        </React.Fragment>
                                                    : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <button className={`btn btn-info`} 
                                onClick={ () => this.startRemediationProcess()}>Apply</button>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
		)
	}
}
 
/**
 * Type of the props used in the component
 */
RemediationLeftSection.propTypes = {
    getRemediationDetails: PropTypes.func,
    getArtifactDetails: PropTypes.func,
    getRemediationStatus: PropTypes.func,
    listRemediation: PropTypes.func,
    startRemediation: PropTypes.func,   
    apiToDevCall: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
    caseDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('----------------------',state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
        alertsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        remediationStatus: state.aiops.remediationStatus,
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},        
        selectedRemediation: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
    }
}

export default connect(mapStateToProps, {
  getRemediationDetails,
  getArtifactDetails,
  getRemediationStatus,
  listRemediation,
  startRemediation,
  apiToDevCall,
  setAiopsPropsDetails,
  getCaseDetails
})(withRouter(RemediationLeftSection))