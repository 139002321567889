/*************************************************
 * Tvastar
 * @exports
 * @file ExpandedDarkThemeTable.js
 * @author Prakash //on 19/05/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTable, useRowSelect, useBlockLayout, useResizeColumns, useSortBy, usePagination, useExpanded } from 'react-table'
import { UncontrolledTooltip } from 'reactstrap'
import styled from "styled-components";
import { useSticky } from "react-table-sticky";
import { momentDateGivenFormat } from '../../../utils/utility';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


const toolTipModifiers = {
	flip: { behavior: ['top'] },
	preventOverflow: { boundariesElement: 'viewport' },
}

function ExpandedDarkThemeTable({
	data,
	selectedPeriod,
	dashboard,
	perPage = 10,
	sortByColumn,
	sortByType = true,
	tooltipFor = false,
	riskTooltip = false,
	onClickRow,
	selectedRecord,
	tableHead = false,
	tableBody = false,
	tableSize = false,
	columnFontSize = false,
	rounded = false,
	tableType = false,
	deSelectBackground = false,
	emptyMessage = '',
	defaultSelectedRecord = 0,
}) {
	let sortBy = []
	const defaultColumn = React.useMemo(
		() => ({
			// When using the useFlexLayout:
			minWidth: 30, // minWidth is only used as a limit for resizing
			width: 150, // width is used for both the flex-basis and flex-grow
			maxWidth: 600, // maxWidth is only used as a limit for resizing
		}),
		[]
	)

	const columns = React.useMemo(
		() => [
			{
				// Build our expander column
				id: "expander", // Make sure it has an ID
				Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
					// <span {...getToggleAllRowsExpandedProps()}>
					// 	{/* {isAllRowsExpanded ?
					// 		<i className='fas fa-caret-down align-self-center' />  
					// 	:
					// 		<i className='fas fa-caret-right align-self-center' />  
					// 	} */}
					// 	{isAllRowsExpanded ? "👇" : "👉"}
					// </span>
					<span></span>
				),
				width: 50,
				Cell: ({ row }) =>
					// Use the row.canExpand and row.getToggleRowExpandedProps prop getter
					// to build the toggle for expanding a row
					row.canExpand ? (
						<span
							{...row.getToggleRowExpandedProps({
								style: {
								// We can even use the row.depth property
								// and paddingLeft to indicate the depth
								// of the row
								paddingLeft: `${row.depth * 2}rem`
								}
							})}
						>
							{/* {row.isExpanded ? "👇" : "👉"} */}
							{row.isExpanded ?
								<i className='fas fa-caret-down align-self-center f20' />  
							:
								<i className='fas fa-caret-right align-self-center f20' />  
							}
						</span>
					) : null
			},
			{
				Header: ' ',
				// accessor: d => ,
				Cell: cellInfo => (
					<div className='py-2 justify-content-center d-flex align-self-center text-white'>
						{cellInfo.row.original.start_time && cellInfo.row.original.end_time ?
							<React.Fragment>
							<span>{momentDateGivenFormat(cellInfo.row.original.start_time, "MMM")}</span>
							<span>- {momentDateGivenFormat(cellInfo.row.original.end_time, "MMM")}</span>
							</React.Fragment> 
						:
							selectedPeriod === "monthly" ?
								momentDateGivenFormat(cellInfo.row.original.start_time, "MMM DD")
							:
								momentDateGivenFormat(cellInfo.row.original.start_time, "MMM")
						}
					</div>
				),
				width: 190
			},
			{
				Header: 'Budget',
				accessor: 'budget',
				width: 140
			},
			{
				Header: 'Spend',
				accessor: 'spend',
				width: 140
			},
			{
				Header: 'Forecast',
				accessor: 'forecast',
				width: 180
			},
			{
				Header: 'Variance',
				accessor: 'variance',				
				Cell: cellInfo => (
					<div className='py-2 justify-content-center d-flex align-self-center text-white'>
						{cellInfo.row.original.variance ?
							<span className={`${cellInfo.row.original.variance < 0 ? "text-success" : "text-danger"}`}>{cellInfo.row.original.variance}</span>
						: null}
					</div>
				),
				width: 120
			},
			{
				Header: '   ',
				accessor:  '',
				width: 1
			}
		],
		[]
	)

	if (sortByColumn && sortByColumn !== '') {
		if (!sortByType) {
			sortBy = [
				{
					id: sortByColumn,
					asc: true,
				},
			]
		} else {
			sortBy = [
				{
					id: sortByColumn,
					desc: true,
				},
			]
		}
	}

	const [selectedRowIndex, setSelectedRowIndex] = useState(defaultSelectedRecord)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		// rows,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// preGlobalFilteredRows,
		// setGlobalFilter,
		// state,
		// selectedFlatRows,
		// state: { pageIndex, pageSize, selectedRowIds },
		state: { pageIndex, pageSize, expanded },
	} = useTable(
		{
			columns: columns,
			defaultColumn,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: perPage,
				sortBy: sortBy,
			},
		},
		useBlockLayout,
		// useResizeColumns,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		useSticky,
	)

	return (
		<React.Fragment>
			<div className={`${rounded ? 'rounded' : ''} table-responsive`}>
				<table
					{...getTableProps()}
					className={`table ${tableSize ? tableSize : 'table-sm'} ${
						tableType ? tableType : 'table-dark-hover'
					} text-white mb-0`}
				>
					<thead className={`${tableHead ? tableHead : 'bg-muted'}`}>
						{headerGroups.map((headerGroup, i) => (
							<tr key={i} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th key={index} {...column.getHeaderProps()} onClick={() => setSelectedRowIndex(0)}>
										<span
											{...column.getSortByToggleProps({ title: 'Sort' })}
											style={{ marginRight: 10, cursor: 'pointer' }}
										>
											<span className="w-100">
												{column.isSorted ? (
													column.isSortedDesc ? (
														<i className='fas fa-sort-down align-self-center' />
													) : (
														<i className='fas fa-sort-up align-self-center' />
													))
												: null}
												<span className={`${column.isSorted ? "ml-1" : ""}`}>{column.render('Header')}</span> 
												{column.HeaderUnit ?
													<span className="text-primary-color f10 align-self-center ml-1">({column.HeaderUnit})</span>
												: null}
											</span>
										</span>
										{/* {headerGroup.headers.length - 1 === index ? null : (
											<div {...column.getResizerProps()} className={`resizer`} />
										)} */}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className={`${tableBody ? tableBody : 'bg-secondary'}`} {...getTableBodyProps()}>
						{page.map((row, i) => {
							if (selectedRecord && !i && !selectedRowIndex && selectedRecord !== row.original) {
								onClickRow(row.original, '')
							}
							prepareRow(row)
							return (
								<tr
									className={`${deSelectBackground ? '' : selectedRowIndex === i ? 'bg-black6' : ''} w-100`}
									key={i}
									{...row.getRowProps()}
									onClick={() => {
										onClickRow(row.original, 'click')
										setSelectedRowIndex(i)
									}}
								>
									{row.cells.map((cell, index) => {
										return (
											<React.Fragment key={index}>
												{tooltipFor && tooltipFor.includes(index) && (
													<UncontrolledTooltip
														key={index}
														placement='top'
														modifiers={toolTipModifiers}
														target={`targetIdNotify${i}${index}`}
													>
														{cell.render('Cell')}
													</UncontrolledTooltip>
												)}
												{riskTooltip &&
													riskTooltip.includes(index) &&
													(cell.row.values.severity ? (
														<span className='uncontrolledTooltip'>
															<UncontrolledTooltip
																/* isOpen={true} */
																key={index}
																placement='top'
																modifiers={toolTipModifiers}
																target={`targetIdNotify${i}${index}`}
																//trigger="click"
																//className="uncontrolledTooltip"
																/* style={{backgroundColor:'red'}} */
															>
																{cell.row.values.severity}
															</UncontrolledTooltip>
														</span>
													) : null)}
												<td
													key={index}
													className={`${columnFontSize}  ${
														!cell.column.className || cell.column.className === ''
															? 'text-truncate'
															: cell.column.className
													} border-0 align-self-center`}
													{...cell.getCellProps()}
													id={`targetIdNotify${i}${index}`}
												>
													{cell.render('Cell')}
												</td>
											</React.Fragment>
										)
									})}
								</tr>
							)
						})}

						{data.length ? null : (
							<tr className='bg-black6 text-center'>
								<td colSpan={columns.length} className='text-truncate border-0'>
									{emptyMessage !== '' ? (
										<span>{emptyMessage}</span>
									) : (
										<span>There are no data on this criteria. Please try adjusting your filter.</span>
									)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			{dashboard && data.length > perPage ? (
				<div className='pagination f12'>
					Rows per page:{' '}
					<select
						className='bg-primary text-primary-color'
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
						{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
							<option className='text-primary-color' key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className='mx-3 pt-1'>
						Page {pageIndex + 1} of {pageOptions.length}{' '}
					</span>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<i className='fal fa-arrow-to-left f12 text-gray4' />
					</button>{' '}
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						<i className='fal fa-angle-left f12 text-gray4' />
					</button>{' '}
					<button onClick={() => nextPage()} disabled={!canNextPage}>
						<i className='fal fa-angle-right f12 text-gray4' />
					</button>{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<i className='fal fa-arrow-to-right f12 text-gray4' />
					</button>
				</div>
			) : null}
		</React.Fragment>
	)
}

/**
 * Type of the props used in the component
 */
ExpandedDarkThemeTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	dashboard: PropTypes.bool,
	onClickRow: PropTypes.func,
	tooltipFor: PropTypes.array,
	riskTooltip: PropTypes.array,
}

export default ExpandedDarkThemeTable
