/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file EventsDashboard.js
 * @author Prakash // on 30/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getEventDetails } from '../../../../actions/aiops/EventAction'
import { listObservabilityEventGroups, listEventsObservabilityEventGroups } from '../../../../actions/aiops/ObservabilityAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'

import RightSection from '../RightSection'

class EventsDashboard extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            showLoading: true,
            jumpDate: '',
            details: {},

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,

            groupType: 'All',
            groupedBy: 'resource_type',
            selectedCategory: 'Important / All',
            pageType: "Events",            
            showRightSide: true
        }
    }

	componentDidMount = () => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            this.setState({ 
                start_time: this.props.aiopsCommonPageFilter.start_time,
                end_time: this.props.aiopsCommonPageFilter.end_time,
                brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                showRightSide: false,
                eventListLoader: true
            },
                () => {
                    this.setState({ showRightSide: true })
                    this.listObservabilityEventGroups()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            if(!_.isEqual(prevProps.aiopsCommonPageFilter, this.props.aiopsCommonPageFilter)) {
                this.setState({ 
                    start_time: this.props.aiopsCommonPageFilter.start_time,
                    end_time: this.props.aiopsCommonPageFilter.end_time,
                    brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                    brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                    showRightSide: false,
                    eventListLoader: true
                },
                    () => {
                        this.setState({ showRightSide: true })
                        this.listObservabilityEventGroups()
                    }
                )
            }
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listObservabilityEventGroups = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        // params.case_id = '2408-1028-7602'
        if(this.state.groupedBy !== 'All') {
            params.aggregate_by = this.state.groupedBy
        }
        params.important = this.state.groupType === 'All' ? false : true

        this.props.listEventsObservabilityEventGroups(params, (promise, response) => {
            if(promise) {
                let data = response.results ? response.results : []
                this.setState({ groupEventsResponse: data, showGroupLoading: false },
                    () => {
                        this.structureGroupEvents()
                        this.props.setAiopsPropsDetails('tabOptions', {})
                    }    
                )
            } else {
                this.setState({ groupEvents: [], showGroupLoading: false, eventListLoader: false })
            }
        })
    }

    structureGroupEvents = () => {
        let groupAllEvents = []

        let totalCount = 0
        
        let groupEventcategory = []
        if(this.state.groupEventsResponse.details && this.state.groupEventsResponse.details.length) {
            totalCount = this.state.groupEventsResponse.details.reduce( function(a, b){ return a + (b.count ? b.count : (b.events ? b.events.length : 0))}, 0);
            this.state.groupEventsResponse.details.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.events ? item.events.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                groupEventcategory.push(dataRow)
                item.events.forEach(alt => {
                    if(groupAllEvents.length) {
                        let filter = groupAllEvents.filter(arr => arr.event_id === alt.event_id)
                        if(!filter.length) {
                            groupAllEvents.push(alt)
                        }
                    } else {
                        groupAllEvents.push(alt)
                    }
                })
            })
        }
        
        let groupEventTotalCount = this.state.groupEventsResponse.total_count ? this.state.groupEventsResponse.total_count : 0
        let groupEventfilteredCount = this.state.groupEventsResponse.filtered_count ? this.state.groupEventsResponse.filtered_count : 0
        
        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupEventcategory.push(dataRow)

        let orderedArray = _.orderBy(groupEventcategory, ['orderBy'], ['asc'])

        this.setState({ groupAllEvents, groupEvents: groupAllEvents, groupEventcategory: orderedArray, groupEventTotalCount, groupEventfilteredCount },
            () => this.filterData()    
        )
    }

    getCategoryEvents = () => {
        let filterdCategoryEvents = this.state.groupAllEvents
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryEvents = this.state.groupEventsResponse.details.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryEvents && filterdCategoryEvents.length) {
                filterdCategoryEvents = filterdCategoryEvents[0].events ? filterdCategoryEvents[0].events : []
            }
        }

        this.setState({ groupEvents: filterdCategoryEvents, selectedEventId: '', startRecord: 0, currentPage: 1 },
            () => {
                this.filterData()
                // this.redirectToAnalysis()
                let scroll = document.getElementById('scrollTop');
                scroll.scrollIntoView();
            }
        )
    }

    filterData = () => {
        let data = this.state.groupEvents ? this.state.groupEvents : []
        data = _.orderBy(data, ['event_time'], ['desc'])

        let totalPages = 1
		if(data.length > this.state.perPage) {
			totalPages = Math.ceil(data.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: data, filteredArray: data, totalPages, eventListLoader: false },
            () => {
                this.formHeaders()
                if(this.state.filteredArray.length) {
                    this.getEventDetails(this.state.filteredArray[0])
                }
            }    
        )
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
                console.log(key, typeof value)
				if(typeof value === "string" && key !== "event_data") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    getEventDetails = (selectedEvent) => {
        let detailParams = {}
        detailParams.event_id = selectedEvent.event_id
        detailParams.event_source = selectedEvent.event_source

        let assetName = ''
        let assetID = ''
        let serviceName = ''
        let resourceType = ''
        let merticsAssetIdInvolved = []
        let merticsAssetNameInvolved = []
        selectedEvent.assets.forEach(item => {
            assetName = item.asset_name
            assetID = item.asset_id
            serviceName = item.service_name
            resourceType = item.resource_type
            if(item.asset_id) {
                merticsAssetIdInvolved.push(item.asset_id)
            }
            if(item.asset_name) {
                merticsAssetNameInvolved.push(item.asset_name)
            }
        })
        selectedEvent.assetName = assetName
        selectedEvent.assetID = assetID
        selectedEvent.serviceName = serviceName
        selectedEvent.resourceType = resourceType


        this.setState({ selectedEventId: selectedEvent.event_id })
        this.props.getEventDetails(detailParams, (promise, result) => {})

        let obj = {}
        obj.selectedTab = 'Details'
        obj.details = selectedEvent
        // obj.merticsAssetIdInvolved = merticsAssetIdInvolved
        // obj.merticsAssetNameInvolved = merticsAssetNameInvolved
        obj.merticsAssetNameInvolved = selectedEvent.assetID ? [selectedEvent.assetID] : []
        obj.brushStartTime = this.state.brushStartTime
        obj.brushEndTime = this.state.brushEndTime
        obj.pageType = this.state.pageType
        this.props.setAiopsPropsDetails('tabOptions', obj)
        this.props.setAiopsPropsDetails('alertsPageMonitoringList', {})
        this.props.setAiopsPropsDetails('errorEventPageMonitoringList', {})
    }
    
    handleChange = (event) => {
        this.setState({ groupType: event.target.value, showGroupLoading: true, selectedEventId: '', eventListLoader: true },
            () => {
                this.listObservabilityEventGroups()
                // this.redirectToAnalysis()
            }    
        )
    }    

    // redirectToAnalysis = () => {
    //     let obj = {}
    //     obj.selectedTab = 'Analysis'
    //     obj.details = {}
    //     obj.merticsAssetNameInvolved = []
    //     obj.brushStartTime = this.state.brushStartTime
    //     obj.brushEndTime = this.state.brushEndTime
    //     obj.pageType = this.state.selectedPageType
    //     this.props.setAiopsPropsDetails('tabOptions', obj)
    // }

    handleClickOutside(event) {
        if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
            this.setState({ showPageTypeOptions: false })
        } else {
            this.setState({ showPageTypeOptions: true })
        }
    }
    
	render() {
		return (
            <div className="" id="scrollTop" onClick={(event) => this.handleClickOutside(event)}>
                <div className="w-100">
                    <div className={`d-flex mt-2 ml-1 ${this.state.eventListLoader ? 'disabled' : ''}`}>
                        <div className="select-sm-bg curvedDropdown minWidth120 mr-2" ref={this.toggleRef}>
                            <div className="dropdown">
                                <span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
                                    {this.state.pageType ? this.state.pageType : <span className="placeholder">Select</span>}
                                </span>
                                <ul className={`dropdown-menu p-0 ${this.state.showPageTypeOptions ? "show" : ''}`}>
                                    {this.props.pageTypes.map(item => {
                                        return (
                                            <li 
                                                onClick={() => {
                                                    this.props.setAiopsPropsDetails('tabOptions', {})
                                                    this.setState({ 
                                                        pageType: item, 
                                                        showPageTypeOptions: false
                                                    }, 
                                                        () => {
                                                            this.props.onChangePageType(item)
                                                        }
                                                    )
                                                }}
                                                className={`${this.state.pageType === item ? "selected" :  ""}`}
                                            >
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <p className="m-0 align-self-end small mr-2">Show the </p>
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                            onChange={this.handleChange}
                            value={this.state.groupType}
                            >
                                <option key="All" value="All">ALL</option>
                                <option key="important" value="important">Important</option>
                            </select>
                        </div> 
                        <p className="m-0 align-self-end small mx-2">events groupd by</p>
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                                onChange={(event) => {
                                    this.setState({ groupedBy: event.target.value, showGroupLoading: true, selectedCategory: "Important / All", selectedEventId: '', eventListLoader: true },
                                        () => {
                                            // this.redirectToAnalysis()
                                            this.listObservabilityEventGroups()
                                        }
                                    )
                                }}
                                value={this.state.groupedBy}                            
                                >
                                <option key="resource_type" value="resource_type">Resource</option>
                                <option key="impact_type" value="impact_type">Impact</option>
                                <option key="asset_name" value="asset_name">Asset</option>
                                <option key="lifecycle" value="lifecycle">LifeCycle</option>
                                <option key="asset_category" value="asset_category">Product</option>
                                <option key="event_source" value="event_source">Source</option>
                                {/* {this.state.aggregateByArray && this.state.aggregateByArray.map(item => {
                                    return(
                                        <option value={item}>{capitalizeFirstLetter(item)}</option>
                                    )
                                })} */}
                            </select>
                        </div>
                    </div>
                    
                    <div className="d-flex mt-2">
                        <div className={`${this.state.expandRIghtSection ? 'w-60' : 'w-60'} mr-1`}>
                            <div className="rounded bg-dark p-3">
                                <div className="d-flex mb-2 justify-content-between">
                                    <div className="d-flex mr-2 w-40">
                                        <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length}{this.state.totalFilteredArray && this.state.totalFilteredArray.length > 1 ? ' events' : ' events'}</p>
                                        {/* {this.state.totalErrorList && this.state.totalErrorList.length}{this.state.totalErrorList && this.state.totalErrorList.length > 1 ? ' errors' : ' error'} from </p> */}
                                        <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}</p>
                                        {/*  */}
                                    </div>
                                    <div className="d-flex justify-content-end w-60 mb-n2">
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <CSVLink 
                                                data={this.state.filteredArray} 
                                                headers={this.state.headers ? this.state.headers : []}
                                                filename={"observability-alerts.csv"}
                                                className={"align-self-center"}
                                                target="_blank"
                                            >
                                                <i className="fas fa-download text-primary-color"></i>
                                                {/* <span className="text-primary-color mx-2">csv</span> */}
                                            </CSVLink>
                                        : null}
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <Search
                                                data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-black5 w-50 mr-1 border-gray5 rounded-5 f12 align-self-center'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-gray5 f12'}
                                                searchPlaceHolder={'Search events....'}
                                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                filteredData={(filteredArray) => {
                                                    this.setState({ filteredArray, errorDetails: {}, merticsAssetNameInvolved: [] },
                                                        () => {
                                                            if(filteredArray.length) {
                                                                this.getEventDetails(this.state.filteredArray[0])
                                                            }
                                                        }    
                                                    )
                                                }}
                                            />
                                        : null}
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span>
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                
                                <div className="d-flex errorSectionScroll">
                                    {this.state.groupEventcategory ? 
                                        <div className="w-20 pr-3 categoryBoxSection">
                                            <div class="bg-muted px-2 pt-2 select-bgg">
                                                {this.state.groupedBy === "severity" ?
                                                    this.state.groupEventcategory.map(item => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <div class="d-flex">
                                                                        {item.totalBars.map(bar => {
                                                                            return(
                                                                            <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                            )
                                                                        })}
                                                                        <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                            <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    })                                            
                                                : 
                                                    this.state.groupEventcategory.map((item, index) => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                    <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                        <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                        <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                        <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                                    </div>
                                                                </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    : null}
                                    <div className={`${this.state.groupEventcategory ? 'w-80' : 'w-100'}`}>
                                        <div className={`bg-dark3 py-3 pl-2`} style={{minHeight: "545px"}}>
                                            {this.state.eventListLoader ? 
                                                <div className='d-flex justify-content-center m-4'>
                                                    <Spinner className='text-center' color='white' size='lg' />
                                                </div>
                                            : this.state.filteredArray && this.state.filteredArray.length ?
                                                this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                                    return(
                                                        <div className={`d-flex mr-2 px-2 mb-2 pb-1 ${this.state.startRecord + this.state.perPage !== this.state.startRecord + i ? 'border-bottom-black5' : ''} ${this.state.selectedEventId !== item.event_id ? 'cursorPointer' : 'bg-muted text-white pt-1'}`} onClick={() => {
                                                            this.props.setAiopsPropsDetails('tabOptions', {})
                                                            this.getEventDetails(item)
                                                        }
                                                        }>
                                                            <p className="mb-0">
                                                                <span className="mb-0 mr-2 f12 text-primary-color font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Events</span>
                                                                {/* <span className={`mb-0 mr-2 f12 ${item.event_source === 'checks' ? 'text-purple' : item.event_source === 'config' ? 'text-secondary' : (item.event_source === 'cloudtrail' || item.event_source === 'cloudwatch_alarm') ? 'text-info' : 'text-gray3'} font-weight-bold`}>{capitalizeFirstLetter(item.event_source) + (item.event_name ? ' : ' + item.event_name : '') + (item.event_type ? ' : '+item.event_type : '')}</span> */}
                                                                <span className={`mb-0 mr-2 f12 text-purple-2`}>{(item.event_source ? capitalizeFirstLetter(item.event_source) : "") + (item.event_name ? ' : ' + item.event_name : '') + (item.event_type ? ' : '+item.event_type : '')}</span>
                                                                {item.assets && item.assets ? 
                                                                    <span className="mb-0 mr-2 f12 text-white font-weight-bold"> 
                                                                        {item.assets.map((ast, astIndex) => (item.assets.length - 1 === astIndex ? (ast.asset_name) : ast.asset_name + ', '))}
                                                                    </span>
                                                                : null}
                                                                <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                {item.account_id ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                                : null}
                                                                {item.region ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                                : null}
                                                                {item.description ?
                                                                    <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            :
                                                <div className='d-flex justify-content-center m-4'>
                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {!this.state.eventListLoader ?  */}
                            <div className={`${this.state.expandRIghtSection ? 'w-40' : 'w-40'}`}>
                                <RightSection
                                    updateDetail = {() => this.setState({ selectedEventId: '' }, () => this.props.setAiopsPropsDetails('tabOptions', {}))}
                                    pageType={this.state.pageType}
                                />
                            </div>
                        {/* : null} */}
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
EventsDashboard.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},
        jumpTo: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.jumpTo ? state.aiops.aiopsPropsDetails.jumpTo : '',
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    listObservabilityEventGroups,
    listEventsObservabilityEventGroups,
    getEventDetails
})(withRouter(EventsDashboard))