/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Diagnostics.js
 * @author Prakash // on 03/07/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { momentConvertionUtcToLocalTime, getRegionName, getAccountNameFromId } from '../../../utils/utility'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import { 
	listAllAccounts, 
	listAllRegions
} from '../../../actions/commonAction'

import SreMetricDetails from './SreMetricDetails'
import MetricsSection from './MetricsSection'

// import DbSlowQueryAnalysis from './DbSlowQueryAnalysis'
import DbSlowQueryMySQL from './DbSlowQueryMySQL'
import DbSlowQueryPostgreSQL from './DbSlowQueryPostgreSQL'

import Reachability from './Reachability'

class DiagnosticsRightSection extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.scrolltoTop = React.createRef()
        this.state = {
            caseId: this.props.caseId,
            accounts: [],
            regions: [],
        }
    }

    componentDidMount = () => {} 

    componentDidUpdate = (prevProps) => {        
        if(prevProps.selectedDiagnosticEvent !== this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent && Object.entries(this.props.selectedDiagnosticEvent).length) {
            let mertcisAnnotationTypes = [
                // {prefix: 'CS', label: ' - Case Start Time', value: 'CS'}, 
                // {prefix: 'CE', label: ' - Case End Time', value: 'CE'}
            ]
            if(this.props.selectedDiagnosticEvent.label === 'Application Changes' || this.props.selectedDiagnosticEvent.label === 'DB Audit MySQL' || this.props.selectedDiagnosticEvent.label === 'DB Audit PostgreSQL' || this.props.selectedDiagnosticEvent.label === 'Capacity Changes' || this.props.selectedDiagnosticEvent.label === 'Infra Changes' || this.props.selectedDiagnosticEvent.label === 'SRE Observations') {
                mertcisAnnotationTypes.push({prefix: 'C', label: ' - Change', value: 'C'})
            } else if(this.props.selectedDiagnosticEvent.label === 'Health Events' || this.props.selectedDiagnosticEvent.label === 'Maintenance and Scheduled Events' || this.props.selectedDiagnosticEvent.label === 'DB SlowQuery Events' || this.props.selectedDiagnosticEvent.label === 'Connectivity Checks') {
                mertcisAnnotationTypes.push({prefix: 'E', label: ' - Event', value: 'E'})
            }

            this.setState({ 
                selectedDiagnosticEventLabel: this.props.selectedDiagnosticEvent.label,
                selectedDiagnosticEvent: this.props.selectedDiagnosticEvent.data, 
                assetDetails: this.props.selectedDiagnosticEvent.asset_details,
                mertcisAnnotationTypes,
                showMeticsChart: false
            },
                () => {
                    this.scrolltoTop.current.scrollIntoView()
                    // this.getRegionAccountDetails()
                    if(this.state.selectedDiagnosticEventLabel !== 'DB SlowQuery MySQL' && this.state.selectedDiagnosticEventLabel !== 'DB SlowQuery PostgreSQL' && this.state.selectedDiagnosticEventLabel !== 'Reachability Analysis') {
                        this.setState({ showMeticsChart: true })
                    }
                }
            )
        } else if(prevProps.selectedDiagnosticEvent !== this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent && !Object.entries(this.props.selectedDiagnosticEvent).length) {
            this.setState({ 
                selectedDiagnosticEventLabel: '', 
                selectedDiagnosticEvent: {}, 
                assetDetails: {}
            })
        }
    }

	getRegionAccountDetails = () => {
        if(!this.state.accounts || !this.state.accounts.length || !this.state.regions || !this.state.regions.length) {
            let params = {}
            if (this.state.assetDetails.provider) {
                params.provider = this.state.assetDetails.provider.toLowerCase()
            }
            if(this.props.accounts && this.props.accounts.length) {
                let filterdAccounts = this.props.accounts
                if(this.state.assetDetails.provider) {
                    filterdAccounts = this.props.accounts.filter(account => account.provider === this.state.assetDetails.provider.toLowerCase())
                }
                this.setState({ accounts: filterdAccounts })
            } else {
                this.props.listAllAccounts(params, (promise, allAccounts) => {
                    if (promise) {						
                        let filterdAccounts = allAccounts
                        if(this.state.assetDetails.provider) {
                            filterdAccounts = allAccounts.filter(account => account.provider === this.state.assetDetails.provider.toLowerCase())
                        }
                        this.setState({ accounts: filterdAccounts })
                    }
                })
            }

            if(this.props.regions && this.props.regions.length) {
                let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
                this.setState({ regions: providerRegions })
            } else {
                this.props.listAllRegions(params, (promise, regions) => {
                    if (promise) {
                        let providerRegions = regions && regions[0] && regions[0].provider_regions
                        this.setState({ regions: providerRegions })
                    } else {
                        this.setState({ regions: [] })
                    }
                })
            }
        }
	}
    redirect = (url) => {
        window.open(url, '_blank');
    }

	render() {
		return (
            <div className='' ref={this.scrolltoTop}>
                <div className={`m-0 h-100`}>
                    {this.state.selectedDiagnosticEventLabel === 'Health Events' ?
                        <div className="mt-3">
                            <div className="rounded bg-dark p-3">
                                {/* <p className="mb-1"><b>Event Details</b></p> */}
                                <div className="mb-1">
                                    <p className="mb-0 f16 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_name ? this.state.selectedDiagnosticEvent.health_event_name : <span>&nbsp;</span>}</p>
                                    <p className="f12">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_description ? this.state.selectedDiagnosticEvent.health_event_description : <span>&nbsp;</span>}</p>
                                </div>
                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Event ID:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_id ? this.state.selectedDiagnosticEvent.health_event_id : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Event Time:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.health_event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>

                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Event Type:</p>
                                        <p className="mb-0 text-info" onClick={() => this.redirect('https://status.aws.amazon.com/')}>{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_type ? this.state.selectedDiagnosticEvent.health_event_type : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Arn:</p>
                                        <p className="mb-0 text-white text-break">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_arn ? this.state.selectedDiagnosticEvent.health_event_arn : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>
                        
                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Region:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_region ? getRegionName(this.state.selectedDiagnosticEvent.health_event_region, this.state.regions) : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Service:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_service ? this.state.selectedDiagnosticEvent.health_event_service : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>

                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Start Time:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_start_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.health_start_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">End Time:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_end_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.health_end_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>

                                <div className="d-flex mb-1">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Category:</p>
                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.health_event_category ? 
                                            <small className={`badge badge-secondary`}>{this.state.selectedDiagnosticEvent.health_event_category}</small> 
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    : this.state.selectedDiagnosticEventLabel === 'Maintenance and Scheduled Events'  ?
                        <div className="mt-3">
                            <div className="rounded bg-dark p-3">
                                <div className="d-flex justify-content-between mb-1">
                                    <p className="mb-0 f16 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_name ? this.state.selectedDiagnosticEvent.maintsch_event_name : <span>&nbsp;</span>}</p>
                                    <p className="f12">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_description ? this.state.selectedDiagnosticEvent.maintsch_event_description : <span>&nbsp;</span>}</p>
                                </div>
                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Event ID:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_id ? this.state.selectedDiagnosticEvent.maintsch_event_id : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Event Time:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.maintsch_event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>

                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Event Type:</p>
                                        <p className="mb-0 text-info" onClick={() => this.redirect('https://status.aws.amazon.com/')}>{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_type ? this.state.selectedDiagnosticEvent.maintsch_event_type : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Arn:</p>
                                        <p className="mb-0 text-white text-break">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_arn ? this.state.selectedDiagnosticEvent.maintsch_event_arn : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>
                        
                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Region:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_region ? getRegionName(this.state.selectedDiagnosticEvent.maintsch_event_region, this.state.regions) : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Service:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_service ? this.state.selectedDiagnosticEvent.maintsch_event_service : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>

                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Start Time:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_start_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.maintsch_start_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">End Time:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_end_time ? momentConvertionUtcToLocalTime(this.state.selectedDiagnosticEvent.maintsch_end_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>

                                <div className="d-flex mb-1">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Category:</p>
                                        {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.maintsch_event_category ? 
                                            <small className={`badge badge-secondary`}>{this.state.selectedDiagnosticEvent.maintsch_event_category}</small> 
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    : this.state.selectedDiagnosticEventLabel === 'SRE Observations' ?
                        <div className="mt-3">
                            <div className="rounded bg-dark p-3">
                                <p className="mb-1 f16 text-white">Asset Details</p>
                                {/* <div className="d-flex justify-content-between mb-1 border-bottom">
                                    <div>
                                        <p className="mb-0 font-weight-bold">LOREM IPSUM</p>
                                        <small className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit</small>
                                    </div>
                                </div> */}
                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Provider / Account / Region</p>
                                        <p className="mb-0 text-white">
                                            {this.state.assetDetails && this.state.assetDetails.provider ? this.state.assetDetails.provider.toUpperCase() : <span>&nbsp;</span>}
                                            {this.state.assetDetails && this.state.assetDetails.account_id ? (' : ')+getAccountNameFromId(this.state.assetDetails.account_id, this.state.accounts) : <span>&nbsp;</span>}
                                            {this.state.assetDetails && this.state.assetDetails.region ?  (' : ')+getRegionName(this.state.assetDetails.region, this.state.regions) : <span>&nbsp;</span>}
                                        </p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Service:</p>
                                        <p className="mb-0 text-white">
                                            {this.state.assetDetails && this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : <span>&nbsp;</span>}
                                            {this.state.assetDetails && this.state.assetDetails.service_name ?  (' : ')+this.state.assetDetails.service_name : <span>&nbsp;</span>}
                                        </p>
                                    </div>
                                </div>
                                
                                <div className="d-flex">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Asset Name:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.sre_asset_name ? this.state.selectedDiagnosticEvent.sre_asset_name : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Asset Id:</p>
                                        <p className="mb-0 text-white">{this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.sre_asset_id ? this.state.selectedDiagnosticEvent.sre_asset_id : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>
                        
                                {/* <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Status:</p>
                                        <small className={`badge badge-outline-success`}>Lorem ipsum</small>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Risk:</p>
                                        <small className={`badge badge-outline-danger`}>Lorem ipsum</small>
                                    </div>
                                </div> */}
                                
                                {/* <div className="d-flex mb-1">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Service Group:</p>
                                        <small className={`badge badge-secondary`}>Lorem ipsum</small>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Category:</p>
                                        <small className={`badge badge-secondary`}>Lorem ipsum</small>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    : (this.state.selectedDiagnosticEventLabel !== 'DB SlowQuery MySQL' && this.state.selectedDiagnosticEventLabel !== 'DB SlowQuery PostgreSQL' && this.state.selectedDiagnosticEventLabel === 'Reachability Analysis') ?
                        <div className="mt-3">
                            <div className="rounded bg-dark p-3">
                                <p className="mb-1 f16 text-white">Asset Details</p>

                                <div className="d-flex mb-1 border-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Provider / Account / Region</p>
                                        <p className="mb-0 text-white">
                                            {this.state.assetDetails && this.state.assetDetails.provider ? this.state.assetDetails.provider.toUpperCase() : <span>&nbsp;</span>}
                                            {this.state.assetDetails && this.state.assetDetails.account_id ? (' : ')+getAccountNameFromId(this.state.assetDetails.account_id, this.props.accounts) : <span>&nbsp;</span>}
                                            {this.state.assetDetails && this.state.assetDetails.region ?  (' : ')+getRegionName(this.state.assetDetails.region, this.state.regions) : <span>&nbsp;</span>}
                                        </p>
                                    </div>                                    
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Service:</p>
                                        <p className="mb-0 text-white">
                                            {this.state.assetDetails && this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : <span>&nbsp;</span>}
                                            {this.state.assetDetails && this.state.assetDetails.service_name ?  (' : ')+this.state.assetDetails.service_name : <span>&nbsp;</span>}
                                        </p>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Asset:</p>
                                        <p className="mb-0 text-white">{this.state.assetDetails && this.state.assetDetails.asset_name ? this.state.assetDetails.asset_name : <span>&nbsp;</span>}</p>
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Asset Time:</p>
                                        <p className="mb-0 text-white">{this.state.assetDetails && this.state.assetDetails.event_time ? momentConvertionUtcToLocalTime(this.state.assetDetails.event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : null}

                    {this.state.selectedDiagnosticEvent && Object.entries(this.state.selectedDiagnosticEvent).length ? 
                        this.state.selectedDiagnosticEventLabel === 'Reachability Analysis' ?
                            <Reachability
                                assetDetails= {this.state.assetDetails}
                                diagnostics = {this.props.aiopsDiagnosticsDetails}
                                selectedDiagnosticEvent = {this.state.selectedDiagnosticEvent}
                            />
                        : this.state.selectedDiagnosticEventLabel === 'DB SlowQuery MySQL' ?
                            <DbSlowQueryMySQL
                                assetDetails= {this.state.assetDetails}
                                selectedDiagnosticEvent={this.state.selectedDiagnosticEvent}
                                diagnostics = {this.props.aiopsDiagnosticsDetails}
                             />
                        : this.state.selectedDiagnosticEventLabel === 'DB SlowQuery PostgreSQL' ?
                            <DbSlowQueryPostgreSQL
                                assetDetails= {this.state.assetDetails}
                                selectedDiagnosticEvent={this.state.selectedDiagnosticEvent}
                                diagnostics = {this.props.aiopsDiagnosticsDetails}
                            />
                        : this.state.selectedDiagnosticEventLabel === 'Application Changes' ?
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    {this.state.selectedDiagnosticEvent.deployment_events ?
                                        <div className="mb-3">
                                            <p className="f16 text-white mb-1">Deployment Events</p>
                                            <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.deployment_events && this.state.selectedDiagnosticEvent.deployment_events.length} deployment events in application</p>
                                            <ResizeableDarkThemeTable
                                                columns={[
                                                    {
                                                        Header: 'Tool',
                                                        // accessor: d => d.additional_details.tool,
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Deployment ID',
                                                        // accessor: d => d.additional_details.deployment_id,
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.deployment_id ? cellInfo.row.original.additional_details.deployment_id : ''}
                                                            </span>
                                                        ),
                                                    },                                                        
                                                    {
                                                        Header: 'Date / User',
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Description',
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.artifacts && cellInfo.row.original.additional_details.artifacts.location ? cellInfo.row.original.additional_details.artifacts.location : ''}
                                                            </span>
                                                        ),
                                                        width: 200
                                                    },
                                                    {
                                                        Header: ' ',
                                                        Cell: cellInfo => (
                                                            <span className=''>&nbsp;</span>
                                                        ),
                                                        width: 1
                                                    }
                                                ]}
                                                data={this.state.selectedDiagnosticEvent.deployment_events}
                                                perPage={2}
                                                dashboard={(this.state.selectedDiagnosticEvent.deployment_events && this.state.selectedDiagnosticEvent.deployment_events.length) ? true : false}
                                                sortByColumn={''}
                                                riskTooltip={[]}
                                                onClickRow={tableRow => {}}
                                                tableSize={'table-xs'}
                                            />
                                        </div>
                                    :null}
                                    {this.state.selectedDiagnosticEvent.build_events ?
                                        <div className="mb-3">
                                            <p className="f16 text-white mb-1">Build Events</p>
                                            <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.build_events && this.state.selectedDiagnosticEvent.build_events.length} build events in application</p>
                                            <ResizeableDarkThemeTable
                                                columns={[
                                                    {
                                                        Header: 'Tool',
                                                        // accessor: d => d.additional_details.tool,
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Reference',
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.build_job_name +' : '+ cellInfo.row.original.additional_details.build_number : ''}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Date / User',
                                                        // accessor: 'event_time'
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Artifacts',
                                                        // accessor: 'event_time'
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {Object.entries(cellInfo.row.original.additional_details.artifacts).length+' Artifacts'}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Description',
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.artifacts && cellInfo.row.original.additional_details.artifacts.location ? cellInfo.row.original.additional_details.artifacts.location : ''}
                                                            </span>
                                                        ),
                                                        width: 200
                                                    },
                                                    {
                                                        Header: ' ',
                                                        Cell: cellInfo => (
                                                            <span className=''>&nbsp;</span>
                                                        ),
                                                        width: 1
                                                    }
                                                ]}
                                                data={this.state.selectedDiagnosticEvent.build_events}
                                                perPage={2}
                                                dashboard={(this.state.selectedDiagnosticEvent.build_events && this.state.selectedDiagnosticEvent.build_events.length) ? true : false}
                                                sortByColumn={''}
                                                riskTooltip={[]}
                                                onClickRow={tableRow => {}}
                                                tableSize={'table-xs'}
                                            />
                                        </div>
                                    :null}
                                    {this.state.selectedDiagnosticEvent.commit_events ?
                                        <div className="mb-3">
                                            <p className="f16 text-white mb-1">Commit Events</p>
                                            <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.commit_events && this.state.selectedDiagnosticEvent.commit_events.length} commit events in application</p>
                                            <ResizeableDarkThemeTable
                                                columns={[
                                                    {
                                                        Header: 'Tool',
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.tool ? cellInfo.row.original.additional_details.tool : ''}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Repo / URL',
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.repository_name +' / '+ cellInfo.row.original.additional_details.branch_name : ''}
                                                            </span>
                                                        ),
                                                    },                                                    
                                                    {
                                                        Header: 'Date / User',
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm') +' / '+ (cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Commit ID',
                                                        Cell: cellInfo => (
                                                            <span className=''>
                                                                {cellInfo.row.original.additional_details ? cellInfo.row.original.additional_details.commit_id : ''}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'Files',
                                                        Cell: cellInfo => (
                                                            <span className=''>&nbsp;</span>
                                                        ),
                                                    }
                                                ]}
                                                data={this.state.selectedDiagnosticEvent.commit_events}
                                                perPage={2}
                                                dashboard={(this.state.selectedDiagnosticEvent.commit_events && this.state.selectedDiagnosticEvent.commit_events.length) ? true : false}
                                                sortByColumn={''}
                                                riskTooltip={[]}
                                                onClickRow={tableRow => {}}
                                                tableSize={'table-xs'}
                                            />
                                        </div>
                                    :null}
                                </div>
                            </div>
                        : (this.state.selectedDiagnosticEventLabel === 'DB Audit MySQL' || this.state.selectedDiagnosticEventLabel === 'DB Audit PostgreSQL') ?
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    <p className="f16 text-white mb-1">{this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent.tableHeading}</p>
                                    <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details.length} changes in {this.props.selectedDiagnosticEvent && this.props.selectedDiagnosticEvent.tableHeading ? this.props.selectedDiagnosticEvent.tableHeading.toLowerCase() : ''}</p>
                                    <ResizeableDarkThemeTable
                                        columns={[
                                            {
                                                Header: 'Date',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm')}
                                                    </span>
                                                ),
                                            },
                                            {
                                                Header: 'Type',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {(cellInfo.row.original.additional_details.db_object ? cellInfo.row.original.additional_details.db_object : '')}
                                                    </span>
                                                ),
                                                width: 75
                                            },
                                            {
                                                Header: 'DB Name',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.database ? cellInfo.row.original.additional_details.database : ''}
                                                    </span>
                                                ),
                                            },
                                            {
                                                Header: 'DB Object',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.db_object_name ? cellInfo.row.original.additional_details.db_object_name : ''}
                                                    </span>
                                                ),
                                            },
                                            {
                                                Header: 'Operation',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {(cellInfo.row.original.additional_details.action ? cellInfo.row.original.additional_details.action : '')}
                                                    </span>
                                                ),
                                            },
                                            {
                                                Header: 'User',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {(cellInfo.row.original.additional_details.user ? cellInfo.row.original.additional_details.user.name : '')}
                                                    </span>
                                                ),
                                            },
                                            {
                                                Header: ' ',
                                                Cell: cellInfo => (
                                                    <span className=''>&nbsp;</span>
                                                ),
                                                width: 1
                                            }
                                        ]}
                                        
                                        data={this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details.length ? this.state.selectedDiagnosticEvent.details : []}
                                        perPage={5}
                                        dashboard={(this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details.length) ? true : false}
                                        sortByColumn={''}
                                        riskTooltip={[]}
                                        onClickRow={tableRow => {}}
                                        tableSize={'table-xs'}
                                    />
                                </div>
                            </div>
                        : (this.state.selectedDiagnosticEventLabel === 'Health Events' || this.state.selectedDiagnosticEventLabel === 'Maintenance and Scheduled Events') && this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details !== 'None' && this.state.selectedDiagnosticEvent.details.length ?
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    <p className="f16 text-white mb-1">Assets</p>
                                    <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.details.length} {this.state.selectedDiagnosticEvent.details.length > 1 ? ' assets' : 'asset'} involved in { this.state.selectedDiagnosticEventLabel === 'Health Events' ? 'Health events' : 'Maintenance and Scheduled events'}</p>
                                    <ResizeableDarkThemeTable
                                        columns={[
                                            {
                                                Header: 'Asset',
                                                accessor: 'asset_name'
                                            },
                                            {
                                                Header: 'Arn',
                                                accessor: 'asset_arn',
                                                width: 300
                                            },
                                            {
                                                Header: 'Region',
                                                accessor: 'region',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {(cellInfo.row.original.region ? getRegionName(cellInfo.row.original.region, this.state.regions): '')}
                                                    </span>
                                                )
                                            },
                                            {
                                                Header: 'Resource',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {((cellInfo.row.original.resource_type ? cellInfo.row.original.resource_type : '') + ' : '+(cellInfo.row.original.service_name ? cellInfo.row.original.service_name : ''))}
                                                    </span>
                                                ),
                                            },
                                            {
                                                Header: ' ',
                                                Cell: cellInfo => (
                                                    <span className=''>&nbsp;</span>
                                                ),
                                                width: 1
                                            }
                                        ]}
                                        
                                        data={this.state.selectedDiagnosticEvent.details}
                                        perPage={5}
                                        dashboard={(this.state.selectedDiagnosticEvent.details && this.state.selectedDiagnosticEvent.details !== 'None' && this.state.selectedDiagnosticEvent.details.length) ? true : false}
                                        sortByColumn={''}
                                        riskTooltip={[]}
                                        onClickRow={tableRow => {}}
                                        tableSize={'table-xs'}
                                    />
                                </div>
                            </div>
                        : this.state.selectedDiagnosticEventLabel === 'Capacity Changes' ?
                            <React.Fragment>
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                        this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`mb-1 ${!index}`}>
                                                    <p className="mb-0 f16 text-white">Before Changes</p>
                                                    <p className="f12 mb-0">{(cng.field ? cng.field : '')+''+(cng.previous_value ? +' : '+cng.previous_value : '')}</p>
                                                </div>
                                                {cng.previous_features ? 
                                                    Object.entries(cng.previous_features).map(([key, value]) => {
                                                        return(
                                                        <div className="py-1">
                                                            <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                            <div className="d-flex mb-1 border-bottom">
                                                                {typeof value === 'object' ? 
                                                                    Object.entries(value).map(([vKey, vValue]) => {
                                                                        return(
                                                                        <div className="py-1 w-33">
                                                                            <p className="b-block mb-0">{vKey}:</p>
                                                                            <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                        </div>
                                                                        )
                                                                    })
                                                                :     
                                                                    <p className="f12 text-white">{value}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                : null}
                                                </React.Fragment>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                        this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`mb-1 ${!index}`}>
                                                    <p className="mb-0 f16 text-white">After Changes</p>
                                                    <p className="f12">{(cng.field ? cng.field : '')+''+(cng.updated_value ? +' : '+cng.updated_value : '')}</p>
                                                </div>
                                                {cng.current_features ? 
                                                    Object.entries(cng.current_features).map(([key, value]) => {
                                                        return(
                                                        <div className="py-1">
                                                            <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                            <div className="d-flex mb-1 border-bottom">
                                                                {typeof value === 'object' ? 
                                                                    Object.entries(value).map(([vKey, vValue]) => {
                                                                        return(
                                                                        <div className="py-1 w-33">
                                                                            <p className="b-block mb-0">{vKey}:</p>
                                                                            <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                        </div>
                                                                        )
                                                                    })
                                                                :     
                                                                    <p className="f12 text-white">{value}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                : null}
                                                </React.Fragment>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            </React.Fragment>
                        : this.state.selectedDiagnosticEventLabel === 'Infra Changes' ?
                            <React.Fragment>
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                        this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`mb-1 ${!index}`}>
                                                    <p className="mb-0 f16 text-white">Before Changes</p>
                                                    <p className="f12">{(cng.field ? cng.field : '')+' : '+(cng.previous_value ? cng.previous_value : '')}</p>
                                                </div>
                                                {cng.previous_features ? 
                                                    Object.entries(cng.previous_features).map(([key, value]) => {
                                                        return(
                                                        <div className="py-1">
                                                            <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                            <div className="d-flex mb-1 border-bottom">
                                                                {typeof value === 'object' ? 
                                                                    Object.entries(value).map(([vKey, vValue]) => {
                                                                        return(
                                                                        <div className="py-1 w-33">
                                                                            <p className="b-block mb-0">{vKey}:</p>
                                                                            <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                        </div>
                                                                        )
                                                                    })
                                                                :     
                                                                    <p className="f12 text-white">{value}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                : null}
                                                </React.Fragment>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    {this.state.selectedDiagnosticEvent && this.state.selectedDiagnosticEvent.changes ?
                                        this.state.selectedDiagnosticEvent.changes.map((cng,index) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`mb-1 ${!index}`}>
                                                    <p className="mb-0 f16 text-white">After Changes</p>
                                                    <p className="f12">{(cng.field ? cng.field : '')+' : '+(cng.updated_value ? cng.updated_value : '')}</p>
                                                </div>
                                                {cng.current_features ? 
                                                    Object.entries(cng.current_features).map(([key, value]) => {
                                                        return(
                                                        <div className="py-1">
                                                            <p className={`mb-0 f16 ${typeof value === 'object' ? 'text-white' : ''}`}>{key}</p>
                                                            <div className="d-flex mb-1 border-bottom">
                                                                {typeof value === 'object' ? 
                                                                    Object.entries(value).map(([vKey, vValue]) => {
                                                                        return(
                                                                        <div className="py-1 w-33">
                                                                            <p className="b-block mb-0">{vKey}:</p>
                                                                            <p className="mb-0 text-white">{vValue.toLowerCase() === 'y' ? 'Yes' : vValue.toLowerCase() === 'n' ? 'No' : vValue }</p>
                                                                        </div>
                                                                        )
                                                                    })
                                                                :     
                                                                    <p className="f12 text-white">{value}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                : null}
                                                </React.Fragment>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            </React.Fragment>
                        : this.state.selectedDiagnosticEventLabel === 'SRE Observations' ?
                            <div className=" mt-3">
                                <SreMetricDetails 
                                    assetDetails={this.state.assetDetails}
                                    diagnostics = {this.props.diagnostics}
                                    brush={true}
                                    sparkline={false}
                                    yaxis={true}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={''}
                                    xaxisLabel={true}
                                    grid={false}
                                    axisLabelColor={'#B8BBBE'}
                                    legend={false}
                                    stacked={false}
                                    height={200}
                                    horizontal={true}
                                    barHeight={'40%'}
                                    barEndShape={'rounded'}
                                    columnWidth={'50%'}
                                    gradient={true}
                                    gradientColor={['#24A597', '#775BA2']}
                                    hideTooltipValue={true}
                                    backgroundBarShape={'rounded'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={"transparentTooltip"}
                                    annotations={true}
                                />
                            </div>
                        : this.state.selectedDiagnosticEventLabel === 'Connectivity Checks' && this.state.selectedDiagnosticEvent.conntchks_results && this.state.selectedDiagnosticEvent.conntchks_results !== 'None' && this.state.selectedDiagnosticEvent.conntchks_results.length ?
                            <div className="mt-3">
                                <div className="bg-dark rounded p-3">
                                    <p className="f16 text-white mb-1">Connectivity Checks List</p>
                                    <p className="f12 text-white">Showing {this.state.selectedDiagnosticEvent.conntchks_results.length} {this.state.selectedDiagnosticEvent.conntchks_results.length > 1 ? 'checks' : 'checks'} involved in { this.state.selectedDiagnosticEventLabel}</p>
                                    <ResizeableDarkThemeTable
                                        columns={[
                                            {
                                                Header: 'Date',
                                                accessor: 'conntchks_event_time',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {momentConvertionUtcToLocalTime(cellInfo.row.original.conntchks_event_time, 'DD MMM YYYY HH:mm')}
                                                    </span>
                                                ),
                                            },
                                            {
                                                Header: 'Evnet ID',
                                                accessor: 'conntchks_event_id',
                                            },
                                            {
                                                Header: 'Type',
                                                // accessor: 'dbslowq_event_querytime',
                                                Cell: cellInfo => (
                                                    <span>
                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_type ? cellInfo.row.original.additional_details.connectivity_type : ''}
                                                    </span>
                                                ),
                                                width: 80,
                                            },
                                            {
                                                Header: 'EndPoint',
                                                // accessor: 'dbslowq_event_querytime',
                                                Cell: cellInfo => (
                                                    <span>
                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_endpoint ? cellInfo.row.original.additional_details.connectivity_endpoint : ''}
                                                    </span>
                                                ),
                                                width: 100,
                                            },
                                            {
                                                Header: 'Protocol',
                                                // accessor: 'dbslowq_event_querytime',
                                                Cell: cellInfo => (
                                                    <span>
                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_protocol ? cellInfo.row.original.additional_details.connectivity_protocol : ''}
                                                    </span>
                                                ),
                                                width: 100,
                                            },
                                            {
                                                Header: 'Response',
                                                // accessor: 'dbslowq_event_querytime',
                                                Cell: cellInfo => (
                                                    <span>
                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_resp ? cellInfo.row.original.additional_details.connectivity_resp : ''}
                                                    </span>
                                                ),
                                                width: 100,
                                            },
                                            {
                                                Header: 'Duration',
                                                // accessor: 'dbslowq_event_sqlquery',
                                                Cell: cellInfo => (
                                                    <span className=''>
                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_time ? cellInfo.row.original.additional_details.connectivity_time.toFixed(1) +' '+ (cellInfo.row.original.connectivity_time > 1 ? ' Seconds' : ' Second') : ''}
                                                    </span>
                                                ),
                                                width: 100,
                                            },
                                            {
                                                Header: 'Status',
                                                // accessor: 'dbslowq_event_sqlquery',
                                                Cell: cellInfo => (
                                                    <span>
                                                        {cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.connectivity_status ? cellInfo.row.original.additional_details.connectivity_status : ''}
                                                    </span>
                                                ),
                                                width: 100
                                            },
                                            {
                                                Header: ' ',
                                                Cell: cellInfo => (
                                                    <span className=''>&nbsp;</span>
                                                ),
                                                width: 1
                                            }
                                        ]}
                                        
                                        data={this.state.selectedDiagnosticEvent.conntchks_results}
                                        perPage={5}
                                        dashboard={(this.state.selectedDiagnosticEvent.conntchks_results && this.state.selectedDiagnosticEvent.conntchks_results !== 'None' && this.state.selectedDiagnosticEvent.conntchks_results.length) ? true : false}
                                        sortByColumn={''}
                                        riskTooltip={[]}
                                        onClickRow={tableRow => {}}
                                        tableSize={'table-xs'}
                                    />
                                </div>
                            </div>
                        : null
                    : null}
                    
                    <div className="my-3">
                        {this.state.showMeticsChart ?
                            <MetricsSection
                                metricsType={this.state.selectedDiagnosticEventLabel}
                                assetDetails={this.state.assetDetails}
                                diagnostics = {this.props.diagnostics}
                                brush={true}
                                sparkline={false}
                                yaxis={true}
                                yaxisLabel={true}
                                xaxis={true}
                                xaxisFormat={''}
                                xaxisLabel={true}
                                grid={false}
                                axisLabelColor={'#B8BBBE'}
                                legend={false}
                                stacked={false}
                                height={115}
                                horizontal={true}
                                barHeight={'40%'}
                                barEndShape={'rounded'}
                                columnWidth={'50%'}
                                gradient={true}
                                gradientColor={['#039BE5', '#5F5BA2']}
                                hideTooltipValue={true}
                                backgroundBarShape={'rounded'}
                                backgroundBarColors={['#333947']}
                                showBackgroundBarColors={false}
                                className={"transparentTooltip mt-n4 mb-n4"}
                                annotations={true}
                                annotaionOptions={this.state.mertcisAnnotationTypes}
                            />
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
DiagnosticsRightSection.propTypes = {
    listAllAccounts: PropTypes.func,
	listAllRegions: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    return {
        accounts: state.filters.accounts,
        regions: state.filters.regions,
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        selectedDiagnosticEvent: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selectedDiagnosticEvent ? state.aiops.aiopsPropsDetails.selectedDiagnosticEvent : {},
        aiopsDiagnosticsDetails: state.aiops.aiopsDiagnosticsDetails && state.aiops.aiopsDiagnosticsDetails.result ? state.aiops.aiopsDiagnosticsDetails.result : [],
    }
}

export default connect(mapStateToProps, {
    listAllAccounts,
	listAllRegions,
})(withRouter(DiagnosticsRightSection))