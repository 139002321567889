/*************************************************
 * Tvastar
 * @exports
 * @file assetsAction.js
 * @author Rajasekar // on 05/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'
import _ from 'lodash'
import {
	SYNCHRONIZER,
	GET_ASSET_BY_SERVICE,
	GET_ASSET_BY_SERVICE_DETAILS,
	GET_ASSET_BY_CATEGORY,
	GET_ASSET_BY_CATEGORY_DETAILS,
	GET_RESOURCE_CHART,
	GET_TOTAL_ASSET_COUNT,
	GET_PURCHASE_DETAILS,
	GET_PURCHASE_BY_INSTANACE_TYPE,
	GET_OVERALL_ASSET_COUNT,
	
	BILLING,
	GET_TOTAL_BILLING
} from '../../config'
import { myLog, capitalizeFirstLetter, numberFormater } from '../../utils/utility'

/**
 * Action to get total Asset Count
 * @param {Object} body
 * @param {Function} callback
 */

export const getOverAllAssetCount = (body, callback) => {
	return async () => {
		try {
			const overallAssectCount = await API.post(SYNCHRONIZER, GET_OVERALL_ASSET_COUNT, { body })

			var overallAssectCountArray = {};
			Object.entries(overallAssectCount).forEach(([key,value]) => {
				if(key === 'resource_type' || key === 'category') {
					// let dataRow = {} 
					// dataRow.value = value
					// dataRow.donut = formDoughnutChart(value, 7)
					overallAssectCountArray[key] = value
				} else {
					if(key === 'status' || key === 'availability' || key === 'management' || key === 'action' || key === 'pricing_model') {
						overallAssectCountArray[key] = formCirlceGraph(value)
					} else if(key === 'region') {
						let dataArray = []
						let totalCount = 0;
						let dataTotal = {}
						let availabilityZones = 0
						Object.entries(value).forEach(([dKey,dValue]) => {			
							if(Object.entries(value).length === 1) {
								availabilityZones = 1
								//let totalAvailCount = 0
								Object.entries(dValue.availability_zones).forEach(([aKey,aValue]) => {
									totalCount += aValue;
									let dataAvail = {}
									dataAvail.region = dKey
									dataAvail.key = aKey
									dataAvail.count = aValue
									dataArray.push(dataAvail)
								})
								//dataRow.dataAvailArray = dataAvailArray
								//dataRow.totalCount = totalAvailCount
							} else {
								let dataRow = {}							
								dataRow.availability_zones = dValue.availability_zones	
								totalCount += dValue.count
								dataRow.region = dKey
								dataRow.key = dKey
								dataRow.count = dValue.count
								dataArray.push(dataRow) 
							}
						})	

						let newArray = []
						let i = -1
						let color
						let sortData = _.orderBy(dataArray, ['count'], ['desc'])
						sortData.forEach(item => {
							i++;
							if(i===0) { color = '#4A397C' }
							else if(i===1) {color = '#6E6096' }
							else if(i===2) {color = '#998FB5' }
							else if(i===3) {color = '#08B089' }
							else if(i===4) {color = '#39BFA0' }
							else if(i===5) {color = '#AAE0D3' }
							else if(i===6) {color = '#70798F' }
							else if(i===7) {color = '#9FA4B0' }
							else if(i===8) {color = '#BBBFC7' }
							else if(i===9) {color = '#49E1FC' }
							else if(i===10) {color = '#91EDFD' }
							else if(i===11) {color = '#C8F6FE'; i = -1 }

							item.color = color
							newArray.push(item)
						})
						dataTotal.showingAvailabilityZones = availabilityZones
						dataTotal.totalCount = totalCount
						//dataTotal.data = _.orderBy(dataArray, ['count'], ['desc'])
						dataTotal.data = newArray
						overallAssectCountArray[key] = dataTotal
						//overallAssectCountArray[key] = dataArray
					} else if(key === 'account_id') {
						overallAssectCountArray[key] = value
					} else {
						let dataArray = []
						let totalCount = 0;
						let dataTotal = {}
						
						Object.entries(value).forEach(([dKey,dValue]) => {
							totalCount += dValue
							let dataRow = {}
							dataRow.key = dKey
							dataRow.count = dValue
							dataArray.push(dataRow) 
						})
						
						
						let newArray = []
						let i = -1
						let color
						let sortData = _.orderBy(dataArray, ['count'], ['desc'])
						sortData.forEach(item => {
							i++;
							if(i===0) { color = '#4A397C' }
							else if(i===1) {color = '#6E6096' }
							else if(i===2) {color = '#998FB5' }
							else if(i===3) {color = '#08B089' }
							else if(i===4) {color = '#39BFA0' }
							else if(i===5) {color = '#AAE0D3' }
							else if(i===6) {color = '#70798F' }
							else if(i===7) {color = '#9FA4B0' }
							else if(i===8) {color = '#BBBFC7' }
							else if(i===9) {color = '#49E1FC' }
							else if(i===10) {color = '#91EDFD' }
							else if(i===11) {color = '#C8F6FE'; i = -1 }

							item.color = color
							newArray.push(item)
						})

						dataTotal.totalCount = totalCount
						dataTotal.data = newArray
						overallAssectCountArray[key] = dataTotal
					}
				}
			})
			
			callback(true, overallAssectCountArray)
		} catch (error) {
			myLog('overallAssectCount', error)
			callback(false)
		}
	}
}

export const getTotalBilling = (body, callback) => {
	return async () => {
		try {
			const totalBilling = await API.post(BILLING, GET_TOTAL_BILLING, { body })
			let totalArray = {}
			let array = []
			if(totalBilling.length) {
				//totalBilling = 
				totalBilling.forEach(bill => {
					let dataRow = {}
					dataRow.label = bill.resource_type
					let splitSpace = bill.total_cost.split(' ');
					dataRow.count = parseFloat(splitSpace[1])
					dataRow.total_cost = bill.total_cost	
					array.push(dataRow)
				})								
			}
			let newArray = []
			let totalCost = 0
			let i = -1
			let color
			let sortArray = _.orderBy(array, ['count'], ['desc'])
			sortArray.forEach(item => {
				totalCost += item.count
				i++	
				if(i===0) { color = '#4A397C' }
				else if(i===1) {color = '#6E6096' }
				else if(i===2) {color = '#998FB5' }
				else if(i===3) {color = '#08B089' }
				else if(i===4) {color = '#39BFA0' }
				else if(i===5) {color = '#AAE0D3' }
				else if(i===6) {color = '#70798F' }
				else if(i===7) {color = '#9FA4B0' }
				else if(i===8) {color = '#BBBFC7' }
				else if(i===9) {color = '#49E1FC' }
				else if(i===10) {color = '#91EDFD' }
				else if(i===11) {color = '#C8F6FE'; i = -1 }
				item.color = color
				newArray.push(item)
			})
			totalArray.totalCost = totalCost
			totalArray.data = newArray
				
			callback(true, totalArray)
		} catch (error) {
			myLog('totalBilling', error)
			callback(false)
		}
	}
}

export const getAssetByService = (body, callback) => {
	return async () => {
		try {
			const assetByService = await API.post(SYNCHRONIZER, GET_ASSET_BY_SERVICE, { body })
			var assetByServiceArray = [];
			Object.entries(assetByService).forEach(([key,value]) => {
				value.donut = formDoughnutChart(value.services, 10)
				assetByServiceArray.push(value)
			})

			callback(true, assetByServiceArray)
		} catch (error) {
			myLog('getassetByService', error)
			callback(false)
		}
	}
}

export const getAssetByCategory = (body, callback) => {
	return async () => {
		try {
			const assetByCategory = await API.post(SYNCHRONIZER, GET_ASSET_BY_CATEGORY, { body })

			var assetByCategoryArray = [];
			Object.entries(assetByCategory).forEach(([key,value]) => {
				value.donut = formDoughnutChart(value.services, 10)
				assetByCategoryArray.push(value)
			})

			callback(true, assetByCategoryArray)
		} catch (error) {
			myLog('getassetByCategory', error)
			callback(false)
		}
	}
}


const formDoughnutChart = (data, length) => {
	let doughnutChartData = [];
	let doughnuGraphLabels = [];
	let backgroundColor = [];
	let hoverBackgroundColor = [];

	if(Object.entries(data).length) {
		backgroundColor = [
			'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
		];
		hoverBackgroundColor = [
			'#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'
		];
		let sum = 0;
		Object.entries(data).slice(0,length).forEach(([key,value]) => {
			sum = sum + parseInt(value)
			doughnuGraphLabels.push(key+': '+value);
			doughnutChartData.push(value);
		})

		if(sum === 0) {
			doughnutChartData = [100, 100];
			backgroundColor = ['#ececec'];
			hoverBackgroundColor = ['#ececec'];
		}
	} else {
		doughnuGraphLabels.push('No records');
		doughnutChartData.push(100);
		backgroundColor = ['#ececec'];
		hoverBackgroundColor = ['#ececec'];
	}
		
	let datasetsColumn = [{
		data: doughnutChartData,
		backgroundColor: backgroundColor,
		hoverBackgroundColor: hoverBackgroundColor,
		barThickness : 5,
	}];

	let doughnut_datas = {labels:doughnuGraphLabels, datasets: datasetsColumn}; 
	
	let doughnut_option = {
		tooltips: {
			callbacks: {
				label: function(tooltipItem, doughnutChartData) {
					//let value = doughnutChartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
					//let color = doughnutChartData.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index]
					let label = doughnutChartData.labels[tooltipItem.index]
					return label;
				}
			}
		},
		cutoutPercentage: 50,
		animate   : true,
		animation: { duration: 1000 },
		maintainAspectRatio: false,
		responsive: false,
		legend: {				
			display:true,
			position: 'right',
			labels: {
				boxWidth: 15,
				fontSize: 9
			}
		}
	}

	var graph = {};
	graph.doughnut_datas = doughnut_datas
	graph.doughnut_option = doughnut_option

	return graph;
}

export const getAssetByServiceDetails = (body, callback) => {
	return async () => {
		try {
			const assetByCategoryDetails = await API.post(SYNCHRONIZER, GET_ASSET_BY_SERVICE_DETAILS, { body })
			let formedData = {}
			Object.entries(assetByCategoryDetails).forEach(([key,value]) => {
				formedData[key] = formTableArray(value)
			})
			callback(true, formedData)
		} catch (error) {
			myLog('getassetByCategory', error)
			callback(false)
		}
	}
}

export const getAssetByCategoryDetails = (body, callback) => {
	return async () => {
		try {
			const assetByCategoryDetails = await API.post(SYNCHRONIZER, GET_ASSET_BY_CATEGORY_DETAILS, { body })
			let formedData = {}
			Object.entries(assetByCategoryDetails).forEach(([key,value]) => {
				formedData[key] = formTableArray(value)
			})
			callback(true, formedData)
		} catch (error) {
			myLog('getassetByCategory', error)
			callback(false)
		}
	}
}

const formTableArray = (value) => {
	let result = []
	if(value.length) {
		value.forEach(item => {
			let object = {}
			object.originalData = item
			object.service = item.service
			
			object.total_status_count = parseInt(item.status.active !== undefined ? item.status.active : 0) + parseInt(item.status.inactive !== undefined ? item.status.inactive : 0)
			object.active = item.status.active !== undefined ? item.status.active : 0
			object.inactive = item.status.inactive !== undefined ? item.status.inactive : 0

			object.created = item.actions.created !== undefined ? item.actions.created : 0
			object.modified = item.actions.modified !== undefined ? item.actions.modified : 0
			object.deleted = item.actions.delted !== undefined ? item.actions.delted : 0
			object.total_actions_count = parseInt(item.actions.created !== undefined ? item.actions.created : 0) + parseInt(item.actions.modified !== undefined ? item.actions.modified : 0) + parseInt(item.actions.delted !== undefined ? item.actions.delted : 0)
			result.push(object)
		})
	}
	return result
}

export const getResourceChart = (body, callback) => {
	return async () => {
		try {
			const resourceChart = await API.post(SYNCHRONIZER, GET_RESOURCE_CHART, { body })
			console.log(resourceChart)
			var resourceChartArray = {};
			Object.entries(resourceChart).forEach(([key,value]) => {				
				if(key === 'region') {
					
					let dataArray = []
					Object.entries(value).forEach(([dKey,dValue]) => {			
						let dataObject = {}
						if(Object.entries(dValue.availability_zones).length) {
							let totalCount = 0
							let dataAvailArray = []
							Object.entries(dValue.availability_zones).forEach(([aKey,aValue]) => {
								totalCount += aValue;
								let dataAvail = {}
								dataAvail.key = aKey
								dataAvail.count = aValue
								dataAvailArray.push(dataAvail)
							})
							dataObject.data = _.orderBy(dataAvailArray, ['count'], ['desc'])
							dataObject.region = dKey
							dataObject.totalCount = totalCount
							dataObject.availabilityZones = 1
							dataArray.push(dataObject)
						} else {
							let dataAvailArray = []
							let dataRow = {}
							dataRow.key = dKey
							dataRow.count = dValue.count
							dataAvailArray.push(dataRow)

							dataObject.data = _.orderBy(dataAvailArray, ['count'], ['desc'])
							dataObject.totalCount = dValue.count
							dataObject.availabilityZones = 0
							dataObject.region = dKey	

							dataArray.push(dataObject)
						}
					})	

					let newArray = []
					let color
					dataArray.forEach(zones => {
						let i = -1
						let zoneArray = []
						zones.data.forEach(zone => {
							i++;
							if(i===0) { color = '#4A397C' }
							else if(i===1) {color = '#6E6096' }
							else if(i===2) {color = '#998FB5' }
							else if(i===3) {color = '#08B089' }
							else if(i===4) {color = '#39BFA0' }
							else if(i===5) {color = '#AAE0D3' }
							else if(i===6) {color = '#70798F' }
							else if(i===7) {color = '#9FA4B0' }
							else if(i===8) {color = '#BBBFC7' }
							else if(i===9) {color = '#49E1FC' }
							else if(i===10) {color = '#91EDFD' }
							else if(i===11) {color = '#C8F6FE'; i = -1 }
							zone.color = color
							zoneArray.push(zone)
						})
						let zonesRow = {}
						zonesRow.availabilityZones = zones.availabilityZones
						zonesRow.region = zones.region
						zonesRow.totalCount = zones.totalCount
						zonesRow.data = zoneArray
						newArray.push(zonesRow)
					})
					//console.log(newArray)
					resourceChartArray[key] = newArray
				} else {
					// value.donut = formDoughnutChart(value, 10)
					resourceChartArray[key] = value
				}
			})

			callback(true, resourceChartArray)
		} catch (error) {
			myLog('resourceChart', error)
			callback(false)
		}
	}
}

export const getTotalAssetCount = (body, searchInputs, callback) => {
	return async () => {
		try {
			const totalAssetCount = await API.post(SYNCHRONIZER, GET_TOTAL_ASSET_COUNT, { body })
			const covertToMapArray = await convert_to_map(searchInputs, totalAssetCount)

			callback(true, covertToMapArray)
		} catch (error) {
			myLog('resourceChart', error)
			callback(false)
		}
	}
}

export const getPurchaseDetails = (body, callback) => {
	return async () => {
		try {
			const purchaseDetails = await API.post(SYNCHRONIZER, GET_PURCHASE_DETAILS, { body })

			var purchaseDetailsArray = {};
			Object.entries(purchaseDetails).forEach(([key,value]) => {
				if(key === 'pricing_model') {
					value.donut = formDoughnutChart(value, 10)
					purchaseDetailsArray[key] = value
				} else if(key === 'reservation_details') {
					let dataRow = {}
					Object.entries(value).forEach(([rKey,rValue]) => {
						rValue.donut = formDoughnutChart(rValue, 10)
						dataRow[rKey] = rValue
					})
					purchaseDetailsArray[key] = dataRow
				} else {
					purchaseDetailsArray[key] = value
				}
			})
			callback(true, purchaseDetailsArray)
		} catch (error) {
			myLog('purchaseDetails', error)
			callback(false)
		}
	}
}

export const getPurchaseByInstanceType = (body, callback) => {
	return async () => {
		try {
			const purchaseTableDetails = await API.post(SYNCHRONIZER, GET_PURCHASE_BY_INSTANACE_TYPE, { body })

			callback(true, purchaseTableDetails)
		} catch (error) {
			myLog('purchaseDetails', error)
			callback(false)
		}
	}
}

const convert_to_map = (searchInputs, data) => {
	var result = {};
	data.forEach((item) => {
		var keys = [];
		searchInputs.forEach((groupByKey) => {
			keys.push(item[groupByKey])
		})
		if(!result.hasOwnProperty(keys.join(' / '))) {
			result[keys.join(' / ')] = [item]
		} else {
			result[keys.join(' / ')].push(item);
		}
	})

	return result;
}

const formCirlceGraph = (data) => {
	
	let series = [] 
	let labels = []
	let total = 0
	Object.entries(data).forEach(([key, value]) => {
		series.push(value)
		labels.push(key)
		total += value 
	})
	
	//let overAll = value.overall ? parseInt(value.overall.split('/')[0]) : 0
	let options = {
		stroke: {
			width: 0
		},
		chart: {
			  type: 'donut',
		},
		legend: {
			show: false,
		},
		dataLabels: {
			  enabled: false
		},
		fill: {
			type: 'gradient',
		},
		labels: labels,
		colors: ['#08B089', '#39BFA0', '#4A397C', '#6E6096'],
		//colors: ['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				donut: {
					size: '80%',
					labels: {
						show: true,
						value: {
							fontSize: '13px',
							fontWeight: 800,
							offsetY: 0,
							formatter: function (val) {
								return numberFormater(val)
							}
						},
						name: {
							fontSize: '13px',
							offsetY: -2,
							formatter: function (val) {
								return capitalizeFirstLetter(val)
							}
						},						
						total: {
							show: true,
							label: 'Total',
							fontSize: '13px',
							color: '#4d4848',
							formatter: function (w) {
								return numberFormater(total)
							}
						}
					}
				},      
			}
		},
	}

	let graph = {}
	graph.series = series
	graph.options = options
	graph.data = data
	return graph
}