/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AnomalyAnalysis.js
 * @author Prakash // on 08/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
*************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import _ from 'lodash'
import { momentConvertionLocalToUtcTime, momentDateGivenFormat, momentConvertionUtcToLocalTime, addHours, subHours, capitalizeFirstLetter } from '../../../../utils/utility'

import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getObservabilityTopAnomalies, getAnomalyTotalCount, getObservabilityAnomalyDailyTrend } from '../../../../actions/aiops/ObservabilityAction'

import ApexDonutChart from '../../../common/charts/ApexDonutChart'
import ApexBarChart from '../../../common/charts/ApexBarChart'
import ApexTrendBarChart from '../../../common/charts/ApexTrendBarChart'

// import Chart from 'react-apexcharts'

class AnomalyAnalysis extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            selectedTab: 'Analysis',
            selectedTopErrorBy: 'service_asset',
            start_time: this.props.aiopsCommonPageFilter.start_time ? this.props.aiopsCommonPageFilter.start_time : this.props.start_time,
            end_time: this.props.aiopsCommonPageFilter.end_time ? this.props.aiopsCommonPageFilter.end_time : this.props.end_time,
        }
    }

    componentDidMount = () => {
        if(this.props.start_time && this.props.start_time !== "") {
            this.onSearch()
        } else if(this.props.aiopsCommonPageFilter && this.props.aiopsCommonPageFilter.start_time && this.props.aiopsCommonPageFilter.start_time !== "") {
            this.onSearch()
        }

        if(this.props.anomalyTrend) {
            let graphData = {
                start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
            }
            let graphDetails = this.props.anomalyTrend.results && this.props.anomalyTrend.results.length ? this.props.anomalyTrend.results[0] : {}
            if(graphDetails && graphDetails.data) {
                graphData = {
                    data: graphDetails.data,
                    labels: graphDetails.dates,
                    start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                    end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
                }
            }
            this.totalAnomalies()
            this.setState({ graphData })
        }
    }

    resetAnomalyGraph = () => {
        let graphData = this.state.graphData
        this.setState({ graphData: {} },
            () => {
                this.setState({ graphData })
            }
        )
        // this.setState({ anomalySeries: [], anomalyOptions: {} },
        //     () => this.totalAnomalies()
        // )
    }

    resetAnomalyTypeGraph = (key) => {
        let graphData = this.state[key+"_trend"]
        this.setState({ [key+"_trend"]: {} },
            () => {
                this.setState({ [key+"_trend"]: graphData })
            }
        )
    }
    

    getObservabilityAnomalyDailyTrend = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
        // params.duration = "1d"
        params.aggregate_by = ["anomaly_type"]
        params.radial = true
        params.duration_aggregate_by = "seconds"
        
        this.props.getObservabilityAnomalyDailyTrend(params, (promise, response) => {
            if(promise) {
                this.setState({ anomalyTypeDailyTrend: response && response.results && response.results.length ? response.results[0] : {} },
                    () => {
                        this.formAnomalyTypeTrend()
                    }    
                )
            } else {
                this.setState({ anomalyTypeDailyTrend: [] }, 
                    () => this.formAnomalyTypeTrend()    
                )
            }
        })
    }

    formAnomalyTypeTrend = () => {
        let anomalyGraphTypes = []
        if(this.state.anomalyTypeDailyTrend && Object.entries(this.state.anomalyTypeDailyTrend).length) {
            let graphDetails = this.state.anomalyTypeDailyTrend
            Object.entries(graphDetails.data).forEach(([key, value]) => {
                anomalyGraphTypes.push(key)
                let graphData = {
                    start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                    end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
                }

                graphData = {
                    data: value,
                    labels: graphDetails.dates,
                    start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                    end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
                }
                this.setState({ [key+"_trend"]: graphData })
            }) 
        }
        this.setState({ anomalyGraphTypes })
    }    

    totalAnomalies = () => {
        // let colors = ['#FF0000']
        let colors = ['#775BA2']
        let graphDetails = this.props.anomalyTrend && this.props.anomalyTrend.results && this.props.anomalyTrend.results.length ? this.props.anomalyTrend.results[0] : {}
        let anomalySeries = []
        let columnWidth = ""
        // let xaxisDateFormat = "MMM DD"
        if(graphDetails && graphDetails.data && graphDetails.data) {
            let dataArray = []
            let graphLabels = graphDetails.dates
            graphDetails.data.forEach((val, index) => {
                let dataItems = {}
                dataItems.x = momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                dataItems.y = val
                dataArray.push(dataItems)
            })

            let seriesRow = {}
            seriesRow.name = ""
            seriesRow.data = dataArray

            anomalySeries = [seriesRow]

            if(dataArray.length < 10) {
                columnWidth = '1%'
            }
        } else {
            let dataArray = []
            let dataItems = {}
            dataItems.x = momentDateGivenFormat(this.props.aiopsCommonPageFilter.start_time, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(addHours(this.props.aiopsCommonPageFilter.start_time, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(subHours(this.props.aiopsCommonPageFilter.end_time, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(this.props.aiopsCommonPageFilter.end_time, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            let seriesRow = {}
            seriesRow.name = "No Data"
            seriesRow.data = dataArray
            anomalySeries = [seriesRow]

            if(dataArray.length < 10) {
                columnWidth = '1%'
            }
        }

        let barOptions = {                
            rangeBarOverlap: false,
            rangeBarGroupRows: false,
        }

        if(columnWidth !== "") {
            barOptions.columnWidth = columnWidth
        }
        
        let anomalyOptions = {
            chart: {
                id:"anomaly",
                // redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false,
                },
                type: 'bar',
                height: 150,
                stacked: false,
            },
            markers: {
                size: 0
            },
            colors: colors,
            // plotOptions: {
            //     bar: barOptions
            // },
            dataLabels: {
                enabled: false
            },                
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -5
                },
            },
            xaxis: {
                show: true,
                tooltip: {
                    enabled: false
                },
                axisTicks: {
                    show: false
                },
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#434B5E',
                },
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: '#50586A',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    // formatter: function (value) {
                    //     return momentDateGivenFormat(value, xaxisDateFormat)
                    // }
                    // offsetX: 0,
                    // offsetY: -5,
                },
            },
            yaxis: {
                show: true,
                min: 0,
                tickAmount: 5,
                axisBorder: {
                    show: false,
                    color: '#434B5E',
                },
                labels: {
                    offsetX: -10,
                    show: true,
                    style: {
                        colors: '#50586A',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    formatter: function(val, index) {
                        return parseInt(val);
                    }
                },
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, "DD MMM YYYY HH:mm") + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'"> Anomalies </span> ' + val + ' </span>' +
                        '</div>'
                },
                style: {
                    fontSize: '9px',
                },
            },
        }

        this.setState({ anomalySeries, anomalyOptions })
    }

    onSearch = () => {
        this.setState({
            showDonut: false,
            topEvents: {}, topAssets: {}
        }, () => {
            this.getAnomalyTotalCount()
            this.getObservabilityTopAnomalies()
            this.getObservabilityAnomalyDailyTrend()
        })
    }

    getObservabilityTopAnomalies = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            "top": 5,
	        "aggregate_by": [["asset_name", "anomaly_name"]],
            //'aggregate_by': [["importance","lifecycle","assets.resource_type","category"]]
        }

        this.props.getObservabilityTopAnomalies(params, (promise, response) => {
            if(promise) {
                this.setState({ topaAnomalies: response.length && response[0].data ? response[0].data : {} },
                    () => this.topAnamolySectionGraph()
                )
            }
        }) 
    }

    topAnamolySectionGraph = () => {
        if(this.state.topaAnomalies.asset_name) {
            let list = this.state.topaAnomalies.asset_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topAssets: topStatusData })
        }
        
        if(this.state.topaAnomalies.anomaly_name) {
            let list = this.state.topaAnomalies.anomaly_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topAnomalies: topStatusData })
        }        
    }

    getAnomalyTotalCount = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'radial': true,
            'aggregate_by': [["resource_type", "severity", "anomaly_type"]]
        }

        this.props.getAnomalyTotalCount(params, (promise, response) => {
            if(promise) {
                this.setState({ rightSideTotalSection: response.results && response.results.length && response.results[0].data ? response.results[0].data : {} },
                    () => this.rigthSideTopSection()    
                )
            }
        }) 
    }

    rigthSideTopSection = () => { 
        
        //services Donut start
        let services = []
        let services_total_count = 0
        this.state.rightSideTotalSection.resource_type && Object.entries(this.state.rightSideTotalSection.resource_type).forEach(([key, value]) => {
            let donutRow = {}
            donutRow.item_count = value
            donutRow.item_name = key
            services.push(donutRow)
            services_total_count += value 
        })

        services = _.orderBy(services, ['item_count'], ['desc'])
        services = services.slice(0,10)

        let resourceDonutData = {
            "items": services,
            "label": "Services",
            "total_count": services_total_count
        }

        //services Donut start

        //severity Donut start
        let severity = []
        let severity_total_count = 0
        this.state.rightSideTotalSection.severity && Object.entries(this.state.rightSideTotalSection.severity).forEach(([key, value]) => {

            let donutRow = {}
            donutRow.item_name = key
            donutRow.item_count = value
            donutRow.orderBy = key.toLowerCase() === 'critical' ? 1 :key.toLowerCase() === 'high' ? 2 : key.toLowerCase() === 'medium' ? 3 : 4
            severity.push(donutRow)

            severity_total_count += value 
        })

        severity = _.orderBy(severity, ['orderBy'], ['asc'])

        let severityDonutData = {
            "items": severity,
            "label": "Severity",    
            "total_count": severity_total_count
        }

        //severity Donut start

        this.setState({ severityDonutData, resourceDonutData, showDonut: true })
    }

    render() {
        return (
            <div className={`${this.props.start_time ? "p-3 bg-muted" : ""}`} style={{maxHeight: "100%"}}>
                <div className="col-sm-12 mt-2">
                    <div className="row">
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.severityDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.severityDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#039BE5']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.resourceDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.resourceDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}                                
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                    </div>
                </div>
                <div className="rounded counts_threads p-3 mt-2">
                    <div className="row mb-3">
                        <div className="col-lg-6">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Anomalies</label>
                                {this.state.topAnomalies && Object.entries(this.state.topAnomalies).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.topAnomalies}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            paddingLeft={-10}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="col-lg-6 pl-0">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Assets</label>
                                {this.state.topAssets && Object.entries(this.state.topAssets).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.topAssets}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            paddingLeft={-10}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="bg-dark p-3 rounded">
                                <div className="d-flex justify-content-between">
                                    <label>Total Anomaly Trend </label>
                                    <i className='far fa-redo cursorPointer' onClick={()=> this.resetAnomalyGraph()}></i>
                                </div>
                                {this.state.graphData && Object.keys(this.state.graphData).length ?
                                    <div className="mt-n2"> 
                                        <ApexTrendBarChart
                                            graphData={this.state.graphData}
                                            graphRiskCountSection={false}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            yaxisTickAmount={2}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            legend={false}
                                            stacked={false}
                                            height={220}
                                            horizontal={false}
                                            barHeight={'40%'}
                                            barEndShape={'flat'}
                                            columnWidth={'0%'}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={true}
                                            className={"transparentTooltip"}
                                            colors={['#3DAFE8']}
                                        />
                                    </div>
                                : null}
                                {/* <div className="transparentTooltip mt-n2">
                                    {this.state.anomalySeries && this.state.anomalySeries.length ?
                                        <Chart options={this.state.anomalyOptions} series={this.state.anomalySeries} type="bar" height={200} />
                                    : null}
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {this.state.anomalyGraphTypes && this.state.anomalyGraphTypes.length ?
                        this.state.anomalyGraphTypes.map(item => {
                            return (
                                <div className="row mb-3">
                                    <div className="col-lg-12">
                                        <div className="bg-dark p-3 rounded">
                                            <div className="d-flex justify-content-between">
                                                <label>
                                                    {item === "apicalls" ? "Api calls" : item === "metric" ? "Metric" : item === "logs" ? "Logs" : item} Anomaly Trend 
                                                </label>
                                                <i className='far fa-redo cursorPointer' onClick={()=> this.resetAnomalyTypeGraph(item)}></i>
                                            </div>
                                            {this.state[item+"_trend"] && Object.keys(this.state[item+"_trend"]).length ?
                                                <div className="mt-n2"> 
                                                    <ApexTrendBarChart
                                                        graphData={this.state[item+"_trend"]}
                                                        graphRiskCountSection={false}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        yaxisTickAmount={2}
                                                        xaxis={true}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#50586A'}
                                                        legend={false}
                                                        stacked={false}
                                                        height={220}
                                                        horizontal={false}
                                                        barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={'0%'}
                                                        backgroundBarShape={'rounded'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={true}
                                                        className={"transparentTooltip"}
                                                        colors={['#3DAFE8']}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    : null}
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
AnomalyAnalysis.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},        
        anomalyTrend: state.observability.observabilityAnomalyTrend
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getObservabilityTopAnomalies,
    getObservabilityAnomalyDailyTrend,
    getAnomalyTotalCount,
})(withRouter(AnomalyAnalysis))