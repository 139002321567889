/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertsDashboard.js
 * @author Prakash // on 12/11/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import { CSVLink } from "react-csv";
import Select from 'react-select'

import { getAllTagsKeys, getAllTagsValues } from '../../../../actions/commonAction'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { listObservabilityAlertGroups } from '../../../../actions/aiops/ObservabilityAction'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'

// import ResizeableDarkThemeTable from '../../../designComponents/Table/ResizeableDarkThemeTable'
import RightSection from '../RightSection'

class AlertsDashboard extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            showLoading: true,
            
            jumpDate: '',
            alertDetails: {},
            showAnnotation: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,
            
            alertType: "All",
            groupedBy: 'severity',
            selectedCategory: 'Important / All',
            pageType: "Alerts",
            showRightSide: true,

            tagsArray: [0],
        }
    }

	componentDidMount = () => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            this.setState({ 
                start_time: this.props.aiopsCommonPageFilter.start_time,
                end_time: this.props.aiopsCommonPageFilter.end_time,
                brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                showRightSide: false,
                alertListLoader: true
            },
                () => {
                    this.setState({ showRightSide: true })
                    this.listObservabilityAlertGroups()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            if(!_.isEqual(prevProps.aiopsCommonPageFilter, this.props.aiopsCommonPageFilter)) {
                this.setState({ 
                    start_time: this.props.aiopsCommonPageFilter.start_time,
                    end_time: this.props.aiopsCommonPageFilter.end_time,
                    brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                    brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                    showRightSide: false,
                    alertListLoader: true
                },
                    () => {
                        this.setState({ showRightSide: true })
                        this.listObservabilityAlertGroups()
                    }
                )
            }
        }
    }

    navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, pageNumber, startRecord })
        } else  if(pageNumber > this.state.totalPages) {
            this.setState({ pageNumber: this.state.currentPage })  
        } else {
            this.setState({ pageNumber })
        }
    }

    listObservabilityAlertGroups = () => {
        let params = {}
        // params.alerts_case_id = '2408-1028-7602'
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.aggregate_by = this.state.groupedBy
        if(this.state.alertType !== "All") {
            params.alert_type = this.state.alertType
        }
        params.include_name = true

        this.props.listObservabilityAlertGroups(params, (promise, response) => {
            if(promise) {
                let data = response.results && response.results.details ? response.results.details : []
                this.setState({ alertResponse: data, alertListLoader: false },
                    () => {
                        this.structureAlerts()
                        this.props.setAiopsPropsDetails('tabOptions', {})
                    }    
                )
            } else {
                this.setState({ alertsList: [], alertListLoader: false })
            }
        })
    }

    structureAlerts = () => {
        let listAllAlerts = []
        
        let totalCount = 0
        let alertsCategory = []
        if(this.state.alertResponse && this.state.alertResponse.length) {
            totalCount = this.state.alertResponse.reduce( function(a, b){ return a + b.count;}, 0);
            this.state.alertResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                alertsCategory.push(dataRow)
                item.alerts.forEach(alt => {
                    listAllAlerts.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        alertsCategory.push(dataRow)

        this.setState({ listAllAlerts, alertsList: listAllAlerts, alertsCategory: _.orderBy(alertsCategory, ['orderBy'], ['asc'])},
            () => this.getCategoryAlerts()
        )
    }

    getCategoryAlerts = () => {
        let filterdCategoryAlerts = this.state.listAllAlerts
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryAlerts = this.state.alertResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                filterdCategoryAlerts = filterdCategoryAlerts[0].alerts ? filterdCategoryAlerts[0].alerts : []
            }
        }

        let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], ['desc'])

        let totalPages = 1
		if(orderedArray.length > this.state.perPage) {
			totalPages = Math.ceil(orderedArray.length / this.state.perPage)
		}

        this.setState({ totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedAlertId: '', totalPages, startRecord: 0, currentPage: 1 },
            () => {
                // this.redirectToAnalysis()
                this.formHeaders()
                if(this.state.filteredArray.length) {
                    this.getAlertDetails(this.state.filteredArray[0])
                }
            } 
        )
    }

    getAlertDetails = (alertItemDetails) => {
        alertItemDetails.group_id = alertItemDetails.event_id ? alertItemDetails.event_id : ''
        alertItemDetails.type = 'alert'
        this.setState({ selectedAlertId: alertItemDetails.event_id })

        let obj = {}
        obj.selectedTab = 'Details'
        obj.details = alertItemDetails
        obj.merticsAssetNameInvolved = alertItemDetails.asset_id ? [alertItemDetails.asset_id] : []
        obj.brushStartTime = this.state.brushStartTime
        obj.brushEndTime = this.state.brushEndTime
        obj.pageType = this.state.pageType
        this.props.setAiopsPropsDetails('tabOptions', obj)
    }
    
    handleChange = (event) => {
        let groupedBy = this.state.groupedBy
        if(event.target.value === "Application" && groupedBy !== "severity" && groupedBy!== "event_name") {
            groupedBy = "severity"
        }
        this.setState({ alertType: event.target.value, groupedBy, selectedAlertId: '', alertListLoader: true },
            () => {
                this.listObservabilityAlertGroups()
                // this.redirectToAnalysis()
            }    
        )
        // this.setState({ alertType: event.target.value },
        //     () => {
        //         let alertsList = this.state.listAllAlerts
        //         if(this.state.alertType !== "All") {
        //             alertsList = alertsList.filter(e => e.alert_type === "")
        //         }

        //     }    
        // )
    }

    // redirectToAnalysis = () => {
    //     let obj = {}
    //     obj.selectedTab = 'Analysis'
    //     obj.details = {}
    //     obj.merticsAssetNameInvolved = []
    //     obj.brushStartTime = this.state.brushStartTime
    //     obj.brushEndTime = this.state.brushEndTime
    //     this.props.setAiopsPropsDetails('tabOptions', obj)
    // }

    handleClickOutside(event) {
        if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
            this.setState({ showPageTypeOptions: false })
        } else {
            this.setState({ showPageTypeOptions: true })
        }
    }

    getAllTagsKeys = () => {
        if(!this.props.tagKeys || !this.props.tagKeys.length) {
            let params = {}
            this.props.getAllTagsKeys(params, (promise, response) => {})
        }
    }

    getAllTagsValues = (index) => {
        let params = {}
        params.tags = [{ "key": this.state["tag_key_"+index] }]
        this.props.getAllTagsValues(params, (promise, response) => {
            if(promise) {
                this.setState({ ["tagValues_"+index]: response })
            }
        })
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList },
            // () => this.checkAllDataFilled()    
        )
    }

    removeSection = (item, i) => {
        this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" },
            () => {
                let rowList = this.state.tagsArray;
                rowList.splice(i, 1);
                this.setState({ tagsArray: rowList })
            }
        )
    }

    resetFilterSelection = () => {
        this.state.tagsArray.forEach(item => {
            this.setState({ ["tag_key_"+item]: "", ["tag_value_"+item]: "" })
        })

        this.setState({ tagsArray: [0], selectedService: [], assetCategory: [] },
            // () => this.filterResults()
        )
    }

    filteredResults = () => {
        let results = this.state.totalFilteredArray ? this.state.totalFilteredArray : []
        
        if(results && results.length && this.state.tagsArray && this.state.tagsArray.length) {
            let tagMatchedData = []
            let recordExist = false
            this.state.tagsArray.forEach(item => {
                if(this.state["tag_key_"+item] && this.state["tag_key_"+item] !== "" && this.state["tag_value_"+item] && this.state["tag_value_"+item] !== "") {
                    recordExist = true
                    if(tagMatchedData && tagMatchedData.length) {
                        let newTagsMatched = []
                        tagMatchedData.forEach(itm => {
                            if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === this.state["tag_key_"+item] && e.value === this.state["tag_value_"+item]).length) {
                                newTagsMatched.push(itm)
                            }
                        })
                        tagMatchedData = newTagsMatched
                    } else {
                        results.forEach(itm => {
                            if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === this.state["tag_key_"+item] && e.value === this.state["tag_value_"+item]).length) {
                                tagMatchedData.push(itm)
                            }
                        })                        
                    }
                }
            })
            console.log("recordExist", recordExist)
            if(recordExist) {
                results = tagMatchedData
            }

            console.log("results", results)
            console.log("tagMatchedData", tagMatchedData)
        }

        if(this.state.searchText && this.state.searchText !== '') {
			results =
				results &&
				results.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

        let totalPages = 1
        if(results && results.length > this.state.perPage) {
            totalPages = Math.ceil(results.length / this.state.perPage)
        }
        let showLoadMore = false
        if(results.length > this.state.endRecord) {
            showLoadMore = true
        } 

        this.setState({ filteredArray: results, totalPages, startRecord: 0, currentPage: 1, showLoadMore },
            () => this.formHeaders()    
        )
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string" && key !== "event_data") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

	render() {
		return (
            <div className="" onClick={(event) => this.handleClickOutside(event)}>
                <div className="w-100">
                    <div className={`d-flex mt-2 ml-1 ${this.state.alertListLoader ? 'disabled' : ''}`}>
                        <div className="select-sm-bg curvedDropdown minWidth120 mr-2" ref={this.toggleRef}>
                            <div className="dropdown">
                                <span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
                                    {this.state.pageType ? this.state.pageType : <span className="placeholder">Select</span>}
                                </span>
                                <ul className={`dropdown-menu p-0 ${this.state.showPageTypeOptions ? "show" : ''}`}>
                                    {this.props.pageTypes.map(item => {
                                        return (
                                            <li 
                                                onClick={() => {
                                                    this.props.setAiopsPropsDetails('tabOptions', {})
                                                    this.setState({ 
                                                        pageType: item, 
                                                        showPageTypeOptions: false
                                                    }, 
                                                        () => {
                                                            this.props.onChangePageType(item)
                                                        }
                                                    )
                                                }}
                                                className={`${this.state.pageType === item ? "selected" :  ""}`}
                                            >
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <p className="m-0 align-self-end small mr-2">Show </p>
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                            onChange={this.handleChange}
                            value={this.state.alertType}
                            >
                                <option key="All" value="All">ALL</option>
                                <option key="Application" value="Application">Application</option>
                                <option key="Infra" value="Infra">Infra</option>
                            </select>
                        </div> 
                        <p className="m-0 align-self-end small mr-2"> alerts groupd by</p>
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                                onChange={(event) => {
                                    this.setState({ groupedBy: event.target.value, selectedAlertId: '', selectedCategory: "Important / All", alertListLoader: true },
                                        () => {
                                            this.listObservabilityAlertGroups()
                                            // this.redirectToAnalysis()
                                        }    
                                    )
                                }}
                                value={this.state.groupedBy}                            
                                >
                                <option value='severity'>Severity</option>
                                <option value='event_name'>Name</option>
                                {this.state.alertType !== "Application" ?
                                    <React.Fragment>
                                    <option value='asset_name'>Assets</option>
                                    <option value='resource_type'>Service</option>
                                    <option value='category'>Category</option>
                                    </React.Fragment>
                                : null}
                            </select>
                        </div>
                        <i className="fas fa-filter align-self-end cursorPointer ml-3 f14" onClick={() => this.setState({ showFilterSection: !this.state.showFilterSection }, () => this.getAllTagsKeys())}></i>
                    </div>
                    
                    <div className="d-flex mt-2">
                        <div className={`${this.state.expandRIghtSection ? 'w-60' : 'w-60'} mr-1`}>
                            {this.state.showFilterSection ?
                                <div className="observabilityEventDashboardFilter border mt-2">
                                    <div className="p-3 zapInputDark bg-muted">
                                        <div className={`w-100 mt-2`}>
                                            <label className="mb-1">Tags</label>
                                            {this.state.tagsArray.map((index, i) => {
                                                return(
                                                    <div className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                                        <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                            <div className="w-80">
                                                                <Select
                                                                    placeholder={'Select'}
                                                                    isSearchable={true}
                                                                    // menuIsOpen={true}
                                                                    className='f13 p-0 dark-select-theme-with-dropdown-indicator'
                                                                    value={({
                                                                        value: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : 'Select',
                                                                        label: this.state["tag_key_"+index] && this.state["tag_key_"+index] !== "" ? this.state["tag_key_"+index] : <span className="placeholder">Select</span>
                                                                    })}
                                                                    options={this.props.tagKeys && this.props.tagKeys.map(item => ({
                                                                        value: item,
                                                                        label: item,	
                                                                    }))}
                                                                    onChange={event => this.setState({
                                                                        ["tag_key_"+index]: event.value,
                                                                        ["tagValues_"+index]: [],
                                                                    },
                                                                        () => this.getAllTagsValues(index)
                                                                    )}
                                                                />
                                                                <Select
                                                                    placeholder={'Select'}
                                                                    isSearchable={true}
                                                                    // menuIsOpen={true}
                                                                    className='f13 p-0 dark-select-theme-with-dropdown-indicator mt-2'
                                                                    value={({
                                                                        value: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : 'Select',
                                                                        label: this.state["tag_value_"+index] && this.state["tag_value_"+index] !== "" ? this.state["tag_value_"+index] : <span className="placeholder">Select</span>
                                                                    })}
                                                                    options={this.state["tagValues_"+index] && this.state["tagValues_"+index].map(item => ({
                                                                        value: item,
                                                                        label: item,	
                                                                    }))}
                                                                    onChange={event => this.setState({
                                                                        ["tag_value_"+index]: event.value
                                                                    })}
                                                                />
                                                            </div>
                                                            {this.state.tagsArray.length > 1 ?
                                                                <span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.removeSection(index, i)}></span>
                                                            : null}
                                                            {(this.state.tagsArray.length - 1) ===  i ?
                                                                <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addSection("tagsArray")}></span>
                                                            : null}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                            <span className={`text-info mr-2 align-self-center`} onClick={() => this.resetFilterSelection()}>Clear</span>
                                            <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => this.setState({ showFilterSection: false }, () => this.filteredResults())}>Filter</button>
                                            <button className={`btn btn-sm btn-light`} onClick={() => this.setState({ showFilterSection: false })}>Close</button>
                                        </div>
                                    </div>
                                </div>
                            : null}
                            <div className="rounded bg-dark p-3">
                                <div className="d-flex mb-2 justify-content-between">
                                    <div className="d-flex mr-2 w-40">
                                        <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length}{this.state.totalFilteredArray && this.state.totalFilteredArray.length > 1 ? ' alerts' : ' alert'}</p>
                                        {/* {this.state.totalErrorList && this.state.totalErrorList.length}{this.state.totalErrorList && this.state.totalErrorList.length > 1 ? ' errors' : ' error'} from </p> */}
                                        <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}</p>
                                        {/*  */}
                                    </div>
                                    <div className="d-flex justify-content-end w-60 mb-n2">
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <CSVLink 
                                                data={this.state.filteredArray} 
                                                headers={this.state.headers ? this.state.headers : []}
                                                filename={"observability-alerts.csv"}
                                                className={"align-self-center"}
                                                target="_blank"
                                            >
                                                <i className="fas fa-download text-primary-color"></i>
                                                {/* <span className="text-primary-color mx-2">csv</span> */}
                                            </CSVLink>
                                        : null}
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <Search
                                                data={this.state.totalFilteredArray ? this.state.totalFilteredArray : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-gray5 f12'}
                                                searchPlaceHolder={'Search alerts....'}
                                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                filteredData={(filteredArray) => {
                                                    this.setState({ filteredArray, alertDetails: {}, merticsAssetNameInvolved: [] },
                                                        () => {
                                                            if(this.state.filteredArray.length) {
                                                                this.getAlertDetails(this.state.filteredArray[0])
                                                            }
                                                        }    
                                                    )
                                                }}
                                            />
                                        : null}
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.pageNumber || this.state.pageNumber === "" ? this.state.pageNumber : this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span>
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                
                                <div className="d-flex errorSectionScroll">
                                    {this.state.alertsCategory ? 
                                        <div className="w-20 pr-3 categoryBoxSection">
                                            <div class="bg-muted px-2 pt-2 select-bgg">
                                                {this.state.groupedBy === "severity" ?
                                                    this.state.alertsCategory.map(item => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts())}>
                                                                    <div class="d-flex">
                                                                        {item.totalBars.map(bar => {
                                                                            return(
                                                                            <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                            )
                                                                        })}
                                                                        <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                            <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    })                                            
                                                : 
                                                    this.state.alertsCategory.map((item, index) => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                            <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts())}>
                                                                <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                    <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                    <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                    <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    : null}
                                    {/* {this.state.alertsCategory ? 
                                        <div className="mr-1 w-20">
                                            <div className="col-12 p-0 bg-dark3 h-100">
                                                {this.state.alertsCategory.map(item => {
                                                    return(
                                                        <div className={`d-flex p-2 border-bottom cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts())}>
                                                            <p className="m-0 f-20">{item.category}</p>
                                                            <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    : null} */}
                                    <div className={`${this.state.alertsCategory ? 'w-80' : 'w-100'}`}>
                                        <div className={`bg-dark3 py-3 pl-2`} style={{minHeight: "545px"}}>
                                            {this.state.alertListLoader ? 
                                                <div className='d-flex justify-content-center m-4'>
                                                    <Spinner className='text-center' color='white' size='lg' />
                                                </div>
                                            : this.state.filteredArray && this.state.filteredArray.length ?
                                                this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                                    return(
                                                        <React.Fragment>
                                                        <div className={`d-flex mr-2 px-2 mb-2 pb-1 ${this.state.startRecord + this.state.perPage !== this.state.startRecord + i ? 'border-bottom-black5' : ''} ${this.state.selectedAlertId !== item.group_id ? 'cursorPointer' : 'bg-muted text-white pt-1'}`} onClick={() => this.getAlertDetails(item)
                                                        }>  
                                                            <div className="d-flex">
                                                                <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                                    {item.severity ? 
                                                                        (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                    : 'U'}
                                                                </span>
                                                                <p className="mb-0">
                                                                    <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                    {/* <span className="f12 mb-0 mr-2 text-purple-2"> {item.total_alerts+ (item.total_alerts>1 ?' alerts':' alert')}</span>  */}
                                                                    <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                                        {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                                        {item.event_name ? ' : ' + item.event_name : ''}
                                                                        {item.event_type ? ' : '+ item.event_type : ''}</span>
                                                                    {/* <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.event_type ? item.event_type : ""}</span> */}
                                                                    {/* <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.event_name ? item.event_name : ""}</span> */}
                                                                    {item.asset_name ?
                                                                        <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                                    : null}
                                                                    <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                    {item.account_id ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                                    : null}
                                                                    {item.region ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                                    : null}
                                                                    {item.resource_type ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                                    : null}
                                                                    {item.service_name ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                                    : null}
                                                                    {item.description ?
                                                                        <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                                    : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                            :
                                                <div className='d-flex justify-content-center m-4'>
                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!this.state.alertListLoader ? 
                            <div className={`${this.state.expandRIghtSection ? 'w-40' : 'w-40'}`}>
                                <RightSection
                                    updateDetail = {() => this.setState({ selectedAlertId: '' }, () => this.props.setAiopsPropsDetails('tabOptions', {}))}
                                    pageType={this.state.pageType}
                                />
                            </div>
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AlertsDashboard.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},
        tagKeys: state.filters.tagKeys,
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getAllTagsKeys,
    getAllTagsValues,
    listObservabilityAlertGroups,
})(withRouter(AlertsDashboard))