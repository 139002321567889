/*************************************************
 * Tvastar
 * @exports
 * @file resultsAction.js
 * @author Rajasekar // on 27/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	DETECTORS,	
	GET_DAY_WISE_COUNT_TAGS,
	GET_BILL_DETAILS,
	GET_ISSUE_STATUS_COUNT,
	GET_STATUS_COUNT,
	GET_COUNT,
	GET_COST_TREND,
	GET_COMPLIANCE_COVERAGE,
	
	SCORE,
	GET_SCORE,
	GET_DAILY_SCORE,
} from '../../config'
import { myLog } from '../../utils/utility'

/**
 * Action to get all detectors list
 * @param {Object} body
 * @param {Function} callback
 */

/**
 * Action to get score
 * @param {Object} body
 * @param {Function} callback
 */
export const getScore = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(SCORE, GET_SCORE, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to get day wise tag count
 * @param {Object} body
 * @param {Function} callback
 */
export const getDayWiseCountTags = (body, callback) => {
	return async () => {
		try {
			const day_wise_count_tags = await API.post(DETECTORS, GET_DAY_WISE_COUNT_TAGS, { body })
			callback(true, day_wise_count_tags)
		} catch (error) {
			myLog('day_wise_count_tags', error)
			callback(false)
		}
	}
}
/**
 * Action to get bill count
 * @param {Object} body
 * @param {Function} callback
 */
export const getBillingCount = (body, callback) => {
	return async () => {
		try {
			const bill_count = await API.post(DETECTORS, GET_BILL_DETAILS, { body })
			callback(true, bill_count)
		} catch (error) {
			myLog('bill_count', error)
			callback(false)
		}
	}
}
/**
 * Action to get bill count
 * @param {Object} body
 * @param {Function} callback
 */
export const getStatusCount = (body, callback) => {
	return async () => {
		try {
			const issue_status_count = await API.post(DETECTORS, GET_STATUS_COUNT, { body })
			callback(true, issue_status_count)
		} catch (error) {
			myLog('getIssueStatusCount', error)
			callback(false)
		}
	}
}
/**
 * Action to get bill count
 * @param {Object} body
 * @param {Function} callback
 */
export const getIssueStatusCount = (body, callback) => {
	return async () => {
		try {
			const issue_status_count = await API.post(DETECTORS, GET_ISSUE_STATUS_COUNT, { body })
			callback(true, issue_status_count)
		} catch (error) {
			myLog('getIssueStatusCount', error)
			callback(false)
		}
	}
}
/**
 * Action to get count
 * @param {Object} body
 * @param {Function} callback
 */
export const getCount = (body, callback) => {
	return async () => {
		try {
			const get_count = await API.post(DETECTORS, GET_COUNT, { body })
			callback(true, get_count)
		} catch (error) {
			myLog('getCount', error)
			callback(false)
		}
	}
}

/**
 * Action to get cost Trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostTrend = (body, callback) => {
	return async () => {
		try {
			const get_cost_trend = await API.post(DETECTORS, GET_COST_TREND, { body })
			callback(true, get_cost_trend)
		} catch (error) {
			myLog('getCount', error)
			callback(false)
		}
	}
}

/**
 * Action to get Daily Score
 * @param {Object} body
 * @param {Function} callback
 */
export const getDailyScore = (body, callback) => {
	return async () => {
		try {
			const get_daily_score = await API.post(SCORE, GET_DAILY_SCORE, { body })
			callback(true, get_daily_score)
		} catch (error) {
			myLog('getCount', error)
			callback(false)
		}
	}
}



/**
 * Action to get compliance coverage
 * @param {Object} body
 * @param {Function} callback
 */
 export const getComplianceCoverage = (body, callback) => {
	return async () => {
		try {
			const response = await API.get(DETECTORS, GET_COMPLIANCE_COVERAGE, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}