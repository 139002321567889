/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RcaLeftSection.js
 * @author Prakash // on 07/10/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component  } from 'react'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../../config/urlPath'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Search from '../../common/SearchComponent'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, getAccountNameFromId } from '../../../utils/utility'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// import { listAllAccounts, listAllRegions } from '../../../actions/commonAction'
import { setDiagnosticsPropsDetails } from '../../../actions/aiops/DiagnosticsAction'


class RcaLeftSection extends Component {
	diagnosticsSlider = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            minMaxHeight: "700px",
            troubleshootDetails: this.props.troubleshootDetails,
			rca_block_0: true,
        }
    }
    
    componentDidMount = () => {
        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length !== 0) {
            this.setState({ diagnostics: this.props.location.state.diagnostics })
        }

        this.getWindowSize()
        window.addEventListener("resize", () => {
            this.getWindowSize()
        })

        this.onLoad()
    }
    componentDidUpdate = (prevProps) => {
        if(!_.isEqual(prevProps.troubleshootDetails, this.props.troubleshootDetails) && this.props.troubleshootDetails && this.props.troubleshootDetails.length) {

            this.onLoad()
        }
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 90  
		

        let groupSection = 30
        if(this.state.groupedDiagnostics && this.state.groupedDiagnostics.length) {
            let groupRowsHeight = Math.ceil(this.state.groupedDiagnostics.length / 5)
            groupRowsHeight = 44 * groupRowsHeight

            groupSection = groupSection + groupRowsHeight
        }
        let outerHeight = (window.outerHeight - browserBorder - headerHeight - groupSection) +"px"

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

    onLoad = () => {
        let obj = {}
        obj.label = ""
        obj.eventGroup = ""
        obj.flag = ""
        obj.data = {}
        obj.troubleshootDetails = this.props.troubleshootDetails && this.props.troubleshootDetails.length ? this.props.troubleshootDetails [0] : {}
        obj.asset_details = ""
        obj.tableHeading = ""
        this.props.setDiagnosticsPropsDetails("selectedDiagnosticRcaEventDetail", obj)
    }    

    onExpandChecks = (item, index, row, rowIndex) => {
        this.setState({ ["rca_checks_"+rowIndex]: !this.state["rca_checks_"+rowIndex] },
            () => {
                if(row.data && row.data.length && this.state["rca_checks_"+rowIndex]) {
                    this.onChangeTroubleshootEvent(item, row, row.data[0], index, rowIndex, 0, "selected_"+index+"_"+rowIndex+"_0", "", "")
                }
            }
        )
    }

    onChangeTroubleshootEvent = (troubleshootDetails, row, data, index, rowIndex, trbIndex, selectedRcaTrouble, asset_id, tableHeading) => {
        let asset_details = []
        if(data.asset_id && data.asset_id !== "") {
            let assetRow = {}
            assetRow.asset_id = data.asset_id ? data.asset_id : ""
            assetRow.asset_name = data.asset_name ? data.asset_name : ""
            assetRow.asset_arn = data.asset_arn ? data.asset_arn : ""
            assetRow.resource_type = data.resource_type ? data.resource_type : ""
            assetRow.service_name = data.service_name ? data.service_name : ""
            assetRow.provider = data.provider ? data.provider : ""
            assetRow.account_id = data.account_id ? data.account_id : ""
            assetRow.region = data.region ? data.region : ""
            asset_details.push(assetRow)
        } else if(data.assets) {
            asset_details = data.assets
        }

        let assetDetails = Array.isArray(asset_details) && asset_details.length ? asset_details[0] : {}

        let obj = {}
        obj.label = row.label
        obj.eventGroup = row.tag
        obj.flag = row.flag
        obj.data = data
        obj.troubleshootDetails = troubleshootDetails
        obj.asset_details = assetDetails
        obj.tableHeading = ""
        this.props.setDiagnosticsPropsDetails("selectedDiagnosticRcaEventDetail", obj)

        this.setState({ selectedRcaTrouble })
    }

	render() {
		return (
            <div className="">
                <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div> 
                <div className="col-md-12 p-3 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-self-center">
                            <div className="mr-3">
                                <Link 
                                    className="text-info far fa-arrow-left" 
                                    to={{
                                        pathname: URL_PATH.DIAGNOSTICS_DETAILS,
                                        state: { 
                                            diagnostics: this.props.diagnostics
                                        }
                                    }}
                                    
                                >
                                    <span className="ml-2 fontFamily">Back to Diagnostics</span>
                                </Link>
                            </div>
                            <div className="">
                                <h6 className="mb-1 w-100">Root Cause Analysis</h6>
                                <p className="mb-0">Summary of analysis and observations of the chosen Cloud resources</p>
                            </div>
                        </div>
                        <div className="">
                            <p className="mb-1">
                                {this.props.troubleshootDetails && this.props.troubleshootDetails.length && this.props.troubleshootDetails[0].provider ? this.props.troubleshootDetails[0].provider.toUpperCase() : null}
                                {this.props.troubleshootDetails && this.props.troubleshootDetails.length && this.props.troubleshootDetails[0].account_id ? (" : ")+ getAccountNameFromId(this.props.troubleshootDetails[0].account_id, this.props.accounts) : null}
                                {this.props.troubleshootDetails && this.props.troubleshootDetails.length && this.props.troubleshootDetails[0].region ?  (" : ")+this.props.troubleshootDetails[0].region : null}
                            </p>
                            <small className="mb-0 align-self-center text-info d-flex justify-content-end">{this.props.troubleshootDetails && this.props.troubleshootDetails.length ? (this.props.troubleshootDetails[0].start_time && this.props.troubleshootDetails[0].end_time ? momentConvertionUtcToLocalTime(this.props.troubleshootDetails[0].start_time, "DD MMM HH:mm") +" - "+momentConvertionUtcToLocalTime(this.props.troubleshootDetails[0].end_time, "DD MMM HH:mm") : null) : null}</small>
                        </div>
                        {/* <div className="">
                            <p className="mb-1">
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.provider ? this.state.diagnosticDetails.provider.toUpperCase() : null}
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.account_id ? (" : ")+ getAccountNameFromId(this.state.diagnosticDetails.account_id, this.props.accounts) : null}
                                {this.state.diagnosticDetails && this.state.diagnosticDetails.region ?  (" : ")+this.state.diagnosticDetails.region : null}
                            </p>
                            <small className="mb-0 align-self-center text-info">{this.state.diagnosticDetails ? (this.state.diagnosticDetails.start_time && this.state.diagnosticDetails.end_time ? momentConvertionUtcToLocalTime(this.state.diagnosticDetails.start_time, "DD MMM HH:mm") +" - "+momentConvertionUtcToLocalTime(this.state.diagnosticDetails.end_time, "DD MMM HH:mm") : null) : null}</small>
                        </div> */}
                    </div>
                </div>
                <div className="col-md-12 px-3">
                    <div className={`bg-dark3 p-2 overflowYAuto`} style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
                        {this.props.troubleshootDetails && this.props.troubleshootDetails.length ?
                            this.props.troubleshootDetails.map((item, index) => {
                                return(
                                    // border-gray3
                                    <div className="rounded border-info px-3 py-2 boxBoxHeadingDesign mt-5">
                                        <div className='d-flex justify-content-between'>
                                            <div className="header w-90 d-flex justify-content-between">
                                                <div className='w-80'>
                                                    <p className='mb-1'>{item.rca_type}</p>
                                                    <p className='mb-1 text-gray5'>{item.rca_description}</p>
                                                    <p className='mb-1'>Resources {item.asset_name +" : "+ item.service_name} probability {item.probability ? parseFloat(item.probability).toFixed(2) : 0} </p>
                                                </div>
                                                <div className='w-10 d-flex justify-content-end'>
                                                    <CircularProgressbar
                                                        value={70}
                                                        text={"70%"}
                                                        counterClockwise={false}
                                                        styles={{trail: {stroke: "#353C4C",strokeWidth:"8"},path: {stroke: "#039BE5",strokeWidth:"6"},text: {fill: "#C1C1C1",fontSize: "25px"}}}
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-end align-self-start'>
                                                <span className={`ml-4 fa fa-angle-${this.state["rca_block_"+index] ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ ["rca_block_"+index]: !this.state["rca_block_"+index] })}></span>
                                            </div>
                                        </div>
                                        <div className={`mt-3 ${this.state["rca_block_"+index] ? '' : "displayNone"}`}>
                                            <div className="accordion" id="accordionExample">
                                                {item.checks && item.checks.length ?
                                                    _.orderBy(item.checks, ['flag'], ['desc']).map((row, rowIndex) => {
                                                        return(
                                                            <div className={`card bg-dark border-0 mb-2 overflow-unset`}>
                                                                <div id={'heading'} onClick={() => this.onExpandChecks(item, index, row, rowIndex) }>
                                                                    <div className="px-3 py-2 mb-0 d-flex justify-content-between">
                                                                        <p className="mb-0 cursorPointer text-white" onClick={() => this.setState({ show2: !this.stateshow2 })}><span className={`far ${this.state["rca_checks_"+rowIndex] ? "fa-minus-circle" : "fa-plus-circle"} mr-2`} ></span>{row.label} 
                                                                            {/* <span className="ml-1 text-primary-color">({row.tag})</span> */}
                                                                        </p>
                                                                        {/* <span className={`ml-4 fa fa-angle-${this.state["rca_checks_"+rowIndex] ? "up" : "down"} f18 text-primary-color cursorPointer align-self-center`} onClick={() => this.setState({ ["rca_checks_"+rowIndex]: !this.state["rca_checks_"+rowIndex] })}></span> */}
                                                                        <span className={`${row.flag ? "text-purplishRed" : "text-success"} cursorPointer`}>{row.flag ? "Possible root cause detected." : "No causal observations detected."} </span>
                                                                    </div>
                                                                </div>
                                                                <div id={'collapse'} className={`collapse ${this.state["rca_checks_"+rowIndex] ? 'show' : ""}`}>
                                                                    <div className="card-body">
                                                                        {row.data && row.data.length ?
                                                                            row.data.map((trb, trbIndex) => {
                                                                                return(
                                                                                    <div className={`row mt-1 ${this.state.selectedRcaTrouble === "selected_"+index+"_"+rowIndex+"_"+trbIndex ? "bg-muted disabled" : "cursorPointer"}`} onClick={() => this.onChangeTroubleshootEvent(item, row, trb, index, rowIndex, trbIndex, "selected_"+index+"_"+rowIndex+"_"+trbIndex, "", "")}>
                                                                                        {row.tag === "Traffic" ?
                                                                                            <div className="col-12 py-2">{/* border-left */}
                                                                                                <p className="mb-0">
                                                                                                    {trb.asset_name ?
                                                                                                        <span className="mb-0 mr-2 f12 text-white font-weight-bold">{trb.asset_name}</span>
                                                                                                    : null}
                                                                                                    <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                                                    {trb.account_id ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+trb.account_id}</span>
                                                                                                    : null}
                                                                                                    {trb.region ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+trb.region}</span>
                                                                                                    : null}
                                                                                                    {trb.resource_type ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(trb.resource_type) ? trb.resource_type.join(', ') : trb.resource_type)}</span>
                                                                                                    : null}
                                                                                                    {trb.service_name ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+trb.service_name}</span>
                                                                                                    : null}

                                                                                                    <span className="mr-1 text-white">Detected a traffic surge in the</span>
                                                                                                    <span className="mr-1 text-white">{(trb.metric_name ? trb.metric_name : "")} metric, </span> 
                                                                                                    {/* <span className="mr-1 text-white">{(trb.asset_name ? trb.asset_name : "")}</span> */}
                                                                                                    <span className="mr-1 text-white">following a Transient </span>
                                                                                                    <span className="mr-1 text-white">{(trb.pattern ? trb.pattern : "")} pattern and having a </span>
                                                                                                    <span className={`mr-1 badge ${trb.severity ? "risk-badge-"+(trb.severity ? trb.severity.toLowerCase() : "") : ""}`}>{(trb.severity ? trb.severity : "")}</span>
                                                                                                    <span className="mr-1 text-white">severity</span>
                                                                                                </p>
                                                                                            </div>
                                                                                        : 
                                                                                            <div className="col-12 py-2">{/* border-left */}
                                                                                                <div className="d-flex">
                                                                                                    <div className='w-100'>
                                                                                                        {trb.severity ?
                                                                                                            <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${trb.severity ? trb.severity.toLowerCase() : 'critical'}`}>
                                                                                                                {trb.severity ? 
                                                                                                                    (trb.severity.toLowerCase() === 'critical' ? 'C' : trb.severity.toLowerCase() === 'high' ? 'H' : trb.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                                                                : 'U'}
                                                                                                            </span>
                                                                                                        : null}
                                                                                                        <p className="mb-0">
                                                                                                            {trb.event_time ?
                                                                                                                <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{trb.event_time ? momentConvertionUtcToLocalTime(trb.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                                                            : null}
                                                                                                            {trb.asset_name ?
                                                                                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold">{trb.asset_name}</span>
                                                                                                            : null}
                                                                                                            <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                                                            {trb.account_id ?
                                                                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+trb.account_id}</span>
                                                                                                            : null}
                                                                                                            {trb.region ?
                                                                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+trb.region}</span>
                                                                                                            : null}
                                                                                                            {trb.resource_type ?
                                                                                                                <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(trb.resource_type) ? trb.resource_type.join(', ') : trb.resource_type)}</span>
                                                                                                            : null}
                                                                                                            {trb.service_name ?
                                                                                                                <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+trb.service_name}</span>
                                                                                                            : null}
                                                                                                            {trb.description ?
                                                                                                                <span className="mb-0 ml-1 f12 text-white font-weight-bold">{trb.description}</span>
                                                                                                            : null}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    {/* <span className={`far fa-exclamation-triangle ${trb.flag !== "undefined" ? (trb.flag ? "text-purplishRed" : "text-success") : row.flag ? "text-purplishRed" : "text-success"} ml-2 align-self-center`}></span> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
RcaLeftSection.propTypes = {
    setDiagnosticsPropsDetails: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log("state", state)
	return {
        accounts: state.filters.accounts,
        regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions,
        troubleshootDetails: state.aiops.diagnosticsTroubleshootDetails ? state.aiops.diagnosticsTroubleshootDetails : {},
    }
}

export default connect(mapStateToProps, {
	// listAllAccounts,
	// listAllRegions,
    setDiagnosticsPropsDetails,
})(withRouter(RcaLeftSection))