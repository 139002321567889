/*************************************************
 * Tvastar
 * @exports
 * @file EditConfirmation.js
 * @author Rajasekar // on 14/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setEditRuleDetails } from '../../actions/governance/governanceAction'
import { getRegionName, stringLength, getAccountNameFromId, momentDateTime } from '../../utils/utility'
import { UncontrolledTooltip, Spinner} from 'reactstrap'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { URL_PATH } from '../../config/urlPath'

import { listAllRegions, listAllAccounts } from '../../actions/commonAction'


class EditConfirmation extends Component {
	constructor(props) {
		super(props)
		this.state = {
			allowedVolumeType: {},
			accounts: [],
			regions: [],
			decisionArray: [],
			finishBtn: 'Finish'
		}
	}
	
	componentDidMount = () => {
		let allowedVolumeType = {}
		if(this.props.editRuleDetails.category === 'Service-BlockStore') {
			let volumeSizeArray = []
			let piopsConfigurationArray = []
			this.props.editRuleDetails.condition.allowedVolumeType.forEach((vType, index) => {
				let volumeDataRow = {}
				volumeDataRow.maxSizePerVolume = vType.maxSizePerVolume
				volumeDataRow.maxSizePerVolumeUnit = vType.maxSizePerVolumeUnit
				volumeDataRow.totalSizeVolumes = vType.totalSizeVolumes
				volumeDataRow.totalSizeVolumesUnit = vType.totalSizeVolumesUnit
				volumeDataRow.totalVolumes = vType.totalVolumes
				volumeDataRow.volumeName = vType.volumeName
				volumeDataRow.volumeType = vType.volumeType
				volumeSizeArray.push(volumeDataRow)
				
				if(vType.volumeName === 'Provisioned IOPS SSD (io1)' || vType.volumeName === 'Provisioned IOPS SSD (io2)' ) {	
					let piopsDataRow = {}
					piopsDataRow.volumeName = vType.volumeName
					piopsDataRow.totalPIOPS = vType.totalPIOPS
					piopsDataRow.maxPIOPSPerVolume = vType.maxPIOPSPerVolume
					piopsConfigurationArray.push(piopsDataRow)
				}			
			})
			allowedVolumeType['Volume Size'] = volumeSizeArray
			if(piopsConfigurationArray.length) {
				allowedVolumeType['PIOPS Configuration'] = piopsConfigurationArray
			}
			this.setState({ allowedVolumeType })
		} else if(this.props.editRuleDetails.category === 'Service-Sizing' && this.props.editRuleDetails.resource_type === 'EC2') {
			let decisionArray = []
			this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedInstanceFamily.forEach(decision => {
				let decisionRow = {}
				decisionRow.node_type = decision.node_type
				let nodes = _.chain(decision.instance_type).groupBy('instanceFamily').map((value, key) => ({ label: key, sub: value })).value()
				let parentArray = []
				nodes.forEach(item => {
					let parentRow = {}
					parentRow.family = item.label
					parentRow.series = _.chain(item.sub).groupBy('instanceSeries').map((value, key) => ({ label: key, types: value })).value()
					parentArray.push(parentRow)
				})
				decisionRow.parent = parentArray

				decisionArray.push(decisionRow)
				// array = parentArray
				// previewRow.value = array
				// previewArray.push(previewRow)
			})

			this.setState({ decisionArray })
		}

		let params = {}
		params.provider = this.props.editRuleDetails.provider.toLowerCase()

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions
			this.setState({ regions: providerRegions })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}

		if(this.props.accounts && this.props.accounts.length) {
			this.setState({ accounts: this.props.accounts })
		} else {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts })
				} else {
					this.setState({ accounts: [] })
				}
			})
		}
	}

	getInstanceCount = (series) => {
		let count = 0
		this.state.decisionArray.forEach(item => {
			item.parent.forEach(instance => {
				instance.series.forEach(srs => {
					if(srs.label === series) {
						srs.types.forEach((typ, typIndex) => {
							count += parseInt(typ.count === '' ? 0 : typ.count)
						})
					}
				})
			})
		})
		return count
	}

	render() {
		return (
			<div>				
				<h5>Confirmation</h5>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					pariatur.
				</p>
				<div className="p-3 bg-dark rounded">
					<h6 className="text-info">Description</h6>
					<div className="p-3 bg-dark3 rounded mb-3">
						<div className="row">
							<div className="col-md-3">
								<label className="small mb-1 d-block">Provider</label>
								<h6 className="text-white">{this.props.editRuleDetails.provider && this.props.editRuleDetails.provider !== ''  ? this.props.editRuleDetails.provider.toUpperCase() : ''}</h6>
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Category</label>
								<h6 className="text-white">{this.props.editRuleDetails.category}</h6>
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Service</label>
								<h6 className="text-white">{this.props.editRuleDetails.resource_type}</h6>
							</div>						
							<div className="col-md-3">
								<label className="small mb-1 d-block">Base Rule ID</label>
								<h6 className="text-white">{this.props.editRuleDetails.base_rule_id}</h6>
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Base Rule Name</label>
								<h6 className="text-white">{this.props.editRuleDetails.base_rule_name}</h6>
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Priority</label>
								<label className="badge badge-danger text-white">{this.props.editRuleDetails.priority}</label>
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Compliance</label>
								{this.props.editRuleDetails.complianceId ?				
									this.props.editRuleDetails.complianceId.map(comp => {
										return (
											<label className="badge badge-outline-light mr-1">{comp}</label>
										)
									})
								: null
								}
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Classification</label>
								<h6 className="text-white">{this.props.editRuleDetails.classification}</h6>
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Type</label>
								{this.props.editRuleDetails.governance_type ?				
									this.props.editRuleDetails.governance_type.map(type => {
										return (
											<label className="badge badge-outline-light mr-1">{type}</label>
										)
									})
								: null
								}
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Category</label>
								<h6 className="text-white">{this.props.editRuleDetails.governance_category}</h6>
							</div>
							<div className="col-md-3">
								<label className="small mb-1 d-block">Rule Name</label>
								<h6 className="text-white">{this.props.editRuleDetails.rule_name}</h6>
							</div>					
							{this.props.editRuleDetails.version ? 
								<div className="col-md-3">
									<p className="mb-1">Current Version</p>
									<h6 className="display-6 text-white">{this.props.editRuleDetails.version}</h6>
								</div>
							: null
							}
							<div className="col-md-6">
								<label className="small mb-1 d-block">Description</label>
								<h6>{this.props.editRuleDetails.description}</h6>
							</div>
							<div className="col-md-6">
								<label className="small mb-1 d-block">Rule Id</label>
								<h6 className="text-white">{this.props.editRuleDetails.rule_id}</h6>
							</div>
							{this.props.editRuleDetails.created_at && this.props.editRuleDetails.created_by ? 
								<React.Fragment>
									<div className="col-md-3">
										<label className="small mb-1 d-block">Created Date</label>
										<h6 className="text-white">{momentDateTime(this.props.editRuleDetails.created_at)}</h6>
									</div>
									<div className="col-md-3">
										<label className="small mb-1 d-block">Created By</label>
										<h6 className="text-white">{this.props.editRuleDetails.created_by}</h6>
									</div>
									{this.props.editRuleDetails.last_modified && this.props.editRuleDetails.last_modified !== '' ?
										<React.Fragment>
											<div className="col-md-3">
												<label className="small mb-1 d-block">Modified Date</label>
												<h6 className="text-white">{momentDateTime(this.props.editRuleDetails.last_modified)}</h6>
											</div>
											<div className="col-md-3">
												<label className="small mb-1 d-block">Modified By</label>
												<h6 className="text-white">{this.props.editRuleDetails.modified_by}</h6>
											</div>
										</React.Fragment>
									: null
									}
								</React.Fragment>
							: null
							}
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-sm-12">
						<div className="bg-dark p-3 rounded">
							<h6 className="text-info">Resource</h6>
							{this.props.editRuleDetails.resources && this.props.editRuleDetails.resources.length ?
								this.props.editRuleDetails.resources.map((item, index) => {
									return (
										<div className="p-3 bg-dark3 rounded mb-3">
											<div className={`row`}>
												<div className="col-sm-3">
													<label className="small mb-1 d-block">Account</label>
													<div className="d-flex">
														{item.account.map((acc, i) => {
															return (
																<small className="badge badge-outline-light mb-1 mr-1">{getAccountNameFromId(acc, this.state.accounts) !== '-' ? getAccountNameFromId(acc, this.state.accounts) : acc}</small>
															)
														})}
													</div>
												</div>
												<div className="col-sm-4">
													<label className="small mb-1 d-block"><i className={`far fa-location mr-1 align-self-center`}></i>Region</label>
													{item.region && item.region.map((reg, i) => {
														return(
														<React.Fragment>
															
															<small className={`badge badge-outline-light mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</small>													
														</React.Fragment>
														)
													})}
												</div>
												<div className="col-sm-5">
													<label className="small mb-1 d-block"><i className={`far fa-tag mr-1 align-self-center`}></i>Tags</label>
													{item.tags && item.tags.length ? 
														item.tags.map((tag, i) => {
															return (
																<small key={i} className="badge badge-outline-light mr-1 mb-1">{tag.key +' : '+tag.value}</small>
															)
														})
													:
														<label className="badge badge-outline-light ml-1">All</label>
													}
												</div>
											</div>
										</div>
									)
								})
							: null }
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-sm-12">
						<div className="bg-dark p-3 rounded">
							<h6 className="text-info">Condition</h6>
							<div className="row">
								<div className="col-sm-12">
									{this.props.editRuleDetails.condition && Object.entries(this.props.editRuleDetails.condition).length ?
										this.props.editRuleDetails.category === 'Service-BlockStore' ?
											Object.entries(this.state.allowedVolumeType).map(([key,value],index) => {
												if(key === 'Volume Size') {
													return(
														<React.Fragment>
														<label className="small text-info"><b>{key}</b></label>
														<div className="p-3 bg-dark3 rounded mb-3">
															<div className="table-responsive rounded">
																<table className="table table-sm text-white">
																	<thead>
																		<tr>
																			<th>Volume</th>
																			<th>Total volume</th>
																			<th>Total Size of volume</th>
																			<th>Max Size per volume</th>
																		</tr>
																	</thead>
																	<tbody>
																		{value.map(vType => {
																			return (
																			<tr>
																				<td>{vType.volumeName}</td>
																				<td>{vType.totalVolumes}</td>
																				<td>{vType.totalSizeVolumes+vType.totalSizeVolumesUnit}</td>
																				<td>{vType.maxSizePerVolume+vType.maxSizePerVolumeUnit}</td>
																			</tr>
																			)
																		})}
																	</tbody>
																</table>
															</div>
														</div>
														</React.Fragment>
													)
												} else {
													return(
														<React.Fragment>
														<label className="small text-info"><b>{key}</b></label>
														<div className="p-3 bg-dark3 rounded mb-3">
															<div className="table-responsive rounded">
																<table className="table table-sm text-white">
																	{/* <thead className="bg-muted"> */}
																	<thead>
																		<tr>
																			<th>Volume</th>
																			<th>Total PIOPS</th>
																			<th>Max PIOPS per volume</th>
																			<th style={{'width':'25%'}}></th>
																		</tr>
																	</thead>
																	<tbody>
																		{value.map(vType => {
																			return (
																			<tr>
																				<td>{vType.volumeName}</td>
																				<td>{vType.totalPIOPS}</td>
																				<td>{vType.maxPIOPSPerVolume}</td>
																				<td>&nbsp;</td>
																			</tr>
																			)
																		})}
																	</tbody>
																</table>
															</div>
														</div>
														</React.Fragment>
													)
												}	 
											})
										: this.props.editRuleDetails.category === 'Service-Containers' ?
											this.props.editRuleDetails.condition.allowedContainers && this.props.editRuleDetails.condition.allowedContainers.length ?
												this.props.editRuleDetails.condition.allowedContainers.map((item, index) => {
													return(
													<div className="p-3 bg-dark3 rounded mb-3">
														<div className="row">
															<div className="col-lg-2 col-sm-3 align-self-center">
																<label className="small mb-1 d-block">Account</label>
																{item.account.map((acc, i) => {
																	return(
																		<small className={`badge badge-outline-light mb-1 mr-1`}>{getAccountNameFromId(acc,this.state.accounts) !== '-' ? getAccountNameFromId(acc,this.state.accounts) : acc}</small>
																	)
																})}
															</div>
															<div className="col-lg-2 col-sm-3 align-self-center">
																<label className="small mb-1 d-block"><i className={`far fa-location mr-1 align-self-center`}></i>Region</label>
																{item.region && item.region.length ? item.region.map((reg, i) => {
																	return(
																		<small className={`badge badge-outline-light mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</small>
																	)
																})
																:
																	<small className={`badge badge-outline-light mb-1 mr-1`}>All</small>
																}
															</div>
															<div className="col-lg-2 col-sm-3 align-self-center">
																<label className="small mb-1 d-block">Repository Image</label>
																{item.repository && item.repository.length ?
																	item.repository.map((rep, i) => {
																		return(
																			<small className={`badge badge-outline-light mb-1 mr-1`}>{rep}</small>
																		)
																	})
																:
																	<small className={`badge badge-outline-light mb-1 mr-1`}>All</small>
																}
															</div>
															<div className="col-lg-2 col-sm-3 align-self-center">
																<label className="small mb-1 d-block">Duration</label>
																<p className="mb-0"><i className="far fa-clock mr-1"></i>{item.duration}</p>
															</div>
															<div className="col-lg-2 col-sm-3 align-self-center">
																<p className="small m-0 p-0">Latest Image Version</p>
																<small className={`mb-1 badge ${item.latestImageVersion ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.latestImageVersion ? 'Yes' : 'No'}</small>
																<p className="small mb-0">Scanned Containers</p>
																<small className={`mb-1 badge ${item.onlyScannedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyScannedContainers ? 'Yes' : 'No'}</small>
															</div>
															<div className="col-lg-2 col-sm-3 align-self-center">
																<p className="small m-0 p-0">Encrypted Containers</p>
																<small className={`badge ${item.onlyEncryptedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyEncryptedContainers ? 'Yes' : 'No'}</small>
																<p className="small m-0 p-0">KMS Encrypted Containers</p>
																<small className={`mb-1 badge ${item.onlyKMSEncryptedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyKMSEncryptedContainers ? 'Yes' : 'No'}</small>
															</div>
														</div>
													</div>
													)
												})
											: null
										: this.props.editRuleDetails.category === 'Service-Logs' ?		
											<div className="p-3 bg-dark3 rounded mb-3">					
												<div className="table table-responsive mb-0">
													<table className="table table-borderless">
														<thead>
															<tr className="text-info font-weight-bold">
																<th style={{"width": "200px;"}}>Log Type</th>
																<th>Condition</th>
																<th>Retention Period</th>
																<th>Encryption</th>
																<th>Log Limit</th>
																<th className="text-nowrap">Log in Cross Account</th>
															</tr>
														</thead>
														<tbody className="align-middle text-white">
															{Object.entries(this.props.editRuleDetails.condition.log_list).map(([key,value],index) => {
																return(
																	value.allowed ?
																		<tr>
																			<td>{key}</td>
																			<td>{value.destination}</td>
																			<td>{value.retentionPeriod}</td>
																			<td>{value.encryption}</td>
																			<td>{value.logLimit+value.logLimitType}</td>
																			<td>{value.crossAccount ? 'Yes' : 'No'}</td>
																		</tr>
																	: null
																)
															})}
														</tbody>
													</table>
												</div>
											</div>
										: this.props.editRuleDetails.category === 'Service-Sizing' && this.props.editRuleDetails.resource_type === 'EC2' ?
											this.state.decisionArray.length ? this.state.decisionArray.map((item, index) => {
												return (
													item.parent.map(instance => {
														return(
														<React.Fragment>
															<div class="d-flex my-3">
																<h5 class="mb-0 align-self-center text-info">{instance.family}</h5>
															</div>
															{instance.series.map((srs, srsIndex) => {
																return(
																	<div class="table-responsive rounded border-black3 mb-3">
																		<table class="table table-sm mb-0 text-white">
																			<thead class="bg-dark3">
																				<tr>
																					<th class="text-nowrap">Instance Type</th>
																					<th class="text-nowrap">Max Count</th>
																					<th>Pricing</th>
																					<th>
																						Infra Specification
																						<span class='badge bg-info ml-2 mr-2'>{instance.family +': '+ srs.label}</span>
																						<span className='badge badge-light text-black'>Total Instance Count: {this.getInstanceCount(srs.label)} 
																							{/* <i className="fa fa-times-circle"></i> */}
																						</span>
																					</th>
																					<th className="w-15">Description</th>
																				</tr>
																			</thead>
																			<tbody className="bg-dark">
																				{srs.types.map((typ, typIndex) => {
																					return(
																					<tr class="border-bottom-black3">
																						<td>
																							<p class="mb-0"><b>{typ.type}</b></p>
																						</td>
																						<td>
																							<p class="mb-0"><b>{typ.count !== '' && typ.count > 0 ? typ.count : 'No Limit'}</b></p>
																						</td>
																						<td>
																							<h6 className="mb-0">$100.20</h6>
																						</td>
																						<td>
																							<div className="container-fluid pl-md-0">
																								<div className="row">
																									{typ.vCPU ?
																										<div className="col-md-3"><p className="mb-1"><b>VCPU:</b> {typ.vCPU}</p></div>
																									: null}
																									{typ.memory ?
																										<div className="col-md-2"><p className="mb-1"><b>Memory:</b> {typ.memory}</p></div>
																									: null}
																									{typ.ebsOptimized ?
																										<div className="col-md-2"><p className="mb-1"><b>EBS Optimized:</b> {typ.ebsOptimized === 'Y' ? 'Yes' : 'No'}</p></div>
																									: null}
																									{typ.storageType ?
																										<div className="col-md-3"><p className="mb-1"><b>Storage Type:</b> {typ.storageType}</p></div>
																									: null}
																									{typ.storageDetails ?
																										<div className="col-md-3"><p className="mb-1"><b>Storage Details:</b> {typ.storageDetails}</p></div>
																									: null}

																									{typ.cpuProcessor ?
																										<div className="col-md-5"><p className="mb-1"><b>CPU Processor:</b> {typ.cpuProcessor +' '+typ.architecture}</p></div>
																									: null}

																									{typ.networkingPerformance ?
																										<div className="col-md-4"><p className="mb-1"><b>NW Performance:</b> {typ.networkingPerformance+ (typ.enhancedNetworking === 'Y' ? '(Enhanced Networking)' : '')}</p></div>
																									: null}
																								</div>
																							</div>
																						</td>
																						<td>
																							<p className="mb-0" id={`description-${index}-${srsIndex}-${typIndex}`}><b>{typ.description && typ.description !== "" ? stringLength(typ.description, 100) : ""}</b></p>
																							<UncontrolledTooltip placement='top' target={`description-${index}-${srsIndex}-${typIndex}`}>{typ.description}</UncontrolledTooltip>		
																						</td>
																					</tr>
																					)
																				})}
																			</tbody>
																		</table>
																	</div>
																)
															})}
														</React.Fragment>
														)
													})
												)
												})
											: null
										: this.props.editRuleDetails.category === 'Service-Images' ?
											this.props.editRuleDetails.condition && Object.entries(this.props.editRuleDetails.condition).length ?
											<React.Fragment>
											<div className="d-flex align-self-center mt-2 mb-2">
												<div className="d-flex mr-2">
													<p className="small m-0 p-0 mr-2 align-self-center">Allowed Community Images</p>
													<small className={`badge ${this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedCommunityImages ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedCommunityImages ? 'Yes' : 'No'}</small>
												</div>
												<div className="d-flex">
													<p className="small m-0 p-0 mr-2 align-self-center">Allowed Marketplace Images</p>
													<small className={`badge ${this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedMarketplaceImages ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.generalSettings && this.props.editRuleDetails.condition.generalSettings.allowedMarketplaceImages ? 'Yes' : 'No'}</small>
												</div>
											</div>
											{this.props.editRuleDetails.condition.allowedImages && this.props.editRuleDetails.condition.allowedImages.map(item => {
												return(
												<div className="p-3 bg-dark3 rounded mb-3">
													<div className="row">
														<div className="col-lg-2 col-sm-3 align-self-center">
															<label className="small mb-1 d-block">Account</label>
															{item.account.split(":").map((acc, i) => {
																return(
																	<small className={`badge badge-outline-light mb-1 mr-1`}>{getAccountNameFromId(acc,this.state.accounts) !== '-' ? getAccountNameFromId(acc,this.state.accounts) : acc}</small>
																)
															})}
															<p class="mb-2"><i class="far fa-clock"></i> {item.duration}</p>
														</div>
														<div className="col-lg-2 col-sm-3 align-self-center">
															<label className="small mb-1 d-block"><i className={`far fa-location mr-1 align-self-center`}></i>Region</label>
															{item.regions.map((reg, i) => {
																return(
																	<small className={`badge badge-outline-light mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</small>
																)
															})}
														</div>													
														<div className="col-lg-2 col-sm-3 align-self-center">
															<label className="small mb-1 d-block">Allowed Platforms</label>
															{item.allowedPlatforms && item.allowedPlatforms.length ?
																item.allowedPlatforms.map((rep, i) => {
																return(
																	<small className={`badge badge-outline-light mr-1 mb-1`}>{rep}</small>
																)
															})
															:
																<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
															}
														</div>
														<div className="col-lg-2 col-sm-3 align-self-center">
															<label className="small mb-1 d-block">Allowed AMI</label>
															{item.allowedAmi && item.allowedAmi.length ?
																item.allowedAmi.map((rep, i) => {
																return(
																	<small className={`badge badge-outline-light mr-1 mb-1`}>{rep}</small>
																)
															})
															:
																<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
															}
														</div>
														
														<div className="col-lg-2 col-sm-3 align-self-center">
															<label className="small mb-1 d-block">Allowed Image Architecture</label>
															{item.imageArchitecture && item.imageArchitecture.length ?
																item.imageArchitecture.map((rep, i) => {
																return(
																	<small className={`badge badge-outline-light mr-1 mb-1`}>{rep}</small>
																)
															})
															:
																<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
															}
														</div>
														
														<div className="col-lg-2 col-sm-3 align-self-center">
															<label className="small mb-1 d-block">Allowed Root Device Type</label>
															{item.allowedRootDevice && item.allowedRootDevice.length ?
																item.allowedRootDevice.map((rep, i) => {
																return(
																	<small className={`badge badge-outline-light mr-1 mb-1`}>{rep}</small>
																)
															})
															:
																<small className={`badge badge-outline-light mr-1 mb-1`}>All</small>
															}
														</div>
													</div>
												</div>
												)
											})}
											</React.Fragment>
											: null
										: this.props.editRuleDetails.category === 'Service-Engine' && this.props.editRuleDetails.resource_type === 'RDS' ?
											this.props.editRuleDetails.condition.allowedEngineDetails && this.props.editRuleDetails.condition.allowedEngineDetails.length ?
												<div className="p-3 bg-dark3 rounded mb-3">
													{this.props.editRuleDetails.condition.allowedEngineDetails.map((item, index) => {
														return(
															<div className={`p-1 ${this.props.editRuleDetails.condition.allowedEngineDetails.length > 1 ? 'border-bottom' : ''} mb-3`}>
																<div className="row">
																	<div className="col-lg-2 col-sm-3 align-self-center">
																		<label className="small d-block mb-0">Options</label>
																		<small className={`badge badge-outline-light mb-1 mr-1`}>{item.options}</small>
																	</div>
																	<div className="col-lg-2 col-sm-3 align-self-center">
																		<label className="small d-block mb-0">Allowed only latest Version</label>
																		<small className={`badge ${item.latestVersion ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.latestVersion ? 'Yes' : 'No'}</small>
																	</div>
																	<div className="col-lg-5 col-sm-3 align-self-center">
																		<label className="small d-block mb-0">DB Engine Version</label>
																		{item.optionType && item.optionType.length ? 
																			item.optionType.map((type, i) => {
																				return(
																					<small className={`badge badge-outline-light mb-1 mr-1`}>{type}</small>
																				)
																			})
																		:
																			<small className={`badge badge-outline-light mb-1 mr-1`}>{item.latestVersionDetail}</small>
																		}
																	</div>
																</div>
															</div>
														)
													})}
												</div>
											: null
										: this.props.editRuleDetails.category === 'Service-Availability' ?
											this.props.editRuleDetails.condition && Object.entries(this.props.editRuleDetails.condition).length ?
												<div className="p-3 bg-dark3 rounded mb-3">
													<div className="row">
														<div className="serviceAvailWidth1 align-self-center">
															<p className="small m-0 p-0">Allow Replication</p>
															<small className={`badge ${this.props.editRuleDetails.condition.allowReplication ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.allowReplication ? 'Yes' : 'No'}</small>
														</div>
														<div className="serviceAvailWidth1 align-self-center">
															<p className="small m-0 p-0">Allowed Standby</p>
															<small className={`badge ${this.props.editRuleDetails.condition.allowStandby ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.allowStandby ? 'Yes' : 'No'}</small>
														</div>
														
														<div className="serviceAvailWidth2 align-self-center">
															<p className="small m-0 p-0">Encrypt Replicas at rest</p>
															<small className={`badge ${this.props.editRuleDetails.condition.encryptReplicasAtRest ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.encryptReplicasAtRest ? 'Yes' : 'No'}</small>
														</div>

														<div className="serviceAvailWidth1 align-self-center">
															<p className="small m-0 p-0">Allow Cross Region Replication</p>
															<small className={`badge ${this.props.editRuleDetails.condition.allowCrossRegionReplication ? 	'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.allowCrossRegionReplication ? 'Yes' : 'No'}</small>
														</div>
														<div className="ml-3 align-self-center">
															<p className="small m-0 p-0"><i className={`far fa-location mr-1 align-self-center`}></i>Region</p>														
															{this.props.editRuleDetails.condition.allowCrossRegionOption.length ? 
																this.props.editRuleDetails.condition.allowCrossRegionOption.map((reg, i) => {
																	return(
																		<small className={`badge badge-outline-light mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</small>
																	)
																})
															: 
																<small className={`badge badge-outline-light mb-1 mr-1`}>All</small>
															}
														</div>
													</div>
													<div className="row mt-2">
														<div className="serviceAvailWidth1 align-self-center">
															<p className="small m-0 p-0">Max. number of replicas allowed per Cluster</p>
															<p className="mb-0">{this.props.editRuleDetails.condition.maxReplicationPerCluster}</p>
														</div>
														<div className="serviceAvailWidth1 align-self-center">
															<p className="small m-0 p-0">Allow Cross Zone Replication</p>
															<small className={`badge ${this.props.editRuleDetails.condition.allowCrossZoneReplication ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.allowCrossZoneReplication ? 'Yes' : 'No'}</small>
														</div>
														<div className="serviceAvailWidth2 align-self-center">
															<p className="small m-0 p-0">Encrypt Replicas at transit</p>
															<small className={`badge ${this.props.editRuleDetails.condition.encryptReplicasAtTransit ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.encryptReplicasAtTransit ? 'Yes' : 'No'}</small>
														</div>
														<div className="serviceAvailWidth1 align-self-center">
															<p className="small m-0 p-0">Allow Cross Account</p>
															<small className={`badge ${this.props.editRuleDetails.condition.allowCrossAccountReplication ? 	'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.allowCrossAccountReplication ? 'Yes' : 'No'}</small>
														</div>
														<div className="ml-3 align-self-center">
															<p className="small m-0 p-0">Account</p>
															{this.props.editRuleDetails.condition.allowCrossAccountOption.length ? this.props.editRuleDetails.condition.allowCrossAccountOption.map((reg, i) => {
																return(
																	<small className={`badge badge-outline-light mb-1 mr-1`}>{getAccountNameFromId(reg, this.state.accounts)}</small>
																)
															})
															: 
																<small className={`badge badge-outline-light mb-1 mr-1`}>All</small>
															}
														</div>
													</div>
												</div>
											: null
										: this.props.editRuleDetails.category === 'Service-Encryption' ?
											this.props.editRuleDetails.condition && Object.entries(this.props.editRuleDetails.condition).length ?
												<div className="p-3 bg-dark3 rounded mb-3">
													<div className="row col-sm-12">
														<div className="align-self-center mr-4">
															<p className="small m-0 p-0">Enforce Encryption at Rest</p>
															<small className={`badge ${this.props.editRuleDetails.condition.encryptionAtRest ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.encryptionAtRest ? 'Yes' : 'No'}</small>
														</div>
														<div className="align-self-center mr-4">
															<p className="small m-0 p-0">Enforce Encryption at Transit</p>
															<small className={`badge ${this.props.editRuleDetails.condition.encryptionAtTransit ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.encryptionAtTransit ? 'Yes' : 'No'}</small>
														</div>
														<div className="align-self-center">
															<p className="small m-0 p-0">Encryption Options</p>
															<div className="d-flex">
																{this.props.editRuleDetails.condition.encryptOptions.length ? this.props.editRuleDetails.condition.encryptOptions.map((reg, i) => {
																	return(
																		<small className={`badge badge-outline-light mb-1 mr-1`}>{reg}</small>
																	)
																})
																: null
																}
															</div>
														</div>
													</div>
													<div className="row mt-3 col-sm-12">
														<label className="w-100 small"><b>KMS Settings</b></label>
														<div className="align-self-center mr-4">
															<p className="small m-0 p-0">Allow Cross account Access for Customer Managment KMS</p>
															<small className={`badge ${this.props.editRuleDetails.condition.crossAccountAccessforCusmtomerMgmtKMS ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.crossAccountAccessforCusmtomerMgmtKMS ? 'Yes' : 'No'}</small>
														</div>
														<div className="align-self-center mr-4">
															<p className="small m-0 p-0">Allow Cross account KMS for Encryption</p>
															<small className={`badge ${this.props.editRuleDetails.condition.crossAccountKMSEncryption ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.condition.crossAccountKMSEncryption ? 'Yes' : 'No'}</small>
														</div>
														<div className="align-self-center">
															<p className="small m-0 p-0">Max. Key Administrators in Customer Managed KMS</p>
															<p className="mb-0">{this.props.editRuleDetails.condition.customerManagedKMS ? this.props.editRuleDetails.condition.customerManagedKMS : " "}</p>
														</div>
													</div>
													<div className="row mt-3 col-sm-12">
														<label className="w-100 small"><b>Advanced Settings</b></label>
														<div className="align-self-center mr-4">
															<p className="small m-0 p-0">Allowed Key Types</p>
															{this.props.editRuleDetails.condition.keyTypeOptions.length ? this.props.editRuleDetails.condition.keyTypeOptions.map((reg, i) => {
																return(
																	<small className={`badge badge-outline-light mb-1 mr-1`}>{reg}</small>
																)
															})
															: null
															}
														</div>
														<div className="ml-4 align-self-center mr-4">
															<p className="small m-0 p-0">Allowed Key usage</p>
															{this.props.editRuleDetails.condition.keyUsageOptions.length ? this.props.editRuleDetails.condition.keyUsageOptions.map((reg, i) => {
																return(
																	<small className={`badge badge-outline-light mb-1 mr-1`}>{reg}</small>
																)
															})
															: null
															}
														</div>
														<div className="ml-4 align-self-center">
															<p className="small m-0 p-0">Key Spec</p>
															{this.props.editRuleDetails.condition.keySpecOptions.length ? this.props.editRuleDetails.condition.keySpecOptions.map((reg, i) => {
																return(
																	<small className={`badge badge-outline-light mb-1 mr-1`}>{reg}</small>
																)
															})
															: null
															}
														</div>
													</div>
												</div>
											: null
										: null
									: null}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-sm-12">
						<div className="bg-dark p-3 rounded">
							<h6 className="text-info">Action</h6>
							<div className="p-3 bg-dark3 rounded mb-3">
								<div className="row">
									<div className="col-sm-6">
										<p className="small mb-1 d-block">Send Alert Message</p>
										<small className={`badge ${this.props.editRuleDetails.alertAction ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.alertAction ? 'Yes' : 'No'}</small>
									</div>
									<div className="col-sm-6">
										<p className="small mb-1 d-block">Terminate the Instance</p>
										<small className={`badge ${this.props.editRuleDetails.alertAction && this.props.editRuleDetails.terminateInstance ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.alertAction && this.props.editRuleDetails.terminateInstance ? 'Yes' : 'No'}</small>
									</div>
									<div className={`col-sm-12 ${this.props.editRuleDetails.alertAction && this.props.editRuleDetails.alertAction.alert ? 'mt-2' :'displayNone'}`}>
										<label className="small mb-1 d-block">Alert Message </label>
										<h6 className="mb-0">{this.props.editRuleDetails.alertAction && this.props.editRuleDetails.alertAction.message}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-sm-12">
						<div className="bg-dark p-3 rounded">
							<h6 className="text-info">Notification</h6>
							<div className="p-3 bg-dark3 rounded mb-3">
								<div className="row">
									<div className="col-sm-3">
										<label className="small mb-1 d-block">Send notification to email</label>
										<small className={`badge ${this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.emailChecked ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.emailChecked ? 'Yes' : 'No'}</small>									
									</div>
									<div className="col-sm-3">
										<label className="small mb-1 d-block">Email ID</label>
										<h6 className="mb-0">{this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.email}</h6>
									</div>
									<div className="col-sm-3">
										<label className="small mb-1 d-block">Send notification to slack(channel)</label>
										<small className={`badge ${this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.slackChecked ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.slackChecked ? 'Yes' : 'No'}</small>
									</div>
									<div className="col-sm-3">
										<label className="small mb-1 d-block">Slack ID</label>
										<h6 className="mb-0">{this.props.editRuleDetails.notifications && this.props.editRuleDetails.notifications.slack}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='mt-2 pt-2 border-top'>
					<button className="btn btn-light mr-1" onClick={() => this.props.onClickBack()}>Back</button>
					<button className="btn btn-primary" onClick={() => this.props.onClickFinish()} disabled={this.props.disableFinish}>
						{this.props.finishBtn ? this.props.finishBtn : 'Finish'} {this.props.disableFinish ? <Spinner size='sm' color='light' className='ml-2' /> : null}
					</button>
					<Link className='float-right btn btn-light text-muted text-decoration-none ml-1' to={URL_PATH.GOVERNANCE_RULES} >
						Cancel
					</Link>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
EditConfirmation.propTypes = {
	listAllRegions: PropTypes.func,
	listAllAccounts: PropTypes.func,
	onClickFinish: PropTypes.func,
	onClickBack: PropTypes.func,
	setEditRuleDetails: PropTypes.func,
	editRuleDetails: PropTypes.object,
	disableFinish: PropTypes.bool,
	finishBtn: PropTypes.string
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('confirmation state',state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
		regions: state.filters.regions && state.filters.regions[0] ? state.filters.regions[0].provider_regions : [],
		accounts: state.filters.accounts,
	}
}
export default connect(mapStateToProps, { setEditRuleDetails, listAllRegions, listAllAccounts })(EditConfirmation)
