/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 17/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { AppWrapper } from '../../../common/AppWrapper'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, capitalizeAllLetter, unitTimeConvertionDetail, twoDateDiffrenceDayHoursMinutes, currentUTCtime } from '../../../../utils/utility'
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { URL_PATH } from '../../../../config/urlPath'

import { setAutomationPropsDetails } from '../../../../actions/commonAction'
import { getAutomationExecution, startAutomation } from '../../../../actions/AutomationAction'
import { listRemediation, startRemediation } from '../../../../actions/aiops/RemediationAction'

import { getEventInfo } from '../../../../actions/detectors/detectorsAction'

import ResizeableDarkThemeTable from '../../../designComponents/Table/ResizeableDarkThemeTable'

import ActionSection from './ActionSection'

class LandingPage extends Component {
    
    constructor(props) {

        super(props)
        this.sortAutoamtionDropdown = React.createRef()
        this.scrolltoDiv = React.createRef()
        this.sliderWrap = React.createRef()
        
        this.state = {
            showLoading: true,
            pageType: "Details",
            expandCheckDetails: true, 
            expandTagDetails: false,
            
            totalItemShowing: 0,
            showingLeftIcon: false,
            showingRightIcon: true,
			showingOffStart: 1,
            showingOff: 0,

            expandAssetDetails: true,
        }
    }

    componentDidMount = () => {
        if (this.props.location && this.props.location.state && Object.keys(this.props.location.state).length) {
            let remediationAction = this.props.location.state.remediationAction ? this.props.location.state.remediationAction : {}
            let assetDetails = this.props.location.state.assetDetails ? this.props.location.state.assetDetails[0] : {}
            this.setState({ 
                remediationAction,
                assetDetails
            },
               () => this.structureActionData(remediationAction)
            )
        } else {
            this.goBack()
        }
        this.hideLoading()
    }

    goBack = () => {
        this.props.history.push({
            pathname: URL_PATH.RESOURCES_LANDING_PAGE,
        })
    }

    hideLoading = () => {
        setTimeout(() => { this.setState({ showLoading: false }) }, 1000)
    }

    structureActionData = (ruleDetails) => {
        console.log("ruleDetails", ruleDetails)
        let actions = ruleDetails.actions ? ruleDetails.actions : []            
        let newAction = []
        let tabs = []
        actions.forEach((act, i) => {
            act.section = i+1
            tabs.push(act.section)
            newAction.push(act)
        })

        ruleDetails.actions = newAction

        this.props.setAutomationPropsDetails(ruleDetails)
        this.setState({
            //ruleDetails.actions.length, 
            latestTab: ruleDetails.actions.length,
            activeSection: 1,
            ["showAction_1"]: true,
            tabs,
            enablePlusAction: true,
            loadingAutomation: false
         })
    }

    scrollToSection = (activeSection) => {
        this.scrolltoDiv.current.scrollIntoView()
    }

    validateInput = () => {
        this.setState({ showLoading: true }, 
            () => this.startAutomation()
        )
    }

    startAutomation = () => {
        let propsData = this.props.automationPropsDetails
        // let params = propsData
        let params = {}
        params.actions = propsData.actions ? propsData.actions : []
        params.processor_rule_id = propsData.processor_rule_id ? propsData.processor_rule_id : ""
        params.trigger_type = "manual"
        params.source = "rersources"
        params.trigger_time = currentUTCtime()
        params.resource_type = this.state.assetDetails && this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : ""
        params.service_name = this.state.assetDetails && this.state.assetDetails.service_name ? this.state.assetDetails.service_name : ""
        params.source_id = this.state.assetDetails && this.state.assetDetails.asset_id ? this.state.assetDetails.asset_id : ""
        params.asset_name = this.state.assetDetails && this.state.assetDetails.resource_id ? this.state.assetDetails.resource_id : ""
        params.region = this.state.assetDetails && this.state.assetDetails.region ? this.state.assetDetails.region : ""
        // params.account_id = this.state.identifierDetail && this.state.identifierDetail.account_id ? this.state.identifierDetail.account_id : ""
        params.save_automation = propsData.save_automation ? true : false

        console.log("params", params)

        this.props.startAutomation(params, (promise, response) => {
            if(promise) {
                this.setState({ pageType: "Executions", showLoading: false, showDetailsLoading: true },
                    () => {
                        if(response && response.execution_id) {
                            this.getAutomationExecution(response.execution_id)
                        } else {
                            this.setState({ showLoading: false, automationdetails: {}, automaitonTaskDetails: [], showDetailsLoading: false })
                        }
                    }
                )
            } else {
                this.setState({ pageType: "Executions", showLoading: false, automationdetails: {}, automaitonTaskDetails: [], showDetailsLoading: false })
            }
        })
    }

    getAutomationExecution = (executionId) => {
        let params = {
            "execution_id": executionId
        }

        this.props.getAutomationExecution(params, (promise, response) => {
            if(promise) {
                let automationdetails = response.overall_status ? response.overall_status : {}
                let automaitonTaskDetails = response.task_status ? response.task_status : []
                
                this.setState({ automationdetails, automaitonTaskDetails, showDetailsLoading: false, showTaskLoading: false },
                    () => {
                        if(automationdetails.status && automationdetails.status.toLowerCase() !== "success" && automationdetails.status.toLowerCase() !== "failed") {
                            this.setState({ showTaskLoading: true })
                            setTimeout(() => { this.getAutomationExecution(executionId) }, 5000)
                        } 
                        // else {
                        //     if(this.state.identifierDetail.resolution_status_info && this.state.identifierDetail.resolution_status_info.status !== "resolved") {
                        //         this.getIdentifierResults()
                        //         this.getEventInfo()
                        //     }
                        // }
                    }    
                )
            } else {
                this.setState({ automationdetails: {}, automaitonTaskDetails: [], showDetailsLoading: false })
            }
        })
    }

	render() {
		return (
            <div className={`main-content bg-muted`}>
                <div className={`container-fluid overflow-auto flex-grow-1`}>
                    <div className={`loaderOverlay ${this.state.showLoading ? "" : "displayNone"}`}>
                        <div className="overlayEqualizerLoader">
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                            <div className="spinner-item"></div>
                        </div>
                    </div>
                    <div className="row h-100">
                        <div className={`border-right col-md-6 p-0 h-100 overflowYAuto`}>
                            <div className="title-section pb-3">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <h6 className="text-white m-0 f16 text-break align-self-center w-100">Remediation</h6>
                                        <p className="small m-0">Showing remediation options</p>
                                    </div>
                                    <button className="btn btn-sm bg-dark text-decoration-none text-white" onClick={() => this.goBack()} >Back</button>
                                </div>
                            </div>
                            <div className="col-12">
                                {this.state.assetDetails ?
                                    <div className="rounded bg-dark3 p-3 mt-2">
                                        <div className="accordion" id="accordionExample">
                                            <div className={`card bg-dark border-0 mb-2`}>
                                                <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                        <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                        {/* <div className="text-info">Showing asset details</div> */}
                                                    </div>
                                                </div>
                                                <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Name:</p>
                                                                <p className="mb-0 text-white">{this.state.assetDetails.asset_name ? this.state.assetDetails.asset_name : ""}</p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Id:</p>
                                                                <p className="mb-0 text-white">{this.state.assetDetails.asset_id ? this.state.assetDetails.asset_id : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Resource:</p>
                                                                <p className="mb-0 text-white">
                                                                    {this.state.assetDetails.provider ? this.state.assetDetails.provider.toUpperCase() : ""}
                                                                    {this.state.assetDetails.account_id ? (" : ")+this.state.assetDetails.account_id : ""}
                                                                    {this.state.assetDetails.region ? (" : ")+this.state.assetDetails.region : ""}
                                                                </p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Services:</p>
                                                                <p className="mb-0 text-white">
                                                                    {this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : ""}
                                                                    {this.state.assetDetails.service_name ? (" : ")+this.state.assetDetails.service_name : ""}
                                                                </p>
                                                            </div>
                                                        </div>                                                        
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">ARN:</p>
                                                                <p className="mb-0 text-white">{this.state.assetDetails.asset_arn ? this.state.assetDetails.asset_arn : ""}</p>
                                                            </div>
                                                        </div>
                                                        {this.state.assetDetails && this.state.assetDetails.category ? 
                                                            <div className="d-flex">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">Category:</p>
                                                                    <div className={`d-flex flex-wrap`}>
                                                                        {Array.isArray(this.state.assetDetails.category) ?
                                                                            this.state.assetDetails.category.map(item => {
                                                                                return (
                                                                                    <small className={`mr-1 mb-1 badge badge-light`}>{item}</small>
                                                                                )
                                                                            })
                                                                        : 
                                                                            <small className={`mr-1 mb-1 badge badge-light`}>{this.state.assetDetails.category}</small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : null}
                                <div className="container-fluid mt-2">
                                    <div className={`row ${!this.state.showLoading ? "" : 'disabled'}`}>
                                        <div className="d-flex flex-nowrap overflow-hidden" ref={this.sliderWrap}>
                                            {this.state.remediationAction ?
                                                <div className={`font-weight-bold mr-3 automationActionBox cursorPointer align-self-start bg-dark border-black6 text-white`}>
                                                    <div className="d-flex">
                                                        <span><i className={`mr-2 fa fa-check-circle text-info`}></i></span>
                                                        <span className="align-self-center">{this.state.remediationAction.name}</span>
                                                    </div>
                                                    
                                                    <div className="d-flex">
                                                        <span className={`ml-3 pl-1 align-self-center small text-primary-color`}>{this.state.remediationAction.description ? "    "+this.state.remediationAction.description : <span>&nbsp;</span>}</span>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded p-0 zapDesign zapInputDark">
                                    {this.state.tabs && this.state.tabs.length ? 
                                        this.state.tabs.map((item, index) => {
                                            return(
                                                <ActionSection
                                                    activeSection={item}
                                                    tabCount={index+1}
                                                    totalActions={this.state.tabs.length}
                                                    showAction={this.state["showAction_"+item]}
                                                    latestTab={this.state.latestTab}
                                                    selectAction={(selectedActionTabs) => this.setState({ selectedActionTabs })}
                                                    selectedActionTabs={this.state.selectedActionTabs ? this.state.selectedActionTabs : []}
                                                    scrollToSection={(section) => this.scrollToSection(section)}
                                                    createAutomation={() => this.validateInput()}
                                                    assetDetails={this.state.assetDetails}
                                                    goBack={() => this.goBack()}
                                                />
                                            )
                                        })
                                    : null}  
                                </div>
                                <div className={`mt-5 pt-5`} ref={this.scrolltoDiv}></div>
                            </div>
                        </div>
                        <div className="col-md-6 h-100 overflowYAuto">
                            <ul className={`row nav nav-tabs border-bottom bg-muted shadow-none rounded justify-content-between mb-0`} id="myTab" role="tablist">
                                <span className={`d-flex`}>
                                    <li className="nav-item">
                                        <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Details' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Details', menuDisabled: true })}>
                                            Details
                                        </span>
                                    </li>
                                    <li className="nav-item">
                                        <span className={`nav-link text-white cursorPointer ${this.state.pageType === 'Executions' ? ' active' : ""}`} onClick={() => this.setState({ pageType: 'Executions', menuDisabled: true })}>
                                            Executions
                                        </span>
                                    </li>
                                </span>
                            </ul>
                            
                            {this.state.pageType === "Executions" ?
                                <React.Fragment>
                                {!this.state.showDetailsLoading && this.state.automationdetails && Object.entries(this.state.automationdetails).length ?
                                    <React.Fragment>
                                    <div className="accordion" id="accordionDetails">
                                        <div className={`card bg-dark border-0 mb-2`}>
                                            <div id={"heading_details"} onClick={() => this.setState({ expandExecutionDetails: !this.state.expandExecutionDetails })}>
                                                <div className={`p-3 mb-0 d-flex justify-content-between ${this.state.expandExecutionDetails ? "" : ""}`} data-toggle="collapse" data-target={"#collapse_details"} aria-expanded="true" aria-controls={"collapse_details"}>
                                                    <div className="d-flex">
                                                        <div className="text-white">
                                                            <span className={`far ${this.state.expandExecutionDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-white mb-0">{this.state.automationdetails.automation_name}</p>
                                                            <p className="small mb-0">{this.state.automationdetails.automation_plan}</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-n1">
                                                        <p className="b-block mb-0 mr-2">Severity</p>
                                                        <span className={`mr-2 badge ${this.state.automationdetails.importance ? "risk-badge-"+this.state.automationdetails.importance.toLowerCase() : "badge-secondary" }`}>
                                                            {this.state.automationdetails.importance ? capitalizeFirstLetter(this.state.automationdetails.importance) : "Unknown"}
                                                        </span>
                                                    </div>
                                                    <div className="mt-n1">
                                                        <p className="b-block mb-0 mr-2">Status</p>
                                                        <span className={`mr-2 badge badge-${this.state.automationdetails.status.toLowerCase() === "success" ? "success" : "danger"}`}>{this.state.automationdetails.status}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={"collapse_details"} className={`collapse ${this.state.expandExecutionDetails ? "show" : ""}`} aria-labelledby={"heading_details"} data-parent="#accordionDetails">
                                                <div className="card-body">
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Type</p>
                                                            <p className="mb-0 text-white">{this.state.automationdetails.automation_type}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Provider / Account / Region </p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.provider ? this.state.automationdetails.provider.toUpperCase() : ""}
                                                                {this.state.automationdetails.account_id ? (" / ")+this.state.automationdetails.account_id : ""}
                                                                {this.state.automationdetails.region ? (" / ")+this.state.automationdetails.region : ""}
                                                            </p>
                                                            {/* <p className="b-block mb-0">Sub Type</p>
                                                            <p className="mb-0 text-white">{this.state.automationdetails.automation_subtype}</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Start Time</p>
                                                            <p className="mb-0 text-warning">
                                                                {this.state.automationdetails.start_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.start_time, "DD MMM YYYY HH:mm:ss") : ""}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Services </p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : ""}
                                                                {this.state.assetDetails.service_name ? (" : ")+this.state.assetDetails.service_name : ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">End Time</p>
                                                            <p className="mb-0 text-warning">
                                                                {this.state.automationdetails.end_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.end_time, "DD MMM YYYY HH:mm:ss") : ""}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Time Saved</p>
                                                            <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.automationdetails.time_saved, "Seconds")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Duration</p>
                                                            <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.automationdetails.duration, "Seconds")}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Cost Saved</p>
                                                            <p className="mb-0 text-info">{this.state.automationdetails.cost_saved} USD</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-30">
                                                            <p className="b-block mb-0">Elapsed Time</p>
                                                            <p className="mb-0 text-info">{unitTimeConvertionDetail(this.state.automationdetails.elapsed_time, "Seconds")}</p>
                                                        </div>
                                                        <div className="py-1 w-70 pl-3">
                                                            <p className="b-block mb-0">Tags</p>
                                                            {this.state.automationdetails.automation_tags && this.state.automationdetails.automation_tags.length ? 
                                                                this.state.automationdetails.automation_tags.map(tag => {
                                                                    return(
                                                                        <small className={`mr-1 badge badge-secondary`}>{tag}</small> 
                                                                    )
                                                                })  
                                                            : 
                                                                <p className="mb-0 text-info">&nbsp;</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="d-flex">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Description</p>
                                                            <p className="mb-0 text-white">{this.state.automationdetails.description ? this.state.automationdetails.description : ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion" id="accordionTrigger">
                                        <div className={`card bg-dark border-0 my-2`}>
                                            <div id={"heading_trigger"} onClick={() => this.setState({ expandTriggerDetails: !this.state.expandTriggerDetails })}>
                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_trigger"} aria-expanded="true" aria-controls={"collapse_trigger"}>
                                                    <div className="text-white"><span className={`far ${this.state.expandTriggerDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Trigger Details</div>
                                                    <div className="text-info">Showing trigger details</div>
                                                </div>
                                            </div>
                                            <div id={"collapse_trigger"} className={`collapse ${this.state.expandTriggerDetails ? "show" : ""}`} aria-labelledby={"heading_trigger"} data-parent="#accordionTrigger">
                                                <div className="card-body">
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Name</p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.trigger_name ? this.state.automationdetails.trigger_name : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Source</p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.trigger_type ? this.state.automationdetails.trigger_type : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1 border-bottom">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Type</p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.trigger_source ? this.state.automationdetails.trigger_source : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Type Detail</p>
                                                            <p className="mb-0 text-white">
                                                                {this.state.automationdetails.trigger_source_name? this.state.automationdetails.trigger_source_name : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mb-1">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Trigger Time</p>
                                                            <p className="mb-0 text-warning">
                                                                {this.state.automationdetails.start_time ? momentConvertionUtcToLocalTime(this.state.automationdetails.trigger_time, "DD MMM YYYY HH:mm:ss") : <span>&nbsp;</span>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.automationdetails.assets && this.state.automationdetails.assets.length ?
                                        <div className="accordion" id="accordionExample">
                                            <div className={`card bg-dark border-0 my-2`}>
                                                <div id={"heading_assets"} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse_assets"}aria-expanded="true" aria-controls={"collapse_assets"}>
                                                        <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? "fa-minus-circle" : "fa-plus-circle"} mr-2`}></span>Asset Details</div>
                                                        <div className="text-info">Showing asset details</div>
                                                    </div>
                                                </div>
                                                <div id={"collapse_assets"} className={`collapse ${this.state.expandAssetDetails ? "show" : ""}`} aria-labelledby={"heading_assets"} data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <ResizeableDarkThemeTable
                                                            columns={[
                                                                {
                                                                    Header: "Name",
                                                                    accessor: "asset_name"
                                                                },
                                                                {
                                                                    Header: "ID",
                                                                    accessor: "asset_id"
                                                                },
                                                                {
                                                                    Header: "VPC",
                                                                    accessor: d => d.vpc_id ? d.vpc_id : ""
                                                                },
                                                                {
                                                                    Header: "Resource Type",
                                                                    accessor: d => (d.resource_type ? d.resource_type : "")
                                                                },
                                                                {
                                                                    Header: "Service",
                                                                    accessor: d => (d.service_name ? d.service_name : "")
                                                                }
                                                            ]}
                                                            data={this.state.automationdetails.assets}
                                                            perPage={10}
                                                            dashboard={true}
                                                            sortByColumn={"asset_name"}
                                                            riskTooltip={[]}
                                                            onClickRow={tableRow => {}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                    </React.Fragment>
                                : 
                                    <div className="d-flex justify-content-center m-4">
                                        <p className="mb-0">No Execution details</p>
                                    </div>
                                }

                                {!this.state.showTaskLoading && this.state.automaitonTaskDetails && this.state.automaitonTaskDetails.length ?
                                    <div className="rounded bg-dark p-3 mt-2">
                                        <div className="timeline-style ml-2">
                                            {this.state.automaitonTaskDetails && this.state.automaitonTaskDetails.map(item => {
                                                return (
                                                    item.taskStartMessage && item.taskStartMessage !== "" ?
                                                        <div className={`d-flex justify-content-between}`}>
                                                            <div className={`d-flex`}>
                                                                <div className="d-flex border-right">
                                                                    <div className="p-2 pr-3 align-self-center">
                                                                        <div className={`font-weight-bold text-center p-2 ${item.taskStatus.toLowerCase() === "completed" ? "text-success success-border" : item.taskStatus.toLowerCase() === "failed" ? "text-danger danger-border" : "text-warning warning--border"} `}>
                                                                            <p className="small mb-0">{momentConvertionUtcToLocalTime(item.taskStartTime, "MMM DD")}</p>
                                                                            <p className="m-0 f10">{momentConvertionUtcToLocalTime(item.taskStartTime, "HH:mm:ss")}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="timeline-space mt-0 align-self-center">
                                                                    <span className="border bg-gray3"></span>
                                                                    <div className="bg-gray3"></div>
                                                                </div>
                                                                <div className={`align-self-center ml-2 border-bottom-muted`}>
                                                                    <div className="d-flex flex-wrap pt-2">
                                                                        <span className={`align-self-center mr-1 fa fa-circle ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}></span>
                                                                        <p className={`mb-0 mr-2 ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}>{capitalizeFirstLetter(item.taskStatus)}</p>
                                                                        <p className={`mb-0 ${item.taskStatus.toLowerCase() === "completed" ? "text-success" : item.taskStatus.toLowerCase() === "failed" ? "text-danger" : "text-warning"}`}>{item.taskName}</p>
                                                                        {item.taskEndTime && item.taskEndTime !== "" ?
                                                                            <p className="mb-0 ml-2">({twoDateDiffrenceDayHoursMinutes(item.taskStartTime, item.taskEndTime) === "-" ? "0 second" : twoDateDiffrenceDayHoursMinutes(item.taskStartTime, item.taskEndTime) })</p>
                                                                        : null}
                                                                    </div>
                                                                    {item.description ? 
                                                                        <div className="d-flex flex-wrap pt-1">
                                                                            <p className="mb-0">{item.description}</p>
                                                                        </div>
                                                                    : null}
                                                                    <div className="d-flex flex-wrap pt-2">
                                                                        <p className="mb-0">{momentConvertionUtcToLocalTime(item.taskStartTime, "DD MMM YYYY HH:mm:ss")}<span className="mr-1">:</span>{item.taskStartMessage}</p>
                                                                    </div>
                                                                    {item.taskStatus && item.taskMessage && item.taskMessage !== "" && item.taskMessage !== "{}" ?
                                                                        <div className="w-100 mt-2 ml-3">
                                                                            {item.taskMessage.split("\n").length ? 
                                                                                item.taskMessage.split("\n").map((msg, i) => {
                                                                                    return(
                                                                                        <div className="d-flex">
                                                                                            <p className="mb-0">{i+1}.</p>
                                                                                            <p className="ml-2 mb-0">{msg}</p>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            : null}
                                                                        </div>
                                                                    : null }
                                                                    {item.taskEndTime && item.taskEndTime !== "" ?
                                                                        <div className="d-flex flex-wrap mt-1">
                                                                            <div className="w-100">
                                                                                <span>{momentConvertionUtcToLocalTime(item.taskEndTime, "DD MMM YYYY HH:mm:ss")}<span className="mr-1">:</span>{item.taskEndMessage} </span>
                                                                            </div>
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null
                                                )
                                            })}
                                        </div>
                                    </div>
                                : this.state.showTaskLoading ?
                                    <div className="d-flex justify-content-center m-4">
                                        <span ><Spinner className="text-center" color="white" size="sm" /></span>
                                    </div>
                                : null}
                                </React.Fragment>
                            : 
                                <div className="accordion" id="accordionDetails">
                                    {this.state.assetDetails ?
                                        <React.Fragment>
                                            <div className={`card bg-dark border-0 mb-2`}>
                                                <div id={"collapse_details"} className={`collapse ${this.state.expandCheckDetails ? "show" : ""}`} aria-labelledby={"heading_details"} data-parent="#accordionDetails">
                                                    <div className="card-body">
                                                        <div className="d-flex mb-1 border-bottom">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Services</p>
                                                                <p className="mb-0 text-white">
                                                                    {this.state.assetDetails.resource_type ? this.state.assetDetails.resource_type : <span>&nbsp;</span>}
                                                                    {this.state.assetDetails.service_name ? " : "+this.state.assetDetails.service_name : <span>&nbsp;</span>}
                                                                </p>
                                                            </div>
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Asset</p>
                                                                <p className="mb-0 text-white">
                                                                    {this.state.assetDetails.asset_name ? this.state.assetDetails.asset_name : <span>&nbsp;</span>}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
	}
}

/**
 * Type of the props used in the component
 */
LandingPage.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log("-",state)
	return {
        providers: state.filters.providers, 
        accounts: state.filters.accounts,
		regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions : [],
        automationPropsDetails: state.filters.automationPropsDetails,
	}
}

export default AppWrapper(LandingPage, mapStateToProps, {
    listRemediation, 
    startRemediation,
    startAutomation,
    getAutomationExecution,
    getEventInfo,
    setAutomationPropsDetails,
})