/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ObservationTab.js
 * @author Prakash // on 10/05/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'

import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import { getCauseAndImpactedMetrics } from '../../../actions/CausalCorrelationAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getPeakDipDetectionResults } from '../../../actions/aiops/ObservabilityAction'

import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit } from '../../../utils/utility'

import Chart from 'react-apexcharts'

class ObservationTab extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            observationLoader: true,
            pageType: "Observation",
            topPeak: 3,
            sortPeakBy: "extrema_score",
            topDip: 3,
            sortDipBy: "extrema_score",
        }
    }

	componentDidMount = () => {
        // this.filterPeakDip()
        if(this.props.caseAssetPageFilter && Object.entries(this.props.caseAssetPageFilter).length) {
            this.setState({ 
                start_time: this.props.caseAssetPageFilter.start_time,
                end_time: this.props.caseAssetPageFilter.end_time,
                brushStartTime: this.props.caseAssetPageFilter.brushStartTime ? this.props.caseAssetPageFilter.brushStartTime : this.props.caseAssetPageFilter.start_time,
                brushEndTime: this.props.caseAssetPageFilter.brushEndTime ? this.props.caseAssetPageFilter.brushEndTime : this.props.caseAssetPageFilter.end_time,
                selectedAsset: this.props.caseAssetPageFilter.selectedAsset ? this.props.caseAssetPageFilter.selectedAsset : {},
                expandCauseSection: false,
                expandPeakAndDip: false,
                expandImpactSection: false,
                expandCorrelationSection: false,
                graphData: {}
            },
                () => {
                    // this.getMetricDetails()
                    this.getCauseAndImpactedMetrics()
                    this.getPeakDipDetectionResults()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        
        if(this.props.caseAssetPageFilter && Object.entries(this.props.caseAssetPageFilter).length) {
            if(!_.isEqual(prevProps.caseAssetPageFilter, this.props.caseAssetPageFilter)) {
                this.setState({ 
                    observationLoader: true,
                    start_time: this.props.caseAssetPageFilter.start_time,
                    end_time: this.props.caseAssetPageFilter.end_time,
                    brushStartTime: this.props.caseAssetPageFilter.brushStartTime ? this.props.caseAssetPageFilter.brushStartTime : this.props.caseAssetPageFilter.start_time,
                    brushEndTime: this.props.caseAssetPageFilter.brushEndTime ? this.props.caseAssetPageFilter.brushEndTime : this.props.caseAssetPageFilter.end_time,
                    selectedAsset: this.props.caseAssetPageFilter.selectedAsset ? this.props.caseAssetPageFilter.selectedAsset : {},
                    graphData: {},
                    topDip: 3,
                    topPeak: 3,
                },
                    () => {
                        if(this.props.selectedMetrics && this.props.selectedMetrics !== "") {
                            this.getCauseAndImpactedMetrics()
                            this.getPeakDipDetectionResults()
                        }
                    }
                )
            } else if(this.props.selectedMetrics) {
                if(this.props.selectedMetrics !== "" && !_.isEqual(prevProps.selectedMetrics, this.props.selectedMetrics)) {
                    this.setState({ 
                        observationLoader: true, 
                        expandCauseSection: false, 
                        expandPeakAndDip: false,
                        expandImpactSection: false,
                        expandCorrelationSection: false,
                        selectedCorrelationMetrics: [], 
                        selectedImpactMetrics: [],
                        topDip: 3,
                        topPeak: 3,
                    },
                        () => {
                            this.getCauseAndImpactedMetrics()                  
                            this.getPeakDipDetectionResults()
                        }
                    )
                }
            }
        }
    }

    getCauseAndImpactedMetrics = () => {
        let startTime = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        let endTime = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        let assetId = this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        let assetArn = this.state.selectedAsset.asset_arn ? this.state.selectedAsset.asset_arn : ""
        let assetName = this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""
        
        let params = {}
        params.asset_id = assetId
        params.metric_name = this.props.selectedMetrics
        params.start_time = startTime
        params.end_time = endTime
        params.top = 4
        // {"asset_id": "c6b1432e918279757719547ff4113486", "metric_name": "NetworkOut", "start_time":"2022-05-11 01:00:00", "end_time": "2022-05-11 02:00:00", "top": 4}

        this.props.getCauseAndImpactedMetrics(params, (promise, response) => {
            if(promise) {
                this.setState({ causeImpactData: response },
                    () => this.structureCaseImpact()    
                )
            }
        })
    }

    structureCaseImpact = () => {
        let causeImpactData = this.state.causeImpactData

        let causeData = causeImpactData.cause
        if(causeData && causeData.length) {
            causeData.forEach((item, i) => { 
                if(item.metrics && Object.entries(item.metrics).length) {
                    // this.setState({ selectedCauseMetrics: [] }) //updated selected cause metrics
                    this.metricsListGraph("cause", item.details, item.metrics, causeImpactData.dates)
                }
            })
        }

        let impactData = causeImpactData.impact
        if(impactData && impactData.length) {
            impactData.forEach((item, i) => { 
                if(item.metrics && Object.entries(item.metrics).length) {
                    // this.setState({ selectedImpactMetrics: [] }) //updated selected impact metrics
                    this.metricsListGraph("impact", item.details, item.metrics, causeImpactData.dates)
                }
            })
        }

        let correlationData = causeImpactData.correlation
        if(correlationData && correlationData.length) {
            correlationData.forEach((item, i) => { 
                if(item.metrics && Object.entries(item.metrics).length) {
                    // this.setState({ selectedCorrelationMetrics: [] }) //updated selected impact metrics
                    this.metricsListGraph("correlation", item.details, item.metrics, causeImpactData.dates)
                }
            })
        }
        this.setState({ causeData, impactData, correlationData, observationLoader: false })
    }

    getPeakDipDetectionResults = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.asset_id = this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : "" //"cf58f86ea0f188a78a07817e52424239"
        // params.duration = "1w"
        params.future_metrics = true
        if(this.props.selectedMetrics && this.props.selectedMetrics !== "") {
            params.metric_name = this.props.selectedMetrics
        }
        // statistic = "Average"
        this.props.getPeakDipDetectionResults(params, (promise, response) => {
            if(promise) {
                let results = response && response.results ? response.results : []
                this.setState({ peakDipData: results },
                    () => this.filterPeakDip()    
                )
            } else {
                this.setState({ peakDipData: [] })
            }
        })
    }

    filterPeakDip = () => {
        let hourlyData = this.state.peakDipData.filter(e => e.extrema_freq === "Hourly")
        let peakData = hourlyData.filter(e => e.extrema_type === "peak")
        let dipData = hourlyData.filter(e => e.extrema_type === "dip")

        if(peakData) {
            peakData = _.orderBy(peakData, [this.state.sortPeakBy], ["desc"])
        }

        if(dipData) {
            dipData = _.orderBy(dipData, [this.state.sortDipBy], ["desc"])
        }

        this.setState({ peakData, dipData })
    }

    getMetricDetails = () => {
        let startTime = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        let endTime = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        let assetId = this.state.selectedAsset.asset_id ? this.state.selectedAsset.asset_id : ""
        let assetArn = this.state.selectedAsset.asset_arn ? this.state.selectedAsset.asset_arn : ""
        let assetName = this.state.selectedAsset.asset_name ? this.state.selectedAsset.asset_name : ""

        let assetDetails = []
        if(assetId !== "" || assetArn !== "" || assetName !== "") {
            let params = {}
            params.start_time = startTime
            params.end_time = endTime
            if(assetId !== "") {
                params.asset_id = assetId
            } else if(assetArn !== "") {
                params.asset_arn = assetArn
            } else {
                params.asset_name = assetName
                params.account_id = this.state.selectedAsset.account_id
                params.region = this.state.selectedAsset.region
                if(this.state.selectedAsset.service_name) {
                    params.service_name = this.state.selectedAsset.service_name
                } else if(this.state.selectedAsset.resource_type) {
                    params.resource_type = this.state.selectedAsset.resource_type
                }
            }
            if(this.state.selectedAsset.service_name) {
                params.service_name = this.state.selectedAsset.service_name
            }
                        
            params.top = 5
            params.anomaly = false
                            
            this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                if(promise) {
                    let orderByResponse = response
                    if(response && response.length) {
                        orderByResponse = _.orderBy(response, ['order'], ['asc'])
                    }
                    this.setState({ 
                        metricsData: orderByResponse, 
                        observationLoader: response.length ? true : false,
                        assetDetails
                    },
                        () => {
                            this.metricsListGraph()
                        }
                    )
                    // if(response.length) {
                    //     let obj = this.props.observabilityCoreMetricsList
                    //     obj[assetId] = orderByResponse
                    //     this.props.setAiopsPropsDetails('observabilityCoreMetricsList', obj)
                    // }
                } else {
                    this.setState({ observationLoader: false })
                    this.props.menuDisabled()
                }
            })
        } else {            
            this.setState({ observationLoader: false })
        }
    }
    
    metricsListGraph = (type, details, graphData, graphLabels) => {
        console.log("graphData", graphData)
        let colors = []
        let series = []
        let options = {}

        let  strokeWidth = []

        if(graphData.values && graphData.values.length) {
            if(graphData.values && graphData.values.length) { //statistic
                
                
                let maxValue = graphData.statistics && graphData.statistics.max ? graphData.statistics.max : 1
                let avgValue = graphData.statistics && graphData.statistics.avg ? graphData.statistics.avg : 1
                let minValue = graphData.statistics && graphData.statistics.min ? graphData.statistics.min : 1
                
                
                let graphConvertTo = ""

                let maxRandomNumber = maxValue
                let minRandomNumber = minValue
                if(graphData.unit) {
                    graphConvertTo = this.getConvertedUnit(maxValue, graphData.unit)
                    maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, graphData.unit, "round")
                    minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, graphData.unit, "round")
                }

                colors.push('#3DAFE8')
                strokeWidth.push(2)
                let dataRow = {}
                dataRow.name = graphData.statistic === "Maximum" ? "max" : graphData.statistic === "Average" ? "avg" : graphData.statistic === "Sum" ? "sum" : graphData.statistic
                dataRow.type = graphData.statistic !== "Maximum" ? "area" : "line"
                let plotValue = []
                
                graphLabels.map((lab, i) => {
                    let dataArray = []
                    dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                    let val = graphData.values[i]
                    if(graphData.unit) {
                        val = this.getGrapUnitValue(val, graphData.unit, "", graphConvertTo)
                    }
                    dataArray.push(val)
                    plotValue.push(dataArray)
                })
                dataRow.data = plotValue
                series.push(dataRow)
            }

            // if(graphData.additional_statistics && graphData.additional_statistics.length) {
            //     if(graphData.values && graphData.values.length) {
            //         if(graphData.additional_statistics[0] !== "Maximum") {
            //             colors.push('#775BA2')
            //         } else {
            //             colors.push('#3DAFE8')
            //         }
            //         strokeWidth.push(2)
            //         let dataRow = {}
            //         dataRow.name = graphData.additional_statistics[0] === "Maximum" ? "max" : graphData.additional_statistics[0] === "Average" ? "avg" : graphData.additional_statistics[0] === "Sum" ? "sum" : graphData.additional_statistics[0]
            //         dataRow.type = graphData.additional_statistics[0] !== "Maximum" ? "area" : "line"
            //         let plotValue = []
            //         graphLabels.map((lab, i) => {
            //             let dataArray = []
            //             dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
            //             let val = graphData.values[i]
            //             if(graphData.unit) {
            //                 val = this.getGrapUnitValue(val, graphData.unit)
            //             }
            //             dataArray.push(val)
            //             plotValue.push(dataArray)
            //         })
            //         dataRow.data = plotValue
            //         series.push(dataRow)
            //     }
            // }

            console.log(details.metric_name, series)
            
            let xaxisAnnotation = []

            let annotations = {
                xaxis: xaxisAnnotation
            }

            options = {
                chart: {
                    // group: 'metrics_sre',
                    // id: 'chart_rate_'+index,
                    // redrawOnParentResize: true,
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: false
                    },
                    type: "line",
                    width: '100%',
                    animations: {
                        enabled: false
                    }
                },
                colors: colors,
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: strokeWidth,
                    curve: 'smooth',
                },
                legend: {
                    show: false
                },
                fill: {
                    type:'solid',
                    opacity: [0.25, 1],
                },
                // fill: {
                //     type: "gradient",
                //     gradient: {
                //         // shadeIntensity: 1,
                //         // opacityFrom: 0.7,
                //         // opacityTo: 0.9,
                //         // stops: [0, 90, 100]
                //         gradientToColors: ['#775BA2'],
                //         shadeIntensity: 0,
                //         opacityFrom: .3,
                //         opacityTo: 1,
                //         stops: [30, 90]
                //     }
                // },
                tooltip: {
                    // custom: function({series, seriesIndex, dataPointIndex, w}) {
                    //     let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                    //     dateLable = dateLable.toString()
                    //     dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                    //     let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                    //     return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                    // },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {         
                        let returnData = ''
                        
                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                            Object.entries(w.globals.initialSeries).map(([key, value], index) => {
                                if(!index) {
                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                }
                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                            })
                        }

                        returnData += '</div>'
                        return returnData
                    },
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false,
                    },
                    y: {
                        show: false
                    },        
                    marker: {
                        show: false
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                        style: {
                            colors: '#60687C'
                        },
                        // formatter: function (value) {
                        //     return momentDateGivenFormat(value, 'HH:mm')
                        // },
                    },                            
                    axisTicks: {
                        show: false
                    },
                    tooltip: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                yaxis: [
                    {
                        show: true,
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                            color: '#60687C'
                        },
                        tooltip: {
                            enabled: false
                        },
                        // tickAmount: 2,
                        min: 0,
                        // max: yaxisMaxValue, 
                        tickAmount: 1,
                        labels: {
                            offsetX: -10,
                            // minWidth: 50,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#FFFFFF'
                            },
                            formatter: (value) => { 
                                if(Number(value) === value && value % 1 === 0) {
                                    return Math.ceil(value.toFixed(1))
                                } else {
                                    return Math.ceil(value.toFixed(1))
                                }
                                
                                // if(!value) {
                                //     return 0
                                // } else {
                                //     let val = value.toFixed(2)
                                //     val = val.split(".")
                                //     if(val[1] && val[1] > 0) {
                                //         return value.toFixed(2)
                                //     } else {
                                //         return parseInt(val[0])
                                //     }
                                // }
                            },
                            // offsetX: 0,
                            // offsetY: 0,
                        },
                    },
                    {
                        show: false,
                    }
                ],
                grid: {
                    show: false,
                    borderColor: '#2D323E',
                    strokeDashArray: 0,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: true
                        },
                        labels: {
                            style: {
                                colors: ['#FFF'],
                            }
                        },
                        axisTicks: {
                            show: false
                        }
                    },   
                    yaxis: {
                        lines: {
                            show: false
                        },
                        labels: {
                            style: {
                                colors: ['#969AA7'],
                            }
                        }
                    },                
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                },
                annotations: annotations,
            }
        }
        this.setState({ [details.metric_name+"_"+type+"_series_"+details.asset_id]: series, [details.metric_name+"_"+type+"_options_"+details.asset_id]: options, observationLoader: false })
    }

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    expanCasueTab = () => {
        if(this.state.causeData && this.state.causeData.length) {
            this.setState({ expandCauseSection: !this.state.expandCauseSection })
        }
    }

    expanImpactTab = () => {
        if(this.state.impactData && this.state.impactData.length) {
            this.setState({ expandImpactSection: !this.state.expandImpactSection })
        }
    }

    expandCorrelationTab = () => {
        if(this.state.correlationData && this.state.correlationData.length) {
            this.setState({ expandCorrelationSection: !this.state.expandCorrelationSection })
        }
    }

    expandPeakDibTab = () => {
        // if(this.state.correlationData && this.state.correlationData.length) {
        this.setState({ expandPeakAndDip: !this.state.expandPeakAndDip })
        // }
    }

    updatedSelectedMetrics = (type, index) => {
        if(type === "cause") {
            let selectedMetrics = this.state.selectedCauseMetrics ? this.state.selectedCauseMetrics : []
            console.log("selectedMetrics", selectedMetrics)
            if(selectedMetrics.includes(index)) {
                selectedMetrics = selectedMetrics.filter(e => e !== index)
                console.log("selectedMetrics", selectedMetrics)
            } else {
                selectedMetrics.push(index)
            }

            this.setState({ selectedCauseMetrics: selectedMetrics })
        } else if(type === "impact") {
            let selectedMetrics = this.state.selectedImpactMetrics ? this.state.selectedImpactMetrics : []
            if(selectedMetrics.includes(index)) {
                selectedMetrics = selectedMetrics.filter(e => e !== index)
            } else {
                selectedMetrics.push(index)
            }

            this.setState({ selectedImpactMetrics: selectedMetrics })
        } else if(type === "correlation") {
            let selectedMetrics = this.state.selectedCorrelationMetrics ? this.state.selectedCorrelationMetrics : []
            if(selectedMetrics.includes(index)) {
                selectedMetrics = selectedMetrics.filter(e => e !== index)
            } else {
                selectedMetrics.push(index)
            }

            this.setState({ selectedCorrelationMetrics: selectedMetrics })
        }

    }

	render() {
		return (
            <div className="rounded bg-dark">
                <div className="accordion" id="accordionExample">
                    <div className={`card bg-dark3 border-0 mb-2`}>
                        <div id={'heading_event'} onClick={() => this.expandPeakDibTab()}>
                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                <div className="text-white">
                                    <span className={`far ${this.state.expandPeakAndDip ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>
                                    Peak and Dip
                                </div>
                                {/* {this.state.peakDipLoader ?
                                    <Spinner className='text-center' color='white' size='sm' />
                                :
                                    <div className="text-info">{!this.state.peakDipLoader || !this.state.peakDipLoader.length ? "No" : this.state.peakDipLoader.length} Peak(s)</div>
                                } */}
                            </div>
                        </div>
                        <div id={'collapse_event'} className={`collapse ${this.state.expandPeakAndDip ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                            <div className="card-body">
                                {this.state.peakDipLoader ?
                                    <div className='d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='white' size='lg' />
                                    </div>
                                :
                                    <div className="">
                                        <p className="m-0 text-white">Peak</p>
                                        <p className="small m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et, sed do eiusmod tempor incididunt ut labore et.</p>
                                        <div className="rounded bg-muted p-3 mt-2">
                                            <div className={`d-flex mb-1`}>
                                                <p className="m-0 align-self-end small mr-2">Show Top </p>
                                                <div className={`transparentSelectBorderBottom align-self-end`}>
                                                    <select className="form-control"
                                                        onChange={e => this.setState({ topPeak: e.target.value},
                                                            // () =>this.filterPeakDip("peak")
                                                        )}                         
                                                            value={this.state.topPeak}
                                                        >
                                                            <option value={3}>3</option>
                                                            <option value={5}>5</option>
                                                            <option value={10}>10</option>
                                                    </select>
                                                </div>
                                                <p className="m-0 align-self-end small mx-2">Peaks sort by Score</p>
                                                {/* <p className="m-0 align-self-end small mr-2">Sort by</p>
                                                <div className={`transparentSelectBorderBottom align-self-end`}>
                                                    <select className="form-control"
                                                        onChange={e => this.setState({ sortPeakBy: e.target.value},
                                                            () =>this.filterPeakDip("peak")
                                                        )}                         
                                                            value={this.state.sortPeakBy}
                                                        >
                                                            <option value={"date"}>Date</option>
                                                            <option value={"time_range"}>Duration</option>
                                                            <option value={"extrema_score"}>Score</option>
                                                            <option value={"extrema_value"}>Value</option>
                                                    </select>
                                                </div> */}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-dark">
                                                    <thead className="bg-dark3" >
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Day</th>
                                                            <th>Range</th>
                                                            <th>Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.peakData && this.state.peakData.length ? 
                                                            this.state.peakData.slice(0,this.state.topPeak).map((item, index) => {
                                                                return(
                                                                    <tr className={`${!index ? "" : "border-top"}`}>
                                                                        <td className="align-middle">
                                                                            {item.date ? momentConvertionUtcToLocalTime(item.date, "DD MMM YYYY") : ""}
                                                                        </td>                            
                                                                        <td className="align-middle">{item.day ? item.day : ""}</td>
                                                                        <td className="align-middle">
                                                                            {item.min_time && item.max_time ?
                                                                                <div className="d-flex">
                                                                                    <span>{item.min_time ? momentConvertionUtcToLocalTime(item.min_time, "DD MMM YYYY HH:mm") : ""}</span>
                                                                                    <span className="ml-2">{item.max_time ? " - "+momentConvertionUtcToLocalTime(item.max_time, "DD MMM YYYY HH:mm") : ""}</span>
                                                                                </div>
                                                                            : null}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            {item.time_range_in_mins ? item.time_range_in_mins : ''}
                                                                        </td>
                                                                    </tr>
                                                                )

                                                            })
                                                        : 
                                                            <tr key={-1}>
                                                                <td colSpan={4} className="text-center">No Peak(s) found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>  

                                        <p className="text-white mt-4 mb-0">Dip</p>
                                        <p className="small m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et, sed do eiusmod tempor incididunt ut labore et.</p>
                                        <div className="rounded bg-muted p-3 mt-2">
                                            <div className={`d-flex mb-1`}>
                                                <p className="m-0 align-self-end small mr-2">Show Top </p>
                                                <div className={`transparentSelectBorderBottom align-self-end`}>
                                                    <select className="form-control"
                                                        onChange={e => this.setState({ topDip: e.target.value},
                                                            // () =>this.filterPeakDip("dip")
                                                        )}                         
                                                            value={this.state.topDip}
                                                        >
                                                            <option value={3}>3</option>
                                                            <option value={5}>5</option>
                                                            <option value={10}>10</option>
                                                    </select>
                                                </div>
                                                <p className="m-0 align-self-end small mx-2">Dips sort by Score</p>
                                                {/* <p className="m-0 align-self-end small mr-2"></p> */}
                                                {/* <div className={`transparentSelectBorderBottom align-self-end`}>
                                                    <select className="form-control"
                                                        onChange={e => this.setState({ sortDipBy: e.target.value},
                                                            () =>this.filterPeakDip("peak")
                                                        )}                         
                                                            value={this.state.sortDipBy}
                                                        >
                                                            <option value={"date"}>Date</option>
                                                            <option value={"time_range_in_mins"}>Duration</option>
                                                            <option value={"extrema_score"}>Score</option>
                                                            <option value={"extrema_value"}>Value</option>
                                                    </select>
                                                </div> */}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-dark">
                                                    <thead className="bg-dark3">
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Day</th>
                                                            <th>Range</th>
                                                            <th>Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.dipData && this.state.dipData.length ? 
                                                            this.state.dipData.slice(0,this.state.topDip).map((item, index) => {
                                                                return(
                                                                    <tr className={`${!index ? "" : "border-top"}`}>
                                                                        <td className="align-middle">
                                                                            {item.date ? momentConvertionUtcToLocalTime(item.date, "DD MMM YYYY") : ""}
                                                                        </td>                            
                                                                        <td className="align-middle">{item.day ? item.day : ""}</td>
                                                                        <td className="align-middle">
                                                                            {item.min_time && item.max_time ?
                                                                                <div className="d-flex">
                                                                                    <span>{item.min_time ? momentConvertionUtcToLocalTime(item.min_time, "DD MMM YYYY HH:mm") : ""}</span>
                                                                                    <span className="ml-2">{item.max_time ? " - "+momentConvertionUtcToLocalTime(item.max_time, "DD MMM YYYY HH:mm") : ""}</span>
                                                                                </div>
                                                                            : null}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            {item.time_range_in_mins ? item.time_range_in_mins : ''}
                                                                        </td>
                                                                    </tr>
                                                                )

                                                            })
                                                        : 
                                                            <tr key={-1}>
                                                                <td colSpan={4} className="text-center">No Dip(s) found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div> 
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {this.props.selectedMetrics && this.props.selectedMetrics !== "" ?
                        <React.Fragment>
                        <div className={`card bg-dark3 border-0 mb-2`}>
                            <div id={'heading_event'} onClick={() => this.expanCasueTab()}>
                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                    <div className="text-white">
                                        <span className={`far ${this.state.expandCauseSection || (!this.state.causeData || !this.state.causeData.length) ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>
                                        Cause
                                    </div>
                                    {this.state.observationLoader ?
                                        <Spinner className='text-center' color='white' size='sm' />
                                    :
                                        <div className="text-info">{!this.state.causeData || !this.state.causeData.length ? "No" : this.state.causeData.length} Cause(s)</div>
                                    }
                                </div>
                            </div>
                            <div id={'collapse_event'} className={`collapse ${this.state.expandCauseSection ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                <div className="card-body">
                                    {this.state.observationLoader ?
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    :
                                        <div className="col-sm-12">
                                            {this.state.causeData && this.state.causeData.length ?
                                                this.state.causeData.map((item, i) => {
                                                    return(
                                                        <div className="row">
                                                            <div className={`${this.state.selectedCauseMetrics && this.state.selectedCauseMetrics.includes(i) ? "col-sm-9 pl-0" : "col-sm-12 p-0"}`}>
                                                                {item.metrics && Object.entries(item.metrics).length ?
                                                                    <div className={`${this.state.selectedCauseMetrics && this.state.selectedCauseMetrics.includes(i) ? "bg-muted p-2 mb-2" : ""} ${i ? "pt-2" : ""}`}>
                                                                        <div className="d-flex justify-content-between displayNone">
                                                                            <div className="d-flex">
                                                                                <h7 className="text-white mb-0">{item.details.metric_name}</h7>
                                                                                <p className="text-primary-color f12 align-self-end mb-0 ml-2">
                                                                                    ({item.metrics.statistic})
                                                                                    {/* ({value.statistic+""+(value.additional_statistics && value.additional_statistics.length ?  " - "+value.additional_statistics[0] : "")}) */}
                                                                                </p>
                                                                            </div>
                                                                            <i className="fas fa-eye cursorPointer text-white" onClick={() => this.updatedSelectedMetrics("cause", i)}></i>
                                                                        </div>
                                                                        {/* <p className="text-primary-color f12 align-self-end mb-2 w-100">
                                                                            The {item.source.metric_name} of {item.source.asset_name} is the probable cause for the {item.target.metric_name}. Score {item.relation.score}, with {item.relation.lag} lag in {item.relation.lag_time_in_minutes} minutes
                                                                        </p> */}
                                                                        <p className={`text-primary-color f12 align-self-end mb-0 pb-2 w-100`}>
                                                                            <i class={`far fa-${this.state.selectedCauseMetrics && this.state.selectedCauseMetrics.includes(i) ? "compress" : "expand-wide"} cursorPointer text-white mr-2`} onClick={() => this.updatedSelectedMetrics("cause", i)}></i> The <span className="text-white">{item.source.metric_name}</span> of <span className="text-purple-2">{item.source.asset_name}</span> is the probable cause for the <span className="text-white">{item.target.metric_name}</span> of <span className="text-purple-2">{item.target.asset_name}</span> ({item.metrics.statistic}), its score {item.relation.score} with {item.relation.lag} lag(s) in {item.relation.lag_time_in_minutes} minutes
                                                                        </p>

                                                                        {this.state[item.details.metric_name+"_cause_series_"+item.details.asset_id] && this.state[item.details.metric_name+"_cause_series_"+item.details.asset_id].length && this.state[item.details.metric_name+"_cause_options_"+item.details.asset_id] ?
                                                                            <div className={`cursorPointer transparentTooltip mb-n4 mt-n4 ${this.state.selectedCauseMetrics && this.state.selectedCauseMetrics.includes(i) ? "" : "displayNone"}`}>
                                                                                <Chart options={this.state[item.details.metric_name+"_cause_options_"+item.details.asset_id]} series={this.state[item.details.metric_name+"_cause_series_"+item.details.asset_id]} type="line" height={135}/>
                                                                            </div>
                                                                        : 
                                                                            <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                                <div className="align-self-center">
                                                                                    No Data
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            <div className={`${this.state.selectedCauseMetrics && this.state.selectedCauseMetrics.includes(i) ? "col-sm-3 p-0" : "displayNone"}`}>
                                                                <div class={`col-xs-12 verticalArrowDown bg-muted p-2 mb-2`}>
                                                                    <p class="text-center bg-info btn text-white center-block mb-0 w-100 p-0" id={"source_cause_"+i}>
                                                                        {/* <span>{item.source.asset_name}</span> */}
                                                                        <p className="text-center w-100 mb-0">{item.source.metric_name}</p>
                                                                        <p className="text-center w-100 mb-0">{item.source.service_name}</p>
                                                                    </p>
                                                                    
                                                                    <UncontrolledTooltip placement='top' target={"source_cause_"+i}>
                                                                        <div className="uncontrolledTooltipLeftAlign">
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span> {item.source.asset_name}</p>
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Metrics: </span> {item.source.metric_name}</p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Resources: </span>
                                                                                {item.source.provider ? item.source.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.source.account_id ? (' : ')+item.source.account_id : <span>&nbsp;</span>}
                                                                                {item.source.region ?  (' : ')+item.source.region : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Services: </span>
                                                                                {/* {item.source.resource_type ?  ('  ')+item.source.resource_type : <span>&nbsp;</span>} */}
                                                                                {item.source.service_name ?  (' ')+item.source.service_name : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>    
                                                                    </UncontrolledTooltip>
                                                                    <div class="d-flex">
                                                                        <p className="lineText w-45 mb-0 align-self-center">{item.relation.lag} lag(s)</p>
                                                                        <p class="vl mb-0" style={{height: "68px"}}><i class="arrow down w-45" style={{marginTop: "63px"}}></i></p>
                                                                        {/* <p className="lineText">Score {item.relation.score}, with {item.relation.lag} lag in {item.relation.lag_time_in_minutes} minutes</p> */}
                                                                        <p className="lineText mb-0 align-self-center">in {item.relation.lag_time_in_minutes} minute(s)</p>
                                                                    </div>
                                                                    <p class="text-center bg-info btn text-white mt-2 mb-0 w-100 p-0" id={"target_cause_"+i}>
                                                                        {/* <span>{item.target.asset_name}</span> */}
                                                                        <p className="text-center w-100 mb-0">{item.target.metric_name}</p>
                                                                        <p className="text-center w-100 mb-0">{item.target.service_name}</p>
                                                                    </p>
                                                                    <UncontrolledTooltip placement='top' target={"target_cause_"+i}>
                                                                        <div className="uncontrolledTooltipLeftAlign">
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span> {item.target.asset_name}</p>
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Metrics: </span> {item.target.metric_name}</p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Resources: </span>
                                                                                {item.target.provider ? item.target.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.target.account_id ? (' : ')+item.target.account_id : <span>&nbsp;</span>}
                                                                                {item.target.region ?  (' : ')+item.target.region : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Services: </span>
                                                                                {/* {item.target.resource_type ?  ('  ')+item.target.resource_type : <span>&nbsp;</span>} */}
                                                                                {item.target.service_name ?  (' ')+item.target.service_name : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>    
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`card bg-dark3 border-0 mb-2`}>
                            <div id={'heading_event'} onClick={() => this.expanImpactTab()}>
                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                    <div className="text-white">
                                        <span className={`far ${this.state.expandImpactSection || (!this.state.impactData || !this.state.impactData.length) ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>
                                        Impact
                                    </div>
                                    {this.state.observationLoader ?
                                        <Spinner className='text-center' color='white' size='sm' />
                                    :
                                        <div className="text-info">impact(s) {!this.state.impactData || !this.state.impactData.length ? "No" : this.state.impactData.length} other metrics</div>
                                    }
                                </div>
                            </div>
                            <div id={'collapse_event'} className={`collapse ${this.state.expandImpactSection ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                <div className="card-body">
                                    {this.state.observationLoader ?
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    :                                
                                        <div className="col-sm-12">
                                            {this.state.impactData && this.state.impactData.length ?
                                                this.state.impactData.map((item, i) => {
                                                    return(
                                                        <div className="row">
                                                            <div className={`${this.state.selectedImpactMetrics && this.state.selectedImpactMetrics.includes(i) ? "col-sm-9 pl-0" : "col-sm-12 p-0"}`}>
                                                                {item.metrics && Object.entries(item.metrics).length ?
                                                                    <div className={`${this.state.selectedImpactMetrics && this.state.selectedImpactMetrics.includes(i) ? "bg-muted p-2 mb-2" : ""} ${i ? "pt-2" : ""}`}>
                                                                        <div className="d-flex justify-content-between displayNone">
                                                                            <div className="d-flex">
                                                                                <h7 className="text-white mb-0">{item.details.metric_name}</h7>
                                                                                <p className="text-primary-color f12 align-self-end mb-0 ml-2">
                                                                                    ({item.metrics.statistic})
                                                                                    {/* ({value.statistic+""+(value.additional_statistics && value.additional_statistics.length ?  " - "+value.additional_statistics[0] : "")}) */}
                                                                                </p>
                                                                            </div>
                                                                            <i className="fas fa-eye cursorPointer text-white" onClick={() => this.updatedSelectedMetrics("impact", i)}></i>
                                                                        </div>
                                                                        <p className={`text-primary-color f12 align-self-end mb-0 pb-2 w-100`}>
                                                                            <i class={`far fa-${this.state.selectedImpactMetrics && this.state.selectedImpactMetrics.includes(i) ? "compress" : "expand-wide"} cursorPointer text-white mr-2`} onClick={() => this.updatedSelectedMetrics("impact", i)}></i> The <span className="text-white">{item.source.metric_name}</span> of <span className="text-purple-2">{item.source.asset_name}</span> is the probable cause for the <span className="text-white">{item.target.metric_name}</span> of <span className="text-purple-2">{item.target.asset_name}</span> ({item.metrics.statistic}), its score {item.relation.score} with {item.relation.lag} lag(s) in {item.relation.lag_time_in_minutes} minutes
                                                                        </p>

                                                                        {this.state[item.details.metric_name+"_impact_series_"+item.details.asset_id] && this.state[item.details.metric_name+"_impact_series_"+item.details.asset_id].length && this.state[item.details.metric_name+"_impact_options_"+item.details.asset_id] ?
                                                                            <div className={`cursorPointer transparentTooltip mb-n4 mt-n4 ${this.state.selectedImpactMetrics && this.state.selectedImpactMetrics.includes(i) ? "" : "displayNone"}`}>
                                                                                <Chart options={this.state[item.details.metric_name+"_impact_options_"+item.details.asset_id]} series={this.state[item.details.metric_name+"_impact_series_"+item.details.asset_id]} type="line" height={135}/>
                                                                            </div>
                                                                        : 
                                                                            <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                                <div className="align-self-center">
                                                                                    No Data
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            <div className={`${this.state.selectedImpactMetrics && this.state.selectedImpactMetrics.includes(i) ? "col-sm-3 p-0" : "displayNone"}`}>
                                                                <div class={`col-xs-12 verticalArrowDown bg-muted p-2 mb-2`}>
                                                                    <p class="text-center bg-info btn text-white center-block mb-0 w-100 p-0" id={"source_impact_"+i}>
                                                                        {/* <span>{item.source.asset_name}</span> */}
                                                                        <p className="text-center w-100 mb-0">{item.source.metric_name}</p>
                                                                        <p className="text-center w-100 mb-0">{item.source.service_name}</p>
                                                                    </p>
                                                                    
                                                                    <UncontrolledTooltip placement='top' target={"source_impact_"+i}>
                                                                        <div className="uncontrolledTooltipLeftAlign">
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span> {item.source.asset_name}</p>
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Metrics: </span> {item.source.metric_name}</p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Resources: </span>
                                                                                {item.source.provider ? item.source.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.source.account_id ? (' : ')+item.source.account_id : <span>&nbsp;</span>}
                                                                                {item.source.region ?  (' : ')+item.source.region : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Services: </span>
                                                                                {/* {item.source.resource_type ?  ('  ')+item.source.resource_type : <span>&nbsp;</span>} */}
                                                                                {item.source.service_name ?  (' ')+item.source.service_name : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>    
                                                                    </UncontrolledTooltip>
                                                                    <div class="d-flex">
                                                                        <p className="lineText w-45 mb-0 align-self-center">{item.relation.lag} lag(s)</p>
                                                                        <p class="vl mb-0" style={{height: "68px"}}><i class="arrow down w-45" style={{marginTop: "63px"}}></i></p>
                                                                        {/* <p className="lineText">Score {item.relation.score}, with {item.relation.lag} lag in {item.relation.lag_time_in_minutes} minutes</p> */}
                                                                        <p className="lineText mb-0 align-self-center">in {item.relation.lag_time_in_minutes} minute(s)</p>
                                                                    </div>
                                                                    <p class="text-center bg-info btn text-white mt-2 mb-0 w-100 p-0" id={"target_impact_"+i}>
                                                                        {/* <span>{item.target.asset_name}</span> */}
                                                                        <p className="text-center w-100 mb-0">{item.target.metric_name}</p>
                                                                        <p className="text-center w-100 mb-0">{item.target.service_name}</p>
                                                                    </p>
                                                                    <UncontrolledTooltip placement='top' target={"target_impact_"+i}>
                                                                        <div className="uncontrolledTooltipLeftAlign">
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span> {item.target.asset_name}</p>
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Metrics: </span> {item.target.metric_name}</p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Resources: </span>
                                                                                {item.target.provider ? item.target.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.target.account_id ? (' : ')+item.target.account_id : <span>&nbsp;</span>}
                                                                                {item.target.region ?  (' : ')+item.target.region : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Services: </span>
                                                                                {/* {item.target.resource_type ?  ('  ')+item.target.resource_type : <span>&nbsp;</span>} */}
                                                                                {item.target.service_name ?  (' ')+item.target.service_name : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>    
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`card bg-dark3 border-0 mb-2`}>
                            <div id={'heading_event'} onClick={() => this.expandCorrelationTab()}>
                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                    <div className="text-white">
                                        <span className={`far ${this.state.expandCorrelationSection || (!this.state.correlationData || !this.state.correlationData.length) ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>
                                        Correlation
                                    </div>
                                    {this.state.observationLoader ?
                                        <Spinner className='text-center' color='white' size='sm' />
                                    :
                                        <div className="text-info">Has Correlation(s) with {!this.state.correlationData || !this.state.correlationData.length ? "No" : this.state.correlationData.length} metrics</div>
                                    }
                                </div>
                            </div>
                            <div id={'collapse_event'} className={`collapse ${this.state.expandCorrelationSection ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                <div className="card-body">
                                    {this.state.observationLoader ?
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='white' size='lg' />
                                        </div>
                                    :
                                        <div className="col-sm-12">
                                            {this.state.correlationData && this.state.correlationData.length ?
                                                this.state.correlationData.map((item, i) => {
                                                    return(
                                                        <div className="row">
                                                            <div className={`${this.state.selectedCorrelationMetrics && this.state.selectedCorrelationMetrics.includes(i) ? "col-sm-9 pl-0" : "col-sm-12 p-0"}`}>
                                                                {item.metrics && Object.entries(item.metrics).length ?
                                                                    <div className={`${this.state.selectedCorrelationMetrics && this.state.selectedCorrelationMetrics.includes(i) ? "bg-muted p-2 mb-2" : ""} ${i ? "pt-2" : ""}`}>
                                                                        <div className="d-flex justify-content-between displayNone">
                                                                            <div className="d-flex">
                                                                                <h7 className="text-white mb-0">{item.details.metric_name}</h7>
                                                                                <p className="text-primary-color f12 align-self-end mb-0 ml-2">
                                                                                    ({item.metrics.statistic})
                                                                                    {/* ({value.statistic+""+(value.additional_statistics && value.additional_statistics.length ?  " - "+value.additional_statistics[0] : "")}) */}
                                                                                </p>
                                                                            </div>
                                                                            <i className="fas fa-eye cursorPointer text-white" onClick={() => this.updatedSelectedMetrics("correlation", i)}></i>
                                                                        </div>
                                                                        {/* <p className="text-primary-color f12 align-self-end mb-2 w-100">
                                                                            The {item.source.metric_name} of {item.source.asset_name} is the probable cause for the {item.target.metric_name}. Score {item.relation.score}, with {item.relation.lag} lag in {item.relation.lag_time_in_minutes} minutes
                                                                        </p> */}
                                                                        <p className={`text-primary-color f12 align-self-end mb-0 pb-2 w-100`}>
                                                                            <i class={`far fa-${this.state.selectedCorrelationMetrics && this.state.selectedCorrelationMetrics.includes(i) ? "compress" : "expand-wide"} cursorPointer text-white mr-2`} onClick={() => this.updatedSelectedMetrics("correlation", i)}></i> The <span className="text-white">{item.source.metric_name}</span> of <span className="text-purple-2">{item.source.asset_name}</span> is the probable cause for the <span className="text-white">{item.target.metric_name}</span> of <span className="text-purple-2">{item.target.asset_name}</span> ({item.metrics.statistic}), its score {item.relation.score} with {item.relation.lag} lag(s) in {item.relation.lag_time_in_minutes} minutes
                                                                        </p>

                                                                        {this.state[item.details.metric_name+"_correlation_series_"+item.details.asset_id] && this.state[item.details.metric_name+"_correlation_series_"+item.details.asset_id].length && this.state[item.details.metric_name+"_correlation_options_"+item.details.asset_id] ?
                                                                            <div className={`cursorPointer transparentTooltip mb-n4 mt-n4 ${this.state.selectedCorrelationMetrics && this.state.selectedCorrelationMetrics.includes(i) ? "" : "displayNone"}`}>
                                                                                <Chart options={this.state[item.details.metric_name+"_correlation_options_"+item.details.asset_id]} series={this.state[item.details.metric_name+"_correlation_series_"+item.details.asset_id]} type="line" height={135}/>
                                                                            </div>
                                                                        : 
                                                                            <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                                <div className="align-self-center">
                                                                                    No Data
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            <div className={`${this.state.selectedCorrelationMetrics && this.state.selectedCorrelationMetrics.includes(i) ? "col-sm-3 p-0" : "displayNone"}`}>
                                                                <div class={`col-xs-12 verticalArrowDown bg-muted p-2 mb-2`}>
                                                                    <p class="text-center bg-info btn text-white center-block mb-0 w-100 p-0" id={"source_correlation_"+i}>
                                                                        {/* <span>{item.source.asset_name}</span> */}
                                                                        <p className="text-center w-100 mb-0">{item.source.metric_name}</p>
                                                                        <p className="text-center w-100 mb-0">{item.source.service_name}</p>
                                                                    </p>
                                                                    
                                                                    <UncontrolledTooltip placement='top' target={"source_correlation_"+i}>
                                                                        <div className="uncontrolledTooltipLeftAlign">
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span> {item.source.asset_name}</p>
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Metrics: </span> {item.source.metric_name}</p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Resources: </span>
                                                                                {item.source.provider ? item.source.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.source.account_id ? (' : ')+item.source.account_id : <span>&nbsp;</span>}
                                                                                {item.source.region ?  (' : ')+item.source.region : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Services: </span>
                                                                                {/* {item.source.resource_type ?  ('  ')+item.source.resource_type : <span>&nbsp;</span>} */}
                                                                                {item.source.service_name ?  (' ')+item.source.service_name : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>    
                                                                    </UncontrolledTooltip>
                                                                    <div class="d-flex">
                                                                        <p className="lineText w-45 mb-0 align-self-center">&nbsp;</p>
                                                                        <p class="vl mb-0" style={{height: "68px"}}><i class="arrow down w-45" style={{marginTop: "63px"}}></i></p>
                                                                        {/* <p className="lineText">Score {item.relation.score}, with {item.relation.lag} lag in {item.relation.lag_time_in_minutes} minutes</p> */}
                                                                        <p className="lineText mb-0 align-self-center">&nbsp;</p>
                                                                    </div>
                                                                    <p class="text-center bg-info btn text-white mt-2 mb-0 w-100 p-0" id={"target_correlation_"+i}>
                                                                        {/* <span>{item.target.asset_name}</span> */}
                                                                        <p className="text-center w-100 mb-0">{item.target.metric_name}</p>
                                                                        <p className="text-center w-100 mb-0">{item.target.service_name}</p>
                                                                    </p>
                                                                    <UncontrolledTooltip placement='top' target={"target_correlation_"+i}>
                                                                        <div className="uncontrolledTooltipLeftAlign">
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span> {item.target.asset_name}</p>
                                                                            <p className="mb-1 text-primary-color"><span className="text-white mr-1">Metrics: </span> {item.target.metric_name}</p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Resources: </span>
                                                                                {item.target.provider ? item.target.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                {item.target.account_id ? (' : ')+item.target.account_id : <span>&nbsp;</span>}
                                                                                {item.target.region ?  (' : ')+item.target.region : <span>&nbsp;</span>}
                                                                            </p>
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Services: </span>
                                                                                {/* {item.target.resource_type ?  ('  ')+item.target.resource_type : <span>&nbsp;</span>} */}
                                                                                {item.target.service_name ?  (' ')+item.target.service_name : <span>&nbsp;</span>}
                                                                            </p>
                                                                        </div>    
                                                                    </UncontrolledTooltip>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        </React.Fragment>
                    : null}
                </div>
            </div>
        )
	}
}

/**
 * Type of the props used in the component
 */
ObservationTab.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        caseAssetPageFilter: state.aiops.aiopsPropsDetails.caseAssetPageFilter ? state.aiops.aiopsPropsDetails.caseAssetPageFilter : {},
        caseAssetAlertsList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.caseAssetAlertsList ? state.aiops.aiopsPropsDetails.caseAssetAlertsList : {},
    }
}

export default connect(mapStateToProps, {
    getMonitoringMetricsByTrend,
    getCauseAndImpactedMetrics,
    getPeakDipDetectionResults,
    setAiopsPropsDetails,
})(withRouter(ObservationTab))