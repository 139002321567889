/*************************************************
 * Tvastar
 * @exports
 * @file detectorsAction.js
 * @author Rajasekar // on 27/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'
import { Auth } from 'aws-amplify'
import axios from 'axios'

import {
	DETECTORS,
	GET_TOTAL_COUNT,
	GET_DAY_WISE_COUNT,
	GET_ALL_IDENTIFIERS_RESULTS,
	GET_LATEST_IDENTIFIER_RESULTS,
	GET_AGGREGATED_IDENTIFIER_RESULTS,

	MONITORING,
	GET_MONITORING_SNAPSHOT,

	BILLING,
	GET_BILLING_SNAPSHOT,

	CONFIGURATION,
	GET_CONFIGURATION,
	

	DETECTORS_END_POINT,
	RESOLVE_ISSUE,
	
	SYNCHRONIZER,
	GET_TOP_EVENTS,
	GET_RECENT_EVENTS,
	GET_EVENTS_GRAPH_COUNT,
	GET_ASSET_DETAIL,
	LIST_ASSETS,
	LIST_COVERED_ASSETS,
	GET_COVERED_SERVICES,
	GET_AGGREGATED_ACCOUNTS_BY_REGION,
	GET_TOTAL_ASSET_COUNT,
	GET_DAILY_ASSET_AVAILABILITY,
	GET_EVENT_INFO,
	GET_EVENT_BY_BOUNDARY,
	GET_SERVICE_QUOTA_DETAILS,
} from '../../config'
import { myLog, momentTime, momentDate, convertBytes, convertSeconds, convertBits, countFormater } from '../../utils/utility'
import { ACTION_TYPES } from '../types'


/**
 * Action to get all detectors list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllDetectors = (body, callback) => {
	return async dispatch => {
		try {
			const detectorsList = await API.post(DETECTORS, GET_ALL_IDENTIFIERS_RESULTS, { body })
			dispatch({
				type: ACTION_TYPES.SET_DETECTORS_LIST,
				payload: detectorsList,
			})
			callback(true)
		} catch (error) {
			myLog('detectorsList', error)
			dispatch({
				type: ACTION_TYPES.SET_DETECTORS_LIST,
				payload: {},
			})
			callback(false)
		}
	}
}

/**
 * Action to get detectors related issues
 * @param {Object} body
 * @param {Function} callback
 */
export const getRelatedIssues = (body, callback) => {
	return async () => {
		try {
			const relatedIssue = await API.post(DETECTORS, GET_ALL_IDENTIFIERS_RESULTS, { body })
			callback(true, relatedIssue.results)
		} catch (error) {
			callback(false)
			myLog('relatedIssue', error)
		}
	}
}

/**
 * Action to get total count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalCount = (body, callback) => {
	return async () => {
		try {
			const totalCount = await API.post(DETECTORS, GET_TOTAL_COUNT, { body })
			callback(true, totalCount)
		} catch (error) {
			myLog('totalCount', error)
			callback(false)
		}
	}
}
/**
 * Action to get day wise count
 * @param {Object} body
 * @param {Function} callback
 */
export const getDayWiseCount = (body, callback) => {
	return async () => {
		try {
			const totalCount = await API.post(DETECTORS, GET_DAY_WISE_COUNT, { body })
			callback(true, totalCount)
		} catch (error) {
			callback(false)
			myLog('daywiseCount', error)
		}
	}
}
/**
 * Action to get monitoring snapshot
 * @param {Object} body
 */
export const getMonitoringSnapShot = (body, dayType, callback) => {
	return async () => {
		try {
			const monitoringSnapShot = await API.post(MONITORING, GET_MONITORING_SNAPSHOT, { body })	
			let object = {}
			if(Object.entries(monitoringSnapShot).length) {
				Object.entries(monitoringSnapShot).length && Object.entries(monitoringSnapShot).forEach(([subKey,subValue], index) => {

					if(subKey !== 'request') {
						let totalArray = []		
						let data = []
						let label = []
						if(subValue.metrics) {
							subValue.metrics.forEach(metric => {
								data.push(metric.Value)
								if(dayType === 'h') {
									label.push(momentTime(metric.Timestamp))
								} else {
									label.push(momentDate(metric.Timestamp, 'DD MMM'))
								}
							})
							// label = ['10 DEC', '11 DEC', '12 DEC', '13 DEC', '14 DEC', '15 DEC', '16 DEC', '17 DEC', '18 DEC', '19 DEC']
							// data = [0, 15, 20, 5, 8, 10, 0, 12, 20, 6]
							// alert(subValue.details.unit)
							subValue.metricsChart = monitoringApexMetrics(subKey, data, label, subValue.details.unit, index)
						}
						totalArray.push(subValue)
						object[subKey] = totalArray
					}
				})
			}
			callback(object)
		} catch (error) {
			myLog('monitoringSnapShot', error)
			callback(false)
		}
	}
}

const monitoringApexMetrics = (metric, data, label, unit, index) => {		
	if(unit === 'Bytes/second') { unit = 'Bytes' } 
	else if(unit === 'Kilobytes/second') { unit = 'Kilobytes' } 
	else if(unit === 'Megabytes/second') { unit = 'Megabytes' } 
	else if(unit === 'Gigabytes/second') { unit = 'Gigabytes' } 
	else if(unit === 'Terabytes/second') { unit = 'Terabytes' }
	
	if(unit === 'Bits /second') { unit = 'Bits' } 
	else if(unit === 'Kilobits/second') { unit = 'Kilobits' } 
	else if(unit === 'Megabits/second') { unit = 'Megabits' } 
	else if(unit === 'Gigabits/second') { unit = 'Gigabits' } 
	else if(unit === 'Terabits/second') { unit = 'Terabits' }

	if(unit === 'Count/second') { unit = 'Count'}

	let graphLabel = []
	let createdGraphData = []
	// let modifiedGraphData = []
	let fontColors = []
	let startEndLabels = []
	if(label.length) {
		label.forEach((item, x) => {
			graphLabel.push(item)
			if(label.length > 5) {
				let length = label.length - 2
				let interval = parseInt(length / 3)
				let second = interval
				let third =  interval * 2
				let fourth = interval * 3
				if(fourth > label.length - 1) {
					if(x === fourth) {
						startEndLabels.push(item)
					}
				}

				if(x === 0 || x === second || x === third || x === fourth || x === (label.length - 1)) {
					startEndLabels.push(item)
				}
			} else {
				startEndLabels.push(item)
			}
		})
		let labelType = ''
		let data1 = []	
		data.forEach((item, x) => {
			createdGraphData.push(item)
			let dataItems1 = {}
			dataItems1.x = label[x]
			//dataItems1.y  = parseFloat(item,2)
			
			if(unit === 'Percent') {
				labelType = '%'
				item = parseFloat(item).toFixed(2) + '%';
			} else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes') {
				item = convertBytes(item, unit)
				labelType = item.split(' ')[item.split(' ').length - 1]
			} else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds') {
				item = convertSeconds(item, unit)
				labelType = item.split(' ')[item.split(' ').length - 1]
			} else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
				item = convertBits(item, unit)
				labelType = item.split(' ')[item.split(' ').length - 1]
			} else if(unit === 'Count') {
				item = countFormater(item)
				labelType = item.split(' ')[item.split(' ').length - 1]
			} else {
				item = parseInt(item)
			}
			dataItems1.y = item.toString()
			data1.push(dataItems1)

			fontColors.push('#B8BBBE')
		})

		let series = []
		let dataRow = {}
		dataRow.data = data1
		dataRow.name = metric
		dataRow.labels = graphLabel
		series.push(dataRow)
		let options = {
			chart: {
				id:'monitoring_'+index,
				group:'monitoring',
				height: 100,
				type: 'area',
				zoom: {
					enabled: false
				},
				sparkline: {
					enabled: false
				},
				toolbar: {
					show: false,
				}
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'butt',
				colors: undefined,
				width: 2,
				dashArray: 0,      
			},
			grid: {
				borderColor: '#343947',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    },  
                },   
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    }
                },
				padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -2
				}
			},			
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: fontColors
					},
					formatter: function(value) {
						if(unit === 'Percent') {
							return parseFloat(value).toFixed(2) + '%';
						} else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes') {
							return convertBytes(value, unit)
						} else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds') {
							return convertSeconds(value)
						} else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
							return convertBits(value, unit)
						} else if(unit === 'Count') {
							return countFormater(value, unit)
						} else {
							return parseInt(value)
						}
					}
				},
				axisBorder: {
                    show: true,
                    // color:'#434959',
                    color: '#343947',
                    widht: 2,
                    offsetX: -15,
                    // offsetY: 0
                },
                axisTicks: {
                    show: false
                },
				tickAmount: 2
			},
			labels: graphLabel,
			xaxis: {
				Categories: [graphLabel],
				tickAmount: 'dataPoints',
				show: true,
				// range: 7,
				labels: {
					rotate: 0,
					show: true,
					formatter: function(value) {
						if(value === startEndLabels[0] || value === startEndLabels[1] || value === startEndLabels[2] || value === startEndLabels[3] || value === startEndLabels[4]) {
							return value
						}
					},
					style: {
						colors: fontColors,
						fontSize: '9px'

					},
				},
				axisTicks: {
					show: false,
				},				
				axisBorder: {
                    show: true,
                    color: '#343947',
                    widht: 2,
                },
				tooltip: {
					enabled: false
				},
				// min: min,
				// max: max,
			},
			colors: ['#775BA2'],
			fill: {
                type: "gradient",
                gradient: {
                    // shadeIntensity: 1,
                    // opacityFrom: 0.7,
                    // opacityTo: 0.9,
                    // stops: [0, 90, 100]
                    gradientToColors: [ '#775BA2'],
                    shadeIntensity: 0,
                    opacityFrom: .3,
                    opacityTo: 1,
                    stops: [50, 90]
                }
            },
			tooltip: {
				enabled: true,
				enabledOnSeries: false,
				shared: false,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					let val  = series[seriesIndex][dataPointIndex] + ' '+ labelType
					//let val  = series[seriesIndex][dataPointIndex];
					let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
					//let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
					//val = Math.abs(Math.round(val))
					let name = w.globals.initialSeries[0].name
					//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
					return '<div class="arrow_box"> ' +  labelName + ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + name +' '+ val +'</span> </div>'
				},
				fillSeriesColor: false,
				theme: false,
				style: {
				fontSize: '12px',				
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				x: {
					show: false,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: false,
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				marker: {
					show: false,
				}
			}
		}

		let graph = {}
		graph.series = series
		graph.options = options

		return graph;
	}
	
}

/**
 * Action to get billing snapshot
 * @param {Object} body
 */
export const getBillingSnapshot = (body, callback) => {
	return async () => {
		try {
			const billingSnapShot = await API.post(BILLING, GET_BILLING_SNAPSHOT, { body })

			callback(billingSnapShot)
		} catch (error) {
			callback(false)
			myLog('billingSnapShot', error)
		}
	}
}
/**
 * Action to get event top details
 * @param {Object} body
 */
export const getTopEvents = (body, callback) => {
	return async () => {
		try {
			const topEvents = await API.post(SYNCHRONIZER, GET_TOP_EVENTS, { body })

			callback(true, topEvents)
		} catch (error) {
			callback(false)
			myLog('topEvents', error)
		}
	}
}
/**
 * Action to get event recent details
 * @param {Object} body
 */
export const getRecentEvents = (body, callback) => {
	return async () => {
		try {
			const recentEvents = await API.post(SYNCHRONIZER, GET_RECENT_EVENTS, { body })
			myLog('recentEvents', recentEvents)
			callback(true, recentEvents)
		} catch (error) {
			callback(false)
			myLog('recentEvents', error)
		}
	}
}

/**
 * Action to get event graph details
 * @param {Object} body
 */
export const getEventGraphCounts = (body, callback) => {
	return async () => {
		try {
			const graphEventCounts = await API.post(SYNCHRONIZER, GET_EVENTS_GRAPH_COUNT, { body })

			callback(true, graphEventCounts)
		} catch (error) {
			callback(false)
			myLog('graphEventCounts', error)
		}
	}
}

/**
 * Action to get configuration
 * @param {Object} body
 */
export const getConfiguration = body => {
	return async () => {
		try {
			const configuration = await API.post(CONFIGURATION, GET_CONFIGURATION, { body })
			myLog('configuration', configuration)
		} catch (error) {
			myLog('configuration', error)
		}
	}
}
/**
 * Action to get asset details
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetDetails = (body, callback) => {
	return async () => {
		try {
			const assetDetails = await API.post(SYNCHRONIZER, GET_ASSET_DETAIL, { body })

			callback(true, assetDetails)
		} catch (error) {
			myLog('assetDetails', error)
			callback(false)
		}
	}
}

/**
 * Action to get asset details
 * @param {Object} body
 * @param {Function} callback
 */
export const listAssets = (body, callback) => {
	return async () => {
		try {
			const listAssets = await API.post(SYNCHRONIZER, LIST_ASSETS, { body })

			callback(true, listAssets)
		} catch (error) {
			myLog('listAssets', error)
			callback(false)
		}
	}
}

/**
 * Action to list-covered-assets
 * @param {Object} body
 * @param {Function} callback
 */
 export const listCoveredAssets = (body, callback) => {
	return async () => {
		try {
			const listAssets = await API.post(SYNCHRONIZER, LIST_COVERED_ASSETS, { body })

			callback(true, listAssets)
		} catch (error) {
			myLog('listAssets', error)
			callback(false)
		}
	}
}

/**
 * Action to get-covered-services
 * @param {Object} body
 * @param {Function} callback
 */
 export const getCoveredServices = (body, callback) => {
	return async () => {
		try {
			const listAssets = await API.post(SYNCHRONIZER, GET_COVERED_SERVICES, { body })

			callback(true, listAssets)
		} catch (error) {
			myLog('listAssets', error)
			callback(false)
		}
	}
}

/**
 * Action to get-aggregated-accounts-by-region
 * @param {Object} body
 * @param {Function} callback
 */
 export const getAggregatedAccountsByRegion = (body, callback) => {
	return async () => {
		try {
			const listAssets = await API.post(SYNCHRONIZER, GET_AGGREGATED_ACCOUNTS_BY_REGION, { body })

			callback(true, listAssets)
		} catch (error) {
			myLog('listAssets', error)
			callback(false)
		}
	}
}

/**
 * Action to getTotalAssetCount
 * @param {Object} body
 * @param {Function} callback
 */
 export const getTotalAssetCount = (body, callback) => {
	return async () => {
		try {
			const listAssets = await API.post(SYNCHRONIZER, GET_TOTAL_ASSET_COUNT, { body })

			callback(true, listAssets)
		} catch (error) {
			myLog('listAssets', error)
			callback(false)
		}
	}
}


/**
 * Action to get daily asset availability details
 * @param {Object} body
 * @param {Function} callback
 */
export const getDailyAssetAvailability = (body, callback) => {
	return async () => {
		try {
			const dailyAssetDetails = await API.post(SYNCHRONIZER, GET_DAILY_ASSET_AVAILABILITY, { body })

			callback(true, dailyAssetDetails)
		} catch (error) {
			myLog('dailyAssetDetails', error)
			callback(false)
		}
	}
}


/**
 * Action to get close issue
 * @param {Object} body
 * @param {Function} callback
 */
export const resolveIssue = (body, callback) => {
	return async dispatch => {
		try {
			const url = DETECTORS_END_POINT + RESOLVE_ISSUE;
			const resolveIssue = await axios.post(url, body, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
				}
			})
			callback(true, resolveIssue)
		} catch (error) {
			let respone = {}
			respone.message = error.response.data.message
			respone.status = error.response.data.status
			callback(false, respone)
		}
	}
}

export const getEventInfo = (body, callback) => {
	return async () => {
		try {
			const getEventInfo = await API.post(SYNCHRONIZER, GET_EVENT_INFO, { body })
			callback(true, getEventInfo)
		} catch (error) {
			myLog('getEventInfo', error)
			callback(false)
		}
	}
}

export const getEventByBoundary = (body, callback) => {
	return async () => {
		try {
			const getEventByBoundary = await API.post(SYNCHRONIZER, GET_EVENT_BY_BOUNDARY, { body })
			callback(true, getEventByBoundary)
		} catch (error) {
			myLog('getEventByBoundary', error)
			callback(false)
		}
	}
}

export const getServiceQuotaDetails = (body, callback) => {
	return async () => {
		try {
			const response = await API.post(SYNCHRONIZER, GET_SERVICE_QUOTA_DETAILS, { body })
			callback(true, response)
		} catch (error) {
			myLog('response', error)
			callback(false)
		}
	}
}

/**
 * Action to get-aggregated-identifier-results
 * @param {Object} body
 * @param {Function} callback
 */
 export const getAggregatedIdentifierResults = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(DETECTORS, GET_AGGREGATED_IDENTIFIER_RESULTS, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}

/**
 * Action to get-lastest-identifier-results
 * @param {Object} body
 * @param {Function} callback
 */
 export const getLatestIdentifierResults = (body, callback) => {
	return async dispatch => {
		try {
			const response = await API.post(DETECTORS, GET_LATEST_IDENTIFIER_RESULTS, { body })
			callback(true, response)
		} catch (error) {
			callback(false)
		}
	}
}