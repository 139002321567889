/*************************************************
 * Tvastar
 * @exports
 * @file DataEventsProcessor.js
 * @author Prakash // on 09/09/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { restrictCharacter } from '../../../../utils/utility'
import {
    getDataEventsProcessorBaseParams,
    insertMsc,
    updateMsc,
} from '../../../../actions/dlp/DlpJobsAction'
import { Spinner } from 'reactstrap'

class DataEventsProcessor extends Component {
    hierarichalBucketBarChartRef = React.createRef()
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            showLoading: true,
            bucketArray: [0],
            eventsBucketArray: [0],
        }
    }

    componentDidMount = () => {        
        this.getDataEventsProcessorBaseParams()
        if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
            let selectedRecord = this.props.selectedRecord
            let queue_name = selectedRecord.dlp_data_events_processor_config.queue_name

            let mandatory = selectedRecord.mandatory
            let eventsBucketArray = []
            if(selectedRecord.bucket) {
                selectedRecord.bucket.forEach((item, index) => {
                    eventsBucketArray.push(index)
                    this.setState({ ['events_bucket_name_'+index]: item.bucket_name, ['events_prefix_name_'+index]: item.prefix })
                })
            } else {
                eventsBucketArray.push(0)
            }

            let bucketArray = []
            if(selectedRecord.dlp_data_events_processor_config) {
                selectedRecord.dlp_data_events_processor_config.bucket.forEach((item, index) => {
                    bucketArray.push(index)
                    this.setState({ ['bucket_name_'+index]: item.bucket_name, ['prefix_name_'+index]: item.prefix })
                })
            } else {
                bucketArray.push(0)
            }

            let bucket_folder_name = ''
            let prefix_folder_name = ''
            if(selectedRecord.dlp_data_events_processor_config.storageProperties) {
                let item = selectedRecord.dlp_data_events_processor_config.storageProperties
                prefix_folder_name = item.config && item.config.folder && item.config.folder.type ? item.config.folder.type : ''
                bucket_folder_name = item.destination && item.destination.bucket_name ? item.destination.bucket_name : ''
            }

            this.setState({ queue_name, bucketArray, eventsBucketArray, mandatory, bucket_folder_name, prefix_folder_name })
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.dlpPropsDetails && typeof this.props.dlpPropsDetails.hasJobError !== 'undefined' &&  prevProps.dlpPropsDetails.hasJobError !== this.props.dlpPropsDetails.hasJobError) {
            this.setState({ hasError: true })
        }
    }

    getDataEventsProcessorBaseParams = () => {
        let params = {}
        this.props.getDataEventsProcessorBaseParams(params, (promise, response) => {
            this.setState({ masterData: response, showLoading: false })
        })
    }

    onChangePropsEvent = () => {
        let hasError = false
        if(!this.state.queue_name || this.state.queue_name === '') {
            hasError = true
        }

        let checkBucketDataExist = 0
        this.state.bucketArray.forEach(item => {
            if(this.state['bucket_name_'+item] === '' && this.state['prefix_name_'+item] !== '') {
                hasError = true
            } else if(this.state['bucket_name_'+item] !== '') {
                checkBucketDataExist++
            }
        })
        
        if(!checkBucketDataExist) {
            hasError = true
        }

        let checkEventsBucketDataExist = 0
        this.state.eventsBucketArray.forEach(item => {
            if(this.state['events_bucket_name_'+item] === '' && this.state['events_prefix_name_'+item] !== '') {
                hasError = true
            } else if(this.state['events_bucket_name_'+item] !== '') {
                checkEventsBucketDataExist++
            }
        })
        
        if(!checkEventsBucketDataExist) {
            hasError = true
        }
        
        if(this.state.bucket_folder_name !== '' && this.state.prefix_folder_name === '') {
            hasError = true
        } else if(this.state.bucket_folder_name === '' && this.state.prefix_folder_name !== '') {
            hasError = true
        } else if(this.state.bucket_folder_name === '' && this.state.prefix_folder_name === '') {
            hasError = true
        } else if(!this.state.bucket_folder_name || !this.state.prefix_folder_name) {
            hasError = true
        }

        let data = {}
        data.mandatory = this.props.pageType === 'create' ? true : this.state.mandatory
        let jobText = ' for buckets'
        let eventBuckets = []
        this.state.eventsBucketArray.forEach(item => {
            if(this.state['events_bucket_name_'+item] !== '') {
                let dataRow = {}
                dataRow.bucket_name = this.state['events_bucket_name_'+item]
                dataRow.prefix = this.state['events_prefix_name_'+item] ? this.state['events_prefix_name_'+item] : ''
                eventBuckets.push(dataRow)
                jobText += ' bucket name '+this.state['events_bucket_name_'+item]                    
                if(this.state['events_prefix_name_'+item] && this.state['events_prefix_name_'+item] !== '') {
                    jobText += ' prefix '+this.state['events_prefix_name_'+item]
                }
            }
        })
        data.bucket = eventBuckets
        
        let  config= {}
        config.queue_name = this.state.queue_name

        jobText += ' queue name '+this.state.queue_name
        
        jobText += ' from the cloudtrail log location'
        let buckets = []
        this.state.bucketArray.forEach(item => {
            if(this.state['bucket_name_'+item] !== '') {
                let dataRow = {}
                dataRow.bucket_name = this.state['bucket_name_'+item]
                dataRow.prefix = this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''
                buckets.push(dataRow)
                jobText += ' bucket name '+this.state['bucket_name_'+item]
                
                if(this.state['prefix_name_'+item] && this.state['prefix_name_'+item] !== '') {
                    jobText += ' prefix '+this.state['prefix_name_'+item]
                }
            }
        })

        config.bucket = buckets            
        
        if(this.state.masterData && this.state.masterData.static_fields) {
            Object.entries(this.state.masterData.static_fields).forEach(([key, value]) => {
                if(key !== 'dlp_data_events_processor_config') {
                    data[key] = value
                } else {
                    Object.entries(value).forEach(([key, value]) => {
                        config[key] = value
                    })
                }
            })
        } 

        jobText += ' store results in bucket '+this.state.bucket_folder_name+' prefix '+this.state.prefix_folder_name           

        let object = this.state.masterData.storageProperties
        
        let newObject = {}
        if(this.state.bucket_folder_name !== '' && this.state.prefix_folder_name !== '') {
            let config = Object.assign({}, object.config, { 
                folder: Object.assign({}, object.config.folder, {
                    type: this.state.prefix_folder_name
                })
            })
            let destinationsObject = Object.assign({}, object.destination, {
                bucket_name: this.state.bucket_folder_name 
            });

            newObject.config = config
            newObject.destination = destinationsObject
        }

        config.storageProperties = newObject

        data.dlp_data_events_processor_config = config

        this.props.jobData(data, jobText, hasError)
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeBucketSection = (item, i) => {
        this.setState({ ['bucket_name_'+item]: '', ['prefix_name_'+item]: '' },
            () => {
                let rowList = this.state.bucketArray;
                rowList.splice(i, 1);
                this.setState({ bucketArray: rowList })
            }
        );
    }

    removeEventsBucketSection = (item, i) => {
        this.setState({ ['events_bucket_name_'+item]: '', ['events_prefix_name_'+item]: '' },
            () => {
                let rowList = this.state.eventsBucketArray;
                rowList.splice(i, 1);
                this.setState({ eventsBucketArray: rowList })
            }
        );
    }

    render() {		
        return (
            !this.state.showLoading ?
                <div className={`${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>                                
                    <div className={`form-group dlpDescriptionFormWhite`}>
                        <p className="mb-0 mr-2 align-self-end text-purple mt-4">for the buckets</p>
                        {this.state.bucketArray.map((item, i) => {
                            return(
                                <div className="w-100">
                                    <div className="d-flex flex-wrap">
                                        <p className="mb-0 mr-2 align-self-end text-purple mt-3">Bucket</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state['bucket_name_'+item] || this.state['bucket_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                            value={this.state['bucket_name_'+item] ? this.state['bucket_name_'+item] : ''}
                                            onChange={e => this.setState({ ['bucket_name_'+item]: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                        />
                                        <p className="mb-0 mx-2 align-self-end text-purple mt-3">Prefix</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30`}
                                            value={this.state['prefix_name_'+item] ? this.state['prefix_name_'+item] : ''}
                                            onChange={e => this.setState({ ['prefix_name_'+item]: e.target.value }, () => this.onChangePropsEvent())}
                                        />
                                        {this.props.pageType.toLowerCase() !== 'view' && this.state.bucketArray.length > 1 ?
                                            <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeBucketSection(item, i)}></span>
                                        : null}
                                        {this.props.pageType.toLowerCase() !== 'view' && (this.state.bucketArray.length - 1) ===  i ?
                                            <span className={`far fa-plus cursor-pointer f18 align-self-end ml-4`} onClick={() => this.addSection('bucketArray')}></span>
                                        : null}
                                    </div>
                                </div>
                            )
                        })}

                        <div className={`d-flex flex-wrap mt-4`}>
                            <p className="mb-0 mr-2 align-self-end text-purple">Queue Name</p>
                            <input 
                                type="text" 
                                className={`inputField w-40 ${this.state.hasError && (!this.state.queue_name || this.state.queue_name === '') ? 'dottedDangerTextboxLine' : ''} `}
                                value={this.state.queue_name ? this.state.queue_name : ''}
                                onChange={e => this.setState({ queue_name: e.target.value }, () => this.onChangePropsEvent())}
                            />
                        </div>

                        <p className="mb-0 mr-2 align-self-end text-purple mt-4">from the cloudtrail log location</p>
                        {this.state.eventsBucketArray.map((item, i) => {
                            return(
                                <div className="w-100">
                                    <div className="d-flex flex-wrap">
                                        <p className="mb-0 mr-2 align-self-end text-purple mt-3">Bucket</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state['events_bucket_name_'+item] || this.state['events_bucket_name_'+item] === '') ? 'dottedDangerTextboxLine' : ''}`}
                                            value={this.state['events_bucket_name_'+item] ? this.state['events_bucket_name_'+item] : ''}
                                            onChange={e => this.setState({ ['events_bucket_name_'+item]: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                        />
                                        <p className="mb-0 mx-2 align-self-end text-purple mt-3">Prefix</p>
                                        <input 
                                            type="text" 
                                            className={`inputField mt-3 w-30`}
                                            value={this.state['events_prefix_name_'+item] ? this.state['events_prefix_name_'+item] : ''}
                                            onChange={e => this.setState({ ['events_prefix_name_'+item]: e.target.value }, () => this.onChangePropsEvent())}
                                        />
                                        {this.props.pageType.toLowerCase() !== 'view' && this.state.eventsBucketArray.length > 1 ?
                                            <span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeEventsBucketSection(item, i)}></span>
                                        : null}
                                        {this.props.pageType.toLowerCase() !== 'view' && (this.state.eventsBucketArray.length - 1) ===  i ?
                                            <span className={`far fa-plus cursor-pointer f18 align-self-end ml-4`} onClick={() => this.addSection('eventsBucketArray')}></span>
                                        : null}
                                    </div>
                                </div>
                            )
                        })}

                        <p className={`mb-0 mr-2 text-purple mt-4`}>Store results in</p>

                        <div className="w-100">                                                        
                            <div className="d-flex flex-wrap">
                                <p className="mb-0 mr-2 align-self-end text-purple mt-3">Bucket</p>
                                <input 
                                    type="text" 
                                    className={`inputField mt-3 w-30  ${this.state.hasError && (!this.state.bucket_folder_name || this.state.bucket_folder_name === '') ? 'dottedDangerTextboxLine' : ''}`}
                                    value={this.state.bucket_folder_name ? this.state.bucket_folder_name : ''}
                                    onChange={e => this.setState({ bucket_folder_name: restrictCharacter(e.target.value, '/') }, () => this.onChangePropsEvent())}
                                />
                                <p className="mb-0 mx-2 align-self-end text-purple mt-3">Prefix</p>
                                <input 
                                    className={`inputField mt-3 w-30 ${this.state.hasError && (!this.state.prefix_folder_name || this.state.prefix_folder_name === '') ? 'dottedDangerTextboxLine' : ''}`}
                                    value={this.state.prefix_folder_name ? this.state.prefix_folder_name : ''}
                                    onChange={e => this.setState({ prefix_folder_name: e.target.value }, () => this.onChangePropsEvent())}
                                />
                            </div>
                        </div>
                    </div>
                    {this.props.pageType !== 'create' ?
                        <div style={{ display: "inline" }} className="mr-2 align-self-end">
                            <div className="form-check">
                                <input type="checkbox"
                                    onChange={() => this.setState({ mandatory: !this.state.mandatory }, () => this.onChangePropsEvent())}
                                    className="form-check-input"
                                    checked={this.state.mandatory}
                                />
                                <p className="form-check-label mb-0 mr-2 text-purple">Enable</p>
                            </div>
                        </div>
                    : null}
                </div>
            :
                <div className='text-center mt-5' >
                    <Spinner color='white' size='lg' />
                </div>
        )
    }
}

const mapStateToProps = state => {
    return {
		dlpPropsDetails: state.dlp.dlpPropsDetails
    }
}

export default connect(mapStateToProps, {
    getDataEventsProcessorBaseParams,
    insertMsc,
    updateMsc,
})(withRouter(DataEventsProcessor))