/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, addHourGetDate, convertBytes, convertSeconds, countFormater, convertBits } from '../../../utils/utility'

import Chart from 'react-apexcharts'

class EventMetricList extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        this.timer = null;
        
        this.state = {
            activeSideTopTab: 'InstanceMetrics',
            showSreMetrics: false,

            showAnnotation: true,
        }
    }

    componentDidMount = () => {
        if(this.props.eventMonitoringList && Object.entries(this.props.eventMonitoringList).length){
            if(this.props.eventMonitoringList.hasOwnProperty(this.props.eventPageDetails.assetName)) {
                this.setState({ metricsByTrend: [] },
                    () => {
                        this.setState({ 
                            metricsByTrend: this.props.eventMonitoringList[this.props.eventPageDetails.assetName],
                            alertEventTrend: this.props.eventMonitroingTrendList,
                            loadingMonMetrics: true,
                        },
                            () => {
                                this.metricsListGraph()
                                this.newEventChart()
                            }
                        )
                    }
                )
            }
            // if(this.props.eventPageDetails && this.props.eventPageDetails.assets) {
            //     let assetName = ''
            //     this.props.eventPageDetails.assets.map(item => {
            //         console.log(this.props.assetsList)
            //         let filteredAasset = this.props.assetsList.filter(arr => arr.asset_name === item.asset_name)
            //         if(filteredAasset.length) {
            //             assetName = item.asset_name
            //         }
            //     })
            //     if(this.props.eventMonitoringList.hasOwnProperty(assetName)) {
            //         this.setState({ 
            //             assetName,
            //             metricsByTrend: this.props.eventMonitoringList[assetName],
            //             showSreMetrics: true
            //         },
            //             () => this.metricsListGraph()
            //         )
            //     }
            // }
        }
        if(this.props.eventMonitroingTrendList && Object.entries(this.props.eventMonitroingTrendList).length){
            this.setState({ 
                alertEventTrend: this.props.eventMonitroingTrendList,
                showSreMetrics: true
            },
                () => this.newEventChart()
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.eventMonitoringList) {
            if(prevProps.eventMonitoringList !== this.props.eventMonitoringList) {
                if(this.props.eventMonitoringList.hasOwnProperty(this.props.eventPageDetails.assetName)) {
                    this.setState({ metricsByTrend: [] },
                        () => {
                            this.setState({ 
                                metricsByTrend: this.props.eventMonitoringList[this.props.eventPageDetails.assetName],
                                alertEventTrend: this.props.eventMonitroingTrendList,
                                loadingMonMetrics: true,
                            },
                                () => {
                                    this.metricsListGraph()
                                    this.newEventChart()
                                }
                            )
                        }
                    )
                } 
            }
        }
    }
    
    metricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                Object.entries(graphData).forEach(([key, item], i) => {
                    let array = []
                    let series = []
                    let options = {}
                    if(item.values && item.values.length) {
                        item.values.forEach((val, j) => {
                            let arrayValues = []
                            arrayValues.push(momentConvertionUtcToLocalTime(graph.dates[j], 'YYYY-MM-DD HH:mm:ss'))
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            arrayValues.push(val)
                            array.push(arrayValues)
                        })

                        if(array.length === 1) {
                            let arrayValues = []
                            arrayValues.push(addHourGetDate(momentConvertionUtcToLocalTime(graph.dates[0], 'YYYY-MM-DD HH:mm:ss'), 0.1))
                            arrayValues.push(0)
                            array.push(arrayValues)
                        }

                        series = [{
                            data: array
                        }]

                        let xaxisAnnotation = []
                        if(this.state.showAnnotation) {
                            xaxisAnnotation = [{
                                x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                                strokeDashArray: 0,
                                borderColor: '#FF6F00',
                                label: {
                                    borderColor: '#FF6F00',
                                    style: {
                                        // fontSize: '12px',
                                        color: '#fff',
                                        background: '#FF6F00'
                                    },
                                    orientation: 'horizontal',
                                    text: '',
                                }
                            },
                            {
                                x: new Date(momentConvertionUtcToLocalTime(this.props.eventPageDetails.event_time, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                                strokeDashArray: 0,
                                borderColor: '#039BE5',
                                label: {
                                    borderColor: '#039BE5',
                                    style: {
                                        // fontSize: '12px',
                                        color: '#fff',
                                        background: '#039BE5'
                                    },
                                    orientation: 'horizontal',
                                    text: '',
                                }
                            },
                            // {
                            //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.last_event, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                            //     strokeDashArray: 0,
                            //     borderColor: '#FF6F00',
                            //     label: {
                            //         borderColor: '#FF6F00',
                            //         style: {
                            //             // fontSize: '12px',
                            //             color: '#fff',
                            //             background: '#FF6F00'
                            //         },
                            //         orientation: 'horizontal',
                            //         text: 'CE',
                            //     }
                            // }
                            ]
                        }

                        let annotations = {
                            xaxis: xaxisAnnotation
                        }

                        options = {
                            chart: {
                                // group: 'metrics_and_sre',
                                // id:'metrics_'+i,
                                type: 'area',
                                width: '100%',
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                sparkline: {
                                    enabled: false
                                }
                            },
                            stroke: {
                                show: true,
                                curve: 'smooth',
                                lineCap: 'butt',
                                width: 2,
                                dashArray: 0,      
                            },
                            colors: ['#775BA2'],
                            fill: {
                                type: "gradient",
                                gradient: {
                                    // shadeIntensity: 1,
                                    // opacityFrom: 0.7,
                                    // opacityTo: 0.9,
                                    // stops: [0, 90, 100]
                                    gradientToColors: ['#775BA2'],
                                    shadeIntensity: 0,
                                    opacityFrom: .3,
                                    opacityTo: 1,
                                    stops: [30, 90]
                                }
                            },
                            tooltip: {
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                    dateLable = dateLable.toString()
                                    dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                    let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                    return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                                },
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false,
                                },
                                y: {
                                    show: false
                                },        
                                marker: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#60687C'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            yaxis: {
                                show: true,
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                // tickAmount: 2,
                                min: 0,
                                // max: yaxisMaxValue, 
                                tickAmount: 1,
                                labels: {
                                    offsetX: -10,
                                    // minWidth: 50,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    }
                                    // formatter: (value) => { 
                                    //     if(Number(value) === value && value % 1 === 0) {
                                    //         return Math.ceil(value)
                                    //     } else {
                                    //         return Math.ceil(value)
                                    //     }
                                    // },
                                    // offsetX: 0,
                                    // offsetY: 0,
                                }
                            },
                            grid: {
                                show: true,
                                borderColor: '#2D323E',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#FFF'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            annotations: annotations
                        }
                    }

                    this.setState({ ['metrics_series_'+index+'_'+i]: series, ['metrics_options_'+index+'_'+i]: options })
                })

                if(graph.total_anomaly_count) {
                    let array = []                    
                    let original_data = []
                    graph.total_anomaly_count.forEach((val, k) => {
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graph.dates[k], 'YYYY-MM-DD HH:mm:ss'))
                        arrayValues.push(val > 0 ? 5 : 0)
                        original_data.push(val)
                        array.push(arrayValues)
                    })
                    
                    let series = [{
                        name: 'Anomaly',
                        data: array,
                        original_data
                    }]
                    
                    let anomalyBarOptions = {
                        chart: {
                            type: 'bar',
                            sparkline: {
                                enabled: true
                            },
                            animations: {
                                enabled: false,
                            }
                        },
                        plotOptions: {
                            bar: {
                                barHeight: '100%',
                                columnWidth: '5%',        
                            },          
                        },
                        // stroke: {
                        //     // show: true,
                        //     // lineCap: 'butt', 
                        //     width: 1,
                        // },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                            }
                        },
                        colors: ['#F75E3F'],
                        tooltip: {
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false
                            },
                            y: {
                                show: false
                            },
                            marker: {
                                show: false
                            },
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                                let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm')
                                // let labelName = w.globals.initialSeries[0].event_id
    
                                return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ w.globals.colors +'"> '+ val +'</span></div>'
                            }
                        }
                    }
    
                    this.setState({ [graph.asset_id+'_anomaly_'+index]: series, ['anomaly_'+graph.asset_id+'_'+index]: anomalyBarOptions })
                }
                
            })
        }
    }    

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    
    newEventChart = () => {
        let graphDetails = this.state.alertEventTrend
        if(graphDetails) {
            let graphLabels = graphDetails.labels
            let graphData = graphDetails.data
            graphData && Object.entries(graphData).length && Object.entries(graphData).forEach(([key, value]) => {
                let eventSeries = []
                let data = []
                let graphDataRow = {}
                value.events && value.events.length && value.events.forEach((item, index) => {
                    let arrayValuesRow = []
                    arrayValuesRow.push(momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss'))
                    arrayValuesRow.push(item)
                    data.push(arrayValuesRow)
                })
                graphDataRow.data = data
                eventSeries.push(graphDataRow)

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    xaxisAnnotation = [{
                        x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: '',
                        }
                    },
                    {
                        x: new Date(momentConvertionUtcToLocalTime(this.props.eventPageDetails.event_time, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#039BE5', //775BA2 //e0ba0e
                        label: {
                            borderColor: '#039BE5',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#039BE5'
                            },
                            orientation: 'horizontal',
                            text: '',
                        }
                    },
                    // {
                    //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.last_event, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                    //     strokeDashArray: 0,
                    //     borderColor: '#FF6F00',
                    //     label: {
                    //         borderColor: '#FF6F00',
                    //         style: {
                    //             // fontSize: '12px',
                    //             color: '#fff',
                    //             background: '#FF6F00'
                    //         },
                    //         orientation: 'horizontal',
                    //         text: 'CE',
                    //     }
                    // }
                    ]
                }

                let annotations = {
                    xaxis: xaxisAnnotation
                }
                
                let eventOptions = {
                    chart: {
                        id: 'chart2',
                        //group: 'barBrushChart',
                        type: 'line',
                        height: 230,
                        sparkline: {
                            enabled: false
                        },
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        redrawOnParentResize: true
                    },
                    // colors: ['#039BE5'],
                    colors: ['#19B2C0'],
                    stroke: {
                        show: true,
                        curve: 'straight',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 2,
                        dashArray: 0,      
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false,
                            style: {
                                colors: '#60687C'
                            }
                        },
                        axisBorder: {
                            show: true,
                            color:'#434959',
                            widht: 2,
                            // offsetX: -15,
                            // offsetY: 0
                        },
                        tooltip: {
                            enabled: false,
                        }
                    },                    
                    yaxis: {
                        show: true,
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                            color: '#60687C'
                        },
                        tooltip: {
                            enabled: false
                        },
                        // tickAmount: 2,
                        min: 0,
                        // max: yaxisMaxValue, 
                        tickAmount: 1,
                        labels: {
                            offsetX: -10,
                            // minWidth: 50,
                            show: true,
                            style: {
                                fontSize: '9px',
                                colors: '#60687C'
                            },
                            formatter: (value) => { 
                                if(Number(value) === value && value % 1 === 0) {
                                    return Math.ceil(value.toFixed(1))
                                } else {
                                    return Math.ceil(value.toFixed(1))
                                }
                            }
                            // formatter: (value) => { 
                            //     if(Number(value) === value && value % 1 === 0) {
                            //         return Math.ceil(value)
                            //     } else {
                            //         return Math.ceil(value)
                            //     }
                            // },
                            // offsetX: 0,
                            // offsetY: 0,
                        }
                    },
                    tooltip: {
                        fixed: {
                            enabled: false
                        },
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: (seriesName) => seriesName,
                            }
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                            let val = w.globals.initialSeries[0].data[dataPointIndex][1]
                            let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'DD MMM YYYY HH:mm')

                            return '<div class="arrow_box">'+ dateLabel +'<span style="color:'+ w.globals.colors +'"> '+ val +'</span></div>'
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#fff'],
                                }
                            },  
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                        },                
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: -2
                        },
                    },
                    annotations: annotations
                }
                
                this.setState({ [key+'_eventSeries']: eventSeries, [key+'_eventOptions']: eventOptions })
            })
        }
    }
    
    render() {
        return (
            !this.state.showMetrcisLoading ?
                <React.Fragment>
                <div className={`bg-dark rounded p-3 mt-3`}>
                    <div className="d-flex justify-content-between mb-2">
                        <p className={`mb-2 ${this.props.textColor}`}><b>Events</b></p>
                        <div className="d-flex mr-2 align-self-center">
                            <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                            <p className="f12 mb-0">Case Time</p>
                        </div>
                    </div>
                    {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                        this.state.metricsByTrend.map(item => {
                            return (
                                <div className="mb-3">
                                    <div class="p-3 rounded bg-dark3">
                                        {this.state[item.asset_name+'_eventSeries'] && [item.asset_name+'_eventSeries'].length && this.state[item.asset_name+'_eventOptions'] ?
                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                <Chart options={this.state[item.asset_name+'_eventOptions']} series={this.state[item.asset_name+'_eventSeries']} type="line" height={120} width={"100%"}/>
                                            </div>
                                        : 
                                            <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                <div className='align-self-center'>
                                                    No Data
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    : 
                        <div className='d-flex justify-content-center m-4'>
                            No Event for the this criteria, Please adjust your filter to get the event details.
                        </div>
                    }
                </div>
                <div className={`bg-dark rounded p-3 mt-3`}>
                    <div className="d-flex justify-content-between mb-2">
                        <p className={`mb-0 w-75 ${this.props.textColor}`}>
                            <b>Key Metrics for 
                            {this.props.eventPageDetails && this.props.eventPageDetails.resourceType ? ' '+this.props.eventPageDetails.resourceType : ''}
                            {this.props.eventPageDetails && this.props.eventPageDetails.assetName ? ' '+this.props.eventPageDetails.assetName : ''}
                            </b>
                        </p> 

                        <div className="d-flex">
                            <div className="d-flex mr-2 align-self-center">
                                <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                                <p className="f12 mb-0">Case Time</p>
                            </div>
                            <div className="d-flex mr-2 align-self-center">
                                <p className="indicatoreBox bg-info mb-0 mr-2"></p>
                                <p className="f12 mb-0">Event</p>
                            </div>
                            <span className={`fa fa-expand-wide cursorPointer ${this.props.textColor} align-self-center`} 
                                onClick={() => {
                                    this.props.getMetricsDeatils(this.props.eventPageDetails && this.props.eventPageDetails.asset_name, this.props.eventPageDetails && this.props.eventPageDetails.asset_id)
                                    this.props.setAiopsPropsDetails('showMetricsList', false)
                                }
                            }></span>
                        </div>
                    </div>
                    <div className="p-3 rounded bg-dark3">
                        {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                             this.state.metricsByTrend.map((item, index) => {
                                return (
                                <div className="row">
                                    {Object.keys(item.data).map((key, i) => {
                                        return (
                                            key !== this.props.eventType ?
                                                <div className={`col-sm-6 mb-3`}>
                                                    <div class="p-3 border rounded">
                                                        <p class="text-white f16 p-1 mb-0">{key}</p>
                                                        {this.state['metrics_series_'+index+'_'+i] && this.state['metrics_series_'+index+'_'+i].length && this.state['metrics_options_'+index+'_'+i] ?
                                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                <Chart options={this.state['metrics_options_'+index+'_'+i]} series={this.state['metrics_series_'+index+'_'+i]} type="area" height={115}/>
                                                            </div>
                                                        : 
                                                            <div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
                                                                <div className='align-self-center'>
                                                                    No Data
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            : null
                                        )
                                    })}
                                </div>
                                )
                            })
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                No Metrics for the this criteria, Please adjust your filter to get the metric details.
                            </div>
                        }
                    </div>
                </div>
                </React.Fragment>
            : this.state.showMetrcisLoading ?
                <div className={`bg-dark rounded p-3 mt-3`}>
                    <p className={`mb-2 ${this.props.textColor}`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                </div>
            : 
                <div className={`bg-dark rounded p-3 mt-3`}>
                    <p className={`mb-2 ${this.props.textColor}`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        No Metrics for the this criteria, Please adjust your filter to get the metric details.
                    </div>
                </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
EventMetricList.propTypes = {
    setAiopsPropsDetails: PropTypes.func,
    getMetricsDeatils: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    // console.log('state', state)
    return {
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        eventPageDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.event_page_details ? state.aiops.aiopsPropsDetails.event_page_details : {},
        
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],

        eventMonitoringList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.eventMonitoringList ? state.aiops.aiopsPropsDetails.eventMonitoringList : {},
        eventMonitroingTrendList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.eventMonitroingTrendList ? state.aiops.aiopsPropsDetails.eventMonitroingTrendList : {},
    }
    
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails
})(withRouter(EventMetricList))