/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 22/02/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, capitalizeFirstLetter, twoDateTimeInterval, twoDateDiffrence, subHours, subDays, subMinutes } from '../../../utils/utility'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getEventsTotalCount, getImportantEventsDailyTrend, getEventsDailyTrend, getEventDetails, listEventsByAssets } from '../../../actions/aiops/EventAction'
import { getAiopsDailyTrendByAssets } from '../../../actions/aiops/MetricsAction'
import _ from 'lodash'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Chart from 'react-apexcharts'
import Sunburst from "sunburst-chart";

const data = {
    name: "Operations summary",
    color: "#17a2b8",
    description: 'Lorem ipsum',
    children: [
      {
        name: "Running",
        color: "#A88CCC",
        children: [
          {
            name: "Alarm",
            color: "#A88CCC",
            children: [
              {
                name: "Low",
                color: "#C5B2DD",
                size: 89
              },
              {
                name: "Medium",
                color: "#A88CCC",
                size: 10
              }
            ]
          },
          {
            name: "Operational",
            color: "#FFAE90",
            size: 63
          },
          {
            name: "Stand-by",
            color: "#EDD482",
            size: 18
          }
        ]
      },
      {
        name: "Stopped",
        color: "#FE93B4",
        children: [
          {
            name: "Fault",
            color: "#FE93B4",
            size: 10
          },
          {
            name: "Maintenence",
            color: "#FE93B4",
            size: 2
          },
          {
            name: "Alarm",
            color: "#FFB7CD",
            children: [
              {
                name: "Hight",
                color: "#FFB7CD",
                size: 2
              }
            ]
          }
        ]
      }
    ]
}

class EventsLeftSection extends Component {
    
    constructor(props) {

        super(props)
        this.scrollRef = React.createRef()
        this.eventSunBurstRef = React.createRef()

        this.state = {
            showLoading: true, 
            tableLoading: true,
            caseId: this.props.caseId,
            resetSearchBox: false,
            showEventType: 'timeline',
            sunburstArray: [
                {date: '2021-05-04 12:10:00', name: 'name 1', description: 'Lorem Ipsum', details: 'Lorem Ipsum'},
                {date: '2021-05-05 12:20:10', name: 'name 2', description: 'Lorem Ipsum', details: 'Lorem Ipsum'},
            ],            
            selectedFilterType: 'H',
            selectedFilterPeriod: 12,

            groupType: 'All',
            aggregateByArray: ["resource_type", "impact_type" , "asset_name",  "lifecycle", "asset_category"],
            groupedBy: "resource_type",            
            selectedCategory: 'Important / All'
        }
    }

	componentDidMount = () => {
        this.changeGraphTimings()
        this.renderSunBurstGraph()
        this.renderSunBurstRadial()        
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        // if(this.state.eventsList && this.state.eventsList.length) {
            if(prevProps.aiopsPropsDetails.event_brush_changes)  {
                if(this.props.aiopsPropsDetails.event_brush_changes.brushStartDate !== prevProps.aiopsPropsDetails.event_brush_changes.brushStartDate || this.props.aiopsPropsDetails.event_brush_changes.brushEndDate !== prevProps.aiopsPropsDetails.event_brush_changes.brushEndDate) {
                    // this.filterData()
                    this.timelineChart()
                }
            } else if(this.props.aiopsPropsDetails.event_brush_changes && this.props.aiopsPropsDetails.event_brush_changes.brushStartDate && this.props.aiopsPropsDetails.event_brush_changes.brushEndDate) {
                // this.filterData()
                this.timelineChart()
            }
        // }
    }

    changeGraphTimings = () => {
        let caseStartTime = this.props.caseDetails.created_at
        let caseEndTime = this.props.caseDetails.last_event
        if(caseEndTime < caseStartTime) {
            caseEndTime = caseStartTime
        }

        let selectedFilterType = this.state.selectedFilterType
        let selectedFilterPeriod = this.state.selectedFilterPeriod

        // caseStartTime = "2021-08-16 00:00:00"
        let sliderStartDate = caseStartTime
        let sliderEndDate = caseEndTime
        
        if(selectedFilterType === 'D') {
            sliderStartDate = subDays(caseStartTime, selectedFilterPeriod)
        } else if(selectedFilterType === 'H') {
            sliderStartDate = subHours(caseStartTime, selectedFilterPeriod)
        }
        

        let sliderObj = {}
        sliderObj.sliderStartDate = sliderStartDate
        sliderObj.sliderEndDate = sliderEndDate
        this.props.setAiopsPropsDetails('event_slider_changes', sliderObj)

        this.setState({ caseStartTime, caseEndTime, sliderStartDate, sliderEndDate, showGroupLoading: true },
            () => {
                this.eventSection()
                this.getAiopsDailyTrendByAssets()
                this.getEventsTotalCount()
                this.getImportantEventsDailyTrend()

                this.props.setAiopsPropsDetails('event_page_details', {})
                this.props.setAiopsPropsDetails('eventMonitoringList', {})
                this.props.setAiopsPropsDetails('eventMonitroingTrendList', {})
                this.props.setAiopsPropsDetails('eventMonitoringDetails', {})
                this.props.setAiopsPropsDetails('eventMonitoringEvent', {})
                this.props.setAiopsPropsDetails('eventSreMonitoringDetails', {})             
            }
        )
    }

    getAiopsDailyTrendByAssets = () => {
        let params = {}
        params.alerts_case_id = this.state.caseId
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate

        this.props.getAiopsDailyTrendByAssets(params, (promise, response) => {
            let alertEventTrend = response && response.results ? response.results : {}
            this.props.setAiopsPropsDetails('eventMonitroingTrendList', alertEventTrend)
        })
    }

    eventSection = () => {
        let params = {}
        params.case_id = this.state.caseId
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate
        // params.start_time = "2021-08-16 00:00:00"
        // params.duration = "15d"
        // params.aggregate_by = aggregate_by
        // params.duration = "14d"
        // {"asset_id": ["b540a1a090ca61534a95bbe1320f1ea4","b540a1a090ca61534a95bbe1320f1ea3"],"duration":"14d"}
        // params.aggregate_by = aggregate_by

        this.props.getEventsDailyTrend(params, (promise, result) => {
            if(promise) {
                this.setState({ showLoading: false, eventsDailyTrend: result },
                    () => this.brushDuration()
                )
            } else {
                this.setState({ showLoading: false, eventsDailyTrend: {} })
            }
        })

        let assetsParams = {}
        assetsParams.alerts_case_id = this.state.caseId
        assetsParams.log_details = 'true'
        assetsParams.start_time = this.state.sliderStartDate
        assetsParams.end_time = this.state.sliderEndDate

        this.props.listEventsByAssets(assetsParams, (promise, result) => {
            if(promise) {                           
                this.setState({ eventsByAssets: result.results },
                    () => this.timelineChart()
                )
            } else {
                this.setState({ eventsByAssets: {} })
            }
        })
    }

    brushDuration = () => {
        let sliderStartDate = this.state.sliderStartDate
        let sliderEndDate = this.state.sliderEndDate

        let difference = twoDateTimeInterval(this.state.sliderStartDate, this.state.sliderEndDate)

        let brushStartDate = sliderStartDate
        let brushEndDate = sliderEndDate

        if(difference && difference.length) {
            if(difference[0] > 0) {
                if(difference[0] === 1) {
                    brushStartDate = subHours(brushEndDate, 1)
                } else if(difference[0] < 2) {
                    brushStartDate = subHours(brushEndDate, 3)
                } else if(difference[0] < 3) {
                    brushStartDate = subHours(brushEndDate, 4)
                } else {
                    brushStartDate = subHours(brushEndDate, 8)
                }
            } else if(difference[1] > 0) {
                if(difference[1] <= 2) {
                    brushStartDate = subMinutes(brushEndDate, 20)
                } else if(difference[1] < 3) {
                    brushStartDate = subMinutes(brushEndDate, 30)
                } else if(difference[1] < 6) {
                    brushStartDate = subMinutes(brushEndDate, 50)
                } else if(difference[1] < 12) {
                    brushStartDate = subMinutes(brushEndDate, 1)
                } else if(difference[1] < 24) {
                    brushStartDate = subHours(brushEndDate, 1)
                }
            } else {
                if(difference[2] <= 2) {
                    brushStartDate = subMinutes(brushEndDate, .1)
                } else if(difference[2] <= 5) {
                    brushStartDate = subMinutes(brushEndDate, .5)
                } else if(difference[2] <= 10) {
                    brushStartDate = subMinutes(brushEndDate, 1)
                } else {
                    brushStartDate = subMinutes(brushEndDate, 5)
                }
            }
        }
        //convert brush time to local


        if(this.state.caseStartTime < brushEndDate) {
            brushStartDate = momentConvertionUtcToLocalTime(this.state.caseStartTime, 'YYYY-MM-DD HH:mm:ss')
        } else {
            brushStartDate = momentConvertionUtcToLocalTime(brushStartDate, 'YYYY-MM-DD HH:mm:ss')
        }
        // brushStartDate = momentConvertionUtcToLocalTime(brushStartDate, 'YYYY-MM-DD HH:mm:ss')
        brushEndDate = momentConvertionUtcToLocalTime(brushEndDate, 'YYYY-MM-DD HH:mm:ss')

        this.setState({ sliderStartDate, sliderEndDate, brushStartDate, brushEndDate, brushBarOptions: {} }, 
            () => {
                this.eventBrushGraph()
            }
        )
    }

    eventBrushGraph = () => {
        let graphDetails = this.state.eventsDailyTrend.results
        
        // let array = [["2021-08-16 00:10:44", 0]]
        let totalEvents = 0
        let array = []
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            graphDetails.data.count.forEach((val, index) => {
                totalEvents += val

                let arrayValues = []
                if(!index) {
                    arrayValues.push(momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'))
                    arrayValues.push(0) 
                    array.push(arrayValues)
                }
                arrayValues = []
                arrayValues.push(momentConvertionUtcToLocalTime(graphDetails.labels[index], 'YYYY-MM-DD HH:mm:ss'))
                arrayValues.push(val) 
                array.push(arrayValues)

                if(index === graphDetails.data.count.length-1) {
                    arrayValues = []
                    let time = momentConvertionUtcToLocalTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                    arrayValues.push(time)
                    arrayValues.push(0) 
                    array.push(arrayValues)
                }
            })

            // let min = momentConvertionUtcToLocalTime(graphDetails.labels[graphDetails.labels.length - 2], 'YYYY-MM-DD HH:mm:ss')
            // let max = momentConvertionUtcToLocalTime(graphDetails.labels[graphDetails.labels.length - 1], 'YYYY-MM-DD HH:mm:ss')

            let brushBarSeries = [{
                name: 'Events',
                data: array
            }]

            // let sliderObj = {}
            // sliderObj.brushStartDate = this.state.brushStartDate
            // sliderObj.brushEndDate = this.state.brushEndDate
            // this.props.setAiopsPropsDetails('event_brush_changes', sliderObj)

            let columnWidth = '70%'
            if(brushBarSeries[0].data.length <= 10) {
                columnWidth = '5%'
            } else if(brushBarSeries[0].data.length <= 20) {
                columnWidth = '10%'
            } else if(brushBarSeries[0].data.length <= 30) {
                columnWidth = '20%'
            } else if(brushBarSeries[0].data.length <= 40) {
                columnWidth = '25%'
            } else if(brushBarSeries[0].data.length <= 50) {
                columnWidth = '30%'
            }
            let brushBarOptions = {
                chart: {
                    redrawOnParentResize: true,
                    id: 'chart1',
                    height: 130,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                    brush:{
                        target: 'chartx',
                        enabled: true
                    },
                    selection: {
                        enabled: true,
                        fill: {
							color: "#c9ebf5",
							opacity: 0.1
						},  
						stroke: {
							show: false,
							curve: 'straight',
							lineCap: 'round',
							colors: '#039BE5',
							// colors: '#00A3CB',
							width: 0,
							dashArray: 0,      
						}, 
                        xaxis: {
                            min: this.getTimeLocal(this.state.brushStartDate),
                            max: this.getTimeLocal(this.state.brushEndDate)
                        },
                    },
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            this.brushSelection(xaxis)
                        }
                    }
                    // events:{
                    //     selection: (chartContext, { xaxis, yaxis }) => {
                    //         var minDate = new Date(xaxis.min);
                    //         var maxDate = new Date(xaxis.max);
                    //         let brushStartDate = momentDateGivenFormat(minDate, 'YYYY-MM-DD HH:mm:ss');
                    //         let brushEndDate = momentDateGivenFormat(maxDate, 'YYYY-MM-DD HH:mm:ss');
                    //         this.setState({ brushStartDate, brushEndDate },
                    //             () => this.getAttribute()
                    //         )
                    //     },
                    //     click: (event, chartContext, config) => {
                    //         this.setSliderPropDetails()
                    //         this.timelineChart()
                    //     }
                    // }
                },
                stroke: {
                    width: 0
                },
                
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        endingShape: 'rounded',
                        columnWidth: columnWidth,
                        // barHeight: '100%',
                    }
                },

                // colors: ['#039BE5'],
                colors: ['#19B2C0'],
                fill: {
                    colors: ['#039BE5'],
                    type: 'gradient', // gradient
                    gradient: {
                        type: 'vertical', // The gradient in the horizontal direction
                        gradientToColors: ['#5F5BA2'], // The color at the end of the gradient
                        opacityFrom: .9, // transparency
                        opacityTo: 1,
                        stops:[0, 120]
                    }
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    },
                    labels: {
                        datetimeUTC: false,
                        style: {
                            colors: '#60687C'
                        },
                        // formatter: function (value) {
                        //     return momentDateGivenFormat(value, 'HH:mm')
                        // },
                    },
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: true,
                        color:'#434959',
                        widht: 2,
                        // offsetX: -15,
                        // offsetY: 0
                    },
                },
                yaxis: {
                    // tickAmount: 2,
                    labels: {
                        show: false
                    }
                },            
                grid: {
                    position: 'back',
                    borderColor: '#2D323E',
                    show: true,
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    padding: {
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 5
                    },
                },
                annotations: {
                    position: 'back' ,
                    xaxis: [{
                        x:  new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: 'CS',
                        }
                    }]
                }
            }

            this.setState({ brushBarSeries, brushBarOptions, totalEvents },
                // () => this.listEventsByAssets()
            )
        } else {
            this.setState({ brushBarSeries: [], brushBarOptions: {} })
        }
    }

    brushSelection = (xaxis, type) => {
        let brushStartDate = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss')
        let brushEndDate = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss')

        if(this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushStartTime !== this.state.sliderStartDate && this.state.brushEndTime !== this.state.sliderEndDate && this.state.brushStartTime !== brushStartDate && this.state.brushEndTime !== brushEndDate) {
            this.filterData("brush")
        }
        
        if(brushStartDate && brushEndDate) {
            this.setState({ brushStartDate, brushEndDate, brushStartTime: brushStartDate, brushEndTime: brushEndDate },
                () => {
                    this.getAttribute()
                    this.timelineChart()
                }    
            )
        }
    }
    
    getAttribute = () => {
        var attributes = document.querySelector('.eventCaseTimeAnnotation .apexcharts-selection-rect') && document.querySelector('.eventCaseTimeAnnotation .apexcharts-selection-rect').attributes
        if(attributes) {
            let width = attributes.width.value

            let caseTime = twoDateTimeInterval(this.state.brushStartDate, this.state.brushEndDate)
            let sliderBelowStartDate = ''
            let sliderBelowEndDate = ''

            // if(width > 40) {
                if(caseTime[0] > 0) {
                    sliderBelowStartDate = momentDateGivenFormat(this.state.brushStartDate, 'DD MMM')
                    sliderBelowEndDate = momentDateGivenFormat(this.state.brushEndDate, 'DD MMM')
                } else {
                    sliderBelowStartDate = momentDateGivenFormat(this.state.brushStartDate, 'HH:mm')
                    sliderBelowEndDate = momentDateGivenFormat(this.state.brushEndDate, 'HH:mm')
                }
            // }

            let x1 = parseInt(attributes.x.value) + parseInt(20)
            let x2 = parseInt(attributes.x.value) + parseInt(width)

            let leftLPosition = x1
            let rightLPosition = x2
            let diff = twoDateDiffrence(this.state.brushStartDate, this.state.brushEndDate)
            if(diff < 8) {
                leftLPosition = x1 - 20
                if(diff < 4) {
                    leftLPosition = x1 - 40
                }
                rightLPosition = x2
            }

            var d1 = document.getElementById('leftSliderSpan');
            d1.style.position = "absolute";
            d1.style.left = leftLPosition+'px';
            d1.style.top = '95px';
            d1.style.color = '#039BE5';

            var d2 = document.getElementById('rightSliderSpan');
            d2.style.position = "absolute";
            d2.style.left = rightLPosition+'px';
            d2.style.top = '95px';
            d2.style.color = '#039BE5';
            
            this.setState({ sliderBelowStartDate, sliderBelowEndDate })
        }
    }

    setSliderPropDetails = () => {
        let sliderObj = {}
        sliderObj.brushStartDate = this.state.brushStartDate
        sliderObj.brushEndDate = this.state.brushEndDate
        this.props.setAiopsPropsDetails('event_brush_changes', sliderObj)

        this.setState({ onChangeBrushStartDate: this.state.brushStartDate, onChangeBrushEndDate: this.state.brushEndDate })
    }

    getEventsTotalCount = () => {
        let params = {}
        // params.start_time = "2021-08-16 00:00:00"
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate
        params.case_id = this.props.caseDetails.case_id
        if(this.state.groupedBy !== 'All') {
            params.aggregate_by = this.state.groupedBy
        }
        params.important = this.state.groupType === 'All' ? false : true

        this.props.getEventsTotalCount(params, (promise, response) => {
            if(promise) {
                let data = response.results ? response.results : []
                this.setState({ groupEventsResponse: data, showGroupLoading: false },
                    () => {
                        this.structureGroupEvents()
                    }    
                )
            } else {
                this.setState({ groupEvents: [], showGroupLoading: false })
            }
        })
    }

    getImportantEventsDailyTrend = () => {
        let params = {}
        // params.start_time = "2021-08-16 00:00:00"
        params.start_time = this.state.sliderStartDate
        params.end_time = this.state.sliderEndDate
        params.case_id = this.props.caseDetails.case_id
        params.aggregate_by = this.state.groupedBy

        this.props.getImportantEventsDailyTrend(params, (promise, response) => {
            if(promise) {
                let data = response.results && Object.entries(response.results).length ? response.results : {}
                this.setState({ groupEventTrends: data },
                    () => this.eventStackedBar()    
                )
            } else {
                this.setState({ groupEventTrends: [] })
            }
        })
    }

    eventStackedBar = () => {
        let graphData = this.state.groupEventTrends
        // let array = [["2021-08-16 00:10:44", 0]]
        let series = []
        if(Object.entries(graphData).length) {
            Object.entries(graphData).forEach(([key, value]) => {
                let dataArray =[]
                let graphLabels = graphData.labels
                if(key !== 'labels') {
                    // if(!index) {
                    //     let dataItems = []
                    //     dataItems.push(momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'))
                    //     dataItems.push(0) 
                    //     dataArray.push(dataItems)
                    // }

                    graphLabels && graphLabels.forEach((item, i) => {
                        let dataItems = []
                        dataItems.push(momentConvertionUtcToLocalTime(item  , 'YYYY-MM-DD HH:mm:ss'))
                        dataItems.push(value[i]) 
                        dataArray.push(dataItems)
                    })

                    if(!graphLabels.includes(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')) {
                        let dataItems = []
                        dataItems.push(momentConvertionUtcToLocalTime(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'))
                        dataItems.push(0)
                        dataArray.push(dataItems) //to initialize the start time in the graph
                    }
                    
                    if(!graphLabels.includes(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')) {
                        let dataItems = []
                        dataItems.push(momentConvertionUtcToLocalTime(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'))
                        dataItems.push(0)
                        dataArray.push(dataItems) //to initialize the start time in the graph
                    }
                    
                    let seriesRow = {}
                    seriesRow.name = capitalizeFirstLetter(key)
                    seriesRow.data = dataArray
                    series.push(seriesRow)
                }
            })
        }

        let showXaxis = true
        if(!series.length) {
            showXaxis = false
            let dataArray = []
            let dataItems = []
            dataItems.push(momentDateGivenFormat(this.state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))
            dataItems.push(0) 
            dataArray.push(dataItems)

            dataItems = []
            dataItems.push(momentDateGivenFormat(this.state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))
            dataItems.push(0) 
            dataArray.push(dataItems)

            let seriesRow = {}
            seriesRow.name = ""
            seriesRow.data = dataArray
            series.push(seriesRow)
        }

        let sliderObj = {}
        sliderObj.brushStartDate = this.state.brushStartDate
        sliderObj.brushEndDate = this.state.brushEndDate
        this.props.setAiopsPropsDetails('event_brush_changes', sliderObj)

        let columnWidth = '70%'
        if(series[0].data.length <= 10) {
            columnWidth = '1%'
        } else if(series[0].data.length <= 20) {
            columnWidth = '10%'
        } else if(series[0].data.length <= 30) {
            columnWidth = '20%'
        } else if(series[0].data.length <= 40) {
            columnWidth = '25%'
        } else if(series[0].data.length <= 50) {
            columnWidth = '30%'
        }

        let stackedEventOptions = {
            
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0], 'DD MMM YYYY HH:mm') + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                        '</div>'
                },
                style: {
                fontSize: '9px',
                },
            },			
            chart: {
                redrawOnParentResize: true,
                id: 'chart_grouped  1',
                height: 130,
                type: 'bar',
                toolbar: {
                    show: false
                },
                stacked: true
            },
            stroke: {
                width: 0
            },
            
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    endingShape: 'rounded',
                    columnWidth: columnWidth,
                }
            },

            // colors: ['#039BE5'],
            colors: ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597'],
            xaxis: {
                show: showXaxis,
                type: 'datetime',
                tooltip: {
                    enabled: false
                },
                labels: {
                    show: showXaxis,
                    datetimeUTC: false,
                    style: {
                        colors: '#60687C'
                    },
                    // formatter: function (value) {
                    //     return momentDateGivenFormat(value, 'HH:mm')
                    // },
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: true,
                    color:'#434959',
                    widht: 2,
                    // offsetX: -15,
                    // offsetY: 0
                },
            },
            yaxis: {
                // tickAmount: 2,
                labels: {
                    show: false
                }
            },            
            grid: {
                position: 'back',
                borderColor: '#2D323E',
                show: true,
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                padding: {
                    top: 0,
                    right: 10,
                    bottom: 0,
                    left: 5
                },
            },
            
            legend: {
                show: false
            },
            // annotations: {
            //     position: 'back' ,
            //     xaxis: [{
            //         x:  new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
            //         strokeDashArray: 0,
            //         borderColor: '#FF6F00',
            //         label: {
            //             borderColor: '#FF6F00',
            //             style: {
            //                 // fontSize: '12px',
            //                 color: '#fff',
            //                 background: '#FF6F00'
            //             },
            //             orientation: 'horizontal',
            //             text: 'CS',
            //         }
            //     }]
            // }
        }

        this.setState({ stackedEventSeries: series, stackedEventOptions })
    }

    structureGroupEvents = () => {
        let groupAllEvents = []
        
        let totalCount = 0

        let groupEventcategory = []
        if(this.state.groupEventsResponse.details && this.state.groupEventsResponse.details.length) {
            totalCount = this.state.groupEventsResponse.details.reduce( function(a, b){ return a + (b.count ? b.count : (b.events ? b.events.length : 0))}, 0);
            this.state.groupEventsResponse.details.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.events ? item.events.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                groupEventcategory.push(dataRow)
                item.events.forEach(alt => {
                    if(groupAllEvents.length) {
                        let filter = groupAllEvents.filter(arr => arr.event_id === alt.event_id)
                        if(!filter.length) {
                            groupAllEvents.push(alt)
                        }
                    } else {
                        groupAllEvents.push(alt)
                    }
                })
            })
        }
        
        let groupEventTotalCount = this.state.groupEventsResponse.total_count ? this.state.groupEventsResponse.total_count : 0
        let groupEventfilteredCount = this.state.groupEventsResponse.filtered_count ? this.state.groupEventsResponse.filtered_count : 0
        
        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupEventcategory.push(dataRow)

        this.setState({ groupAllEvents, groupEvents: groupAllEvents, groupEventcategory: _.orderBy(groupEventcategory, ['orderBy'], ['asc']), groupEventTotalCount, groupEventfilteredCount},
            () => this.getCategoryEvents()
        )
    }

    getCategoryEvents = (type) => {
        let filterdCategoryEvents = this.state.groupAllEvents
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryEvents = this.state.groupEventsResponse.details.filter(arr => arr.label === this.state.selectedCategory)

            if(filterdCategoryEvents && filterdCategoryEvents.length) {
                filterdCategoryEvents = filterdCategoryEvents[0].events ? filterdCategoryEvents[0].events : []
            }
        }
        this.setState({ groupEvents: filterdCategoryEvents },
            () => this.filterData(type)
        )
    }

    filterData = (type) => {
        let data = this.state.groupEvents ? this.state.groupEvents : []

        if(type === "brush" && data.length && this.state.brushStartTime && this.state.brushStartTime !== "" && this.state.brushEndTime) {
            data = data.filter(d => {
                var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.event_time, 'YYYY-MM-DD HH:mm:ss'));
                return (time >= this.getTimeLocal(this.state.brushStartTime) && time <= this.getTimeLocal(this.state.brushEndTime));
            });
        }

        // if(this.props.aiopsPropsDetails.event_brush_changes && this.props.aiopsPropsDetails.event_brush_changes.brushStartDate && this.props.aiopsPropsDetails.event_brush_changes.brushEndDate) {
        //     data = data.filter(d => {
        //         var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.event_time, 'YYYY-MM-DD HH:mm:ss'));
        //         return (time >= this.getTimeLocal(this.props.aiopsPropsDetails.event_brush_changes.brushStartDate) && time <= this.getTimeLocal(this.props.aiopsPropsDetails.event_brush_changes.brushEndDate));
        //     });
        // } else if(this.state.brushStartDate && this.state.brushEndDate) {
        //     data = data.filter(d => {
        //         var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.event_time, 'YYYY-MM-DD HH:mm:ss'));
        //         return (time >= this.getTimeLocal(this.state.brushStartDate) && time <= this.getTimeLocal(this.state.brushEndDate));
        //     });
        // }

        data = _.orderBy(data, ['event_time'], ['desc'])

        this.setState({ totalFilteredArray: data, filteredArray: data },
            () => {
                if(data.length) {
                    // let filteredArrayOrder = _.orderBy(data, ['event_time'], ['desc'])
                    this.getEventDetails(this.state.totalFilteredArray[0])
                }
            }
        )
    }

    getGraphEventDetails = (series, dataPointIndex) => {

        // let selectedEvent = []
        let selectedEvent = {}
        selectedEvent.event_id = series.data[dataPointIndex][2][0]
        selectedEvent.event_type = series.data[dataPointIndex][3][0]
        selectedEvent.event_source = series.key
        let assets = []
        let assetRow = {}
        assetRow.asset_name = series.asset_name
        assets.push(assetRow)
        selectedEvent.assets = assets
        // selectedEvent.push(selectedEvent)

        this.getEventDetails(selectedEvent)
    }

    getEventDetails = (selectedEvent) => {
        let detailParams = {}
        detailParams.event_id = selectedEvent.event_id
        detailParams.event_source = selectedEvent.event_source
        
        let assetName = ''
        let assetID = ''
        let serviceName = ''
        let resourceType = ''
        if(Array.isArray(selectedEvent.assets)) {
            selectedEvent.assets.forEach(item => {
                let filteredAasset = this.props.assetsList.filter(arr => arr.asset_name === item.asset_name)
                if(filteredAasset.length) {
                    assetName = item.asset_name
                    assetID = item.asset_id
                    serviceName = item.service_name
                    resourceType = item.resource_type
                }
            })
        } else {
            assetName = selectedEvent.assets.asset_name ? selectedEvent.assets.asset_name : ''
            assetID = selectedEvent.assets.asset_id ? selectedEvent.assets.asset_id : ''
            serviceName = selectedEvent.assets.service_name ? selectedEvent.assets.service_name : ''
            resourceType = selectedEvent.assets.resource_type ? selectedEvent.assets.resource_type : ''
        }
        selectedEvent.assetName = assetName
        selectedEvent.assetID = assetID
        selectedEvent.serviceName = serviceName
        selectedEvent.resourceType = resourceType
        // selectedEvent.event_analysis = [
        //     {
        //         "impact_type": "Availablity",
        //         "impact_level": "affects"
        //     },
        //     {
        //         "impact_type": "Scaling",
        //         "impact_level": "affects"
        //     },
        //     {
        //         "impact_type": "Cost",
        //         "impact_level": "affects"
        //     },
        //     {
        //         "impact_type": "Performance",
        //         "impact_level": "affects"
        //     }
        // ]

        this.setState({ selectedEventId: selectedEvent.event_id },
            () => {
                this.props.setAiopsPropsDetails('event_page_details', selectedEvent)
                this.props.setAiopsPropsDetails('showEventMetricsList', true)
            }
        )
        this.props.getEventDetails(detailParams, (promise, result) => {})
    }
    
    getTimeLocal = (time) => {
        time = new Date(time).getTime()
        return time
    }

    filterData = () => {
        // let data = this.state.eventsSourceList ? this.state.eventsSourceList : []
        // if(this.state.selectedSource && this.state.selectedSource !== '') {
        //     data = data.filter(arr => arr.event_source === this.state.selectedSource)
        // }
        let data = this.state.groupEvents ? this.state.groupEvents : []
        if(this.state.onChangeBrushStartDate && this.state.onChangeBrushStartDate !== "" && this.state.on.brushStartDate && this.state.on.brushEndDate) {
            data = data.filter(d => {
                var time = this.getTimeLocal(momentConvertionUtcToLocalTime(d.event_time, 'YYYY-MM-DD HH:mm:ss'));
                return (time >= this.getTimeLocal(this.state.onChangeBrushStartDate) && time <= this.getTimeLocal(this.state.onChangeBrushEndDate));
            });
        }

        data = _.orderBy(data, ['event_time'], ['desc'])

        this.setState({ totalFilteredArray: data, filteredArray: data },
            () => {
                if(data.length) {
                    // let filteredArrayOrder = _.orderBy(data, ['event_time'], ['desc'])
                    this.getEventDetails(this.state.totalFilteredArray[0])
                }
            }
        )
    }

    timelineChart = () => {
        let graphDetails = this.state.eventsByAssets
        if(graphDetails) {
            let graphLabels = graphDetails.labels ? graphDetails.labels : []

            // if(graphLabels.length === 1) {

            // }
            // let min = graphDetails.labels[graphDetails.labels.length - 2]
            // let max = graphDetails.labels[graphDetails.labels.length - 1]

            // let newField = ''
            // let calHour = twoDateTimeInterval(min, max)
            // if(calHour[0] !== 0) {
            //     let data = moment(max).add(calHour[0], 'day')
            //     newField = momentDateGivenFormat(data, 'YYYY-MM-DD 00:00:00')
            // } else if(calHour[1] !== 0){
            //     let data = moment(max).add(calHour[1], 'hour')
            //     newField = momentDateGivenFormat(data, 'YYYY-MM-DD HH:00:00')
            // } else if(calHour[2] !== 0){
            //     let data = moment(max).add(calHour[2], 'minutes')
            //     newField = momentDateGivenFormat(data, 'YYYY-MM-DD HH:mm:00')
            // }
            // graphLabels.push(newField)

            
            let graphData = graphDetails.assets
            let start = 0
            let end = 0
            var temp1 = graphLabels.map(d => Math.abs(new Date(momentConvertionUtcToLocalTime(d, 'YYYY-MM-DD HH:mm:ss')).getTime() - new Date(this.state.brushStartDate).getTime()));
            var idx1 = temp1.indexOf(Math.min(...temp1));

            var temp2 = graphLabels.map(d => Math.abs(new Date(momentConvertionUtcToLocalTime(d, 'YYYY-MM-DD HH:mm:ss')).getTime() - new Date(this.state.brushEndDate).getTime()));
            var idx2 = temp2.indexOf(Math.min(...temp2));

            start = idx1
            if(graphLabels.length > 1) {
                end = idx2 === 0 ? idx2 : idx2 + 1
            } else {
                end = 1
            }

            if(end !== 0) {
                
                graphData.length && graphData.forEach((item, index) => {
                    let count = 0
                    Object.entries(item.data).length && Object.entries(item.data).forEach(([key, value], i) => {
                        let key_count = 0
                        let series = []
                        let arrayDates = []
                        let severityArray = []
                        let graphDataRow = {}
                        graphDataRow.asset_name = item.asset_name
                        graphDataRow.total_count = item.total_count
                        graphDataRow.key = key
                        let data = []
                        value.event_count.slice(start, end).forEach((val, j) => {
                            let arrayValuesRow = []
                            arrayValuesRow.push(momentConvertionUtcToLocalTime(graphLabels[start+j], 'YYYY-MM-DD HH:mm:ss'))
                            arrayValuesRow.push(value.event_count[start+j])
                            if(value.event_count[start+j]) {
                                count += 1
                                key_count += 1
                            }
                            arrayValuesRow.push(value.event_id[start+j])
                            arrayValuesRow.push(value.event_name[start+j])
                            data.push(arrayValuesRow)
                            arrayDates.push(momentConvertionUtcToLocalTime(graphLabels[start+j], 'YYYY-MM-DD HH:mm:ss'))
                            severityArray.push(value.event_name[start+j])
                        })
                        graphDataRow.key_count = key_count
                        graphDataRow.data = data
                        graphDataRow.arrayDates = arrayDates
                        graphDataRow.severityArray = severityArray
                        series.push(graphDataRow)
                        
                        let columnWidth = '20%'
                        if(count <= 3) {
                            columnWidth = '5%'
                        } else if(count <= 5) {
                            columnWidth = '8%'
                        } else if(count <= 8) {
                            columnWidth = '13%'
                        } else if(count <= 12) {
                            columnWidth = '17%'
                        }

                        let timelineOptionsXaxis = {
                            plotOptions: {
                                bar: {
                                    barHeight: '0%',
                                    columnWidth: columnWidth
                                },          
                            },
                            chart: {
                                id: 'chartXaxis',
                                type: 'bar',
                                height: 230,
                                sparkline: {
                                    enabled: false
                                },
                                toolbar: {
                                    show: false,
                                },
                                redrawOnParentResize: true
                            },
                            legend: {
                                show: false
                            },
                            // colors: ['#546E7A'],
                            colors: ['#ffffff'],
                            stroke: {
                                width: 0
                            },
                            dataLabels: {
                                enabled: false
                            },
                            markers: {
                                size: 0
                            },
                            xaxis: {
                                // tickAmount: 7,
                                type: 'datetime',
                                // style: {
                                //   fontSize: '8px',
                                // },
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#C1C1C1'
                                    },
                                    formatter: function (value) {
                                        return momentDateGivenFormat(value, 'HH:mm:ss')
                                    }
                                },
                                axisTicks: {
                                    show: false,
                                },
                            },
                            yaxis: {
                                show: false,
                            },
                            tooltip: {
                                show: false,
                            },      
                            grid: {
                                xaxis: {
                                    lines: {
                                        show: false
                                    },
                                },
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 20
                                }, 
                            }
                        }
                    
                        let timelineOptions = {
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    barHeight: '100%',
                                    // columnWidth: '100%',
                                    // startingShape: 'flat',
                                    // endingShape: 'flat',
                                    columnWidth: columnWidth,
                                    distributed: false,
                                    // rangeBarOverlap: false
                                },          
                            },
                            chart: {
                                id: 'chart2',
                                //group: 'barBrushChart',
                                type: 'bar',
                                height: 230,
                                sparkline: {
                                    enabled: true
                                },     
                                events: {
                                    dataPointSelection: (event, chartContext, config, dataPointIndex) => { 
                                        this.getGraphEventDetails(config.w.globals.initialSeries[0], config.dataPointIndex)
                                    }
                                },
                                redrawOnParentResize: true
                            },
                            // colors: ['#546E7A'],

                            // ${item.event_source === 'checks' ? 'border-orange text-orange' : item.event_source === 'config' ? 'border-info text-info' : item.event_source === 'cloudtrail' ? 'border-purple text-purple' : 'border-gray3 text-gray3'}

                            colors: [function({ value, seriesIndex, dataPointIndex, w }) {
                                let key = w.globals.initialSeries[seriesIndex].key
                                let color = '#FFD200'
                                if (key === 'checks') {
                                    color = '#FF6F00'
                                } else if (key === 'config') {
                                    color = '#1FCFE7'
                                } else if (key === 'cloudtrail') {
                                    color = '#693EBC'
                                }
                                return color
                            }, function({ value, seriesIndex, dataPointIndex, w }) {
                                let key = w.globals.initialSeries[seriesIndex].key
                                let color = '#FFD200'
                                if (key === 'checks') {
                                    color = '#FF6F00'
                                } else if (key === 'config') {
                                    color = '#1FCFE7'
                                } else if (key === 'cloudtrail') {
                                    color = '#693EBC'
                                }
                                return color
                            }],
                            stroke: {
                                width: 0
                            },
                            dataLabels: {
                                enabled: false
                            },
                            markers: {
                                size: 0
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                },
                            },
                            tooltip: {
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false
                                },
                                y: {
                                    title: {
                                    formatter: (seriesName) => seriesName,
                                    }
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm:ss')
                                    let type = w.globals.initialSeries[seriesIndex].data[dataPointIndex][3]
                                    type = type[type.length-1]
                                    let eventId = w.globals.initialSeries[seriesIndex].data[dataPointIndex][2]
                                    eventId = eventId[eventId.length-1]
                                    // let labelName = type + ' '+eventId
                                    // let fontColor = ''
                                    // let type = w.globals.initialSeries[seriesIndex].data[dataPointIndex][2]
                                    // let color = w.globals.initialSeries[seriesIndex].data[dataPointIndex][2]
                                    // if (color === 'Critical') {
                                    //     fontColor = '#5F5BA2'
                                    // } else if (color === 'High') {
                                    //     fontColor = '#9A77D1'
                                    // } else if (color === 'Medium') {
                                    //     fontColor = '#3F73AD'
                                    // } else if (color === 'Low') {
                                    //     fontColor = '#24A597'
                                    // } else {
                                    //     fontColor = '#00B5D4'
                                    // }

                                    return '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+dateLabel+'</div><div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Event Type: </span><span class="font-weight-normal ml-2">'+type+'</span></div><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Event Id: </span><span class="font-weight-normal ml-2">'+eventId+'</span></div></div></div></div>'

                                    //return '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+dateLabel+'</div><div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+ type +'</span></div><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+eventId+'</span></div></div></div></div>'
                                    
                                    // return '<div class="arrow_box"> <span style="color:'+ fontColor +'">' + '  '+ capitalizeFirstLetter(labelName) +'</span> ' + dateLabel + ' </div>'
                                }
                            }
                        }
                    
                        this.setState({ timelineOptionsXaxis, [key+'_timelineSeries_'+index]: series, [key+'_timelineOptions_'+index]: timelineOptions })
                    })
                })

            } else {
                graphData.length && graphData.forEach((item, index) => {
                    Object.entries(item.data).length && Object.entries(item.data).forEach(([key, value], i) => {
                        let columnWidth = '20%'

                        let timelineOptionsXaxis = {
                            plotOptions: {
                                bar: {
                                    barHeight: '0%',
                                    columnWidth: '0%'
                                },          
                            },
                            legend: {
                                show: false
                            },
                            chart: {
                                id: 'chartXaxis',
                                type: 'bar',
                                height: 230,
                                sparkline: {
                                    enabled: false
                                },
                                toolbar: {
                                    show: false,
                                },
                                redrawOnParentResize: true
                            },
                            // colors: ['#546E7A'],
                            colors: ['#ffffff'],
                            stroke: {
                                width: 0
                            },
                            dataLabels: {
                                enabled: false
                            },
                            markers: {
                                size: 0
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    formatter: function (value) {
                                        return moment(value).format('HH:mm:ss')
                                    }, 
                                    style: {
                                        fontSize: '10px',
                                    }
                                },
                            },
                            yaxis: {
                                show: false,
                            },
                            tooltip: {
                                show: false,
                            },      
                            grid: {
                                xaxis: {
                                    lines: {
                                        show: false
                                    },
                                },
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 20
                                }, 
                            }
                        }
                    
                        let timelineOptions = {
                            plotOptions: {
                                bar: {
                                    barHeight: '100%',
                                    // columnWidth: '100%',
                                    startingShape: 'flat',
                                    endingShape: 'flat',
                                    columnWidth: columnWidth,
                                    distributed: false,
                                    rangeBarOverlap: false,
                                    colors: {
                                    ranges: [{
                                        from: 1,
                                        to: 0.9,
                                        color: '#FF4560'
                                    }],
                                    }
                                },          
                            },
                            chart: {
                                id: 'chart2',
                                //group: 'barBrushChart',
                                type: 'bar',
                                height: 230,
                                sparkline: {
                                    enabled: true
                                },
                                redrawOnParentResize: true
                            },
                            // colors: ['#546E7A'],
                            colors: [function({ value, seriesIndex, dataPointIndex, w }) {
                                let color = w.globals.initialSeries[seriesIndex].data[dataPointIndex][2]
                                if (color === 'Critical') {
                                    return '#5F5BA2'
                                } else if (color === 'High') {
                                    return '#9A77D1'
                                } else if (color === 'Medium') {
                                    return '#3F73AD'
                                } else if (color === 'Low') {
                                    return '#24A597'
                                } else {
                                    return '#00B5D4'
                                }
                            }, function({ value, seriesIndex, dataPointIndex, w }) {
                                let color = w.globals[seriesIndex].data[dataPointIndex][2]
                                if (color === 'Critical') {
                                    return '#5F5BA2'
                                } else if (color === 'High') {
                                    return '#9A77D1'
                                } else if (color === 'Medium') {
                                    return '#3F73AD'
                                } else if (color === 'Low') {
                                    return '#24A597'
                                } else {
                                    return '#00B5D4'
                                }
                            }],
                            stroke: {
                                width: 0
                            },
                            dataLabels: {
                                enabled: false
                            },
                            markers: {
                                size: 0
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                },
                            },
                            tooltip: {
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false
                                },
                                y: {
                                    title: {
                                    formatter: (seriesName) => seriesName,
                                    }
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    let dateLabel = momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex][0], 'MMM DD HH:mm:ss')
                                    let labelName = w.globals.initialSeries[0].event_id
                                    let fontColor = ''
                                    let color = w.globals.initialSeries[seriesIndex].data[dataPointIndex][2]
                                    if (color === 'Critical') {
                                        color = '#5F5BA2'
                                    } else if (color === 'High') {
                                        color = '#9A77D1'
                                    } else if (color === 'Medium') {
                                        color = '#3F73AD'
                                    } else if (color === 'Low') {
                                        color = '#24A597'
                                    } else {
                                        color = '#00B5D4'
                                    }

                                    return '<div class="arrow_box"> <span style="color:'+ fontColor +'"> '+ capitalizeFirstLetter(labelName) +'</span> ' + dateLabel + ' </div>'
                                }
                            }
                        }

                        this.setState({ timelineOptionsXaxis, [key+'_timelineSeries_'+index]: [], [key+'_timelineOptions_'+index]: timelineOptions })
                    })
                })
            }
        }
    }

    renderSunBurstGraph = () => {
        Sunburst()
            .data(data)
            .label("name")
            .size("size")
            .color("color")
            .width("300")
            .height("300")
            // .children('Hight')
            // .centerRadius(0.1)
            // .radiusScaleExponent(0.5)
            // .showLabels(true)
            // .excludeRoot(true)
            // .sort((a, b) => a.value - b.value)
            .labelOrientation('auto') //[angular, radial or auto]
            // .minSliceAngle(0.2)
            .radiusScaleExponent(.5)
            // .focusOnNode({})
            // .focusOnNode((d) => d)
            // .focusOnNode('Running')
            .onClick((d, node) => {this.onClickNode(d, node)})
            .onHover((node) => this.onHoverNode(node))
            // .tooltipTitle((d, node) => `<i>${node.data.name}</i>`)(this.sunBrustRef.current)
            .tooltipContent((d, node) => `<i>${node.value}</i>`)(this.eventSunBurstRef.current);
    }

    onClickNode = (d, node) => {
        // let filterData = []
        if(d !== '') {
            // this.state.myChart.focusOnNode(node)
            // this.state.myChart.onClick(this.state.myChart.focusOnNode)
            // let data = {"width": '800'}
            // this.myChart.focusOnNode(d.__dataNode)
            // return node;
            // filterData = data.children.filter(arr => arr.filterId === d.filterId)

        }
        // this.setState({ showSunBurstData: }).setAiopsPropsDetails('event_sunburst_data', this.state.showTrendDetails ? ['data'] : [])
    }
    onHoverNode = (node) => {
        // node.size
        let progressSeries = [75]
        if(node !== null) {
            progressSeries = [node.size ? node.size : 10]
            this.setState({ progressSeries })
        } else {
            this.setState({ progressSeries })
        }
    }

    renderSunBurstRadial = () => {
        let progressSeries = [75]
        let progressOptions = {
            chart: {
                height: 75,
                width: 75,
                type: 'radialBar',
                sparkline: {
                    enabled: true
                },
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    // startAngle: -135,
                    // endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '70%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                        dropShadow: {
                            enabled: true,
                            top: 3,
                            left: 0,
                            blur: 4,
                            opacity: 0.24
                        }
                    },
                    track: {
                        background: '#fff',
                        strokeWidth: '67%',
                        margin: 0, // margin is in pixels
                        dropShadow: {
                            enabled: true,
                            top: 0,
                            left: 0,
                            blur: 4,
                            opacity: 0.35
                        }
                    },
                
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: 0,
                            show: false,
                            color: '#888',
                            fontSize: '14px'
                        },
                        value: {
                            offsetY: 5,
                            formatter: function(val) {
                                return parseInt(val);
                            },
                            color: '#111',
                            fontSize: '14px',
                            show: true,
                        }
                    }
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#ABE5A1'],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['']
        }
        this.setState({ progressSeries, progressOptions })
    }
    
    handleChange = (event) => {
        this.setState({ groupType: event.target.value, showGroupLoading: true },
            () => {
                this.getEventsTotalCount()
            }    
        )
    }

    resetGraph = () => {
        this.setState({ onChangeBrushStartDate: "", onChangeBrushEndDate: "" },
            () => {
                this.brushDuration()
                this.filterData()
            }
        )
    }
    
	render() {
		return (
            <div className='col-md-12'>
                <div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
                    <div className="overlayEqualizerLoader">
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                        <div className="spinner-item"></div>
                    </div>
                </div>
                <div className={`d-flex justify-content-between`}>
                    <div className="d-flex">
                        <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                        <p className="f12 mb-0 align-self-center">CS - Case Start</p>
                    </div>
                    <div className={`d-flex justify-content-end f10`}>
                        <span className={`mr-2 f12 align-self-center `}>Last</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'caseTime' && this.state.selectedFilterPeriod === 0 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'caseTime', selectedFilterPeriod: 0, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>Case Time</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'D' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'D', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3D</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'D' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'D', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1D</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 12 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 12, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>12H</span>
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 6 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 6, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>6H</span>
                        {/* <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 3 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 3, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>3H</span> */}
                        <span className={`mr-2 align-self-center ${this.state.selectedFilterType === 'H' && this.state.selectedFilterPeriod === 1 ? 'badge badge-info disabled' : 'text-info cursorPointer'} `} onClick={() => this.setState({ selectedFilterType: 'H', selectedFilterPeriod: 1, brushStartDate: '', brushEndDate: '' }, () => this.changeGraphTimings())}>1H</span>
                    </div>
                </div>
                <div className="rounded bg-dark p-3 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="d-flex">
                                <p className="f12 m-0 align-self-center pt-1">Showing events for the period of </p>
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartDate ? momentDateGivenFormat(this.state.brushStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                <span className="ml-2 align-self-center">(local time)</span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <span onClick={this.resetGraph} className="fa fa-redo-alt border-right cursorPointer mr-2 align-self-center"></span>
                            {this.state.totalEvents ? 
                                <p className="f12 mb-0 mr-3"> {'Total '+this.state.totalEvents+' events'}</p>
                            : null}
                        </div>
                    </div>
                    <div className="d-flex">
                        <p className="mb-0 align-self-center text-nowrap text-right pr-3 w-10">Events</p>
                        <div className="w-90 mt-n3 mb-n2 ml-n2 transparentTooltip eventCaseTimeAnnotation">
                            {this.state.brushBarOptions && this.state.brushBarSeries ?
                                <Chart options={this.state.brushBarOptions} series={this.state.brushBarSeries} type="bar" height={100} />
                            : null}
                            <div className="d-flex">
                                <div id="leftSliderSpan" className="f12">{this.state.sliderBelowStartDate ? this.state.sliderBelowStartDate : ''}</div>
                                <div id="rightSliderSpan" className="f12">{this.state.sliderBelowEndDate ? this.state.sliderBelowEndDate : ''}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`mt-3 rounded bg-muted p-2 ${this.state.showTrendDetails ? '' : 'displayNone'}`}>
                        <p className="mb-0 f16 text-white">SunBurst</p>
                        <p className="f12">Showing the Alert sunburst Details</p>
                        <div className="row mt-3">
                            <div className="col-lg-5" ref={this.eventSunBurstRef} />
                            <div className="col-lg-1 p-0 pr-3 transparentTooltip">
                                {this.state.progressSeries && this.state.progressSeries.length ?
                                    <React.Fragment>
                                    <label className="mb-1">Progress</label>
                                    <Chart options={this.state.progressOptions} series={this.state.progressSeries} type="radialBar" height="75" width="75"/>
                                    </React.Fragment>
                                : null}
                                {this.state.progressSeries && this.state.progressSeries.length ?
                                    <div className="mt-3">
                                    <label className="mb-1">Progress 2</label>
                                    <Chart options={this.state.progressOptions} series={this.state.progressSeries} type="radialBar" height="75" width="75"/>
                                    </div>
                                : null}
                                {this.state.progressSeries && this.state.progressSeries.length ?
                                    <div className="mt-3">
                                    <label className="mb-1">Progress 3</label>
                                    <Chart options={this.state.progressOptions} series={this.state.progressSeries} type="radialBar" height="75" width="75"/>
                                    </div>
                                : null}
                            </div>
                            {this.state.sunburstArray && this.state.sunburstArray.length && !this.state.tableLoading ?
                                <div className="col-lg-6">
                                    <p className="mb-0 f16 text-white">Sunburst Details</p>
                                    <div className={`scrollbarDesign aiopsAlertTable`}>
                                        <ResizeableDarkThemeTable
                                            columns={[
                                                {
                                                    Header: 'Date',
                                                    accessor: 'date'
                                                },
                                                {
                                                    Header: 'Name',
                                                    accessor: 'name'
                                                },
                                                {
                                                    Header: 'Description',
                                                    accessor: 'description'
                                                },
                                                {
                                                    Header: 'Details',
                                                    accessor: 'details'
                                                }
                                            ]}
                                            data={this.state.sunburstArray ? this.state.sunburstArray : []}
                                            perPage={10}
                                            dashboard={this.state.sunburstArray && this.state.sunburstArray.length ? true : false}
                                            sortByColumn={'date'}
                                            riskTooltip={[]}
                                            onClickRow={tableRow => {}}
                                            tableSize={'table-xs'}
                                        />
                                    </div>
                                </div>
                            : this.state.sunburstArray && !this.state.sunburstArray.length ?
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your Search.</p>
                                </div>
                            : null}
                        </div>
                    </div>
                
                </div>

                <div className={`d-flex mt-2 ml-1 ${this.state.showGroupLoading ? 'disabled' : ''}`}>
                    <p className="m-0 align-self-end small mr-2">Show the </p>
                    <div class={`transparentSelectBorderBottom`}>
                        <select class="form-control" 
                        onChange={this.handleChange} 
                            // onChange={(event) => {
                            //     alert(event.target.value)
                            //     this.setState({ groupType: event.target.value })
                            // },
                            //     () => {
                            //         // this.getEventsTotalCount()
                            //     }
                            // }
                            value={this.state.groupType}
                            >
                            <option key="All" value="All">ALL</option>
                            <option key="important" value="important">Important</option>
                        </select>
                    </div> 
                    <p className="m-0 align-self-end small mx-2">events groupd by</p>
                    <div class={`transparentSelectBorderBottom`}>
                        <select class="form-control" 
                            onChange={(event) => {
                                this.setState({ groupedBy: event.target.value, showGroupLoading: true },
                                    () => {
                                        this.getEventsTotalCount()
                                        this.getImportantEventsDailyTrend()
                                    }
                                )
                            }}
                            value={this.state.groupedBy}                            
                            >
                            <option key="resource_type" value="resource_type">Resource</option>
                            <option key="impact_type" value="impact_type">Impact</option>
                            <option key="asset_name" value="asset_name">Asset</option>
                            <option key="lifecycle" value="lifecycle">LifeCycle</option>
                            <option key="asset_category" value="asset_category">Product</option>
                            {/* {this.state.aggregateByArray && this.state.aggregateByArray.map(item => {
                                return(
                                    <option value={item}>{capitalizeFirstLetter(item)}</option>
                                )
                            })} */}
                        </select>
                    </div>
                </div>

                <div className="rounded bg-dark p-3 mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="d-flex">
                                <p className="f12 m-0 align-self-center pt-1">Showing events for the period of </p>
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartDate ? momentDateGivenFormat(this.state.brushStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndDate, 'DD MMM YYYY HH:mm') : ''}</p>
                                <span className="ml-2 align-self-center">(local time)</span>
                            </div>
                        </div>
                        <div className="d-flex">
                            {this.state.groupEventTotalCount ? 
                                <p className="f12 mb-0 mr-3"> {'Showing '+this.state.groupEventfilteredCount+' events of total '+ this.state.groupEventTotalCount+' events'}</p>
                            : null}
                        </div>
                    </div>
                    {!this.state.showGroupLoading ?
                        <div className="d-flex">
                            <p className="mb-0 align-self-center text-nowrap text-right pr-3 w-10">Grouped</p>
                            <div className="w-90 mt-n3 mb-n3 ml-n2 transparentTooltip">
                                {this.state.stackedEventOptions && this.state.stackedEventSeries && this.state.stackedEventSeries.length ?
                                    <Chart options={this.state.stackedEventOptions} series={this.state.stackedEventSeries} type="bar" height={100} />
                                : null}
                            </div>
                        </div>
                    :
                        <div className='d-flex justify-content-center m-4'>
                            <Spinner className='text-center' color='' size='lg' />
                        </div>
                    }
                </div>

                <div className="rounded bg-dark p-3 mt-3">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="form-group mb-0 mr-3">
                                <select className="form-control form-control-sm select-dark" 
                                    onChange={event => this.setState({ showEventType: event.target.value })}
                                    value={this.state.showEventType}>
                                    <option value='timeline'>Timeline</option>
                                    {/* <option value='table'>Table</option> */}
                                    <option value='distribution'>Distribution</option>
                                </select>
                            </div>
                            
                            {this.state.showEventType === 'table' || this.state.showEventType === 'timeline' ?
                                <small className="align-self-center">Showing {this.state.filteredArray && this.state.filteredArray.length} {this.state.filteredArray && this.state.filteredArray.length > 1 ? 'events' : 'event'} </small>
                            : null}
                        </div>
                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                            <div className="d-flex">
                                <div className="form-group mb-0">
                                    {/* <label className="small link cursorPointer m-0 p-0" onClick={() => >{!this.state.showTimelineEvent ? 'Show Events in Timeline' : 'Show Events in Table'}</label> */}
                                    <SearchWithHiddenInput
                                        ID={'event_search'}
                                        data={this.state.totalFilteredArray ? this.state.totalFilteredArray : this.state.eventsSourceList}
                                        applyLiteDarkTags={true}
                                        searchClassName={'border-0'}
                                        searchIconColor={'text-gray3'}
                                        className="form-control-sm text-gray3 bg-muted border-0"
                                        hideInputSection={true}
                                        topClassName={'dark-search mb-2'}
                                        filteredData={(filteredArray, searchText) => {
                                            this.setState({ filteredArray },
                                                () => {
                                                    if(filteredArray.length) {
                                                        // let filteredArrayOrder = _.orderBy(filteredArray, ['event_time', 'desc'])
                                                        this.getEventDetails(filteredArray[0])
                                                    }
                                                }
                                            )
                                        }}
                                        // searchTextBoolean={(bool) => this.setState({ resetSearchBox: false })}                                        
                                        />
                                </div>
                            </div>
                        : null}
                    </div>
                    {this.state.showEventType === 'table' ?
                        <div className={`mt-2`}>
                            {this.state.filteredArray && this.state.filteredArray.length && !this.state.tableLoading ?
                            <ResizeableDarkThemeTable
                                columns={this.state.eventsDynamicColumns}
                                data={this.state.filteredArray ? this.state.filteredArray : []}
                                perPage={20}
                                dashboard={this.state.filteredArray && this.state.filteredArray.length ? true : false}
                                sortByColumn={'event_time'}
                                riskTooltip={[0]}
                                onClickRow={tableRow => {
                                    this.getEventDetails(tableRow)
                                }}
                                tableSize={'table-xs'}
                            />
                            : this.state.filteredArray && !this.state.filteredArray.length && !this.state.tableLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your Search.</p>
                                </div>
                            : null}
                            {this.state.tableLoading ? (
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='' size='lg' />
                                </div>
                            ) : null}
                        </div>
                    : this.state.showEventType === 'timeline' ?
                        !this.state.showGroupLoading  ?                        
                            <div className="row mt-2">
                                {this.state.groupEventcategory ? 
                                    <div className="col-3 categoryBoxSection">
                                        <div class="bg-muted px-2 pt-2 select-bgg">
                                            {this.state.groupedBy === "severity" ?
                                                this.state.groupEventcategory.map(item => {
                                                    return(
                                                        item.category === "Important / All" ?
                                                            <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                <p className="m-0 f-20">{item.category}</p>
                                                                <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                            </div>
                                                        :
                                                            <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                <div class="d-flex">
                                                                    {item.totalBars.map(bar => {
                                                                        return(
                                                                        <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                        )
                                                                    })}
                                                                    <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                        <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    )
                                                })                                            
                                            : 
                                                this.state.groupEventcategory.map((item, index) => {
                                                    return(
                                                        item.category === "Important / All" ?
                                                            <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                <p className="m-0 f-20">{item.category}</p>
                                                                <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                            </div>
                                                        :
                                                            <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                                <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                    <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                    <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                    <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                                </div>
                                                            </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                : null}
                                {/* {this.state.groupEventcategory ? 
                                    <div className="col-3">
                                        <div className="col-12 p-0 bg-dark3 h-100">
                                            {this.state.groupEventcategory.map(item => {
                                                return(
                                                    <div className={`d-flex p-2 border-bottom cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ((!item.count && item.category !== 'Important / All') ? 'text-gray4 disabled' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryEvents())}>
                                                        <p className="m-0 f-20">{item.category}</p>
                                                        <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                : null} */}
                                <div className="col-9 pl-0">
                                    <div className="timeline-style">
                                        <div className={``}>
                                            {this.state.filteredArray && this.state.filteredArray.map(item => {
                                                return (
                                                <div className={`d-flex ${this.state.selectedEventId === item.event_id ? 'bg-muted' : 'cursorPointer'} `} onClick={() => this.setState({ selectedEventId: item.event_id }, () => this.getEventDetails(item))}>
                                                    <div class="border-right p-3">
                                                        <div class={`font-weight-bold text-center p-2 ${item.event_source === 'checks' ? 'border-purple text-primary' : item.event_source === 'config' ? 'border-secondary text-secondary' : (item.event_source === 'cloudtrail' || item.event_source === 'Cloudtrail') ? 'info-border text-info' : 'border-gray3 text-gray3'}`}>
                                                            <p className="small mb-0">{momentDateGivenFormat(item.event_time, 'MMM DD')}</p>
                                                            <p className="m-0 f10">{momentDateGivenFormat(item.event_time, 'HH:mm:ss')}</p>
                                                        </div>
                                                        {item.severity && item.severity !== '' ?
                                                            <div className={`my-2 f12 font-weight-bolder ${item.severity === 'Low' ? 'text-success' : item.severity === 'Medium' ? 'text-purple' : item.severity === 'High' ? 'text-orange' : 'text-danger'}`}><span class={`fa fa-circle ${item.severity === 'Low' ? 'text-success' : item.severity === 'Medium' ? 'text-purple' : item.severity === 'High' ? 'text-orange' : 'text-danger'}`}></span> {item.severity}</div>
                                                        : null}
                                                        
                                                    </div>
                                                    <div class="timeline-space">
                                                        <span className="border-gray3 bg-gray3"></span>
                                                        <div className="bg-gray3"></div>
                                                    </div>
                                                    <div class="align-self-center ml-3">
                                                        <p class="mb-0">{capitalizeFirstLetter(item.event_source) +' : ' + item.event_name + (item.event_type ? ' : '+item.event_type : '')}</p>
                                                        <div class={`small-divder my-1 ${item.event_source === 'checks' ? 'bg-purple' : item.event_source === 'config' ? 'bg-secondary' : (item.event_source === 'cloudtrail' || item.event_source === 'cloudwatch_alarm') ? 'bg-info' : 'bg-gray3'}`}></div>
                                                        <p class="mb-0 small">Resource 
                                                        {item.account_id ? (' : '+item.account_id) : ''}
                                                        {item.region ? (' : '+item.region) : ''}
                                                        {item.assets && Array.isArray(item.assets) ? 
                                                            (' : '+ item.assets.map((ast, astIndex) => (item.assets.length - 1 === astIndex ? (ast.asset_name) : ast.asset_name + ', ')))
                                                            : 
                                                            item.assets.asset_name
                                                        }
                                                        {item.description ? (' : '+item.description) : ''}</p>
                                                    </div>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                <Spinner className='text-center' color='' size='lg' />
                            </div>
                    : 
                        <div className="rounded mt-2 text-white4">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <p class="mb-0"><b>Distribution</b></p>
                                    <p class="small mb-2 text-muted">Showing asset wise event distribution</p>
                                </div>
                            </div>
                            <div className="eventsDistributionTable">
                                <table className="table table-sm table-dark-hover text-white f12">
                                    <thead className="bg-muted">
                                        <tr>
                                            <th>Asset</th>
                                            <th>Source</th>
                                            <th>Count</th>
                                            <th>
                                                {this.state.eventsByAssets && this.state.eventsByAssets.assets && this.state.eventsByAssets.assets.slice(0,1).map((item, index) => {
                                                    return(
                                                    Object.entries(item.data).length && Object.entries(item.data).slice(0,1).map(([key, value], i) => {
                                                        return (
                                                            <div className="w-80 m-auto distributionLabelGraph disabled">
                                                                {this.state[key+'_timelineOptions_'+index] && this.state[key+'_timelineSeries_'+index] ?
                                                                    <Chart options={this.state.timelineOptionsXaxis} series={this.state[key+'_timelineSeries_'+index]} type="bar" height={30}/>
                                                                :
                                                                    null
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                    )
                                                })}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-secondary">
                                        {this.state.eventsByAssets && this.state.eventsByAssets.assets && this.state.eventsByAssets.assets.map((item, index) => {
                                            return (
                                            Object.entries(item.data).length && Object.entries(item.data).map(([key, value], i) => {
                                                return (
                                                    <tr>
                                                        <td>{item.asset_name}</td>
                                                        <td>{key}</td>
                                                        <td>{item.total_count}</td>
                                                        <td className="w-100">
                                                            <div className="w-70 m-auto transparentTooltip">
                                                                {this.state[key+'_timelineOptions_'+index] && this.state[key+'_timelineSeries_'+index] ?
                                                                    <Chart options={this.state[key+'_timelineOptions_'+index]} series={this.state[key+'_timelineSeries_'+index]} type="bar" height={15}/>
                                                                :
                                                                null
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
EventsLeftSection.propTypes = {
    getEventDetails: PropTypes.func,
    listEventsByAssets: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func,
    getAiopsDailyTrendByAssets: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
	// console.log('state', state)
	return {
		accounts: state.filters.accounts,
        regions: state.filters.regions,
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        alertsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.alerts ? state.aiops.caseDetails.results.alerts : [],
        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
    }
}

export default connect(mapStateToProps, {
    getEventsDailyTrend,
    getEventDetails,
    listEventsByAssets,
    setAiopsPropsDetails,
    getAiopsDailyTrendByAssets,
    getEventsTotalCount,
    getImportantEventsDailyTrend,
})(withRouter(EventsLeftSection))