/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Prakash // on 25/0/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { URL_PATH } from '../../../config/urlPath'
import { UncontrolledTooltip, UncontrolledPopover, PopoverBody, Spinner, PopoverHeader } from 'reactstrap'
import _ from 'lodash'
import { AppWrapper } from '../../common/AppWrapper'

import {
	capitalizeFirstLetter,
	numberFormater,
	getAccountNameFromId,
	momentDateGivenFormat,
	splitterFirstPart,
	momentConvertionUtcToLocalTime,
	capitalizeTheFirstLetterOfEachWord,
} from '../../../utils/utility'

import {
	listAllProviders,
	listAllAccounts,
	listAllServices,
	getVariousCountsByAccount,
} from '../../../actions/commonAction'

import {
	newGovernanceGetTotalCount,
	newGovernanceGetDayWiseCount,
	newGovernanceGetTransitionTrend,
	getGovernanceViolations,
} from '../../../actions/governance/newGovernanceAction'

import Select from 'react-select'
import { CSVLink } from 'react-csv'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import Search from '../../common/SearchComponent'

import Chart from 'react-apexcharts'
import ApexDonutChart from '../../common/charts/ApexDonutChart'
import ApexAreaChart from '../../common/charts/ApexAreaChart'
import ApexRiskStackedBarChart from '../../common/charts/ApexRiskStackedBarChart'
import ApexBarChart from '../../common/charts/ApexBarChart'
import StackedCircleBarChart from '../../common/charts/StackedCircleBarChart'
import DashboardSidePanel from '../DashboardSidePanel'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

const riskOptions = [
	{ label: 'All', value: 'All' },
	{ label: 'Critical', value: 'Critical' },
	{ label: 'High', value: 'High' },
	{ label: 'Medium', value: 'Medium' },
	{ label: 'Low', value: 'Low' },
]

class Dashboard extends Component {
	p = 0
	constructor(props) {
		super(props)
		this.groupByRef = React.createRef()
		this.rightSideTableRef = React.createRef()
		this.rightRadialStackedBarWidthRef = React.createRef()
		this.leftSectionRef = React.createRef()
		this.rightSectionRef = React.createRef()

		this.state = {
			maxResizeDivWidth: 430,
			minMaxHeight: '770px',
			minMaxHeightNumber: 770,

			selectedGovernanceType: 'All',

			showLoading: true,
			providers: [],
			selectedProvider: '',

			accounts: [],
			selectedAccount: '',
			accountsNew: [],

			selectedDuration: '+30d',

			percentStatusCount: [],

			dayWiseCountGraphData: {},

			detectorKey: '',
			selectedTag: 'Environment',
			bar_data: [],
			bar_option: [],
			doughnut_data: [],
			doughnut_options: [],
			billingCount: [],
			processCount: [],

			totalSum: 0,
			passSum: 0,
			failSum: 0,

			dayWiseRiskCountGraphData: {},

			//for next and previous week record
			transitionTrend: [],

			groupBy: 'resource_type',

			governanceTypes: [
				{ label: 'All', value: 'All' },
				{ label: 'Access Managment Policy', value: 'access' },
				{ label: 'Global Policy', value: 'global' },
				{ label: 'Infrastructure Policy', value: 'infra' },
				{ label: 'OPA Policy', value: 'opa' },
				{ label: 'Kubernetes(Gatekeeper) Policy', value: 'opa_kubernetes' },
			],
		}
	}

	componentDidMount = () => {
		// if(this.props.providers && this.props.providers.length) {
		// 	this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
		// 		() => {
		// 			this.filterSection("provider")
		// 		}
		// 	)
		// } else {
		// 	this.props.listAllProviders((promise, providers) => {
		// 		if (promise) {
		// 			this.setState({ providers, selectedProvider: providers.length ? providers[0].provider_name : "" },
		// 				() => {
		// 					this.filterSection("provider")
		// 				}
		// 			)
		// 		} else {
		// 			this.setState(
		// 				{ providers: [],  },
		// 				() => {
		// 					this.filterSection("")
		// 				}
		// 			)
		// 		}
		// 	})
		// }
		this.filterSection()
	}

	/**
	 * Filter identifiers based on the filter selection
	 */
	filterSection = from => {
		this.getAccountsList()
		this.getWindowSize()

		let params = {}
		this.props.listAllAccounts(params, (promise, response) => {})
	}

	getWindowSize = () => {
		const { innerWidth, innerHeight } = window
		let browserBorder = 100
		let headerHeight = 120
		let outerHeight = window.outerHeight - browserBorder - headerHeight + 'px'
		let outerHeightNumber = window.outerHeight - browserBorder - headerHeight

		this.setState({ outerHeight, minMaxHeight: outerHeight, minMaxHeightNumber: outerHeightNumber })
	}

	onClickAccFunction = accountId => {
		if (this.state.selectedAccount !== accountId) {
			this.setState({ selectedAccount: accountId }, () => this.onSearch())
		}
	}

	onSearch = type => {
		this.setState(
			{
				dayWiseRiskCountGraphData: {},
				new_trend_data: {},
				pending_trend_data: {},
				resolved_trend_data: {},
				reoccured_trend_data: {},
				top5Violations: [],
				multi_pass_fail_series: [],
				showRightSideRadialChart: false,
				resetEnabled: type !== 'onLoad' ? true : false,
				groupBy: this.state.selectedGovernanceType === 'opa' || this.state.selectedGovernanceType === 'opa_kubernetes' ? 'title' : this.state.groupBy,
			},
			() => {
				this.newGovernanceGetTotalCount()
				this.getGovernanceViolations()
				this.newGovernanceGetDayWiseCount()
				this.newGovernanceGetDayWiseStatusCount()
				this.newGovernanceGetTransitionTrend()
				this.top5Violations()
				this.getRadialBarDetails()
				this.getInitializePaginationRight()
			}
		)

		setTimeout(() => {
			this.setState({ showLoading: false })
		}, 5000)
	}

	onReset = () => {
		this.setState(
			{
				selectedGovernanceType: 'All',
				selectedAccount: '',
				selectedDuration: '+30d',
				showLoading: this.state.resetEnabled,
			},
			() => {
				if (this.state.resetEnabled) {
					this.onSearch('onLoad')
				}
			}
		)
	}

	newGovernanceGetTotalCount = () => {
		let params = {}

		if (this.state.selectedGovernanceType !== 'All') {
			params.governance_type = this.state.selectedGovernanceType
		}
		// if(this.state.selectedAccount !== "" && this.state.selectedGovernanceType !== "opa") {
		// 	params.account_id = this.state.selectedAccount
		// }
		params.duration = this.state.selectedDuration
		params.aggregate_by = ['risk']

		this.props.newGovernanceGetTotalCount(params, (promise, response) => {
			if (promise) {
				// console.log(response)
			}
		})
	}

	newGovernanceGetDayWiseCount = () => {
		let params = {}

		if (this.state.selectedGovernanceType !== 'All') {
			params.governance_type = this.state.selectedGovernanceType
		}
		// if(this.state.selectedAccount !== "" && this.state.selectedGovernanceType !== "opa") {
		// 	params.account_id = this.state.selectedAccount
		// }
		params.duration = this.state.selectedDuration
		params.aggregate_by = 'risk'

		this.props.newGovernanceGetDayWiseCount(params, (promise, response) => {
			if (promise) {
				this.setState({ dayWiseRiskCountGraphData: response })
			} else {
				this.setState({ dayWiseRiskCountGraphData: {} })
			}
		})
	}

	newGovernanceGetDayWiseStatusCount = () => {
		let params = {}

		if (this.state.selectedGovernanceType !== 'All') {
			params.governance_type = this.state.selectedGovernanceType
		}
		// if(this.state.selectedAccount !== "" && this.state.selectedGovernanceType !== "opa") {
		// 	params.account_id = this.state.selectedAccount
		// }
		params.duration = this.state.selectedDuration
		params.aggregate_by = 'status'

		this.props.newGovernanceGetDayWiseCount(params, (promise, response) => {
			if (promise) {
				this.setState(
					{ dayWiseCountGraphData: response, multi_pass_fail_series: [], multi_pass_fail_options: {} },
					() => this.multidayWiseCountGraphApex()
				)
			} else {
				this.setState({ dayWiseCountGraphData: {} })
			}
		})
	}

	multidayWiseCountGraphApex = () => {
		let graphData =
			this.state.dayWiseCountGraphData && this.state.dayWiseCountGraphData.fail
				? this.state.dayWiseCountGraphData.fail
				: {}

		if (graphData) {
			let passGraphData = []
			let failGraphData = []

			let labels = graphData.labels
			labels &&
				labels.length &&
				labels.forEach((lab, index) => {
					let dataItems1 = {}
					dataItems1.x = momentDateGivenFormat(lab, 'DD MMM Y')
					dataItems1.y = graphData.data.pass[index]
					passGraphData.push(dataItems1)

					let dataItems2 = {}
					dataItems2.x = momentDateGivenFormat(lab, 'DD MMM Y')
					dataItems2.y = graphData.data.fail[index]
					failGraphData.push(dataItems2)
				})

			let passData = graphData.data && graphData.data.pass ? graphData.data.pass : []
			let failData = graphData.data && graphData.data.fail ? graphData.data.fail : []

			let passSum = passData.reduce(function(a, b) {
				return a + b
			}, 0)

			let failSum = failData.reduce(function(a, b) {
				return a + b
			}, 0)

			let totalSum = failSum + passSum

			let multi_series = [
				{
					name: 'Pass',
					data: passGraphData,
				},
				{
					name: 'Fail',
					data: failGraphData,
				},
			]

			let xAxisRow = {}
			if (graphData.labels && graphData.labels && graphData.labels.length >= 7) {
				xAxisRow.type = 'datetime'
			}
			let aAxixRowTool = {}
			aAxixRowTool.enabled = false
			xAxisRow.tooltip = aAxixRowTool
			let aAxixRowTicks = {}
			aAxixRowTicks.show = false
			xAxisRow.labels = aAxixRowTicks

			let multi_options = {
				chart: {
					type: 'bar',
					height: 100,
					stacked: true,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#FFD200', '#B96422'],
				// colors: ["#17522E", "#6E2527"],
				plotOptions: {
					bar: {
						horizontal: false,
						barHeight: '100%',
						borderRadius: 'flat',
						startingShape: 'flat',
						endingShape: 'flat',
						columnWidth: '60%',
						distributed: false,
						rangeBarOverlap: false,
						colors: {
							ranges: [
								{
									from: 1,
									to: 0.9,
									color: 'red',
								},
							],
							backgroundBarColors: ['#333947'],
							backgroundBarOpacity: 1,
							backgroundBarRadius: 0,
						},
					},
				},
				dataLabels: {
					enabled: false,
				},
				legend: {
					show: false,
				},
				grid: {
					show: false,
					xaxis: {
						lines: {
							show: false,
						},
					},
					yaxis: {
						lines: {
							show: false,
						},
					},
					padding: {
						top: 0,
						right: 0,
						bottom: 0,
						left: -5,
					},
				},
				xaxis: xAxisRow,
				yaxis: {
					show: false,
					labels: {
						show: false,
					},
				},
				tooltip: {
					enabled: true,
					custom: function({ series, seriesIndex, dataPointIndex, w }) {
						let val = series[seriesIndex][dataPointIndex]
						let labelName = w.globals.initialSeries[seriesIndex].name
						return (
							'<div class="arrow_box">' +
							'<span> ' +
							w.globals.initialSeries[0].data[dataPointIndex].x +
							' <span style="color:' +
							w.globals.colors[seriesIndex] +
							'">' +
							labelName +
							'</span> ' +
							val +
							' </span>' +
							'</div>'
						)
					},
					style: {
						fontSize: '9px',
					},
				},
			}
			this.setState({
				multi_pass_fail_series: multi_series,
				multi_pass_fail_options: multi_options,
				totalSum,
				passSum,
				failSum,
			})
		}
	}

	top5Violations = () => {
		let params = {}

		if (this.state.selectedGovernanceType !== 'All') {
			params.governance_type = this.state.selectedGovernanceType
		}
		// if(this.state.selectedAccount !== "" && this.state.selectedGovernanceType !== "opa") {
		// 	params.account_id = this.state.selectedAccount
		// }
		params.duration = this.state.selectedDuration
		params.aggregate_by = ['description']

		this.props.newGovernanceGetTotalCount(params, (promise, response) => {
			if (promise) {
				this.setState({ violationsList: response }, () => this.structureTopViolations())
			} else {
				this.setState({ violationsList: {} })
			}
		})
	}

	structureTopViolations = () => {
		let list = this.state.violationsList

		let orderedList = _.orderBy(list, ['fail_count'], ['desc'])

		let labels = []
		let data = []
		orderedList &&
			orderedList.length &&
			orderedList.slice(0, 5).forEach(item => {
				labels.push(item.description)
				data.push(item.fail_count)
			})

		for (let i = orderedList.length; i < 5; i++) {
			data.push(0)
			labels.push('empty')
		}
		let topStatusData = {}
		topStatusData.data = data
		topStatusData.labels = labels

		this.setState({ top5Violations: topStatusData })
	}

	newGovernanceGetTransitionTrend = () => {
		let params = {}

		if (this.state.selectedGovernanceType !== 'All') {
			params.governance_type = this.state.selectedGovernanceType
		}
		// if(this.state.selectedAccount !== "" && this.state.selectedGovernanceType !== "opa") {
		// 	params.account_id = this.state.selectedAccount
		// }
		params.duration = this.state.selectedDuration

		this.props.newGovernanceGetTransitionTrend(params, (promise, response) => {
			if (promise) {
				this.setState({ transitionTrend: response, showLoading: false })
				this.dayWiseTransitionGraph()
			} else {
				this.setState({ transitionTrend: [], showLoading: false })
			}
		})
	}

	dayWiseTransitionGraph = () => {
		let graphDetails = this.state.transitionTrend.details
		let graphLabels = this.state.transitionTrend.labels

		if (graphDetails) {
			for (const [key, value] of Object.entries(graphDetails)) {
				let data = []
				let graphData = value.daywise_trend
				for (let x = 0; x < graphLabels.length; x++) {
					let dataItems = {}
					dataItems.x = momentDateGivenFormat(graphLabels[x], 'DD MMM Y')
					dataItems.y = graphData[x]
					data.push(dataItems)
				}

				let trendGraphData = {}
				trendGraphData.labels = graphLabels
				trendGraphData.data = data

				this.setState({ [key + '_trend_data']: trendGraphData })
			}
		}
	}

	getGovernanceViolations = () => {
		let params = {}

		if (this.state.selectedGovernanceType !== 'All') {
			params.governance_type = this.state.selectedGovernanceType
		}
		// if(this.state.selectedAccount !== "" && this.state.selectedGovernanceType !== "opa") {
		// 	params.account_id = this.state.selectedAccount
		// }
		params.duration = this.state.selectedDuration
		// params.aggregate_by = "resource_type"
		params.aggregate_by = this.state.groupBy === 'title' ? 'category' : this.state.groupBy

		this.props.getGovernanceViolations(params, (promise, response) => {
			if (promise) {
				this.setState(
					{
						violationResponse: response,
						viloationDataNew: response,
						filteredViolationDataNew: response,
						violationDetails: [],
					},
					() => {
						this.formHeaders()
						this.combineAllVilationData()
					}
				)
			} else {
				this.setState({ violationResponse: [] })
			}
		})
	}

	combineAllVilationData = () => {
		let violationAllResults = []
		if (this.state.filteredViolationDataNew && this.state.filteredViolationDataNew.length) {
			this.state.filteredViolationDataNew.forEach(item => {
				item.governance_results.forEach(row => {
					if (row.results) {
						violationAllResults = [...row.results, ...violationAllResults]
					}
				})
			})
		}

		this.setState({ violationAllResults })
	}

	downloadCsv = (resource_type, dat, index, datIndex) => {
		let params = {}
		params.resource_type = resource_type
		params.governance_item = dat.governance_item
		params.governance_item = dat.governance_item

		if (
			this.state['selectedRisk_' + index + '_' + datIndex] &&
			this.state['selectedRisk_' + index + '_' + datIndex] !== 'All'
		) {
			params.status = this.state['selectedRisk_' + index + '_' + datIndex]
		}

		if (this.state['selectedStatus_' + index + '_' + datIndex]) {
			params.status = this.state['selectedStatus_' + index + '_' + datIndex]
		}

		if (this.state['search_' + index + '_' + datIndex] && this.state['search_' + index + '_' + datIndex] !== '') {
			params.search_text = this.state['search_' + index + '_' + datIndex]
		}

		// this.props.downloadChecksResults(params, (promise, result) => {
		// 	console.log(result)
		// })
	}

	getFilteredData = (selectedRisk, selectedStatus, index, datIndex) => {
		let filteredViolationDataNew =
			this.state['search_' + index + '_' + datIndex] && this.state['search_' + index + '_' + datIndex] !== ''
				? this.state.filteredViolationDataNew
				: this.state.viloationDataNew
		if (selectedRisk !== '' && selectedRisk !== undefined) {
			filteredViolationDataNew = filteredViolationDataNew.filter(
				item => item.risk.toLowerCase() === selectedRisk.toLowerCase()
			)
		}

		if (selectedStatus !== '' && selectedStatus !== undefined) {
			filteredViolationDataNew = filteredViolationDataNew.filter(item =>
				item.status ? item.status === selectedStatus : item
			)
		}
		if (selectedRisk && selectedStatus) {
		}

		this.setState({ filteredViolationDataNew })
	}

	structureViolations = () => {
		let data = []
		if (this.state.violationResponse && this.state.violationResponse.length) {
			this.state.violationResponse.forEach(violation => {
				if (violation.governance_results && violation.governance_results.length) {
					violation.governance_results.forEach(item => {
						if (item.results && item.results.length) {
							data = [...data, ...item.results]
						}
					})
				}
			})
		}

		this.setState({ violationDetails: data }, () => this.formViolationDetails(this.state.violationDetails))
	}

	onChangeGroupBy = type => {
		this.setState(
			{
				showRightSideRadialChart: type === 'graph' ? true : false,
				hideDistributionSection: false,
				radialBarData: [],
				showRadialLoading: true,
				showGroupByOptions: false,
				filteredViolationDataNew: [],
			},
			() => {
				// this.formViolationDetails(this.state.violationDetails)
				this.getGovernanceViolations()
				this.getRadialBarDetails()
			}
		)
	}

	formViolationDetails = data => {
		if (data === '') {
			data = this.state.violationDetails
		}
		if (this.state.groupBy === 'title') {
			data = _.chain(data)
				.groupBy('title')
				.map((value, key) => ({
					heading: key,
					count: value.length,
					data: _.chain(value)
						.groupBy('resource_type')
						.map((value, key) => ({ label: key, count: value.length, sub: value }))
						.value(),
				}))
				.value()
		} else {
			data = _.chain(data)
				.groupBy('resource_type')
				.map((value, key) => ({
					heading: key,
					count: value.length,
					data: _.chain(value)
						.groupBy('title')
						.map((value, key) => ({ label: key, count: value.length, sub: value }))
						.value(),
				}))
				.value()
		}

		this.setState({ vilationData: data, filteredViolationData: data })
	}

	getAccountsList = () => {
		let params = {}
		// if(this.state.selectedProvider && this.state.selectedProvider !== "") {
		// 	params.provider = this.state.selectedProvider.toLowerCase()
		// }
		// params.duration = this.state.selectedDuration
		params.aggregate_by = ['account_id']
		this.props.newGovernanceGetTotalCount(params, (promise, response) => {
			if (promise) {
				this.setState(
					{
						accountsNew: response,
						filteredAccount: response,
						selectedAccount: response.length ? response[0].account_id : '',
					},
					() => this.onSearch('onLoad')
				)
			}
		})
	}

	handleClickOutside(event) {
		if (!this.state.hideDistributionSection && this.groupByRef && !this.groupByRef.current.contains(event.target)) {
			this.setState({ showGroupByOptions: false })
		} else if (!this.state.hideDistributionSection) {
			this.setState({ showGroupByOptions: true })
		}
	}

	getRadialBarDetails = () => {
		let params = {}

		if (this.state.selectedGovernanceType !== 'All') {
			params.governance_type = this.state.selectedGovernanceType
		}
		// if(this.state.selectedAccount !== "" && this.state.selectedGovernanceType !== "opa") {
		// 	params.account_id = this.state.selectedAccount
		// }
		params.duration = this.state.selectedDuration
		if (this.state.groupBy === 'resource_type') {
			params.aggregate_by = ['resource_type', 'title']
		} else {
			params.aggregate_by = ['title', 'resource_type']
		}

		this.props.newGovernanceGetTotalCount(params, (promise, response) => {
			if (promise) {
				this.setState({ radialBarData: response }, () => this.formRadialBarChart())
			} else {
				this.setState({ radialBarData: [] })
			}
		})
	}

	formRadialBarChart = () => {
		let graphData = this.state.radialBarData
		let rightSideGraphColors = [
			'#9029cc',
			'#a22ee6',
			'#bc47ff',
			'#c35cff',
			'#cb70ff',
			'#d285ff',
			'#da99ff',
			'#e15ad2',
			'#fb74eb',
			'#fb83ed',
			'#fc93f0',
			'#fca2f2',
			'#fdb2f4',
			'#fdc1f6',
		]
		let keys = []

		let newData = _.chain(graphData)
			.groupBy(this.state.groupBy)
			.map((value, key) => ({ label: key, data: value }))
			.value()

		newData.forEach(item => {
			item.data.forEach(row => {
				if (this.state.groupBy === 'resource_type') {
					if (!keys.filter(e => e === row.title).length) {
						keys.push(row.title)
					}
				} else {
					if (!keys.filter(e => e === row.resource_type).length) {
						keys.push(row.resource_type)
					}
				}
			})
		})

		let rightSideArray = []
		let rightSideHighest = 0
		let rightSideTotal = 0
		let rightSideTotalTableData = {}
		newData.forEach((item, index) => {
			// keys.push(item[this.state.groupBy])
			let State = 'files_' + index
			let dataRow = {}
			let count = 0
			
			let orderData = _.orderBy(item.data, ['fail_count'], ['desc'])
			if (this.state.groupBy === 'resource_type') {
				keys.forEach(row => {
					count += orderData.filter(e => e.resource_type === item.label && e.title === row).length
						? orderData.filter(e => e.resource_type === item.label && e.title === row)[0].fail_count
						: 0
					dataRow[row] = orderData.filter(e => e.resource_type === item.label && e.title === row).length
						? orderData.filter(e => e.resource_type === item.label && e.title === row)[0].fail_count
						: 0
				})
			} else {
				keys.forEach(row => {
					count += orderData.filter(e => e.title === item.label && e.resource_type === row).length
						? orderData.filter(e => e.title === item.label && e.resource_type === row)[0].fail_count
						: 0
					dataRow[row] = orderData.filter(e => e.title === item.label && e.resource_type === row).length
						? orderData.filter(e => e.title === item.label && e.resource_type === row)[0].fail_count
						: 0
				})
			}

			// let orderKeys = dataRow
			// orderKeys = orderKeys.sort(function(a, b) { return obj[a] - obj[b] });
			// console.log("orderKeys", )

			dataRow.State = State
			dataRow.page = 'MultipleKeys'
			dataRow.name = item.label
			dataRow.total = count
			// dataRow.rightSideHighest = passCount + failCount
			let passFailTotal = count
			rightSideTotal = graphData.length
			if (rightSideHighest < passFailTotal) {
				rightSideHighest = passFailTotal
			}
			if (count > 0) {
				rightSideArray.push(dataRow)
			}

			rightSideTotalTableData[State] = dataRow
		})

		let rightSideTotalData = {}
		let data = {}
		data.name = 'Violations'
		keys.forEach((key, j) => {
			data[key] = this.count(rightSideArray, key)
		})
		rightSideTotalData.data = data

		if (rightSideArray.length < 25) {
			let length = rightSideArray.length
			for (let i = length; i <= 25; i++) {
				let dataRow = {}
				dataRow.State = 'files_' + i
				dataRow.name = 'empty_data'
				// dataRow.rightSideHighest = 0
				keys.forEach((key, j) => {
					if (j === 0) {
						let count = 1
						if (rightSideHighest <= 5) {
							count = 1 / 5
						}
						dataRow[key] = count
					} else {
						dataRow[key] = 0
					}
				})
				rightSideArray.push(dataRow)
			}
		}
		
		// rightSideArray = _.orderBy(rightSideArray, ["fail_count"], ["desc"])

		this.setState(
			{
				rightSideGraphColors,
				rightSideArray,
				rightSideKeys: keys,
				rightSideHighest,
				rightSideTotalData,
				rightSideTotal,
				rightSideGraphId: 'totalThreats',
				rightSideSvgGraphId: 'svgTotalThreats',
				showRightSideRadialChart: true,
				hideDistributionSection: true,
				showRadialLoading: false,
				rightSideTotalTableData,
			},
			() => this.rightSideDataFormat()
		)
	}

	count = (array, key) => {
		let data = array.reduce(function(r, a) {
			return r + a[key]
		}, 0)

		return data
	}

	rightSideDataFormat = (data, State) => {
		let result = []
		let rightSidePageCount = 1
		let rightSidePageCountArray = []
		let ritPageStart = 0
		let ritPageEnd = 1

		// let splitName = data && data.name ? data.name.split(":") : []
		let controlName = data && data.name ? data.name : 'All'
		if (!State) {
			result = this.state.rightSideTotalData ? this.state.rightSideTotalData.data : {}
		} else if (this.state.rightSideTotalTableData.hasOwnProperty(State)) {
			result = this.state.rightSideTotalTableData[State]
		}

		let propsToRemove = ['name', 'State', 'total', 'page']
		result = this.removeProperties(result, ...propsToRemove)

		let rightSideDataLength = Object.entries(result).length
		if (rightSideDataLength > this.state.rightSidePerPage) {
			rightSidePageCount = Math.ceil(rightSideDataLength / this.state.rightSidePerPage)
			ritPageEnd = rightSidePageCount > 5 ? 5 : rightSidePageCount
			for (let i = 0; i < rightSidePageCount; i++) {
				rightSidePageCountArray.push(i + 1)
			}
		}

		this.setState({
			ritPageStart,
			ritPageEnd,
			rightSidePageCount,
			rightSidePageCountArray,
			rightSideFilteredTableData: result,
			controlName,
		})
	}

	rightSideDataFormat1 = (data, State) => {
		let rightSideStateName = this.state.rightSideStateName === State ? 'parentData' : State ? State : 'parentData'
		let tableData = this.state.rightSideArray.filter(arr => arr.State === rightSideStateName)
		let rightSideTableData =
			tableData && tableData.length
				? tableData[0]
				: this.state.rightSideTotalData
				? this.state.rightSideTotalData.data
				: {}

		let propsToRemove = ['name', 'State', 'total', 'page']
		rightSideTableData = this.removeProperties(data, ...propsToRemove)

		let rightSideDataLength = Object.entries(rightSideTableData).length
		let rightSidePageCount = 1
		let rightSidePageCountArray = []
		let ritPageStart = 0
		let ritPageEnd = 1

		if (rightSideDataLength > this.state.rightSidePerPage) {
			rightSidePageCount = Math.ceil(rightSideDataLength / this.state.rightSidePerPage)
			ritPageEnd = rightSidePageCount > 5 ? 5 : rightSidePageCount
			for (let i = 0; i < rightSidePageCount; i++) {
				rightSidePageCountArray.push(i + 1)
			}
		}

		this.setState({
			ritPageStart,
			ritPageEnd,
			rightSidePageCount,
			rightSidePageCountArray,
			rightSideTableData,

			rightSideStateName,
			showRightSideRadialChart: true,
			hideDistributionSection: true,
		})
	}

	removeProperties = (object, ...keys) => {
		return Object.entries(object).reduce(
			(prev, [key, value]) => ({ ...prev, ...(!keys.includes(key) && { [key]: value }) }),
			{}
		)
	}

	getInitializePaginationRight = () => {
		this.setState({
			rightSidePageCount: 1,
			currentPage: 1,
			rightSideStart: 0,
			rightSidePerPage: 5,
			disableLeftAction: true,
			disableRightAction: false,
		})
	}

	navigatePage = (action, currentPage) => {
		let ritPageStart = 0
		let ritPageEnd = this.state.rightSidePageCount > 5 ? 5 : this.state.rightSidePageCount

		let startRecord = this.state.rightSideStart
		if (action === 'next') {
			startRecord = startRecord + this.state.rightSidePerPage
			currentPage = currentPage + 1
		} else if (action === 'previous' && currentPage !== 1) {
			currentPage = currentPage - 1
			startRecord = startRecord - this.state.rightSidePerPage
		} else if (action === 'start' && currentPage !== 1) {
			currentPage = 1
			startRecord = 0
		} else if (action === 'end') {
			currentPage = this.state.rightSidePageCount
			ritPageStart = this.state.ritPageStart + (currentPage - 5)
			ritPageEnd = this.state.rightSidePageCount
			startRecord = (this.state.rightSidePageCount - 1) * this.state.rightSidePerPage
		} else if (action === 'page') {
			startRecord = (currentPage - 1) * this.state.rightSidePerPage
		}

		if (this.state.rightSidePageCount > 5) {
			if (currentPage > 2 && (action === 'next' || action === 'page')) {
				ritPageStart = this.state.ritPageStart + 1
				ritPageEnd = this.state.ritPageEnd + 1
			}

			if (currentPage < 3 && (action === 'previous' || action === 'page') && this.state.ritPageStart > 1) {
				ritPageStart = this.state.ritPageStart - 1
				ritPageEnd = this.state.ritPageEnd - 1
			}

			if (ritPageEnd > this.state.rightSidePageCount) {
				ritPageStart = this.state.ritPageStart
				ritPageEnd = this.state.rightSidePageCount
			}
		}

		let disableLeftAction = true
		if (currentPage > 1) {
			disableLeftAction = false
		}

		let disableRightAction = false
		if (currentPage === this.state.rightSidePageCount) {
			disableRightAction = true
		}

		this.setState(
			{ currentPage, ritPageStart, ritPageEnd, rightSideStart: startRecord, disableLeftAction, disableRightAction },
			() => this.rightSideTableRef.current.scrollIntoView()
		)
	}

	formHeaders = () => {
		let data =
			this.state.filteredViolationDataNew &&
			this.state.filteredViolationDataNew.length &&
			this.state.filteredViolationDataNew[0].governance_results
				? this.state.filteredViolationDataNew[0].governance_results
				: {}

		let dataResult = data && data.length && data[0] && data[0].results ? data[0].results : {}
		if (dataResult.length) {
			let headers = []
			Object.keys(dataResult[0]).forEach(item => {
				if (item !== 'resolution_status_info' && item !== 'category' && item !== 'tags' && item !== 'version') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(item)
					headerRow.key = item
					headers.push(headerRow)
				}
			})
			this.setState({ violationHeaders: headers })
		}
	}

	formDownloadData = (data, index, datIndex) => {
		let dataResult =
			this.state['filteredArray_' + index + '_' + datIndex] &&
			this.state['filteredArray_' + index + '_' + datIndex].length
				? this.state['filteredArray_' + index + '_' + datIndex]
				: data

		if (
			this.state['selectedStatus_' + index + '_' + datIndex] &&
			this.state['selectedStatus_' + index + '_' + datIndex] !== ''
		) {
			dataResult = dataResult.filter(e => e.status === this.state['selectedStatus_' + index + '_' + datIndex])
		}

		if (
			this.state['selectedRisk_' + index + '_' + datIndex] &&
			this.state['selectedRisk_' + index + '_' + datIndex] !== ''
		) {
			dataResult = dataResult.filter(e => e.risk === this.state['selectedRisk_' + index + '_' + datIndex])
		}

		if (dataResult.length) {
			let headers = []
			let usedKeys = []
			Object.keys(dataResult[0]).forEach(item => {
				if (item !== 'resolution_status_info' && item !== 'category' && item !== 'tags' && item !== 'version') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(item)
					headerRow.key = item
					headers.push(headerRow)
					usedKeys.push(item)
				}
			})

			let downloadData = []
			dataResult.forEach((item, index) => {
				let dataRow = {}
				Object.entries(item).forEach(([key, value]) => {
					if (usedKeys.includes(key)) {
						dataRow[key] = value
					}
				})
				downloadData.push(dataRow)
			})

			this.setState({
				['headers_' + index + '_' + datIndex]: headers,
				['download_data_' + index + '_' + datIndex]: downloadData,
			})
		}
	}

	/**
	 * Renders Compliance Dashboard
	 */
	render() {
		return (
			<div
				className='container-fluid overflow-auto flex-grow-1 bg-muted'
				onClick={event => {
					this.handleClickOutside(event)
				}}
			>
				<div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className='overlayEqualizerLoader'>
						<div className='spinner-item'></div>
						<div className='spinner-item'></div>
						<div className='spinner-item'></div>
						<div className='spinner-item'></div>
						<div className='spinner-item'></div>
					</div>
				</div>
				{this.state.showDashboardPanel ? (
					<DashboardSidePanel
						closeSidePanel={() => this.setState({ showDashboardPanel: false })}
						selectedViolation={this.state.selectedViolation}
					/>
				) : null}
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className='d-flex'>
								<div className='col-5 pl-0'>
									<h6 className='text-white m-0'>Governance Dashboard</h6>
									<p className='text-white m-0'>Dashboard view of various types of policies performed on your Cloud</p>
								</div>
								<div className={`col-7 pl-0 text-right ${this.state.showLoading ? 'disabled' : ''}`}>
									<div className={`d-md-flex justify-content-end`}>
										{/* <div className={`form-group cursorPointer flex-fill mb-sm-0 styled-feild mr-sm-3 zIndex1`} style={{maxWidth: "150px" }}>
											<label className="mb-1">Provider</label>
											<Select
												placeholder={"All"}
												isSearchable={false}
												className="f13 p-0 colorBlack reactSelectFilterDrop"
												value={({
													value: this.state.selectedProvider,
													label: this.state.selectedProvider && this.state.selectedProvider !== "" ? this.state.selectedProvider : <span className="placeholder">Select</span>
												})}
												options={this.state.providers && this.state.providers.map(item => ({
													value: item.provider_name,
													label: item.provider_name,	
												}))}
												onChange={event =>  
													this.setState({
														selectedProvider: event.value,
														changesInFilter: true
													},
														() => this.getVariousCountsByAccount()
													)
												}
											/>
										</div> */}
										<div
											className={`form-group cursorPointer flex-fill mb-sm-0 styled-feild mr-sm-3 zIndex1`}
											style={{ maxWidth: '150px' }}
										>
											<label className='mb-1'>Governance Type</label>
											<Select
												placeholder={'All'}
												isSearchable={false}
												className='f13 p-0 colorBlack reactSelectFilterDrop'
												value={{
													value: this.state.selectedGovernanceType,
													label:
														this.state.selectedGovernanceType &&
														this.state.governanceTypes &&
														this.state.governanceTypes.filter(e => e.value === this.state.selectedGovernanceType)
															.length ? (
															this.state.governanceTypes.filter(e => e.value === this.state.selectedGovernanceType)[0]
																.label
														) : (
															<span className='placeholder'>Select</span>
														),
												}}
												options={
													this.state.governanceTypes &&
													this.state.governanceTypes.map(row => ({
														value: row.value,
														label: row.label,
													}))
												}
												onChange={event =>
													this.setState({
														selectedGovernanceType: event.value,
														changesInFilter: true,
													})
												}
											/>
										</div>
										<div className={`d-flex`}>
											<span className={`mr-2 f12 align-self-center`}>Last</span>
											<span
												className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${
													this.state.selectedDuration === '+90d' ? 'badge badge-primary text-white' : 'text-filterBlue'
												} `}
												onClick={() => this.setState({ selectedDuration: '+90d' })}
											>
												3 Months
											</span>
											<span
												className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${
													this.state.selectedDuration === '+60d' ? 'badge badge-primary text-white' : 'text-filterBlue'
												} `}
												onClick={() => this.setState({ selectedDuration: '+60d' })}
											>
												2 Month
											</span>
											<span
												className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${
													this.state.selectedDuration === '+30d' ? 'badge badge-primary text-white' : 'text-filterBlue'
												} `}
												onClick={() => this.setState({ selectedDuration: '+30d' })}
											>
												1 Month
											</span>
											<span
												className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${
													this.state.selectedDuration === '+14d' ? 'badge badge-primary text-white' : 'text-filterBlue'
												} `}
												onClick={() => this.setState({ selectedDuration: '+14d' })}
											>
												2 Week
											</span>
											<span
												className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${
													this.state.selectedDuration === '+7d' ? 'badge badge-primary text-white' : 'text-filterBlue'
												} `}
												onClick={() => this.setState({ selectedDuration: '+7d' })}
											>
												1 Week
											</span>
										</div>
										<div className={`ml-2 align-self-center`}>
											<div className='btn-group' role='group' aria-label='checks-dash'>
												<button
													type='button'
													className={`${
														this.state.showLoading ? 'disabled' : 'cursorPointer'
													} btn btn-secondary border-right`}
													onClick={() => this.setState({ showLoading: true }, this.onSearch())}
												>
													{this.state.showLoading ? <Spinner className={`align-self-center mr-2`} size='sm' /> : null}
													Search
												</button>
												<button
													type='button'
													className={`${
														this.state.showLoading ? 'disabled' : 'cursorPointer'
													} btn btn-secondary border-left`}
													onClick={() => this.onReset()}
												>
													<i className='far fa-redo'></i>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={`box rounded-0 shadow-none bg-transparent`}>
							{' '}
							{/* p-3 */}
							<div className='row'>
								<div className='col-sm-12'>
									<div className={`p-3 bg-muted`}>
										{' '}
										{/* rounded */}
										<div className='row'>
											<div
												className={`${this.state.minimizeLeftDiv ? 'w-10 px-3' : 'w-25 px-3'} border-right`}
												ref={this.leftSectionRef}
											>
												{' '}
												{/*resize horizontal*/}
												<div
													className='bg-dark rounded overflowYAuto'
													style={{ minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight }}
												>
													{this.state.selectedGovernanceType !== 'opa' ? (
														<div className='pt-3'>
															<div
																className={`d-flex justify-content-between px-3 ${
																	this.state.minimizeLeftDiv ? 'mb-1' : 'mb-3'
																}`}
															>
																{!this.state.minimizeLeftDiv ? (
																	<p className='mb-0 mt-1 small text-white'>
																		Showing {this.state.filteredAccount && this.state.filteredAccount.length} of Total{' '}
																		{this.state.accountsNew && this.state.accountsNew.length} Account(s)
																	</p>
																) : (
																	<p className='mb-0 mt-1 small text-white'>
																		Showing {this.state.filteredAccount && this.state.filteredAccount.length}
																	</p>
																)}
																<div className='d-flex'>
																	{!this.state.minimizeLeftDiv &&
																	this.state.accountsNew &&
																	this.state.accountsNew.length ? (
																		<SearchWithHiddenInput
																			data={this.state.accountsNew}
																			applyLiteDarkTags={true}
																			searchClassName={'border-0'}
																			searchIconColor={'text-gray3'}
																			className='form-control-sm text-gray3 bg-muted border-0'
																			hideInputSection={true}
																			topClassName={'dark-search mb-2'}
																			searchPlaceHolder={'Search....'}
																			filteredData={filteredAccount => this.setState({ filteredAccount })}
																		/>
																	) : null}
																	{this.state.minimizeLeftDiv ? (
																		<button class='bg-transparent border-0 align-self-center text-white p-0 z999'>
																			<i
																				class='far fa-expand-wide cursorPointer'
																				onClick={() => this.setState({ minimizeLeftDiv: false })}
																			></i>
																		</button>
																	) : (
																		<button class='bg-transparent border-0 align-self-center text-white p-0 z999'>
																			<i
																				class='far fa-compress cursorPointer'
																				onClick={() => this.setState({ minimizeLeftDiv: true })}
																			></i>
																		</button>
																	)}
																</div>
															</div>
															{this.state.minimizeLeftDiv && this.state.accountsNew && this.state.accountsNew.length ? (
																<SearchWithHiddenInput
																	data={this.state.accountsNew}
																	applyLiteDarkTags={true}
																	searchClassName={'border-0'}
																	searchIconColor={'text-gray3'}
																	className='form-control-sm text-gray3 bg-muted border-0'
																	hideInputSection={true}
																	topClassName={'dark-search mb-1'}
																	searchPlaceHolder={'Search....'}
																	filteredData={filteredAccount => this.setState({ filteredAccount })}
																/>
															) : null}
															{this.state.filteredAccount && this.state.filteredAccount.length ? (
																this.state.filteredAccount.map((item, i) => {
																	return (
																		<div
																			className={`cursorPointer ${
																				this.state.selectedAccount === item.account_id ? 'bg-dark3' : ''
																			} px-3`}
																			onClick={() => this.onClickAccFunction(item.account_id)}
																		>
																			<div
																				className={`d-flex justify-content-between py-2 ${i !== 0 ? 'border-top' : ''}`}
																			>
																				<div className='w-55 align-self-center'>
																					<h7 className='font-weight-bold text-white'>
																						{capitalizeFirstLetter(
																							getAccountNameFromId(item.account_id, this.props.accounts)
																						)}
																					</h7>
																					<p className='f12 m-0'>{item.account_id}</p>
																				</div>
																				{/* <div className="align-self-center">
																					h7 className="mb-0 font-weight-bold blackMuted">{acc}</h7>
																				</div> */}
																				{!this.state.minimizeLeftDiv ? (
																					<div className='box bg-muted d-flex p-2 shadow-none heihgtFitContent mr-2 align-self-center'>
																						<div className='mr-2 width75'>
																							<p className='font-weight-bold m-0 f12'>
																								{this.state.minimizeLeftDiv ? 'P' : 'Pass'}&nbsp;:&nbsp;
																								<h7 className='font-weight-bold mb-0 text-warning'>
																									{item.pass_count && item.pass_count}
																								</h7>
																							</p>
																						</div>
																						<div className='mr-2 width75'>
																							<p className='font-weight-bold m-0 f12'>
																								{this.state.minimizeLeftDiv ? 'F' : 'Fail'}&nbsp;:&nbsp;
																								<h7 className='font-weight-bold mb-0 text-dark-theme-orange'>
																									{item.fail_count && item.fail_count}
																								</h7>
																							</p>
																						</div>
																					</div>
																				) : null}
																			</div>
																		</div>
																	)
																})
															) : (
																<div className='d-flex justify-content-center m-4'>
																	<p>There are no data on this criteria. Please try adjusting your search.</p>
																</div>
															)}
														</div>
													) : null}
												</div>
											</div>
											<div
												className={`${this.state.minimizeLeftDiv ? 'w-90 px-3' : 'w-75 px-3'} overflowYAuto`}
												ref={this.rightSectionRef}
												style={{ minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight }}
											>
												<div className='row border-bottom pb-3 ml-0 '>
													<div className='col-sm-9'>
														<div className='row d-flex p-2 bg-dark rounded'>
															{/* {this.state.showDonut && this.state.severityDonutData ? */}
															<div className='ml-5'>
																<ApexDonutChart
																	graphData={'mockData'}
																	legend={false}
																	showTotal={true}
																	showTotalLable={true}
																	showGraphHeading={false}
																	height={144}
																	width={144}
																	legendWidth={200}
																	size={'70%'}
																	gradient={false}
																	gradientColor={['#A88CCC', '#D88ACF']}
																	colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
																	className={'transparentTooltip'}
																/>
															</div>
															<div className='ml-5'>
																<ApexDonutChart
																	graphData={'mockData'}
																	legend={false}
																	showTotal={true}
																	showTotalLable={true}
																	showGraphHeading={false}
																	height={144}
																	width={144}
																	legendWidth={200}
																	size={'70%'}
																	gradient={false}
																	gradientColor={['#A88CCC', '#D88ACF']}
																	colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
																	className={'transparentTooltip'}
																/>
															</div>
															{this.state.transitionTrend &&
															this.state.transitionTrend.details &&
															Object.entries(this.state.transitionTrend.details).length
																? Object.entries(this.state.transitionTrend.details).map(([key, value]) => {
																		return key === 'new' || key === 'pending' ? (
																			<div className='w-25 pr-2 ml-5'>
																				<div className='d-flex'>
																					<h5 className='text-white'>
																						<small className='mr-2'>{capitalizeFirstLetter(key)}</small>
																						<b>{value.count ? value.count : 0}</b>
																						<span
																							className={`ml-2 f14 far fa-arrow-${
																								value.trend === null || value.trend === 'increase'
																									? 'up  text-success'
																									: 'down text-danger'
																							} mr-2`}
																						></span>
																						<small>{value.percent ? value.percent : 0}%</small>
																					</h5>
																				</div>
																				<div className='d-flex'>
																					<div className={`mr-3 risk-critical`}>
																						<p className='mb-0 font-weight-bold'>
																							{value.severity_wise && value.severity_wise.Critical
																								? value.severity_wise.Critical
																								: 0}
																						</p>
																						<p className='small font-weight-bold align-self-end'>Critical</p>
																					</div>
																					<div className={`mr-3 risk-high`}>
																						<p className='mb-0 font-weight-bold'>
																							{value.severity_wise && value.severity_wise.High
																								? value.severity_wise.High
																								: 0}
																						</p>
																						<p className='small font-weight-bold align-self-end'>High</p>
																					</div>
																					<div className='mr-3 risk-medium'>
																						<p className='mb-0 font-weight-bold'>
																							{value.severity_wise && value.severity_wise.Medium
																								? value.severity_wise.Medium
																								: 0}
																						</p>
																						<p className='small font-weight-bold align-self-end'>Medium</p>
																					</div>
																					<div className='mr-3 risk-low'>
																						<p className='mb-0 font-weight-bold'>
																							{value.severity_wise && value.severity_wise.Low
																								? value.severity_wise.Low
																								: 0}
																						</p>
																						<p className='small font-weight-bold align-self-end'>Low</p>
																					</div>
																				</div>
																				{this.state[key + '_trend_data'] &&
																				Object.keys(this.state[key + '_trend_data']).length ? (
																					<div className='w-100 ml-n2 mt-n4 mb-n4'>
																						<ApexAreaChart
																							graphData={this.state[key + '_trend_data']}
																							name={'New'}
																							xaxis={false}
																							yaxis={true}
																							yaxisLabe={false}
																							markerSize={2}
																							hideMarkerLength={30}
																							gradient={true}
																							gradientColor={['#775BA2', '#775BA2']}
																							colors={['#775BA2']}
																							sparkline={false}
																							zoom={false}
																							height={90}
																							width={'100%'}
																							grid={true}
																							className={'transparentTooltip'}
																						/>
																					</div>
																				) : null}
																			</div>
																		) : null
																  })
																: null}
														</div>
													</div>
													<div className='col-xl-3 m-0'>
														<div className='bg-dark p-2 rounded'>
															<div className={``}>
																<p className='mb-1 f16 text-white'>Policies</p>
																<div className='d-flex'>
																	<div className='pr-2 text-warning'>
																		<p className='f12 d-flex mb-0 font-weight-bold mr-1'>
																			{this.state.passSum && this.state.passSum}
																			<small className='d-flex pl-1 text-gray3 align-self-center'>
																				Pass
																				{/* (
																			{this.state.percentStatusCount && Object.entries(this.state.percentStatusCount).length ?
																				<div className="d-flex">
																					<span className= {`mr-2 align-self-center ${(this.state.percentStatusCount["current"].pass > this.state.percentStatusCount["past"].pass) ? "far fa-arrow-up text-success mr-2": "far fa-arrow-down text-success"} `} ></span>
																					<b>{this.state.percentStatusCount["percent"].pass} %</b>
																				</div>
																			: null
																			}
																		)  */}
																			</small>
																		</p>
																	</div>
																	<div className='text-dark-theme-orange'>
																		<p className='f12 d-flex mb-0 font-weight-bold mr-1'>
																			{this.state.failSum && this.state.failSum}
																			<small className='d-flex pl-1 text-gray3 align-self-center'>
																				Fail
																				{/* (
																			{this.state.failSum && Object.entries(this.state.failSum).length ?
																				<div className="d-flex">
																					<span className= {`mr-2 align-self-center ${(this.state.percentStatusCount["current"].fail > this.state.percentStatusCount["past"].fail) ? "far fa-arrow-up text-danger": "far fa-arrow-down text-danger"}`} ></span>
																				<b>{this.state.percentStatusCount["percent"].fail} %</b>			</div>
																			: null}
																		) */}
																			</small>
																		</p>
																	</div>
																</div>
															</div>
															<p className='m-0 f12 mb-0 pr-3 displayNone'> Daily trend is shown below</p>
															{this.state.multi_pass_fail_series && this.state.multi_pass_fail_series.length ? (
																<div className='transparentTooltip mt-3 mb-2'>
																	<Chart
																		type='bar'
																		series={this.state.multi_pass_fail_series}
																		options={this.state.multi_pass_fail_options}
																		height={75}
																	/>
																</div>
															) : null}
														</div>
													</div>
												</div>
												<div className='row border-bottom py-3 ml-0 '>
													<div className='col-xl-7 pl-0'>
														<div className='m-0 p-2 bg-dark rounded' style={{ minHeight: '378px' }}>
															{this.state.dayWiseRiskCountGraphData &&
															Object.keys(this.state.dayWiseRiskCountGraphData).length ? (
																<ApexRiskStackedBarChart
																	graphData={this.state.dayWiseRiskCountGraphData}
																	graphRiskCountSection={true}
																	sparkline={false}
																	yaxis={true}
																	yaxisLabel={true}
																	xaxis={true}
																	xaxisFormat={'string'}
																	xaxisLabel={true}
																	axisLabelColor={'#50586A'}
																	legend={false}
																	stacked={true}
																	height={280}
																	horizontal={false}
																	barHeight={'40%'}
																	barEndShape={'flat'}
																	columnWidth={'25%'}
																	backgroundBarShape={'rounded'}
																	backgroundBarColors={['#333947']}
																	showBackgroundBarColors={true}
																	className={'transparentTooltip'}
																	colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#039BE5']}
																/>
															) : null}
														</div>
													</div>
													<div className='col-xl-5 pl-0'>
														<div className='bg-dark p-3 rounded'>
															<label>Top 5 Violations</label>
															{this.state.top5Violations && Object.entries(this.state.top5Violations).length ? (
																<div className='mt-n2 mb-n2'>
																	<ApexBarChart
																		graphData={this.state.top5Violations}
																		sparkline={false}
																		yaxis={false}
																		yaxisLabel={false}
																		xaxis={true}
																		xaxisFormat={'string'}
																		xaxisLabel={true}
																		axisLabelColor={'#B8BBBE'}
																		paddingLeft={-15}
																		legend={false}
																		stacked={false}
																		height={319}
																		horizontal={true}
																		barHeight={'40%'}
																		barEndShape={'rounded'}
																		columnWidth={'25%'}
																		gradient={true}
																		gradientColor={['#039BE5', '#5F5BA2']}
																		hideTooltipValue={true}
																		backgroundBarShape={'rounded'}
																		backgroundBarColors={['#333947']}
																		showBackgroundBarColors={false}
																		className={'transparentTooltip'}
																	/>
																</div>
															) : null}
														</div>
													</div>
												</div>
												<div className='my-3 bg-dark p-2 rounded'>
													<div className='p-2 rounded'>
														<div className='d-flex justify-content-between'>
															<h5
																className='mb-0 text-purple3 cursorPointer d-flex'
																onClick={() =>
																	this.setState({ hideDistributionSection: !this.state.hideDistributionSection })
																}
															>
																{' '}
																Distribution
															</h5>

															<div className='d-flex'>
																<span
																	className={`ml-4 fa fa-angle-${
																		!this.state.hideDistributionSection ? 'up' : 'down'
																	} f22 text-primary-color cursorPointer`}
																	onClick={() =>
																		this.setState({ hideDistributionSection: !this.state.hideDistributionSection })
																	}
																></span>
															</div>
														</div>
														{!this.state.hideDistributionSection ? (
															<div className={`col-md-12 mt-1`} ref={this.rightSideTableRef}>
																<div className='row'>
																	<div className='col-sm-6 p-0' ref={this.rightRadialStackedBarWidthRef}>
																		{/* <p className="mb-0">By Controls</p> */}
																		<div className='d-flex justify-content-between'>
																			<div className=''>
																				<p className='mb-0'>Distribution</p>
																				<p className='small text-muted'>Showing distribution of Failed Checks</p>
																			</div>
																			<div className='d-flex'>
																				<p className='mb-0 mr-2 mt-2 text-muted'>show by</p>
																				<div
																					className='w-10 select-sm-bg curvedDropdown minWidth150'
																					ref={this.groupByRef}
																				>
																					<div className='dropdown'>
																						<span
																							className='dropdown-toggle d-flex justify-content-between px-2 text-white'
																							onClick={() =>
																								this.setState({ showGroupByOptions: !this.state.showGroupByOptions })
																							}
																						>
																							{this.state.groupBy === 'resource_type' ? (
																								'Service'
																							) : this.state.groupBy === 'title' ? (
																								'Category'
																							) : (
																								<span className='placeholder'>Select</span>
																							)}
																							<span className='caret'></span>
																							{/* <i className="fas fa-angle-down"></i> */}
																						</span>
																						<ul
																							className={`dropdown-menu p-0 ${
																								this.state.showGroupByOptions ? 'show' : ''
																							}`}
																						>
																							{this.state.selectedGovernanceType !== 'opa' ? (
																								<li
																									onClick={() =>
																										this.setState(
																											{ groupBy: 'resource_type', showGroupByOptions: false },
																											() => this.onChangeGroupBy('graph')
																										)
																									}
																									value={'resource_type'}
																									className={`${
																										this.state.groupBy === 'resource_type' ? 'selected' : ''
																									}`}
																								>
																									Service
																								</li>
																							) : null}
																							<li
																								onClick={() =>
																									this.setState({ groupBy: 'title', showGroupByOptions: false }, () =>
																										this.onChangeGroupBy('graph')
																									)
																								}
																								value={'title'}
																								className={`${this.state.groupBy === 'title' ? 'selected' : ''}`}
																							>
																								Category
																							</li>
																						</ul>
																					</div>
																				</div>
																			</div>
																		</div>
																		{this.state.showRightSideRadialChart ? (
																			<StackedCircleBarChart
																				ID={this.state.rightSideGraphId}
																				svgID={this.state.rightSideSvgGraphId}
																				width={
																					this.rightRadialStackedBarWidthRef.current &&
																					this.rightRadialStackedBarWidthRef.current.offsetWidth
																				}
																				data={this.state.rightSideArray}
																				keys={this.state.rightSideKeys}
																				userHighestValue={true}
																				highestValue={this.state.rightSideHighest}
																				hoverData={this.state.rightSideTotalData}
																				totalValue={this.state.rightSideTotal}
																				selectedState={
																					this.state.selectedChartControl ? this.state.selectedChartControl : ''
																				}
																				tableData={(data, State) => {
																					if (State && this.state.selectedChartControl !== State) {
																						this.setState(
																							{ selectedChartControl: State, showRightSideRadialChart: false },
																							() => {
																								this.setState(
																									{
																										showRightSideRadialChart: true,
																									},
																									() => this.rightSideDataFormat(data, State)
																								)
																							}
																						)
																					}
																				}}
																				legendText={'Violations'}
																				colors={this.state.rightSideGraphColors}
																			/>
																		) : (
																			<div className='col-lg-12 d-flex justify-content-center mt-4'>
																				<Spinner className={`align-self-center mr-2 text-white`} size='lg' />
																			</div>
																		)}
																	</div>
																	{this.state.showRightSideRadialChart ? (
																		<div className='col-sm-6'>
																			<p className='small mb-0 mt-2'>
																				Status by Violations
																				<p className='text-muted small'>
																					{this.state.controlName && this.state.controlName !== '' ? (
																						this.state.controlName
																					) : (
																						<span>&nbsp;</span>
																					)}
																				</p>
																			</p>
																			<div className='d-flex text-warning'>
																				<h4 className='mb-0 mr-3'>0325</h4>
																				<h3 className='mb-0 mr-2'>
																					<i className='fad fa-map-marker-alt'></i>
																				</h3>
																				<h3 className='mb-0 mr-2'>
																					<i className='fad fa-map-marker-alt'></i>
																				</h3>
																				<h3 className='mb-0 mr-2'>
																					<i className='fad fa-map-marker-alt'></i>
																				</h3>
																				<div className='flex-row ml-1'>
																					<p className='mb-0 text-nowrap small'>2032, 2031</p>
																					<p className='mb-0 text-nowrap small'>2032, 2031</p>
																				</div>
																			</div>
																			<div className='mt-3 radialTableSection' style={{ minHeight: '300px' }}>
																				{this.state.rightSideFilteredTableData &&
																				Object.entries(this.state.rightSideFilteredTableData).length ? (
																					<div className='' style={{ minHeight: '260px' }}>
																						{Object.entries(this.state.rightSideFilteredTableData)
																							.slice(
																								this.state.rightSideStart,
																								this.state.rightSideStart + this.state.rightSidePerPage
																							)
																							.map(([key, value], i) => {
																								return (
																									<div className='mt-2 col-12 p-0 bg-dark'>
																										<div className='count border-info d-flex p-2'>
																											<h5 className='col-sm-2 p-0 m-0'>{value}</h5>
																											<div className='col-sm-10 d-flex' id={'description_' + i}>
																												<p className='text-nowrap mb-0 align-self-end ml-2 cursorPointer'>
																													{key}
																												</p>
																											</div>
																										</div>
																									</div>
																								)
																							})}
																					</div>
																				) : null}
																				{this.state.rightSidePageCount > 1 ? (
																					<div className='pagination mt-3 justify-content-center d-flex'>
																						<span
																							className={`${this.state.disableLeftAction ? 'disabled text-muted' : ''}`}
																							onClick={e => {
																								e.preventDefault()
																								this.navigatePage('start', this.state.currentPage)
																							}}
																						>
																							<i className='fas fa-chevron-double-left'></i>
																						</span>
																						<span
																							className={`${this.state.disableLeftAction ? 'disabled text-muted' : ''}`}
																							onClick={e => {
																								e.preventDefault()
																								this.navigatePage('previous', this.state.currentPage)
																							}}
																						>
																							<i className='fas fa-chevron-left'></i>
																						</span>

																						{this.state.rightSidePageCountArray
																							.slice(this.state.ritPageStart, this.state.ritPageEnd)
																							.map((pag, index) => {
																								return (
																									<span
																										className={`${this.state.currentPage === pag ? 'active' : ''}`}
																										onClick={e => {
																											e.preventDefault()
																											this.navigatePage('page', index + 1)
																										}}
																									>
																										{pag}
																									</span>
																								)
																							})}
																						<span
																							className={`${
																								this.state.disableRightAction ? 'disabled text-muted' : ''
																							}`}
																							onClick={e => {
																								e.preventDefault()
																								this.navigatePage('next', this.state.currentPage)
																							}}
																						>
																							<i className='fas fa-chevron-right'></i>
																						</span>
																						<span
																							className={`${
																								this.state.disableRightAction ? 'disabled text-muted' : ''
																							}`}
																							onClick={e => {
																								e.preventDefault()
																								this.navigatePage('end', this.state.currentPage)
																							}}
																						>
																							<i className='fas fa-chevron-double-right'></i>
																						</span>
																					</div>
																				) : null}
																			</div>
																		</div>
																	) : null}
																</div>
															</div>
														) : null}
													</div>
												</div>
												<div className='my-3 bg-dark px-2 py-3 rounded'>
													<div className='col-sm-12 p-0'>
														<div className='accordion' id='accordionExample'>
															<div className='d-flex justify-content-between mb-1'>
																<div className='d-flex mb-1'>
																	<p className='mb-0 mr-2 mt-2 text-muted'>show by</p>
																	<div className='w-10 select-sm-bg curvedDropdown minWidth150' ref={this.groupByRef}>
																		<div className='dropdown'>
																			<span
																				className='dropdown-toggle d-flex justify-content-between px-2 text-white'
																				onClick={() =>
																					this.setState({ showGroupByOptions: !this.state.showGroupByOptions })
																				}
																			>
																				{this.state.groupBy === 'resource_type' ? (
																					'Service'
																				) : this.state.groupBy === 'title' ? (
																					'Category'
																				) : (
																					<span className='placeholder'>Select</span>
																				)}
																				<span className='caret'></span>
																				{/* <i className="fas fa-angle-down"></i> */}
																			</span>
																			<ul
																				className={`dropdown-menu p-0 ${this.state.showGroupByOptions ? 'show' : ''}`}
																			>
																				{this.state.selectedGovernanceType !== 'opa_kubernetes' && this.state.selectedGovernanceType !== 'opa' ?(
																				<li
																					onClick={() =>
																						this.setState({ groupBy: 'resource_type', showGroupByOptions: false }, () =>
																							this.onChangeGroupBy()
																						)
																					}
																					value={'resource_type'}
																					className={`${this.state.groupBy === 'resource_type' ? 'selected' : ''}`}
																				>
																					Service
																				</li>):null}
																				<li
																					onClick={() =>
																						this.setState({ groupBy: 'title', showGroupByOptions: false }, () =>
																							this.onChangeGroupBy()
																						)
																					}
																					value={'title'}
																					className={`${this.state.groupBy === 'title' ? 'selected' : ''}`}
																				>
																					Category
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
																<div className='d-flex justify-content-end mb-1'>
																	{this.state.viloationDataNew && this.state.viloationDataNew.length ? (
																		<div className='d-flex'>
																			<CSVLink
																				data={this.state.violationAllResults ? this.state.violationAllResults : []}
																				headers={this.state.violationHeaders ? this.state.violationHeaders : []}
																				filename={'governance_total_violations.csv'}
																				className={'mt-2'}
																				target='_blank'
																			>
																				<span className='d-flex text-primary-color mr-2'>
																					<i className='fas fa-download text-primary-color mr-2'></i>
																				</span>
																			</CSVLink>
																			<div className='form-group mb-0'>
																				<SearchWithHiddenInput
																					data={this.state.viloationDataNew}
																					applyLiteDarkTags={true}
																					searchClassName={'border-0'}
																					searchIconColor={'text-gray3'}
																					className='form-control-sm text-gray3 bg-muted border-0'
																					hideInputSection={true}
																					topClassName={'dark-search'}
																					filteredData={(filteredViolationDataNew, searchText) => {
																						this.setState({ filteredViolationDataNew }, () =>
																							this.combineAllVilationData()
																						)
																					}}
																				/>
																			</div>
																		</div>
																	) : null}
																	<div className='align-self-center'>
																		<ul className='stock-legend mb-0 p-0 ml-2'>
																			<li>
																				<span className='legend-circle-critical'></span>{' '}
																				<span className='text-muted d-inline-block'> &nbsp;Critical</span>
																			</li>
																			<li>
																				<span className='legend-circle-high'></span>{' '}
																				<span className='text-muted d-inline-block'> &nbsp; High</span>
																			</li>
																			<li>
																				<span className='legend-circle-medium'></span>{' '}
																				<span className='text-muted d-inline-block'> &nbsp; Medium</span>
																			</li>
																			<li>
																				<span className='legend-circle-low'></span>{' '}
																				<span className='text-muted d-inline-block'> &nbsp; Low</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															{this.state.filteredViolationDataNew && this.state.filteredViolationDataNew.length ? (
																this.state.filteredViolationDataNew.map((item, index) => {
																	return (
																		<div className={`card bg-dark3 border-0 mb-2`}>
																			<div
																				id={'heading_event'}
																				onClick={() =>
																					this.setState({
																						['expand_parent_' + index]: !this.state['expand_parent_' + index],
																					})
																				}
																			>
																				<div
																					className='p-3 mb-0 d-flex justify-content-between'
																					data-toggle='collapse'
																					data-target={'#collapse_event'}
																					aria-expanded='true'
																					aria-controls={'collapse_event'}
																				>
																					<div className='text-white'>
																						<span
																							className={`far ${
																								this.state['expand_parent_' + index]
																									? 'fa-minus-circle'
																									: 'fa-plus-circle'
																							} mr-2`}
																						></span>
																						{this.state.groupBy !== 'resource_type'
																							? item.category
																							: item.resource_type}
																					</div>
																					<div className='d-flex'>
																						{item.critical ? (
																							<span
																								className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-critical`}
																							>
																								C: {item.critical}
																							</span>
																						) : null}
																						{item.high ? (
																							<span
																								className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-high`}
																							>
																								H: {item.high}
																							</span>
																						) : null}
																						<div className='text-info'>
																							Showing{' '}
																							{this.state.groupBy === 'resource_type'
																								? item.category_count + ' service(s)'
																								: item.service_count +
																								  (item.category_count > 1 ? ' category' : ' categories')}{' '}
																							with {item.violation_count} violation(s) in {item.policy_count}{' '}
																							{item.policy_count > 1 ? 'policies' : 'policy'}
																						</div>
																					</div>
																				</div>
																			</div>
																			<div
																				id={'collapse'}
																				className={`collapse ${this.state['expand_parent_' + index] ? 'show' : ''}`}
																				aria-labelledby={'heading_'}
																				data-parent='#accordionExample'
																			>
																				{item.governance_results.map((dat, datIndex) => {
																					return (
																						<div className='card-body pb-2'>
																							<div className={`card bg-dark border-0`}>
																								<div id={'heading_event'}>
																									<div
																										className='p-3 mb-0 d-flex justify-content-between'
																										data-toggle='collapse'
																										data-target={'#collapse_event'}
																										aria-expanded='true'
																										aria-controls={'collapse_event'}
																									>
																										<div
																											className='text-white'
																											onClick={() =>
																												this.setState({
																													['expand_child_' + index + '_' + datIndex]: !this.state[
																														'expand_child_' + index + '_' + datIndex
																													],
																												})
																											}
																										>
																											<span
																												className={`far ${
																													this.state['expand_child_' + index + '_' + datIndex]
																														? 'fa-minus-circle'
																														: 'fa-plus-circle'
																												} mr-2`}
																											></span>
																											{dat.governance_item}
																										</div>
																										<div className='d-flex'>
																											{this.state['expand_child_' + index + '_' + datIndex] &&
																											dat.results &&
																											dat.results.length ? (
																												<React.Fragment>
																													<div className='dlpDescriptionForm dlpDescriptionFormPlain mt-n2'>
																														<Select
																															isSearchable={true}
																															className={`selectOption mr-1`}
																															components={{
																																IndicatorSeparator: () => null,
																															}}
																															value={{
																																value:
																																	this.state[
																																		'selectedRisk_' + index + '_' + datIndex
																																	] &&
																																	this.state[
																																		'selectedRisk_' + index + '_' + datIndex
																																	] !== ''
																																		? this.state[
																																				'selectedRisk_' + index + '_' + datIndex
																																		  ]
																																		: '',
																																label:
																																	this.state[
																																		'selectedRisk_' + index + '_' + datIndex
																																	] &&
																																	this.state[
																																		'selectedRisk_' + index + '_' + datIndex
																																	] !== '' ? (
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																																	) : (
																																		<span className='placeholder'>All</span>
																																	),
																															}}
																															options={
																																riskOptions &&
																																riskOptions.map(item => ({
																																	value: item.value,
																																	label: item.label,
																																}))
																															}
																															onChange={event =>
																																this.setState({
																																	['selectedRisk_' +
																																	index +
																																	'_' +
																																	datIndex]: event.value,
																																	issueStatusFilter: false,
																																})
																															}
																														/>
																													</div>
																													{/* <div className='d-flex'>
																													<div className="select-sm-bg curvedDropdown minWidth120">
																														<div className="dropdown">
																															<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueStatusFilter: !this.state.issueStatusFilter })}>
																																{this.state["selectedRisk_"+index+"_"+datIndex] ? riskOptions.filter(e => e.value === this.state["selectedRisk_"+index+"_"+datIndex])[0].label : <span className="placeholder">Status</span>}
																																<span className="caret"></span>
																															</span>
																															<ul className={`dropdown-menu p-0 ${this.state.issueStatusFilter ? "show" : ''}`}>
																																{riskOptions.map(item => {
																																	return (
																																		<li 
																																			onClick={() => this.setState({ 
																																				["selectedRisk_"+index+"_"+datIndex]: item.value, 
																																				issueStatusFilter: false
																																			})}
																																		>
																																			{item.label}
																																		</li>
																																	)
																																})}
																															</ul>
																														</div>
																													</div>
																												</div> */}
																													<div className='ml-3 d-flex'>
																														<div className='align-self-center'>
																															<span
																																onClick={() =>
																																	this.setState(
																																		{
																																			['selectedStatus_' + index + '_' + datIndex]:
																																				this.state[
																																					'selectedStatus_' + index + '_' + datIndex
																																				] === 'pass'
																																					? ''
																																					: 'pass',
																																		},
																																		() => this.formDownloadData(index, datIndex)
																																	)
																																}
																																className={`cursor-pointer badge mr-2 ${
																																	this.state[
																																		'selectedStatus_' + index + '_' + datIndex
																																	] === 'pass'
																																		? 'badge-pass'
																																		: 'badge-outline-pass'
																																} `}
																															>
																																Pass
																															</span>
																														</div>
																														<div className='align-self-center'>
																															<span
																																onClick={() =>
																																	this.setState(
																																		{
																																			['selectedStatus_' + index + '_' + datIndex]:
																																				this.state[
																																					'selectedStatus_' + index + '_' + datIndex
																																				] === 'fail'
																																					? ''
																																					: 'fail',
																																		},
																																		() => this.formDownloadData(index, datIndex)
																																	)
																																}
																																className={`cursor-pointer badge mr-2 ${
																																	this.state[
																																		'selectedStatus_' + index + '_' + datIndex
																																	] === 'fail'
																																		? 'badge-fail'
																																		: 'badge-outline-fail'
																																}`}
																															>
																																Fail
																															</span>
																														</div>
																													</div>
																												</React.Fragment>
																											) : null}
																											{this.state['expand_child_' + index + '_' + datIndex] &&
																											dat.results &&
																											dat.results.length ? (
																												<div className='d-flex'>
																													<div className='form-group mb-0'>
																														<SearchWithHiddenInput
																															ID={'anomaly_search'}
																															data={dat.results ? dat.results : dat.results}
																															applyLiteDarkTags={true}
																															searchClassName={'border-0'}
																															searchIconColor={'text-gray3'}
																															className='form-control-sm text-gray3 bg-muted border-0'
																															hideInputSection={true}
																															topClassName={'dark-search'}
																															filteredData={(filteredArray, searchText) => {
																																this.setState(
																																	{
																																		['filteredArray_' +
																																		index +
																																		'_' +
																																		datIndex]: filteredArray,
																																		['search_' + index + '_' + datIndex]: searchText,
																																	},
																																	() => this.formDownloadData(index, datIndex)
																																)
																															}}
																														/>
																													</div>
																												</div>
																											) : null}
																											{this.state['expand_child_' + index + '_' + datIndex] &&
																											dat.results &&
																											dat.results.length ? (
																												<React.Fragment>
																													{/* <i class="fas fa-download mr-4 f14 align-self-center" onClick={() => this.downloadCsv(item.resource_type, dat, index, datIndex)}></i>
																													 */}
																													{/* <i class="fas fa-download mr-4 f14 align-self-center" onClick={() => this.formDownloadData(dat.results, index, datIndex)}></i> */}
																													{/* {(this.state["search_"+index+"_"+datIndex] && this.state["search_"+index+"_"+datIndex] !=="") || (this.state["selectedStatus_"+index+"_"+datIndex] && this.state["selectedStatus_"+index+"_"+datIndex] !== "All") || (this.state["selectedStatus_"+index+"_"+datIndex] && this.state["selectedStatus_"+index+"_"+datIndex] !== "") || (this.state["selectedRisk_"+index+"_"+datIndex] && this.state["selectedRisk_"+index+"_"+datIndex] !== "All") && (this.state["filteredArray_"+index+"_"+datIndex] && this.state["filteredArray_"+index+"_"+datIndex].length) && dat.results.length ? */}
																													<CSVLink
																														data={
																															this.state['filteredArray_' + index + '_' + datIndex] &&
																															this.state['filteredArray_' + index + '_' + datIndex]
																																.length
																																? this.state['filteredArray_' + index + '_' + datIndex]
																																: dat.results
																														}
																														headers={this.state['headers_' + index + '_' + datIndex]}
																														filename={'governance-violations.csv'}
																														className={'align-self-center mr-2'}
																														target='_blank'
																													>
																														<i className='fas fa-download text-primary-color'></i>
																														{/* <span className="text-primary-color mx-2">csv</span> */}
																													</CSVLink>
																													{/* : null} */}
																												</React.Fragment>
																											) : null}
																											{dat.critical ? (
																												<span
																													className={`f10 mr-2 align-self-center badge-sm risk-badge-critical`}
																												>
																													C: {dat.critical}
																												</span>
																											) : null}
																											{dat.high ? (
																												<span
																													className={`f10 mr-2 align-self-center badge-sm risk-badge-high`}
																												>
																													H: {dat.high}
																												</span>
																											) : null}
																											<div
																												className='text-info align-self-center'
																												onClick={() =>
																													this.setState({
																														['expand_child_' + index + '_' + datIndex]: !this.state[
																															'expand_child_' + index + '_' + datIndex
																														],
																													})
																												}
																											>
																												Showing {dat.results && dat.results.length} violation(s)
																											</div>
																										</div>
																									</div>
																								</div>

																								<div
																									id={'collapse'}
																									className={`collapse ${
																										this.state['expand_child_' + index + '_' + datIndex] ? 'show' : ''
																									}`}
																									aria-labelledby={'heading_'}
																									data-parent='#accordionExample'
																								>
																									<div className='card-body'>
																										{this.state.selectedGovernanceType !== 'opa_kubernetes' ? (
																											<ResizeableDarkThemeTable
																												columns={[
																													{
																														Header: 'Risk',
																														accessor: 'risk',
																														Cell: cellInfo => (
																															<span
																																className={`badge risk-badge-${
																																	cellInfo.row.original.risk
																																		? cellInfo.row.original.risk.toLowerCase()
																																		: ''
																																} aiopsTableBadgeWidth`}
																															>
																																{cellInfo.row.original.risk
																																	? cellInfo.row.original.risk.toLowerCase() ===
																																	  'critical'
																																		? 'C'
																																		: cellInfo.row.original.risk.toLowerCase() ===
																																		  'high'
																																		? 'H'
																																		: cellInfo.row.original.risk.toLowerCase() ===
																																		  'medium'
																																		? 'M'
																																		: cellInfo.row.original.risk.toLowerCase() === 'low'
																																		? 'L'
																																		: ''
																																	: 'U'}
																															</span>
																														),
																														width: 80,
																													},
																													{
																														Header: 'Date',
																														accessor: 'timestamp',
																														Cell: cellInfo => (
																															<span>
																																{cellInfo.row.original.status &&
																																cellInfo.row.original.status !== 'pass'
																																	? cellInfo.row.original.resolution_status_info &&
																																	  cellInfo.row.original.resolution_status_info
																																			.timestamp &&
																																	  momentConvertionUtcToLocalTime(
																																			cellInfo.row.original.resolution_status_info
																																				.timestamp,
																																			'DD MMM YYYY HH:mm'
																																	  )
																																	: momentConvertionUtcToLocalTime(
																																			cellInfo.row.original.timestamp,
																																			'DD MMM YYYY HH:mm'
																																	  )}
																															</span>
																														),
																														width: 180,
																													},
																													{
																														Header: 'Policy Name',
																														accessor: 'policy_name',
																														width: 150,
																													},
																													{
																														Header: 'Resources',
																														accessor: d =>
																															d.provider + ' : ' + d.account_id + ' : ' + d.region,
																														Cell: cellInfo => (
																															<React.Fragment>
																																<span className=''>
																																	{(cellInfo.row.original.asset_name
																																		? cellInfo.row.original.asset_name + ' : '
																																		: '') +
																																		cellInfo.row.original.provider +
																																		' : ' +
																																		cellInfo.row.original.account_id +
																																		' : ' +
																																		(cellInfo.row.original.region === 'NA'
																																			? 'Global'
																																			: cellInfo.row.original.region)}
																																</span>
																															</React.Fragment>
																														),
																														width: 200,
																													},
																													{
																														Header: 'Services',
																														accessor: d => d.resource_type + ' : ' + d.service_name,
																														width: 150,
																													},
																													{
																														Header: 'Status',
																														accessor: 'status',
																														Cell: cellInfo => (
																															<span
																																className={`badge status-badge badge-outline-${
																																	cellInfo.row.original.status === 'pass'
																																		? 'pass'
																																		: 'fail'
																																} badge-pill`}
																															>
																																{capitalizeFirstLetter(cellInfo.row.original.status)}
																															</span>
																														),
																														width: 100,
																													},
																													{
																														Header: 'Description',
																														accessor: 'description',
																														Cell: cellInfo => (
																															<React.Fragment>
																																<span className=''>
																																	{(cellInfo.row.original.description
																																		? cellInfo.row.original.description
																																		: '') +
																																		'' +
																																		(cellInfo.row.original.status &&
																																		cellInfo.row.original.status === 'fail' &&
																																		cellInfo.row.original.detail
																																			? ' - ' + cellInfo.row.original.detail
																																			: '')}
																																</span>
																															</React.Fragment>
																														),
																														width: 370,
																														className: 'whiteSpaceUnset',
																													},
																													{
																														Header: ' ',
																														accessor: ' ',
																														width: 1,
																													},
																												]}
																												data={
																													this.state['search_' + index + '_' + datIndex] &&
																													this.state['search_' + index + '_' + datIndex] !== ''
																														? this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All' &&
																														  this.state['selectedStatus_' + index + '_' + datIndex]
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.status ===
																																			this.state[
																																				'selectedStatus_' + index + '_' + datIndex
																																			] &&
																																		e.risk ===
																																			this.state[
																																				'selectedRisk_' + index + '_' + datIndex
																																			]
																															  )
																															: this.state['selectedRisk_' + index + '_' + datIndex] &&
																															  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																	'All'
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.risk ===
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																															  )
																															: this.state['selectedStatus_' + index + '_' + datIndex]
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.status ===
																																		this.state[
																																			'selectedStatus_' + index + '_' + datIndex
																																		]
																															  )
																															: this.state['filteredArray_' + index + '_' + datIndex]
																														: this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All' &&
																														  this.state['selectedStatus_' + index + '_' + datIndex]
																														? dat.results.filter(
																																e =>
																																	e.status ===
																																		this.state[
																																			'selectedStatus_' + index + '_' + datIndex
																																		] &&
																																	e.risk ===
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																														  )
																														: this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All'
																														? dat.results.filter(
																																e =>
																																	e.risk ===
																																	this.state['selectedRisk_' + index + '_' + datIndex]
																														  )
																														: this.state['selectedStatus_' + index + '_' + datIndex]
																														? dat.results.filter(
																																e =>
																																	e.status ===
																																	this.state['selectedStatus_' + index + '_' + datIndex]
																														  )
																														: dat.results
																												}
																												onClickRow={tableRow => {
																													this.setState({ selectedViolation: tableRow }, () =>
																														this.setState({ showDashboardPanel: true })
																													)
																												}}
																												selectedRecord={this.state.selectedTableRow}
																												perPage={20}
																												dashboard={
																													(this.state['search_' + index + '_' + datIndex] &&
																													this.state['search_' + index + '_' + datIndex] !== ''
																														? this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All' &&
																														  this.state['selectedStatus_' + index + '_' + datIndex]
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.status ===
																																			this.state[
																																				'selectedStatus_' + index + '_' + datIndex
																																			] &&
																																		e.risk ===
																																			this.state[
																																				'selectedRisk_' + index + '_' + datIndex
																																			]
																															  )
																															: this.state['selectedRisk_' + index + '_' + datIndex] &&
																															  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																	'All'
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.risk ===
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																															  )
																															: this.state['selectedStatus_' + index + '_' + datIndex]
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.status ===
																																		this.state[
																																			'selectedStatus_' + index + '_' + datIndex
																																		]
																															  )
																															: this.state['filteredArray_' + index + '_' + datIndex]
																														: this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All' &&
																														  this.state['selectedStatus_' + index + '_' + datIndex]
																														? dat.results.filter(
																																e =>
																																	e.status ===
																																		this.state[
																																			'selectedStatus_' + index + '_' + datIndex
																																		] &&
																																	e.risk ===
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																														  )
																														: this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All'
																														? dat.results.filter(
																																e =>
																																	e.risk ===
																																	this.state['selectedRisk_' + index + '_' + datIndex]
																														  )
																														: this.state['selectedStatus_' + index + '_' + datIndex]
																														? dat.results.filter(
																																e =>
																																	e.status ===
																																	this.state['selectedStatus_' + index + '_' + datIndex]
																														  )
																														: dat.results
																													).length
																														? true
																														: false
																												}
																												sortByColumn={'timestamp'}
																												tooltipFor={[]}
																												// riskTooltip={[0]}
																												tableBody={'bg-dark3'}
																												rounded={true}
																												// selectedColumn={'bg-light'}
																												// columnFontSize={'f12'}
																											/>
																										) : this.state.selectedGovernanceType === 'opa_kubernetes' ? (
																											<ResizeableDarkThemeTable
																												columns={[
																													{
																														Header: 'Risk',
																														accessor: 'risk',
																														Cell: cellInfo => (
																															<span
																																className={`badge risk-badge-${
																																	cellInfo.row.original.risk
																																		? cellInfo.row.original.risk.toLowerCase()
																																		: ''
																																} aiopsTableBadgeWidth`}
																															>
																																{cellInfo.row.original.risk
																																	? cellInfo.row.original.risk.toLowerCase() ===
																																	  'critical'
																																		? 'C'
																																		: cellInfo.row.original.risk.toLowerCase() ===
																																		  'high'
																																		? 'H'
																																		: cellInfo.row.original.risk.toLowerCase() ===
																																		  'medium'
																																		? 'M'
																																		: cellInfo.row.original.risk.toLowerCase() === 'low'
																																		? 'L'
																																		: ''
																																	: 'U'}
																															</span>
																														),
																														width: 80,
																													},
																													{
																														Header: 'Date',
																														accessor: 'timestamp',
																														Cell: cellInfo => (
																															<span>
																																{cellInfo.row.original.status &&
																																cellInfo.row.original.status !== 'pass'
																																	? cellInfo.row.original.resolution_status_info &&
																																	  cellInfo.row.original.resolution_status_info
																																			.timestamp &&
																																	  momentConvertionUtcToLocalTime(
																																			cellInfo.row.original.resolution_status_info
																																				.timestamp,
																																			'DD MMM YYYY HH:mm'
																																	  )
																																	: momentConvertionUtcToLocalTime(
																																			cellInfo.row.original.timestamp,
																																			'DD MMM YYYY HH:mm'
																																	  )}
																															</span>
																														),
																														width: 180,
																													},
																													{
																														Header: 'Policy Name',
																														accessor: 'policy_name',
																														width: 150,
																													},
																													{
																														Header: 'Cluster',
																														accessor: d => d.cluster_details,
																														Cell: cellInfo => (
																															<React.Fragment>
																																{cellInfo.row.original.cluster_details &&
																																cellInfo.row.original.cluster_details.clustername ? (
																																	<span>
																																		{cellInfo.row.original.cluster_details.clustername}
																																	</span>
																																) : (
																																	''
																																)}
																																:
																																{cellInfo.row.original.cluster_details &&
																																cellInfo.row.original.cluster_details.clusterid ? (
																																	<span>
																																		{cellInfo.row.original.cluster_details.clusterid}
																																	</span>
																																) : (
																																	''
																																)}
																															</React.Fragment>
																														),
																														width: 150,
																													},
																													{
																														Header: 'Resources',
																														accessor: 'resource_type',
																														width: 130,
																													},
																													{
																														Header: 'Services',
																														accessor: 'service_name',
																														width: 200,
																													},
																													{
																														Header: 'Name Space',
																														accessor: 'namespace',
																														width: 170,
																													},
																													{
																														Header: 'Resource Id',
																														accessor: 'resource_id',
																														width: 200,
																													},
																													{
																														Header: 'Status',
																														accessor: 'status',
																														Cell: cellInfo => (
																															<span
																																className={`badge status-badge badge-outline-${
																																	cellInfo.row.original.status === 'pass'
																																		? 'pass'
																																		: 'fail'
																																} badge-pill`}
																															>
																																{capitalizeFirstLetter(cellInfo.row.original.status)}
																															</span>
																														),
																														width: 100,
																													},
																													{
																														Header: 'Description',
																														accessor: 'description',
																														Cell: cellInfo => (
																															<React.Fragment>
																																<span className=''>
																																	{(cellInfo.row.original.description
																																		? cellInfo.row.original.description
																																		: '') +
																																		'' +
																																		(cellInfo.row.original.status &&
																																		cellInfo.row.original.status === 'fail' &&
																																		cellInfo.row.original.detail
																																			? ' - ' + cellInfo.row.original.detail
																																			: '')}
																																</span>
																															</React.Fragment>
																														),
																														width: 370,
																														className: 'whiteSpaceUnset',
																													},
																													{
																														Header: ' ',
																														accessor: ' ',
																														width: 1,
																													},
																												]}
																												data={
																													this.state['search_' + index + '_' + datIndex] &&
																													this.state['search_' + index + '_' + datIndex] !== ''
																														? this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All' &&
																														  this.state['selectedStatus_' + index + '_' + datIndex]
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.status ===
																																			this.state[
																																				'selectedStatus_' + index + '_' + datIndex
																																			] &&
																																		e.risk ===
																																			this.state[
																																				'selectedRisk_' + index + '_' + datIndex
																																			]
																															  )
																															: this.state['selectedRisk_' + index + '_' + datIndex] &&
																															  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																	'All'
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.risk ===
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																															  )
																															: this.state['selectedStatus_' + index + '_' + datIndex]
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.status ===
																																		this.state[
																																			'selectedStatus_' + index + '_' + datIndex
																																		]
																															  )
																															: this.state['filteredArray_' + index + '_' + datIndex]
																														: this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All' &&
																														  this.state['selectedStatus_' + index + '_' + datIndex]
																														? dat.results.filter(
																																e =>
																																	e.status ===
																																		this.state[
																																			'selectedStatus_' + index + '_' + datIndex
																																		] &&
																																	e.risk ===
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																														  )
																														: this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All'
																														? dat.results.filter(
																																e =>
																																	e.risk ===
																																	this.state['selectedRisk_' + index + '_' + datIndex]
																														  )
																														: this.state['selectedStatus_' + index + '_' + datIndex]
																														? dat.results.filter(
																																e =>
																																	e.status ===
																																	this.state['selectedStatus_' + index + '_' + datIndex]
																														  )
																														: dat.results
																												}
																												onClickRow={tableRow => {
																													this.setState({ selectedViolation: tableRow }, () =>
																														this.setState({ showDashboardPanel: true })
																													)
																												}}
																												dashboard={
																													(this.state['search_' + index + '_' + datIndex] &&
																													this.state['search_' + index + '_' + datIndex] !== ''
																														? this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All' &&
																														  this.state['selectedStatus_' + index + '_' + datIndex]
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.status ===
																																			this.state[
																																				'selectedStatus_' + index + '_' + datIndex
																																			] &&
																																		e.risk ===
																																			this.state[
																																				'selectedRisk_' + index + '_' + datIndex
																																			]
																															  )
																															: this.state['selectedRisk_' + index + '_' + datIndex] &&
																															  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																	'All'
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.risk ===
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																															  )
																															: this.state['selectedStatus_' + index + '_' + datIndex]
																															? this.state[
																																	'filteredArray_' + index + '_' + datIndex
																															  ].filter(
																																	e =>
																																		e.status ===
																																		this.state[
																																			'selectedStatus_' + index + '_' + datIndex
																																		]
																															  )
																															: this.state['filteredArray_' + index + '_' + datIndex]
																														: this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All' &&
																														  this.state['selectedStatus_' + index + '_' + datIndex]
																														? dat.results.filter(
																																e =>
																																	e.status ===
																																		this.state[
																																			'selectedStatus_' + index + '_' + datIndex
																																		] &&
																																	e.risk ===
																																		this.state['selectedRisk_' + index + '_' + datIndex]
																														  )
																														: this.state['selectedRisk_' + index + '_' + datIndex] &&
																														  this.state['selectedRisk_' + index + '_' + datIndex] !==
																																'All'
																														? dat.results.filter(
																																e =>
																																	e.risk ===
																																	this.state['selectedRisk_' + index + '_' + datIndex]
																														  )
																														: this.state['selectedStatus_' + index + '_' + datIndex]
																														? dat.results.filter(
																																e =>
																																	e.status ===
																																	this.state['selectedStatus_' + index + '_' + datIndex]
																														  )
																														: dat.results
																													).length
																														? true
																														: false
																												}
																												selectedRecord={this.state.selectedTableRow}
																												perPage={20}
																												sortByColumn={'timestamp'}
																												tooltipFor={[]}
																												tableBody={'bg-dark3'}
																												rounded={true}
																											/>
																										) : null}
																										{(this.state['search_' + index + '_' + datIndex] &&
																										this.state['search_' + index + '_' + datIndex] !== ''
																											? this.state['filteredArray_' + index + '_' + datIndex]
																											: dat.results
																										).map((row, rowIndex) => {
																											return (
																												<div
																													className={`px-2 ${
																														!rowIndex ? '' : 'border-top-black5 pt-2'
																													} displayNone`}
																													onClick={() => {
																														this.setState({ selectedViolation: row }, () =>
																															this.setState({ showDashboardPanel: true })
																														)
																													}}
																												>
																													<div className={`d-flex cursorPointer mb-2`}>
																														<span
																															className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${
																																row.risk ? row.risk.toLowerCase() : 'critical'
																															}`}
																														>
																															{row.risk
																																? row.risk.toLowerCase() === 'critical'
																																	? 'C'
																																	: row.risk.toLowerCase() === 'high'
																																	? 'H'
																																	: row.risk.toLowerCase() === 'medium'
																																	? 'M'
																																	: 'L'
																																: 'U'}
																														</span>
																														<p className='mb-0'>
																															<span className='mb-0 mr-2 text-white f12 font-weight-bold text-primary-color'>
																																{row.timestamp
																																	? momentConvertionUtcToLocalTime(
																																			row.timestamp,
																																			'DD MMM YYYY HH:mm'
																																	  )
																																	: ''}{' '}
																															</span>
																															{/* <span className={`mb-0 mr-2 f12 text-purple-2`}>
																															{row.event_source ? (Array.isArray(row.event_source) ? row.event_source.join(", ") : row.event_source) : ""}
																															{row.event_name ? ' : ' + row.event_name : ""}
																															{row.event_type ? ' : '+ row.event_type : ""}
																														</span> */}
																															{row.policy_name ? (
																																<span className='mb-0 mr-2 f12 text-white font-weight-bold'>
																																	{row.policy_name}
																																</span>
																															) : null}
																															{row.service_id ? (
																																<span className='mb-0 mr-2 f12 text-white font-weight-bold'>
																																	{row.service_id}
																																</span>
																															) : null}
																															{row.asset_name ? (
																																<span className='mb-0 mr-2 f12 text-white font-weight-bold'>
																																	{row.asset_name}
																																</span>
																															) : null}
																															<span className='mb-0 small mr-1 text-primary-color'>
																																Resource
																															</span>
																															{row.account_id ? (
																																<span className='mb-0 f12 text-primary-color font-weight-bold'>
																																	{' ' + row.account_id}
																																</span>
																															) : null}
																															{row.region ? (
																																<span className='mb-0 f12 text-primary-color font-weight-bold'>
																																	{' : ' + row.region}
																																</span>
																															) : null}
																															{row.resource_type ? (
																																<span className='mb-0 f12 text-primary-color font-weight-bold'>
																																	{' : ' +
																																		(Array.isArray(row.resource_type)
																																			? row.resource_type.join(', ')
																																			: row.resource_type)}
																																</span>
																															) : null}
																															{row.service_name ? (
																																<span className='mb-0 f12 text-primary-color font-weight-bold mr-2'>
																																	{' : ' + row.service_name}
																																</span>
																															) : null}
																															{row.detail ? (
																																<span className='mb-0 ml-2 f12 text-white font-weight-bold'>
																																	{row.detail}
																																</span>
																															) : null}
																														</p>
																													</div>
																												</div>
																											)
																										})}
																									</div>
																								</div>
																							</div>
																						</div>
																					)
																				})}
																			</div>
																		</div>
																	)
																})
															) : (
																<div className='col-lg-12 d-flex justify-content-center my-4'>
																	<p>There are no data on this criteria. Please try adjusting your search.</p>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Dashboard.propTypes = {
	listAllProviders: PropTypes.func,
	listAllAccounts: PropTypes.func,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
	}
}

export default AppWrapper(Dashboard, mapStateToProps, {
	listAllProviders,
	listAllAccounts,

	newGovernanceGetTotalCount,
	newGovernanceGetDayWiseCount,
	newGovernanceGetTransitionTrend,
	getGovernanceViolations,
})
