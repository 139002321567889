/*************************************************
 * Tvastar
 * @exports
 * @file ViewSupressSection.js
 * @author Rajasekar // on 09/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { deleteSupression } from '../../../actions/detectors/suppressorsAction'

import SupressForm from './SupressForm'
import { connect } from 'react-redux'
import { momentConvertionUtcToLocalTime } from '../../../utils/utility'

class SupressSection extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isCreateClicked: false,
		}
	}

	toggleDeleteModal = () => this.setState({ showDeleteConfigModal: !this.state.showDeleteConfigModal })

	/**
	 * Deletes configuartion
	 */
	deleteSupress = item => {
		this.props.deleteSupression(
			{
				provider: item.provider,
				service_name: item.service_name,
				region: 'us-east-2',
				account_id: '150143397566',
				organization: '8kmiles',
				tags: [
					{
						key: 'project',
						value: 'pana-life',
					},
					{
						key: 'application',
						value: 'config',
					},
				],
				asset_name: 'subnet-5d5a2235',
				key_name: 'subnet block',
				module_id: 'AWSVPC027',
				module_name: 'identifier',
				configuration_id: '905868963',
			},
			() => {}
		)
	}

	render() {
		return (
			<div className='pop-slider bg-dark3'>
				<div className='container-fluid bg-dark'>
					<div className='row pt-2 pb-2'>
						<div className='col-8 pr-0 align-self-center'>
							<h6 className='m-0 text-white'>
								<i
									className='far fa-arrow-left mr-2 cursor-pointer'
									onClick={() => this.props.onClickSupressBackButton(false)}
								/>{' '}
								Suppress
							</h6>
						</div>
						<div className='col-4 text-right'>
							<button
								className='btn btn-sm btn-primary'
								onClick={() => {
									this.setState({ isCreateClicked: true })
									this.props.onClickSupressCreateButton(true)
								}}
							>
								Create
							</button>
						</div>
					</div>
				</div>
				<div className='container-fluid overflow-auto'>
					<div className='row'>
						<div className='col-12'>
							{this.props.selectedRowAssetDetails && this.props.selectedRowAssetDetails.length ? (
								<React.Fragment>
									<p className='mb-0 mt-3 text-white'>Asset Details</p>
									<div class="bg-dark mt-2 p-1 rounded">
										<div class="d-flex py-2 mb-2 border-bottom">
											<div className='col-sm-7'>
												<span className='text-muted'>Service :</span> <br />
												<span className='text-white breakWord'>{this.props.selectedRowAssetDetails[0].service_name ? this.props.selectedRowAssetDetails[0].service_name : ''}</span>
											</div>
											<div className='m-auto bd-highlight col-sm-5'>
												<span className='text-muted'>IP Address:</span> <br />
												<span className='text-dark breakWord'>-</span>
											</div>
										</div>
										<div class="d-flex pb-2 mb-2 border-bottom">
											<div className='col-sm-12'>
												<span className='text-muted'>VPC :</span> <br />
												<span className='text-dark breakWord'>{this.props.selectedRowAssetDetails[0].vpc_id ? this.props.selectedRowAssetDetails[0].vpc_id : <span>&nbsp;</span>}</span>
											</div>
										</div>
										<div class="d-flex pb-2 mb-2 border-bottom">
											<div className='col-sm-12'>
												<span className='text-muted'>ARN :</span> <br />
												<span className='text-dark breakWord'>
													{this.props.selectedRowAssetDetails[0].action_by ?
														this.props.selectedRowAssetDetails[0].action_by.split('/')[0] 
													: ''}
												</span>
											</div>
										</div>
										<div class="d-flex pb-2 mb-2 border-bottom">
											<div className='col-sm-12'>
												<span className='text-muted'>Created At :</span> <br />
												<span className='text-dark breakWord'>{this.props.selectedRowAssetDetails[0].asset_time ? momentConvertionUtcToLocalTime(this.props.selectedRowAssetDetails[0].asset_time, "DD MMM YYYY HH:mm") : ''}</span>
											</div>
										</div>
										<div class="d-flex pb-2 mb-2 border-bottom">
											<div className='m-auto bd-highlight col-sm-12'>
												<span className='text-muted'>Created By:</span> <br />
												<span className='text-dark breakWord text-break'>
													{this.props.selectedRowAssetDetails[0].action_by ?
														this.props.selectedRowAssetDetails[0].action_by.split('/')[1] : ''}
												</span>
											</div>
										</div>
										<div class="d-flex pb-2 mb-2 border-bottom">
											<div className='col-sm-12'>
												<span className='text-muted'>Last Modified :</span> <br />
												<span className='text-dark breakWord'>{this.props.selectedRowAssetDetails[0].action_date ? momentConvertionUtcToLocalTime(this.props.selectedRowAssetDetails[0].action_date, "DD MMM YYYY HH:mm") : ''}</span>
											</div>
										</div>
										<div class="d-flex pb-2 mb-2">
											<div className='m-auto bd-highlight col-sm-12'>
												<span className='text-muted text-break'>Last Modified By:</span> <br />
												<span className='text-dark breakWord'>
													{this.props.selectedRowAssetDetails[0].action_by ?
														this.props.selectedRowAssetDetails[0].action_by.split('/')[1] : ''}
												</span>
											</div>
										</div>
									</div>
								</React.Fragment>
							) : this.props.showLoading ? (
								<div className='d-flex justify-content-center m-4'>
									<Spinner className='text-center' color='dark' size='lg' />
								</div>
							) : null}
							{/* <p className='mb-0 mt-3'>Rules</p> */}
							{this.props.showSupressForm ? (
								<React.Fragment>
									<p className='mb-0 mt-3 text-white'>Rules</p>
									<SupressForm
										providersData={this.props.providersData}
										data={this.props.selectedRowAssetDetails && this.props.selectedRowAssetDetails[0]}
										onClickBackButton={() => this.props.onClickSupressCreateButton(false)}
										isCreateClicked={this.state.isCreateClicked}
									/>
								</React.Fragment>
							) : (
								<React.Fragment>
									{this.props.supressRules && this.props.supressRules.length
										? this.props.supressRules.map((item, index) => {
												return (
													<div key={index} className='card'>
														<div className='card-body'>
															<div className='d-flex'>
																<h5
																	onClick={() => {
																		this.setState({ isCreateClicked: false })
																		this.props.onClickSupressCreateButton(true)
																	}}
																	className='card-title m-0 align-self-center mr-3'
																>
																	db-suppress
																</h5>
																<h6 className='card-subtitle m-0 align-self-center text-muted'>ID-AWSRDS12548</h6>
																<h6 className='trash-card cursor-pointer' onClick={() => this.toggleDeleteModal()}>
																	<i className='fal fa-trash' />
																</h6>
															</div>
															<p className='card-text m-0'>
																<span className='text-muted'>Service:</span> rds-instance
																<span className='text-muted ml-2'>Provider:</span> Amazon
																<span className='fa fa-stopwatch ml-2' /> 16d
															</p>
															<p className='card-text m-0'>
																<small>Ut qui aut natus sunt rerum facere.</small>
															</p>
														</div>
														<Modal isOpen={this.state.showDeleteSuppressModal} toggle={() => this.toggleDeleteModal()}>
															<ModalHeader toggle={() => this.toggleDeleteModal()}>Delete</ModalHeader>
															<ModalBody>Are you sure want to delete configuration?</ModalBody>
															<ModalFooter>
																<Button color='primary' onClick={() => this.deleteSupress(item)}>
																	Yes
																</Button>{' '}
																<Button color='secondary' onClick={() => this.toggleDeleteModal()}>
																	No
																</Button>
															</ModalFooter>
														</Modal>
													</div>
												)
										  })
										: null}
								</React.Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
SupressSection.propTypes = {
	showSupressForm: PropTypes.bool,
	onClickSupressCreateButton: PropTypes.func,
	deleteSupression: PropTypes.func,
	supressRules: PropTypes.array,
	onClickSupressBackButton: PropTypes.func,
	providersData: PropTypes.array,
	selectedRowAssetDetails: PropTypes.array,
	showLoading: PropTypes.bool,
}

export default connect(null, { deleteSupression })(SupressSection)
