/*************************************************
 * Tvastar
 * @exports
 * @file ViewSidePanel.js
 * @author Prakash // on 26/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner, Input } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { store as CommonNotification } from 'react-notifications-component';

import { getAccountNameFromId, capitalizeFirstLetter, onlyNumeric } from '../../../utils/utility'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import { listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { setResourcePropsDetails } from '../../../actions/assets/assetsAction'
import { createAlarm } from '../../../actions/aiops/AiopsAction'



class ViewSidePanel extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
            minMaxHeight: 700,
			selectedTab: "Poilcy",
			modeTypes: [
				{value: "reactive", label: "Reactive"},
				{value: "preventive", label: "Preventive"}
			],

			actionMethods: [
				{value: "immediately", label: "Immediately after the violation"},
				{value: "run_at", label: "At"},
				{value: "run_after", label: "After"},
			],

			unitOptions: ["Gigabits/Second", "Gigabits", "Percent", "Terabits", "Bits", "Kilobits", "Megabytes/Second", "Kilobytes", "Megabits/Second", "Terabytes", "Bytes", "Count", "Gigabytes", "Milliseconds", "Count/Second", "Gigabytes/Second", "None", "Terabytes/Second", "Bytes/Second", "Megabits", "Kilobytes/Second", "Megabytes", "Microseconds", "Kilobits/Second", "Terabits/Second", "Bits/Second", "Seconds"]
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })

		this.getAllTagsKeys()
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	_handleKeyDown = event => {
		if(this.state.onSaveFunction) {
			switch (event.keyCode) {
				case 27:
					this.props.closeSidePanel()
					break
				default:
					break
			}
		}
	}

    handleClickOutside(event) {}

	getAllTagsKeys = () => {
		
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = () => {
		let params = {}
		params.tags = [{ "key": this.state.tag_key }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				let result = response
				if(response.length) {
					result.unshift("All");
				}
				this.setState({ tagValues: response })
			}
		})
	}
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		console.log("value", value)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []
		if(prevState.length === 1 && prevState[0] === "All") {
			prevState = []
		}
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All")) {
			if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
				selectedValue.push("All")
			}
		}
		
		this.setState({ [field]: selectedValue })
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : <span className="placeholder w-100 align-self-center">Select</span>)
	}

	addNewTag = () => {
		let selectedTags = this.state.selected_tags ? this.state.selected_tags : []

		if(this.state.tag_key && this.state.tag_key !== "" && this.state.tag_value && this.state.tag_value.length) {
			this.state.tag_value.forEach(tag => {
				if(tag !== "All" && !(selectedTags.filter(e => e.key === this.state.tag_key && e.value !== tag).length) || selectedTags.filter(e => e.key !== this.state.tag_key)) {
					let dataRow = {}
					dataRow.key = this.state.tag_key
					dataRow.value = tag
					selectedTags.push(dataRow)
				}
			})
		}

		this.setState({ selected_tags: selectedTags, tag_key: "",  tag_value: [], tagValues: [] }, () => this.onChangeTagValue())
	}

	removeTagSection = (tag) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.state.selected_tags.filter(e => e !== tag)
		this.setState({selected_tags: filteredResult }, () => this.onChangeTagValue())
	}
	
	onChangeTagValue = () => {
		this.handleInputChange('tags', this.state.selected_tags.filter(e => e.value !== "All"))
	}
	
	handleInputChange = (label, value) => {
		let obj = this.props.selectedAlarm
		obj[label] = value

		this.setState({ [label]: value })

		this.props.setResourcePropsDetails('selectedAlarm', obj)
	}

	onFinish = () => {
		let params = this.props.selectedAlarm

		this.props.createAlarm(params, (promise, response) => {
			let messageType = ''
			let message = ''
			let createErrorMessage = ""
			if(response) {
				if(response.status) {
					messageType = 'success'
					message = 'Alarms created successfully'
				} else {
					messageType = 'danger'
					message = 'Alarms not created'
					createErrorMessage = response.error

				}
			} else {
				messageType = 'danger'
				message = 'Alarms not created'
			}
			this.setState({ onSaveFunction: false })

			CommonNotification.addNotification({
				//title: "Wonderful!",
				message: message,
				type: messageType,
				insert: "top",
				container: "top-center",
				// animationIn: ["animate__animated", "animate__fadeIn"],
				// animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
				duration: 5000,
				onScreen: false,
				pauseOnHover: true,
				showIcon: true,
				}
			});
		})
	}

	addNewActions = () => {
		let alarm_actions = this.props.selectedAlarm && this.props.selectedAlarm.alarm_actions ? this.props.selectedAlarm.alarm_actions : []

		if(this.state.alarmActions && this.state.alarmActions !== "") {
			if(!alarm_actions.includes(this.state.alarmActions)) {
				alarm_actions.push(this.state.alarmActions)
			}
		}

		this.setState({ alarm_actions, alarmActions: "" },
			() => this.handleInputChange("alarm_actions", alarm_actions)
		)
	}

	removeActionsSection = (row) => {
		// filteredResult = this.state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = this.props.selectedAlarm.alarm_actions.filter(e => e !== row)
		this.handleInputChange("alarm_actions", filteredResult)
	}
	
	render() {		
		return (
			<div className="advanced-search" onClick={(event) => this.handleClickOutside(event)}>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>Alarm Details</h5>
							<div className="mr-2 d-flex">
								{/* <div className="mr-3">
									<p className="m-0 small">Last Scan: 29 Sep 2020 11:10</p>
								</div> */}
								{/* <div className="mr-3">
									<p className="m-0 pl-2 small">Account:  {this.state.selectedAccount ? this.state.selectedAccount: 'All'}</p>
								</div>
								<div className="mr-3">
									<p className="m-0 pl-2 small">Region:  {this.state.selectedRegion ? this.state.selectedRegion: 'All'}</p>
								</div>
								<div>
									<p className="m-0 pl-2 small">Duration: 
										{DURATION_FILTER_OPTIONS.map((item, index) => {
											return (
												this.state.selectedDuration === item.value ?
													item.option
												: null
											)
										})}
									</p>
								</div> */}
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							<i className={`far fa-times cursorPointer  ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''} p-2`}>
						<div className="dlpDescriptionForm overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
							{this.state.createErrorMessage && this.state.createErrorMessage !== "" ?
								<div className="p-3">
									<p  className="text-danger">{this.state.createErrorMessage}</p>
								</div>
							: null}
							<div className="p-3 rounded bg-dark">
								<div className="d-flex justify-content-between">
									<h5 className="mb-0 cursorPointer d-flex" onClick={() => this.setState({ showDetails: !this.state.showDetails })}>Details</h5>
									
									<span className={`ml-4 fa fa-angle-${this.state.showDetails ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showDetails: !this.state.showDetails })}></span>
								</div>
								{this.state.showDetails ?
									<div className='mt-2'>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Provider / Account /Region:</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.alarm_type ? this.props.selectedAlarm.provider : ''}

													{this.props.selectedAlarm.account_id ? ' : '+ getAccountNameFromId(this.props.selectedAlarm.account_id, this.props.accounts) : ''}

													{this.props.selectedAlarm.region ? ' : '+ getAccountNameFromId(this.props.selectedAlarm.region, this.props.regions) : ''}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Alarm Type:</p>
												<p className="mb-0 text-white">{this.props.selectedAlarm && this.props.selectedAlarm.alarm_type ? this.props.selectedAlarm.alarm_type : <span>&nbsp;</span>}</p>
											</div>											
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Asset Name</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.asset_name ? this.props.selectedAlarm.asset_name : <span>&nbsp;</span>}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Asset Arn</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.asset_arn ? this.props.selectedAlarm.asset_arn : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Category</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.category ? 
														this.props.selectedAlarm.category.map(item => {
															return (
															<small className={`badge badge-secondary mr-1`}>{item}</small>
															)
														}) 
													: null}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Default Alarm Name</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.default_alarm_name ? this.props.selectedAlarm.default_alarm_name : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Event Name</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.event_name ? this.props.selectedAlarm.event_name : <span>&nbsp;</span>}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">IAM Arn</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.iam_arn ? this.props.selectedAlarm.iam_arn : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Resource Type</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.resource_type ? this.props.selectedAlarm.resource_type : <span>&nbsp;</span>}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Service Name</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.service_name ? this.props.selectedAlarm.service_name : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
										<div className="d-flex mb-1">
											<div className="py-1 w-50">
												<p className="b-block mb-0">SRE Metric</p>
												<p className="mb-0 text-white">
													{this.props.selectedAlarm && this.props.selectedAlarm.sre_metric ? this.props.selectedAlarm.sre_metric : <span>&nbsp;</span>}
												</p>
											</div>
										</div>
									</div>
								: null}
							</div>
							<div className="p-3 rounded bg-dark zapInputDark mt-3">
								<div className="d-flex justify-content-between">
									<h5 className="mb-0 cursorPointer d-flex" onClick={() => this.setState({ showInputDetails: !this.state.showInputDetails })}>Input Details</h5>
									
									<span className={`ml-4 fa fa-angle-${this.state.showInputDetails ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showInputDetails: !this.state.showInputDetails })}></span>
								</div>
								{this.state.showInputDetails ?
									<div className='mt-2'>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Alarm Name:</p>
												<p className="mb-0 text-white">
													<Input
														type="text"
														placeholder="Enter Alarm Name"
														className={`inputTextbox ${this.props.selectedAlarm && this.props.selectedAlarm.missing_alarm ? "" : ""}`}
														// maxlength="128"
														value={this.props.selectedAlarm.alarm_name && this.props.selectedAlarm.alarm_name !== "" ? this.props.selectedAlarm.alarm_name : ""}
														onChange={e => this.handleInputChange("alarm_name", e.target.value)}
													/>
												</p>
												<p className="b-block mb-0 mt-2">Comparison Operator:</p>
												<p className="mb-0 text-white">
													<Input
														type="text"
														placeholder="Enter comparison operator"
														className="inputTextbox"
														// maxlength="128"
														value={this.props.selectedAlarm.comparison_operator && this.props.selectedAlarm.comparison_operator !== "" ? this.props.selectedAlarm.comparison_operator : ""}
														onChange={e => this.handleInputChange("comparison_operator", e.target.value)}
													/>
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Description:</p>
												<p className="mb-0 text-white">
													<Input
														type="textarea"
														className="inputTextbox" 
														placeholder="Enter Policy Description"
														rows="4"
														id="comment"
														maxlength="256"
														onChange={e => this.handleInputChange("description", e.target.value)}
														value={this.props.selectedAlarm.description && this.props.selectedAlarm.description !== "" ? this.props.selectedAlarm.description : ""}
													/>
												</p>
											</div>											
										</div>
										{this.props.selectedAlarm.metrics ?
											<React.Fragment>
											<div className="d-flex mb-1 border-bottom">
												<div className="py-1 w-50">
													<p className="b-block mb-0">Metric Name:</p>
													<p className="mb-0 text-white">
														<Input
															type="text"
															placeholder="Enter Metric Name"
															className="inputTextbox"
															// maxlength="128"
															value={this.props.selectedAlarm.metric_name && this.props.selectedAlarm.metric_name !== "" ? this.props.selectedAlarm.metric_name : ""}
															onChange={e => this.handleInputChange("metric_name", e.target.value)}
														/>
													</p>
												</div>
												<div className="py-1 w-50 pl-3">
													<p className="b-block mb-0">Namespace:</p>
													<p className="mb-0 text-white">
														<Input
															type="text"
															placeholder="Enter Namespace"
															className="inputTextbox"
															// maxlength="128"
															value={this.props.selectedAlarm.namespace && this.props.selectedAlarm.namespace !== "" ? this.props.selectedAlarm.namespace : ""}
															onChange={e => this.handleInputChange("namespace", e.target.value)}
														/>
													</p>
												</div>											
											</div>
											<div className="d-flex mb-1 border-bottom">
												<div className="py-1 w-50">
													<p className="b-block mb-0">Statistic:</p>
													<p className="mb-0 text-white">
														<Input
															type="text"
															placeholder="Enter Statistic"
															className="inputTextbox"
															// maxlength="128"
															value={this.props.selectedAlarm.statistic && this.props.selectedAlarm.statistic !== "" ? this.props.selectedAlarm.statistic : ""}
															onChange={e => this.handleInputChange("statistic", e.target.value)}
														/>
													</p>
												</div>
												<div className="py-1 w-50 pl-3">
													<p className="b-block mb-0">Period:</p>
													<p className="mb-0 text-white">
														<Input
															type="text"
															placeholder="Enter Period"
															className="inputTextbox"
															// maxlength="128"
															value={this.props.selectedAlarm.period && this.props.selectedAlarm.period !== "" ? this.props.selectedAlarm.period : ""}
															onChange={e => this.handleInputChange("period", onlyNumeric(e.target.value))}
														/>
													</p>
												</div>
											</div>
											</React.Fragment>
										: null}
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Unit:</p>
												<p className="mb-0 text-white">
													<Select
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null
														}}
														className={`mr-2 mt-2 w-50`}
														value={({
															value: this.props.selectedAlarm && this.props.selectedAlarm.unit ? this.props.selectedAlarm.unit : "",
															label: this.props.selectedAlarm && this.props.selectedAlarm.unit ? this.props.selectedAlarm.unit : <span className="placeholder">Key</span>
														})}														
														
														options={this.state.unitOptions && this.state.unitOptions.map(item => ({
															value: item,
															label: item,	
														}))}
														onChange={event => this.handleInputChange("unit", event.value)}
													/>
													{/* <Input
														type="text"
														placeholder="Enter Unit"
														className="inputTextbox"
														// maxlength="128"
														value={this.props.selectedAlarm.unit && this.props.selectedAlarm.unit !== "" ? this.props.selectedAlarm.unit : ""}
														onChange={e => this.handleInputChange("unit", e.target.value)}
													/> */}
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Evaluation Periods:</p>
												<p className="mb-0 text-white">
													<Input
														type="text"
														placeholder="Enter Evaluation Periods"
														className="inputTextbox"
														// maxlength="128"
														value={this.props.selectedAlarm.evaluation_periods && this.props.selectedAlarm.evaluation_periods !== "" ? this.props.selectedAlarm.evaluation_periods : ""}
														onChange={e => this.handleInputChange("evaluation_periods", onlyNumeric(e.target.value))}
													/>
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-50">
												<p className="b-block mb-0">Datapoints to Alarm:</p>
												<p className="mb-0 text-white">
													<Input
														type="text"
														placeholder="Enter Datapoints to Alarm"
														className="inputTextbox"
														// maxlength="128"
														value={this.props.selectedAlarm.datapoints_to_alarm && this.props.selectedAlarm.datapoints_to_alarm !== "" ? this.props.selectedAlarm.datapoints_to_alarm : ""}
														onChange={e => this.handleInputChange("datapoints_to_alarm", e.target.value)}
													/>
												</p>
											</div>
											<div className="py-1 w-50 pl-3">
												<p className="b-block mb-0">Threshold:</p>
												<p className="mb-0 text-white">
													<Input
														type="number"
														placeholder="Enter Threshold"
														className="inputTextbox"
														// maxlength="128"
														value={this.props.selectedAlarm.threshold && this.props.selectedAlarm.threshold !== "" ? this.props.selectedAlarm.threshold : ""}
														onChange={e => this.handleInputChange("threshold", e.target.value)}
													/>
												</p>
											</div>
										</div>
										<div className="d-flex mb-1 border-bottom">
											<div className="py-1 w-100">
												<p className="b-block mb-0">Alarm Actions:</p>
												<div className="d-flex">
													<p className="mb-0 text-white w-80">
														<Input
															type="text"
															placeholder="Enter Alarm Actions"
															className={`inputTextbox`}
															// maxlength="128"
															value={this.state.alarmActions}
															onChange={e => this.setState({ alarmActions: e.target.value })}
														/>
													</p>
													<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-center mr-4`} onClick={() => this.addNewActions()}></span>
												</div>
												{this.props.selectedAlarm && this.props.selectedAlarm.alarm_actions && this.props.selectedAlarm.alarm_actions.length ? 
													this.props.selectedAlarm.alarm_actions.map(row => {
														return(
															<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{row}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeActionsSection(row) } ></i>
															</span>
														)
													})
												: null}
											</div>
										</div>
										<div className="d-flex mb-1">
											<div className="py-1 w-100">
												<p className="b-block mb-0">Tags:</p>
												<div className="d-flex">
													<Select
														isSearchable={true}
														components={{
															IndicatorSeparator: () => null
														}}
														className={`mr-2 mt-2 w-50`}
														value={({
															value: this.state.tag_key,
															label: this.state.tag_key && this.state.tag_key !== "" ? this.state.tag_key : <span className="placeholder">Key</span>
														})}														
														
														options={this.props.tagKeys && this.props.tagKeys.map(item => ({
															value: item,
															label: item,	
														}))}
														onChange={event => this.setState({
															tag_key: event.value,
															tagValues: [],
														},
															() => this.getAllTagsValues()
														)}
													/>
													<div className={`multiselectDarkTheme align-self-center mr-2 z998 w-50`} style={{marginTop: "0.5rem"}}>
														<ReactMultiSelectCheckboxes
															placeholderButtonLabel="Select"
															getDropdownButtonLabel={() => this.getMultiSelectedCount("tag_value", this.state.tag_value ? this.state.tag_value : [])}
															options={this.state.tagValues && this.state.tagValues.map(row => ({
																value: row,
																label: row,
															}))}
															onChange={arr => { this.handleMultiSelectChange("tag_value", arr ? arr : [], this.state.tagValues) }}
															value={this.state.tag_value && this.state.tag_value.map(acc => ({
																value: acc
															}))}
														/>
													</div>
													<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-center mr-4`} onClick={() => this.addNewTag()}></span>
												</div>
												{this.state.selected_tags && this.state.selected_tags.length ? 
													this.state.selected_tags.filter(e => e.value !== "All").map((tag, index) => {
														return(
															<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key +" : "+tag.value}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
															</span>
														)
													})
												: null}
											</div>
										</div>
										<div className='mt-4'>
											<button className={`btn btn-primary mr-2 ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.setState({ onSaveFunction: true }, () => this.onFinish())} >
												{this.state.onSaveFunction ? 
													<Spinner size='sm' color='light' className='mx-2' /> 
												: null}
												Save 
											</button>
											<button className={`btn btn-secondary ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.props.closeSidePanel()} >Cancel</button>
										</div>
									</div>
								: null}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ViewSidePanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		tagKeys: state.filters.tagKeys,
		selectedAlarm: state.assets.resourcesPropsDetails && state.assets.resourcesPropsDetails.selectedAlarm ? state.assets.resourcesPropsDetails.selectedAlarm : {},
	}
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions, 
	getAllTagsKeys, 
	getAllTagsValues,
	createAlarm,
	setResourcePropsDetails
})(withRouter(ViewSidePanel))