/*************************************************
 * Tvastar
 * @exports
 * @file OtherDetails.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class OtherDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className="box p-3 bg-dark">
				<div className="d-flex">
					<p className="flex-grow-1 mb-2 text-white">
						<b>Other Details</b>
					</p>
				</div>
				{this.props.requireHttps ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Require Https</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.requireHttps}</div>
					</div>
				) : null}
				{this.props.restEncrption ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Encryption at rest</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.restEncrption}</div>
					</div>
				) : null}
				{this.props.nodeToNode ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Node-to-node encryption</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.nodeToNode}</div>
					</div>
				) : null}
				{this.props.updateStatus ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Upgrade status</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.updateStatus}</div>
					</div>
				) : null}
				{this.props.startHour ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Start hour for the daily automated snapshot</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.startHour}</div>
					</div>
				) : null}
				{this.props.cognitoEnabled ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Amazon Cognito for authentication</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.cognitoEnabled}</div>
					</div>
				) : null}
				{this.props.softwareRelease ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Service software release</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.softwareRelease}</div>
					</div>
				) : null}

				{this.props.dbInstanceCaCertificateIdentifier ? (
					<>
						<div>
							{this.props.dbInstanceCaCertificateIdentifier ? (
								<p className="text-muted mb-0">
									Certificate Authority: <b className="text-dark">{this.props.dbInstanceCaCertificateIdentifier}</b>
								</p>
							) : null}
						</div>
						<div>
							{this.props.performanceInsight ? (
								<p className="text-muted mb-2">
									Performance Insights: <b className="text-dark">{this.props.performanceInsight}</b>
								</p>
							) : null}
						</div>
						{/* <div className="bordered-tile border-left-0">
							<div className="flex-grow-1">
								<h4 className="mb-2">gmao-rds-proxy</h4>
								<p className="mb-0">
									<i className="fal fa-database" /> My Sql
								</p>
							</div>
							<div>
								<p className="mb-0">
									<span className="badge badge-outline-success">Available</span>
								</p>
							</div>
						</div> */}
					</>
				) : null}
				{this.props.virtualizationType ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Virtualization</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.virtualizationType}</div>
					</div>
				) : null}
				{this.props.apiTermination ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Termination Protection</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.apiTermination}</div>
					</div>
				) : null}
				{this.props.monitoringState ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Monitoring</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.monitoringState}</div>
					</div>
				) : null}
				{this.props.shutdownBehavior ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Stop-Hibernation behaviour</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.shutdownBehavior}</div>
					</div>
				) : null}
				{this.props.sourceCheck ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Source/dest. Check</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.sourceCheck}</div>
					</div>
				) : null}
				{this.props.tentancy ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Tenancy</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.tentancy}</div>
					</div>
				) : null}
				{this.props.lifeCycle ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">Lifecycle</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.lifeCycle}</div>
					</div>
				) : null}
				{this.props.placementGroupName ? (
					<div className="d-flex mb-2 border-bottom">
						<div className="py-2">PlacementGroup</div>
						<div className="flex-grow-1 text-right py-2 text-white">{this.props.placementGroupName}</div>
					</div>
				) : null}
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
OtherDetails.propTypes = {
	dbInstanceCaCertificateIdentifier: PropTypes.string,
	requireHttps: PropTypes.string,
	startHour: PropTypes.string,
	performanceInsight: PropTypes.string,
	softwareRelease: PropTypes.number,
	cognitoEnabled: PropTypes.string,
	updateStatus: PropTypes.string,
	nodeToNode: PropTypes.string,
	restEncrption: PropTypes.string,
	tentancy: PropTypes.string,
	sourceCheck: PropTypes.string,
	shutdownBehavior: PropTypes.string,
	monitoringState: PropTypes.string,
	lifeCycle: PropTypes.string,
	placementGroupName: PropTypes.string,
	apiTermination: PropTypes.string,
	virtualizationType: PropTypes.string,
}

export default OtherDetails
