/*************************************************
 * Tvastar
 * @exports
 * @file Settings.js
 * @author Rajasekar // on 19/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AppWrapper } from '../common/AppWrapper'

// import logo from '../../assets/images/logo.svg'
import gcp from '../../assets/images/gcp.png'
import azure from '../../assets/images/azure.png'
import aws from '../../assets/images/aws.png'
import { validatePassword, momentDateTime } from '../../utils/utility'
//import { Settings } from '../../actions/SettingsAction'
import { URL_PATH } from '../../config/urlPath'
import { listAllAccountsDetails } from '../../actions/commonAction'
//const videoPopup = `https://www.youtube.com/embed/${video.id.videoId}`

class Settings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			accounts: [],
			filteredAccounts: [],
			navigatePage: 1,
			accountTab: 'all',
			memberTab: 'all',
			integrationTab: 'slack',
			modalHeader: '',
			videoPopup: false,
			selectedAccount: 'gcp',

			firstName:'',
			lastName:'',
			phone:'',
			company:'',
			email: '',
			password: '',
			hasError: false,
			onLoading: false,
			showRegisterErrorMessage: false,
			showRegisterSuccessScreen: false,
			
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
			showResetErrorMessage: '',
		}
	}

	componentDidMount = () => {
		let params = {}
		this.props.listAllAccountsDetails(params, (promise, accounts) => {
			console.log(accounts)
			if (promise) {
				this.setState({ accounts, filteredAccounts: accounts })
			} else {
				this.setState({ accounts: [] })
			}
		})
	}

	selectedAccountTab = (type) => {
		let filteredAccounts = this.state.accounts
		if(type === 'active') {
			filteredAccounts = filteredAccounts.filter(account => account.disabled === false)
		} else if(type === 'inActive') {
			filteredAccounts = filteredAccounts.filter(account => account.disabled === true)
		}
		this.setState({ accountTab: type, filteredAccounts })
	}

	navigatePage = (page) => {
		this.setState({ navigatePage: page })
	}

	changePassword = () => {
		this.setState({ hasError: false })
		if (
			!validatePassword(this.state.oldPassword) ||
			!validatePassword(this.state.newPassword) ||
			!validatePassword(this.state.confirmPassword)
		) {
			this.setState({ hasError: true })
		} else if(this.state.newPassword !== this.state.confirmPassword) {
			this.setState({ hasError: true, showResetErrorMessage: "New/Confirm does't match" })
		} else {
		}
	}

	selectedMemberAction = actionFor => {
		this.setState({ selectedMemberAction: this.state.selectedMemberAction === actionFor ? 0 : actionFor })
	}

	/**
	 * Renders register screen design
	 */
	render() {
		const { 
			hasError,
			// showResetErrorMessage,
			oldPassword,
			newPassword,
			confirmPassword,
			filteredAccounts
		} = this.state
		return (
			<div className="container-fluid overflow-auto flex-grow-1">
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className="title-section p-3">
							<h6 className="text-white">Settings</h6>
							<p className="text-white">Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
								enim.</p>
						</div>
						<div className="box m-3 p-3">
							<div className="row">
								<div className="col-sm-6">
									<h6>Settings</h6>
									<p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
								</div>
								<div className="col-sm-6 text-right">
								</div>
							</div>
							<div className="row">
								<div className="col-3">
									<div className="nav flex-column nav-pills">
										<span onClick={() => this.navigatePage(1)} className={`nav-link cursorPointer ${this.state.navigatePage === 1 ? 'active': ''}`}>Account</span>
										<span onClick={() => this.navigatePage(2)} className={`nav-link cursorPointer ${this.state.navigatePage === 2 ? 'active': ''}`}>Member</span>
										<span onClick={() => this.navigatePage(3)} className={`nav-link cursorPointer ${this.state.navigatePage === 3 ? 'active': ''}`}>Integrations</span>
										<span onClick={() => this.navigatePage(4)} className={`nav-link cursorPointer ${this.state.navigatePage === 4 ? 'active': ''}`}>Change Password</span>
									</div>
								</div>
								<div className="col-9 border-left">
									<div className="tab-content">
										<div className={`tab-pane fade ${this.state.navigatePage === 1 ? 'show active': ''}`}>
											<div class="row"> 
												<div class="col-sm-12">
													<h6 class="font-weight-normal">Accounts</h6>
													<p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
												</div>
											</div>
											<div className='row'>
												<div className='col-sm-6'>
													<select class="custom-select w-auto mr-3">
														<option selected>Filter</option>
														<option value="1">InActive</option>
														<option value="2">Active</option>
													</select>
													<select class="custom-select w-auto">
														<option selected>Provider</option>
														<option value="aws">AWS</option>
														<option value="gcp">GCP</option>
														<option value="azure">AZURE</option>
													</select>
												</div>
												<div className='col-sm-6 text-right'>
													<Link className='btn btn-primary cursorPointer text-center ' to={URL_PATH.ACCOUNT_REGISTER}>
														Add Account
													</Link>
												</div>
											</div>
											{/* <ul className="nav nav-tabs bg-transparent shadow-none" id="myTab"
												role="tablist">
												<li className="nav-item">
													<span className={`nav-link cursorPointer ${this.state.accountTab === 'all' ? 'active': ''}`} onClick={() => this.setState({ filteredAccounts: [] }, () => this.selectedAccountTab('all')) }>All Account</span>
												</li>
												<li className="nav-item">
													<span className={`nav-link cursorPointer ${this.state.accountTab ===  'active' ? 'active': ''}`} onClick={() => this.setState({ filteredAccounts: [] }, () => this.selectedAccountTab('active')) }>Active Account</span>
												</li>
												<li className="nav-item">
													<span className={`nav-link cursorPointer ${this.state.accountTab === 'inActive' ? 'active': ''}`} onClick={() => this.setState({ filteredAccounts: [] }, () => this.selectedAccountTab('inActive')) }>In-Active Account</span>
												</li>
											</ul> */}
											<div className="tab-content accountList">
												<div className={`tab-pane fade show active`}>
													<div className="table-responsive">
														<table className="table table-striped">
															<thead>
																<tr>
																	<th></th>
																	<th>Account Name</th>
																	{/* <th>Account Name</th> */}
																	<th>Account Number</th>
																	<th>Status</th>
																	<th>Date</th>
																</tr>
															</thead>
															<tbody>
																{filteredAccounts.length ? filteredAccounts.map((item, index) => {
																	return(
																		<tr key={index}>
																			<td>{index+1}</td>
																			<td className="align-middle">
																			<img src={item.provider === 'aws' ? aws : item.provider === 'gcp' ? gcp : azure} className="img-fluid" alt={item.provider} />&nbsp;{item.account_name}
																			</td>
										
																			<td className="align-middle">{item.account_id}
																			</td>
																			<td className="align-middle">{item.disabled ? 'In-Active' : 'Active'}
																			</td>
																			<td className="align-middle">
																				{item.date !== null ? momentDateTime(item.date) : ''}
																			</td>
																		</tr>
																	)

																})
																: 
																	<tr key={-1}>
																		<td colSpan={7} className="text-center">No Records</td>
																	</tr>
																}
																
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div className={`tab-pane fade ${this.state.navigatePage === 2 ? 'show active': ''}`}>
										<div class="d-flex"> 
												<div class="col-sm-10 m-0 p-0">
													<h6 class="font-weight-normal">Member</h6>
													<p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
												</div>
												<div class="col-sm-2 m-0 p-0 float-right text-right align-self-center">
													<Link className='btn btn-primary cursorPointer text-center ' to={URL_PATH.ACCOUNT_REGISTER}>
														Add Member
													</Link>
												</div>
											</div>
											<ul className="nav nav-tabs bg-transparent shadow-none" id="myTab"
												role="tablist">
												<li className="nav-item">
													<span className={`nav-link cursorPointer ${this.state.memberTab === 'all' ? 'active': ''}`} onClick={() => this.setState({ memberTab: 'all' }) }>All Members</span>
												</li>
												<li className="nav-item">
													<span className={`nav-link cursorPointer ${this.state.memberTab === 'admin' ? 'active': ''}`} onClick={() => this.setState({ memberTab: 'admin' }) }>Admin</span>
												</li>
												<li className="nav-item">
													<span className={`nav-link cursorPointer ${this.state.memberTab === 'member' ? 'active': ''}`} onClick={() => this.setState({ memberTab: 'member' }) }>Member</span>
												</li>
											</ul>
											<div className="tab-content" id="myTabContent">
												<div className={`tab-pane fade ${this.state.memberTab === 'all' ? 'show active': ''}`}>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Buford Schmeler DDS</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 1 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(1) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 1 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Matilde Graham</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 2 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(2) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 2 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Velda Hintz</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 3 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(3) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 3 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Howard Treutel V</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 4 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(4) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 4 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Freddie Jacobs DDS</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 5 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(5) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 5 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Chelsey Mayert I</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 6 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(6) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 6 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className={`tab-pane fade ${this.state.memberTab === 'admin' ? 'show active': ''}`}>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Buford Schmeler DDS</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 7 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(7) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 7 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Matilde Graham</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 8 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(8) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 8 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className={`tab-pane fade ${this.state.memberTab === 'member' ? 'show active': ''}`}>
													
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Velda Hintz</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 9 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(9) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 9 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Howard Treutel V</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 10 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(10) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 10 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Freddie Jacobs DDS</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 11 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(11) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 11 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
													<div className="d-flex border rounded mb-2 p-2">
														<div>
															<img src="images/user.png" alt="" />
														</div>
														<div className="mx-3 flex-grow-1 align-self-center">
															<h6 className='font-weight-normal'>Chelsey Mayert I</h6>
															<p className="mb-0">Officia amet eiusmod eu sunt tempor
																voluptate laboris velit nisi amet
																enim.</p>
														</div>
														<div className="align-self-center">
															<div className="btn-group">
																<div className={`rounded-circle py-2 px-3 cursorPointer ${this.state.selectedMemberAction === 12 ? 'bg-white5' : 'bg-white3'}`} onClick={ () => this.selectedMemberAction(21) }>
																	<i className="fa fa-ellipsis-v text-secondary"></i>
																</div>
																<div className={`dropdown-menu dropdown-menu-right ${this.state.selectedMemberAction === 12 ? 'show active' : ''} `}>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item"
																		type="button">Action</button>
																	<button className="dropdown-item" type="button">Another
																		action</button>
																	<button className="dropdown-item"
																		type="button">Something else here</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={`tab-pane fade ${this.state.navigatePage === 3 ? 'show active': ''}`}>
											<h6 className="font-weight-normal">Integrations</h6>
											<p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
												enim.</p>
											<ul className="nav nav-tabs bg-transparent shadow-none">
												<li className="nav-item">
													<span className={`nav-link cursorPointer ${this.state.integrationTab === 'slack' ? 'active': ''}`} onClick={() => this.setState({ integrationTab: 'slack' }) }>Slack Integration</span>
												</li>
												<li className="nav-item">
													<span  className={`nav-link cursorPointer ${this.state.integrationTab === 'pagerDuty' ? 'active': ''}`} onClick={() => this.setState({ integrationTab: 'pagerDuty' }) }>PagerDuty Integration</span>
												</li>
											</ul>
											<div className="tab-content">
												<div className={`tab-pane fade ${this.state.integrationTab === 'slack' ? 'show active': ''}`}>
													<div className='form-group mt-3'>
														<label>Slack URL</label>
														<input type='text' className='form-control' placeholder='' />
														<small>Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
													<div className='form-group'>
														<label>Slack Name</label>
														<input type='text' className='form-control' placeholder='' />
														<small>Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
												</div>
												<div className={`tab-pane fade ${this.state.integrationTab === 'pagerDuty' ? 'show active': ''}`}>
													<div className='form-group mt-3'>
														<label>PagerDuty URL</label>
														<input type='text' className='form-control' placeholder='' />
														<small>Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
													<div className='form-group'>
														<label>PagerDuty Name</label>
														<input type='text' className='form-control' placeholder='' />
														<small>Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
												</div>
											</div>
											<div className="d-flex">
												<button className="btn btn-primary">
													Next
												</button>
												<button className="btn btn-link">
													Back to Dashboard
												</button>
											</div>
										</div>
										<div className={`tab-pane fade ${this.state.navigatePage === 4 ? 'show active': ''}`}>
											<h6 className="font-weight-normal">Change Password</h6>
											<p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet
												enim.</p>
											<div className="row">
												<div className="col-sm-6">
													<div className='form-group'>
														<label>Old Password</label>
														<input
															type='password'
															className={`form-control ${
															(hasError && oldPassword === '') || (hasError && !validatePassword(this.state.oldPassword))
																? 'is-invalid'
																: oldPassword === '' ? '' : oldPassword.length < 8 ? 'is-invalid' : 'is-valid'
														}`}
															value={this.state.oldPassword}
															onChange={event => this.setState({ oldPassword: event.target.value, showLoginError: false })}
															placeholder='Old Password'
														/>
														<div className='invalid-feedback'>Please enter a valid old password.</div>
														<small>Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
													<div className='form-group'>
														<label>New Password</label>
														<input
															type='password'
															className={`form-control ${
																(hasError && newPassword === '') || (hasError && !validatePassword(this.state.newPassword))
																	? 'is-invalid'
																	: newPassword === '' ? '' : newPassword.length < 8 ? 'is-invalid' : 'is-valid'
															}`}
															value={this.state.newPassword}
															onChange={event => this.setState({ newPassword: event.target.value, showLoginError: false })}
															placeholder='New Password'
														/>
														<div className='invalid-feedback'>Please enter a valid new password.</div>
													</div>
													<div className='form-group'>
														<label>New Password Confirmation</label>
														<input
															type='password'
															className={`form-control ${
																(hasError && confirmPassword === '') || (hasError && !validatePassword(this.state.confirmPassword))
																	? 'is-invalid'
																	: confirmPassword === '' ? '' : confirmPassword.length < 8 ? 'is-invalid' : 'is-valid'
															}`}
															value={this.state.confirmPassword}
															onChange={event => this.setState({ confirmPassword: event.target.value, showLoginError: false })}
															placeholder='Confirm Password'
														/>
														<div className='invalid-feedback'>Please enter a valid confirm password.</div>
														<small>Officia amet eiusmod eu sunt tempor voluptate laboris
															velit nisi amet enim.</small>
													</div>
													<div className='text-danger mb-2'>{this.state.showResetErrorMessage}</div>
													<div className="d-flex">
														<button className={`btn btn-primary`} onClick={() => this.changePassword()}>
															Next
														</button>
														<button className="btn btn-link">
															Back to Dashboard
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Settings.propTypes = {
	listAllAccountsDetails: PropTypes.func
}

//export default connect(null, { Settings })(Settings)
export default AppWrapper(Settings, null, {listAllAccountsDetails})
