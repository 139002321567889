/*************************************************
 * Tvastar
 * @exports
 * @file userinsightsAction.js
 * @author Prakash // on 13/07/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {	
	USER_INSIGHTS,
	LIST_IDENTITIES,
	GET_PRIVILEAGE_ANALYSIS,
	LAST_ACCESSED_DETAILS,
	UI_GET_TOTAL_COUNT,
	GET_TOTAL_ASSETS_ACCESSED,
	GET_ASSETS_ACCESSIBLE_COUNT,
	GET_ACCESS_TREND,
	LIST_USERS_ASSUMING_ROLE,
	GET_PERMISSION_DETAILS,
	GET_ACTIONS_CATEGORY_WISE,
	GET_ACTIONS_SERVICE_WISE,
	GET_ANOMALY_DETAILS,
	GET_RECENT_BEHAVIOR,
	GET_DETECTOR_ISSUES,
	LIST_FREQUENTLY_ACCESSED,
	GET_RELATED_RISKS,
	GET_RELATED_ISSUES_SERVICE_WISE,
	GET_DAYWISE_DENIED,
	GET_DENIED_SERVICE_WISE,
	
	DETECTORS,
	GET_IDENTIFIER_RESULTS,
	GET_RELATED_IDENTIFIER_RESULTS,
	GET_USER_RELATED_ISSUES
} from '../../config'
import { myLog } from '../../utils/utility'


/**
 * Action to get Identities List
 * @param {Object} body
 * @param {Function} callback
 */
export const getIdentitiesList = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, LIST_IDENTITIES, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to get accessed details
 * @param {Object} body
 * @param {Function} callback
 */
export const lastAccessedDetails = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, LAST_ACCESSED_DETAILS, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to get total count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalCount = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, UI_GET_TOTAL_COUNT, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET TOTAL ASSETS ACCESSED
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalAssetsAccessed = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_TOTAL_ASSETS_ACCESSED, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET ACCESS TRENDt
 * @param {Object} body
 * @param {Function} callback
 */
export const getAccessTrend = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_ACCESS_TREND, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to LIST USERS ASSUMING ROLE
 * @param {Object} body
 * @param {Function} callback
 */
export const listUsersAssumingRole = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, LIST_USERS_ASSUMING_ROLE, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET PRIVILEAGE ANALYSIS
 * @param {Object} body
 * @param {Function} callback
 */
export const getPrivileageAnalysis = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_PRIVILEAGE_ANALYSIS, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET RECENT BEHAVIOR
 * @param {Object} body
 * @param {Function} callback
 */
export const getRecentBehavior = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_RECENT_BEHAVIOR, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET DETECTOR ISSUES
 * @param {Object} body
 * @param {Function} callback
 */
export const getDetectorIssues = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_DETECTOR_ISSUES, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to LIST FREQUENTLY ACCESSED COUNT
 * @param {Object} body
 * @param {Function} callback
 */
export const listFrequentlyAccessed = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, LIST_FREQUENTLY_ACCESSED, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}
/**
 * Action to GET ASSETS ACCESSIBLE COUNT
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetsAccessibleCount = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_ASSETS_ACCESSIBLE_COUNT, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET ANOMALY DETAILS
 * @param {Object} body
 * @param {Function} callback
 */
export const getAnomalyDetails = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_ANOMALY_DETAILS, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET PERMISSION DETAILS
 * @param {Object} body
 * @param {Function} callback
 */
export const getPermissionDetails = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_PERMISSION_DETAILS, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET ACTIONS CATEGORY WISE
 * @param {Object} body
 * @param {Function} callback
 */
export const getActionsCategoryWise = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_ACTIONS_CATEGORY_WISE, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

/**
 * Action to GET ACTIONS SERVICE WISE
 * @param {Object} body
 * @param {Function} callback
 */
export const getActionsServiceWise = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_ACTIONS_SERVICE_WISE, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

export const getIdentifierResults = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(DETECTORS, GET_IDENTIFIER_RESULTS, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

export const getRelatedIdentifierResults = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(DETECTORS, GET_RELATED_IDENTIFIER_RESULTS, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

export const getUserRelatedIssues = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(DETECTORS, GET_USER_RELATED_ISSUES, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

export const getRelatedRisks = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_RELATED_RISKS, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

export const getRelatedIssuesServiceWise = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_RELATED_ISSUES_SERVICE_WISE, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

export const getDaywiseDenied = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_DAYWISE_DENIED, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}

export const getDeniedServiceWise = (body, callback) => {
	return async () => {
		try {
			const score = await API.post(USER_INSIGHTS, GET_DENIED_SERVICE_WISE, { body })
			callback(true, score)
		} catch (error) {
			myLog('score', error)
			callback(false)
		}
	}
}