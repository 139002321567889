/*************************************************
 * Tvastar
 * @exports
 * @file ApesMutliyAxis  .js
 * @author Prakash // on 16/07/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, convertBytes, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subDays, momentConvertionUtcToLocalTime } from '../../../utils/utility'

import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getSreDetails } from '../../../actions/aiops/DiagnosticsAction'

import Chart from 'react-apexcharts'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class AlertSreDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showSreLoading: true,
            test: false
        }
    }
    
    componentDidMount = () => {
        if(this.props.alertSreMonitoringDetails && Object.entries(this.props.alertSreMonitoringDetails).length) {
            if(this.props.alertSreMonitoringDetails.hasOwnProperty(this.props.alertPageDetails.asset_name)) {
                this.setState({ 
                    sreDetails: this.props.alertSreMonitoringDetails[this.props.alertPageDetails.asset_name],
                    showSreLoading: true
                }, 
                    () => {
                        this.formSreLatencyDetailGraph()
                        this.formSreRateDetailGraph()
                    }
                )
            } else {
                this.getSreDetails()
            }
        } else {
            this.getSreDetails()
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.sliderDetails && Object.entries(prevProps.sliderDetails).length && this.props.sliderDetails !== prevProps.sliderDetails) {
            this.formSreLatencyDetailGraph()
            this.formSreRateDetailGraph()
        }
    }

    getSreDetails = () => {
        let caseStartTime = this.props.caseDetails.created_at
        
        let caseEndTime = this.props.caseDetails.last_event
        if(caseEndTime < caseStartTime) {
            caseEndTime = caseStartTime
        }

        let updatedCaseStartTime = subDays(this.props.caseDetails.created_at, 3)
        
        let params = {}
        params.case_id = this.props.caseDetails.case_id
        params.start_time = updatedCaseStartTime
        params.end_time = caseEndTime

        let assetId = ''
        if(Array.isArray(this.props.alertPageDetails.asset_id)) {
            assetId = this.props.alertPageDetails.asset_id[0]
        } else {
            assetId = this.props.alertPageDetails.asset_id
        }
        params.asset_id = assetId

        if(assetId !== null && assetId !== '') {
            this.props.getSreDetails(params, (promise, response) => {
                if(promise) {
                    this.setState({ 
                        sreDetails: response,
                        showSreLoading: false
                    },
                        () => {
                            this.formSreLatencyDetailGraph()
                            this.formSreRateDetailGraph()
                            if(response && response.length) {
                                let obj = this.props.alertSreMonitoringDetails
                                obj[this.props.alertPageDetails.asset_name] = response
                                this.props.setAiopsPropsDetails('alertSreMonitoringDetails', obj)
                            }
                        }
                    )
                } else {
                    this.setState({ sreDetails: [], showSreLoading: false },
                        // () => this.props.setAiopsPropsDetails('alertSreMonitoringDetails', [])    
                    )
                }
            })
        }
    }

    formSreLatencyDetailGraph = () => {
        let brushStartDate = this.props.sliderDetails.brushStartDate
        let brushEndDate = this.props.sliderDetails.brushEndDate

        let startIndex = 0
        let endIndex = 0
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates
                graphLabels.forEach((lab, i) => {
                    if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(brushStartDate, 'YYYY-MM-DD HH:mm')) {
                        startIndex = i
                    }
                    if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(brushEndDate, 'YYYY-MM-DD HH:mm')) {
                        endIndex = (i+1)
                    }
                })
                
                if(endIndex === 0) {
                    endIndex = graphLabels.length-1
                }
                let colorInc = 0
                Object.entries(graphData.latency).forEach(([key, item], j) => {
                    let latency_options = {}
                    let latencySeries = []
                    let thresholdArray = []
                    if(item.values && item.values.length) {
                        
                        if(item.threshold && Object.entries(item.threshold).length) {
                            Object.entries(item.threshold).forEach(([key, value]) => {
                                let thresholdRow = {}
                                thresholdRow.prefix = ''
                                thresholdRow.label = key
                                thresholdRow.value = value
                                thresholdArray.push(thresholdRow)
                            })
                        }

                        let chartType = 'line'
                        let animations = {}
                        let plotOptions = {}
                        let stroke = {
                            width: [3],
                            curve: 'smooth',
                        }
                        if(j % 2 === 0) {
                            chartType = 'bar'
                            animations = {
                                enabled: false
                            }
                            plotOptions = {
                                bar: {
                                    horizontal: false,
                                    barHeight: '100%',
                                    borderRadius: 'flat',
                                    startingShape: 'flat',
                                    endingShape: 'flat',
                                    columnWidth: '60%',
                                    distributed: false,
                                    rangeBarOverlap: false,
                                }
                            }
                            stroke = {
                                width: [2],
                            }
                        }

                        
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = chartType

                        let plotValue = []
                        graphLabels.slice(startIndex, endIndex).forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[startIndex + i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(item.values[startIndex + i], item.unit)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[startIndex + i])
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        latencySeries.push(dataRow)

                        let annotations = {}
                        let yaxisAnnotaions = []
                        if(this.state.selected_latency && this.state.selected_latency.length && this.state['threshold_latency_'+index+'_'+j] && this.state['threshold_latency_'+index+'_'+j].length) {
                            let borderColor = ''
                            let background = ''
                            let color = ''
                            let text = ''
                            let location = ''
                            let annotationLineColors = ['#FFD200', '#F75E3F', '#039BE5', '#06CB9E']
                            this.state.selected_latency.forEach((item, i) => {
                                text = item.text
                                let filter = this.state['threshold_latency_'+index+'_'+j].filter(arr => arr.label === item.text)
                                location = filter[0] && filter[0].value ? filter[0].value : 0
                                
                                this.state['threshold_latency_'+index+'_'+j].forEach((thres, k) => {
                                    if(text.toLowerCase() === thres.label.toLowerCase()) {
                                        borderColor = '#fff'
                                        background = annotationLineColors[k]
                                    }
                                })
                                color = '#fff' 

                                let dataRow = {
                                    y: location,
                                    strokeDashArray: 0,
                                    borderColor: borderColor,
                                    opacity: 1,
                                    label: {
                                        borderColor: 'none',
                                        text: text,
                                        style: {
                                            color: color,
                                            background: background,
                                            fontSize: '12px'
                                        },
                                        textAnchor: 'middle',
                                    }
                                }
                                yaxisAnnotaions.push(dataRow)
                            })    
                        }
                        annotations = {
                            yaxis: yaxisAnnotaions
                        }

                        latency_options = {
                            chart: {
                                id: 'chart_latency_details_'+j,
                                type: chartType,
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                animations: animations
                            },
                            plotOptions: plotOptions,
                            dataLabels: {
                                enabled: false
                            },
                            stroke: stroke,
                            colors: [colors[colorInc]],
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    show: this.props.xaxis,
                                    style: {
                                        fontSize: '9px',
                                        colors: this.props.axisLabelColor
                                    },
                                }
                            },
                            yaxis: {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    },
                                }
                            },
                            tooltip: {
                                shared: true,
                                intersect: false,
                                y: {
                                    show: false,
                                },
                                
                                x: {
                                    show: false
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                        let returnData = ''
                                        
                                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                            Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                                if(!index) {
                                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                                }
                                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
        
                                            })
                                        }
        
                                        returnData += '</div>'
                                        return returnData
                                }
                            },
                            grid: {
                                show: this.props.grid,
                                borderColor: '#35363A',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    },  
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },
                                padding: {
                                    // top: 0,
                                    right: 0,
                                    // bottom: 0,
                                    left: 0
                                }, 
                            },
                            legend: {
                                show: false
                            },
                            annotations: annotations
                        }
                        colorInc++;

                        this.setState({ ['series_latency_'+j]: latencySeries, ['options_latency_'+j]: latency_options, ['threshold_latency_'+index+'_'+j]: thresholdArray, threshold_latency: thresholdArray, showSreLoading: false })
                    }

                })
            })
        }
    }

    formSreRateDetailGraph = () => {
        let brushStartDate = this.props.sliderDetails.brushStartDate
        let brushEndDate = this.props.sliderDetails.brushEndDate

        let startIndex = 0
        let endIndex = 0
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreDetails && this.state.sreDetails.length) {
            let errorRateArray= []
            let filteredSreDetails = this.state.sreDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                graphLabels.forEach((lab, i) => {
                    if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(brushStartDate, 'YYYY-MM-DD HH:mm')) {
                        startIndex = i
                    }
                    if(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm') === momentDateGivenFormat(brushEndDate, 'YYYY-MM-DD HH:mm')) {
                        endIndex = (i+1)
                    }
                })

                if(endIndex === 0) {
                    endIndex = graphLabels.length-1
                }

                let x = 0
                graphData.error_rate && Object.entries(graphData.error_rate).forEach(([key, item], j) => {
                    
                    let thresholdArray = []
                    let error_series = []
                    let rate_options = {}
                    errorRateArray.push({key: key, unit: item.unit})

                    if(item.values && item.values.length) {
                        if(item.threshold && Object.entries(item.threshold).length) {
                            Object.entries(item.threshold).forEach(([key, value]) => {
                                let thresholdRow = {}
                                thresholdRow.prefix = ''
                                thresholdRow.label = key
                                let val = this.getGrapUnitValueWithoutUnit(value, 'Seconds')
                                thresholdRow.value = val
                                // thresholdRow.value = value
                                thresholdArray.push(thresholdRow)
                            })
                        }
                        
                        let chartType = 'line'
                        let animations = {}
                        let plotOptions = {}
                        let stroke = {
                            width: [3],
                            curve: 'smooth',
                        }
                        if(x % 2 === 0) {
                            chartType = 'bar'
                            animations = {
                                enabled: false
                            }
                            plotOptions = {
                                bar: {
                                    horizontal: false,
                                    barHeight: '100%',
                                    borderRadius: 'flat',
                                    startingShape: 'flat',
                                    endingShape: 'flat',
                                    columnWidth: '60%',
                                    distributed: false,
                                    rangeBarOverlap: false,
                                }
                            }
                            stroke = {
                                width: [2],
                            }
                        }

                        let errorRow = {}
                        errorRow.name = key
                        errorRow.type = chartType
                        
                        let plotValue = []
                        graphLabels.slice(startIndex, endIndex).forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[startIndex + i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(item.values[startIndex + i], item.unit)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[startIndex + i])
                            plotValue.push(dataArray)
                        })

                        errorRow.data = plotValue
                        error_series.push(errorRow)

                        

                        rate_options = {
                            chart: {
                                type: chartType,
                                id: 'chart_rate_details_'+x,
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                animations: animations,
                            },
                            plotOptions: plotOptions,
                            stroke: stroke,
                            dataLabels: {
                                enabled: false
                            },
                            colors: [colors[x]],
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    show: this.props.xaxis,
                                    style: {
                                        fontSize: '9px',
                                        colors: this.props.axisLabelColor
                                    },
                                }
                            },
                            yaxis: {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    },
                                }
                            },
                            tooltip: {
                                shared: true,
                                intersect: false,
                                y: {
                                    show: false,
                                },
                                
                                x: {
                                    show: false
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                        let returnData = ''
                                        
                                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                            Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                                if(!index) {
                                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                                }
                                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'
        
                                            })
                                        }
        
                                        returnData += '</div>'
                                        return returnData
                                }
                            },
                            grid: {
                                show: this.props.grid,
                                borderColor: '#35363A',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    },  
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },
                                padding: {
                                    // top: 0,
                                    right: 0,
                                    // bottom: 0,
                                    left: 0
                                }, 
                            },
                            legend: {
                                show: false
                            },
                            annotations: {}
                        }                        
                        this.setState({ ['series_rate_detail_'+x]: error_series, ['options_rate_detail_'+x]: rate_options, ['threshold_rate_'+index+'_'+x]: thresholdArray })
                        x++;
                    }

                })
                
                graphData.request_rate && Object.entries(graphData.request_rate).forEach(([key, item], j) => {

                    let thresholdArray = []
                    let request_series = []
                    let request_options = {}
                    errorRateArray.push({key: key, unit: item.unit})
                    if(item.values && item.values.length) {
                        if(item.threshold && Object.entries(item.threshold).length) {
                            Object.entries(item.threshold).forEach(([key, value]) => {
                                let thresholdRow = {}
                                thresholdRow.prefix = ''
                                thresholdRow.label = key
                                let val = this.getGrapUnitValueWithoutUnit(value, 'Seconds')
                                thresholdRow.value = val
                                // thresholdRow.value = value
                                thresholdArray.push(thresholdRow)
                            })
                        }

                        let chartType = 'line'
                        let animations = {}
                        let plotOptions = {}
                        let stroke = {
                            width: [3],
                            curve: 'smooth',
                        }

                        if(x % 2 === 0) {
                            chartType = 'bar'
                            animations = {
                                enabled: false
                            }
                            plotOptions = {
                                bar: {
                                    horizontal: false,
                                    barHeight: '100%',
                                    borderRadius: 'flat',
                                    startingShape: 'flat',
                                    endingShape: 'flat',
                                    columnWidth: '60%',
                                    distributed: false,
                                    rangeBarOverlap: false,
                                }
                            }
                            stroke = {
                                width: [2],
                            }
                        }

                        let plotValue = []
                        graphLabels.slice(startIndex, endIndex).forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[startIndex + i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(item.values[startIndex + i], item.unit)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[startIndex + i])
                            plotValue.push(dataArray)
                        })

                        let requestRow = {}
                        requestRow.name = key
                        requestRow.type = 'bar'
                        requestRow.data = plotValue
                        request_series.push(requestRow)

                        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC']

                        request_options = {
                            chart: {
                                type: chartType,
                                id: 'chart_rate_details_'+x,
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                animations: animations
                            },
                            plotOptions: plotOptions,
                            stroke: stroke,
                            dataLabels: {
                                enabled: false
                            },
                            colors: [colors[x]],
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    show: this.props.xaxis,
                                    style: {
                                        fontSize: '9px',
                                        colors: this.props.axisLabelColor
                                    },
                                }
                            },
                            yaxis: {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        if(Number(value) === value && value % 1 === 0) {
                                            return Math.ceil(value.toFixed(1))
                                        } else {
                                            return Math.ceil(value.toFixed(1))
                                        }
                                    },
                                }
                            },
                            tooltip: {
                                shared: true,
                                intersect: false,
                                y: {
                                    show: false,
                                },
                                
                                x: {
                                    show: false
                                },
                                marker: {
                                    show: false
                                },
                                custom: function({series, seriesIndex, dataPointIndex, w}) {   
                                    let returnData = ''
                                    
                                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                            if(!index) {
                                                returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                            }
                                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                        })
                                    }

                                    returnData += '</div>'
                                    return returnData
                                }
                            },
                            grid: {
                                show: this.props.grid,
                                borderColor: '#35363A',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    },  
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },
                                padding: {
                                    // top: 0,
                                    right: 0,
                                    // bottom: 0,
                                    left: 0
                                }, 
                            },
                            legend: {
                                show: false
                            },
                            annotations: {}
                        }                        
                        this.setState({ ['series_rate_detail_'+x]: request_series, ['options_rate_detail_'+x]: request_options, ['threshold_rate_'+index+'_'+x]: thresholdArray })
                        x++;
                    }

                })
            })
            this.setState({ errorRateArray })
        }
    }

    updateErrorRateOptions = (index, childIndex, unit) => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        let annotations = {}
        let yaxisAnnotaions = []
        if(this.state['selected_rate_'+index+'_'+childIndex] && this.state['selected_rate_'+index+'_'+childIndex].length) {
            let borderColor = ''
            let background = ''
            let color = ''
            let text = ''
            let location = ''
            let annotationLineColors = ['#FFD200', '#F75E3F', '#039BE5', '#06CB9E']
            this.state['selected_rate_'+index+'_'+childIndex].forEach((item, i) => {
                text = item.text
                location = item.value
                this.state['threshold_rate_'+index+'_'+childIndex].forEach((thres, j) => {
                    if(text.toLowerCase() === thres.label.toLowerCase()) {
                        borderColor = '#fff'
                        background = annotationLineColors[j]
                    }
                })
                color = '#fff' 

                let dataRow = {
                    y: location,
                    strokeDashArray: 0,
                    borderColor: borderColor,
                    opacity: 1,
                    label: {
                        borderColor: 'none',
                        text: text,
                        style: {
                            color: color,
                            background: background,
                            fontSize: '12px',
                        },
                        textAnchor: 'middle',
                    }
                }
                yaxisAnnotaions.push(dataRow)
            })
        }
        
        annotations = {
            yaxis: yaxisAnnotaions
        }

        let rate_options = {
            chart: {
                type: 'line',
                id: 'chart_rate_details_'+childIndex,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [2],
                curve: 'smooth',
            },
            colors: [colors[childIndex]],
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false,
                    show: this.props.xaxis,
                    style: {
                        fontSize: '9px',
                        colors: this.props.axisLabelColor
                    },
                }
            },
            yaxis: {
                show: this.props.yaxis,
                tickAmount: 2,
                min: 0,
                labels: {
                    show: this.props.yaxisLabel,
                    style: {
                        fontSize: '9px',
                        colors: this.props.axisLabelColor
                    },
                    formatter: (value) => { 
                        if(Number(value) === value && value % 1 === 0) {
                            return Math.ceil(value.toFixed(1))
                        } else {
                            return Math.ceil(value.toFixed(1))
                        }
                    },
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    show: false,
                },
                
                x: {
                    show: false
                },
                marker: {
                    show: false
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {       
                        let returnData = ''
                        
                        if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                            Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                if(!index) {
                                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                }
                                returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                            })
                        }

                        returnData += '</div>'
                        return returnData
                }
            },
            grid: {
                show: this.props.grid,
                borderColor: '#35363A',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    },  
                },   
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    }
                },
                padding: {
                    // top: 0,
                    right: 0,
                    // bottom: 0,
                    left: 0
                }, 
            },
            legend: {
                show: false
            },
            annotations: annotations
        }

        this.setState({ ['options_rate_detail_'+childIndex]: {} },
            () => {
                this.setState({ ['options_rate_detail_'+childIndex]: rate_options })
            }
        )
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }
    
    handleMultiSelectChange = (type, index, childIndex, unit, arrayValue) => {
		// let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)

        this.setState({ [type+'_'+index+'_'+childIndex]: arrayValue },
            () => {
                if(type === 'selected_latency') {
                    this.updateLatencyOptions(index, childIndex, unit)
                } else if(type === 'selected_rate') {
                    this.updateErrorRateOptions(index, childIndex, unit)
                }
            }    
        )
	}

    onChangeMultiSelectChange = (type, arrayValue) => {
		// let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)

        this.setState({ [type]: arrayValue },
            () => {
                this.formSreLatencyDetailGraph()
            }    
        )
	}

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}

    render() {
        return(
            !this.state.showSreLoading && this.state.sreDetails && this.state.sreDetails.length ? 
                this.state.sreDetails.map((res, index) => {
                    return(
                    <div className="">
                        <div class="bg-dark rounded my-3 p-3">
                            <div class="d-flex justify-content-between">
                                <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                        <div className="align-self-center">
                                            <p class="f16 text-white mb-1">Latency</p>
                                            <p class="f12 text-white">Showing the latency metrics for {res.asset_name ? (res.asset_name +' asset'): ''}</p>
                                        </div>
                                        <div className="d-flex justify-content-end align-self-center mt-2">
                                            <div className="mr-3 w-fit-content">
                                                <div className={`multiSelection-minWidth removeMargin z999 removeDropdownSearchBar position-relative bg-muted`}>
                                                    <ReactMultiSelectCheckboxes						
                                                        placeholderButtonLabel="All"
                                                        getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedlatency', this.state.selected_latency)}
                                                        // menuIsOpen ={this.state.isannotationTypeOpen}
                                                        value={this.state.selected_latency && this.state.selected_latency.map(itm => ({
                                                            value: itm.value,
                                                            label: itm.label,
                                                            text: itm.text,
                                                        }))}
                                                        onChange={arr => {
                                                        this.onChangeMultiSelectChange('selected_latency', arr ? arr : []) }}
                                                        options={this.state.threshold_latency && this.state.threshold_latency.map((itm, i) => ({
                                                            value: itm.value,
                                                            label: <span><span className={`mr-1 multiSelectSquareBox bg-${i === 0 ? 'warning' : i === 1 ? 'danger' : i === 2 ? 'info' : 'success'}`}></span>{itm.label}</span>,
                                                            text: itm.label
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="mb-3 col-12 px-0">
                                        <div class="p-3 rounded bg-dark3">
                                            <div className="row">
                                                {res.data && Object.entries(res.data.latency).map(([key, item], j) => {
                                                    return(
                                                        <div className={`${j % 2 === 0 && j === (Object.entries(res.data.latency).length-1) ? 'col-sm-12' : (j % 2 !== 0 ? 'col-sm-6' : 'col-sm-6')} mb-3`}>
                                                        <div className={`border rounded p-3`}>
                                                            <div className="d-flex">
                                                                <p class="f16 text-white mb-1 mr-2">{key}</p>
                                                                <div className="d-flex align-self-center displayNone">
                                                                    <div className="mr-3 w-fit-content">
                                                                        <div className={`multiSelection-minWidth removeMargin z999 removeDropdownSearchBar position-relative bg-muted`}>
                                                                            <ReactMultiSelectCheckboxes						
                                                                                placeholderButtonLabel="All"
                                                                                getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedCount', this.state['selected_latency_'+index+'_'+j])}
                                                                                // menuIsOpen ={this.state.isannotationTypeOpen}
                                                                                value={this.state['selected_latency_'+index+'_'+j] && this.state['selected_latency_'+index+'_'+j].map(itm => ({
                                                                                    value: itm.value,
                                                                                    label: itm.label,
                                                                                    text: itm.text,
                                                                                }))}
                                                                                onChange={arr => {
                                                                                this.handleMultiSelectChange('selected_latency', index, j, item.unit, arr ? arr : []) }}
                                                                                options={this.state['threshold_latency_'+index+'_'+j] && this.state['threshold_latency_'+index+'_'+j].map((itm, i) => ({
                                                                                    value: itm.value,
                                                                                    label: <span><span className={`mr-1 multiSelectSquareBox bg-${i === 0 ? 'warning' : i === 1 ? 'danger' : i === 2 ? 'info' : 'success'}`}></span>{itm.label}</span>,
                                                                                    text: itm.label
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`mt-n3 ml-n3 ${this.props.className}`}>
                                                                {this.state['options_latency_'+j] && this.state['series_latency_'+j] && this.state['series_latency_'+j].length ?
                                                                    <Chart options={this.state['options_latency_'+j]} series={this.state['series_latency_'+j]} type={`${j % 2 === 0 ? 'bar' : 'line'}`} height={150} width={"100%"} />
                                                                :
                                                                    <div style={{minHeight:"165px"}} className="d-flex align-self-center justify-content-center">
                                                                        <div className='align-self-center'>
                                                                            No Data
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="bg-dark rounded my-3 p-3">
                            <div class="d-flex justify-content-between">
                                <div className="">
                                    <p class="f16 text-white mb-1">API Error/Request Rate</p>
                                    <p class="f12 text-white">Showing the API Error/Request Rate metrics for {res.asset_name ? (res.asset_name+' asset') : ''}</p>
                                </div>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="mb-3 col-12 px-0">
                                        <div class="p-3 rounded bg-dark3">
                                            <div className="row">
                                                {this.state.errorRateArray && this.state.errorRateArray.length && this.state.errorRateArray.map((item, j) => {
                                                    return(
                                                        <div className={`${j % 2 === 0 && j === (this.state.errorRateArray.length-1) ? 'col-sm-12' : (j % 2 !== 0 ? 'col-sm-6' : 'col-sm-6')} mb-3`}>
                                                        <div className={`border rounded p-3`}>
                                                            <div className="d-flex">
                                                            <p class="f16 text-white mb-1 mr-2">{item.key}</p>
                                                                <div className="d-flex align-self-center">
                                                                    <div className="mr-3 w-fit-content">
                                                                        <div className={`multiSelection-minWidth removeMargin z999 removeDropdownSearchBar position-relative bg-muted`}>
                                                                            <ReactMultiSelectCheckboxes						
                                                                                placeholderButtonLabel="All"
                                                                                getDropdownButtonLabel={() => this.getMultiSelectedCount('selectedCount', this.state['selected_rate_'+index+'_'+j])}
                                                                                // menuIsOpen ={this.state.isannotationTypeOpen}
                                                                                value={this.state['selected_rate_'+index+'_'+j] && this.state['selected_rate_'+index+'_'+j].map(itm => ({
                                                                                    value: itm.value,
                                                                                    label: itm.label,
                                                                                    text: itm.text,
                                                                                }))}
                                                                                onChange={arr => {
                                                                                this.handleMultiSelectChange('selected_rate', index, j, item.unit, arr ? arr : []) }}
                                                                                options={this.state['threshold_rate_'+index+'_'+j] && this.state['threshold_rate_'+index+'_'+j].map((itm, i) => ({
                                                                                    value: itm.value,
                                                                                    label: <span><span className={`mr-1 multiSelectSquareBox bg-${i === 0 ? 'warning' : i === 1 ? 'danger' : i === 2 ? 'info' : 'success'}`}></span>{itm.label}</span>,
                                                                                    text: itm.label
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`mt-n3 ml-n3 ${this.props.className}`}>
                                                                {this.state['options_rate_detail_'+j] && this.state['series_rate_detail_'+j] && this.state['series_rate_detail_'+j].length ?
                                                                    <Chart options={this.state['options_rate_detail_'+j]} series={this.state['series_rate_detail_'+j]} type={`${j % 2 === 0 ? 'bar' : 'line'}`} height={150} width={"100%"} />
                                                                :
                                                                    <div style={{minHeight:"165px"}} className="d-flex align-self-center justify-content-center">
                                                                        <div className='align-self-center'>
                                                                            No Data
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }) 
            : this.state.showSreLoading ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner className='text-center' color='white' size='lg' />
                </div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
AlertSreDetails.propTypes = {
    getSreDetails: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func
}   

const mapStateToProps = state => {
    return {
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        alertPageDetails: state.aiops.aiopsPropsDetails.alert_page_details ? state.aiops.aiopsPropsDetails.alert_page_details : {},
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        sliderDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.sre_slider_period ? state.aiops.aiopsPropsDetails.sre_slider_period : {},
        
        alertSreMonitoringDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.alertSreMonitoringDetails ? state.aiops.aiopsPropsDetails.alertSreMonitoringDetails : {},
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    setAiopsPropsDetails
})(withRouter(AlertSreDetails))