/*************************************************
 * Tvastar
 * @exports
 * @file ScheduleAutomation.js
 * @author Prakash // on 21/11/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { listAllAccounts, listAllRegions } from '../../../../actions/commonAction'
import { listLifecyclePolicies, putLifecyclePolicy, listLifecycleRules } from '../../../../actions/ScheduleActions'

import { momentDateGivenFormat, currentLocaltime, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime, momentTimeConvertionUtcToLocalTime, momentTimeConvertionLocalToUtcTime , capitalizeFirstLetter } from '../../../../utils/utility'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import SearchWithHiddenInput from '../../../common/SearchWithHiddenInput'
import { store as CommonNotification } from 'react-notifications-component';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import ViewMetricDetails from './ViewMetricDetails'

const disableFutureDt = current => {
	// return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 30))
	return current.isAfter(currentLocaltime())
}

class ScheduleAutomation extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
			selectedTab: "Policy",
			showLoading: true,
			shutdownOptions: [
				{label: "Daily/Weekly", value: "daily"},
				{label: "Monthly", value: "monthly"},
			],
			dailyArray: [0],
			notificationArray: ["slack", "email"],
			dayOptions: [
				{label: "Monday", value: "Monday"},
				{label: "Tuesday", value: "Tuesday"},
				{label: "Wednesay", value: "Wednesay"},
				{label: "Thursday", value: "Thursday"},
				{label: "Friday", value: "Friday"},
				{label: "Saturday", value: "Saturday"},
				{label: "Sunday", value: "Sunday"},
			],
			retain_dependencies: true,
			retain_configuration: true,
		}
	}

	handleExpiationChange  = this.handleExpiationChange.bind(this)

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)

		this.listLifecyclePolicies()

		let monthOptions = []		
		for(let i=1;i<=31;i++) {
			let dataRow = {}
			dataRow.label = i
			dataRow.value = i

			monthOptions.push(dataRow)
		}

		this.setState({ monthOptions })
    }

	listLifecyclePolicies = () => {
		let params = {}
		params.provider = this.props.selectedAsset && this.props.selectedAsset.provider ? this.props.selectedAsset.provider : "aws"
		if(this.props.selectedAsset && this.props.selectedAsset.account_id) {
			params.account_id = this.props.selectedAsset.account_id
		}
		if(this.props.selectedAsset && this.props.selectedAsset.region) {
			params.region = this.props.selectedAsset.region
		}
		if(this.props.selectedAsset && this.props.selectedAsset.asset_id) {
			params.asset_id = this.props.selectedAsset.asset_id //"i-034019eb63e8dd1a1"
		}
		if(this.props.selectedAsset && this.props.selectedAsset.service_name) {
			params.service_name = this.props.selectedAsset.service_name //"i-034019eb63e8dd1a1"
		}

		this.props.listLifecyclePolicies(params, (promise, response) => {
			if(promise) {
				let results = response && response.results ? response.results : {}

				let rules = response && response.rules && response.rules.length ? response.rules[0] : {}

				this.setState({ 
					listPolicies: results && results.length ? results[0] : {},
					listPolicyRules: rules,
					showLoading: true
				},
					() => {
						this.structurePolicy()	
						this.props.showLoader()
					}
				)
			} else {
				this.setState({ listPolicies: {}, showLoading: false })
				this.props.showLoader()
			}
		})
	}

	structurePolicy = () => {
		let data = this.state.listPolicies

		let dailyArray = []

		if(data && Object.entries(data).length) {
			let dailyPolicies = data.policies && data.policies.filter(e => e.schedule === "daily").length ? data.policies.filter(e => e.schedule === "daily") : []
			if(dailyPolicies && dailyPolicies.length) {
				dailyPolicies.forEach((item, index) => {
					dailyArray.push(index)
					
					let selectedStopOptions = ""
					let selectedStartOptions = ""
					if(item.actions && Object.entries(item.actions).length) {
						Object.entries(item.actions).forEach(([key, value]) => {
							selectedStartOptions = key
							selectedStopOptions = value
						})
					}
					
					this.setState({ 
						["start_time_"+index]: momentTimeConvertionUtcToLocalTime(item.start_time, "HH:mm"),
						["end_time_"+index]: momentTimeConvertionUtcToLocalTime(item.end_time, "HH:mm"),
						["selectedDays_"+index]: item.days_of_the_week ? item.days_of_the_week : [],
						["selectedStopOptions_"+index]: selectedStopOptions,
						["selectedStartOptions_"+index]: selectedStartOptions,
						["selectedStartStopOption_"+index]: selectedStartOptions+":"+selectedStopOptions,
						showPolicies: true
					},
						() => {
							if(this.state["start_time_"+index] && this.state["start_time_"+index] !== "") {
								this.validateTimeRange(this.state["start_time_"+index], "start_time_"+item)
							}
							if(this.state["end_time_"+index] && this.state["end_time_"+index] !== "") {
								this.validateTimeRange(this.state["end_time_"+index], "end_time_"+item)
							}
						}
					)
				})
			} else {
				dailyArray = [0]
			}

			let monthlyPolicy = data.policies && data.policies.filter(e => e.schedule === "monthly").length ? data.policies.filter(e => e.schedule === "monthly") : []

			if(monthlyPolicy && monthlyPolicy.length) {
				monthlyPolicy.forEach(item => {
					let monhlySelectedStopOptions = ""
					let monthlySelectedStartOptions = ""
					if(item.actions && Object.entries(item.actions).length) {
						Object.entries(item.actions).forEach(([key, value]) => {
							monthlySelectedStartOptions = key
							monhlySelectedStopOptions = value
						})
					}

					this.setState({ 
						start_time: momentTimeConvertionUtcToLocalTime(item.start_time, "HH:mm"),
						end_time: momentTimeConvertionUtcToLocalTime(item.end_time, "HH:mm"),
						selectedStartDay: item.start_day,
						selectedEndDay: item.end_day,
						selectedStartOptions: monthlySelectedStartOptions,
						selectedStopOptions: monhlySelectedStopOptions,
						showPolicies: true
					})
				})
			}
 
			// if(data.notifications) {
			// 	console.log(data.notifications)
			// 	console.log(data.notifications.slack)
			// 	this.state.notificationArray.forEach(row => {
			// 		this.setState({ ["shared_details_"+row]:  data.notifications[row] ? data.notifications[row] : [] })
			// 	})
			// }

			this.setState({ 
				notification_enabled: data.notification_enabled,
				retain_configuration: data.retain_configuration,
				retain_dependencies: data.retain_dependencies,
				expiration_time: data.expiration_time ? momentConvertionUtcToLocalTime(data.expiration_time, "YYYY-MM-DD HH:mm") : ""
			})
		}

		let ruleOptions = []
		let dailyRuleOptions = []
		let rules = this.state.listPolicyRules
		// if(rules && rules.actions && Object.entries(rules.actions).length) {
		let actions = [{
			"launch": ["shutdown", "snapshot-shutdown"],
			"snapshot-restore": ["shutdown 1", "snapshot-shutdown 1"]
		}]
		if(rules && rules.actions && rules.actions.length) {
			rules.actions.forEach(row => {
				Object.entries(row).forEach(([key, value]) => {
					let dataRow = {}
					dataRow.label = key
					dataRow.value = key
					dataRow.endValue = value
					dataRow.startValue = key
					ruleOptions.push(dataRow)
				})
			})

			rules.actions.forEach(row => {
				Object.entries(row).forEach(([key, value]) => {
					if(Array.isArray(value)) {
						value.forEach(valRow => {
							let dataRow = {}
							dataRow.label = key+"/"+valRow
							dataRow.value = key+"/"+valRow
							dataRow.endValue = valRow
							dataRow.startValue = key
							dailyRuleOptions.push(dataRow)
						})
					} else {
						let dataRow = {}
						dataRow.label = key+"/"+value
						dataRow.value = key+"/"+value
						dataRow.endValue = value
						dataRow.startValue = key
						dailyRuleOptions.push(dataRow)						
					}
				})
			})

			if(ruleOptions && ruleOptions.length === 1) {				
				this.setState({ selectedStartStopOption: ruleOptions[0].startValue, selectedStopOptions: ruleOptions[0].endValue, selectedStartOptions: ruleOptions[0].startValue })
			}
		}

		if(!dailyArray.length) {
			dailyArray = [0]
		}

		this.setState({ dailyArray, ruleOptions, dailyRuleOptions, showLoading: false })
	}

	
	handleExpiationChange(date) {
		let expiration_time = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		this.setState({ expiration_time})
	}

	onChangePeriod = () => {
		let selectedShutdownPeriod = this.state.selectedShutdownPeriod
		this.setState({ selectedStartDay: "", selectedEndDay: "" })
	}

	addNewSharedDetails = (item) => {
		let sharedDetails = this.state["shared_details_"+item] ? this.state["shared_details_"+item] : []
        if(this.state["shared_to_"+item] && this.state["shared_to_"+item] !== "") {
			sharedDetails.push(this.state["shared_to_"+item])
		}

		this.setState({ ["shared_details_"+item]: sharedDetails, ["shared_to_"+item]: "" })
    }

	removeSharedDetails = (item, sharedTo) => {
		let filteredResult = this.state["shared_details_"+item].filter(e => e !== sharedTo)
        this.setState({ ["shared_details_"+item]: filteredResult })
    }

	addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }	

    removeSection = (item) => {
        this.setState({ ["start_time_"+item]: "", ["end_time_"+item]: "", ["selectedDays_"+item]: "", ["selectedStartOptions_"+item]: "", ["selectedStopOptions_"+item]: "" },
            () => {
                let rowList = this.state.dailyArray;
                rowList.splice(item, 1);
                this.setState({ dailyArray: rowList })
            }
        )
    }

	removeDays = (item, day) => {
		let filteredResult = this.state["selectedDays_"+item].filter(e => e !== day)
        this.setState({ ["selectedDays_"+item]: filteredResult })
    }

	onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			this.validateTimeRange(str, state)
		} else {
			this.validateTimeRange(time, state)
		}
	}

	validateTimeRange = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length == 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00"
			} else {
				data += firstMinute
			}
		}

		this.setState({ [state]: data })
	}

	createUpdatePolicy = () => {
		let notifications = {}
		this.state.notificationArray.forEach(row => {
			notifications[row] = this.state["shared_details_"+row] && this.state["shared_details_"+row].length ? this.state["shared_details_"+row] : []
		})

		let policies = []
		this.state.dailyArray && this.state.dailyArray.forEach(item => {
			if(this.state["start_time_"+item] && this.state["end_time_"+item] && this.state["selectedDays_"+item] && this.state["selectedStartOptions_"+item] && this.state["selectedStopOptions_"+item]) {
				let dataRow = {}
				dataRow.schedule = "daily"
				dataRow.start_time = this.state["start_time_"+item] ? momentTimeConvertionLocalToUtcTime(this.state["start_time_"+item], "HH:mm:00") : ""
				dataRow.end_time = this.state["end_time_"+item] ? momentTimeConvertionLocalToUtcTime(this.state["end_time_"+item], "HH:mm:00") : ""
				dataRow.days_of_the_week = this.state["selectedDays_"+item]
				let action = {}
				action[this.state["selectedStartOptions_"+item]] = this.state["selectedStopOptions_"+item]
				dataRow.action = action

				policies.push(dataRow)
			}
		})

		
		if(this.state.start_time && this.state.end_time && this.state.selectedStartDay && this.state.selectedEndDay && this.state.selectedStartOptions && this.state.selectedStopOptions) {
			let dataRow = {}
			dataRow.schedule = "monthly"
			dataRow.start_time = this.state.start_time ? momentTimeConvertionLocalToUtcTime(this.state.start_time, "HH:mm:00") : ""
			dataRow.end_time = this.state.end_time ? momentTimeConvertionLocalToUtcTime(this.state.end_time, "HH:mm:00") : ""
			dataRow.start_day = this.state.selectedStartDay
			dataRow.end_day = this.state.selectedEndDay
			let action = {}
			action[this.state.selectedStartOptions] = this.state.selectedStopOptions
			dataRow.action = action

			policies.push(dataRow)
		}

		let params = {}
		params.policies = policies
		params.retain_configuration = this.state.retain_configuration
		params.retain_dependencies = this.state.retain_dependencies
		params.notification_enabled = this.state.notification_enabled
		// params.notifications = notifications
		params.expiration_time = this.state.expiration_time ? momentConvertionLocalToUtcTime(this.state.expiration_time, "YYYY-MM-DD HH:mm:00") : ""
		params.asset_id = this.props.selectedAsset && this.props.selectedAsset.asset_id ? this.props.selectedAsset.asset_id : ""

		if(policies && policies.length) {
			this.props.putLifecyclePolicy(params, (promise, response) => {
				let messageType = ""
				if(promise) {
					messageType = 'success'
				} else {
					messageType = 'danger'
				}
				this.setState({ disableSubmit: false })
	
				let message = response && response.message ? response.message : 'Error in inserting the schedule policy '
	
				CommonNotification.addNotification({
					message: message,
					type: messageType,
					insert: "top",
					container: "top-center",
					dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
					}
				});
			})
		}
		
	}

	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []

		if(state) {
			let prevState = this.state[field] ? this.state[field] : []
			if(prevState.length === 1 && prevState[0] === "All") {
				prevState = []
			}
			if(value.includes("All")) {
				if(!prevState.includes("All")) {
					selectedValue = value
				} else {
					const index = value.indexOf("All");
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes("All")) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes("All")) {
				if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
					selectedValue.push("All")
					let dataRow = {
						label: "All",
						value: "All",
					}
				}
			}
		} else {
			selectedValue = value
		}

		let selectedDays = this.state.selectedDays ? this.state.selectedDays : []
		if(this.state.selectedDays && !this.state.selectedDays.length) {
			selectedDays = selectedValue
		} else {
			selectedValue.forEach(item => {
				if(selectedDays.includes(item)) {
					const index = selectedDays.indexOf(item);
					if (index > -1) {
						selectedDays.splice(index, 1);
					}
				} else{
					selectedDays.push(item)
				}
			})
		}

		this.setState({ [field]: selectedValue, selectedDays })
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : "All")
	}

    handleClickOutside(event) {}
	
	render() {		
		return (
			<div className="w-100" onClick={(event) => this.handleClickOutside(event)}>
				<div className={`${!this.state.showLoading ? 'overflow-auto' : ''} p-2`}>
					{this.state.showMetricsDetails ?
						<ViewMetricDetails 
							selectedAsset= {this.props.selectedAsset}
							closeSidePanel= {() => this.setState({ showMetricsDetails: false })}
						/>
					: null}
					{this.state.showLoading ?
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center' color='white' size='lg' />
						</div>
					: !this.state.showPolicies ?
						!this.state.ruleOptions || !this.state.ruleOptions.length ?
							<div className='d-flex justify-content-center m-4'>
								<p>There are no Policies for this asset</p>
							</div>
						:
							<div className='d-flex justify-content-end'>
								<button className="btn btn-primary" onClick={() => this.setState({ showPolicies: !this.state.showPolicies })}>+ Add Policy</button>
							</div>
					:
						<div className="">		
							<div className="d-flex justify-content-between">				
								<p className="px-2 align-self-center text-white">Showing the schedule details for the <span className="text-info ml-1">{this.props.selectedAsset && this.props.selectedAsset.asset_name ? this.props.selectedAsset.asset_name : ""}</span></p>

								<p className="text-info mb-0 cursorPointer" onClick={() => this.setState({ showMetricsDetails: true })}>Metric Details</p>
							</div>

							<div className="rounded p-3 dlpDescriptionForm dlpDescriptionFormPlain bg-dark3">
								<div className="d-flex justify-content-between text-white">
									<div className="d-flex">
										<p className="mb-0 f20 cursorPointer text-warning" onClick={() => this.setState({ showDailySection: !this.state.showDailySection })}>Daily/Weekly</p>
									</div>
									<span className={`ml-4 fa fa-angle-${this.state.showDailySection ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showDailySection: !this.state.showDailySection })}></span>
								</div>
								{this.state.showDailySection ?
									<React.Fragment>
									{this.state.dailyArray.map(item => {
										return(
											<div className="d-flex justify-content-between">
												<div class="d-flex flex-wrap mb-4">
													<p className={`mb-0 mr-1 mt-2 align-self-center text-white`}>Resource should be available between business hours</p>
													<input 
														type="text" 
														className={`inputField w-8 pl-2`}
														style={{minHeight: "38px"}}
														placeholder={"HH:MM"}
														value={this.state["start_time_"+item]}
														maxLength="5"
														onKeyDown={e => this.onKeyDownTimeRange(e, e.target.value, "start_time_"+item)}
														onChange={e => this.validateTimeRange(e.target.value, "start_time_"+item)}
													/>
													
													<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center text-white`}>to</p>
													
													<input 
														type="text" 
														className={`inputField w-8 pl-2`}
														style={{minHeight: "38px"}}
														placeholder={"HH:MM"}
														value={this.state["end_time_"+item]}
														maxLength="5"
														onKeyDown={e => this.onKeyDownTimeRange(e, e.target.value, "end_time_"+item)}
														onChange={e => this.validateTimeRange(e.target.value, "end_time_"+item)}
													/>
													<p className={`mb-0 ml-2 mr-1 mt-3 align-self-center text-primary-color f12`}> (local time eg:- 22:15) </p>

													<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center text-white`}>hrs on</p>

													<div className={`multiSelectOption align-self-end mt-2`}>
														<ReactMultiSelectCheckboxes
															placeholderButtonLabel="All"
															getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedDays_"+item, this.state["selectedDays_"+item] ? this.state["selectedDays_"+item] : [])}
															options={this.state.dayOptions && this.state.dayOptions.map(row => ({
																value: row.value,
																label: row.label
															}))}
															onChange={arr => { this.handleMultiSelectChange("selectedDays_"+item, arr ? arr : [], this.state.dayOptions) }}
															value={this.state["selectedDays_"+item] && this.state["selectedDays_"+item].map(row => ({
																value: row
															}))}
														/>
													</div>
													{this.state["selectedDays_"+item] && this.state["selectedDays_"+item].length ? 
														this.state["selectedDays_"+item].map((day, index) => {
															return(
																<span className="badge border-gray3 ml-2 mt-2 align-self-center f14 text-info px-2">{day}
																	<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeDays(item, day) } ></i>
																</span>
															)
														})
													: null}
													<p className={`mb-0 ml-1 mr-1 mt-2 align-self-center text-white`}>.  Apply</p>
													<Select
														placeholder={'Select'}
														isSearchable={false}
														components={{
															IndicatorSeparator: () => null
														}}
														className={"selectOption"}
														value={({
															value: this.state["selectedStartStopOption_"+item],
															label: this.state["selectedStartStopOption_"+item] ? this.state["selectedStartStopOption_"+item] : <span className="placeholder">Select</span>
														})}		
														options={this.state.dailyRuleOptions && this.state.dailyRuleOptions.map(row => ({
															value: row.value,
															label: row.label,
															startValue: row.startValue,
															endValue: row.endValue
														}))}															
														onChange={event => this.setState({ ["selectedStartStopOption_"+item]: event.value, ["selectedStopOptions_"+item]: event.endValue, ["selectedStartOptions_"+item]: event.startValue })}
													/>

													<p className={`mb-0 ml-1 mr-1 mt-2 align-self-center text-white`}>lifecycle method</p>
												</div>	
												{this.state.dailyArray.length > 1 ?
													<span className={`far fa-trash cursor-pointer f18 align-self-center ml-4 w-5`} onClick={() => this.removeSection(item)}></span>
												: null}
											</div>
										)
									})}
									<span className="text-info mt-2 mb-5 cursorPointer" onClick={() => this.addSection("dailyArray")}>+ Add New Policy</span>
									</React.Fragment>
								: null}
							</div>
							
							<div className="rounded p-3 dlpDescriptionForm dlpDescriptionFormPlain bg-dark3 mt-3">
								<div className="d-flex justify-content-between text-white">
									<div className="d-flex">
										<p className="mb-0 f20 cursorPointer text-warning" onClick={() => this.setState({ showMonthlySection: !this.state.showMonthlySection })}>Monthly</p>
									</div>
									<span className={`ml-4 fa fa-angle-${this.state.showMonthlySection ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showMonthlySection: !this.state.showMonthlySection })}></span>
								</div>
								{this.state.showMonthlySection ?
									<div class="d-flex flex-wrap mb-2">
										<p className={`mb-0 mr-1 mt-2 align-self-center text-white`}>Resource should be</p>
										<Select
											placeholder={'Select'}
											isSearchable={false}
											components={{
												IndicatorSeparator: () => null
											}}
											className={"selectOption"}
											value={({
												value: this.state.selectedStartOptions,
												label: this.state.selectedStartOptions && this.state.selectedStartOptions !== "" && this.state.ruleOptions.filter(e => e.startValue === this.state.selectedStartOptions).length ? this.state.ruleOptions.filter(e => e.startValue === this.state.selectedStartOptions)[0].startValue : <span className="placeholder">Select</span>
											})}		
											options={this.state.ruleOptions && this.state.ruleOptions.map(row => ({
												value: row.startValue,
												label: row.startValue,
												startValue: row.startValue,
												endValue: row.endValue
											}))}															
											onChange={event => this.setState({ 
												selectedStartOptions: event.startValue, 
												selectedStopOptions: Array.isArray(event.endValue) ? "" : event.endValue, 
												stopRuleOptions: Array.isArray(event.endValue) ? event.endValue : []
											})}
										/>
										<p className={`mb-0 mx-2 mt-2 align-self-center text-white`}>at</p>

										<input 
											type="text" 
											className={`inputField w-8 pl-2`}
											style={{minHeight: "38px"}}
											placeholder={"HH:MM"}
											value={this.state.start_time}
											maxLength="5"
											onKeyDown={e => this.onKeyDownTimeRange(e, e.target.value, "start_time")}
											onChange={e => this.validateTimeRange(e.target.value, "start_time")}
										/>											
										<p className={`mb-0 ml-2 mr-1 mt-3 align-self-center text-primary-color f12`}> (local time eg:- 22:15) </p>
										<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center text-white`}>hrs on day</p>

										<Select
											placeholder={'Select'}
											isSearchable={true}
											components={{
												IndicatorSeparator: () => null
											}}
											className={"selectOption"}
											value={({
												value: this.state.selectedStartDay,
												label: this.state.selectedStartDay && this.state.selectedStartDay !== "" && this.state.shutdownOptions ? this.state.monthOptions.filter(e => e.value === this.state.selectedStartDay)[0].label : <span className="placeholder">Select</span>
											})}		
											options={this.state.monthOptions && this.state.monthOptions.map(row => ({
												value: row.value,
												label: row.label
											}))}																
											onChange={event => this.setState({ selectedStartDay: event.value })}
										/>
										
										<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center text-white`}>and </p>

										{this.state.stopRuleOptions && this.state.stopRuleOptions.length ?
											<Select
												placeholder={'Select'}
												isSearchable={false}
												components={{
													IndicatorSeparator: () => null
												}}
												className={"selectOption"}
												value={({
													value: this.state.selectedStopOptions,
													label: this.state.selectedStopOptions && this.state.selectedStopOptions !== "" ? this.state.selectedStopOptions : <span className="placeholder">Select</span>
												})}		
												options={this.state.stopRuleOptions && this.state.stopRuleOptions.map(row => ({
													value: row,
													label: row
												}))}															
												onChange={event => this.setState({
													selectedStopOptions: event.value
												})}
											/>
										:
											<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center text-info`}>{this.state.selectedStopOptions}</p>
										}
										<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center text-white`}>at</p>

										<input 
											type="text" 
											className={`inputField w-8 pl-2`}
											style={{minHeight: "38px"}}
											placeholder={"HH:MM"}
											value={this.state.end_time}
											maxLength="5"
											onKeyDown={e => this.onKeyDownTimeRange(e, e.target.value, "end_time")}
											onChange={e => this.validateTimeRange(e.target.value, "end_time")}
										/>
										<p className={`mb-0 ml-2 mr-1 mt-3 align-self-center text-primary-color f12`}> (local time eg:- 22:15) </p>
										<p className={`mb-0 ml-2 mr-1 mt-2 align-self-center text-white`}>hrs on day</p>

										<Select
											placeholder={'Select'}
											isSearchable={true}
											components={{
												IndicatorSeparator: () => null
											}}
											className={"selectOption"}
											value={({
												value: this.state.selectedEndDay,
												label: this.state.selectedEndDay && this.state.selectedEndDay !== "" && this.state.shutdownOptions ? this.state.monthOptions.filter(e => e.value === this.state.selectedEndDay)[0].label : <span className="placeholder">Select</span>
											})}		
											options={this.state.monthOptions && this.state.monthOptions.map(row => ({
												value: row.value,
												label: row.label
											}))}																
											onChange={event => this.setState({ selectedEndDay: event.value })}
										/>
									</div>
								: null}
							</div>

							<div className="rounded p-3 dlpDescriptionForm dlpDescriptionFormPlain">
								<div class="d-flex flex-wrap">
									<p className={`mb-0 mr-2 mt-2 align-self-center text-white`}>Expire the Resources on</p>
									<Datetime 
										value={this.state.expiration_time && this.state.expiration_time !== "" ? momentDateGivenFormat(this.state.expiration_time, 'DD MMM YYYY HH:mm') : ""}
										onChange={this.handleExpiationChange}
										dateFormat={'DD MMM YYYY'}
										timeFormat={'HH:mm'}
										inputProps={{ readOnly: true, class: "inputField" }}
										className="mt-3"
										isValidDate={disableFutureDt}
									/>

									<p className="mb-0 mt-2 ml-2 text-info" onClick={() => this.setState({ expiration_time: "" })}>Clear</p>
								</div>
							</div>

							<div className="rounded p-3 dlpDescriptionForm dlpDescriptionFormPlain">
								<div className="d-flex">
									<div className={`form-check form-check-inline align-self-center mr-2 ${this.state.retain_configuration ? "" : "checkboxGrayColor"}`}>
										<input
											className='form-check-input m-0'
											type='checkbox'
											checked={this.state.retain_configuration}
											onClick={e => this.setState({ retain_configuration: !this.state.retain_configuration })}	
											// title={this.state.retain_configuration ? "not enable" : "enable"}								
										/>
										{!this.state.retain_configuration ?
											<span class="checkmark" onClick={e => this.setState({ retain_configuration: !this.state.retain_configuration})}></span>
										: null}
									</div>
									<span class="mt-2p f16">Retain configuration</span>
								</div>
							</div>

							<div className="rounded p-3 dlpDescriptionForm dlpDescriptionFormPlain">
								<div className="d-flex">
									<div className={`form-check form-check-inline align-self-center mr-2 ${this.state.retain_dependencies ? "" : "checkboxGrayColor"}`}>
										<input
											className='form-check-input m-0'
											type='checkbox'
											checked={this.state.retain_dependencies}
											onClick={e => this.setState({ retain_dependencies: !this.state.retain_dependencies })}	
											// title={this.state.retain_dependencies ? "not enable" : "enable"}								
										/>
										{!this.state.retain_dependencies ?
											<span class="checkmark" onClick={e => this.setState({ retain_dependencies: !this.state.retain_dependencies})}></span>
										: null}
									</div>
									<span class="mt-2p f16">Retain Dependencies</span>
								</div>
							</div>

							<div className="rounded p-3 dlpDescriptionForm dlpDescriptionFormPlain">
								<div className="d-flex">
									<div className={`form-check form-check-inline align-self-center mr-2 ${this.state.notification_enabled ? "" : "checkboxGrayColor"}`}>
										<input
											className='form-check-input m-0'
											type='checkbox'
											checked={this.state.notification_enabled}
											onClick={e => this.setState({ notification_enabled: !this.state.notification_enabled })}	
											// title={this.state.notification_enabled ? "not enable" : "enable"}								
										/>
										{!this.state.notification_enabled ?
											<span class="checkmark" onClick={e => this.setState({ notification_enabled: !this.state.notification_enabled})}></span>
										: null}
									</div>
									<span class="mt-2p f16">Enable Notifications</span>
								</div>
							</div>

							<div className="rounded p-3 dlpDescriptionForm dlpDescriptionFormPlain displayNone">
								<div className="d-flex justify-content-between">
									<p className="mb-0 f16 text-white font-weight-bold">Notifications</p>
									{/* <h5 className="mb-0 text-white cursorPointer d-flex">Notifications</h5> */}
									{/* <span className={`ml-4 fa fa-angle-${this.state.showNotificationSection ? "up" : "down"} f22 text-white cursorPointer`} onClick={() => this.setState({ showNotificationSection: !this.state.showNotificationSection })}></span> */}
								</div>
								{this.state.notificationArray.map(item => {
									return(
										<div className="d-flex flex-wrap mb-3">
											<p className={`mb-0 mr-1 mt-2 align-self-center text-white`}>{capitalizeFirstLetter(item)} details</p>
											<div className="selectBoxBottomColor w-20">
												<input 
													type="text" 
													className={`inputField w-100`}
													style={{minHeight: "38px"}}
													placeholder={"Enter details"}
													value={this.state["shared_to_"+item]}
													onChange={e => this.setState({ ["shared_to_"+item]: e.target.value })}
												/>
											</div>
										
											<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => this.addNewSharedDetails(item)}></span>
											{this.state["shared_details_"+item] && this.state["shared_details_"+item].length ? 
												this.state["shared_details_"+item].map((share, index) => {
													return(
														<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{share}
															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeSharedDetails(item, share) } ></i>
														</span>
													)
												})
											: null}
										</div>
									)
								})}
							</div>								
							{/* disabled={this.state.disableSubmit} */}
							<div className="d-flex justify-content-end">
								<button className="btn btn-primary" onClick={() => this.setState({ disableSubmit: true }, () => this.createUpdatePolicy())} >Submit</button>
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ScheduleAutomation.propTypes = {}

const mapStateToProps = state => {
	// console.log('observability query page',state)
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions,
		editNewRuleDetails: state.governance.editNewRuleDetails,
	}
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions,
	listLifecyclePolicies,
	putLifecyclePolicy,
	listLifecycleRules,
})(withRouter(ScheduleAutomation))