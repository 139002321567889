/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spinner, Input } from 'reactstrap'
import { setEditRuleDetails, listStorageTypes } from '../../../actions/governance/governanceAction'
import { onlyNumeric } from '../../../utils/utility'
import PropTypes from 'prop-types'
import Select from 'react-select'

// const volumeTypes = [
// 	'General Purpose',
// 	'Provisioned IOPS SSD (io1)',
// 	'Provisioned IOPS SSD (io2)',
// 	'Magnetic',
// 	'Cold HDD',
// 	'Throughput Optimized'
// ]

const sizeOption = [
	{label: 'GB', value: 'GB'},
	{label: 'TB', value: 'TB'}
]

class ServiceActionLimits extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: this.props.hasError,
			showVolumeLoading: false,
			storageVolumeTypes: []
		}
	}

	componentDidMount = () => {
		let params = {}
		params.provider = this.props.editRuleDetails.provider.toLowerCase()
		params.resource_type = this.props.editRuleDetails.resource_type
		this.setState({ showVolumeLoading : true })
		if(!this.props.blockStoreProps || !Object.entries(this.props.blockStoreProps).length || this.props.blockStoreProps.body.resource_type !== this.props.editRuleDetails.resource_type) {
			this.props.listStorageTypes(params, (promise, storageTypes) => {
				let storageVolumeTypes = []
				if(promise) {
					storageTypes.length && storageTypes[0].results && storageTypes[0].results.forEach(item => {
						// let volType = item.volume_details.volume_type
						let dataRow = {}
						dataRow.label = item.volume_details.volume_name
						dataRow.value = item.volume_details.volume_type
						// if(volType === 'io1' || volType === 'io2') {
						// 	volType = 'Provisioned IOPS '+volType
						// }
						storageVolumeTypes.push(dataRow)
						
					})
					let volumeTypeArray = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType ? this.props.editRuleDetails.condition.allowedVolumeType : []
					if(storageVolumeTypes.length && volumeTypeArray.length === storageVolumeTypes.length) {
						this.setState({ selectAllVolume: true })
					}
					this.setState({ storageTypes, storageVolumeTypes, showVolumeLoading: false })
				} else {
					this.setState({ storageTypes: [], storageVolumeTypes, showVolumeLoading: false })
				}
			})	
		} else {
			let storageVolumeTypes = []
			Object.entries(this.props.blockStoreProps).length && this.props.blockStoreProps.result[0].results && this.props.blockStoreProps.result[0].results.forEach(item => {
				// let volType = item.volume_details.volume_type
				let dataRow = {}
				dataRow.label = item.volume_details.volume_name
				dataRow.value = item.volume_details.volume_type
				// if(volType === 'io1' || volType === 'io2') {
				// 	volType = 'Provisioned IOPS '+volType
				// }
				storageVolumeTypes.push(dataRow)
				
			})
			let volumeTypeArray = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType ? this.props.editRuleDetails.condition.allowedVolumeType : []
			if(storageVolumeTypes.length && volumeTypeArray.length === storageVolumeTypes.length) {
				this.setState({ selectAllVolume: true })
			}
			this.setState({ storageTypes: this.props.blockStoreProps, storageVolumeTypes, showVolumeLoading: false })
		}	
	}

	componentDidUpdate = (prevProps) => {
		if(typeof this.props.hasError !== 'undefined' && this.props.hasError !== prevProps.hasError) {
			this.setState({ hasError: this.props.hasError })
		}
	}

	setAllSlectedTypes = () => {
		let volumeTypeArray = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType ? this.props.editRuleDetails.condition.allowedVolumeType : []
		if(this.state.selectAllVolume) {
			if(this.state.storageVolumeTypes.length) {
			 	this.state.storageVolumeTypes.map(async type => {
					if(volumeTypeArray.length) {
						if(!volumeTypeArray.filter(arr => arr.volumeType === type.value).length) {
							let dataRow = {}
							dataRow.volumeType = type.value
							dataRow.volumeName = type.label
							dataRow.totalVolumes = ''
							dataRow.totalSizeVolumes = ''
							dataRow.totalSizeVolumesUnit = 'GB'
							dataRow.maxSizePerVolume = ''
							dataRow.maxSizePerVolumeUnit = 'GB'
							if(type.label === 'Provisioned IOPS SSD (io1)' || type.label === 'Provisioned IOPS SSD (io2)' ) {
								dataRow.totalPIOPS = ''
								dataRow.maxPIOPSPerVolume = ''
							}
							volumeTypeArray.push(dataRow)
						}
					} else {
						let dataRow = {}
						dataRow.volumeType = type.value
						dataRow.volumeName = type.label
						dataRow.totalVolumes = ''
						dataRow.totalSizeVolumes = ''
						dataRow.totalSizeVolumesUnit = 'GB'
						dataRow.maxSizePerVolume = ''
						dataRow.maxSizePerVolumeUnit = 'GB'
						if(type.label === 'Provisioned IOPS SSD (io1)' || type.label === 'Provisioned IOPS SSD (io2)' ) {
							dataRow.totalPIOPS = ''
							dataRow.maxPIOPSPerVolume = ''
						}
						volumeTypeArray.push(dataRow)
					}

					this.props.setEditRuleDetails('condition', {
						...this.props.editRuleDetails.condition,
						allowedVolumeType: volumeTypeArray
					})
				})
			}
		} else {
			let volumeTypeArray = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType ? this.props.editRuleDetails.condition.allowedVolumeType : []
			if(this.state.storageVolumeTypes.length) {
				this.state.storageVolumeTypes.forEach(type => {
					if(volumeTypeArray.length) {
						if(volumeTypeArray.filter(arr => arr.volumeType === type.value).length) {
							volumeTypeArray = volumeTypeArray.filter(arr => arr.volumeType !== type.value)
						}
					}
					
					this.props.setEditRuleDetails('condition', {
						...this.props.editRuleDetails.condition,
						allowedVolumeType: volumeTypeArray
					})
				})
			}
		}
	}

	setAllowedVolumeTypes = (volumeType, volumeName) => {
		let volumeTypeArray = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType ? this.props.editRuleDetails.condition.allowedVolumeType : []
		if(volumeTypeArray.length) {
			if(volumeTypeArray.filter(arr => arr.volumeType === volumeType).length) {
				volumeTypeArray = volumeTypeArray.filter(arr => arr.volumeType !== volumeType)
			} else {
				let dataRow = {}
				dataRow.volumeName = volumeName
				dataRow.volumeType = volumeType
				dataRow.totalVolumes = ''
				dataRow.totalSizeVolumes = ''
				dataRow.totalSizeVolumesUnit = 'GB'
				dataRow.maxSizePerVolume = ''
				dataRow.maxSizePerVolumeUnit = 'GB'
				if(volumeType === 'Provisioned IOPS SSD (io1)' || volumeType === 'Provisioned IOPS SSD (io2)' ) {
					dataRow.totalPIOPS = ''
					dataRow.maxPIOPSPerVolume = ''
				}
				volumeTypeArray.push(dataRow)
				// volumeTypeArray.push(volumeType)
			}
		} else {
			let dataRow = {}
			dataRow.volumeName = volumeName
			dataRow.volumeType = volumeType
			dataRow.totalVolumes = ''
			dataRow.totalSizeVolumes = ''
			dataRow.totalSizeVolumesUnit = 'GB'
			dataRow.maxSizePerVolume = ''
			dataRow.maxSizePerVolumeUnit = 'GB'
			if(volumeType === 'Provisioned IOPS SSD (io1)' || volumeType === 'Provisioned IOPS SSD (io2)' ) {
				dataRow.totalPIOPS = ''
				dataRow.maxPIOPSPerVolume = ''
			}
			volumeTypeArray.push(dataRow)
			// volumeTypeArray.push(volumeType)
		}
		if(volumeTypeArray.length === this.state.storageVolumeTypes.length) {
			this.setState({ selectAllVolume: true })
		} else {
			this.setState({ selectAllVolume: false })
		}
		this.props.setEditRuleDetails('condition', {
			...this.props.editRuleDetails.condition,
			allowedVolumeType: volumeTypeArray
		})
	}

	setAllowedVolumeTypeValues = (volumeType, volumeName, type, textBoxValue) => {
		textBoxValue = textBoxValue.slice(0, 10);
		let volumeTypeArray = this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType ? this.props.editRuleDetails.condition.allowedVolumeType : []
		volumeTypeArray.forEach((value ,index) => {
			if(value.volumeType === volumeType) {
				let dataRow = {}
				dataRow.volumeType = volumeType
				dataRow.volumeName = volumeName
				if(type === 'totalVolumes') {
					dataRow.totalSizeVolumes = value.totalSizeVolumes
					dataRow.totalSizeVolumesUnit = value.totalSizeVolumesUnit
					dataRow.maxSizePerVolume = value.maxSizePerVolume
					dataRow.maxSizePerVolumeUnit = value.maxSizePerVolumeUnit
					dataRow.totalVolumes = textBoxValue
					if(volumeType === 'Provisioned IOPS SSD (io1)' || volumeType === 'Provisioned IOPS SSD (io2)' ) {
						dataRow.totalPIOPS = value.totalPIOPS
						dataRow.maxPIOPSPerVolume = value.maxPIOPSPerVolume
					}
				} else if(type === 'totalSizeVolumes') {
					dataRow.totalSizeVolumes = textBoxValue
					dataRow.totalSizeVolumesUnit = value.totalSizeVolumesUnit
					dataRow.maxSizePerVolume = value.maxSizePerVolume
					dataRow.maxSizePerVolumeUnit = value.maxSizePerVolumeUnit
					dataRow.totalVolumes = value.totalVolumes
					if(volumeType === 'Provisioned IOPS SSD (io1)' || volumeType === 'Provisioned IOPS SSD (io2)' ) {
						dataRow.totalPIOPS = value.totalPIOPS
						dataRow.maxPIOPSPerVolume = value.maxPIOPSPerVolume
					}
					
				} else if(type === 'maxSizePerVolume') {
					dataRow.totalSizeVolumes = value.totalSizeVolumes
					dataRow.totalSizeVolumesUnit = value.totalSizeVolumesUnit
					dataRow.maxSizePerVolume = textBoxValue
					dataRow.maxSizePerVolumeUnit = value.maxSizePerVolumeUnit
					dataRow.totalVolumes = value.totalVolumes
					if(volumeType === 'Provisioned IOPS SSD (io1)' || volumeType === 'Provisioned IOPS SSD (io2)' ) {
						dataRow.totalPIOPS = value.totalPIOPS
						dataRow.maxPIOPSPerVolume = value.maxPIOPSPerVolume
					}
				} else if(type === 'totalSizeVolumesUnit') {
					dataRow.totalSizeVolumes = value.totalSizeVolumes
					dataRow.totalSizeVolumesUnit = textBoxValue
					dataRow.maxSizePerVolume = value.maxSizePerVolume
					dataRow.maxSizePerVolumeUnit = value.maxSizePerVolumeUnit
					dataRow.totalVolumes = value.totalVolumes
					if(volumeType === 'Provisioned IOPS SSD (io1)' || volumeType === 'Provisioned IOPS SSD (io2)' ) {
						dataRow.totalPIOPS = value.totalPIOPS
						dataRow.maxPIOPSPerVolume = value.maxPIOPSPerVolume
					}
				} else if(type === 'maxSizePerVolumeUnit') {
					dataRow.totalSizeVolumes = value.totalSizeVolumes
					dataRow.totalSizeVolumesUnit = value.totalSizeVolumesUnit
					dataRow.maxSizePerVolume = value.maxSizePerVolume
					dataRow.maxSizePerVolumeUnit = textBoxValue
					dataRow.totalVolumes = value.totalVolumes
					if(volumeType === 'Provisioned IOPS SSD (io1)' || volumeType === 'Provisioned IOPS SSD (io2)' ) {
						dataRow.totalPIOPS = value.totalPIOPS
						dataRow.maxPIOPSPerVolume = value.maxPIOPSPerVolume
					}
				} else {
					dataRow.totalSizeVolumes = value.totalSizeVolumes
					dataRow.totalSizeVolumesUnit = value.totalSizeVolumesUnit
					dataRow.maxSizePerVolume = value.maxSizePerVolume
					dataRow.maxSizePerVolumeUnit = value.maxSizePerVolumeUnit
					dataRow.totalVolumes = value.totalVolumes
				}
				
				if(type === 'totalPIOPS') {
					dataRow.totalPIOPS = textBoxValue
					dataRow.maxPIOPSPerVolume = value.maxPIOPSPerVolume
				} else if(type === 'maxPIOPSPerVolume') {
					dataRow.totalPIOPS = value.totalPIOPS
					dataRow.maxPIOPSPerVolume = textBoxValue
				}				
				let objIndex = volumeTypeArray.findIndex(arr => arr.volumeType === value.volumeType);
				if(objIndex >= 0) {
					volumeTypeArray[objIndex] = dataRow
				} else {
					volumeTypeArray.push(dataRow)
				}
			}
		})
		
		this.props.setEditRuleDetails('condition', {
			...this.props.editRuleDetails.condition,
			allowedVolumeType: volumeTypeArray
		})
	}
	
	render() {
		return (
			<React.Fragment>
				<div className={`mb-2 ${this.state.storageVolumeTypes.length ? 'pb-3 border-bottom' : ''} `}>
					<div className="d-flex">
						<h6 className="text-info mr-3">Allowed Volume Types</h6>
						<div className={`${this.state.showVolumeLoading ? '' : 'displayNone'}`}>
							<Spinner className='text-center' color='light' size='sm' />
						</div>
						<div className={`form-group form-check mb-1 ${this.state.storageVolumeTypes.length ? '' : 'displayNone'}`}>
							<input 
								type="checkbox" 
								className="form-check-input" 
								id="imageVersion" 
								checked={this.state.selectAllVolume ? true : false}
								onChange={e => this.setState({ selectAllVolume: this.state.selectAllVolume ? false : true },
									() => this.setAllSlectedTypes('All')
								)}
							/>
							<label className="form-check-label" for="imageVersion">Select/Unselect&nbsp;all&nbsp;volume&nbsp;types</label>
						</div>
						{this.state.hasError && (!this.props.editRuleDetails.condition.allowedVolumeType || !this.props.editRuleDetails.condition.allowedVolumeType.length)? 
							<small className='text-danger'>Please select the allowed Volume Types.</small>
						: null}
					</div>
					<div className="d-flex">
						{this.state.storageVolumeTypes && this.state.storageVolumeTypes.length ? 
							this.state.storageVolumeTypes.map(vType => {
								return (
									<div className="form-group form-check mr-3 mb-0">
										<input 
											type="checkbox" 
											className="form-check-input" 
											checked={this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType && this.props.editRuleDetails.condition.allowedVolumeType.filter(arr => arr.volumeType === vType.value).length ? true : false}
											onChange={e => this.setAllowedVolumeTypes(vType.value, vType.label)}
										/>
										<label className="form-check-label">
											{vType.label}
										</label>
									</div>
								)
							})
						: 
							!this.state.showVolumeLoading ?
								<div className='d-flex justify-content-center m-4'>
									<p>No Volume Types in {this.props.editRuleDetails.resource_type}</p>
								</div>
							: null
						}
					</div>
				</div>
				<div className="row">
					{this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType && this.props.editRuleDetails.condition.allowedVolumeType.length ?
						<div className="col-md-9">
							<div className="mb-2">
								<h6 className="text-info">Volume Size</h6>
								<p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
									magna aliqua.
								</p>
								<div className="p-3 bg-dark rounded mb-3">
									{this.props.editRuleDetails.condition.allowedVolumeType.map((value, index) => {
										return (
											<div className="row mb-3 zapInputDark">
												<div className="col-md-2 align-self-center">
												<p className="mb-0 font-weight-bold">{value.volumeName}</p>
												</div>
												<div className="col-md-3 align-self-center">
													<div className="d-flex">
														<small className="align-self-center">Total volume</small>
														<Input 
															type="text" 
															className="inputTextbox w-25 mx-2" 
															maxlength="10"
															value={value.totalVolumes}
															onChange={e => this.setAllowedVolumeTypeValues(value.volumeType, value.volumeName, 'totalVolumes', onlyNumeric(e.target.value))}
														/>
												</div>
												</div>
												<div className="col-md-3 align-self-center">
													<div className="d-flex">
														<small className="align-self-center">Total Size of volume</small>
														<Input 
															type="text" 
															className="inputTextbox w-25 mx-2"
															maxlength="10"
															value={value.totalSizeVolumes}
															onChange={e => this.setAllowedVolumeTypeValues(value.volumeType, value.volumeName, 'totalSizeVolumes', onlyNumeric(e.target.value))}
														/>
														<Select
															isSearchable={false}
															className={`f13 wpx-80 p-0`}
															value={({
																value: value.totalSizeVolumesUnit,
																label: value.totalSizeVolumesUnit ? value.totalSizeVolumesUnit : <span className="placeholder">Select</span>
															})}
															options={sizeOption.length && sizeOption.map(item => ({
																value: item.value,
																label: item.label,
															}))}
															onChange={e => this.setAllowedVolumeTypeValues(value.volumeType, value.volumeName, 'totalSizeVolumesUnit', e.value)}
														/>
												</div>
												</div>
												<div className="col-md-3 align-self-center">
													<div className="d-flex">
														<small className="align-self-center">Max Size per volume</small>
														<Input 
															type="text" 
															className="inputTextbox w-25 mx-2"
															maxlength="10"
															value={value.maxSizePerVolume}
															onChange={e => this.setAllowedVolumeTypeValues(value.volumeType, value.volumeName, 'maxSizePerVolume', onlyNumeric(e.target.value))}
														/>
														<Select
															isSearchable={false}
															className={`f13 wpx-80 p-0`}
															value={({
																value: value.maxSizePerVolumeUnit,
																label: value.maxSizePerVolumeUnit ? value.maxSizePerVolumeUnit : <span className="placeholder">Select</span>
															})}
															options={sizeOption.length && sizeOption.map(item => ({
																value: item.value,
																label: item.label,
															}))}
															onChange={e => this.setAllowedVolumeTypeValues(value.volumeType, value.volumeName, 'maxSizePerVolumeUnit', e.value)}
														/>
													</div>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					: null}
					
					{this.props.editRuleDetails.condition && this.props.editRuleDetails.condition.allowedVolumeType && this.props.editRuleDetails.condition.allowedVolumeType.length && 
					(this.props.editRuleDetails.condition.allowedVolumeType.filter(arr => arr.volumeName === 'Provisioned IOPS SSD (io1)').length ||
					this.props.editRuleDetails.condition.allowedVolumeType.filter(arr => arr.volumeName === 'Provisioned IOPS SSD (io2)').length) ?
						<div className="col-md-12 pt-3 mt-2 border-top">
							<div className="row zapInputDark">
								<div className="col-md-9">
									<h6 className="text-info">PIOPS Configuration</h6>
									<p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
										magna aliqua.
									</p>
									<div className="p-3 bg-dark rounded mb-3">
										{this.props.editRuleDetails.condition.allowedVolumeType.map((value, index) => {
											if(value.volumeName === 'Provisioned IOPS SSD (io1)' || value.volumeName === 'Provisioned IOPS SSD (io2)') {
												return (
													<div className="row mb-3">
														<div className="col-md-2 align-self-center">
															<p className="mb-0 font-weight-bold">{value.volumeName}</p>
														</div>
														<div className="col-md-3 align-self-center">
															<div className="d-flex">
																<small className="align-self-center">Total PIOPS</small>
																<Input 
																	type="text" 
																	className="inputTextbox w-25 mx-2" 
																	maxlength="10"
																	value={value.totalPIOPS}
																	onChange={e => this.setAllowedVolumeTypeValues(value.volumeType, value.volumeName, 'totalPIOPS', onlyNumeric(e.target.value))}
																/>
															</div>
														</div>
														<div className="col-md-3 align-self-center">
															<div className="d-flex">
																<small className="align-self-center">Max PIOPS per volume</small>
																<Input 
																	type="text" 
																	className="inputTextbox w-25 mx-2" 
																	maxlength="10"
																	value={value.maxPIOPSPerVolume}
																	onChange={e => this.setAllowedVolumeTypeValues(value.volumeType, value.volumeName, 'maxPIOPSPerVolume', onlyNumeric(e.target.value))}
																/>
															</div>
														</div>
													</div>
												)
											}
										})}
									</div>
								</div>
							</div>
						</div>
					: null}
				</div>
			</React.Fragment>
		)
	}
}

/**
 * Type of the props used in the component
 */
ServiceActionLimits.propTypes = {
	setEditRuleDetails: PropTypes.func,
	condition: PropTypes.object,
	editRuleDetails: PropTypes.object,
	listStorageTypes: PropTypes.func
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	console.log('state.governance.editRuleDetails',state.governance)
	return {
		editRuleDetails: state.governance.editRuleDetails,
		blockStoreProps: state.governance.blockStoreProps
	}
}

export default connect(mapStateToProps, { setEditRuleDetails, listStorageTypes })(ServiceActionLimits)
