/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { capitalizeFirstLetter, getAccountNameFromId, getRegionName, stringLength, momentDateTime } from '../../utils/utility'
import { UncontrolledTooltip } from 'reactstrap'
import { deleteUserRule } from '../../actions/governance/governanceAction'
import { listAllRegions, listAllAccounts } from '../../actions/commonAction'

import { store as CommonNotification } from 'react-notifications-component';

class DeleteSidePanel extends Component {
	proEnvWrap = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			activeTab: 0,
			showLoading: false,
			rulesJson: this.props.rulesJson,
			allowedVolumeType: {},
			accounts: [],
			regions: [],
			enteredRuleId: '',
			decisionArray: [],
		}
	}

	componentDidMount = () => {
		let allowedVolumeType = {}
		if(this.props.rulesJson.category === 'Service-BlockStore') {
			let volumeSizeArray = []
			let piopsConfigurationArray = []
			this.props.rulesJson.condition.allowedVolumeType.forEach((vType, index) => {
				let volumeDataRow = {}
				volumeDataRow.maxSizePerVolume = vType.maxSizePerVolume
				volumeDataRow.maxSizePerVolumeUnit = vType.maxSizePerVolumeUnit
				volumeDataRow.totalSizeVolumes = vType.totalSizeVolumes
				volumeDataRow.totalSizeVolumesUnit = vType.totalSizeVolumesUnit
				volumeDataRow.totalVolumes = vType.totalVolumes
				volumeDataRow.volumeName = vType.volumeName
				volumeDataRow.volumeType = vType.volumeType
				volumeSizeArray.push(volumeDataRow)

				
				if(vType.volumeName === 'Provisioned IOPS SSD (io1)' || vType.volumeName === 'Provisioned IOPS SSD (io2)' ) {	
					let piopsDataRow = {}
					piopsDataRow.volumeName = vType.volumeName
					piopsDataRow.totalPIOPS = vType.totalPIOPS
					piopsDataRow.maxPIOPSPerVolume = vType.maxPIOPSPerVolume
					piopsConfigurationArray.push(piopsDataRow)
				}			
			})
			allowedVolumeType['Volume Size'] = volumeSizeArray
			if(piopsConfigurationArray.length) {
				allowedVolumeType['PIOPS Configuration'] = piopsConfigurationArray
			}

			this.setState({ allowedVolumeType })
		} else if(this.props.rulesJson.category === 'Service-Sizing' && this.props.rulesJson.resource_type === 'EC2') {
			let decisionArray = []
			this.props.rulesJson.condition && this.props.rulesJson.condition.allowedInstanceFamily.forEach(decision => {
				let decisionRow = {}
				decisionRow.node_type = decision.node_type
				let nodes = _.chain(decision.instance_type).groupBy('instanceFamily').map((value, key) => ({ label: key, sub: value })).value()
				let parentArray = []
				nodes.forEach(item => {
					let parentRow = {}
					parentRow.family = item.label
					parentRow.series = _.chain(item.sub).groupBy('instanceSeries').map((value, key) => ({ label: key, types: value })).value()
					parentArray.push(parentRow)
				})
				decisionRow.parent = parentArray

				decisionArray.push(decisionRow)
				// array = parentArray
				// previewRow.value = array
				// previewArray.push(previewRow)
			})

			this.setState({ decisionArray })
		}

		let params = {}
		params.provider = this.props.rulesJson.provider.toLowerCase()

		if(this.props.accounts && this.props.accounts.length) {
			this.setState({ accounts: this.props.accounts })
		} else {
			this.props.listAllAccounts(params, (promise, accounts) => {
				if (promise) {
					this.setState({ accounts })
				} else {
					this.setState({ accounts: [] })
				}
			})
		}

		if(this.props.regions && this.props.regions.length) {
			let providerRegions = this.props.regions
			this.setState({ regions: providerRegions })
		} else {
			this.props.listAllRegions(params, (promise, regions) => {
				if (promise) {
					let providerRegions = regions && regions[0] && regions[0].provider_regions
					if(providerRegions) {
						let provider_regions_row = {}
						provider_regions_row.availability_zones = []
						provider_regions_row.city = "All"
						provider_regions_row.country = "All"
						provider_regions_row.geography = "All"
						provider_regions_row.name = "All"
						provider_regions_row.region = "All"
						provider_regions_row.region_id = "All"
						provider_regions_row.state = "All"
						providerRegions.unshift(provider_regions_row);
					}
					this.setState({ regions: providerRegions })
				} else {
					this.setState({ regions: [] })
				}
			})
		}
	}

	onClickDeleteRule = (ruleId) => {
		if(ruleId) {
			let params = {}
			params.rule_id = ruleId
			this.props.deleteUserRule(params, (promise, result) => {
				let messageType = 'danger'		
				let message = result.message
				CommonNotification.addNotification({
					//title: "Wonderful!",
					message: message,
					type: messageType,
					insert: "top",
					container: "top-center",
					// animationIn: ["animate__animated", "animate__fadeIn"],
					// animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
					  duration: 5000,
					  onScreen: false,
					  pauseOnHover: true,
					  showIcon: true,			  
					}
				});
				if(result.status) {
					this.setState({ showLoading: false },
						() => this.setTimeoutFunction(ruleId)
					)
				}
			})	
		}
	}

	setTimeoutFunction = (ruleId) => {
		setTimeout(() => {
			this.props.onClickDeleteRule(ruleId)
			this.props.closeViewDeleteSidePanel()
		}, 5000)
	}
	
	getInstanceCount = (series) => {
		let count = 0
		this.state.decisionArray.forEach(item => {
			item.parent.forEach(instance => {
				instance.series.forEach(srs => {
					if(srs.label === series) {
						srs.types.forEach((typ, typIndex) => {
							count += parseInt(typ.count === '' ? 0 : typ.count)
						})
					}
				})
			})
		})
		return count
	}

	render() {
		return (
			<div className="advanced-search" style={{zIndex:9999999}}>
				<div className="search-content overflow-auto">
					<div className="header-search bd-highlight justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>{this.props.rulesJson.rule_name}</h5>
						</div>
						<div className="flex-fill bd-highlight ml-3 align-self-center cursorPointer text-right">
							<i className="far fa-times" onClick={() => this.props.closeViewDeleteSidePanel()}></i>
						</div>
					</div>
					{!this.state.showLoading ?
						<div className="col-sm-12 mt-3 mb-3">
							<h5 className="text-danger">Delete Rule</h5>
							<p>To  delete the selected rule, please enter the rule id (<span className="font-weight-bold">{this.props.rulesJson.rule_id}</span>) in box below to confirm the action</p>
							

							<div className="mt-3 mb-3">
								<div className="d-flex m-auto">
									<div className="w-100">
										<input 
											type="text" 
											className="form-control"
											onChange={(event) => this.setState({ enteredRuleId:  event.target.value })} 
											value={this.state.enteredRuleId}
										/>
									</div>
									<button className={`ml-2 btn bg-danger text-white ${this.state.enteredRuleId === this.props.rulesJson.rule_id ? '' : 'disabled'}`} type="button" onClick={() => this.setState({ showLoading: false }, this.onClickDeleteRule(this.props.rulesJson.rule_id)) }>Delete</button>
								</div>
							</div>
							<h6>Policy Detail</h6>
							<div className='mt-1'>
								<h5>{capitalizeFirstLetter(this.state.selectedRole)}</h5>
								<div className="p-3 bg-light-50 rounded">
									<h6 className="text-primary">Description</h6>
									<div className="row">
										<div className="col-md-12">
											<label className="small mb-1 d-block">Rule ID</label>
											<h6>{this.props.rulesJson.rule_id}</h6>
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Provider</label>
											<h6>{this.props.rulesJson.provider && this.props.rulesJson.provider !== ''  ? this.props.rulesJson.provider.toUpperCase() : ''}</h6>
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Category</label>
											<h6>{this.props.rulesJson.category}</h6>
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Service</label>
											<h6>{this.props.rulesJson.resource_type}</h6>
										</div>						
										<div className="col-md-6">
											<label className="small mb-1 d-block">Base Rule ID</label>
											<h6>{this.props.rulesJson.base_rule_id}</h6>
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Base Rule Name</label>
											<h6>{this.props.rulesJson.base_rule_name}</h6>
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Priority</label>
											<label className="badge badge-danger text-white">{this.props.rulesJson.priority}</label>
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Compliance</label>
											{this.props.rulesJson.complianceId ?				
												this.props.rulesJson.complianceId.map(comp => {
													return (
														<label className="badge badge-outline-secondary mr-1">{comp}</label>
													)
												})
											: null
											}
										</div>										
										<div className="col-md-6">
											<label className="small mb-1 d-block">Classification</label>
											<h6>{this.props.rulesJson.classification}</h6>
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Type</label>
											{this.props.rulesJson.governance_type ?				
												this.props.rulesJson.governance_type.map(type => {
													return (
														<label className="badge badge-outline-secondary mr-1">{type}</label>
													)
												})
											: null
											}
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Category</label>
											<h6>{this.props.rulesJson.governance_category}</h6>
										</div>
										{this.props.editRuleDetails.version ? 
											<div className="col-md-6">
												<label className="small mb-1 d-block">Current Version</label>
												<h6>{this.props.rulesJson.version}</h6>
											</div>
										: null
										}
										<div className="col-md-6">
											<label className="small mb-1 d-block">Rule Name</label>
											<h6>{this.props.rulesJson.rule_name}</h6>
										</div>
										<div className="col-md-6">
											<label className="small mb-1 d-block">Description</label>
											<h6>{this.props.rulesJson.description}</h6>
										</div>
										{this.props.rulesJson.created_at && this.props.rulesJson.created_by ? 
											<React.Fragment>
											<div className="col-md-6">
												<label className="small mb-1 d-block">Created Date</label>
												<h6>{momentDateTime(this.props.rulesJson.created_at)}</h6>
											</div>
											<div className="col-md-6">
												<label className="small mb-1 d-block">Created By</label>
												<h6>{this.props.rulesJson.created_by}</h6>
											</div>
											{this.props.rulesJson.last_modified && this.props.rulesJson.last_modified !== '' ?
												<React.Fragment>
												<div className="col-md-6">
													<label className="small mb-1 d-block">Modified Date</label>
													<h6>{momentDateTime(this.props.rulesJson.last_modified)}</h6>
												</div>
												<div className="col-md-6">
													<label className="small mb-1 d-block">Modified By</label>
													<h6>{this.props.rulesJson.modified_by}</h6>
												</div>
												</React.Fragment>
											: null}
											</React.Fragment>
										: null
										}
									</div>
								</div>
							</div>
											

							<div className='mt-3'>
								<div className="bg-light-50 p-3 rounded">
									<h6 className="text-primary">Resource</h6>
									{this.props.rulesJson.resources && this.props.rulesJson.resources.length ?
										this.props.rulesJson.resources.map((item, index) => {
											return (
											<div className={`row ${this.props.rulesJson.resources.length === (index + 1) ? '' : 'border-bottom pb-2 mb-2'}`}>
												<div className="col-sm-3">
													<label className="small mb-0 d-block">Account</label>
													<div className="d-flex">
														{item.account.length ? 
															item.account.map((acc, i) => {
																return (
																	<small className="badge badge-outline-secondary">{getAccountNameFromId(acc, this.state.accounts)}</small>
																)
															})
														:
															<small className="badge badge-outline-secondary">All</small>		
														}
													</div>
												</div>
												<div className="col-sm-4">
													<label className="small mb-0 d-block"><i className={`far fa-location mr-1 align-self-center`}></i>Region</label>
													{item.region && item.region.length ? 
														item.region.map((reg, i) => {
															return(
																<small className={`badge badge-outline-secondary mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</small>
															)
														})
													:
														<small className={`badge badge-outline-secondary mb-1 mr-1`}>All</small>
													}
												</div>
												<div className="col-sm-5">
													<label className="small mb-0 d-block"><i className={`far fa-tag mr-1 align-self-center`}></i>Tags</label>
													{item.tags && item.tags.length ? 
														item.tags.map((tag, i) => {
															return (
																<small key={i} className="badge badge-outline-secondary mr-1">{tag.key +' : '+tag.value}</small>
															)
														})
													:
														<small className={`badge badge-outline-secondary mb-1 mr-1`}>All</small>
													}
												</div>
											</div>
											)
										})
									: null }
								</div>
							</div>

							<div className="mt-3">
								<div className="bg-light-50 p-3 rounded">
									<h6 className="text-primary">Condition</h6>
									<div className="row">
										<div className="col-sm-12">
										{this.props.rulesJson.condition && Object.entries(this.props.rulesJson.condition).length ?
											this.props.rulesJson.category === 'Service-BlockStore' ?
												Object.entries(this.state.allowedVolumeType).map(([key,value],index) => {
													if(key === 'Volume Size') {
														return(
															<React.Fragment>
															<label className="small"><b>{key}</b></label>
															<div className="table-responsive rounded">
																<table className="table table-sm">
																	<thead className="bg-secondary-50">
																		<tr>
																			<th>Volume</th>
																			<th>Total volume</th>
																			<th>Total Size of volume</th>
																			<th>Max Size per volume</th>
																		</tr>
																	</thead>
																	<tbody>
																		{value.map(vType => {
																			return (
																			<tr>
																				<td>{vType.volumeName}</td>
																				<td>{vType.totalVolumes}</td>
																				<td>{vType.totalSizeVolumes+vType.totalSizeVolumesUnit}</td>
																				<td>{vType.maxSizePerVolume+vType.maxSizePerVolumeUnit}</td>
																			</tr>
																			)
																		})}
																	</tbody>
																</table>
															</div>
															</React.Fragment>
														)
													} else {
														return(
															<React.Fragment>
															<label className="small"><b>{key}</b></label>
															<div className="table-responsive rounded">
																<table className="table table-sm">
																	<thead className="bg-secondary-50">
																		<tr>
																			<th>Volume</th>
																			<th>Total PIOPS</th>
																			<th>Max PIOPS per volume</th>
																		</tr>
																	</thead>
																	<tbody>
																		{value.map(vType => {
																			return (
																			<tr>
																				<td>{vType.volumeName}</td>
																				<td>{vType.totalPIOPS}</td>
																				<td>{vType.maxPIOPSPerVolume}</td>
																				<td>&nbsp;</td>
																			</tr>
																			)
																		})}
																	</tbody>
																</table>
															</div>
															</React.Fragment>
														)
													}	 
												})
											: 
											this.props.rulesJson.category === 'Service-Containers' ?
												this.props.rulesJson.condition.allowedContainers && this.props.rulesJson.condition.allowedContainers.length ?
													this.props.rulesJson.condition.allowedContainers.map((item, index) => {
														return(
														<div className="p-1 border-bottom mb-3">
															<div className="row">
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-1 d-block">Account</label>
																	{item.account.map((acc, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{acc}</small>																			)
																	})}
																</div>
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-1 d-block">Region</label>
																	{item.region && item.region.length ?
																		item.region.map((reg, i) => {
																			return(
																			<React.Fragment>
																				<div className="d-flex">
																					<i className={`far fa-location mr-1 align-self-center`}></i>
																					<small className={`badge badge-outline-secondary mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</small>
																				</div>
																			</React.Fragment>
																			)
																		})
																	:
																		<React.Fragment>
																			<i className={`far fa-location mr-1 align-self-center`}></i>
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>All</small>
																		</React.Fragment>
																	}
																</div>
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-1 d-block">Repository Image</label>
																	{item.repository && item.repository.length ?
																		item.repository.map((rep, i) => {
																			return(
																				<small className={`badge badge-outline-secondary mb-1 mr-1`}>{rep}</small>
																			)
																		})
																	:
																		<small className={`badge badge-outline-secondary mb-1 mr-1`}>All</small>
																	}
																</div>
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-1 d-block">Duration</label>
																	<p className="mb-0"><i className="far fa-clock mr-1"></i>{item.duration}</p>
																</div>
																
																<div className="col-lg-2 col-sm-3">
																	<p className="small m-0 p-0">Latest Image Version</p>
																	<small className={`mb-1 badge ${item.latestImageVersion ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.latestImageVersion ? 'Yes' : 'No'}</small>
																	<p className="small mb-0">Scanned Containers</p>
																	<small className={`mb-1 badge ${item.onlyScannedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyScannedContainers ? 'Yes' : 'No'}</small>
																</div>
																<div className="col-lg-2 col-sm-3">
																	<p className="small m-0 p-0">Encrypted Containers</p>
																	<small className={`badge ${item.onlyEncryptedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyEncryptedContainers ? 'Yes' : 'No'}</small>
																	<p className="small m-0 p-0">KMS Encrypted Containers</p>
																	<small className={`mb-1 badge ${item.onlyKMSEncryptedContainers ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.onlyKMSEncryptedContainers ? 'Yes' : 'No'}</small>
																</div>
															</div>
														</div>
														)
													})
												: null
											: 
											this.props.rulesJson.category === 'Service-Logs' ?							
												<div className="table table-responsive mb-0">
													<table className="table table-borderless">
														<thead>
															<tr className="text-purple font-weight-bold">
																<th style={{"width": "200px;"}}>Log Type</th>
																<th>Condition</th>
																<th>Retention Period</th>
																<th>Encryption</th>
																<th>Log Limit</th>
																<th className="text-nowrap">Log in Cross Account</th>
															</tr>
														</thead>
														<tbody className="align-middle">
															{Object.entries(this.props.rulesJson.condition.log_list).map(([key,value],index) => {
																return(
																	value.allowed ?
																		<tr>
																			<td className="bg-light text-right">{key}</td>
																			<td>{value.destination}</td>
																			<td>{value.retentionPeriod}</td>
																			<td>{value.encryption}</td>
																			<td>{value.logLimit+value.logLimitType}</td>
																			<td>{value.crossAccount ? 'Yes' : 'No'}</td>
																		</tr>
																	: null
																)
															})}
														</tbody>
													</table>
												</div>
											:  
											this.props.rulesJson.category === 'Service-Sizing' && this.props.rulesJson.resource_type === 'EC2' ?
												this.state.decisionArray.length ? this.state.decisionArray.map((item, index) => {
													return (
														item.parent.map(instance => {
															return(
															<React.Fragment>
																<div className="d-flex my-3">
																	<h6 className="mb-0 align-self-center">{instance.family}</h6>
																</div>
																{instance.series.map((srs, srsIndex) => {
																	return(
																		<div className="table-responsive rounded border mb-3">
																			<table className="table table-sm mb-0 f11">
																				<thead className="bg-gray3">
																					<tr>
																						<th className="text-nowrap">Instance Type</th>
																						<th className="text-nowrap">Max Count</th>
																						<th>Pricing</th>
																						<th className="f12">
																							Infra Specification
																							<span className='badge bg-purple text-white ml-2 mr-2'>{instance.family +': '+ srs.label}</span>
																							<span className='badge badge-secondary text-white'>Total Instance Count: {this.getInstanceCount(srs.label)} 
																								{/* <i className="fa fa-times-circle"></i> */}
																							</span>
																						</th>
																						<th className="f12 w-15">Description</th>
																					</tr>
																				</thead>
																				<tbody style={{"background-color": "#FAFAFA"}}>
																					{srs.types.map((typ, typIndex) => {
																						return(
																						<tr>
																							<td>
																								<p className="mb-0 f11"><b>{typ.type}</b></p>
																							</td>
																							<td>
																								<p className="mb-0 f11"><b>{typ.count !== '' && typ.count > 0 ? typ.count : 'No Limit'}</b></p>
																							</td>
																							<td>
																								<h6 className="mb-0 f11">$100.20</h6>
																							</td>
																							<td>
																								<div className="container-fluid pl-md-0">
																									<div className="row">
																										{typ.vCPU ?
																											<div className="col-md-3"><p className="mb-1 f11"><b>VCPU:</b> {typ.vCPU}</p></div>
																										: null}
																										{typ.memory ?
																											<div className="col-md-3"><p className="mb-1 f11"><b>Memory:</b> {typ.memory}</p></div>
																										: null}
																										{typ.ebsOptimized ?
																											<div className="col-md-3"><p className="mb-1 f11"><b>EBS Optimized:</b> {typ.ebsOptimized === 'Y' ? 'Yes' : 'No'}</p></div>
																										: null}
																										{typ.storageType ?
																											<div className="col-md-3"><p className="mb-1 f11"><b>Storage Type:</b> {typ.storageType}</p></div>
																										: null}
																										{typ.storageDetails ?
																											<div className="col-md-3"><p className="mb-1 f11"><b>Storage Details:</b> {typ.storageDetails}</p></div>
																										: null}

																										{typ.networkingPerformance ?
																											<div className="col-md-4"><p className="mb-1 f11"><b>NW Performance:</b> {typ.networkingPerformance+ (typ.enhancedNetworking === 'Y' ? '(Enhanced Networking)' : '')}</p></div>
																										: null}
																										{typ.cpuProcessor ?
																											<div className="col-md-5"><p className="mb-1 f11"><b>CPU Processor:</b> {typ.cpuProcessor +' '+typ.architecture}</p></div>
																										: null}
																									</div>
																								</div>
																							</td>
																							<td class="UncontrolledTooltipGovernance">
																								<p className="mb-0 f11" id={`description-${index}-${srsIndex}-${typIndex}`}><b>{typ.description && typ.description !== "" ? stringLength(typ.description, 100) : ""}</b></p>
																								{typ.description && typ.description !== "" ?
																									<UncontrolledTooltip placement='top' target={`description-${index}-${srsIndex}-${typIndex}`}>{typ.description}</UncontrolledTooltip>
																								: null}
																							</td>
																						</tr>
																						)
																					})}
																				</tbody>
																			</table>
																		</div>
																	)
																})}
															</React.Fragment>
															)
														})
													)
													})
												: null
											:
											this.props.rulesJson.category === 'Service-Images' ?
												this.props.rulesJson.condition && Object.entries(this.props.rulesJson.condition).length ?
													<React.Fragment>
													<div className="d-flex align-self-center mt-2 mb-2">
														<div className="d-flex mr-2">
															<p className="small m-0 p-0 mr-2">Allowed Community Images</p>
															<small className={`badge ${this.props.rulesJson.condition && this.props.rulesJson.condition.generalSettings && this.props.rulesJson.condition.generalSettings.allowedCommunityImages ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition && this.props.rulesJson.condition.generalSettings && this.props.rulesJson.condition.generalSettings.allowedCommunityImages ? 'Yes' : 'No'}</small>
														</div>
														<div className="d-flex">
															<p className="small m-0 p-0 mr-2">Allowed Marketplace Images</p>
															<small className={`badge ${this.props.rulesJson.condition && this.props.rulesJson.condition.generalSettings && this.props.rulesJson.condition.generalSettings.allowedMarketplaceImages ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition && this.props.rulesJson.condition.generalSettings && this.props.rulesJson.condition.generalSettings.allowedMarketplaceImages ? 'Yes' : 'No'}</small>
														</div>
													</div>
													{this.props.rulesJson.condition.allowedImages && this.props.rulesJson.condition.allowedImages.map(item => {
														return(
														<div className="p-3 mb-3">
															<div className="row">
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-0 d-block">Account</label>
																	{item.account.split(":").map((acc, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{getAccountNameFromId(acc,this.state.accounts) !== '-' ? getAccountNameFromId(acc,this.state.accounts) : acc}</small>
																		)
																	})}
																	<p class="mb-2"><i class="far fa-clock"></i> {item.duration}</p>
																</div>
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-0 d-block">
																		<i className={`far fa-location mr-1 align-self-center`}></i>Region
																	</label>
																	{item.regions.map((reg, i) => {
																		return(
																			<React.Fragment>
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</small>
																			</React.Fragment>
																		)
																	})}
																</div>													
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-0 d-block">Allowed Platforms</label>
																	{item.allowedPlatforms && item.allowedPlatforms.length ?
																		item.allowedPlatforms.map((rep, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mr-1 mb-1`}>{rep}</small>
																		)
																	})
																	:
																		<small className={`badge badge-outline-secondary mr-1 mb-1`}>All</small>
																	}
																</div>
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-0 d-block">Allowed AMI</label>
																	{item.allowedAmi && item.allowedAmi.length ?
																		item.allowedAmi.map((rep, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mr-1 mb-1`}>{rep}</small>
																		)
																	})
																	:
																		<small className={`badge badge-outline-secondary mr-1 mb-1`}>All</small>
																	}
																</div>
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-0 d-block">Allowed Image Architecture</label>
																	{item.imageArchitecture && item.imageArchitecture.length ?
																		item.imageArchitecture.map((rep, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mr-1 mb-1`}>{rep}</small>
																		)
																	})
																	:
																		<small className={`badge badge-outline-secondary mr-1 mb-1`}>All</small>
																	}
																</div>
																<div className="col-lg-2 col-sm-3 align-self-center">
																	<label className="small mb-0 d-block">Allowed Root Device Type</label>
																	{item.allowedRootDevice && item.allowedRootDevice.length ?
																		item.allowedRootDevice.map((rep, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mr-1 mb-1`}>{rep}</small>
																		)
																	})
																	:
																		<small className={`badge badge-outline-secondary mr-1 mb-1`}>All</small>
																	}
																</div>
															</div>
														</div>
														)
													})}
													</React.Fragment>
												: null

											: 
											this.props.rulesJson.category === 'Service-Engine' && this.props.rulesJson.resource_type === 'RDS'  ?
												this.props.rulesJson.condition.allowedEngineDetails && this.props.rulesJson.condition.allowedEngineDetails.length ?
													this.props.rulesJson.condition.allowedEngineDetails.map((item, index) => {
														return(
															<div className={`p-3 mb-3 ${this.props.rulesJson.condition.allowedEngineDetails.length > 1 ? 'border-bottom' : ''}`}>
																<div className="row">
																	<div className="col-lg-3 col-sm-3 align-self-center">
																		<label className="small mb-0 d-block">Options</label>
																		<small className={`badge badge-outline-secondary mb-1 mr-1`}>{item.options}</small>
																	</div>
																	<div className="col-lg-3 col-sm-3 align-self-center">
																		<label className="small mb-0 d-block">Allowed only latest Version</label>
																		<small className={`badge ${item.latestVersion ? 'badge-outline-success' : 'badge-outline-danger'}`}>{item.latestVersion ? 'Yes' : 'No'}</small>
																	</div>
																	<div className="col-lg-6 col-sm-6 align-self-center">
																		<label className="small mb-0 d-block">DB Engine Version</label>
																		{item.optionType && item.optionType.length ? 
																			item.optionType.map((type, i) => {
																				return(
																					<small className={`badge badge-outline-secondary mb-1 mr-1`}>{type}</small>
																				)
																			})
																		: 
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{item.latestVersionDetail}</small>
																		}
																	</div>
																</div>
															</div>
														)
													})
												: null
											:  
											this.props.rulesJson.category === 'Service-Availability' ?
												this.props.rulesJson.condition && Object.entries(this.props.rulesJson.condition).length ?
													<div className="p-3 mb-3">
														<div className="row">
															<div className="serviceAvailWidth1 align-self-center">
																<label className="small mb-0 d-block">Allow Replication</label>
																<small className={`badge ${this.props.rulesJson.condition.allowReplication ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.allowReplication ? 'Yes' : 'No'}</small>
															</div>
															<div className="serviceAvailWidth1 align-self-center">
																<label className="small mb-0 d-block">Allowed Standby</label>
																<small className={`badge ${this.props.rulesJson.condition.allowStandby ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.allowStandby ? 'Yes' : 'No'}</small>
															</div>
															
															<div className="serviceAvailWidth2 align-self-center">
																<label className="small mb-0 d-block">Encrypt Replicas at rest</label>
																<small className={`badge ${this.props.rulesJson.condition.encryptReplicasAtRest ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.encryptReplicasAtRest ? 'Yes' : 'No'}</small>
															</div>
														</div>
														<div className="row mt-2">
															<div className="serviceAvailWidth1 align-self-center">
																<label className="small mb-0 d-block">Max. number of replicas allowed per Cluster</label>
																<p className="mb-0">{this.props.rulesJson.condition.maxReplicationPerCluster && this.props.rulesJson.condition.maxReplicationPerCluster !== '' ? this.props.rulesJson.condition.maxReplicationPerCluster : <span>&nbsp;</span> }</p>
															</div>
															<div className="serviceAvailWidth1 align-self-center">
																<label className="small mb-0 d-block">Allow Cross Zone Replication</label>
																<small className={`badge ${this.props.rulesJson.condition.allowCrossZoneReplication ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.allowCrossZoneReplication ? 'Yes' : 'No'}</small>
															</div>
															<div className="serviceAvailWidth2 align-self-center">
																<label className="small mb-0 d-block">Encrypt Replicas at transit</label>
																<small className={`badge ${this.props.rulesJson.condition.encryptReplicasAtTransit ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.encryptReplicasAtTransit ? 'Yes' : 'No'}</small>
															</div>
														</div>																		
														<div className="row mt-2">
															<div className="serviceAvailWidth1 align-self-center">
																<label className="small mb-0 d-block">Allow Cross Region Replication</label>
																<small className={`badge ${this.props.rulesJson.condition.allowCrossRegionReplication ? 	'badge-outline-success' : 'badge-outline-danger'}`}>
																	{this.props.rulesJson.condition.allowCrossRegionReplication ? 'Yes' : 'No'}
																</small>
															</div>
															<div className="ml-3 align-self-center">
																<label className="small mb-0 d-block"><i className={`far fa-location mr-1 align-self-center`}></i>Region</label>
																{this.props.rulesJson.condition.allowCrossRegionOption.length ? 
																	this.props.rulesJson.condition.allowCrossRegionOption.map((reg, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{getRegionName(reg, this.state.regions)}</small>
																		)
																	})
																: null
																}
															</div>
														</div>
														<div className="row mt-2">
															<div className="serviceAvailWidth1 align-self-center">
																<label className="small mb-0 d-block">Allow Cross Account</label>
																<small className={`badge ${this.props.rulesJson.condition.allowCrossAccountReplication ? 	'badge-outline-success' : 'badge-outline-danger'}`}>
																	{this.props.rulesJson.condition.allowCrossAccountReplication ? 'Yes' : 'No'}
																</small>
															</div>
															<div className="ml-3 align-self-center">
																<label className="small mb-0 d-block">Account</label>
																{this.props.rulesJson.condition.allowCrossAccountOption.length ? this.props.rulesJson.condition.allowCrossAccountOption.map((reg, i) => {
																	return(
																		<small className={`badge badge-outline-secondary mb-1 mr-1`}>{getAccountNameFromId(reg, this.state.accounts)}</small>
																	)
																})
																: null
																}
															</div>
														</div>
													</div>
												: null
											: 
											this.props.rulesJson.category === 'Service-Encryption' ?
												this.props.rulesJson.condition && Object.entries(this.props.rulesJson.condition).length ?
													<div className="p-3 mb-3">
														<div className="row col-sm-12">
															<div className="align-self-center mr-4 mb-2">
																<label className="small mb-0 d-block">Enforce Encryption at Rest</label>
																<small className={`badge ${this.props.rulesJson.condition.encryptionAtRest ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.encryptionAtRest ? 'Yes' : 'No'}</small>
															</div>
															<div className="align-self-center mr-4 mb-2">
																<label className="small mb-0 d-block">Enforce Encryption at Transit</label>
																<small className={`badge ${this.props.rulesJson.condition.encryptionAtTransit ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.encryptionAtTransit ? 'Yes' : 'No'}</small>
															</div>
															<div className="align-self-center mb-2">
																<label className="small mb-0 d-block">Encryption Options</label>
																{this.props.rulesJson.condition.encryptOptions.length ?
																	this.props.rulesJson.condition.encryptOptions.map((reg, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{reg}</small>
																		)
																	})
																: 
																	<small className={`badge badge-outline-secondary mb-1 mr-1`}>All</small>
																}
															</div>
														</div>
														<div className="row mt-3 col-sm-12">
															<label className="w-100 small"><b>KMS Settings</b></label>
															<div className="align-self-center mr-4 mb-2">
																<label className="small mb-0 d-block">Allow Cross account Access for Customer Managment KMS</label>
																<small className={`badge ${this.props.rulesJson.condition.crossAccountAccessforCusmtomerMgmtKMS ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.crossAccountAccessforCusmtomerMgmtKMS ? 'Yes' : 'No'}</small>
															</div>
															<div className="lign-self-center mr-4 mb-2">
																<label className="small mb-0 d-block">Allow Cross account KMS for Encryption</label>
																<small className={`badge ${this.props.rulesJson.condition.crossAccountKMSEncryption ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.condition.crossAccountKMSEncryption ? 'Yes' : 'No'}</small>
															</div>
															<div className="lign-self-center mb-2">
																<label className="small mb-0 d-block">Max. Key Administrators in Customer Managed KMS</label>
																<p className="mb-0">{this.props.rulesJson.condition.customerManagedKMS}</p>
															</div>
														</div>
														<div className="row mt-3 col-sm-12">
															<label className="w-100 small"><b>Advanced Settings</b></label>
															<div className="align-self-center mr-4 mb-2">
																<label className="small mb-0 d-block">Allowed Key Types</label>
																{this.props.rulesJson.condition.keyTypeOptions.length ? 
																	this.props.rulesJson.condition.keyTypeOptions.map((reg, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{reg}</small>
																		)
																	})
																: 
																	<small className={`badge badge-outline-secondary mb-1 mr-1`}>All</small>
																}
															</div>
															<div className="align-self-center mr-4 mb-2">
																<label className="small mb-0 d-block">Allowed Key usage</label>
																{this.props.rulesJson.condition.keyUsageOptions.length ? 
																	this.props.rulesJson.condition.keyUsageOptions.map((reg, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{reg}</small>
																		)
																	})
																: 
																	<small className={`badge badge-outline-secondary mb-1 mr-1`}>All</small>
																}
															</div>
															<div className="align-self-center mb-2">
																<label className="small mb-0 d-block">Key Spec</label>
																{this.props.rulesJson.condition.keySpecOptions.length ?
																	this.props.rulesJson.condition.keySpecOptions.map((reg, i) => {
																		return(
																			<small className={`badge badge-outline-secondary mb-1 mr-1`}>{reg}</small>
																		)
																	})
																:
																	<small className={`badge badge-outline-secondary mb-1 mr-1`}>All</small>
																}
															</div>
														</div>
													</div>
												: null
											: null
										: null
										}
										</div>
									</div>
								</div>
							</div>
							
							<div className='mt-3'>
								<div className="bg-light-50 p-3 rounded">
									<h6 className="text-primary">Action</h6>
									<div className="row">
										<div className="col-sm-6">
											<p className="small mb-1 d-block">Send Alert Message</p>
											<small className={`badge ${this.props.rulesJson.alertAction ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.alertAction ? 'Yes' : 'No'}</small>
										</div>
										<div className="col-sm-6">
											<p className="small mb-1 d-block">Terminate the Instance</p>
											<small className={`badge ${this.props.rulesJson.alertAction && this.props.rulesJson.terminateInstance ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.alertAction && this.props.rulesJson.terminateInstance ? 'Yes' : 'No'}</small>
										</div>
										<div className={`col-sm-12 ${this.props.rulesJson.alertAction && this.props.rulesJson.alertAction.alert ? '' :'displayNone'}`}>
											<label className="small mb-1 d-block">Alert Message </label>
											<h6 className="mb-0">{this.props.rulesJson.alertAction && this.props.rulesJson.alertAction.message}</h6>
										</div>
									</div>
								</div>
							</div>

							<div className="mt-3">
								<div className="bg-light-50 p-3 rounded">
									<h6 className="text-primary">Notification</h6>
									<div className="row">
										<div className="col-sm-3">
											<label className="small mb-1 d-block">Send notification to email</label>
											<small className={`badge ${this.props.rulesJson.notifications && this.props.rulesJson.notifications.emailChecked ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.notifications && this.props.rulesJson.notifications.emailChecked ? 'Yes' : 'No'}</small>
										</div>
										<div className="col-sm-3">
											<label className="small mb-1 d-block">Email ID</label>
											<h6 className="mb-0">{this.props.rulesJson.notifications && this.props.rulesJson.notifications.email}</h6>
										</div>
										<div className="col-sm-3">
											<label className="small mb-1 d-block">Send notification to slack(channel)</label>
											<small className={`badge ${this.props.rulesJson.notifications && this.props.rulesJson.notifications.slackChecked ? 'badge-outline-success' : 'badge-outline-danger'}`}>{this.props.rulesJson.notifications && this.props.rulesJson.notifications.slackChecked ? 'Yes' : 'No'}</small>
										</div>
										<div className="col-sm-3">
											<label className="small mb-1 d-block">Slack ID</label>
											<h6 className="mb-0">{this.props.rulesJson.notifications && this.props.rulesJson.notifications.slack}</h6>
										</div>
									</div>
								</div>
							</div>					
						</div>
					:
						null
					}
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
DeleteSidePanel.propTypes = {
	//getPrivileageAnalysis:PropTypes.func
	listAllRegions: PropTypes.func,
	listAllAccounts: PropTypes.func,
	deleteUserRule: PropTypes.func,
	location: PropTypes.object,
	editRuleDetails: PropTypes.object,
}

const mapStateToProps = state => {
	// console.log('mapshidepanel',state)
	return {
		editRuleDetails: state.governance.editRuleDetails,
		regions: state.filters.regions && state.filters.regions[0] ? state.filters.regions[0].provider_regions : [],
		accounts: state.filters.accounts,
	}
}

export default connect(mapStateToProps, {listAllRegions, listAllAccounts, deleteUserRule})(withRouter(DeleteSidePanel))



