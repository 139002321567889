import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
	getAllTags
} from '../../actions/commonAction'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

class TagFilterSection extends React.Component {
    tagRef = React.createRef()
    tagkeyRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            selectedProvider: this.props.selectedProvider,
            selectedAccount: this.props.selectedAccount,
            selectedRegion: this.props.selectedRegion,
            selectedTags: this.props.selectedTags,                        
            tagKeysArray: [],
            tagSubArray: [],
            rowList: [true]
        };
    };

    componentDidMount = () => {
        this.getAllTags()
        if(this.props.selectedTags.length) {
            let rowList = []
            this.props.selectedTags.forEach((tag, index) => {
                rowList.push(true)
                this.setState({ ['key_'+index]: tag.key })
                this.setState({ ['selectedFilter_'+index]: tag.value })
            })

            this.setState({ rowList })
        } 
    }    
    
    getAllTags = () => {
		this.setState({ tagKeysArray:[], tagSubArray: []})
		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		//params.duration = this.state.selectedDuration
		if(this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
			params.region = this.state.selectedRegion
		}
		if(this.state.selectedAccount.length) {
			params.account_id = this.state.selectedAccount
		}
		//params.aggregate_by = ['account_id', 'region']
		//let searchInputs = ['account_id', 'region']
		//console.log('tags', params)
		this.props.getAllTags(params, (promise, tags) => {
			//console.log('tags', tags)
			let tagSubArray = []
			let tagsKeyArray = []
			if (promise) {
				let tagArray = []
				tags.forEach(tag => {
					tagArray.push(tag.tags)
                })
                
				// let result = _.chain(tagArray)
                _.chain(tagArray)
				.groupBy('key')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					tagsKeyArray.push(service)
					service.sub.forEach((item, i) => {
						tagSubArray.push(item)
					})
				})

				this.setState({ tagKeysArray: _.orderBy(tagsKeyArray, ['label'], ['asc']), tagSubArray }, () => this.internalLoadFunction())
			} else {
				this.setState({ tagKeysArray:[], tagSubArray: [] })
			}
		})
    }
    
    internalLoadFunction = () => {
        if(this.props.selectedTags.length) {
            this.props.selectedTags.forEach((tag, index) => {
                let filterData = this.state.tagSubArray
                if(this.state['key_'+index] !== '') {
                    filterData = filterData.filter(tag => tag.key === this.state['key_'+index])
                }
                this.setState({ ['filter_'+index]: filterData})
            })            
        }
    }

    applyFilter = () => {
        let tag = []
        let totalTagSelected = this.state.rowList.length
        for(let i = 0; i< this.state.rowList.length; i++) {
            let key = this.state['key_'+i]
            let dataRow = {}
            dataRow.key = key
            
            if(this.state['selectedFilter_'+i] && this.state['selectedFilter_'+i].length) {
                let value = this.state['selectedFilter_'+i]
                dataRow.value = value
            }

            tag.push(dataRow)
        }
        
        return this.props.data(tag, totalTagSelected)
    }

    closeFilter = () => {
        return this.props.data(false);
    }

    remove = (type, row, index) => {
        let valueArray = this.state['selectedFilter_'+row]; 
        valueArray.splice(index, 1);
        this.setState({ ['selectedFilter_'+row] : valueArray });
    }

    addRow = () => {
        let rowList = [...this.state.rowList];
        rowList.push(true);
        this.setState({ rowList });
    }

    removeRow = (i) => {
        let rowList = [...this.state.rowList];
        rowList.splice(i, 1);
        this.setState({ rowList });
        this.setState({ ['key_'+i] : undefined, ['filter_'+i] : undefined, ['selectedFilter_'+i] : undefined,  });
    }

    handleMultiSelectChange = (key, childKey, arrayValue) => {
        let value = arrayValue.map(item => item.value)
        //let label = arrayValue.map(item => item)
        if(childKey === 'selectedTagValue') {
			this.setState({ ['selectedFilter_'+key]: value })
		}
        
        // else if(childKey === 'selectedRegion') {
		// 	if(value !== '' && (value.includes("All") || this.state.selectedRegion.includes("All"))) {
		// 		alert(1)
		// 		value = ['All']
		// 		label = {}
		// 		label.value = 'All'
		// 		label.label = 'All'
		// 		this.setState({ selectedRegion: ['All'], selectedRegionName: [label] },
		// 			() => this.getAllTags()
		// 		)
		// 	} else {
		// 		this.setState({ selectedRegion: value, selectedRegionName: label},
		// 			() => this.getAllTags()
		// 		)

		// 	}
		// }
    }
    
    getTagValue = (value, index) => {
        this.setState({ ['key_'+index]: value, ['filter_'+index]: [], ['selectedFilter_'+index]: [] }, 
            () => this.getTagInternalFucntion(index)            
        )

    }
    
    getTagInternalFucntion = (index) => {
        let filterData = this.state.tagSubArray
        if(this.state['key_'+index] !== '') {
            filterData = filterData.filter(tag => tag.key === this.state['key_'+index])
        }
		this.setState({ ['filter_'+index]: filterData})
    }

    getMultiSelectedCount = (type, array) => {
		return array.length && array.filter(e => e.toLowerCase() === 'all').length ? 'All' :  array.length ? array.length +' Selected' : 'All'
	}

    render() {
        return (
            <div className="card cardDropSection tagFilter">
                <div className="col-sm-12 m-0 p-0 d-flex mt-2">
                    <div className="col-sm-6 m-0 p-0">
                        <p className="text-white col-sm-12 f14 pb-0 mb-0">Lorem ipsum text industry since 1500s
                        </p>
                    </div>
                    <div className="col-sm-6 text-right">
                        <i 
                            className="fa fa-plus addRow cursorPointer" 
                            onClick={() => this.addRow()}>
                        </i>
                    </div>
                </div>

                <div className="card-body">
                    {this.state.rowList.map((x, i) => {
                    return(
                        <div className="d-flex mb-2" key={i}>
                            <div className={`mr-3 mt-3`}>
                                <i class={`fa fa-minus removeRow cursorPointer ${i===0 ? 'disabled' : ''} `} onClick={() => this.removeRow(i)}></i>
                            </div>
                            <div
                                className={`form-group mb-sm-0 styled-feild mr-sm-3 tagDropBg minWidth150 transparentSelect align-self-start`} ref={this.tagRef}>
                                <label className="mb-1">Tags Key</label>
                                <Select
                                    placeholder={'Select'}
                                    // menuIsOpen={true}
                                    className='form-control p-0 colorBlack'
                                    value={({
                                        value: this.state['key_'+i],
                                        label: this.state['key_'+i] ? this.state['key_'+i] : <span className="placeholder">Select</span>,
                                    })}
                                    options={this.state.tagKeysArray.map(tag => ({
                                        value: tag.label,
                                        label: tag.label,
                                    }))}
                                    onChange={event => this.getTagValue(event.value, i)}
                                />
                            </div>
                            {this.state['filter_'+i] && this.state['filter_'+i].length ?
                                <div
                                    className={`form-group mb-sm-0 styled-feild mr-sm-3 tagDropBg minWidth150 align-self-start`}  ref={this.tagkeyRef}>
                                    <label className="mb-1">Tags Value</label>
                                    <div className={`transparentMultiSelect ${this.state['filter_'+i].length > 10 ? '' : 'removeDropdownSearchBar'} `}>
                                        <ReactMultiSelectCheckboxes						
                                            placeholderButtonLabel="All"
                                            getDropdownButtonLabel={() => this.getMultiSelectedCount('tagValue_'+i, this.state['selectedFilter_'+i])}
                                            onChange={arr => {
                                            this.handleMultiSelectChange(i, 'selectedTagValue', arr ? arr : []) }}
                                            options={this.state['filter_'+i].map(tag => ({
                                                value: tag.value,
                                                label: tag.value,
                                            }))}
                                        />
                                    </div>
                                </div>
                            : null
                            }
                            {console.log('selectedFilter_'+i, this.state['selectedFilter_'+i])}
                            <div className="mr-3 minWidth300 align-self-center text-left">
                                {this.state['selectedFilter_'+i] && this.state['selectedFilter_'+i].length ? 
                                    this.state['selectedFilter_'+i] && this.state['selectedFilter_'+i].map((item, index) => {
                                        return (
                                            index < 3 ?
                                                <span className='badge badge-primary custom-badge ml-2 p-1 text-white mb-1'> {item}
                                                    <i className='ml-1 fal fa-times f14 cursorPointer text-dark font-weight-bold' onClick={() => this.remove('role', i, index)}></i>
                                                </span> 
                                            : null
                                        )
                                    })
                                :
                                    null
                                }
                            </div>
                            <div className="mr-3 align-self-center displayNone">{/* display none */}
                                {this.state['selectedFilter_'+i] && this.state['selectedFilter_'+i].length > 3 ?
                                    <p className="text-white col-sm-12 f10 pb-0 mb-0">  {this.state['selectedFilter_'+i] && this.state['selectedFilter_'+i].length+' Selected '}</p>
                                : null}
                            </div>
                        </div>
                    )
                    })}
                </div>
                <div className="card-footer">
                    <div className="col-sm-6 m-0 p-0 float-left">
                        &nbsp;
                    </div>
                    <div className="col-sm-6 m-0 p-0 float-right text-right">
                        <span className="dropCancelBtn p-2 mr-2"
                            onClick={ () => this.closeFilter()}
                        >
                            Cancel
                        </span>
                        <span className="dropApplyBtn p-2" 
                            onClick={ () => this.applyFilter()}
                        >
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

/**
 * Type of the props used in the component
 */
TagFilterSection.propTypes = {
    getAllTags:PropTypes.func
}

export default connect(null, { getAllTags })(TagFilterSection);

//export default connect(TagFilterSection, null, {getIdentitiesList})

//export default TagFilterSection;
