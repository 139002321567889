/*************************************************
 * Tvastar
 * @exports
 * @file AlertAction.js
 * @author parakash // on 16/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
	AIOPS_SERVICE,
	AIOPS_ALERTS_LIST_ALERT_GROUPS,
	AIOPS_ALERTS_GET_ALERT_GROUPS_DAILY_TREND,
	AIOPS_ALERTS_GET_ALERT_REDUCTION_STATS,

} from '../../config'

/**
 * Action to list cases
 * @param {Object} body
 * @param {Function} callback
 */
export const listAlertGroups = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, AIOPS_ALERTS_LIST_ALERT_GROUPS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get Alert Group Daily Trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getAlertGroupDailyTrend = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, AIOPS_ALERTS_GET_ALERT_GROUPS_DAILY_TREND, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}


/**
 * Action to get Alert Reductaion Status
 * @param {Object} body
 * @param {Function} callback
 */
 export const getAlertReductaionStatus = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, AIOPS_ALERTS_GET_ALERT_REDUCTION_STATS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}