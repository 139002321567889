/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ErrorsDashboard.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { listAllProviders, listAllAccounts, listAllRegions } from '../../../../actions/commonAction'
import { getDailyCount, listErrorResults } from '../../../../actions/aiops/ErrorAction'
import { getMonitoringMetricsByTrend } from '../../../../actions/aiops/MetricsAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'
import RightSection from '../RightSection'

class ErrorsDashboard extends Component {
    constructor(props) {
        super(props)
        this.toggleRef = React.createRef()
        
        this.state = {
            showLoading: true,
            
            jumpDate: '',
            errorDetails: {},
            showAnnotation: true,

            startRecord: 0, 
			currentPage: 1,
			totalPages: 1,
            overAllTotalPages: 1,
			perPage: 100,
            
            groupedBy: 'severity',
            selectedCategory: 'Important / All',
            pageType: "Errors",
            showRightSide: true
        }
    }

	componentDidMount = () => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            this.setState({ 
                start_time: this.props.aiopsCommonPageFilter.start_time,
                end_time: this.props.aiopsCommonPageFilter.end_time,
                brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                showRightSide: false,
                errorListLoader: true
            },
                () => {
                    this.setState({ showRightSide: true })
                    this.listErrorResults()
                }
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            if(!_.isEqual(prevProps.aiopsCommonPageFilter, this.props.aiopsCommonPageFilter)) {
                this.setState({ 
                    start_time: this.props.aiopsCommonPageFilter.start_time,
                    end_time: this.props.aiopsCommonPageFilter.end_time,
                    brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                    brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                    showRightSide: false,
                    errorListLoader: true,
                    nextErrorListToken: ""
                },
                    () => {
                        this.setState({ showRightSide: true })
                        this.listErrorResults()
                    }
                )
            }
        }
    }

    listErrorResults = () => {
        let params = {}
        params.start_time = momentConvertionLocalToUtcTime(this.state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
        params.end_time = momentConvertionLocalToUtcTime(this.state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
        params.aggregate_by = this.state.groupedBy
        if(this.state.nextErrorListToken && this.state.nextErrorListToken !== '') {
            params.next_token = this.state.nextErrorListToken
        }
        params.size = 1000
        this.props.listErrorResults(params, (promise, response) => {
            if(promise) {let startRecord = 0
                let currentPage = 1
                
                let results = response.results && response.results.details ? response.results.details : []
        
                let totalPages = 1
                if(results.length > this.state.perPage) {
                    totalPages = Math.ceil(results.length / this.state.perPage)
                }

                let totalErrorList = [] 
                if(this.state.nextErrorListToken && this.state.nextErrorListToken !== '') {
                    totalErrorList = this.state.errorResponse ? this.state.errorResponse : []
                    startRecord = this.state.startRecord
                    currentPage = this.state.currentPage
                }
                
                if(results.length) {
                    totalErrorList = totalErrorList.concat(results)
                }

                this.setState({
                    errorResponse: totalErrorList,
                    filteredArray: totalErrorList,
                    errorListLoader: false,
                    nextErrorListToken: response.next_token ? response.next_token : '', 
                    totalPages,
                    startRecord,
                    currentPage 
                },
                    () => {
                        this.formHeaders()
                        this.structureErrors()    
                    }
                )
            } else {
                this.setState({ errorListLoader: false })
            }
        })        
    }

	formHeaders = () => {
		let dataResult = this.state.filteredArray && this.state.filteredArray.length ? this.state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
                console.log(key, typeof value)
				if(typeof value === "string" && key !== "event_data") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			this.setState({ headers })
		}
	}

    structureErrors = () => {
        let listAllErrors = []
        let totalCount = 0
        let errorsCategory = []
        if(this.state.errorResponse && this.state.errorResponse.length) {
            totalCount = this.state.errorResponse.reduce( function(a, b){ return a + b.count;}, 0);
            this.state.errorResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                errorsCategory.push(dataRow)
                item.events.forEach(alt => {
                    listAllErrors.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        errorsCategory.push(dataRow)

        this.setState({ listAllErrors, errorsList: listAllErrors, errorsCategory: _.orderBy(errorsCategory, ['orderBy'], ['asc'])},
            () => this.getCategoryErrors()
        )
    }
    
    getCategoryErrors = () => {
        let filterdCategoryErrors = this.state.listAllErrors
        if(this.state.selectedCategory !== 'Important / All') {
            filterdCategoryErrors = this.state.errorResponse.filter(arr => arr.label === this.state.selectedCategory)
            if(filterdCategoryErrors && filterdCategoryErrors.length) {
                filterdCategoryErrors = filterdCategoryErrors[0].events ? filterdCategoryErrors[0].events : []
            }
        }

        let orderedArray = _.orderBy(filterdCategoryErrors, ['event_time'], ['desc'])

        let totalPages = 1
		if(orderedArray.length > this.state.perPage) {
			totalPages = Math.ceil(orderedArray.length / this.state.perPage)
		}
        this.setState({ totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedError: {}, totalPages },
            () => {
                if(this.state.filteredArray.length) {
                    this.getErrorDetails(this.state.filteredArray[0])
                }
            }
        )
    }

    updateSelectedErrors = (eventId) => {
        let selectedError = this.state.selectedError ? this.state.selectedError : []
        if(selectedError.includes(eventId)) {
            selectedError = selectedError.filter(e => e !== eventId)
        } else {
            selectedError.push(eventId)
        }
        this.setState({ ['error_'+eventId]: !this.state['error_'+eventId], selectedError })
    }

    navigatePage = (action, currentPage) => {
        if(currentPage === this.state.totalPages && action === 'next') {
            this.setState({ errorListLoader: true, currentPage: currentPage + 1 },
                () => this.listErrorResults()                
            )
        } else if(currentPage > 0) {
            let startRecord = this.state.startRecord
            if(action === 'next' && currentPage !== this.state.totalPages) {
                startRecord = startRecord + this.state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - this.state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = this.state.totalPages
                startRecord = (this.state.totalPages - 1) * this.state.perPage
            }
            this.setState({ currentPage, startRecord })
        } 
	}

    gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= this.state.totalPages) {
            let startRecord = (pageNumber - 1) * this.state.perPage
            this.setState({ currentPage: pageNumber, startRecord })
        }
    }

    getErrorDetails = (selectedError) => {
        console.log("selectedError", selectedError)
        // let merticsAssetNameInvolved = []
        // if(selectedError.origin && selectedError.origin.asset_id) {
        //     merticsAssetNameInvolved.push(selectedError.origin.asset_id)
        // }
        // if(selectedError.raised_in && selectedError.raised_in.asset_id) {
        //     merticsAssetNameInvolved.push(selectedError.raised_in.asset_id)
        // }
        // if(!this.state.errorDetails.event_id || (this.state.errorDetails.event_id !== selectedError.event_id)) {
            let obj = {}
            obj.selectedTab = 'Details'
            obj.details = selectedError
            // obj.merticsAssetNameInvolved = merticsAssetNameInvolved
            obj.brushStartTime = this.state.brushStartTime
            obj.brushEndTime = this.state.brushEndTime
            obj.pageType = this.state.pageType
            this.props.setAiopsPropsDetails('tabOptions', obj)
            this.props.setAiopsPropsDetails('alertsPageMonitoringList', {})
            this.props.setAiopsPropsDetails('errorEventPageMonitoringList', {})
        // }

        this.setState({ errorDetails: selectedError })
    }

    // redirectToAnalysis = () => {
    //     let obj = {}
    //     obj.selectedTab = 'Analysis'
    //     obj.details = {}
    //     obj.merticsAssetNameInvolved = []
    //     obj.brushStartTime = this.state.brushStartTime
    //     obj.brushEndTime = this.state.brushEndTime
    //     // obj.pageType = this.state.selectedPageType
    //     this.props.setAiopsPropsDetails('tabOptions', obj)
    // }
    
    handleChange = (event) => {
        this.setState({ groupType: event.target.value, errorDetails: {}, errorListLoader: true },
            () => {
                this.listErrorResults()
                // this.redirectToAnalysis()
            }    
        )
    }

    handleClickOutside(event) {
        if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
            this.setState({ showPageTypeOptions: false })
        } else {
            this.setState({ showPageTypeOptions: true })
        }
    }

	render() {
		return (
            <div className="" onClick={(event) => this.handleClickOutside(event)}>
                <div className="w-100">
                    <div className={`d-flex mt-2 ml-1 ${this.state.errorListLoader ? 'disabled' : ''}`}>
                        <div className="select-sm-bg curvedDropdown minWidth120 mr-2" ref={this.toggleRef}>
                            <div className="dropdown">
                                <span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
                                    {this.state.pageType ? this.state.pageType : <span className="placeholder">Select</span>}
                                </span>
                                <ul className={`dropdown-menu p-0 ${this.state.showPageTypeOptions ? "show" : ''}`}>
                                    {this.props.pageTypes.map(item => {
                                        return (
                                            <li 
                                                onClick={() => this.setState({ 
                                                    pageType: item, 
                                                    showPageTypeOptions: false
                                                }, 
                                                    () => this.props.onChangePageType(item)
                                                )}
                                                className={`${this.state.pageType === item ? "selected" :  ""}`}
                                            >
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <p className="m-0 align-self-end small mr-2">Show All</p>
                        {/* <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                            onChange={this.handleChange}
                            value={this.state.groupType}
                            >
                                <option key="All" value="All">ALL</option>
                                <option key="important" value="important">Important</option>
                            </select>
                        </div>  */}
                        <p className="m-0 align-self-end small mr-2">errors groupd by</p>
                        <div class={`transparentSelectBorderBottom align-self-end`}>
                            <select class="form-control" 
                                onChange={(event) => {
                                    this.setState({ groupedBy: event.target.value, showGroupLoading: true, selectedCategory: "Important / All", errorDetails: {}, errorListLoader: true },
                                        () => {
                                            this.listErrorResults()
                                            // this.getImportantEventsDailyTrend('onChange')
                                        }
                                    )
                                }}
                                value={this.state.groupedBy}                            
                                >
                                <option key="severity" value="severity">Severity</option>
                                <option key="asset_name" value="asset_name">Asset</option>
                                <option key="impact_type" value="impact_type">Impact</option>
                                <option key="category" value='category'>Category</option>
                                <option value='resource_type'>Service</option>
                            </select>
                        </div>
                    </div>
                                    
                    <div className="d-flex mt-2">
                        <div className={`${this.state.expandRIghtSection ? 'w-60' : 'w-60'} mr-1`}>
                            <div className="rounded bg-dark p-3 errorSectionScroll">
                                <div className="d-flex mb-2 justify-content-between">
                                    <div className="d-flex mr-2 w-50">
                                        <p className="f12 m-0 align-self-center pt-1">Showing {this.state.filteredArray && this.state.filteredArray.length} of total {this.state.totalFilteredArray && this.state.totalFilteredArray.length}{this.state.totalFilteredArray && this.state.totalFilteredArray > 1 ? ' errors' : ' error'}</p>
                                        {/* {this.state.totalErrorList && this.state.totalErrorList.length}{this.state.totalErrorList && this.state.totalErrorList.length > 1 ? ' errors' : ' error'} from </p> */}
                                        <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{this.state.brushStartTime ? momentDateGivenFormat(this.state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(this.state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}</p>
                                        {/*  */}
                                    </div>
                                    <div className="d-flex justify-content-end w-50 mb-n2">
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <CSVLink 
                                                data={this.state.filteredArray} 
                                                headers={this.state.headers ? this.state.headers : []}
                                                filename={"observability-alerts.csv"}
                                                className={"align-self-center"}
                                                target="_blank"
                                            >
                                                <i className="fas fa-download text-primary-color"></i>
                                                {/* <span className="text-primary-color mx-2">csv</span> */}
                                            </CSVLink>
                                        : null}
                                        {this.state.totalFilteredArray && this.state.totalFilteredArray.length ?
                                            <Search
                                                data={this.state.totalFilteredArray}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-black5 w-50 mr-1 border-gray5 rounded-5 f12'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-gray5 f12'}
                                                searchPlaceHolder={'Search errors....'}
                                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                filteredData={(filteredArray) => {
                                                    this.setState({ filteredArray, errorDetails: {}, merticsAssetNameInvolved: [] },
                                                        // () => this.redirectToAnalysis()  
                                                        () => {
                                                            if(this.state.filteredArray.length) {
                                                                this.getErrorDetails(this.state.filteredArray[0])
                                                            }  
                                                        }
                                                    )
                                                }}
                                            />
                                        : null}
                                        {this.state.filteredArray && this.state.filteredArray.length > this.state.perPage ?
                                            <div className="pagination errorPagePagination f12">
                                                <span className="mx-3">Page <strong>{this.state.currentPage} of {this.state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('start', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${this.state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('previous', this.state.currentPage)}></i></button> 
                                                {/* <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={this.state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : 1
                                                            this.gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span> */}
                                                <button><i className={`fal fa-angle-right cursorPointer ${this.state.currentPage === this.state.overAllTotalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('next', this.state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${this.state.currentPage === this.state.overAllTotalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => this.navigatePage('end', this.state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    {this.state.errorsCategory ? 
                                        <div className="w-20 pr-3 categoryBoxSection">
                                            <div class="bg-muted px-2 pt-2 select-bgg">
                                                {this.state.groupedBy === "severity" ?
                                                    this.state.errorsCategory.map(item => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex p-2 cursorPointer ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getErrorDetails())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                                <div class={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getErrorDetails())}>
                                                                    <div class="d-flex">
                                                                        {item.totalBars.map(bar => {
                                                                            return(
                                                                            <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                            )
                                                                        })}
                                                                        <div class={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                            <p class="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    })                                            
                                                : 
                                                    this.state.errorsCategory.map((item, index) => {
                                                        return(
                                                            item.category === "Important / All" ?
                                                                <div className={`d-flex cursorPointer mb-3 ${this.state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getErrorDetails())}>
                                                                    <p className="m-0 f-20">{item.category}</p>
                                                                    <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                                </div>
                                                            :
                                                            <div className={`d-flex cursorPointer mb-2 ${this.state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getErrorDetails())}>
                                                                <div class="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                    <p class="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                    <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                    <input class="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    : null}
                                    <div className={`${this.state.errorsCategory ? 'w-80' : 'w-100'}`}>
                                        <div className={`bg-dark3 errorSectionScroll py-3 pl-2`} style={{minHeight: "545px"}}>
                                            {this.state.errorListLoader ? 
                                                <div className='d-flex justify-content-center m-4'>
                                                    <Spinner className='text-center' color='white' size='lg' />
                                                </div>
                                            : this.state.filteredArray && this.state.filteredArray.length ?
                                                this.state.filteredArray.slice(this.state.startRecord, this.state.startRecord + this.state.perPage).map((item, i) => {
                                                    return(
                                                        <div className={`d-flex mr-2 px-2 mb-2 pb-1 ${this.state.startRecord + this.state.perPage !== this.state.startRecord + i ? 'border-bottom-black5' : ''} ${item.event_id !== this.state.errorDetails.event_id ? 'cursorPointer' : 'bg-muted text-white pt-1'}`} onClick={() => this.setState({ errorDetails: {}, merticsAssetNameInvolved: [] }, () => this.getErrorDetails(item))}>
                                                            {/* <span className="mr-2">
                                                                <input type="checkbox"
                                                                    onChange={() => this.updateSelectedErrors(item.event_id) }
                                                                    checked={this.state['error_'+item.event_id]}
                                                                />
                                                            </span> */}
                                                            <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                                {item.severity ? 
                                                                    (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                : 'U'}
                                                            </span>
                                                            <p className="mb-0 text-white">
                                                                <span className="mb-0 mr-2 text-primary-color f12 font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                <span className="mb-0 mr-2 f12 font-weight-bold text-purple-2">Errors</span>
                                                                {item.origin && item.origin.asset_name ? 
                                                                    <span className="f12 mb-0 mr-2 text-purple-2"> Asset {item.origin.asset_name}</span> 
                                                                : null}
                                                                <span className="mb-0 mr-2 f12 font-weight-bold">{item.message ? item.message : ''}</span>
                                                            </p>                                                            
                                                            {/* <div className="d-flex ml-2 align-self-center"> */}
                                                                {/* <span className={`f12 ml-2 align-self-center font-weight-bold fa fa-circle risk-${item.severity ? item.severity.toLowerCase(): 'critical'}`}></span> */}
                                                                {/* <small className="mb-0 mr-1">{this.state.severityResponse && this.state.severityResponse.total && this.state.severityResponse.total.Critical ? this.state.severityResponse.total.Critical : 0}</small> */}
                                                            {/* </div> */}
                                                            {/* <i className={`fas fa-clock text-gray5 ml-3 f12 mt-1`} /> */}
                                                            {/* <div className="d-flex ml-2 align-self-center font-weight-bold"> */}
                                                                {/* <span className="mb-0 mr-2 f12 font-weight-bold">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                {item.origin && item.origin.asset_name ? <span className="f12 mb-0 mr-2 text-orange50"> Asset {item.origin.asset_name}</span> : null}
                                                                <span className="mb-0 mr-2 f12 text-success-light font-weight-bold">{item.message ? item.message : ''}</span> */}
                                                                {/* <p className="mb-0 mr-2 f12">. Raised in </p>
                                                                {item.raised_in && item.raised_in.asset_name ? <p className="mb-0 mr-2"> Asset {item.raised_in.asset_name}</p> : null}
                                                                {item.raised_in && item.raised_in.resource_type ? <p className="mb-0 mr-2">{item.raised_in.resource_type}</p> : null}
                                                                {item.raised_in && item.raised_in.service_name ? <p className="mb-0 mr-2">{item.raised_in.service_name}</p> : null} */}
                                                            {/* </div> */}
                                                        </div>                                            
                                                    )
                                                })
                                            :
                                                <div className='d-flex justify-content-center m-4'>
                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!this.state.errorListLoader ? 
                            <div className={`${this.state.expandRIghtSection ? 'w-40' : 'w-40'}`}>
                                <RightSection 
                                    updateDetail = {() => this.setState({ errorDetails: {} }, () => this.props.setAiopsPropsDetails('tabOptions', {}))}
                                    pageType={this.state.pageType}
                                />
                            </div>
                        : null}
                    </div>
                </div>
            </div>
		)
	}
}

/**
 * Type of the props used in the component
 */
 ErrorsDashboard.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('errorDashboard',state)
	return {
        accounts: state.filters.accounts,
		regions: state.filters.regions,
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},
        jumpTo: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.jumpTo ? state.aiops.aiopsPropsDetails.jumpTo : '',
    }
}

export default connect(mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
    setAiopsPropsDetails,

    getDailyCount,
    listErrorResults,
    getMonitoringMetricsByTrend,
})(withRouter(ErrorsDashboard))