/*************************************************
 * Tvastar
 * @exports
 * @file CreateEditConfiguration.js
 * @author Prakash // on 28/02/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { getRegionName, getAccountNameFromId } from '../../../utils/utility'

import { listAllProviders, listAllAccounts, listAllRegions, listAllServices } from '../../../actions/commonAction'
import { insertConfiguration, updateConfiguration } from '../../../actions/governance/governanceAction'

import { Spinner, Input } from 'reactstrap'

import Select from 'react-select'
import { store as CommonNotification } from 'react-notifications-component';

const Organizations = ["8kMiles"]
const Assets = ["1", "2", "3", "4", "5"]

class CreateEditConfiguration extends Component {
	constructor(props) {
		super(props)
		this.props = props;
		
		this.createAccountRef = React.createRef()
		this.createRegionRef = React.createRef()

		this.state = {
            ruleProvider: "",
            ruleAccount: "",
            ruleRegion: "",
			ruleOrganization: Organizations && Organizations.length === 1 ? Organizations[0] : "",
			ruleService: "",

			newRuleTags: [{ key: '', value: '' }],
		}
	}

	componentDidMount = () => {
		if(this.props.actionType === "Edit") {
			console.log(this.props.selectedConfiguration)
			this.setState({
				showModal: true,
				configName: this.props.selectedConfiguration.key_name && this.props.selectedConfiguration.key_name,
				configurationId: this.props.selectedConfiguration.configuration_id && this.props.selectedConfiguration.configuration_id,
				moduleId: this.props.selectedConfiguration.module_id && this.props.selectedConfiguration.module_id,
				configValue: this.props.selectedConfiguration.key_value && this.props.selectedConfiguration.key_value,
				moduleName: this.props.selectedConfiguration.module_name && this.props.selectedConfiguration.module_name,
				ruleProvider: this.props.selectedConfiguration.provider && this.props.selectedConfiguration.provider,
				ruleOrganization: this.props.selectedConfiguration.organization && this.props.selectedConfiguration.organization,
				ruleAccount: this.props.selectedConfiguration.account_id && this.props.selectedConfiguration.account_id,
				ruleRegion: this.props.selectedConfiguration.region && this.props.selectedConfiguration.region,
				ruleService: this.props.selectedConfiguration.service_name && this.props.selectedConfiguration.service_name,
				ruleAsset: this.props.selectedConfiguration.asset_name && this.props.selectedConfiguration.asset_name,
				newRuleTags: this.props.selectedConfiguration.tags ? this.props.selectedConfiguration.tags : [{ key: '', value: '' }],
				tagsButtonClicked: this.props.selectedConfiguration.tags ? true : false,
			})
		}
		
        if(this.props.providers && this.props.providers.length) {
			let providers = this.props.providers.filter(e => e.provider_name !== "All")
			this.setState({ providers },
				() => {
					this.getFilterData()
				}
			)
		} else {
			this.props.listAllProviders((promise, response) => {
				if (promise) {
					let providers = response.filter(e => e.provider_name !== "All")
					this.setState({ providers },
						() => {
							this.getFilterData()
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.getFilterData()
						}
					)
				}
			})
		}	
	}
	/**
	 * Filter identifiers based on the filter selection
	 */
	getFilterData = () => {
		let params = {}
		if (this.state.ruleProvider) {
			params.provider = this.state.ruleProvider.toLowerCase()
		}
		
		this.props.listAllAccounts(params, (promise, allAccounts) => {
			if (promise) {						
				let filterdAccounts = allAccounts.filter(e => e.provider === this.state.ruleProvider.toLowerCase())
				this.setState({ accounts: filterdAccounts })
			}
		})
		
		this.props.listAllRegions(params, (promise, regions) => {
			if (promise) {
				let providerRegions = regions && regions[0] && regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.setState({ regions: [], ruleRegion: [] })
			}
		})

		this.props.listAllServices(params, (promise, services) => {
			if (promise) {
				this.setState({ services }, 
					() => this.formSericeSelect()	
				)
			}
		})
	}

	formSericeSelect = () => {
		let data = []
		if(this.state.services && this.state.services.length) {
			_.chain(this.state.services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					let child = []
					service.sub.forEach((item, i) => {
						let childRow = {}
						childRow.value = service.service_name
						childRow.label = item.service_name
						child.push(childRow)
					})
					let dataRow = {}
					dataRow.label = service.label
					dataRow.options = child

					data.push(dataRow)
				})
		}

		this.setState({ servicesOptGrop: data })
	}

	handleClickOutside = (event) => {

		// if (this.createRegionRef && !this.createRegionRef.current.contains(event.target)) {
		// 	this.setState({ isRegionOpen: false })
		// } else {
		// 	this.setState({ isRegionOpen: true })
		// }
	}
	/**
	 * Add new rule form
	 */
	addNewRuleTags = () => {
		const temp = this.state.newRuleTags
		temp.push({
			key: '',
			value: '',
		})
		console.log("temp", temp)
		this.setState({ newRuleTags: temp })
	}
	/**
	 * Delete new rule form
	 */
	deleteNewRuleTags = index => {
		if (this.state.newRuleTags.length === 1) {
			this.setState({ tagsButtonClicked: false })
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			temp.push({ key: '', value: '' })
			this.setState({ newRuleTags: temp })
		} else {
			const temp = this.state.newRuleTags
			temp.splice(index, 1)
			this.setState({ newRuleTags: temp })
		}
	}
	
	/**
	 * Handles on submit new rule form
	 */
	onSubmitNewRuleForm = () => {
		if (
			this.state.configName === '' ||
			this.state.configValue === '' ||
			this.state.moduleId === '' ||
			this.state.moduleName === '' ||
			this.state.ruleProvider === '' ||
			this.state.ruleDescription === '' ||
			this.state.ruleDetectorId === '' ||
			this.state.ruleService === '' ||
			this.state.ruleOrganization === '' ||
			this.state.ruleAccount === '' ||
			this.state.ruleRegion === '' ||
			this.state.ruleAsset === '' ||
			this.state.newRuleTags[0].key === '' ||
			this.state.newRuleTags[0].value === ''
		) {
			this.setState({ hasErrorInRuleForm: true, submitConfigurationLoading: false })
		} else {
			let formData = {
				key_name: this.state.configName,
				module_id: this.state.moduleId,
				key_value: this.state.configValue,
				module_name: this.state.moduleName,
				provider: this.state.ruleProvider,
				organization: this.state.ruleOrganization,
				account_id: this.state.ruleAccount,
				region: this.state.ruleRegion,
				service_name: this.state.ruleService,
				asset_name: this.state.ruleAsset,
				tags: this.state.newRuleTags,
			}

			let messageType = ""
			let message = ""
			if (this.props.actionType === "Edit") {
				formData.configuration_id = this.state.configurationId
				this.props.updateConfiguration(formData, (promise, response) => {
					this.setState({ submitConfigurationLoading: false })
					if (promise) {
						messageType = "success"
						if(!response.status) {
							messageType = "danger"
						}
					} else {
						messageType = "danger"	
						this.setState({ runDiagnosticsLoading: false, runSaveDiagnosticsLoading: false })
					}

					message = response && response.message ? response.message : ""
					let diagnosticId = response.result && response.result.diagnostic_id ? response.result.diagnostic_id : ""
					if(messageType === "success") {
						message = 'Confguration updated successfully. Configuration Id: '+diagnosticId
					}

					CommonNotification.addNotification({
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
		
					if(messageType === "success") {
						this.props.callListApi()
					}
				})
			} else {
				this.props.insertConfiguration(formData, (promise, response) => {
					this.setState({ submitConfigurationLoading: false })
					if (promise) {
						messageType = "success"
						if(!response.status) {
							messageType = "danger"
						}
					} else {
						messageType = "danger"	
						this.setState({ runDiagnosticsLoading: false, runSaveDiagnosticsLoading: false })
					}

					message = response && response.message ? response.message : ""
					let diagnosticId = response.result && response.result.diagnostic_id ? response.result.diagnostic_id : ""
					if(messageType === "success") {
						message = 'Confguration submitted successfully. Configuration Id: '+diagnosticId
					}

					CommonNotification.addNotification({
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
		
					if(messageType === "success") {
						this.props.callListApi()
					}
				})				
			}
		}
	}
	
	render() {		
		return (
			<div className={`advanced-search ${this.state.submitConfigurationLoading ? "disabled" : ""} over`} onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className="search-content bg-muted overflow-auto">
					<div className="header-search bd-highlight justify-content-between">
						<label>{this.props.actionType} Rule</label>
						<i className="far fa-times" onClick={() => this.props.closePanel()}></i>
					</div>
					<div className="p-3">
						<div className="rounded bg-dark p-3">
							<div className="d-flex mb-2">
								<div className="py-1 w-50">
									<div className="d-flex justify-content-between">
										<p className="mb-0 text-white">Name</p>										
										{this.state.hasErrorInRuleForm && (!this.state.configName || this.state.configName === "") ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Input 
										type="text" 
										placeholder="Name"
										maxLength={80}
										className={`inputDarkTheme`}
										value={this.state.configName ? this.state.configName : ""}
										onChange={e => this.setState({ configName: e.target.value })}
									/>
								</div>
								<div className="pl-3 py-1 w-50">
									<div className="d-flex justify-content-between">
										<p className="b-block mb-0 text-white">Module ID</p>
										{this.state.hasErrorInRuleForm && (!this.state.moduleId || this.state.moduleId === "") ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Input 
										type="text" 
										placeholder="Module ID"
										maxLength={80}
										className={`inputDarkTheme`}
										value={this.state.moduleId ? this.state.moduleId : ""}
										onChange={e => this.setState({ moduleId: e.target.value })}
									/>
								</div>
							</div>
							<div className="d-flex mb-2">
								<div className="py-1 w-50">
									<div className="d-flex justify-content-between">
										<p className="mb-0 text-white">Value</p>										
										{this.state.hasErrorInRuleForm && (!this.state.configValue || this.state.configValue === "") ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Input 
										type="text" 
										placeholder="Name"
										maxLength={80}
										className={`inputDarkTheme`}
										value={this.state.configValue ? this.state.configValue : ""}
										onChange={e => this.setState({ configValue: e.target.value })}
									/>
								</div>
								<div className="pl-3 py-1 w-50">
									<div className="d-flex justify-content-between">
										<p className="b-block mb-0 text-white">Module Name</p>
										{this.state.hasErrorInRuleForm && (!this.state.moduleName || this.state.moduleName === "") ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									<Input 
										type="text" 
										placeholder="Name"
										maxLength={80}
										className={`inputDarkTheme`}
										value={this.state.moduleName ? this.state.moduleName : ""}
										onChange={e => this.setState({ moduleName: e.target.value })}
									/>
								</div>
							</div>
							<div className="d-flex mt-2">
								<div className="py-1 w-50">
									<div className="w-60">
										<div className="d-flex justify-content-between">
											<p className="b-block mb-0 text-white">Provider</p>
											{this.state.hasErrorInRuleForm && (!this.state.ruleProvider || this.state.ruleProvider === "") ?
												<p className="mb-0 text-danger">required</p>
											: null}
										</div>
										<Select
											placeholder={"Select"}
											isSearchable={true}
											// isDisabled={this.props.actionType === "Run" ? true : false}
											className="f13 p-0 selectDarkTheme"
											options={this.state.providers && this.state.providers.map(item => ({
												value: item.provider_name,
												label: item.provider_name,
											}))}
											value={({
												value: this.state.ruleProvider,
												label: this.state.ruleProvider && this.state.ruleProvider !== "" ? this.state.ruleProvider.toUpperCase() : <span className="placeholder">Select</span>
											})}
											onChange={event =>  
												this.setState({
													ruleProvider: event.value.toLowerCase()
												},
													() => this.getFilterData()
												)
											}
										/>
									</div>
								</div>
								<div className="pl-3 py-1 w-50">
									<div className="w-60">
										<div className="d-flex justify-content-between">
											<p className="b-block mb-0 text-white">Organization</p>
											{this.state.hasErrorInRuleForm && (!this.state.ruleOrganization || this.state.ruleOrganization === "") ?
												<p className="mb-0 text-danger">required</p>
											: null}
										</div>
										<Select
											placeholder={"Select"}
											isSearchable={true}
											// menuIsOpen={this.props.actionType === "Run" ? false : true}
											className="f13 p-0 selectDarkTheme"
											value={({
												value: this.state.ruleOrganization && this.state.ruleOrganization !== "" ? this.state.ruleOrganization : "Select",
												label: this.state.ruleOrganization && this.state.ruleOrganization !== "" ? this.state.ruleOrganization : <span className="placeholder">Select</span>
											})}
											options={Organizations && Organizations.map(item => ({
												value: item,
												label: item,	
											}))}
											onChange={event =>  
												this.setState({
													ruleOrganization: event.value
												})
											}
										/>
									</div>
								</div>
							</div>
							<div className="d-flex mt-2">
								<div className="py-1 w-50">
									<div className="w-60">
										<div className="d-flex justify-content-between">
											<p className="b-block mb-0 text-white">Account</p>
											{this.state.hasErrorInRuleForm && (!this.state.ruleAccount || this.state.ruleAccount === "") ?
												<p className="mb-0 text-danger">required</p>
											: null}
										</div>
										<Select
											placeholder={"Select"}
											isSearchable={true}
											className="f13 p-0 selectDarkTheme"
											options={this.state.accounts && this.state.accounts.map(account => ({
												value: account.account_id,
												label: account.account_name,
											}))}
											value={({
												value: this.state.ruleAccount && this.state.ruleAccount !== "" ? this.state.ruleAccount : "Select",
												label: this.state.ruleAccount && this.state.ruleAccount !== "" ? getAccountNameFromId(this.state.ruleAccount, this.state.accounts) : <span className="placeholder">Select</span>
											})}
											onChange={event =>  
												this.setState({
													ruleAccount: event.value
												})
											}
										/>
									</div>
								</div>
								<div className="pl-3 py-1 w-50">
									<div className="w-60">
										<div className="d-flex justify-content-between">
											<p className="b-block mb-0 text-white">Region</p>
											{this.state.hasErrorInRuleForm && (!this.state.ruleRegion || this.state.ruleRegion === "") ?
												<p className="mb-0 text-danger">required</p>
											: null}
										</div>
										<Select
											placeholder={"Select"}
											isSearchable={true}
											// menuIsOpen={true}
											className="f13 p-0 selectDarkTheme"
											options={this.state.regions && this.state.regions.map(reg => ({
												value: reg.region,
												label: reg.name,
											}))}
											value={({
												value: this.state.ruleRegion && this.state.ruleRegion !== "" ? this.state.ruleRegion : "Select",
												label: this.state.ruleRegion && this.state.ruleRegion !== "" ? getRegionName(this.state.ruleRegion, this.state.regions) : <span className="placeholder">Select</span>
											})}
											onChange={event =>  
												this.setState({
													ruleRegion: event.value
												})
											}
										/>
									</div>
								</div>
							</div>
							<div className="d-flex mt-2">
								<div className="py-1 w-50">
									<div className="w-60">
										<div className="d-flex justify-content-between">
											<p className="b-block mb-0 text-white">Services</p>
											{this.state.hasErrorInRuleForm && (!this.state.ruleService || this.state.ruleService === "") ?
												<p className="mb-0 text-danger">required</p>
											: null}
										</div>
										<Select
											placeholder={"Select"}
											// isSearchable={false}
											// menuIsOpen={true}
											//onFocus={this.openProivderMenu}
											className="f13 p-0 selectDarkTheme reactSelectOptGroupFilterDrop"
											// label={({
											// 	label: this.state.selectedService,
											// 	// label: this.state.selectedService ? getRegionName(this.state.selectedService, regions) : <span className="placeholder">Select</span>
											// })}
											
											value={({
												value: this.state.ruleService && this.state.ruleService !== "" ? this.state.ruleService : "Select",
												label: this.state.ruleService && this.state.ruleService !== "" ?this.state.ruleService : <span className="placeholder">Select</span>
											})}
											options={this.state.servicesOptGrop && this.state.servicesOptGrop}
											onChange={event => this.setState({ ruleService: event.label, changesInFilter: true })}
										/>
									</div>
								</div>
								<div className="pl-3 py-1 w-50">
									<div className="w-60">
										<div className="d-flex justify-content-between">
											<p className="b-block mb-0 text-white">Asset</p>
											{this.state.hasErrorInRuleForm && (!this.state.ruleAsset || this.state.ruleAsset === "") ?
												<p className="mb-0 text-danger">required</p>
											: null}
										</div>
										<Select
											placeholder={"Select"}
											isSearchable={true}
											// menuIsOpen={this.props.actionType === "Run" ? false : true}
											className="f13 p-0 selectDarkTheme"
											value={({
												value: this.state.ruleAsset && this.state.ruleAsset !== "" ? this.state.ruleAsset : "Select",
												label: this.state.ruleAsset && this.state.ruleAsset !== "" ? this.state.ruleAsset : <span className="placeholder">Select</span>
											})}
											options={Assets && Assets.map(item => ({
												value: item,
												label: item,	
											}))}
											onChange={event =>  
												this.setState({
													ruleAsset: event.value
												})
											}
										/>
									</div>
								</div>
							</div>

							{this.state.newRuleTags ?
								<div className="mt-3">
									<div className="w-82 d-flex justify-content-between">
										<p className="b-block mb-0 text-white">Tags</p>
										{this.state.hasErrorInRuleForm && (this.state.newRuleTags[0].key === '' || this.state.newRuleTags[0].value === '') ?
											<p className="mb-0 text-danger">required</p>
										: null}
									</div>
									{this.state.newRuleTags.map((item, index) => {
										return(
											<div className={`w-100 ${index ? "mt-3" : ""}`}>
												<div className="d-flex">
													{/* <p className="mb-0 mr-2 align-self-end text-info">Bucket</p> */}
													<Input
														type="text" 
														placeholder="key (environment)"
														className={`w-40 inputDarkTheme`}
														value={item.key}
														onChange={e => {
															let tags = this.state.newRuleTags
															tags[index].key = e.target.value
															this.setState({ newRuleTags: tags })
														}}
													/>
													{/* <p className="mb-0 mx-2 align-self-end text-info">Prefix</p> */}
													<Input 
														type="text" 
														placeholder="value (prod)"
														className={`w-40 inputDarkTheme ml-3`}
														value={item.value}
														onChange={e => {
															let tags = this.state.newRuleTags
															tags[index].value = e.target.value
															this.setState({ newRuleTags: tags })
														}}
													/>
													{this.state.newRuleTags.length > 1 ?
														<span className={`far fa-trash cursor-pointer f18 align-self-center ml-4`} onClick={() => this.deleteNewRuleTags(index)}></span>
													: null}
													{this.state.newRuleTags.length - 1 === index?
														<span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => this.addNewRuleTags()}></span>
													: null}
												</div>
											</div>
										)
									})}
								</div>
							: null}	
							
							<div className="d-flex mt-3 mb-2">
								<button className={`btn bg-secondary p-2 text-white mr-3`} onClick={() => this.props.closePanel()}>Cancel</button>
								<button className={`btn bg-info p-2 text-white mr-3`} onClick={() => this.setState({ submitConfigurationLoading: true }, () => this.onSubmitNewRuleForm())}>
									<Spinner className={`mr-2 ${this.state.submitConfigurationLoading ? "" : "displayNone"}`} color="white" size="sm" />{this.props.actionType === "Edit" ? "Update" : "Save"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
CreateEditConfiguration.propTypes = {}

const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
        accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default connect(mapStateToProps, {
	listAllProviders,
	listAllServices,
	listAllRegions,
	listAllAccounts,
	insertConfiguration,
	updateConfiguration
})(withRouter(CreateEditConfiguration))



