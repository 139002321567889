/*************************************************
 * Tvastar
 * @exports
 * @file registerAction.js
 * @author Rajasekar // on 23/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
// import { myLog } from '../../utils/utility'
import { API } from 'aws-amplify'

import {
    AIOPS_SERVICE,
    LIST_ALERTS_BY_TYPE,
    LIST_ALERTS_BY_ASSETS
} from '../../config'

/**
 * Action to get Alerts by type Alert
 * @param {Object} body
 * @param {Function} callback
 */
export const getAlertsByType = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, LIST_ALERTS_BY_TYPE, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}

/**
 * Action to get Alerts by type Alert
 * @param {Object} body
 * @param {Function} callback
 */
export const listAlertsByAssets = (body, callback) => {
	return async () => {
		await API.post(AIOPS_SERVICE, LIST_ALERTS_BY_ASSETS, { body })
		.then((response) => {
			callback(true, response)
		}).catch((err) => {
			let message = ''
			callback(false, message)
		})
    }
}