/*************************************************
 * Tvastar
 * @exports
 * @file CreateBudgetPanel.js
 * @author Prakash // on 113/02/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _, { filter } from 'lodash'
// import PropTypes from 'prop-types'
import { Spinner, Input, UncontrolledPopover, PopoverBody } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { store as CommonNotification } from 'react-notifications-component';

import { getAccountNameFromId, capitalizeFirstLetter, onlyNumeric, momentConvertionUtcToLocalTime, getRegionName } from '../../../utils/utility'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

import { listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues } from '../../../actions/commonAction'
import { getBudgetFilters, insertBudgetDetails, updateBudgetDetails, getCostFilterValues, listBudgetDetails } from '../../../actions/cost/CostAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchComponent'

class CreateBudgetPanel extends Component {
	constructor(props) {
		super(props)
        this.toggleRef = React.createRef()
		this.state = {
			showBudgetScreen: "manage",
            minMaxHeight: 700,
			showDetails: true,
			periods: [
				{label: "Daily", value: "daily"},
				{label: "Monthly", value: "monthly"},
				{label: "Quarterly", value: "quarterly"},
				{label: "Half Yearly", value: "half-yearly"},
				{label: "Annual", value: "yearly"},
			],
			costSource: ["aws", "kubernetes"],
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this._handleKeyDown)
		this.getWindowSize()
		window.addEventListener('resize', () => {
            this.getWindowSize()
        })
		this.getBudgetFilters()
		this.listBudgetDetails()

		this.getAllTagsKeys()		
    }

    getWindowSize = () =>  {
        const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		this.setState({ outerHeight, minMaxHeight: outerHeight })
    }

	listBudgetDetails = () => {
		let params = {}
		this.props.listBudgetDetails(params, (promise, response) => {
			if(promise) {
				this.setState({ listBudgets: response, filterListBudgets: response })
			} else {
				this.setState({ listBudgets: [], filterListBudgets: [] })
			}
		})
	}

	getBudgetFilters = () => {
		let params = {}
		this.props.getBudgetFilters(params, (promise, response) => {
			this.setState({ costSource: response })
		})
	}

	_handleKeyDown = event => {
		if(this.state.onSaveFunction) {
			switch (event.keyCode) {
				case 27:
					this.props.closeSidePanel()
					break
				default:
					break
			}
		}
	}

    handleClickOutside(event) {}

	getAllTagsKeys = () => {
		if(!this.props.tagKeys || !this.props.tagKeys.length) {
			let params = {}
			// params.account_id = "265469181489"
			// params.region = "us-east-2"
			this.props.getAllTagsKeys(params, (promise, response) => {})
		}
	}

	getAllTagsValues = (index, arrIndex) => {
		let params = {}
		params.tags = [{ "key": this.state["tag_key_"+index+"_"+arrIndex] }]
		this.props.getAllTagsValues(params, (promise, response) => {
			if(promise) {
				let result = response
				// if(response.length) {
				// 	result.unshift("All");
				// }
				this.setState({ ["tagValues_"+index+"_"+arrIndex]: response })
			}
		})
	}		

	addNewTag = (index, arrIndex) => {
		let selectedTags = this.state["selected_tags_"+index+"_"+arrIndex] ? this.state["selected_tags_"+index+"_"+arrIndex] : []

		if(this.state["tag_key_"+index+"_"+arrIndex] && this.state["tag_key_"+index+"_"+arrIndex] !== "" && this.state["tag_value_"+index+"_"+arrIndex] && this.state["tag_value_"+index+"_"+arrIndex].length) {
			this.state["tag_value_"+index+"_"+arrIndex].forEach(tag => {
				if(!selectedTags.filter(e => e.key === this.state["tag_key_"+index+"_"+arrIndex] && e.value === tag).length) {
					let dataRow = {}
					dataRow.key = this.state["tag_key_"+index+"_"+arrIndex]
					dataRow.value = tag
					selectedTags.push(dataRow)
				}
			})
		}

		this.setState({ ["selected_tags_"+index+"_"+arrIndex]: selectedTags, ["tag_key_"+index+"_"+arrIndex]: "",  ["tag_value_"+index+"_"+arrIndex]: [], ["tagValues_"+index+"_"+arrIndex]: [] })
	}

	removeRegion = (region, index, arrIndex) => {
		let filteredResult = this.state["selectedRegion_"+index+"_"+arrIndex].filter(e => e !== region)
		this.setState({["selectedRegion_"+index+"_"+arrIndex]: filteredResult })
	}

	removeItem = (field, state, item) => {
        this.setState({ [field]: this.state[field].filter(e => e != item) })
	}

	removeTagSection = (tag, index, arrIndex) => {
		let filteredResult = this.state["selected_tags_"+index+"_"+arrIndex].filter(e => e !== tag)
		filteredResult = filteredResult.filter(e => e.value !== "All")
		this.setState({["selected_tags_"+index+"_"+arrIndex]: filteredResult })
	}
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		let prevState = this.state[field] ? this.state[field] : []
		if(prevState.length === 1 && prevState[0] === "All") {
			prevState = []
		}
		if(value.includes("All")) {
			if(!prevState.includes("All")) {
				if(state.filter(e => e.value).length) {
					selectedValue = state.map(e => e.value)
				} else {
					selectedValue = state
				}
			} else {
				const index = value.indexOf("All");
				if (index > -1) {
					value.splice(index, 1);
				}
				selectedValue = value
			}
		} else if(!prevState.includes("All")) {
			selectedValue = value
		}

		if(selectedValue.length && !selectedValue.includes("All")) {
			if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
				selectedValue.push("All")
			}
		}
		
		this.setState({ [field]: selectedValue },
			() => {
				if(field === "selectedPeriod") {
					this.initializeFilterArray()
				}
			}	
		)
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes("All") ? "All" : (array.length ? array.length +" Selected" : <span className="placeholder w-100 align-self-center">Select</span>)
	}
	
	handleInputChange = (label, value) => {
		let obj = this.props.selectedItem
		obj[label] = value

		this.setState({ [label]: value })
	}

	validateInput = () => {
		let hasError = false
		
		if(!this.state.selectedSource || !this.state.selectedPeriod || !this.state.budget_name) {
			hasError = true
		}

		this.state.selectedPeriod.forEach((period, index) => {
			if(!this.state["budget_amount_"+period]) {
				hasError = true
			}
			let appliesTo = []
			this.state["period_array_"+period].forEach(arrIndex => {
				let applyRow = {}
				applyRow.period = period
				applyRow.account_id = this.state["selectedAccount_"+index+"_"+arrIndex] ? this.state["selectedAccount_"+index+"_"+arrIndex] : ""
				if(this.state["selectedRegion_"+index+"_"+arrIndex] && this.state["selectedRegion_"+index+"_"+arrIndex].length) {
					applyRow.region = this.state["selectedRegion_"+index+"_"+arrIndex]
				}
				if(this.state["selected_tags_"+index+"_"+arrIndex] && this.state["selected_tags_"+index+"_"+arrIndex].length) {
					applyRow.tags = this.state["selected_tags_"+index+"_"+arrIndex]
				
				}
				appliesTo.push(applyRow)
			})
			let isDuplicateArrayObject = false
			var result = _.map(appliesTo, function(o, i) {
				var eq = _.find(appliesTo, function(e, ind) {
				  if (i > ind) {
					return _.isEqual(e, o);
				  }
				})
				if (eq) {
				  isDuplicateArrayObject = true;
				}
			})

			if(isDuplicateArrayObject) {
				this.setState({ ["isDuplicate_"+period]: true, hasError: true })
			} else {
				this.setState({ ["isDuplicate_"+period]: false })
			}
		})

		if(!hasError) {
			{this.state.editMode ? 
			   this.updateBudget()
			:
			   this.onFinish()
			}
		} else {
			this.setState({ onSaveFunction: false })
		}

	}

	onFinish = () => {
		let params = {}
		params.source = this.state.selectedSource
		params.provider = this.props.selectedProvider.toLowerCase()
		// params.selectedPeriod = this.state.selectedPeriod ? this.state.selectedPeriod : []
		params.selectedAccount = this.state.selectedAccount
		params.budget_name = this.state.budget_name

		let budget_details = []
		this.state.selectedPeriod.forEach((period, index) => {
			let periodRow = {}
			periodRow.period = period
			periodRow.budget = this.state["budget_amount_"+period]
			let appliesTo = []
			this.state["period_array_"+period].forEach(arrIndex => {
				let applyRow = {}
				applyRow.account_id = this.state["selectedAccount_"+index+"_"+arrIndex] ? this.state["selectedAccount_"+index+"_"+arrIndex] : ""
				if(this.state["selectedRegion_"+index+"_"+arrIndex] && this.state["selectedRegion_"+index+"_"+arrIndex].length) {
					applyRow.region = this.state["selectedRegion_"+index+"_"+arrIndex]
				}
				if(this.state["selected_tags_"+index+"_"+arrIndex] && this.state["selected_tags_"+index+"_"+arrIndex].length) {
					applyRow.tags = this.state["selected_tags_"+index+"_"+arrIndex]
				
				}
				if(this.state["selectedAccount_"+index+"_"+arrIndex]) {
					appliesTo.push(applyRow)
				}
			})
			periodRow.applies_to = appliesTo
			budget_details.push(periodRow)
		})
		
		params.budget_details = budget_details

		// console.log("params", params)
		
		this.props.insertBudgetDetails(params, (promise, response) => {
			let messageType = ''
			let message = ''
			let createErrorMessage = ""
			if(response) {
				if(response.status) {
					messageType = 'success'
					message = 'Budget details saved successfully'
				} else {
					messageType = 'danger'
					message = 'Error in saving Budget details'
					createErrorMessage = response.error

				}
			} else {
				messageType = 'danger'
				message = 'Error in saving Budget details'
			}
			this.setState({ onSaveFunction: false },
				() => this.listBudgetDetails()	
			)

			CommonNotification.addNotification({
				//title: "Wonderful!",
				message: message,
				type: messageType,
				insert: "top",
				container: "top-center",
				// animationIn: ["animate__animated", "animate__fadeIn"],
				// animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
				duration: 5000,
				onScreen: false,
				pauseOnHover: true,
				showIcon: true,
				}
			});
		})
	}

	updateBudget = () => {
		let params = {}
		params.source = this.state.selectedSource
		params.provider = this.props.selectedProvider.toLowerCase()
		params.budget_name = this.state.budget_name
		params.budget_id = this.state.selectedData.budget_id
		if(this.state.selectedData.doc_id) {
			params.doc_id = this.state.selectedData.doc_id
		}

		let budget_details = []
		this.state.selectedPeriod.forEach((period, index) => {
			let periodRow = {}
			periodRow.period = period
			periodRow.budget = this.state["budget_amount_"+period]
			let appliesTo = []
			this.state["period_array_"+period].forEach(arrIndex => {
				let applyRow = {}
				applyRow.account_id = this.state["selectedAccount_"+index+"_"+arrIndex] ? this.state["selectedAccount_"+index+"_"+arrIndex] : ""
				if(this.state["selectedRegion_"+index+"_"+arrIndex] && this.state["selectedRegion_"+index+"_"+arrIndex].length) {
					applyRow.region = this.state["selectedRegion_"+index+"_"+arrIndex]
				}
				if(this.state["selected_tags_"+index+"_"+arrIndex] && this.state["selected_tags_"+index+"_"+arrIndex].length) {
					applyRow.tags = this.state["selected_tags_"+index+"_"+arrIndex]
				
				}
				if(this.state["selectedAccount_"+index+"_"+arrIndex]) {
					appliesTo.push(applyRow)
				}
			})
			periodRow.applies_to = appliesTo
			budget_details.push(periodRow)
		})
		
		params.budget_details = budget_details
		this.props.updateBudgetDetails(params, (promise, response) => {
			let messageType = ''
			let message = ''
			let createErrorMessage = ""
			if(response) {
				if(response.status) {
					messageType = 'success'
					message = 'Budget details saved successfully'
				} else {
					messageType = 'danger'
					message = 'Error in saving Budget details'
					createErrorMessage = response.error

				}
			} else {
				messageType = 'danger'
				message = 'Error in saving Budget details'
			}
			this.setState({ onSaveFunction: false },
				() => this.listBudgetDetails()	
			)

			CommonNotification.addNotification({
				//title: "Wonderful!",
				message: message,
				type: messageType,
				insert: "top",
				container: "top-center",
				// animationIn: ["animate__animated", "animate__fadeIn"],
				// animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
				duration: 5000,
				onScreen: false,
				pauseOnHover: true,
				showIcon: true,
				}
			});
		})
	}

	initializeFilterArray = () => {
		this.state.selectedPeriod.forEach(prd => {
			let rowList = this.state["period_array_"+prd] ? this.state["period_array_"+prd] : [0]
			this.setState({ ["period_array_"+prd]: rowList })
		})
	}

	addFilterArray = (period, index) => {
		let rowList = this.state["period_array_"+period] ? this.state["period_array_"+period] : [0]
		if (this.state["period_array_"+period]) {
			let value = this.state["period_array_"+period][this.state["period_array_"+period].length - 1]
			value = value + 1
			rowList.push(value)
		}

		this.setState({
			["period_array_"+period]: rowList
		})
	}

	// onChangeView = () => {
	// 	let view = this.state.selectedView

	// 	let budgetColumns = []
	// 	if(view === "rule_wise") {
	// 		dataRow.Header = 'Date'
	// 		dataRow.accessor = 'created_at'
	// 		dataRow.Cell = cellInfo => (<span>{cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : ''}</span>)
	// 		dataRow.width = 150
	// 		budgetColumns.push(dataRow)

	// 		dataRow = {}
	// 		dataRow.Header = 'Name'
	// 		dataRow.accessor = 'budget_name'	
	// 		dataRow.width = 200				
	// 		budgetColumns.push(dataRow)

	// 		dataRow = {}
	// 		dataRow.Header = 'Source'
	// 		dataRow.accessor = 'source'
	// 		dataRow.width = 100
	// 		budgetColumns.push(dataRow)

	// 		dataRow = {}
	// 		dataRow.Header = 'Resources'
	// 		// dataRow.accessor = 'severity'
	// 		dataRow.Cell = cellInfo => (
	// 			<div className="d-flex">
	// 				<span className='mr-2'>{cellInfo.row.original.appResources}</span>
	// 				{cellInfo.row.original.showMore ?
	// 					<span className={`badge status-badge badge-outline-info`} onClick={() => this.setState({ masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original}, () => this.setState({ showFilterDetailsPanel: true }))}>more</span>
	// 				: null}
	// 			</div>
	// 		)
	// 		dataRow.width = 400
	// 		budgetColumns.push(dataRow)

	// 		dataRow = {}	
	// 		dataRow.Header = 'Tags'
	// 		dataRow.Cell = cellInfo => (
	// 			<div className="d-flex">
	// 				{cellInfo.row.original.ruleTags && cellInfo.row.original.ruleTags.length ? 
	// 					<span className={`badge status-badge risk-badge-outline-high risk-high`}>{cellInfo.row.original.ruleTags[0]}</span>
	// 				: null}
	// 			</div>
	// 		)
	// 		dataRow.width = 150
	// 		budgetColumns.push(dataRow)

	// 		dataRow = {}	
	// 		dataRow.Header = 'Status'
	// 		dataRow.Cell = cellInfo => (
	// 			<span className={`badge status-badge badge-outline-${cellInfo.row.original.ruleEnabled ? "success" : "danger"}`}>{cellInfo.row.original.ruleEnabled ? "Enabled" : "Disabled"}</span>
	// 		)
	// 		dataRow.width = 150
	// 		budgetColumns.push(dataRow)

	// 		dataRow = {}	
	// 		dataRow.Header = 'Description'
	// 		dataRow.accessor = "ruleDescription"
	// 		dataRow.width = this.state.selectedPolicyType !== "all_rules" ? 500 : 600
	// 		budgetColumns.push(dataRow)

	// 		if(this.state.selectedPolicyType !== "all_rules") {	
	// 			dataRow = {}
	// 			dataRow.Header = 'Actions'
	// 			// dataRow.accessor = 'severity'
	// 			dataRow.Cell = cellInfo => (
	// 				<div className="d-flex justify-content-between align-self-center">
	// 					<p className="m-0 p-0 mx-1 cursorPointer" onClick={() => this.setState({ masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original}, () => this.setState({ showFilterDetailsPanel: true }))}><i className="fas fa-ellipsis-h"></i></p>
	// 				</div>
	// 			)
	// 			dataRow.width = 100
	// 			budgetColumns.push(dataRow)
	// 		}
	// 	}
	// 	this.setState({ budgetColumns })
	// }

	onClickEditBudget = (row) => {
		let selectedPeriod = []
		row.budget_details.forEach((item, index) => {
			selectedPeriod.push(item.period)
			let periodArray = [0]
			this.setState({ 
				["selectedAccount_"+index+"_"+0]: row.account_id,
				["selectedRegion_"+index+"_"+0]: row.region ? row.region : [],
				["selected_tags_"+index+"_"+0]: row.tags ? row.tags : [],
				["budget_amount_"+item.period]: item.budget
			})
			this.setState({ ["period_array_"+item.period]: periodArray })
		})
		this.setState({ 
			selectedData: row,
			selectedSource: row.source,
			budget_name: row.budget_name,
			selectedPeriod,
			showBudgetScreen: "edit",
			editMode: true
		})		
	}

	resetState = () => {
		this.state.periods.forEach((row, index) => {
            this.state["period_array_"+row] && this.state["period_array_"+row].forEach(arrIndex => {
				this.setState({ 
					["selectedAccount_"+index+"_"+0]: "",
					["selectedRegion_"+index+"_"+0]: [],
					["selected_tags_"+index+"_"+0]: [],
					["budget_amount_"+row]: ""
				})
			})
			
			this.setState({ ["period_array_"+row]: [0] },
				() => this.listBudgetDetails()
			)
		})
		this.setState({ 
			selectedSource: "",
			budget_name: "",
			selectedPeriod: []
		})	
	}
	
	render() {
		return (
			<div className="advanced-search" onClick={(event) => this.handleClickOutside(event)}>
				<div className={`loaderOverlay ${this.state.queryLoading ? "" : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className="search-content bg-muted w-80">
					<div className="header-search bd-highlight d-flex justify-content-between">
						<div className="flex-fill bd-highlight">
							<h5>
								{this.state.showBudgetScreen === "manage" ? "Manage" : (this.state.showBudgetScreen === "new" ?  "Create" : "Edit")} Budgets
							</h5>
							<div className="mr-2 d-flex">
							</div>
						</div>

						<div className="text-right flex-fill bd-highlight align-self-center">
							{this.state.showBudgetScreen !== "manage" ?
								<button type="button"  className='mr-w btn btn-primary align-self-center cursorPointer' onClick={() => this.setState({ showBudgetScreen: "manage", editMode: false })}>
									Manage Budget
								</button>
							: 
								<button type="button"  className='mr-w btn btn-primary align-self-center cursorPointer' onClick={() => this.setState({ showBudgetScreen: "new" })}>
									New Budget
								</button>
							}
							<i className={`far fa-times cursorPointer ml-4 ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.props.closeSidePanel()}></i>
						</div>
					</div>
					<div className={`${!this.state.showLoading ? 'overflow-auto' : ''} p-2`}>
						<div className="containerBorderFilterSection overflowYAuto" style={{minHeight: this.state.minMaxHeight, maxHeight: this.state.minMaxHeight}}>
							{this.state.createErrorMessage && this.state.createErrorMessage !== "" ?
								<div className="p-3">
									<p  className="text-danger">{this.state.createErrorMessage}</p>
								</div>
							: null}
							{this.state.showBudgetScreen !== "manage" ?
								<div className="p-3 rounded bg-dark">
									<div className="d-flex justify-content-between">
										{/* <h5 className="mb-0 cursorPointer d-flex" onClick={() => this.setState({ showDetails: !this.state.showDetails })}>Create Budget</h5> */}
										
										{/* <span className={`ml-4 fa fa-angle-${this.state.showDetails ? "up" : "down"} f22 text-primary-color cursorPointer`} onClick={() => this.setState({ showDetails: !this.state.showDetails })}></span> */}
									</div>
									<div className=''>
										<div className="d-flex mb-3">
											
											<div className="py-1 w-33">
												<div className='d-flex justify-content-between'>
													<p className="b-block mb-0">Source</p>
													{this.state.hasError && !this.state.selectedSource ?
														<span className='text-danger align-self-center'>required</span>
													: null}
												</div>
												<p className="b-block mb-0"></p>
												<p className="mb-0 text-white">
													{this.state.editMode ?
													    this.state.selectedSource
													:
													   <Select
														    placeholder={'Select'}
														    isSearchable={true}
														    // menuIsOpen={true}
														    className={`selectOption w-80 f13 p-0`}
														    components={{
															    IndicatorSeparator: () => null
														    }}
														    isDisabled={this.state.editMode}
														    value={({
															    value: this.state.selectedSource,
															    label: this.state.selectedSource ? capitalizeFirstLetter(this.state.selectedSource, this.props.accounts) : <span className="placeholder">Select</span>
														    })}
														    options={this.state.costSource && Object.keys(this.state.costSource).map(row => ({
															    value: row,
															    label: capitalizeFirstLetter(row),
														    }))}
														    onChange={event => this.setState({ selectedSource: event.value })}
													    />
													}
												</p>
											</div>
											<div className="py-1 w-33 pl-3">
												<div className='d-flex justify-content-between'>
													<p className="b-block mb-0">Budget Name</p>
													{this.state.hasError && !this.state.budget_name ?
														<span className='text-danger align-self-center'>required</span>
													: null}
												</div>
												<p className="mb-0 text-white">
												   {this.state.editMode ?
													    this.state.budget_name
													:
													<Input
														type="text"
														placeholder="Enter name"
														className={`inputTextField w-80`}
														// maxlength="128"
														value={this.state.budget_name ? this.state.budget_name : ""}
														onChange={e => this.handleInputChange("budget_name", e.target.value)}
													/>
													}
												</p>
											</div>
											<div className="py-1 w-33 pl-3">
												<div className='d-flex justify-content-between'>
													<p className="b-block mb-0">Period</p>
													{this.state.hasError && !this.state.selectedPeriod ?
														<span className='text-danger align-self-center'>required</span>
													: null}
												</div>
												<p className="b-block mb-0"></p>
												{!this.state.editMode ?
												<p className="mb-0 text-white">
													<div className={`multiSelectOption align-self-center mr-2`}>
														<ReactMultiSelectCheckboxes
															placeholderButtonLabel="Select"
															isDisabled={this.state.editMode}
															getDropdownButtonLabel={() => this.getMultiSelectedCount("selectedPeriod", this.state.selectedPeriod ? this.state.selectedPeriod : [])}
															options={this.state.periods && this.state.periods.map(row => ({
																value: row.value,
																label: row.label,
															}))}
															onChange={arr => { this.handleMultiSelectChange("selectedPeriod", arr ? arr : [], this.state.periods) }}
															value={this.state.selectedPeriod && this.state.selectedPeriod.map(row => ({
																value: row
															}))}
														/>
													</div>
												</p>
												: null}
												
												{this.state["selectedPeriod"] && this.state["selectedPeriod"].length ? 
													this.state["selectedPeriod"].filter(e => e.value !== "All").map(item => {
													return(
														<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{item}
														    {!this.state.editMode ?
															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeItem("selectedPeriod", item) } ></i>
															: null}
														</span>
													)
												    })
												: null}
											</div>
										</div>
										{this.state.selectedPeriod && this.state.selectedPeriod.map((period, index) => {
											return(
												<div className='bg-muted p-3 rounded mb-2'>
													<h6 className=''>{this.state.periods.filter(e => e.value === period) && this.state.periods.filter(e => e.value === period).length ? this.state.periods.filter(e => e.value === period)[0].label : ""}</h6>
													<div className='d-flex mb-2'>
														<div className="py-1 w-33">
															<div className='d-flex justify-content-between'>
																<p className="b-block mb-0">Budget Amount</p>
																{this.state.hasError && !this.state["budget_amount_"+period] ?
																	<span className='text-danger align-self-center'>required</span>
																: null}
															</div>
															<p className="mb-0 text-white">
																<Input
																	type="text"
																	placeholder="Enter amount"
																	className={`inputTextField w-80`}
																	// maxlength="128"
																	value={this.state["budget_amount_"+period] ? this.state["budget_amount_"+period] : ""}
																	onChange={e => this.handleInputChange(["budget_amount_"+period], e.target.value)}
																/>
															</p>
														</div>
													</div>
													
													{this.state["period_array_"+period] && this.state["period_array_"+period].length ?
														<div className='bg-dark3 rounded p-3 mb-3'>
															<h5 className='mb-0 text-purple3 cursorPointer d-flex'>Applies To</h5>
															{this.state["period_array_"+period].map(arrIndex => {
																return(
																	<div className={`d-flex ${arrIndex ? "mt-1 pt-1 border-top":  ""}`}>
																		<div className="py-1 w-20">
																			<div className='d-flex justify-content-between'>
																				<p className="b-block mb-0">Account</p>
																				{this.state.hasError && !this.state["selectedAccount_"+index+"_"+arrIndex] ?
																					<span className='text-danger align-self-center'>required</span>
																				: null}
																			</div>
																			<p className="mb-0 text-white">
																			    {this.state.editMode ?
																                    this.state["selectedAccount_"+index+"_"+arrIndex]
																				:
																				<Select
																					placeholder={'Select'}
																					isSearchable={true}
																					// menuIsOpen={true}
																					className={`selectOption w-80 f13 p-0`}
																					components={{
																						IndicatorSeparator: () => null
																					}}
																					isDisabled={this.state.editMode}
																					value={({
																						value: this.state["selectedAccount_"+index+"_"+arrIndex],
																						label: this.state["selectedAccount_"+index+"_"+arrIndex] ? getAccountNameFromId(this.state["selectedAccount_"+index+"_"+arrIndex], this.props.accounts) : <span className="placeholder">Select</span>
																					})}
																					options={this.props.accounts && this.props.accounts.map(row => ({
																						value: row.account_id,
																						label: row.account_name
																					}))}
																					onChange={event => this.setState({ 
																						["selectedAccount_"+index+"_"+arrIndex]: event.value }	
																					)}
																				/>
																				}
																			</p>
																		</div>
																		{this.state["selectedAccount_"+index+"_"+arrIndex] ?
																		<React.Fragment>
																		<div className="py-1 w-20 pl-3 mr-2">
																			<p className="b-block mb-0">Region</p>
																			
																			{!this.state.editMode ?
																			<p className="mb-0 text-white">
																				<div className={`multiSelectOption align-self-center mr-2 w-80`}>
																					<ReactMultiSelectCheckboxes
																						placeholderButtonLabel="Select"
																						isDisabled={this.state.editMode}
																						getDropdownButtonLabel={() => this.getMultiSelectedCount(this.state["selectedRegion_"+index+"_"+arrIndex], this.state["selectedRegion_"+index+"_"+arrIndex] ? this.state["selectedRegion_"+index+"_"+arrIndex] : [])}
																						options={this.props.regions && this.props.regions.map(row => ({
																							value: row.region,
																							label: row.name,
																						}))}
																						onChange={arr => { this.handleMultiSelectChange("selectedRegion_"+index+"_"+arrIndex, arr ? arr : [], this.props.regions) }}
																						value={this.state["selectedRegion_"+index+"_"+arrIndex] && this.state["selectedRegion_"+index+"_"+arrIndex].map(row => ({
																							value: row
																						}))}
																					/>
																				</div>
																			</p>
																			: null}
																			{this.state["selectedRegion_"+index+"_"+arrIndex] && this.state["selectedRegion_"+index+"_"+arrIndex].length ? 
																				this.state["selectedRegion_"+index+"_"+arrIndex].filter(e => e.value !== "All").map(reg => {
																					return(
																						<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{getRegionName(reg, this.props.regions)}
																						{!this.state.editMode ?
																							<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeRegion(reg, index, arrIndex) } ></i>
																						: null}
																						</span>
																					)
																				})
																			: null}
																		</div>
																		<div className="py-1 w-50 pl-3 ml-4">
																			<p className="b-block mb-0">Tags</p>
																			
																			{!this.state.editMode ?
																			<div className="d-flex mb-1">
																				<Select
																					isSearchable={true}
																					components={{
																						IndicatorSeparator: () => null
																					}}
																					isDisabled={this.state.editMode}
																					className={`selectOption mr-2 w-80`}
																					value={({
																						value: this.state["tag_key_"+index+"_"+arrIndex],
																						label: this.state["tag_key_"+index+"_"+arrIndex] && this.state["tag_key_"+index+"_"+arrIndex] !== "" ? this.state["tag_key_"+index+"_"+arrIndex] : <span className="placeholder">Key</span>
																					})}														
																					
																					options={this.props.tagKeys && this.props.tagKeys.map(item => ({
																						value: item,
																						label: item,	
																					}))}
																					onChange={event => this.setState({
																						["tag_key_"+index+"_"+arrIndex]: event.value,
																						["tagValues_"+index+"_"+arrIndex]: [],
																					},
																						() => this.getAllTagsValues(index, arrIndex)
																					)}
																				/>
																				<div className={`multiSelectOption align-self-center mr-2 w-50`}>
																					<ReactMultiSelectCheckboxes
																						placeholderButtonLabel="Value"
																						isDisabled={this.state.editMode}
																						getDropdownButtonLabel={() => this.getMultiSelectedCount(this.state["tag_value_"+index+"_"+arrIndex], this.state["tag_value_"+index+"_"+arrIndex] ? this.state["tag_value_"+index+"_"+arrIndex] : [])}
																						options={this.state["tagValues_"+index+"_"+arrIndex] && this.state["tagValues_"+index+"_"+arrIndex].map(row => ({
																							value: row,
																							label: row,
																						}))}
																						onChange={arr => { this.handleMultiSelectChange("tag_value_"+index+"_"+arrIndex, arr ? arr : [], this.state["tagValues_"+index+"_"+arrIndex]) }}
																						value={this.state["tag_value_"+index+"_"+arrIndex] && this.state["tag_value_"+index+"_"+arrIndex].map(acc => ({
																							value: acc
																						}))}
																					/>
																				</div>
																				<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-center mr-4`} onClick={() => this.addNewTag(index, arrIndex)}></span>
																			</div>
																			: null}
																			{this.state["selected_tags_"+index+"_"+arrIndex] && this.state["selected_tags_"+index+"_"+arrIndex].length ? 
																				this.state["selected_tags_"+index+"_"+arrIndex].filter(e => e.value !== "All").map((tag, index) => {
																					return(
																						<React.Fragment>
																						<div className='d-flex flex-wrap'>
																							<span className="d-flex badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2 breakWhiteSpace" id={"tag_"+index}>
																								<div className='hiddenEllipses'  style={{maxWidth: "250px"}}>
																									{tag.key +" : "+tag.value}
																								</div>
																								{this.state.editMode ?
																									<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag, index, arrIndex) } ></i>
																								:null}
																							</span>
																						</div>
																						
																						<UncontrolledPopover className="longData" trigger="hover" placement="top" target={`tag_`+index}>
																							<PopoverBody>
																								<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																								</span>
																							</PopoverBody>
																						</UncontrolledPopover>
																					</React.Fragment>
																					)
																				})
																			: null}
																		</div>
																		</React.Fragment>
																		: null}
																	</div>
																)
															})}
															<div className='d-flex'>
																{!this.state.editMode ?
																<span className={`align-self-center mr-2 cursorPoitner text-info`} onClick={() => this.addFilterArray(period, index)} >
																	<i className={`far fa-plus f16 mt-2 mb-1 align-self-end mr-2`}></i>Add
																</span>
																: null}
																{this.state.hasError && this.state["isDuplicate_"+period] ?
																	<span className='m-4 text-danger align-self-center'>Duplicate in applies to</span>
																: null}
															</div>
														</div>
													: null}
													
												</div>
											)
										})}
									</div>
									<div className='mt-4'>
										<button className={`btn btn-primary mr-2 ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.setState({ onSaveFunction: true }, () => this.validateInput())} >
											{this.state.onSaveFunction ? 
												<Spinner size='sm' color='light' className='mx-2' /> 
											: null}
											{this.state.showBudgetScreen === "new" ?  "Save"  : "Update"}
										</button>
										<button className={`btn btn-secondary ${this.state.onSaveFunction ? "disbaled" : ""}`} onClick={() => this.setState({ showBudgetScreen: "manage" }, () => this.resetState())}>Cancel</button>
									</div>
								</div>
							: 
								<div className="p-3 rounded bg-dark">
									<div className="d-flex justify-content-between">
										<p className="f12 m-0 align-self-center pt-1 w-50">Showing {this.state.filterListBudgets && this.state.filterListBudgets.length} of total {this.state.listBudgets && this.state.listBudgets.length} budget(s)</p>
										{this.state.listBudgets && this.state.listBudgets.length ?
											<Search
												data={this.state.listBudgets ? this.state.listBudgets : []}
												applyTags={false}
												applyLiteDarkTags={true}
												topClassName={'bg-black5 align-self-center w-25 mr-1 border-gray5 rounded-5 f12'}
												searchClassName={'px-2 f12'}
												searchIconColor={'text-gray5 f12'}
												searchPlaceHolder={'Search....'}
												className={"bg-transparent text-white pl-0 form-control-sm f12"}
												filteredData={(filterListBudgets) => this.setState({ filterListBudgets })}
											/>
										: null}
									</div>
									{this.state.filterListBudgets ? 
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: 'Date',
													accessor: d => d.modified_at ? "modified_at" : 'created_at',
													Cell: cellInfo => (
													<span>
														{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
													</span>
													),
													width: 200
												},
												{
													Header: 'Name',
													accessor: 'budget_name',
													width: 200,
												},
												{
													Header: 'Source',
													accessor: 'source',
													width: 100,
												},
												{
													Header: 'Period/Budget',
													// accessor: d=>d.budget,
													Cell: cellInfo => (
														cellInfo.row.original.budget_details && cellInfo.row.original.budget_details.map(row => {
															return(
																<small className={`mr-1 badge badge-outline-info text-white`}>{row.period+": "+row.budget}</small>
															)
														})
													),
													width: 350
												},
												{
													Header: 'Details',
													// accessor: d=>d.budget_det',
													Cell: cellInfo => (
														<div className='d-flex flex-wrap'>
															<small className={`mr-1 badge badge-outline-info text-white`}><span className="mr-1">Account:</span>{getAccountNameFromId(cellInfo.row.original.account_id, this.props.accounts)}</small>
															{cellInfo.row.original.regions && cellInfo.row.original.regions.length ?
															    <React.Fragment>
																<small className={`mr-1 badge badge-outline-info text-white`}><span className="mr-1">Region:</span>{getRegionName(cellInfo.row.original.region[0], this.props.regions)}</small>
																{cellInfo.row.original.regions && cellInfo.row.original.regions.length && cellInfo.row.original.regions.length > 1 ?
																   <span className='mx-1 text-info'>more</span>
																: null}
																</React.Fragment>
															: null}
															{cellInfo.row.original.tags && cellInfo.row.original.tags.length ?
															    <React.Fragment>
																<small className={`mr-1 badge badge-outline-info text-white hiddenEllipses`} style={{maxWidth: "250px"}}><span className="mr-1">Tag:</span>{cellInfo.row.original.tags[0].key+": "+cellInfo.row.original.tags[0].value}</small>
																{cellInfo.row.original.tags && cellInfo.row.original.tags.length && cellInfo.row.original.tags.length > 1 ?
																	<span className='mx-1 text-info'>more</span>
																 : null}
																 </React.Fragment>
															: null}
														</div>
													),
													width: 500
												},
												{
													Header: 'Actions',
													Cell: cellInfo => (
														<div className="d-flex justify-content-between align-self-center">
															{/* <p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fal fa-book-open"></i></p> */}
															<p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fas fa-pencil" onClick={() => this.onClickEditBudget(cellInfo.row.original)}></i></p>
															{/* <p className="text-white m-0 p-0 mr-1 cursorPointer"><i className="fal fa-trash"></i></p> 
															<div className="toggleOnOffSwitchTable d-flex mr-1 align-self-center" onClick={() => this.automaiotnUpdateStatus(cellInfo.row.original)}>
																<label className="switch mb-0" id={cellInfo.row.original.processor_rule_id} >
																	<input type="checkbox" 
																		className="inputdemo" 
																		checked={this.state['enabled_'+cellInfo.row.original.processor_rule_id] ? (this.state['enabled_'+cellInfo.row.original.processor_rule_id] === 'Enabled' ? true : false) : cellInfo.row.original.enabled}
																	/>
																	<span className="slider round"></span>
																</label>
															</div> */}
														</div>
													),
													width: 100
												}
											]}
											data={this.state.filterListBudgets ? this.state.filterListBudgets : this.state.filterListBudgets}
											perPage={20}
											dashboard={this.state.filterListBudgets && this.state.filterListBudgets.length ? true : false}
											sortByColumn={'created_date'}
											riskTooltip={[]}
											onClickRow={tableRow => {}}
											// tableSize={}
										/>
									:
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='white' size='lg' />
										</div>
									}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
CreateBudgetPanel.propTypes = {}

const mapStateToProps = state => {
	// console.log('csot budget',state)
	return {
		accounts: state.filters.accounts,
		regions: state.filters.regions && state.filters.regions[0] && state.filters.regions[0].provider_regions ? state.filters.regions[0].provider_regions :[],
		tagKeys: state.filters.tagKeys,
		selectedItem: state.cost.costPropsDetails && state.cost.costPropsDetails.selectedItem ? state.cost.costPropsDetails.selectedItem : {},
	}
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllRegions, 
	getAllTagsKeys,
	getAllTagsValues,
	getBudgetFilters,
	listBudgetDetails,
	insertBudgetDetails,
	updateBudgetDetails,
	getCostFilterValues
})(withRouter(CreateBudgetPanel))