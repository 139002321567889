/*************************************************
 * Tvastar
 * @exports
 * @file LogsAndEvents.js
 * @author Rajasekar // on 20/02/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class LogsAndEvents extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className="box p-3 bg-dark3">
				<div className="d-flex">
					<p className="flex-grow-1 mb-2">
						<b className="text-white">Logs and Events</b>
					</p>
				</div>
				<div>
					{this.props.name ? (
						<p className="text-muted mb-2">
							Log Name: <b className="text-white">{this.props.name}</b>
						</p>
					) : null}
					{this.props.dbInstanceAutoMinorVersionUpgrade ? (
						<p className="text-muted mb-0">
							Auto minor version upgrade: <b className="text-white">{this.props.dbInstanceAutoMinorVersionUpgrade}</b>
						</p>
					) : null}
					{this.props.dbInstancePreferredMaintenanceWindow ? (
						<p className="text-muted mb-0">
							Maint.window: <b className="text-white">{this.props.dbInstancePreferredMaintenanceWindow}</b>
						</p>
					) : null}
					{this.props.modifications ? (
						<p className="text-muted mb-2">
							Pending Modifications: <b className="text-white">{this.props.modifications && this.props.modifications.dBInstanceClass ? this.props.modifications.dBInstanceClass : ""}</b>
						</p>
					) : null}
				</div>
				{this.props.logDetails && this.props.logDetails.length
					? this.props.logDetails.map((item, i) => {
							return (
								<div className="bordered-tile" key={i}>
									<div className="flex-grow-1">
										<p className="mb-2">{item.logFileName}</p>
										<p className="mb-0">{item.lastWritten}</p>
									</div>
									<div className="align-self-center">
										<p className="mb-0">
											<span className="badge badge-secondary">{item.size}</span>
										</p>
									</div>
								</div>
							)
					  })
					: null}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
LogsAndEvents.propTypes = {
	dbInstanceAutoMinorVersionUpgrade: PropTypes.string,
	dbInstancePreferredMaintenanceWindow: PropTypes.string,
	modifications: PropTypes.string,
	logDetails: PropTypes.array,
	name: PropTypes.string,
}

export default LogsAndEvents
