/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Dashboard.js
 * @author Rajasekar // on 02/03/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listAllProviders } from '../../actions/commonAction'
import {
	listUserRules,
} from '../../actions/governance/governanceAction'
import { PRIORITY_FILTER_OPTIONS } from '../../utils/constants'
import { AppWrapper } from '../common/AppWrapper'
import CheckboxTable from '../designComponents/Table/CheckboxTable'
import Search from '../common/SearchComponent'
import moment from 'moment'
import { momentDateTime, momentDateGivenFormat, capitalizeFirstLetter, getPastDays } from '../../utils/utility'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import Chart from 'react-apexcharts'
import ApexBarLineBrush from '../common/charts/ApexBarLineBrush'
import SreMetricDetails from '../aiops/diagnostics/SreMetricDetails'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

const dataOne = [
  ['2021-01-01 11:00:00',0],
  ['2021-01-01 11:00:20',1],
  ['2021-01-01 11:00:50',2],
  ['2021-01-01 12:25:00',4],
  ['2021-01-01 12:26:00',3],
  ['2021-01-01 12:27:00',4],
  ['2021-01-01 12:28:00',5],
  ['2021-01-01 12:29:00',4],
  ['2021-01-01 12:30:00',1],
  ['2021-01-01 12:32:00',4],
  ['2021-01-01 12:40:00',2]
]

const today = moment();
const disableFutureDt = current => {
  return current.isBefore(today)
}

class ResizeTable extends Component {
  constructor(props) {
    

		super(props)
		this.providerRef = React.createRef()
    this.annotationXTypeRef = React.createRef()
    this.annotationYTypeRef = React.createRef()

		this.state = {
			showLoading: false,
      
      
			providers: [],			
			isProviderOpen: false,
			selectedProvider: '',
      
      isPriorityOpen: false,
      selectedPriority: [],

      filteredArray: [],

      selectedFilterPeriod: '1 Month',
      filterSelectionPeriod: 7,
      filterOptionType: 'day',
      startDate: '',
      endDate: '',
      showFilerSection: false,
    }
	}
  
  handleStartTimeChange  = this.handleStartTimeChange.bind(this)
  handleEndTimeChange    = this.handleEndTimeChange.bind(this)

	componentDidMount = () => {
		this.setState({ showLoading: true })
		if(!this.props.providers || !this.props.providers.length) {
			this.props.listAllProviders(promise => {
				if (promise) {
					let providers = this.props.providers
					this.setState({ providers, showLoading: true, selectedProvider: providers[0].provider_name },
						() => {
							this.filterWorkPage('provider')
						}
					)
				} else {
					this.filterWorkPage()
				}
			})
		} else {
			let providers = this.props.providers.filter(arr => arr.provider_name !== 'All')
			this.setState({ providers, showLoading: true, selectedProvider: providers[0].provider_name },
				() => {
					this.filterWorkPage('provider')
				}
			)
    }		

    this.sparklineGraph()

    //this.scriptChart()

    this.barCharts()

    this.syncCharts()

    this.annotationChart()

    this.annotationChart1()

    this.mixedChart()

    this.brushChartBar()
    // this.brushChartLable()
    this.brushChart()

    //

    this.sliderPeriod()

  }

  sliderPeriod = () => {  

    let dateArray = []
    let month = ''

    let length = 180
    let filterOptionType = 'day'
    let filterSelectionPeriod = 0

    if(this.state.selectedFilterPeriod === '1 Month') {
      filterSelectionPeriod = 30

      dateArray = []
      month = ''
      for(let x=1;x<=length;x++) {
        let date = momentDateGivenFormat(this.addDays(length-x),'YYYY-MM-DD')
        let value = 5
        if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
          month = momentDateGivenFormat(date, 'MM')
          value = 10
        }
        dateArray.push([date, value])
      }

    } else if(this.state.selectedFilterPeriod === '1 Week') {
      filterSelectionPeriod = 7
      length = 60
      dateArray = []
      month = ''
      for(let x=1;x<=length;x++) {
        let date = momentDateGivenFormat(this.addDays((length-x)),'YYYY-MM-DD')
        for(let y=0;y<3;y++) {
          let dateHour = momentDateGivenFormat(this.addHour(date, y*8 ),'YYYY-MM-DD HH:mm')
          let value = 5
          if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
            month = momentDateGivenFormat(date, 'MM')
            value = 10
          }
          if(new Date(dateHour).getTime() < new Date().getTime()) {
            dateArray.push([dateHour, value])
          }
        }
      }
    } else if(this.state.selectedFilterPeriod === '1 Day') {
      length = 15
      filterSelectionPeriod = 24
      filterOptionType = 'hour'
      
      dateArray = []
      month = ''
      for(let x=1;x<=length;x++) {
        let date = momentDateGivenFormat(this.addDays((length-x)),'YYYY-MM-DD')
        for(let y=0;y<12;y++) {
          let dateHour = momentDateGivenFormat(this.addHour(date, y*2),'YYYY-MM-DD HH:mm')
          let value = 5
          if(month === '' || month !== momentDateGivenFormat(date, 'DD')) {
            month = momentDateGivenFormat(date, 'DD')
            value = 10
          }
          if(new Date(dateHour).getTime() < new Date().getTime()) {
            dateArray.push([dateHour, value])
          }
        }
      }
    } else if(this.state.selectedFilterPeriod === '6 H') {
      length = 7
      filterOptionType = 'hour'
      filterSelectionPeriod = 6

      dateArray = []
      month = ''
      for(let x=1;x<=length;x++) {
        let date = momentDateGivenFormat(this.addDays((length-x)),'YYYY-MM-DD')
        for(let y=0;y<24;y++) {
          let dateHour = momentDateGivenFormat(this.addHour(date, y ),'YYYY-MM-DD HH:mm')
          let value = 5
          if(momentDateGivenFormat(dateHour, 'HH') === '00') {
            value = 10
          }
          if(new Date(dateHour).getTime() < new Date().getTime()) {
            dateArray.push([dateHour, value])
          }
        }
      }
    } else if(this.state.selectedFilterPeriod === '1 H') {
      length = 2
      filterOptionType = 'hour'
      filterSelectionPeriod = 1

      dateArray = []
      month = ''
      for(let x=1;x<=length;x++) {
        let date = momentDateGivenFormat(this.addDays((length-x)),'YYYY-MM-DD')
        let min = 0
        for(let y=0;y<96;y++) {
          let dateHour = momentDateGivenFormat(this.addHour(date, y/4),'YYYY-MM-DD HH:mm')
          let value = 5
          // console.log('dateHour', dateHour)
          if(momentDateGivenFormat(dateHour, 'mm') === '00' && min === 0) {
            value = 10
            min += 1
          } else {
              min += 1
              if(min === 16) {
                min = 0
              }
          }
          if(new Date(dateHour).getTime() < new Date().getTime()) {
            dateArray.push([dateHour, value])
          }
        }
      }
    }

    // let startDate =  momentDateGivenFormat(this.addDays(length),'YYYY-MM-DD')
    // let endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD')

    let startDate = this.addLastDays(new Date(), filterSelectionPeriod)
    // let columnWidth = '10%'
    if(filterOptionType !== 'day') {
      startDate = this.subHour(new Date(), filterSelectionPeriod)
      // columnWidth = '4%'
    }

    startDate = moment(startDate).format('YYYY-MM-DD hh:mm A');
    let endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD hh:mm A')

    this.setState({ dateArray, filterOptionType, filterSelectionPeriod, brushSliderSeries: [], startDate, endDate },
      () => this.brushSliderChart()
    )
  }

  sliderPeriod1 = () => {  
    // let startDate = ''
    // let endDate = ''
    let length = 0
    let filterOptionType = 'day'
    let filterSelectionPeriod = 7
    if(this.state.selectedFilterPeriod === '3 Months') {
      // startDate =  momentDateGivenFormat(this.addDays(90),'YYYY-MM-DD')
      // endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD')
      filterOptionType = 'day'
      length = 90
    } else if(this.state.selectedFilterPeriod === '2 Months') {
      // startDate =  momentDateGivenFormat(this.addDays(60),'YYYY-MM-DD')
      // endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD')
      filterOptionType = 'day'
      length = 60
    } else if(this.state.selectedFilterPeriod === '1 Month') {
      // startDate =  momentDateGivenFormat(this.addDays(30),'YYYY-MM-DD')
      // endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD')
      filterOptionType = 'day'
      length = 30
    } else if(this.state.selectedFilterPeriod === '1 Week') {
      // startDate =  momentDateGivenFormat(this.addDays(7),'YYYY-MM-DD')
      // endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD')
      filterOptionType = 'day'
      length = 14
      filterSelectionPeriod = 4
    } else if(this.state.selectedFilterPeriod === '4 Days') {
      // startDate =  momentDateGivenFormat(new Date(4),'YYYY-MM-DD')
      // endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD')
      filterOptionType = 'hour'
      length = 4
      filterSelectionPeriod = 1
    } else if(this.state.selectedFilterPeriod === '1 Day') {
      // startDate =  momentDateGivenFormat(this.subHour(new Date(), 24),'YYYY-MM-DD HH:mm:ss')
      // endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD HH:mm:ss')
      filterOptionType = 'halfHour'
      length = 48
      filterSelectionPeriod = 3
    } else if(this.state.selectedFilterPeriod === '6 H') {
      // startDate =  momentDateGivenFormat(this.subHour(new Date(), 6),'YYYY-MM-DD HH:mm:ss')
      // endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD HH:mm:ss')
      filterOptionType = 'quaterHour'
      length = 48
      filterSelectionPeriod = 2
    } else if(this.state.selectedFilterPeriod === '1 H') {
      // startDate =  momentDateGivenFormat(this.subHour(new Date(), 1),'YYYY-MM-DD HH:mm:ss')
      // endDate = momentDateGivenFormat(new Date(),'YYYY-MM-DD HH:mm:ss')
      filterOptionType = 'minute'
      length = 36
      filterSelectionPeriod = .5
    }    

    let dateArray = []
    let month = ''
    if(filterOptionType === 'day') {
      for(let x=1;x<90+1;x++) {
        let date = momentDateGivenFormat(this.addDays(length-x),'YYYY-MM-DD')
        let value = 5
        if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
          month = momentDateGivenFormat(date, 'MM')
          value = 10
        }
        dateArray.push([date, value])
      }
    } else if(filterOptionType === 'halfHour') {
      for(let x=1;x<length+1;x++) {
        let date = momentDateGivenFormat(this.subHour(new Date(), (length-x)/2),'YYYY-MM-DD HH:mm:ss')
        let value = 5
        if(momentDateGivenFormat(date, 'mm') === '00') {
          value = 10
        }
        // if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
        //   month = momentDateGivenFormat(date, 'MM')
        //   value = 10
        // }
        dateArray.push([date, value])
      }
    } else if(filterOptionType === 'quaterHour') {
      for(let x=1;x<length+1;x++) {
        let date = momentDateGivenFormat(this.subHour(new Date(), (length-x)/4),'YYYY-MM-DD HH:mm:ss')
        let value = 5
        if(momentDateGivenFormat(date, 'mm') === '00') {
          value = 10
        }
        // if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
        //   month = momentDateGivenFormat(date, 'MM')
        //   value = 10
        // }
        dateArray.push([date, value])
      }
    } else {
      for(let x=1;x<length+1;x++) {
        let date = momentDateGivenFormat(this.subHour(new Date(), (length-x)/12),'YYYY-MM-DD HH:mm:ss')

        let value = 5
        if(momentDateGivenFormat(date, 'mm') === '00') {
          value = 10
        }

        // if(month === '' || month !== momentDateGivenFormat(date, 'MM')) {
        //   month = momentDateGivenFormat(date, 'MM')
        //   value = 10
        // }
        dateArray.push([date, value])
      }
    }

    this.setState({ dateArray, filterOptionType, filterSelectionPeriod, brushSliderSeries: []  },
      () => this.brushSliderChart()
    )
  }

  brushChartLable = () => {
    

    let brushLineOptionsXaxis = {
      plotOptions: {
        bar: {
          barHeight: '0%',
          columnWidth: '40%'
        },          
      },
      chart: {
        id: 'chartXaxis',
        type: 'bar',
        height: 230,
        sparkline: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        redrawOnParentResize: true
      },
      // colors: ['#546E7A'],
      colors: ['#ffffff'],
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      xaxis: {
        // tickAmount: 7,
        type: 'datetime',
        // style: {
        //   fontSize: '8px',
        // },
        labels: {
          datetimeUTC: false,
          formatter: function (value) {
            return moment(value).format('HH:mm:ss')
          }
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        show: false,
      },      
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20
      }, 
      }
    }

    this.setState({ brushLineOptionsXaxis })
  }

  brushChartBar = () => {
    let dataOneLength = dataOne.length
    // let sliceFrom = dataOneLength-15
    let start =  dataOneLength-parseInt(dataOneLength/4)
    let end = dataOne.length
    // let startTime = 0;
    // let endTime = 0
    let dataExist = 0;
    if(this.state.startSlice && this.state.endSlice) {
      dataOne.forEach((item, index) => {
        
        // let startsliceTime = new Date(this.state.startSlice).getTime()
        // let endsliceTime = new Date(this.state.endSlice).getTime()
        // let dateLoop = new Date(item[0]).getTime()
        // let startDiff = startsliceTime - dateLoop
        
        // if(startTime === 0 || (startDiff > startTime && startDiff > 0)) {
        //     startTime = startDiff
        //     start =  index
        // }
        // let endDiff = endsliceTime - dateLoop
        // if(endTime === 0 || (endDiff > endTime && endDiff > 0)) {
        //     endTime = endDiff
        //     end = index + 1
        // }

        

       
        if(item[0] > this.state.startSlice && item[0] < this.state.endSlice) {
          dataExist += 1
          var temp1 = dataOne.map(d => Math.abs(new Date(d[0]).getTime() - new Date(this.state.startSlice).getTime()));
          var idx1 = temp1.indexOf(Math.min(...temp1));
  
          var temp2 = dataOne.map(d => Math.abs(new Date(d[0]).getTime() - new Date(this.state.endSlice).getTime()));
          var idx2 = temp2.indexOf(Math.min(...temp2));

          if(dataExist === 1) {
            start =  idx1 ? idx1 + 1 : idx1
          }
          if((dataOneLength - 1) === idx2) {
            end  = idx2 + 1
          } else {
            end  = idx2
          }
          if(idx1 === idx2) {
            if((dataOneLength - 1) === idx2) {
              end  = idx2 + 1
            } else {
              end  = idx2
            }
          }
          // alert(dataExist)
        } 
        
        if(!dataExist) {
          start = dataOneLength + 1
          end = dataOneLength + 1
        }


        // console.log('startsliceTime', startsliceTime)
        // console.log('endsliceTime', endsliceTime)
        // console.log('dateLoop', dateLoop)
        // console.log('item[0]', item[0])
        // console.log('this.state.startSlice', this.state.startSlice)
        // console.log('start', start)
        // console.log('this.state.endSlice', this.state.endSlice)
        // console.log('end', end)
        // console.log('---------------------------------------------------------------------------------------')

       
        
        // console.log('start', start)
        // console.log('end', end)
        // if(this.state.startSlice === item[0]) {
        //   start = index
        // }
        // if(this.state.endSlice === item[0]) {
        //   end = index + 1
        // }
      })
    }
    
    let columnWidth = '20%'
    if(dataExist <= 5) {
      columnWidth = '1%'
    } else if(dataExist <= 8) {
      columnWidth = '3%'
    } else if(dataExist <= 12) {
      columnWidth = '4%'
    }
    // console.log(start)
    // console.log(end)
    
    let sliceData = dataOne.slice(start, end)
    // console.log('sliceData', sliceData)
    let brushLineSeries = [{
      data: sliceData
    }]

    let brushLineOptionsXaxis = {
      plotOptions: {
        bar: {
          barHeight: '0%',
          columnWidth: '40%'
        },          
      },
      chart: {
        id: 'chartXaxis',
        type: 'bar',
        height: 230,
        sparkline: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        redrawOnParentResize: true
      },
      // colors: ['#546E7A'],
      colors: ['#ffffff'],
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      xaxis: {
        // tickAmount: 7,
        type: 'datetime',
        // style: {
        //   fontSize: '8px',
        // },
        labels: {
          datetimeUTC: false,
          formatter: function (value) {
            // console.log(value)
            //return value
            return moment(value).format('HH:mm:ss')
          }
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        show: false,
      },      
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20
      }, 
      }
    }

    let brushLineOptions = {
      plotOptions: {
        bar: {
          barHeight: '100%',
          columnWidth: columnWidth
        },          
      },
      chart: {
        id: 'chart2',
        //group: 'barBrushChart',
        type: 'bar',
        height: 230,
        sparkline: {
          enabled: true
        },
        events: {
          dataPointSelection: function(event, chartContext, config) {
            // console.log(event)
            // console.log(chartContext)
            // console.log(config)
          }
        },
        redrawOnParentResize: true
      },
      // colors: ['#546E7A'],
      colors: [function({ value, seriesIndex, w }) {
        if (value >= 5) {
            return '#F75E3F'
        } else if (value >= 4) {
            return '#FF6F00'
        } else if (value >= 3) {
          return '#FFD200'
        } else if (value >= 2) {
          return '#693EBC'
        } else {
          return '#00B5D4'
        }
      }, function({ value, seriesIndex, w }) {
        if (value < 111) {
            return '#7E36AF'
        } else {
            return '#D9534F'
        }
      }],
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      xaxis: {
        // labels: {
        //   show: true,
        // },
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
        // position: 'bottom',
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: (seriesName) => seriesName,
          }
        },
        marker: {
          show: false
        },
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          let val = w.globals.initialSeries[0].data[dataPointIndex][1]
          let labelName = moment(w.globals.initialSeries[0].data[dataPointIndex][0]).format('MMM DD HH:mm:ss')

          let color = ''
          if (val >= 5) {
            color = '#F75E3F'
          } else if (val >= 4) {
              color = '#FF6F00'
          } else if (val >= 3) {
            color = '#FFD200'
          } else if (val >= 2) {
            color = '#693EBC'
          } else {
            color = '#00B5D4'
          }
          return '<div class="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ color +'"> '+ val + '</span> </div>'
        }
      }
    }

    let brushLineOptions3 = {
      plotOptions: {
        bar: {
          barHeight: '100%',
          columnWidth: columnWidth
        },          
      },
      chart: {
        id: 'chart2',
        //group: 'barBrushChart',
        type: 'bar',
        height: 230,
        sparkline: {
          enabled: true
        },
        events: {
          dataPointSelection: function(event, chartContext, config) {
            // console.log(event)
            // console.log(chartContext)
            // console.log(config)
          }
        }
      },
      // colors: ['#546E7A'],
      colors: [function({ value, seriesIndex, w }) {
        if (value >= 5) {
            return '#F75E3F'
        } else if (value >= 4) {
            return '#FF6F00'
        } else if (value >= 3) {
          return '#FFD200'
        } else if (value >= 2) {
          return '#693EBC'
        } else {
          return '#00B5D4'
        }
      }, function({ value, seriesIndex, w }) {
        if (value < 111) {
            return '#7E36AF'
        } else {
            return '#D9534F'
        }
      }],
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      xaxis: {
        // labels: {
        //   show: true,
        // },
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
        // position: 'bottom',
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: (seriesName) => seriesName,
          }
        },
        marker: {
          show: false
        },
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          let val = w.globals.initialSeries[0].data[dataPointIndex][1]
          let labelName = moment(w.globals.initialSeries[0].data[dataPointIndex][0]).format('MMM DD HH:mm:ss')

          let color = ''
          if (val >= 5) {
            color = '#F75E3F'
          } else if (val >= 4) {
              color = '#FF6F00'
          } else if (val >= 3) {
            color = '#FFD200'
          } else if (val >= 2) {
            color = '#693EBC'
          } else {
            color = '#00B5D4'
          }
          return '<div class="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ color +'"> '+ val + '</span> </div>'
        }
      }
    }
    
    let brushLineOptions4 = {
      plotOptions: {
        bar: {
          barHeight: '100%',
          columnWidth: columnWidth
        },          
      },
      chart: {
        id: 'chart2',
        //group: 'barBrushChart',
        type: 'bar',
        height: 230,
        sparkline: {
          enabled: true
        },
        events: {
          dataPointSelection: function(event, chartContext, config) {
            // console.log(event)
            // console.log(chartContext)
            // console.log(config)
          }
        }
      },
      // colors: ['#546E7A'],
      colors: [function({ value, seriesIndex, w }) {
        if (value >= 5) {
            return '#F75E3F'
        } else if (value >= 4) {
            return '#FF6F00'
        } else if (value >= 3) {
          return '#FFD200'
        } else if (value >= 2) {
          return '#693EBC'
        } else {
          return '#00B5D4'
        }
      }, function({ value, seriesIndex, w }) {
        if (value < 111) {
            return '#7E36AF'
        } else {
            return '#D9534F'
        }
      }],
      stroke: {
        width: 0
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      xaxis: {
        // labels: {
        //   show: true,
        // },
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
        // position: 'bottom',
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: (seriesName) => seriesName,
          }
        },
        marker: {
          show: false
        },
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          let val = w.globals.initialSeries[0].data[dataPointIndex][1]
          let labelName = moment(w.globals.initialSeries[0].data[dataPointIndex][0]).format('MMM DD HH:mm:ss')

          let color = ''
          if (val >= 5) {
            color = '#F75E3F'
          } else if (val >= 4) {
              color = '#FF6F00'
          } else if (val >= 3) {
            color = '#FFD200'
          } else if (val >= 2) {
            color = '#693EBC'
          } else {
            color = '#00B5D4'
          }
          return '<div class="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ color +'"> '+ val + '</span> </div>'
        }
      }
    }

    this.setState({ brushLineOptionsXaxis, brushLineSeries, brushLineOptions, brushLineOptions3, brushLineOptions4 })
  }

  brushChart = () => {
    let dataOneLength = dataOne.length
    let lastDate = dataOne[dataOneLength-1][0]
    // console.log('lastDate', lastDate)
    
    let brushAreaSeries = [{
      data: dataOne
    }]
    // console.log('data', brushAreaSeries)
    let brushAreaOptions = {
      plotOptions: {
        bar: {
          barHeight: '100%',
          columnWidth: '20%',
          
        },          
      },
      chart: {
        id: 'chart1',
        height: 130,
        type: 'bar',
        brush:{
          target: 'chart2',
          enabled: true
        },
        redrawOnParentResize: true,
        // selection: {
        //   enabled: true,
        //   xaxis: {
        //     min: this.addDays(15),
        //     max: new Date().getTime()
        //   }
        // },
        selection: {
          enabled: true,
          fill: {
            color: "#fff",
            opacity: 0.5
          },  
          xaxis: {
            min: this.subHour(lastDate, 1.2),
            max: new Date(lastDate).getTime()
          }
        },
        events:{
          selection: (chartContext, { xaxis, yaxis }) => {
            // console.log(xaxis);
            var minDate = new Date(xaxis.min);
            var maxDate = new Date(xaxis.max);
            // console.log(minDate, 'minDate')
            // console.log(maxDate, 'maxDate')

            // let startSlice = moment(minDate).format('YYYY-MM-DD');
            // let endSlice = moment(maxDate).format('YYYY-MM-DD');
            // let dateDiff = twoDateDiffrence(endSlice, startSlice)
            // // console.log(dateDiff, 'dateDiff')
            // if(dateDiff === 0) {
              let startSlice = moment(this.subMinute(minDate, 1)).format('YYYY-MM-DD HH:mm:ss');
              let endSlice = moment(this.addMinute(maxDate, 1)).format('YYYY-MM-DD HH:mm:ss');
            // }
            this.setState({ startSlice, endSlice }, 
              () => this.brushChartBar()
            )
            //date.toString("YYYY-MMM-dd"); // "Dec 20"
            // return 
            // onChange
          }
        }
      },
      colors: ['#693EBC'],
      xaxis: {
        type: 'datetime',
        tooltip: {
          enabled: false
        },
        labels: {
          datetimeUTC: false,
          formatter: function (value) {
            // console.log(value)
            //return value
            return moment(value).format('HH:mm:ss')
          }
        },
      },
      yaxis: {
        tickAmount: 2
      }
    }

    this.setState({ brushAreaSeries, brushAreaOptions })
  }

  addHour = (date, hour) => {
    let data = moment(date).add(hour, 'hour')
    return new Date(data).getTime()
    // console.log(data)

  }

  subHour = (lastDate, hour) => {
    let data = moment(lastDate).subtract(hour, 'hour')
    return new Date(data).getTime()
    // console.log(data)

  }

  addMinute = (date, minute) => {
    let data = moment(date).add(minute, 'm')
    return new Date(data).getTime()
  }

  subMinute = (lastDate, hour) => {
    let data = moment(lastDate).subtract(hour, 'm')
    return new Date(data).getTime()
    // console.log(data)

  }

  brushSliderChart = () => {
    // let dataOneLength = this.state.dateArray.length
    // let lastDate = this.state.dateArray[dataOneLength-1][0]
    // console.log('lastDate', lastDate)
    let brushSliderSeries = [{
      data: this.state.dateArray
    }]
    // console.log('brushSliderSeries', brushSliderSeries)
    // let tickAmount = 0
    let min = this.addLastDays(new Date(), this.state.filterSelectionPeriod)
    let columnWidth = '10%'
    if(this.state.filterOptionType !== 'day') {
      min = this.subHour(new Date(), this.state.filterSelectionPeriod)
      columnWidth = '4%'
    }
    let brushSliderOptions = {
      plotOptions: {
        bar: {
          barHeight: '100%',
          columnWidth: columnWidth,
          
        },          
      },
      chart: {
        id: 'chart1',
        height: 130,
        type: 'bar',
        brush:{
          target: 'none',
          enabled: true,
          autoScaleYaxis: false,          
        },
        selection: {
          enabled: true,
          fill: {
            color: "#00A3CB",
            opacity: 0.6
          },  
          stroke: {
            show: false,
            curve: 'smooth',
            lineCap: 'round',
            colors: '#00A3CB',
            width: 0,
            dashArray: 0,      
          },
          xaxis: {
            min: min,
            max: new Date().getTime(),            
          },
          style: {
            fontSize: '28px',
            color: '#000000'
          }
        },
        events:{
          selection: (chartContext, { xaxis, yaxis }) => {
            var minDate = new Date(xaxis.min);
            var maxDate = new Date(xaxis.max);
            let startSlice = moment(minDate).format('YYYY-MM-DD hh:mm A');
            let endSlice = moment(maxDate).format('YYYY-MM-DD hh:mm A');
            this.setState({ startDate: startSlice, endDate: endSlice }, 
              () => this.brushChartBar()
            )
          }
        }
      },
      colors: ['#515151'],
      xaxis: {
        type: 'datetime',
        tooltip: {
          enabled: false
        },
        position: 'top',
        labels: {
          datetimeUTC: false,
          // formatter: function (val, opts) {
          //   // if(this.state.filterOptionType !== 'day') {
          //     return momentDateGivenFormat(val,'YYYY-MM-DD hh:mm a')
          //   // } else {
          //   //   return momentDateGivenFormat(val,'YYYY-MM-DD')
          //   // }
          // }
        },
      },
      yaxis: {
        tickAmount: 2,
        labels: {
          show: false
        },
      },
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
      }
    }
    
    

    this.setState({ brushSliderSeries, brushSliderOptions },
      // () => this.functionTest()
    )
  }

  functionTest = () => {
    var height = document.getElementById("SvgjsRect2908").getAttribute("height")

    var right = document.getElementById('SvgjsRect2909');
    right.setAttribute("height", (height-2))

    var att = document.createAttribute("class");
    att.value = "svg_select_points";
    right.setAttributeNode(att);


    var left = document.getElementById('SvgjsRect2910');
    left.setAttribute("height", (height-2))
    var attLeft = document.createAttribute("class");
    attLeft.value = "svg_select_points";
    left.setAttributeNode(attLeft);

    // console.log('ddddddddddddddd', d)
    // rect[Attributes Style] {
    //     width: 8;
    //     height: 16;
    //     x: -4;
    //     y: 0.5;
    // }

    // d.setAttribute("width", 6)
    // d.setAttribute("y", "0")
    // "translate("+zoomX+","+zoomY+") scale("+zoomScale+")")
    // rect[Attributes Style] {
    //   width: 8;
    //   height: 16;
    //   x: -4;
    //   y: 0.5;
    // }
  }

  addLastDays = (lastDate, days) => {
    var result = moment(lastDate).subtract(days, 'days')
    // console.log('result', result)
    return new Date(result).getTime()
  }

  addDays = (days) => {
    var result = new Date();
    result.setDate(result.getDate() - days);
    return result.getTime();
  }

  addYears = (days) => {
    var result = new Date();
    result.setFullYear( result.getFullYear() - days );
    // result.setDate(result.getYear() - days);
    // console.log('result', result)
    return result.getTime();
  }
  
  mixedChart = () => {
    let mixedSeries = [{
      name: 'High',
      type: 'line',
      data: [18, 11, 22, 27, 13, 22, 37, 22, 30, 22, 30]
    }, {
      name: 'occurnace',
      type: 'area',
      data: [25, 18, 30, 35, 18, 38, 30, 38, 35, 27, 38]
    }, {
      name: 'Low',
      type: 'line',
      data: [30, 25, 36, 40, 30, 45, 50, 30, 45, 36, 45]
    }]
    var points =  [
      {
        x: new Date('31 July 2003').getTime(),
        y: 38,
        marker: {
          size: 6,
          fillColor: "red",
          strokeColor: "red",
          radius: 2
        },
        label: {
          borderColor: "#FF4560",
          offsetY: 0,
          style: {
            color: "#fff",
            background: "#FF4560"
          },

          // text: "Point Annotation (XY)"
        }
      }
    ]

    let mixedOptions = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [1, 3, 1],
        curve: ['smooth', 'straight', 'smooth']
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },
      
      fill: {
        // type: 'none',
        opacity: [0.85, 0, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        },
        // pattern: {
        //   style: 'verticalLines',
        //   width: 6,
        //   height: 6,
        //   strokeWidth: 2,
        // }
      },
      colors: ['#C1C1C1', '#693EBC', '#C1C1C1'],
      legend: {
        show: false,
      },
      labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003',
        '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'
      ],
      markers: {
        size: 0
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      yaxis: {
        // title: {
        //   show: 'false',
        //   text: 'Points',
        // },
        min: 0
      },
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
      
          }
        }
      },
      annotations: {
        points: points
      }
    }

    this.setState({ mixedSeries, mixedOptions })
  }

  generateDayWiseTimeSeries = (baseval, count, yrange) => {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = baseval;
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push([x, y]);
      baseval += 86400000;
      i++;
    }
    return series;
  }

  generateDayWiseYearSeries = (baseval, count, yrange) => {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = baseval;
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push([x, y]);
      baseval += 31536000315 ;
      i++;
    }
    return series;
  }

  barCharts = () => {
    let barChartSeries = [{
      data: this.generateDayWiseTimeSeries(new Date('5 Dec 2020').getTime(), 60, {
        min: 10,
        max: 60
      })
    }]
    
    let barChartOptions = {
      plotOptions: {
        bar: {
          barHeight: '100%',
          columnWidth: '20%',
          distributed: false,
          rangeBarOverlap: false,
          colors: {
            backgroundBarColors: ['#f7f7f7'],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 2,
          }        
        },          
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        //id: 'fb',
        //group: 'social',
        type: 'bar',
        height: 160,
        toolbar: {
          show: false,
        },
        sparkline: {
          //enabled: true
        },
      },
      colors: ['#008FFB'],
      yaxis: {
        tickAmount: 2,
        labels: {
          minWidth: 40
        }
      },
      xaxis: {
        labels: {
          show: false,
          datetimeUTC: false
        },
        tooltip: {
          enabled: false,
        },
        type: 'datetime',
      },
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
      }
    }

    this.setState({ barChartSeries, barChartOptions })

  }

  annotationChart = () => {
    // {high: 10, low: 5, value: 8},
    // {high: 15, low: 8, value: 12},
    // {high: 20, low: 10, value: 15},
    // {high: 25, low: 12, value: 20},
    // {high: 15, low: 10, value: 25},
    // {high: 40, low: 20, value: 25},
    // {high: 35, low: 25, value: 32},
    // {high: 20, low: 10, value: 12},
    // {high: 30, low: 15, value: 20},
    // {high: 50, low: 25, value: 40},
    let annotationSeries = [{
      name: 'Data',
      data: [8,12,15,20,25,25,32,12,20,40]
    }]
    let annotationOptions = {
      chart: {
        type: 'area',
        height: 160,
        toolbar: {
          show: false,
        },
        sparkline: {
          //enabled: true
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          if(val > 30) {
            return ''
          } else {
            return ''
          }
        }
      },
      annotations: {
        // position: 'back',
        yaxis: [
          {
            y: 5,
            y2: 25,
            x: 1,
            x2: 2,
            // borderColor: '#000',
            fillColor: '#3C4253',
            label: {
              text: 'Prediction'
            },
            yAxisIndex: 0,
            opacity: 0.3,
            offsetX: 0,
            offsetY: -3,
          }
          // {
          //   y: 32,
          //   borderColor: 'purple',
          //   label: {
          //     borderColor: 'purple',
          //     style: {
          //       color: '#fff',
          //       background: 'purple'
          //     },
          //     text: 'Y-axis annotation on 32'
          //   }
          // },
          // {
          //   y: 40,
          //   borderColor: 'red',
          //   label: {
          //     borderColor: 'red',
          //     style: {
          //       color: '#fff',
          //       background: 'red'
          //     },
          //     text: 'Y-axis annotation on 40',
          //     align: 'left',
          //   }
          // }
        ],
        points: [
          {
            x: 7,
            y: 32,
            marker: {
              size: 6,
              fillColor: "red",
              strokeColor: "red",
              radius: 2
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560"
              },
    
              // text: "Point Annotation (XY)"
            }
          },
          {
            x: 10,
            y: 40,
            marker: {
              size: 6,
              fillColor: "red",
              strokeColor: "red",
              radius: 2
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560"
              },
    
              // text: "Point Annotation (XY)"
            }
          }
        ]
      },
      colors: ['#00E396'],
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,      
      },
      yaxis: {
        tickAmount: 6,
        min: 0,
        labels: {
            show: true,
            align: 'right',
            minWidth: 40,
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            // formatter: (value) => { return val },
        },
        axisBorder: {
            show: false,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
        tooltip: {
            enabled: false,
            offsetX: 0,
        },
      },
      xaxis: {
        labels: {
          show: false,
          datetimeUTC: false
        },
        tooltip: {
          enabled: false,
        },        
        // type: 'datetime',
      },
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
      }
    }

    this.setState({ annotationSeries, annotationOptions })
  }

  annotationChart1 = () => {
    // {high: 10, low: 5, value: 8},
    // {high: 15, low: 8, value: 12},
    // {high: 20, low: 10, value: 15},
    // {high: 25, low: 12, value: 20},
    // {high: 15, low: 10, value: 25},
    // {high: 40, low: 20, value: 25},
    // {high: 35, low: 25, value: 32},
    // {high: 20, low: 10, value: 12},
    // {high: 30, low: 15, value: 20},
    // {high: 50, low: 25, value: 40},
    let annotationSeries1 = [{
      name: 'Data',
      data: [8,12,15,20,25,25,32,12,20,40]
    }]
    let annotationOptions1 = {
      chart: {
        type: 'area',
        height: 160,
        toolbar: {
          show: false,
        },
        sparkline: {
          //enabled: true
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          if(val > 30) {
            return ''
          } else {
            return ''
          }
        }
      },
      annotations: {
        // position: 'back',
        yaxis: [
          {
            y: 32,
            borderColor: 'purple',
            label: {
              borderColor: 'purple',
              style: {
                color: '#fff',
                background: 'purple'
              },
              text: '32'
            }
          },
          {
            y: 40,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: 'red'
              },
              text: '40',
            }
          }
        ],
        points: [
          {
            x: 7,
            y: 32,
            marker: {
              size: 6,
              fillColor: "red",
              strokeColor: "red",
              radius: 2
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560"
              },
    
              // text: "Point Annotation (XY)"
            }
          },
          {
            x: 10,
            y: 40,
            marker: {
              size: 6,
              fillColor: "red",
              strokeColor: "red",
              radius: 2
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560"
              },
    
              // text: "Point Annotation (XY)"
            }
          }
        ]
      },
      colors: ['#00E396'],
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,      
      },
      yaxis: {
        tickAmount: 6,
        min: 0,
        labels: {
            show: true,
            align: 'right',
            minWidth: 40,
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            // formatter: (value) => { return val },
        },
        axisBorder: {
            show: false,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0
        },
        axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: '#b6b6b6',
                width: 1,
                dashArray: 0,
            },
        },
        tooltip: {
            enabled: false,
            offsetX: 0,
        },
      },
      xaxis: {
        labels: {
          show: false,
          datetimeUTC: false
        },
        tooltip: {
          enabled: false,
        },        
        // type: 'datetime',
      },
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
      }
    }

    this.setState({ annotationSeries1, annotationOptions1 })
  }

  syncCharts = () => {
    let seriesLine1 = [{
      data: this.generateDayWiseTimeSeries(new Date('5 Dec 2020').getTime(), 60, {
        min: 10,
        max: 60
      })
    }]
    
    let optionsLine1 = {
      plotOptions: {
        bar: {
          barHeight: '100%',
          columnWidth: '20%',
          distributed: false,
          rangeBarOverlap: false,
          colors: {
            backgroundBarColors: ['#f7f7f7'],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 2,
          }        
        },          
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        id: 'fb',
        group: 'social',
        type: 'bar',
        height: 160,
        toolbar: {
          show: false,
        },
        sparkline: {
          //enabled: true
        },
      },
      colors: ['#008FFB'],      
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,      
      },
      yaxis: {
        tickAmount: 2,
        labels: {
          minWidth: 40
        }
      },
      xaxis: {
        labels: {
          show: false,
          datetimeUTC: false
        },
        tooltip: {
          enabled: false,
        },
        type: 'datetime',
      },
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
      }
    }

    let seriesLine2 = [{
      data: this.generateDayWiseTimeSeries(new Date('5 Dec 2020').getTime(), 60, {
        min: 10,
        max: 30
      })
    }]

    let optionsLine2 = {
      chart: {
        id: 'tw',
        group: 'social',
        type: 'line',
        height: 160,
        toolbar: {
          show: false,
        },
        sparkline: {
          //enabled: true
        },
      },
      colors: ['#546E7A'],      
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,      
      },
      yaxis: {
        tickAmount: 2,
        labels: {
          minWidth: 40
        }
      },
      xaxis: {
        labels: {
          show: false,
          datetimeUTC: false
        },
        tooltip: {
          enabled: false,
        },
        type: 'datetime',
      },
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
      }
    }

    let seriesArea = [{
      data: this.generateDayWiseTimeSeries(new Date('5 Dec 2020').getTime(), 60, {
        min: 10,
        max: 60
      })
    }]

    let optionsArea = {
      chart: {
        id: 'yt',
        group: 'social',
        type: 'area',
        height: 160,
        toolbar: {
          show: false,
        },
        sparkline: {
          //enabled: true
        },
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#00E396'],
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,      
      },
      yaxis: {
        tickAmount: 2,
        labels: {
          minWidth: 40
        }
      },
      xaxis: {
        labels: {
          show: false,
          datetimeUTC: false
        },
        tooltip: {
          enabled: false,
        },
        type: 'datetime',
      },
      grid: {
        xaxis: {
            lines: {
                show: false
            },
        },
        yaxis: {
            lines: {
                show: false
            }
        },
      }
    }

    this.setState({ seriesLine1, optionsLine1, seriesLine2, optionsLine2, seriesArea, optionsArea })
  }

  sparklineGraph = () => {
    let series = [{
      name: 'Data',
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54, 25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
    }]
    let options = {
      chart: {
        id: 'fb',
        group: 'slider',
        type: 'area',
        width: 100,
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,      
      },
      fill: {
        type: 'none',
        // show: false,
        opacity: 0
      },
      colors: ['#693EBC'],
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: (seriesName) => seriesName,
          }
        },        
        marker: {
          show: false
        }
      }
    }

    let barSeries = [{
      name: 'bar',
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54, 25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
    }]

    let barOptions = {

      chart: {
        type: 'bar',
        width: 100,
        height: 35,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          columnWidth: '20%',
          distributed: false,
          rangeBarOverlap: false,
          colors: {
            backgroundBarColors: ['#f7f7f7'],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 2,
          }        
        },          
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: '50%'
      //   }
      // },
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      xaxis: {
        crosshairs: {
          width: 1
        },
      },      
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: (seriesName) => seriesName,
          }
        },
        marker: {
          show: false
        }
      }
    }

    let anomalyBarSeries = [{
      name: 'Anomaly',
      data: [5, 0, 0, 0, 0, 5, 0, 5, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 5, 0],
      original_data: [25, 0, 0, 0, 0, 25, 0, 12, 0, 0, 0, 25, 0, 0, 0, 0, 0, 0, 0, 0, 9, 0]
    }]

    let anomalyBarOptions = {

      chart: {
        type: 'bar',
        width: 100,
        height: 10,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          // barHeight: '100%',
          columnWidth: '20%',        
        },          
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: '50%'
      //   }
      // },
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      xaxis: {
        crosshairs: {
          width: 1
        },
      },
      colors: ['#F75E3F'],
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: (seriesName) => seriesName,
          }
        },
        marker: {
          show: false
        },
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          let val = w.globals.initialSeries[0].original_data[dataPointIndex]
          let labelName = w.globals.initialSeries[0].name
          return '<div class="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'"> '+ val + '</span> </div>'
        },
      }
    }
    
    this.setState({ series, options, barSeries, barOptions, anomalyBarSeries, anomalyBarOptions })
  }
  
  filterWorkPage = () => {
    this.setState({ showLoading: true, filteredArray: [] })
		let params = {}
		if (this.state.selectedProvider) {
			params.provider = this.state.selectedProvider.toLowerCase()
    }
    
    if (this.state.selectedPriority.length && !this.state.selectedPriority.filter(obj => obj.value === 'All').length) {
			let array = []
			this.state.selectedPriority.forEach(item => {
				array.push(item.value)
			})
			params.priority = array
		}
		
		//params.enabled = true
		this.props.listUserRules(params, () => {
			this.setState({ showLoading: false })
			if (this.props.rules && this.props.rules.length) {
				this.setState({ showLoading: false, filteredArray: this.props.rules })
			}
		})
  }

	handleMultiSelectChange = (childKey, arrayValue) => {
		// let value = arrayValue.map(item => item.value)
		// let label = arrayValue.map(item => item)
		let currentState = arrayValue

		if(childKey === 'selectedPriority') {
			let selectedPriority = arrayValue
			let prevState = this.state.selectedPriority

			// let value = arrayValue.map(item => item.value)
			let label = arrayValue.map(item => item)

			prevState = prevState.filter(obj => obj.value === 'All')
			currentState = arrayValue.filter(obj => obj.value === 'All')
			if(prevState.length && currentState.length) {
				arrayValue = arrayValue.filter(obj => obj.value !== 'All')
				selectedPriority = []
				arrayValue.forEach(item => {
					PRIORITY_FILTER_OPTIONS.forEach(prio => {
						if(prio.value === item.value) {
							label = {}
							label.value = prio.value
							label.label = prio.option
							selectedPriority.push(label)
						}
					})
				})
			} else if(prevState.length && !currentState.length) {
				selectedPriority = []
			} else if(!prevState.length && !currentState.length) {
				selectedPriority = arrayValue
			} else if(!prevState.length && currentState.length) {
				selectedPriority = []
				PRIORITY_FILTER_OPTIONS.forEach(prio => {
					label = {}
					label.value = prio.value
					label.label = prio.option
					selectedPriority.push(label)
				})
				
			}
			this.setState({ selectedPriority }) 	
		} else if(childKey === 'annotationXaxisType') {
      this.setState({ annotatinXaxis: arrayValue, showBarLineBrush: false },
          () => {
              this.setState({ showBarLineBrush: true })
          }    
      )
    } else if(childKey === 'annotationYaxisType') {
      this.setState({ annotationYaxis: arrayValue, showBarLineBrush: false },
          () => {
              this.setState({ showBarLineBrush: true })
          }    
      )
    }
	}

	getMultiSelectedCount = (type, array) => {
		return array.length && array.filter(obj => obj.value === 'All').length ? 'All' :  array.length ? array.length +' Selected' : 'All'
	}

	onSearch = () => {
		// this.filterWorkPage()
	}
	
	onReset = () => {
		this.setState({ 
			selectedProvider: this.state.providers[0].provider_name,
			selectedCategory: [],
			selectedService: [],
			selectedPriority: [],
		})
	}	

	handleClickGroup = (val) => {
		this.setState({ selectedTableDrop: this.state.selectedTableDrop === val ? '' : val })
  }
  
  handleStartTimeChange(date) {
    let startDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
    let endDate = this.state.endDate
    if(new Date(startDate).getTime() > new Date(endDate).getTime() ) {
      endDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD hh:mm A')
    }
    this.setState({ startDate, endDate })
  }

  handleEndTimeChange(date) {
    // console.log(date.format('YYYY-MM-DD hh:mm A'));
    let startDate = this.state.startDate
    let endDate = momentDateGivenFormat(date, 'YYYY-MM-DD hh:mm A')
    if(new Date(endDate).getTime() > new Date(startDate).getTime() ) {
      this.setState({ endDate })
    } 
  }
	
	handleClickOutside(event) {
      //   if (this.annotationXTypeRef && !this.annotationXTypeRef.current.contains(event.target)) {
      //       this.setState({ isannotationXaxisOpen: false })
      //   } else {
      //       this.setState({ isannotationXaxisOpen: true })
      //   }

      //   if (this.annotationYTypeRef && !this.annotationYTypeRef.current.contains(event.target)) {
      //     this.setState({ isannotationYaxisOpen: false })
      // } else {
      //     this.setState({ isannotationYaxisOpen: true })
      // }
    }

	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section'>
							<div className='row'>
								<div className='col-8 align-self-center'>
									<h6 className='text-white m-0'>Work Page</h6>
									<p className='text-white m-0'>
										Manage user created governance rules on your Cloud
									</p>
								</div>
								<div className={`col-4 text-right ${this.state.showLoading ? 'disabled' : ''}`}>
									<Search
										data={this.props.rules && this.props.rules}
										filteredData={filteredArray => {
											this.setState({ filteredArray })
										}}
									/>
								</div>
							</div>
							<div className='row mt-3'>
								<div className={`col-md-12 d-flex flex-wrap justify-content-end bd-highlight ${this.state.showLoading ? 'disabled' : ''}`}>
									<div className={`form-group my-2 minMaxWidth150 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.providerRef}>
										<label>Provider</label>
										<Select
											placeholder={'All'}
											menuIsOpen={this.state.isProviderOpen}
											isSearchable={false}
											//onFocus={this.openProivderMenu}
											className='f13 p-0 colorBlack reactSelectDrop'
											value={({
												value: this.state.selectedProvider ? this.state.selectedProvider : 'Select',
												label: this.state.selectedProvider ? this.state.selectedProvider : 'Select'
											})}
											options={this.state.providers.map(item => ({
												value: item.provider_name,
												label: item.provider_name,	
											}))}
											onChange={event => {
												if(this.props.selectedFilters.provider !==  event.value) {
													this.setState({ selectedProvider: event.value })
													this.filterWorkPage('provider')
												}
											}}
										/>
									</div>
									<div className={`form-group my-2 minMaxWidth350 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3`} ref={this.priorityRef} >
										<label>Priority</label>
										<div className={`filterMultiSelectDropdown`}>
											<ReactMultiSelectCheckboxes						
												placeholderButtonLabel="All"
												getDropdownButtonLabel={() => this.getMultiSelectedCount('priority', this.state.selectedPriority)}
												menuIsOpen ={this.state.isPriorityOpen}
												value={this.state.selectedPriority && this.state.selectedPriority.map(cat => ({
													value: cat.value,
													label: cat.label
												}))}
												onChange={arr => {
												this.handleMultiSelectChange('selectedPriority', arr ? arr : []) }}
												options={PRIORITY_FILTER_OPTIONS.map(item => ({ 
													value: item.value,
													label: item.option,
												}))}
											/>
										</div>
									</div>

									<div className="mt-2 d-flex align-self-center">
										<div className="btn-group" role="group" aria-label="rs-table">
											<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={()=> this.onSearch()}>Search</button>
											<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='box custom-margin'>
              <div className="mt-3 mb-3 col-md-12">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="bg-muted">
                      <ApexBarLineBrush 
                        graphData={'mockData'}
                        brush={true}
                        sparkline={false}
                        yaxis={true}
                        yaxisLabel={true}
                        xaxis={true}
                        xaxisFormat={''}
                        xaxisLabel={true}
                        grid={false}
                        axisLabelColor={'#EDEDED'}
                        legend={false}
                        stacked={false}
                        height={200}
                        horizontal={true}
                        barHeight={'40%'}
                        barEndShape={'rounded'}
                        columnWidth={'50%'}
                        gradient={true}
                        gradientColor={['#039BE5', '#5F5BA2']}
                        hideTooltipValue={true}
                        backgroundBarShape={'rounded'}
                        backgroundBarColors={['#333947']}
                        showBackgroundBarColors={false}
                        className={"transparentTooltip"}
                        annotations={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bg-muted">
                      <div className="d-flex pt-2 pl-3" style={{minHeight: "32px"}}></div>
                      <div className="mt-n3">
                        <SreMetricDetails 
                          graphData={'mockData'}
                          brush={true}
                          sparkline={false}
                          yaxis={true}
                          yaxisLabel={true}
                          xaxis={true}
                          xaxisFormat={''}
                          xaxisLabel={true}
                          grid={false}
                          axisLabelColor={'#EDEDED'}
                          legend={false}
                          stacked={false}
                          height={200}
                          horizontal={true}
                          barHeight={'40%'}
                          barEndShape={'rounded'}
                          columnWidth={'50%'}
                          gradient={true}
                          gradientColor={['#039BE5', '#5F5BA2']}
                          hideTooltipValue={true}
                          backgroundBarShape={'rounded'}
                          backgroundBarColors={['#333947']}
                          showBackgroundBarColors={false}
                          className={"transparentTooltip"}
                          annotations={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row col-md-12 aiopsTopFiltersection">
                    <div className="row col-md-12">
                      <div className="col-md-12 d-flex justify-content-end mb-2">
                        <div className={`bg-filterBlue text-white border align-self-center p-1 mr-1 cursorPointer`} onClick={() => this.setState({ showFilerSection: !this.state.showFilerSection})}>
                          {getPastDays(this.state.startDate, this.state.endDate)}
                        </div>
                        <div>
                          <p className="f11 m-0 p-0">{this.state.startDate}</p>
                          <p className="f11 m-0 p-0">{this.state.endDate}</p>
                        </div>
                      </div>                      
                    </div>
                    <div className={`row col-md-12 ${this.state.showFilerSection ? '' : 'displayNone'}`}>
                      <div className={`col-md-12 d-flex justify-content-end cursorPointer`}>
                        <span className={`mr-2 text-filterBlue font-weight-bold f12 cursorPointer align-self-center displayNone ${this.state.selectedFilterPeriod === '3 Months' ? 'displayNone' : ''} `} onClick={() => this.setState({ selectedFilterPeriod: '3 Months' }, () => this.sliderPeriod())}>3 Months</span>
                        <span className={`mr-2 text-filterBlue font-weight-bold f12 cursorPointer align-self-center displayNone ${this.state.selectedFilterPeriod === '2 Months' ? 'displayNone' : ''} `} onClick={() => this.setState({ selectedFilterPeriod: '2 Months' }, () => this.sliderPeriod())}>2 Months</span>
                        <span className={`mr-2 text-filterBlue font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === '1 Month' ? 'displayNone' : ''} `} onClick={() => this.setState({ selectedFilterPeriod: '1 Month' }, () => this.sliderPeriod())}>1 Month</span>
                        <span className={`mr-2 text-filterBlue font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === '1 Week' ? 'displayNone' : ''} `} onClick={() => this.setState({ selectedFilterPeriod: '1 Week' }, () => this.sliderPeriod())}>1 Week</span>
                        <span className={`mr-2 text-filterBlue font-weight-bold f12 cursorPointer align-self-center displayNone ${this.state.selectedFilterPeriod === '4 Days' ? 'displayNone' : ''} `} onClick={() => this.setState({ selectedFilterPeriod: '4 Days' }, () => this.sliderPeriod())}>4 Days</span>
                        <span className={`mr-2 text-filterBlue font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === '1 Day' ? 'displayNone' : ''} `} onClick={() => this.setState({ selectedFilterPeriod: '1 Day' }, () => this.sliderPeriod())}>1 Day</span>
                        <span className={`mr-2 text-filterBlue font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === '6 H' ? 'displayNone' : ''} `} onClick={() => this.setState({ selectedFilterPeriod: '6 H' }, () => this.sliderPeriod())}>6 H</span>
                        <span className={`mr-2 text-filterBlue font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === '1 H' ? 'displayNone' : ''} `} onClick={() => this.setState({ selectedFilterPeriod: '1 H' }, () => this.sliderPeriod())}>1 H</span>
                        <div className="d-flex">
                          <Datetime 
                            value={momentDateGivenFormat(this.state.startDate, 'DD MMM YYYY hh:mm A')}
                            onChange={this.handleStartTimeChange}
                            dateFormat={'DD MMM YYYY'}
                            timeFormat={'hh:mm A'}
                            inputProps={{ readOnly: true }}
                            className="w-100 mr-3"
                            //maxDate={new Date().getTime()}
                            isValidDate={disableFutureDt}
                          />
                          <Datetime
                            value={momentDateGivenFormat(this.state.endDate, 'DD MMM YYYY hh:mm A')}
                            onChange={this.handleEndTimeChange}
                            dateFormat={'DD MMM YYYY'}
                            timeFormat={'hh:mm A'}
                            inputProps={{ readOnly: true }}
                            className="w-100"
                            //minDate={new Date(this.state.startDate).getTime()}
                            //maxDate={new Date()}
                            isValidDate={disableFutureDt}
                          />
                        </div>
                      </div>
                      {this.state.brushSliderOptions && this.state.brushSliderSeries ?
                        <div className="col-md-12">
                          <Chart options={this.state.brushSliderOptions} series={this.state.brushSliderSeries} type="bar" height={80} />
                        </div>
                      : null}
                    </div>
                  </div>
                  <div className="row col-md-12">
                    {this.state.brushLineOptions && this.state.brushLineSeries ?
                      <div className="col-md-12">
                        <div className="row col-md-12">
                          <table class="table table-bordered table-striped timeLineTable">
                            <thead>
                              <tr>
                                <th></th>
                                <th>First</th>
                                <th>Last</th>
                                <th>
                                  <div className="w-70 m-auto">
                                    {this.state.brushLineOptionsXaxis && this.state.brushLineSeries ?
                                        <Chart options={this.state.brushLineOptionsXaxis} series={this.state.brushLineSeries} type="bar" height={20}/>
                                      :
                                      null
                                    }
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="d-flex"><div className={`risk-time-line bg-danger`}>&nbsp;</div><div className="ml-2">Critical</div></td>
                                <td>EC2 Cpu statys anomaly EC2 Cpu statys anomaly</td>
                                <td>i-191840a423m</td>
                                <td className="w-100">
                                  <div className="w-70 m-auto">
                                    {this.state.brushLineOptions && this.state.brushLineSeries ?
                                      <Chart options={this.state.brushLineOptions} series={this.state.brushLineSeries} type="bar" height={15}/>
                                    :
                                      null
                                    }
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="d-flex"><div className={`risk-time-line bg-warning`}>&nbsp;</div><div className="ml-2">High</div></td>
                                <td>EC2 Cpu statys anomaly anomaly</td>
                                <td>i-1243410a423m</td>
                                <td className="w-100">
                                  <div className="w-70 m-auto">
                                    {this.state.brushLineOptions3 && this.state.brushLineSeries ?
                                      <Chart options={this.state.brushLineOptions3 } series={this.state.brushLineSeries} type="bar" height={15} />
                                    :
                                      null
                                    }
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="d-flex"><div className={`risk-time-line bg-success`} >&nbsp;</div><div className="ml-2">Low</div></td>
                                <td>EC2 Cpu statys anomaly statys anomaly</td>
                                <td>i-98hy6870a423m</td>
                                <td className="w-100">
                                  <div className="w-70 m-auto">
                                    {this.state.brushLineOptions4 && this.state.brushLineSeries ?
                                      <Chart options={this.state.brushLineOptions4} series={this.state.brushLineSeries} type="bar" height={15} />
                                    :
                                      null
                                    }
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <Chart options={this.state.brushAreaOptions} series={this.state.brushAreaSeries} type="bar" height={130} />
                      </div>
                    : null }
                  </div>

                  

                  <div className="col-md-6 align-self-center">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="border p-1">
                          {this.state.anomalyBarOptions && this.state.anomalyBarSeries ?
                            <div className="d-flex cursorPointer mb-2">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.anomalyBarOptions} series={this.state.anomalyBarSeries} type="bar" height={10}/>                    
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.barOptions && this.state.barSeries ?
                            <div className="d-flex cursorPointer">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.barOptions} series={this.state.barSeries} type="bar" height={35}/>                    
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>                    
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>                     
                          :
                            null
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="border p-1">
                          {this.state.anomalyBarOptions && this.state.anomalyBarSeries ?
                            <div className="d-flex cursorPointer mb-2">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.anomalyBarOptions} series={this.state.anomalyBarSeries} type="bar" height={10}/>                    
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.barSeries && this.state.barSeries ?
                            <div className="d-flex cursorPointer">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.barOptions} series={this.state.barSeries} type="bar" height={35}/>                    
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>                    
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>                     
                          :
                            null
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="border p-1">
                          {this.state.anomalyBarOptions && this.state.anomalyBarSeries ?
                            <div className="d-flex cursorPointer mb-2">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.anomalyBarOptions} series={this.state.anomalyBarSeries} type="bar" height={10}/>                    
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.barOptions && this.state.barSeries ?
                            <div className="d-flex cursorPointer">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.barOptions} series={this.state.barSeries} type="bar" height={35}/>                    
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>                    
                          :
                            null
                          }
                          {this.state.options && this.state.series ?
                            <div className="d-flex cursorPointer mt-2 border-top">
                              <small className="align-self-center mr-2">Event</small>
                              <div>
                                <Chart options={this.state.options} series={this.state.series} type="area" height={35}/>
                              </div>
                            </div>                     
                          :
                            null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  <div className="col-md-4 align-self-center">
                    <div id="chart-line">
                      {this.state.optionsLine1 && this.state.seriesLine1 ?
                        <Chart options={this.state.optionsLine1} series={this.state.seriesLine1} type="line" height={80} />
                      : null }
                    </div>
                    <div id="chart-line2">
                      {this.state.optionsLine2 && this.state.seriesLine2 ?
                        <Chart options={this.state.optionsLine2} series={this.state.seriesLine2} type="line" height={80} />
                        : null }
                    </div>
                    <div id="chart-area">
                      {this.state.optionsArea && this.state.seriesArea ?
                        <Chart options={this.state.optionsArea} series={this.state.seriesArea} type="area" height={80} />
                      : null}
                    </div>
                  </div>

                  <div className="col-md-4 align-self-center">
                    {this.state.barChartSeries && this.state.barChartOptions ?
                      <Chart options={this.state.barChartOptions} series={this.state.barChartSeries} type="bar" height={80} />
                    : null }
                  </div>

                  <div className="col-md-4 align-self-center">
                    <div id="chart-line">
                      {this.state.annotationOptions && this.state.annotationSeries ?
                        <Chart options={this.state.annotationOptions} series={this.state.annotationSeries} type="area" height={200} />
                      : null }
                    </div>
                  </div>

                  <div className="col-md-4 align-self-center">                    
                    <div id="chart-line">
                      {this.state.annotationOptions1 && this.state.annotationSeries1 ?
                        <Chart options={this.state.annotationOptions1} series={this.state.annotationSeries1} type="area" height={200} />
                      : null }
                    </div>
                  </div>

                  <div className="col-md-4 align-self-center">
                    <label>Conirmed range chart</label>
                    <div id="chart-line">
                      {this.state.mixedOptions && this.state.mixedSeries ?
                        <Chart options={this.state.mixedOptions} series={this.state.mixedSeries} type="line" height={350} />
                      : null }
                    </div>
                  </div>
                  
                </div>
              </div>

							<div className='tab-content p-3' id='myTabContent'>
								<div className='tab-pane fade show active resizeTable' id='home' role='tabpanel' aria-labelledby='home-tab'>
									{this.props.rules && this.props.rules.length && !this.state.showLoading ? (
										<CheckboxTable
											columns={[
												{
													Header: 'Date',
													accessor:  d => d.last_modified ? d.last_modified : d.created_at,
													Cell: cellInfo => cellInfo.row.original.last_modified && cellInfo.row.original.last_modified !== '' ?  momentDateTime(cellInfo.row.original.last_modified) : momentDateTime(cellInfo.row.original.created_at)
												},
												/* {
													Header: 'Rule ID',
													accessor: 'rule_id'
												}, */
												{
													Header: 'Rule Name',
													accessor: 'rule_name'
												},
												/* {
													Header: 'Description',
													accessor: 'description'
												}, */
												{
													Header: 'Category',
													accessor: 'category',
												},
												{
													Header: 'Service',
													accessor: 'resource_type',
												},
												{
													Header: 'Priority',
													accessor: 'priority',
													Cell: cellInfo => (
														<span className={`badge ${
															cellInfo.row.original.priority === 'Low'
																? 'badge-outline-low'
																: cellInfo.row.original.priority === 'Medium'
																	? 'badge-outline-medium'
																	: cellInfo.row.original.priority === 'High'
																		? 'badge-outline-high'
																		: cellInfo.row.original.priority === 'Critical'
																			? 'badge-outline-critical'
																			: ''
															}`}
														>
															{cellInfo.row.original.priority}
														</span>
													),
												},
												{
													Header: 'Type',
													accessor: d =>
														Array.isArray(d.governance_type)
															? d.governance_type.map((item, index) => (d.governance_type.length - 1 === index ? item : item + ', '))
															: d.governance_type,
													Cell: cellInfo => (
														<React.Fragment>
														{cellInfo.row.original.governance_type && cellInfo.row.original.governance_type.map(item => {
															return(
															<small className={`badge badge-outline-secondary mb-1 mr-1`}>{item}</small>
															)
														})}
														</React.Fragment>
													),
												},
												{
													Header: 'Version',
													accessor: 'version',
												},
												{
													Header: 'Status',
													accessor: 'enabled',
													Cell: cellInfo => (
														<span
															className={`badge status-badge badge-outline-${
																cellInfo.row.original.enabled ?'success-black' : 'danger-black'
																} badge-pill`}
														>
															{cellInfo.row.original.enabled ? 'Enabled' : 'Disabled'}
														</span>
													),
												},
												{
													Header: 'Action',													
													Cell: cellInfo => (
													<div className="align-self-center">
														<div className="btn-group dropleft">
															<div className={`rounded-circle px-2 py-1 cursorPointer ${this.state.selectedTableDrop === cellInfo.row.id ? 'bg-light' : 'bg-white3'} `} onClick={ () => this.handleClickGroup(cellInfo.row.id) }>
																<i className="fa fasis-v text-secondary"></i>
															</div>
															<div className={`dropdown-menu ${this.state.selectedTableDrop === cellInfo.row.id ? 'show active' : ''} `}>
																<div>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item" type="button"
																	>Close</button>
																</div>
																<div>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item" type="button"
																	>Close 1</button>
																</div>
																<div>
																	<i className="fa fa-caret-up"></i>
																	<button className="dropdown-item" type="button"
																	>Close 2</button>
																</div>
															</div>
														</div>
													</div>
													),
													
												}
											]}
											data={ this.state.filteredArray}
											onClickRow={tableRow => {}}
											perPage={20}
											tooltipFor={[1,2,3]}
											dashboard
											manualWidth={true}
                      sortByColumn={'Date'}
										/>
									) : (!this.state.showLoading && !this.props.rules) ||
									  (this.props.rules && !this.props.rules.length && !this.state.showLoading) ? (
										<div className='d-flex justify-content-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									) : null}
									{this.state.showLoading ? (
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center' color='dark' size='lg' />
										</div>
									) : null}
								</div>                
							</div>
						</div>

            
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ResizeTable.propTypes = {
	listAllProviders: PropTypes.func,
	listUserRules: PropTypes.func,
	history: PropTypes.object,
	location: PropTypes.object,
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('-',state)
	return {
		rules: state.governance.rules.results ? state.governance.rules.results.filter(arr => arr.status === 'ACTIVE') : [],
		providers: state.filters.providers,
	}
}

export default AppWrapper(ResizeTable, mapStateToProps, {
  listAllProviders,
  listUserRules,
})


  // scriptChart = () => {
  //   var data = this.generateDayWiseTimeSeries(new Date("22 Apr 2017").getTime(), 115, {
  //     min: 30,
  //     max: 90
  //   });
  //   var options1 = {
  //     chart: {
  //       id: "chart2",
  //       type: "area",
  //       height: 230,
  //       foreColor: "#ccc",
  //       toolbar: {
  //         autoSelected: "pan",
  //         show: false
  //       }
  //     },
  //     colors: ["#00BAEC"],
  //     stroke: {
  //       width: 3
  //     },
  //     grid: {
  //       borderColor: "#555",
  //       clipMarkers: false,
  //       yaxis: {
  //         lines: {
  //           show: false
  //         }
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     fill: {
  //       gradient: {
  //         enabled: true,
  //         opacityFrom: 0.55,
  //         opacityTo: 0
  //       }
  //     },
  //     markers: {
  //       size: 5,
  //       colors: ["#000524"],
  //       strokeColor: "#00BAEC",
  //       strokeWidth: 3
  //     },
  //     series: [
  //       {
  //         data: data
  //       }
  //     ],
  //     tooltip: {
  //       theme: "dark"
  //     },
  //     xaxis: {
  //       type: "datetime"
  //     },
  //     yaxis: {
  //       min: 0,
  //       tickAmount: 4
  //     }
  //   };
    
  //   // var chart1 = new Chart(document.querySelector("#chart-area"), options1);
  //   // chart1.render()
    
  //   var options2 = {
  //     chart: {
  //       id: "chart1",
  //       height: 130,
  //       type: "bar",
  //       foreColor: "#ccc",
  //       brush: {
  //         target: "chart2",
  //         enabled: true
  //       },
  //       selection: {
  //         enabled: true,
  //         fill: {
  //           color: "#fff",
  //           opacity: 0.4
  //         },
  //         xaxis: {
  //           min: new Date("27 Jul 2017 10:00:00").getTime(),
  //           max: new Date("14 Aug 2017 10:00:00").getTime()
  //         }
  //       }
  //     },
  //     colors: ["#FF0080"],
  //     series: [
  //       {
  //         data: data
  //       }
  //     ],
  //     stroke: {
  //       width: 2
  //     },
  //     grid: {
  //       borderColor: "#444"
  //     },
  //     markers: {
  //       size: 0
  //     },
  //     xaxis: {
  //       type: "datetime",
  //       tooltip: {
  //         enabled: false
  //       }
  //     },
  //     yaxis: {
  //       tickAmount: 2
  //     }
  //   };
    
  //   var chart2 = new Chart(document.querySelector("#chart-bar"), options2);
    
  //   chart2.render();    

  //   this.setState({ options1, data })
  // }