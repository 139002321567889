/*************************************************
 * Tvastar
 * @exports
 * @file DLP DlpJobsAction.js
 * @author Prakash // on 30/09/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import {
    DLPPOLICYENDPOINT,
    DLP_CONFIGURATION_GET_DLP_ENGINE_EVENTS_BASE_PARAMS,
    DLP_CONFIGURATION_GET_THREAT_JOB_BASE_PARAMS,
    DLP_CONFIGURATION_GET_UEBA_JOB_BASE_PARAMS,
    DLP_CONFIGURATION_GET_DATA_EVENTS_PROCESSOR_BASE_PARAMS,
    DLP_CONFIGURATION_GET_DATA_EVENTS_CONSOLIDATOR_BASE_PARAMS,
    DLP_CONFIGURATION_GET_ACCESS_LOGS_PROCESSOR_BASE_PARAMS,
    DLP_CONFIGURATION_GET_DATA_EVENTS_AGGREGATOR_BASE_PARAMS,
    DLP_CONFIGURATION_GET_INVENTORY_PROCESSOR_BASE_PARAMS,
    DLP_CONFIGURATION_LIST_MSC,
    DLP_CONFIGURATION_INSERT_MSC,
    DLP_CONFIGURATION_UPDATE_MSC,
    DLP_CONFIGURATION_DELETE_MSC,
} from '../../config'
import { ACTION_TYPES } from '../types'

/**
 * Action to DLP engine event base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getDlpEngineEventsBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_DLP_ENGINE_EVENTS_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}

/**
 * Action to DLP threat job base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getThreatJobBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_THREAT_JOB_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}


/**
 * Action to DLP data events processor base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getUebaJobBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_UEBA_JOB_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}

/**
 * Action to DLP ueba job base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getDataEventsProcessorBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_DATA_EVENTS_PROCESSOR_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}

/**
 * Action to DLP ueba job base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getDataEventsConsolidatorBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_DATA_EVENTS_CONSOLIDATOR_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}

/**
 * Action to DLP ueba job base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getAcceessLogsProcessorBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_ACCESS_LOGS_PROCESSOR_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}

/**
 * Action to DLP data events aggregator base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getDataEventsAggregatorBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_DATA_EVENTS_AGGREGATOR_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}

/**
 * Action to DLP inventoru procesor base params
 * @param {Object} body
 * @param {Function} callback
*/

export const getInventoryProcessorBaseParams = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_GET_INVENTORY_PROCESSOR_BASE_PARAMS, { body })
            callback(true, response)
        } catch (error) {
            callback(false, {})
        }
    }
}

/**
 * Action to list msc
 * @param {Object} body
 * @param {Function} callback
*/

export const listMsc = (body, callback) => {
    return async dispatch => {
        let jobType = 'SET_DLP_JOBS_LIST'
        if(body.job_type === 'dlp_engine_event') {
            jobType = 'SET_DLP_CONFIGURATION_LIST_ENGINE_EVENTS'
        }
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_LIST_MSC, { body })
            dispatch({
                type: ACTION_TYPES[jobType],
                payload: response,
            })
            callback(true, response)
        } catch (error) {
            dispatch({
                type: ACTION_TYPES[jobType],
                payload: {},
            })
            callback(false, {})
        }
    }
}

/**
 * Action to Insert msc
 * @param {Object} body
 * @param {Function} callback
 */

export const insertMsc = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_INSERT_MSC, { body })
            callback(true, response)
        } catch (error) {
            callback(false)
        }
    }
}

/**
 * Action to Update msc
 * @param {Object} body
 * @param {Function} callback
 */

export const updateMsc = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_UPDATE_MSC, { body })
            callback(true, response)
        } catch (error) {
            callback(false)
        }
    }
}

/**
 * Action to Delete msc
 * @param {Object} body
 * @param {Function} callback
 */

export const deleteMsc = (body, callback) => {
    return async dispatch => {
        try {
            const response = await API.post(DLPPOLICYENDPOINT, DLP_CONFIGURATION_DELETE_MSC, { body })
            callback(true, response)
        } catch (error) {
            callback(false)
        }
    }
}
