
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ErrorDetails.js
 * @author Prakash // on 02/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, getAccountNameFromId } from '../../../../utils/utility'

class ErrorDetails extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            expandErrorDetails: true,
        }
    }

    componentDidMount = () => {}

    componentDidUpdate = (prevProps) => {
        if(this.props.tabOptions && Object.entries(this.props.tabOptions).length) {
            if(!_.isEqual(prevProps.tabOptions, this.props.tabOptions)) {
                this.setState({ 
                    showSpinner: true,
                    // details: this.props.tabOptions.details,
                    // merticsAssetNameInvolved: this.props.tabOptions.merticsAssetNameInvolved
                },
                    () => {
                        setTimeout(() => this.setState({ showSpinner: false }), 2000)
                    }
                )
            }
        }

        if(!_.isEqual(prevProps.details, this.props.details)) {
            this.setState({ 
                expandErrorDetails: true, 
                expandMoreDetails: false,
                expandAssetDetails: false
            })
        }
    }

    render() {
        return (
            <div className="mt-2 errorSectionScroll">
                {this.state.showSpinner ?
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                :
                    this.props.details && Object.entries(this.props.details).length ?
                        <div className="rounded bg-dark3 p-3">
                            <div className="accordion" id="accordionExample">
                                <div className={`card bg-dark border-0 mb-2`}>
                                    <div id={'heading_event'} onClick={() => this.setState({ expandErrorDetails: !this.state.expandErrorDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                            <div className="text-white"><span className={`far ${this.state.expandErrorDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Error Details</div>
                                            {/* <div className="text-info">Showing event details</div> */}
                                        </div>
                                    </div>
                                    <div id={'collapse_event'} className={`collapse ${this.state.expandErrorDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                <div>
                                                    <p className="mb-0 f16 text-white">{this.props.details.qid ? this.props.details.qid : <span>&nbsp;</span>}</p>
                                                    <p className="mb-0 f12">{this.props.details.description ? this.props.details.description : <span>&nbsp;</span>}</p>
                                                </div>
                                                <div className="ml-3">
                                                    <p className="b-block mb-0">Severity</p>
                                                    <span className={`mr-2 badge ${this.props.details.severity && this.props.details.severity.toLowerCase() !== 'nan' ? 'risk-badge-'+this.props.details.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                        {this.props.details.severity ? capitalizeFirstLetter(this.props.details.severity) : 'Unknown'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2 border-bottom">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Log Type Source:</p>
                                                    <p className="mb-0 text-white">{this.props.details.error_type_source ? this.props.details.error_type_source : ''}</p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Log File Type Seen:</p>
                                                    <p className="mb-0 text-white">
                                                        {this.props.details.log_file_type ? this.props.details.log_file_type : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={`card bg-dark border-0 mt-3 displayNone`}>
                                    <div id={'heading_event'} className="cursorPointer" onClick={() => this.setState({ expandMoreDetails: !this.state.expandMoreDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                            <div className="text-white">
                                                <span className={`far ${this.state.expandMoreDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>
                                                Solution
                                            </div>
                                            {/* <div className="text-info">Showing event details</div> */}
                                        </div>
                                    </div>
                                    <div id={'collapse_event'} className={`collapse ${this.state.expandMoreDetails ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                        <div className="card-body">
                                            <div className="d-flex mb-2 border-bottom">
                                                <div className="py-1">
                                                    <p className="b-block mb-0">Cause:</p> 
                                                    {this.props.details.cause ? 
                                                        this.props.details.cause.map((item, i) => {
                                                            return (
                                                                <small className={`d-flex flex-wrap mb-1 text-white`}>
                                                                    <span className="primary-color mr-1">{(i+1)+'. '}</span>
                                                                    {item}
                                                                </small>
                                                            )
                                                        }) 
                                                    : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2 border-bottom">
                                                <div className="py-1">
                                                    <p className="b-block mb-0">Solution:</p> 
                                                    <small className={`d-flex flex-wrap mb-1 text-white`}>{this.props.details.solution ? this.props.details.solution : ''}</small>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <div className="py-1">
                                                    <p className="b-block mb-0">External Link:</p>
                                                    {this.props.details.external_link ? 
                                                        this.props.details.external_link.map((item, i) => {
                                                            return (
                                                                <small className={`d-flex flex-wrap mb-1`}>
                                                                    <a target='_blank' href={item}><span className="primary-color mr-1">{(i+1)+'. '}</span>{item}</a>
                                                                </small>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                                
                                <div className={`card bg-dark border-0 mt-3`}>
                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !this.state.expandAssetDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                            <div className="text-white"><span className={`far ${this.state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                            {/* <div className="text-info">Showing asset details</div> */}
                                        </div>
                                    </div>
                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                        {this.props.details.assets && this.props.details.assets.map(item => {
                                            return(
                                            <div className="card-body m-2 pt-2 rounded counts_threads">
                                                <div className="d-flex mb-2">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Name:</p>
                                                        <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ""}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Id:</p>
                                                        <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-top">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Resource:</p>
                                                        <p className="mb-0 text-white">
                                                            {this.props.details.provider ? this.props.details.provider.toUpperCase() : ""}
                                                            {this.props.details.account_id ? (" : ")+this.props.details.account_id : ""}
                                                            {this.props.details.region ? (" : ")+this.props.details.region : ""}
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Services:</p>
                                                        <p className="mb-0 text-white">
                                                            {item.resource_type ? item.resource_type : ""}
                                                            {item.service_name ? (" : ")+item.service_name : ""}
                                                        </p>
                                                    </div>
                                                </div>                                                        
                                                <div className="d-flex mb-2 border-top">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">ARN:</p>
                                                        <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : <span>&nbsp;</span>}</p>
                                                    </div>
                                                </div>
                                                {item.category ? 
                                                    <div className="d-flex mb-2 border-top">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Category:</p>
                                                            <div className={`d-flex flex-wrap`}>
                                                                {item.category.map(item => {
                                                                    return (
                                                                        <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                                            
                    : 
                        <div className='d-flex justify-content-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                }
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
 ErrorDetails.propTypes = {}

 /**
  * Map all reducer state to the props of the component
  * @param {Object} state
  */
 const mapStateToProps = state => {
     // console.log('errorDashboard',state)
     return {
         tabOptions: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.tabOptions ? state.aiops.aiopsPropsDetails.tabOptions : {},
     }
 }
 
 export default connect(mapStateToProps, {})(withRouter(ErrorDetails))        