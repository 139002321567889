/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ErrorsAnalysis.js
 * @author Prakash // on 08/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
*************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'

// import { UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import { momentConvertionLocalToUtcTime } from '../../../../utils/utility'

import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { getTopErrors, getTotalCount } from '../../../../actions/aiops/ErrorAction'
import { getDailyCount } from '../../../../actions/aiops/ErrorAction'

import ApexDonutChart from '../../../common/charts/ApexDonutChart'
import ApexBarChart from '../../../common/charts/ApexBarChart'
import ApexTrendBarChart from '../../../common/charts/ApexTrendBarChart'

class ErrorsAnalysis extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            selectedTab: 'Analysis',
            selectedTopErrorBy: 'service_asset',
            start_time: this.props.aiopsCommonPageFilter.start_time ? this.props.aiopsCommonPageFilter.start_time : this.props.start_time,
            end_time: this.props.aiopsCommonPageFilter.end_time ? this.props.aiopsCommonPageFilter.end_time : this.props.end_time,
        }
    }

    componentDidMount = () => {
        if(this.props.start_time && this.props.start_time !== "") {
            this.onSearch()
        } else if(this.props.aiopsCommonPageFilter && this.props.aiopsCommonPageFilter.start_time && this.props.aiopsCommonPageFilter.start_time !== "") {
            this.onSearch()
        }

        if(this.props.errorsTrend) {
            this.errorTotalTrend()
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.errorsTrend && Object.entries(this.props.errorsTrend).length) {
            if(!_.isEqual(prevProps.errorsTrend, this.props.errorsTrend)) {
                this.errorTotalTrend()
            }
        }
    }

    errorTotalTrend = () => {
        if(this.props.errorsTrend) {
            let graphData = {
                start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
            }
            let graphDetails = this.props.errorsTrend && this.props.errorsTrend.results ? this.props.errorsTrend.results[0] : {}
            if(graphDetails && graphDetails.data) {
                graphData = {
                    data: graphDetails.data,
                    labels: graphDetails.dates,
                    start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                    end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
                }
            }
            this.setState({ graphData })
        }
    }

    onSearch = () => {
        this.setState({
            showDonut: false,
            topQidErrors: {}, topAssets: {}, topCause: {}, topImpact: {},
        }, () => {
            this.getTotalCount()
            this.getTopErrors()
            this.getObservabilityErrorsDailyTrend()
        })
    }

    getObservabilityErrorsDailyTrend = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            "aggregate_by": ["event_type"],
            "radial": true,
            "duration_aggregate_by": "seconds"
        }
        this.props.getDailyCount(params, (promise, response) => {
            if(promise) {
                this.setState({ errorTypeDailyTrend: response },
                    () => this.formErrorTypeTrend()
                )
            } else {
                this.setState({ errorTypeDailyTrend: {}},
                    () => this.formErrorTypeTrend()
                )
            }
        })
    }    

    formErrorTypeTrend = () => {
        let errorGraphTypes = []
        if(this.state.errorTypeDailyTrend && Object.entries(this.state.errorTypeDailyTrend).length) {
            let graphDetails = this.state.errorTypeDailyTrend.results ? this.state.errorTypeDailyTrend.results[0] : {}
            graphDetails.data && Object.entries(graphDetails.data).forEach(([key, value]) => {
                errorGraphTypes.push(key)
                let graphData = {
                    start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                    end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
                }

                graphData = {
                    data: value,
                    labels: graphDetails.dates,
                    start_time: momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'), 
                    end_time: momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss')
                }
                console.log(key, graphData)
                this.setState({ [key+"_trend"]: graphData })
            }) 
        }
        console.log("errorGraphTypes", errorGraphTypes)
        this.setState({ errorGraphTypes })
    }

    getTopErrors = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'top': 5,
            'aggregate_by': [['assets.asset_name', 'assets.service_name', 'impact', 'cause_tags', 'qid']]
        }

        this.props.getTopErrors(params, (promise, response) => {
            if(promise) {
                this.setState({ topErrors: response.length && response[0].data ? response[0].data : {} },
                    () => this.topErrorsSectionGraph()
                )
            }
        }) 
    }

    topErrorsSectionGraph = () => {
        if(this.state.topErrors.cause_tags) {
            let list = this.state.topErrors.cause_tags
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topCause: topStatusData })
        }

        if(this.state.topErrors.impact) {
            let list = this.state.topErrors.impact
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topImpact: topStatusData })
        }

        if(this.state.topErrors['assets.asset_name']) {
            let list = this.state.topErrors['assets.asset_name']
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            this.setState({ topAssets: topStatusData })
        }

        if(this.state.topErrors['qid']) {
            let list = this.state.topErrors['qid']
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })

            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels

            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }
            this.setState({ topQidErrors: topStatusData, leftSideTopErrors: topStatusData })
            
        }        
    }

    getTotalCount = () => {
        let params = {
            "start_time": momentConvertionLocalToUtcTime(this.state.start_time, 'YYYY-MM-DD HH:mm:ss'),
            "end_time": momentConvertionLocalToUtcTime(this.state.end_time, 'YYYY-MM-DD HH:mm:ss'),
            'radial': true,
            'aggregate_by': [['category', 'severity', 'event_type', 'assets.service_name']]
        }

        this.props.getTotalCount(params, (promise, response) => {
            if(promise) {
                let results = response.results && response.results.length && response.results[0].data ? response.results[0].data : {}
                this.setState({ rightSideTotalSection: results },
                    () => this.rigthSideTopSection()    
                )
            }
        }) 
    }

    rigthSideTopSection = () => { 
        //services Donut start
        let services = []
        let services_total_count = 0
        this.state.rightSideTotalSection['assets.service_name'] && Object.entries(this.state.rightSideTotalSection['assets.service_name']).forEach(([key, value]) => {
            let donutRow = {}
            donutRow.item_count = value
            donutRow.item_name = key
            services.push(donutRow)
            services_total_count += value 
        })

        services = _.orderBy(services, ['item_count'], ['desc'])
        services = services.slice(0,10)

        let servicesDonutData = {
            "items": services,
            "label": "Services",
            "total_count": services_total_count
        }

        //services Donut start
        
        //severity Donut start
        let severity = []
        let severity_total_count = 0
        this.state.rightSideTotalSection.severity && Object.entries(this.state.rightSideTotalSection.severity).forEach(([key, value]) => {

            let donutRow = {}
            donutRow.item_name = key
            donutRow.item_count = value
            donutRow.orderBy = key.toLowerCase() === 'critical' ? 1 :key.toLowerCase() === 'high' ? 2 : key.toLowerCase() === 'medium' ? 3 : 4
            severity.push(donutRow)

            severity_total_count += value 
        })

        severity = _.orderBy(severity, ['orderBy'], ['asc'])

        let severityDonutData = {
            "items": severity,
            "label": "Severity",    
            "total_count": severity_total_count
        }

        //severity Donut start

        //event type Donut start
        let event_type = []
        let event_type_total_count = 0
        this.state.rightSideTotalSection.event_type && Object.entries(this.state.rightSideTotalSection.event_type).forEach(([key, value]) => {

            let donutRow = {}
            donutRow.item_name = key
            donutRow.item_count = value
            event_type.push(donutRow)

            event_type_total_count += value 
        })

        event_type = _.orderBy(event_type, ['item_count'], ['desc'])

        let eventTypeDonutData = {
            "items": event_type,
            "label": "Event Type",    
            "total_count": event_type_total_count
        }

        //event type Donut start

        this.setState({ servicesDonutData, severityDonutData, eventTypeDonutData, showDonut: true })
    }

    resetErrorGraph = () => {
        let graphData = this.state.graphData
        this.setState({ graphData: {} },
            () => {
                this.setState({ graphData })
            }
        )
    }

    resetErrorTypeGraph = (key) => {
        let graphData = this.state[key+"_trend"]
        this.setState({ [key+"_trend"]: {} },
            () => {
                this.setState({ [key+"_trend"]: graphData })
            }
        )
    }

    render() {
        return (            
            <div className={`${this.props.start_time ? "p-3 bg-muted" : ""}`} style={{maxHeight: "100%"}}>{/* errorSectionScroll */}
                <div className="col-sm-12 mt-2">
                    <div className="row">
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.severityDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.severityDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}                                
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#039BE5']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.severityDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.servicesDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                        <div className="col-sm-4">
                            {this.state.showDonut && this.state.eventTypeDonutData ?
                                <ApexDonutChart
                                    graphData={this.state.eventTypeDonutData}
                                    legend={true}
                                    showTotal={true}
                                    showTotalLable={true}
                                    showGraphHeading={false}
                                    height={150}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                    gradientColor={['#A88CCC', '#D88ACF']}
                                    className={"transparentTooltip leftSide"}
                                    emptyGraphColor={"#2D323E"}
                                />
                            : null}
                        </div>
                    </div>
                </div>
                <div className="rounded counts_threads p-3 mt-2">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Errors</label>
                                {this.state.topQidErrors && Object.entries(this.state.topQidErrors).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.topQidErrors}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#B8BBBE'}
                                            paddingLeft={-25}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="col-lg-6 pl-0">
                            <div className="bg-dark p-3 rounded">
                                <label>Top 5 Assets</label>
                                {this.state.topAssets && Object.entries(this.state.topAssets).length ?
                                    <div className="mt-n4"> 
                                        <ApexBarChart
                                            graphData={this.state.topAssets}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={false}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#B8BBBE'}
                                            paddingLeft={-25}
                                            legend={false}
                                            stacked={false}
                                            height={240}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            gradientColor={['#039BE5', '#5F5BA2']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={"transparentTooltip"}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <div className="bg-dark p-3 rounded">
                                <div className="d-flex justify-content-between">
                                    <label>Total Errors Trend </label>
                                    <i className='far fa-redo cursorPointer' onClick={()=> this.resetErrorGraph()}></i>
                                </div>
                                {this.state.graphData && Object.keys(this.state.graphData).length ?
                                    <div className="mt-n2"> 
                                        <ApexTrendBarChart
                                            graphData={this.state.graphData}
                                            graphRiskCountSection={false}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            yaxisTickAmount={2}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#50586A'}
                                            legend={false}
                                            stacked={false}
                                            height={220}
                                            horizontal={false}
                                            barHeight={'40%'}
                                            barEndShape={'flat'}
                                            columnWidth={'0%'}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={true}
                                            className={"transparentTooltip"}
                                            colors={['#3DAFE8']}
                                        />
                                    </div>
                                : null}
                                {/* <div className="transparentTooltip mt-n2">
                                    {this.state.anomalySeries && this.state.anomalySeries.length ?
                                        <Chart options={this.state.anomalyOptions} series={this.state.anomalySeries} type="bar" height={200} />
                                    : null}
                                </div> */}
                            </div>
                        </div>
                    </div>
                    
                    {this.state.errorGraphTypes && this.state.errorGraphTypes.length ?
                        this.state.errorGraphTypes.map(item => {
                            return (
                                <div className="row mt-3">
                                    <div className="col-lg-12">
                                        <div className="bg-dark p-3 rounded">
                                            <div className="d-flex justify-content-between">
                                                <label>
                                                    {item === "AwsApiCall" ? "Aws Api calls" : item === "metric" ? "Metric" : item === "logs" ? "Logs" : item} Error Trend 
                                                </label>
                                                <i className='far fa-redo cursorPointer' onClick={()=> this.resetErrorTypeGraph(item)}></i>
                                            </div>
                                            {this.state[item+"_trend"] && Object.keys(this.state[item+"_trend"]).length ?
                                                <div className="mt-n2"> 
                                                    <ApexTrendBarChart
                                                        graphData={this.state[item+"_trend"]}
                                                        graphRiskCountSection={false}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        yaxisTickAmount={2}
                                                        xaxis={true}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#50586A'}
                                                        legend={false}
                                                        stacked={false}
                                                        height={220}
                                                        horizontal={false}
                                                        barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={'0%'}
                                                        backgroundBarShape={'rounded'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={true}
                                                        className={"transparentTooltip"}
                                                        colors={['#3DAFE8']}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    : null}
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
ErrorsAnalysis.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},
        errorsTrend: state.observability.observabilityErrorTrend
    }
}

export default connect(mapStateToProps, {
    setAiopsPropsDetails,
    getTopErrors,
    getTotalCount,
    getDailyCount,
})(withRouter(ErrorsAnalysis))