
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AnomalyDetails.js
 * @author Prakash // on 05/01/2022
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'

import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../../utils/utility'

class AnomalyDetails extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            expandAnomalyDetails: true
        }
    }

    componentDidMount = () => {}

    componentDidUpdate = (prevProps) => {
        if(this.props.tabOptions && Object.entries(this.props.tabOptions).length) {
            if(!_.isEqual(prevProps.tabOptions, this.props.tabOptions)) {
                this.setState({ 
                    showSpinner: true,
                    // details: this.props.tabOptions.details,
                    // merticsAssetNameInvolved: this.props.tabOptions.merticsAssetNameInvolved
                },
                    () => {
                        setTimeout(() => this.setState({ showSpinner: false }), 2000)
                    }
                )
            }
        }

        if(!_.isEqual(prevProps.details, this.props.details)) {
            this.setState({ 
                expandAnomalyDetails: true, 
                expandAnomalyPrecedingDetails: false,
                expandPropsAssetDetails: false,
                expandPropsLogDetails: false,
            })
        }
    }

    render() {
        return (
            <div className="mt-2 errorSectionScroll">
                {this.state.showSpinner ?
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                :
                    this.props.details && Object.entries(this.props.details).length ?
                        <div className="rounded bg-dark3 p-3">
                            <div className="accordion" id="accordionExample">
                                <div className={`card bg-dark border-0 mb-2`}>
                                    <div id={'heading_event'} onClick={() => this.setState({ expandAnomalyDetails: !this.state.expandAnomalyDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                            <div className="text-white"><span className={`far ${this.state.expandAnomalyDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Anomaly Details</div>
                                            {/* <div className="text-info">Showing event details</div> */}
                                            <div className="toggleOnOffSwitchTable d-flex mr-1 align-self-center" >
                                                {/* onClick={() => this.automaiotnUpdateStatus(cellInfo.row.original)}> */}
                                                <label className="switch mb-0">
                                                    <input type="checkbox" 
                                                        className="inputdemo" 
                                                        checked={this.state['enabled_'+this.props.details.anomaly_id] ? false : true}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'collapse_event'} className={`collapse ${this.state.expandAnomalyDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between mb-2 pb-2 border-bottom">
                                                <div>
                                                    <p className="mb-0 f16 text-white">{this.props.details.anomaly_name ? this.props.details.anomaly_name : <span>&nbsp;</span>}</p>
                                                    <p className="mb-0 f12">{this.props.details.anomaly_message ? this.props.details.anomaly_message : <span>&nbsp;</span>}</p>
                                                </div>
                                                <div className="ml-3">
                                                    <p className="b-block mb-0">Severity</p>
                                                    <span className={`mr-2 badge ${this.props.details.severity && this.props.details.severity.toLowerCase() !== 'nan' ? 'risk-badge-'+this.props.details.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                        {this.props.details.severity ? capitalizeFirstLetter(this.props.details.severity) : 'Unknown'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Time</p>
                                                    <p className="mb-0 text-white">
                                                            {this.props.details.anomaly_time ? momentConvertionUtcToLocalTime(this.props.details.anomaly_time, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Type</p>
                                                    <p className="mb-0 text-white">
                                                        {this.props.details.anomaly_type ? this.props.details.anomaly_type : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-2 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Score</p>
                                                    <p className="mb-0 text-white">
                                                            {this.props.details.anomaly_score}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Value</p>
                                                    <p className="mb-0 text-white">
                                                        {this.props.details.anomaly_value}
                                                    </p>
                                                </div>
                                            </div>                    
                                            
                                            <div className="d-flex pt-2 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Run Time</p>
                                                    <p className="mb-0 text-white">
                                                        {this.props.details.run_time ? momentConvertionUtcToLocalTime(this.props.details.run_time, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Product</p>
                                                    {this.props.details.product ? 
                                                        this.props.details.product.map(item => {
                                                            return (
                                                            <small className={`badge badge-secondary mr-1`}>{item}</small>
                                                            )
                                                        }) 
                                                    : null
                                                    }
                                                </div>
                                            </div>

                                            {this.props.details.additional_details && Object.entries(this.props.details.additional_details).length ?
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                    {Object.entries(this.props.details.additional_details).map(([key, value], addIdx) => {
                                                        return(
                                                            typeof value === "string" ? 
                                                                <React.Fragment>
                                                                    <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                    <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                        <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                        <p className="mb-0 text-white">{value}</p>
                                                                    </div>
                                                                </React.Fragment>
                                                            : null
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.props.details && this.props.details.type === "sequence" && this.props.details.preceding_sequence && this.props.details.preceding_sequence.length ?
                                <div className={`card bg-dark border-0 mb-2`}>
                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandAnomalyPrecedingDetails: !this.state.expandAnomalyPrecedingDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                            <div className="text-white"><span className={`far ${this.state.expandAnomalyPrecedingDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Preceding Sequence</div>
                                        </div>
                                    </div>
                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandAnomalyPrecedingDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                        <ul className="precedingUl">
                                            {this.props.details.preceding_sequence.map((item, i) => {
                                                    return(
                                                        <li className="d-flex mt-2">
                                                            <span className="ulBullet mt-1 p-1"></span>
                                                            <span className="ml-2 align-self-end cursorPointer text-white" id={"asset_name_"+i}>{item}</span>
                                                            {/* <UncontrolledTooltip target={"asset_name_"+i}>{item.asset_name}</UncontrolledTooltip> */}
                                                        
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            : null}

                            {this.props.details && (this.props.details.asset_name || this.props.details.asset_id) ?
                                <div className={`card bg-dark border-0 mb-2`}>
                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsAssetDetails: !this.state.expandPropsAssetDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                            <div className="text-white"><span className={`far ${this.state.expandPropsAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                            {/* <div className="text-info">Showing asset details</div> */}
                                        </div>
                                    </div>
                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsAssetDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                        <div className="card-body m-2 pt-2 rounded counts_threads">
                                            <div className="d-flex">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Name:</p>
                                                    <p className="mb-0 text-white">{this.props.details.asset_name ? this.props.details.asset_name : <span>&nbsp;</span>}</p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Id:</p>
                                                    <p className="mb-0 text-white">{this.props.details.asset_id ? this.props.details.asset_id : <span>&nbsp;</span>}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-2 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Provider / Account / Region </p>
                                                    <p className="mb-0 text-white">
                                                        {this.props.details.provider ? this.props.details.provider.toUpperCase() : <span>&nbsp;</span>}
                                                        {this.props.details.account_id ? (' : ')+this.props.details.account_id : <span>&nbsp;</span>}
                                                        {this.props.details.region ? (' : ')+this.props.details.region : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Service</p>
                                                    <p className="mb-0 text-white">
                                                        {this.props.details.resource_type ? this.props.details.resource_type : <span>&nbsp;</span>}
                                                        {this.props.details.service_name ? ('  ')+this.props.details.service_name : <span>&nbsp;</span>}
                                                    </p>
                                                </div>
                                            </div>
                                            {this.props.asset_arn ? 
                                                <div className="d-flex pt-2 border-top">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">ARN:</p>
                                                        <p className="mb-0 text-white">{this.props.asset_arn}</p>
                                                    </div>
                                                </div>
                                            : null}
                                            {this.props.details.category ? 
                                                <div className="d-flex pt-2 border-top">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">Category:</p>
                                                        <div className={`d-flex flex-wrap`}>
                                                            {this.props.details.category && Array.isArray(this.props.details.category) ? 
                                                                this.props.details.category.map(item => {
                                                                    return (
                                                                    <small className={`badge badge-secondary mr-1`}>{item}</small>
                                                                    )
                                                                }) 
                                                            : this.props.details.category ?
                                                                <small className={`badge badge-secondary mr-1`}>{this.props.details.category}</small>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            : null}

                            {this.props.details && this.props.details.logs && this.props.details.logs.length ?
                                <div className={`card bg-dark border-0 mb-2`}>
                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsLogDetails: !this.state.expandPropsLogDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                            <div className="text-white"><span className={`far ${this.state.expandPropsLogDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Log Details</div>
                                            {this.state.expandPropsLogDetails ?
                                                <div className="text-info">Showing {this.props.details.count} observed anomalies</div>
                                                // <p className="small mb-0 ml-3">Showing {this.props.details.count} observed anomalies</p>
                                            : null}
                                        </div>
                                    </div>                                    
                                    <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsLogDetails ? 'show' : <span>&nbsp;</span>}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                        <div className={`rounded bg-dark3 mx-3 mb-3`} style={{maxHeight: "600px", overflowY: "auto"}}>
                                            {this.props.details.logs.map((item, i) => {
                                                    return(
                                                        <div className={`d-flex flex-wrap mr-2 px-2 mb-2 pb-1 pt-1 ${i ? "border-top-black5" : ""}`}>
                                                            <p className="mb-0 text-break">
                                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                <span className={`mb-0 mr-2 f12 text-purple-2`}>{item.log_file ? item.log_file : ""}</span>
                                                                {item.log_event_id ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{item.log_event_id}</span>
                                                                : null}
                                                                {item.message ?
                                                                    <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.message}</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            : null}
                        </div>
                    : null
                }
            </div>
        )
    }
}
/**
 * Type of the props used in the component
 */
 AnomalyDetails.propTypes = {}


/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {}
}


export default connect(mapStateToProps, {})(withRouter(AnomalyDetails))        