import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { setAiopsPropsDetails } from '../../../../actions/aiops/AiopsAction'
import { apiToDevCall } from '../../../../actions/commonAction'
import { listSizingEc2 } from '../../../../actions/governance/governanceAction'
import { getAssetDetailExt } from '../../../../actions/aiops/RemediationAction'

import { convertToInt } from '../../../../utils/utility'

import Checkbox from '../../../common/formFields/Checkbox'
import SelectField from '../../../common/formFields/SelectField'

class ChangeRDSVolumeType extends Component {

  constructor(props) {
    super(props);
    this.state = props || {};
    this.state = {
      validationMessage: '',
      parentValue: this.props.value,
      showLoading: true,
    }
    this.updateFormFieldValue = this.updateFormFieldValue.bind(this);
  }

  componentDidMount = () => {
    this.getStorageType()
    let vmArray = []
    let params= {}
    if(this.props.aiopsSelectedRemediationProps.resource_type !== 'EC2') {
      params = {}
      params.provider = {'match': 'AWS'}
      params.region = {'match': "us-east-1"}
      // params.resource_type = this.props.editRuleDetails.resource_type
      params.sourcefields = {"fieldsreq":["features"]}
      this.props.listSizingEc2(params, (success, result) => {
        console.log('result', result)
        let sizingArray = []
        let sizingOutput = []
        
        if(success) {
          let data = result.data.body
          data.length && data.forEach(item => {
            Object.values(item).forEach(parent => {
              let dataRow = {}
              dataRow.description = parent.Description
              dataRow.instanceFamily = parent.General && parent.General['Instance Family'] ? parent.General['Instance Family'] : ''
              dataRow.instanceSeries = parent.General && parent.General['Series'] ? parent.General['Series'] : ''
              dataRow.instanceType = parent.General && parent.General['Instance'] ? parent.General['Instance'] : ''
              
              if(parent.Compute && parent.Compute['vCPU']) {
                dataRow.vCPU = parent.Compute['vCPU']
              }
              if(parent.Memory && parent.Memory['Memory(GiB)']) {
                dataRow.memory = parent.Memory['Memory(GiB)'] + ' GiB'
              }
              if(parent.Storage && parent.Storage['EBS Optimized']) {
                dataRow.ebsOptimized = parent.Storage['EBS Optimized']
              }
              if(parent.Storage && parent.Storage['Storage Type']) {
                dataRow.storageType = parent.Storage['Storage Type']
              }							
              if(parent.Storage && parent.Storage['Storage Details']) {
                dataRow.storageDetails = parent.Storage['Storage Details']
              }
              if(parent.Networking && parent.Networking['Enhanced Networking']) {
                dataRow.enhancedNetworking = parent.Networking['Enhanced Networking']
              }
              if(parent.Networking && parent.Networking['Network Performance(Gbit/s)']) {
                dataRow.networkingPerformance = parent.Networking['Network Performance(Gbit/s)'] +' Gbit/s'
              }
              if(parent.Processor && parent.Processor['Architecture(bit)']) {
                dataRow.architecture = convertToInt(parent.Processor['Architecture(bit)'])
              }
              if(parent.Processor && parent.Processor['CPU Processor']) {
                dataRow.cpuProcessor = parent.Processor['CPU Processor']
              }
              sizingArray.push(dataRow)	
            })
          })
                    
          let instanceFamily = _.chain(sizingArray)
          .groupBy('instanceFamily')
          .map((value, key) => ({ label: key, sub: value })).value()
          .forEach((sizing, i) => {
            let dataRow = {}
            dataRow.instanceFamilyLable = sizing.label
            dataRow.description = sizing.label //equalizing the old array to easy the work
            let vm_series = []
            .groupBy('instanceSeries')
            .map((value, key) => ({ slabel: key, sSub: value })).value()
            .forEach((series, i) => {
              let seriesRow = {}
              seriesRow.series = series.slabel
              seriesRow.vm_types = series.sSub
              vm_series.push(seriesRow)
            })
            dataRow.vm_series = vm_series
            sizingOutput.push(dataRow)
          })

          sizingOutput.length && sizingOutput.forEach(res => {
            res.vm_series && res.vm_series.length && res.vm_series.forEach(series => {
              series.vm_types && series.vm_types.forEach(types => {
                let vmRow = {}
                vmRow.description = res.description
                vmRow.series = series.series
                vmRow.options = types.instanceType
                vmRow.vm_types = types
                vmArray.push(vmRow)
              }) 
            })
          })

          this.setState({ showLoading: false, vmArray: [{
            label: '1123123', options: '11'
          }] })
        }
      })
    } else {
      let name = ''
      this.props.apiToDevCall(name, params, (success, result) => {
        if (success) {
          result.length && result[0].results && result[0].results.forEach(res => {
            res.vm_series && res.vm_series.length && res.vm_series.forEach(series => {
              series.vm_types && series.vm_types.forEach(types => {
                let vmRow = {}
                vmRow.description = res.description
                vmRow.series = series.series
                vmRow.options = types.instanceType
                vmRow.vm_types = types
                vmArray.push(vmRow)
              }) 
            })
          })
          this.setState({ showLoading: false, vmArray })
        }
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.validationError !== prevProps.validationError) {
			this.setState({ 
        validationError: this.props.validationError, 
        InstanceTypeError: this.props.aiopsRemediationParams.InstanceTypeError ? this.props.aiopsRemediationParams.InstanceTypeError : '',
        ApplyImmediatelyError: this.props.aiopsRemediationParams.ApplyImmediatelyError ? this.props.aiopsRemediationParams.ApplyImmediatelyError : ''
      })
		}
  }

  getStorageType = () => {
    let params = {}
    params.provider = Array.isArray(this.props.caseDetails.provider) ? (this.props.caseDetails.provider && this.props.caseDetails.provider.length ? this.props.caseDetails.provider[0].toLowerCase() : '') : (this.props.caseDetails.provider ? this.props.caseDetails.provider.toLowerCase() : '')
    params.service_name = this.props.aiopsSelectedRemediationProps.service_name
    params.asset_id = this.props.aiopsSelectedRemediationProps.asset_id

    this.props.getAssetDetailExt(params, (promise, result) => {
      console.log(result,'/././././')
      if(promise) {
        this.setState({ db_instance_class: result && result[0] && result[0].db_instance_class ? result[0].db_instance_class : '' })
      } else {
        this.setState({ result: [] })
      }
    })
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    let obj = this.props.aiopsRemediationParams
    obj[fieldName] = fieldValue
    obj.processor_rule_id = this.props.selectedRecord
    this.props.setAiopsPropsDetails('remediationParams', obj)

    if(fieldName === 'InstanceType') {
      this.setState({ InstanceTypeError: '' })
    } else {
      this.setState({ ApplyImmediatelyError: '' })
    }

    this.setState({ [fieldName]: fieldValue });
  };

  render() {
    return (
      !this.state.showLoading ?
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="form-group">
              <label>Change to <small className="ml-1">Current Storage: {this.state.db_instance_class ? this.state.db_instance_class : ''} </small></label>
              <SelectField 
                //className="f13 p-0 form-control reactSelectDrop col-sm-6"
                className="f13 p-0 form-control dark-select-theme col-sm-6"
                recordSelection={this.updateFormFieldValue}
                value={this.state.InstanceType ? this.state.InstanceType : ''}
                name={'InstanceType'}
                label='Select'
                placeholder="All"
                required={false}
                selectOptions={this.state.vmArray ? this.state.vmArray : []}
              />
              {this.state.validationError && this.state.InstanceTypeError && this.state.InstanceTypeError !== '' ?
                <small className='text-danger'>{this.state.InstanceTypeError}</small>
              : null }
            </div>
            <div className="form-group">
              <Checkbox 
                className="form-check-input"
                labelClassName={'text-white'}
                recordSelection={this.updateFormFieldValue}
                value={this.state.ApplyImmediately ? true : false}
                name={'ApplyImmediately'}
                label={'ApplyImmediately'}
              />
              {this.state.validationError && this.state.ApplyImmediatelyError && this.state.ApplyImmediatelyError !== '' ?
                <small className='text-danger'>{this.state.ApplyImmediatelyError}</small>
              : null }
            </div>
          </div>
        </div>
      : null
    )
  }
};

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {	
	console.log('ChangeRDSVolumeType', state)  
	return {
    caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : [],
    aiopsRemediationParams: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.remediationParams ? state.aiops.aiopsPropsDetails.remediationParams : {},
    aiopsSelectedRemediationProps: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.selected_remediation ? state.aiops.aiopsPropsDetails.selected_remediation : {},
  }
}

/**
 * Type of the props used in the component
 */
 ChangeRDSVolumeType.propTypes = {  
  getAssetDetailExt: PropTypes.func,
  setAiopsPropsDetails: PropTypes.func,
  apiToDevCall: PropTypes.func,
  listSizingEc2: PropTypes.func,
}


export default connect(mapStateToProps, {
  getAssetDetailExt,
  setAiopsPropsDetails,
  apiToDevCall,
  listSizingEc2,
})(withRouter(ChangeRDSVolumeType))
