/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ChecksSearchBuilder.js
 * @author Prakash // on 30/07/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 **************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Spinner } from 'reactstrap'
import { listAllProviders, listAllAccounts, listAllRegions, listAllCategory } from '../../../../actions/commonAction'
import CommonDropdown from '../../../common/CommonDropdown'
import AccessedByFilterSection from './AccessedByFilterSection'
import LifecycleFilterSection from './LifecycleFilterSection'
import DurationFilterSection from './DurationFilterSection'
import ServiceFilterSection from './ServiceFilterSection'
import ResourceFilterSection from './ResourceFilterSection'
import IPAddressFilterSection from './IPAddressFilterSection'
import NetworkingFilterSection from './NetworkingFilterSection'
import ComplianceFilterSection from './ComplianceFilterSection'


import LocationFilterSection from './LocationFilterSection'
import MonitoringFilterSection from './MonitoringFilterSection'
import AccessMethodFilterSection from './AccessMethodFilterSection'
import ScoreFilterSection from './ScoreFilterSection'


const defaultDropdown = [
	{ label: 'Accessed By', value: 'Accessed By'},
	{ label: 'Account', value: 'Account'},
	{ label: 'Category', value: 'Category'},
	{ label: 'Checks Status', value: 'Checks Status'},
	{ label: 'Compliance', value: 'Compliance'},
	{ label: 'Duration', value: 'Duration'},
	{ label: 'Event', value: 'Event'},
	{ label: 'IP Address', value: 'IP Address'},
	{ label: 'Issue Status', value: 'Issue Status'},
	{ label: 'Networking', value: 'Networking'},
	{ label: 'Region', value: 'Region'},
	{ label: 'Resources', value: 'Resources'},
	{ label: 'Risk', value: 'Risk'},
	{ label: 'Service', value: 'Service'},
];

const statusDropDown = [
	{ label: 'Pass', value: 'Pass'},
	{ label: 'Fail', value: 'Fail'}
];

const issueStatusDropDown = [
	{ label: 'New', value: 'New'},
	{ label: 'Pending', value: 'Pending'},
	{ label: 'Reoccured', value: 'Reoccured'},
	{ label: 'Resolved', value: 'Resolved'},
];

const riskDropDown = [
	{ label: 'Low', value: 'Low'},
	{ label: 'Medium', value: 'Medium'},
	{ label: 'High', value: 'High'},
	{ label: 'Critical', value: 'Critical'},
];

class ChecksSearchBuilder extends Component {
	LifecycleFilterSection = React.createRef()	
	dropRef1 = React.createRef()
	dropRef2 = React.createRef()
	dropRef3 = React.createRef()
	
	constructor(props) {
		super(props)

		this.state = {
			providers: [],
			selectedProvider: this.props.selectedProvider,
			selectedDuration: this.props.selectedDuration,
			accounts: [],
			accountArray: [],
            selectedAccount: this.props.selectedAccount,
			selectedRegion: [],
			
			placeHolder: 'Add Filter',
			selectedDefaultOption: '',
			searchText: '',
                        			
			mainDropdown: '',
            showDropdownOption: false,
			isDefaultDropdown: true,
			
			lifecycleSection: false,
			accessedBySection: false,
			monitoringSection: false,
			accessMethodSection: false,
			scoreSection: false,
			acccountSection: false,	
			accountSelected: [],
			statusSection: false,
			statusSelected: [],
			issueStatusSection: false,
			issueStatusSelected: [],
			riskSection: false,
			riskSelected: [],
			categorySection: false,
			categorySelected: [],
			regionSection: false,
			regionSelected: [],
			durationSection: false,
			selecteDuration: this.props.selecteDuration,
			locationSection: false,
			serviceSection: false,
			resourcesSection: false,
			ipAddressSection: false,
			networkingSection: false,
			complianceSection: false,
			filterWithLabel: [],
            filterValue: [],
            searchName: '',
			responseMessage: '',
			showMessage: false,
			showSaveLoading: false,
            hasErrorInRuleForm: false,
            showSaveOption: false,
		}
    }
    	
	componentDidMount = () => {
		let regionArray = []
		this.props.regions && this.props.regions.length && this.props.regions.forEach(region => {
			let dataRow = {}
			dataRow.value = region.region
			dataRow.label = region.name
			regionArray.push(dataRow)
		})
		
		this.setState({ isDefaultDropdown: true, accounts: this.props.accounts, regionArray,
			mainDropdown: _.orderBy(defaultDropdown, ['value'], ['Asc'])
		})				
		
		// let catParams = {}
		// this.props.listAllCategory(catParams, (promise, categories) => {
		// 	console.log(categories,'categories')
		// 	if (promise) {
		// 		let categoryArray = []
		// 		categories.map((cat, i) => {
		// 			let dataRow = {}
		// 			let exist = 0
		// 			if(this.state.categorySelected.length) {
		// 				this.state.categorySelected.forEach((row, index) => {
		// 					if(cat === row.value) {
		// 						exist = 1
		// 					}
		// 				})
		// 				if(exist === 0) {
		// 					dataRow.value = cat
		// 					dataRow.label = cat
		// 					categoryArray.push(dataRow)
		// 				}
		// 			} else {
		// 				dataRow.value = cat
		// 				dataRow.label = cat
		// 				categoryArray.push(dataRow)
		// 			}
		// 		})
		// 		this.setState({ categories, categoryArray })
		// 	} else {
		// 		this.setState({ categories: [], categoryArray: [] })
		// 	}
		// })
		
		let newCategoryArray = []
		this.props.categoryCounts.forEach(cat =>{
			let dataRow = {}
			dataRow.value = cat.category
			dataRow.label = cat.category
			newCategoryArray.push(dataRow)
		})
		this.setState({ newCategoryArray })
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            if(Object.entries(this.props.selectedTableRow).length) {
                this.setState({ searchName: this.props.selectedTableRow.search_name })
            } else {
                this.setState({ searchName: '' })
            }
            if(!Object.entries(this.props.selectedTableRow).length && Object.entries(prevProps.selectedTableRow).length && this.state.filterValue.length) {
                this.setState({ filterValue: [], searchName: '' })
            } else if((this.state.filterValue.length === (this.props.selectedTableRow.filters && this.props.selectedTableRow.filters.length)) && this.state.filterValue !== this.props.selectedTableRow.filters) {
                this.setState({ filterValue: this.props.selectedTableRow.filters })
            } else if(this.props.selectedTableRow.filters && !this.state.filterValue.length) {
                this.setState({ filterValue: this.props.selectedTableRow.filters })
            } else if(!Object.entries(prevProps.selectedTableRow).length && Object.entries(this.props.selectedTableRow).length ) {
                this.setState({ filterValue: this.props.selectedTableRow.filters })
            }
        }
    }
    
	/**
	 * Filter identifiers based on the filter selection
	 */
	filterIdentifiers = from => {
		this.setState({ showLoading: false })		
	}

	componentWillUnmount() {
		//document.removeEventListener('keydown', this._handleKeyDown)
	}
	
	handleClickOutside(event) {
		// if(event.target !== null) {
		// 	if (this.LifecycleFilterSection && !this.LifecycleFilterSection.current.contains(event.target)) {
		// 		this.setState({ lifecycleSection: false })
		// 	}
		// }
	}

	onKeyUp = (e) => {
		// This would have the current value after hitting backspace.
		if (e.keyCode === 8) { 
			this.setState({
				lifecycleSection: false,
				accessedBySection: false,
				monitoringSection: false,
				accessMethodSection: false,
				scoreSection: false,
				acccountSection: false,
				statusSection: false,
				issueStatusSection: false,
				riskSection: false,
				categorySection: false,
				regionSection: false,
				durationSection: false,
				serviceSection: false,
				resourcesSection: false,
				ipAddressSection: false,
				networkingSection: false,
				complianceSection: false,
				locationSection: false,
			})
		}
	}
    
    filterOnchange = () => {
		this.setState({ showDropdownOption: false }, 
			() => this.onChangeChildOperation()
		)		
	}
		
	onChangeChildOperation = () => {
		let filterData = _.orderBy(defaultDropdown, ['value'], ['Asc'])
		this.setState({ selectedDefaultOption: '' })
		if(this.state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.label.toLocaleLowerCase().includes(this.state.searchText) ||
				filter.value.includes(this.state.searchText)
			)
		}
		this.setState({ 
			mainDropdown: filterData, 
			showDropdownOption: 
			this.state.searchText === '' ? false : true 
		})
	}

    comparisonFilter = () => {
		if(this.state.searchText === '') {
            this.setState({
                showDropdownOption: this.state.showDropdownOption ? false : true, 
                selectedDefaultOption: '', 
				acccountSection: false,
				statusSection: false,
				issueStatusSection: false,
				riskSection: false,
				categorySection: false,
				regionSection: false,
            })
		}
	}

	internalComparisonFilter = () => {
		this.setState({ showDropdownOption: 
			this.state.selectedDefaultOption === '' ? 
				this.state.showDropdownOption ? false : true 
			: false,

			lifecycleSection: 
			this.state.selectedDefaultOption === 'Lifecycle' ? 
				this.state.lifecycleSection ? false : true 
			: false,
			
			accessedBySection: this.state.selectedDefaultOption === 'Accessed By' ? 
				this.state.accessedBySection ? false : true
			: false, 

			monitoringSection: this.state.selectedDefaultOption === 'Monitoring' ? 
				this.state.monitoringSection ? false : true
			: false, 

			accessMethodSection: this.state.selectedDefaultOption === 'Access Method' ? 
				this.state.accessMethodSection ? false : true
			: false, 			

			scoreSection: this.state.selectedDefaultOption === 'Score' ? 
				this.state.scoreSection ? false : true
			: false,

			acccountSection: this.state.selectedDefaultOption === 'Account' ? 
				this.state.acccountSection ? false : true
			: false,
			
			statusSection: this.state.selectedDefaultOption === 'Checks Status' ? 
				this.state.statusSection ? false : true
			: false,

			issueStatusSection: this.state.selectedDefaultOption === 'Issue Status' ? 
				this.state.issueStatusSection ? false : true
			: false,			

			riskSection: this.state.selectedDefaultOption === 'Risk' ? 
				this.state.riskSection ? false : true
			: false,

			categorySection: this.state.selectedDefaultOption === 'Category' ? 
				this.state.categorySection ? false : true
			: false,

			regionSection: this.state.selectedDefaultOption === 'Region' ? 
				this.state.regionSection ? false : true
			: false,
			
			durationSection: this.state.selectedDefaultOption === 'Duration' ? 
				this.state.durationSection ? false : true
			: false,

			serviceSection: this.state.selectedDefaultOption === 'Service' ? 
				this.state.serviceSection ? false : true
			: false,

			resourcesSection: this.state.selectedDefaultOption === 'Service' ? 
				this.state.resourcesSection ? false : true
			: false,

			ipAddressSection: this.state.selectedDefaultOption === 'Service' ? 
				this.state.ipAddressSection ? false : true
			: false,

			networkingSection: this.state.selectedDefaultOption === 'Service' ? 
				this.state.networkingSection ? false : true
			: false,

			complianceSection: this.state.selectedDefaultOption === 'Compliance' ? 
				this.state.complianceSection ? false : true
			: false,

			locationSection: this.state.selectedDefaultOption === 'Location' ? 
				this.state.locationSection ? false : true
			: false,			
		})
	}
	
	selectedOptionFunction = (selectedOption) => {
		if(this.state.selectedDefaultOption === '' && selectedOption !== '') {
			this.setState({ 
				selectedDefaultOption: selectedOption, 
				showDropdownOption: false, 
				acccountSection: selectedOption === 'Account' ? true : false,
				statusSection: selectedOption === 'Checks Status' ? true : false,
				issueStatusSection: selectedOption === 'Issue Status' ? true : false,
				riskSection: selectedOption === 'Risk' ? true : false,
				durationSection: selectedOption === 'Duration' ? true : false,
				serviceSection: selectedOption === 'Service' ? true : false,
				resourcesSection: selectedOption === 'Resources' ? true : false,
				ipAddressSection: selectedOption === 'IP Address' ? true : false,
				networkingSection: selectedOption === 'Networking' ? true : false,				
				complianceSection: selectedOption === 'Compliance' ? true : false,
				categorySection: selectedOption === 'Category' ? true : false,
				regionSection: selectedOption === 'Region' ? true : false,
				lifecycleSection: selectedOption === 'Lifecycle' ? true : false,  accessedBySection: selectedOption === 'Accessed By' ? true : false,
				monitoringSection: selectedOption === 'Monitoring' ? true : false,
				accessMethodSection: selectedOption === 'Access Method' ? true : false,
				scoreSection: selectedOption === 'Score' ? true : false,
				locationSection: selectedOption === 'Location' ? true : false,
				
				searchText: selectedOption +': ', 
				placeHolder: '',
			})
		} else if(selectedOption !== '') {
			this.setState({ 
				showDropdownOption: false, 
				acccountSection: selectedOption === 'Account' ? true : false,
				statusSection: selectedOption === 'Checks Status' ? true : false,
				issueStatusSection: selectedOption === 'Issue Status' ? true : false,
				riskSection: selectedOption === 'Risk' ? true : false,
				durationSection: selectedOption === 'Duration' ? true : false,
				serviceSection: selectedOption === 'Service' ? true : false,
				resourcesSection: selectedOption === 'Resources' ? true : false,
				ipAddressSection: selectedOption === 'IP Address' ? true : false,
				networkingSection: selectedOption === 'Networking' ? true : false,
				complianceSection: selectedOption === 'Compliance' ? true : false,
				regionSection: selectedOption === 'Region' ? true : false,
				categorySection: selectedOption === 'Category' ? true : false,
				lifecycleSection: selectedOption === 'Lifecycle' ? true : false,  accessedBySection: selectedOption === 'Accessed By' ? true : false,
				monitoringSection: selectedOption === 'Monitoring' ? true : false,
				accessMethodSection: selectedOption === 'Access Method' ? true : false,
				scoreSection: selectedOption === 'Score' ? true : false,
				locationSection: selectedOption === 'Location' ? true : false,
			})
		}

		if(selectedOption === 'Account') {
			let accountdata = []
			if(this.state.accounts.length) {
                this.state.accounts.forEach((account, i) => {
                    let dataRow = {}
					let exist = 0
					if(this.state.accountSelected.length) {
						this.state.accountSelected.forEach((row, index) => {
							if(account.account_name === row.value) {
								exist = 1
							}
						})
						if(exist === 0) {
							dataRow.value = account.account_id
							dataRow.label = account.account_name
							accountdata.push(dataRow)
						}
					} else {
						dataRow.value = account.account_id
						dataRow.label = account.account_name
						accountdata.push(dataRow)
					}
                })
			}

			this.setState({ accountArray: accountdata })
		}

	}

	selectedLifecycleFunction = (lifeCycle) => {
		this.setState({ searchText: '' })
		if(lifeCycle !== false) {
			//console.log('lifecycle',lifeCycle)

			Object.entries(lifeCycle).forEach(([key, value]) => {
				let item1 = '';
				let item2 = '';
				let item3 = '';
				let item4 = '';
				value.forEach(item => {
					if(item.label === 'Action') {
						item1 = item.value
					}
					if(item.label === 'Criteria') {
						item2 = item.value
					}
					if(item.label === 'Condition') {
						item3 = item.value
					}
					if(item.label === 'text') {
						item4 = item.value
					}
				})

				let dataRow = {}
				dataRow.label = 'Lifecycle';
				dataRow.value = item1+' '+item2+' '+item3+' '+item4
				dataRow.checkDuplicate = item1+' '+item2+' '+item3

				if(dataRow.value !== '') {
					//let filterArray = this.state.filterValue
					// this.state.filterValue.forEach((row, index) => {
					// 	let splitSpace = row.value.split(' ');
					// 	if(row.label === 'Lifecycle' && splitSpace[0] === item1 && splitSpace[1] === item2 && splitSpace[2] === item3) {
					// 		filterValueExist = 1
					// 		filterArray.splice(index, 1);
					// 		this.setState({ filterValue : filterArray });
							
					// 		this.setState(prevState => ({
					// 			filterValue: [...prevState.filterValue, dataRow]
					// 		}))
					// 	}
						
					// })
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Lifecycle')
							this.filterOnchange()
						}
					)
				}
			})
		}

		this.setState({ lifecycleSection: false })
	}

	selectedAccessedByFunction = (accessedby) => {
		this.setState({ searchText: '' })
		if(accessedby !== false) {
			console.log('accessedby',accessedby)
			let cross = accessedby.cross
			let root = accessedby.root
			let crossLabel = ''
			if(cross) {
				crossLabel = ' from Cross account'
			}

			let rootLabel = ''
			if(root) {
				rootLabel = ' from Root account'
			}
			Object.entries(accessedby).forEach(([key, value]) => {
				// let item1 = '';
				// let item2 = '';
				// let item3 = '';
				// let item4 = '';
				if(key === 'Role' || key === 'User') {
					value.forEach(item => {
						let dataRow = {}
						dataRow.label = 'Accessed By'
						dataRow.value = key+' : '+item+rootLabel+crossLabel
						dataRow.checkDuplicate = key+' : '+item+rootLabel+crossLabel
						// this.state.filterValue.forEach((row, index) => {
						// 	if(row.label === 'Accessed By' && row.value === item) {
						// 		filterArray.splice(index, 1);
						// 	}
						// 	this.setState({ filterValue : filterArray });
						// })
						
						this.setState(prevState => ({
							filterValue: [...prevState.filterValue, dataRow], searchText: ''
						}),
							() => {
								this.checkDuplicate('Accessed By')
								this.filterOnchange()
							}
						)
					})
				}
			})
		}
		this.setState({ accessedBySection: false })
	}

	selectedMonitoringFunction = (monitor) => {
		this.setState({ searchText: '' })
		if(monitor !== false) {
			console.log('monitor',monitor)

			Object.entries(monitor).forEach(([key, value]) => {
				let item1 = '';
				let item2 = '';
				let item3 = '';
				let item4 = '';
				value.forEach(item => {
					if(item.label === 'Monitoring') {
						item1 = item.value
					}
					if(item.label === 'Criteria') {
						item2 = item.value
					}
					if(item.label === 'Condition') {
						item3 = item.value
					}
					if(item.label === 'text') {
						item4 = item.value
					}
				})
				let dataRow = {}
				dataRow.label = 'Monitoring';
				dataRow.value = item1+' '+item2+' '+item3+' '+item4
				dataRow.checkDuplicate = item1+' '+item2+' '+item3

				this.setState(prevState => ({
					filterValue: [...prevState.filterValue, dataRow], searchText: ''
				}), 
					() => {
						this.checkDuplicate('Monitoring')
						this.filterOnchange()
					}
				)
			})
		}
		this.setState({ monitoringSection: false })
	}

	selectedAccessMethodFunction = (accessMethod) => {
		this.setState({ searchText: '' })
		if(accessMethod !== false) {
			console.log('accessMethod',accessMethod)

			Object.entries(accessMethod).forEach(([key, value]) => {
				let item1 = '';
				let item2 = '';
				let item3 = '';
				let item4 = '';
				value.forEach(item => {
					if(item.label === 'Access Method') {
						item1 = item.value
					}
					if(item.label === 'Criteria') {
						item2 = item.value
					}
					if(item.label === 'Condition') {
						item3 = item.value
					}
					if(item.label === 'text') {
						item4 = item.value
					}
				})
				let dataRow = {}
				dataRow.label = 'Access Method';
				dataRow.value = item1+' '+item2+' '+item3+' '+item4
				dataRow.checkDuplicate = item1+' '+item2+' '+item3
				// let filterValueExist = 0
				// let filterArray = this.state.filterValue

				// if(this.state.filterValue.lenght) {
				// 	this.state.filterValue.forEach((row, index) => {
				// 		let splitSpace = row.value.split(' ');
				// 		if(row.label === 'Access Method' && splitSpace[0] === item1 && splitSpace[1] === item2 && splitSpace[2] === item3) {
				// 			filterValueExist = 1
				// 			filterArray.splice(index, 1);
							
				// 			this.setState({ filterValue : filterArray });

				// 			this.setState(prevState => ({
				// 				filterValue: [...prevState.filterValue, dataRow]
				// 			}))
				// 		}
				// 	})
				// }

				this.setState(prevState => ({
					filterValue: [...prevState.filterValue, dataRow], searchText: ''
				}), 
					() => {
						this.checkDuplicate('Access Method')
						this.filterOnchange()
					}
						
				)
			})
		}

		this.setState({ accessMethodSection: false })
	}

	checkDuplicate = (label) => {
		console.log('ssss',this.state.filterValue)
		console.log(label)
		let arr = this.state.filterValue
		console.log(arr)
		var reversed = [];
		for(let i = arr.length-1; i >= 0; i--) {
			reversed.push(arr[i]);
		}
		
		//const reversed = arr.reverse();
		console.log(reversed)
		let duplicate = reversed.filter( (ele, ind) => ind === reversed.findIndex( elem => elem.label === ele.label && elem.checkDuplicate === ele.checkDuplicate))
		console.log(reversed)
		console.log(duplicate)
		var regular = [];
		for(let i = duplicate.length-1; i >= 0; i--) {
			regular.push(duplicate[i]);
		}
		this.setState({ filterValue: regular, searchText: '' })
	}
	
	selectedScoreFunction = (score) => {
		this.setState({ searchText: '' })
		if(score !== false) {
			console.log('score',score)

			Object.entries(score).forEach(([key, value]) => {
				let item1 = '';
				let item2 = '';
				let item3 = '';
				let item4 = '';
				value.forEach(item => {
					if(item.label === 'Score') {
						item1 = item.value
					}
					if(item.label === 'Condition') {
						item2= item.value
					}
					if(item.label === 'text_1') {
						item3 = item.value
					}
					if(item.label === 'text_2') {
						item4 = item.value
					}
				})
				let dataRow = {}
				dataRow.label = 'Score';
				if(item2 === 'between') {
					dataRow.value = item1+' '+item2+' '+item3+' to '+item4
				} else {
					dataRow.value = item1+' '+item2+' '+item3
				}
				dataRow.checkDuplicate = item1+' '+item2

				this.setState(prevState => ({
					filterValue: [...prevState.filterValue, dataRow], searchText: ''
				}), 
					() => {
						this.checkDuplicate('Score')
						this.filterOnchange()
					}
				)
			})
		}
		this.setState({ monitoringSection: false })
		this.setState({ scoreSection: false })
	}

	selectedAccountFunction = (value, valueLabel) => {
		console.log()
		console.log(valueLabel)
		let dataRow = {}
		dataRow.label = 'Account'
		dataRow.value = value
		dataRow.valueLabel = valueLabel
		dataRow.checkDuplicate = value

		let accountExist = 0
		var accountArray = this.state.accountSelected
		this.state.accountSelected.forEach((row, index) => {
			if(row.label === 'Account' && row.value === value) {
				accountArray.splice(index, 1);
				accountExist = 1;
			}
			this.setState({ accountSelected : accountArray, searchText: '' });
		})
		
		if(accountExist === 0) {
			this.setState(prevState => ({
				accountSelected: [...prevState.accountSelected, dataRow],
				searchText: ''
			}))
		}

		if(this.state.searchText === '' && this.state.accountSelected.length === 0) {
			this.setState({ selectedDefaultOption: '' },
				() => this.selectedOptionFunction('Account')
			)
		}

		let filterValueExist = 0
		let filterArray = this.state.filterValue
		this.state.filterValue.forEach((row, index) => {
			if(row.label === 'Account' && row.value === value) {
				filterValueExist = 1
				filterArray.splice(index, 1);
			}
			this.setState({ filterValue : filterArray });
		})
		if(filterValueExist === 0) {
			this.setState(prevState => ({
				filterValue: [...prevState.filterValue, dataRow], searchText: ''
			}))
		}

		console.log('account', this.state.accountSelected)
	}

	selectedIssueStatusFunction = (value) => {
		let dataRow = {}
		dataRow.label = 'Issue Status'
		dataRow.value = value
		dataRow.checkDuplicate = value

		// let exist = 0
		var isseuStatusArray = this.state.issueStatusSelected
		this.state.issueStatusSelected.forEach((row, index) => {
			if(row.label === 'Issue Status' && row.value === value) {
				isseuStatusArray.splice(index, 1);
				// exist = 1;
			}
			this.setState({ issueStatusSelected : isseuStatusArray, searchText: '' });
		})
		
		this.setState(prevState => ({
			issueStatusSelected: [...prevState.issueStatusSelected, dataRow],
			searchText: ''
		}))

		if(this.state.searchText === '' && this.state.issueStatusSelected.length === 0) {
			this.setState({ selectedDefaultOption: '' },
				() => this.selectedOptionFunction('Issue Status')
			)
		}

		let filterValueExist = 0
		let filterArray = this.state.filterValue
		this.state.filterValue.forEach((row, index) => {
			if(row.label === 'Issue Status' && row.value === value) {
				filterValueExist = 1
				filterArray.splice(index, 1);
			}
			this.setState({ filterValue : filterArray });
		})

		if(filterValueExist === 0) {
			this.setState(prevState => ({
				filterValue: [...prevState.filterValue, dataRow], searchText: ''
			}))
		}
	}

	selectedChecksStatusFunction = (value) => {
		let dataRow = {}
		dataRow.label = 'Checks Status'
		dataRow.value = value
		dataRow.checkDuplicate = value

		var statusArray = this.state.statusSelected
		this.state.statusSelected.forEach((row, index) => {
			if(row.label === 'Checks Status' && row.value === value) {
				statusArray.splice(index, 1);
			}
			this.setState({ statusSelected : statusArray, searchText: '' });
		})
		
		this.setState(prevState => ({
			statusSelected: [...prevState.statusSelected, dataRow],
			searchText: ''
		}))

		if(this.state.searchText === '' && this.state.statusSelected.length === 0) {
			this.setState({ selectedDefaultOption: '' },
				() => this.selectedOptionFunction('Checks Status')
			)
		}

		let filterValueExist = 0
		let filterArray = this.state.filterValue
		this.state.filterValue.forEach((row, index) => {
			if(row.label === 'Checks Status' && row.value === value) {
				filterValueExist = 1
				filterArray.splice(index, 1);
			}
			this.setState({ filterValue : filterArray });
		})

		if(filterValueExist === 0) {
			this.setState(prevState => ({
				filterValue: [...prevState.filterValue, dataRow], searchText: ''
			}))
		}
	}
	
	selectedRiskFunction = (value) => {
		let dataRow = {}
		dataRow.label = 'Risk'
		dataRow.value = value
		dataRow.checkDuplicate = value

		var riskArray = this.state.riskSelected
		this.state.riskSelected.forEach((row, index) => {
			if(row.label === 'Risk' && row.value === value) {
				riskArray.splice(index, 1);
			}
			this.setState({ riskSelected : riskArray, searchText: '' });
		})
		
		this.setState(prevState => ({
			riskSelected: [...prevState.riskSelected, dataRow],
			searchText: ''
		}))

		if(this.state.searchText === '' && this.state.riskSelected.length === 0) {
			this.setState({ selectedDefaultOption: '' },
				() => this.selectedOptionFunction('Risk')
			)
		}

		let filterValueExist = 0
		let filterArray = this.state.filterValue
		this.state.filterValue.forEach((row, index) => {
			if(row.label === 'Risk' && row.value === value) {
				filterValueExist = 1
				filterArray.splice(index, 1);
			}
			this.setState({ filterValue : filterArray });
		})

		if(filterValueExist === 0) {
			this.setState(prevState => ({
				filterValue: [...prevState.filterValue, dataRow], searchText: ''
			}))
		}
	}
	
	selectedCategoryFunction = (value) => {
		let dataRow = {}
		dataRow.label = 'Category'
		dataRow.value = value
		dataRow.checkDuplicate = value

		var categoryArray = this.state.categorySelected
		this.state.categorySelected.forEach((row, index) => {
			if(row.label === 'Category' && row.value === value) {
				categoryArray.splice(index, 1);
			}
			this.setState({ categorySelected : categoryArray, searchText: '' });
		})
		
		this.setState(prevState => ({
			categorySelected: [...prevState.categorySelected, dataRow],
			searchText: ''
		}))

		if(this.state.searchText === '' && this.state.categorySelected.length === 0) {
			this.setState({ selectedDefaultOption: '' },
				() => this.selectedOptionFunction('Category')
			)
		}

		let filterValueExist = 0
		let filterArray = this.state.filterValue
		this.state.filterValue.forEach((row, index) => {
			if(row.label === 'Category' && row.value === value) {
				filterValueExist = 1
				filterArray.splice(index, 1);
			}
			this.setState({ filterValue : filterArray });
		})

		if(filterValueExist === 0) {
			this.setState(prevState => ({
				filterValue: [...prevState.filterValue, dataRow], searchText: ''
			}))
		}
	}

	selectedRegionFunction = (value) => {
		let dataRow = {}
		dataRow.label = 'Region'
		dataRow.value = value
		dataRow.checkDuplicate = value

		var regionArray = this.state.regionSelected
		this.state.regionSelected.forEach((row, index) => {
			if(row.label === 'Region' && row.value === value) {
				regionArray.splice(index, 1);
			}
			this.setState({ regionSelected : regionArray, searchText: '' });
		})
		
		this.setState(prevState => ({
			regionSelected: [...prevState.regionSelected, dataRow],
			searchText: ''
		}))

		if(this.state.searchText === '' && this.state.regionSelected.length === 0) {
			this.setState({ selectedDefaultOption: '' },
				() => this.selectedOptionFunction('Region')
			)
		}

		let filterValueExist = 0
		let filterArray = this.state.filterValue
		this.state.filterValue.forEach((row, index) => {
			if(row.label === 'Region' && row.value === value) {
				filterValueExist = 1
				filterArray.splice(index, 1);
			}
			this.setState({ filterValue : filterArray });
		})

		if(filterValueExist === 0) {
			this.setState(prevState => ({
				filterValue: [...prevState.filterValue, dataRow], searchText: ''
			}))
		}
	}
	
	selectedDurationFunction = (value) => {
		let dataRow = {}
		dataRow.value = 'from '+ value[0].from+' to '+value[0].to
		dataRow.start_time = value[0].start_time
		dataRow.end_time = value[0].end_time
		dataRow.label = 'Duration'
		dataRow.selectedDuration = value[0].selectedDuration
		dataRow.checkDuplicate = 'Duration'
		this.setState(prevState => ({
			filterValue: [...prevState.filterValue, dataRow], searchText: ''
		}), 
			() =>  {
				this.checkDuplicate('Duration')
				this.filterOnchange()
			}
		)
		this.setState({ durationSection: false })
	}

	selectedLocationFunction = (value) => {
		
		if(value !== false) {
			console.log(value.country.country.length)
			if(value.country.country && value.country.country.length > 0) {
				value.country.country.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = 'Country '+value.country.type+' '+selected
					dataRow.label = 'Location'
					dataRow.checkDuplicate = 'Country '+selected
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Location')
							this.filterOnchange()
						}
					)
				})
			}

			if(value.geography.geography && value.geography.geography.length > 0) {
				value.geography.geography.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = 'Geography '+selected
					dataRow.label = 'Location'
					dataRow.checkDuplicate = 'Geography '+selected

					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Location')
							this.filterOnchange()
						}
					)
				})
			}

			if(value.region.region && value.region.region.length > 0) {
				value.region.region.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = 'Region '+selected
					dataRow.label = 'Location'
					dataRow.checkDuplicate = 'Region '+selected

					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Location')
							this.filterOnchange()
						}
					)
				})
			}
		}
		this.setState({ locationSection: false })
	}

	selectedServiceFunction = (services) => {
		this.setState({ searchText: '' })
		if(services !== false) {
			console.log(services)
			if(services.service && services.service.length > 0) {
				services.service.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = services.type+' '+selected
					dataRow.label = 'Services'
					dataRow.checkDuplicate = selected
					console.log(dataRow)
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Services')
							this.filterOnchange()
						}
					)
				})
			}
		}
		this.setState({ serviceSection: false })
	}
	
	selectedResourcesFunction = (array) => {
		this.setState({ searchText: '' })
		if(array !== false) {
			console.log(array)
			if(array.resourceIds && array.resourceIds.length > 0) {
				array.resourceIds.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = array.type+' '+array.Resource+' '+selected
					dataRow.label = 'Resource '
					dataRow.checkDuplicate = array.type+' '+array.Resource+' '+selected
					console.log(dataRow)
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Ressource')
							this.filterOnchange()
						}
					)
				})
			}
		}
		this.setState({ resourcesSection: false })
	}

	selectedIpAddressFunction = (array) => {
		this.setState({ searchText: '' })
		if(array !== false) {
			console.log(array)
			if(array.IpAddress && array.IpAddress.length > 0) {
				array.IpAddress.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = array.type+' '+selected
					dataRow.label = 'IP Address'
					dataRow.checkDuplicate = array.type+' '+selected
					console.log(dataRow)
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('IP Address')
							this.filterOnchange()
						}
					)
				})
			}
		}
		this.setState({ ipAddressSection: false })
	}

	selectedNetworkingFunction = (networking) => {
		this.setState({ searchText: '' })
		if(networking !== false) {
			if(networking.networkingIds && networking.networkingIds.length > 0) {
				networking.networkingIds.forEach((selected, i) => {
					let dataRow = {}
					dataRow.value = networking.type+' '+selected
					dataRow.label = 'Networking'
					dataRow.checkDuplicate = networking.type+' '+selected
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('Networking')
							this.filterOnchange()
						}
					)
				})
			}
		}
		this.setState({ networkingSection: false })
	}

	selectedComplianceFunction = (array) => {
		this.setState({ searchText: '' })
		if(array !== false) {
			console.log(array)
			array && array.length && array.forEach((item, i) => {
				console.log(item, i)
				item.selectedControl && item.selectedControl !== undefined && item.selectedControl.length ? 
					item.selectedControl.forEach((ctrl, x) => {
						let dataRow = {}
						dataRow.value = ctrl
						dataRow.label = 'compliance'
						dataRow.checkDuplicate = ctrl
						this.setState(prevState => ({
							filterValue: [...prevState.filterValue, dataRow], searchText: ''
						}),
							() => {
								this.checkDuplicate('compliance')
								this.filterOnchange()
							}
						)
					})

				:
				
				item.majorControl && item.majorControl !== undefined && item.majorControl.length &&
					item.majorControl.forEach((ctrl, x) => {
						let dataRow = {}
						dataRow.value = ctrl
						dataRow.label = 'compliance'
						dataRow.checkDuplicate = ctrl
						this.setState(prevState => ({
							filterValue: [...prevState.filterValue, dataRow], searchText: ''
						}),
							() => {
								this.checkDuplicate('compliance')
								this.filterOnchange()
							}
						)
					})

				
				if(item.selectedControl === undefined && item.majorControl === undefined) {
					let dataRow = {}
					dataRow.value = item.selectedCompliance
					dataRow.label = 'compliance'
					dataRow.checkDuplicate = item.selectedCompliance
					this.setState(prevState => ({
						filterValue: [...prevState.filterValue, dataRow], searchText: ''
					}),
						() => {
							this.checkDuplicate('compliance')
							this.filterOnchange()
						}
					)
				}
			})
		}
		console.log(this.state.filterValue,'[[[[[[[[[[[[[[[[[[[[[[[[')
		this.setState({ complianceSection: false })
	}	
	
	removefilter = (index, label, value) => {
		this.setState({
			showDropdownOption: false,
			lifecycleSection: false,
			accessedBySection: false,
			monitoringSection: false,
			accessMethodSection: false,
			scoreSection: false,
			acccountSection: false,
			statusSection: false,
			issueStatusSection: false,
			riskSection: false,
			categorySection: false,
			regionSection: false,
			durationSection: false,
			serviceSection: false,
			resourcesSection: false,
			ipAddressSection: false,
			networkingSection: false,
			complianceSection: false,
			locationSection: false,
		})

		let filterArray = this.state.filterValue
		filterArray.splice(index, 1);
		this.setState({ filterValue : filterArray });
    }

	saveFilterItem = () => {
        this.setState({ showSaveLoading: true, responseMessage: ''})
        
        if(this.state.searchName === '') {
            this.setState({ hasErrorInRuleForm: true, labelMessage: 'Please Enter the search name' })
        }

        if(!this.state.filterValue.length) {
            this.setState({ hasErrorInRuleForm: true, filterMessage: 'Please select filter to save' })
        }

		let params = {}
		params.label = this.state.searchName
		params.selectedFilterItem = this.state.filterValue
        
		// if(!this.state.hasErrorInRuleForm) {
		// 	this.props.resolveIssue(params, (promise, response) => {
		// 		console.log(response)
		// 		this.setState({
		// 			closeStatusType: '',
		// 			closeReason: '',
		// 			closeAttachment: '',
		// 			responseMessage: response.data ? response.data.message : '',
		// 			showMessage: true,
		// 			showSaveLoading: false,
		// 			hasErrorInRuleForm: false,
		// 			responseStatus: response.data ? response.data.status : false,
		// 		}, 
		// 			() => this.hideMessage()
		// 		)
		// 	})
        //}
        // this.setState({
        //     showMessage: true,
        //     showSaveLoading: false,
        //     hasErrorInRuleForm: false,
        //     responseStatus: true,
        //     responseMessage: 'Saved Successfully'
        // })
    }
    
    onReset = () => {
        this.setState({ 
                filterValue: [], 
				accountSelected: [],
				statusSelected: [],
				issueStatusSelected: [],
				riskSelected: [],
				categorySelected: [],
				regionSelected: [],
				acccountSection: false,
				statusSection: false,
				issueStatusSection: false,
				riskSection: false,
				categorySection: false,
				regionSection: false,
                durationSection: false,
				serviceSection: false,
				resourcesSection: false,
				ipAddressSection: false,
				networkingSection: false,
				complianceSection: false,
				
                lifecycleSection: false,
                accessedBySection: false,
                monitoringSection: false,
                accessMethodSection: false,
                scoreSection: false,
                locationSection: false,
            },
            () => this.props.resetFilter()
        )
    }
	
	
	/**
	 * Renders User Insights
	 */
	render() {
		return (
			<div className='container-fluid overflow-auto flex-grow-1'
                onClick={ (event) => { this.handleClickOutside(event) } }
                >
                    <div className='pb-3 mt-2 commonDropdown'>   
                        <div className="d-flex justify-content-between">
                            <div className="form-control mr-3">
                                <div className="comparisonTextboxDiv">
                                    <input type="text" className={`comparisonTextbox`}
                                    onChange={e => {this.setState({ searchText: e.target.value }, () => this.filterOnchange())}}
                                    onKeyUp={this.onKeyUp}
                                    value={this.state.searchText}
                                    /*{this.state.selectedDefaultOption === '' ? this.state.searchText : this.state.selectedDefaultOption +': ' } */
                                    onClick={ () => this.comparisonFilter() }
                                    placeholder={this.state.placeHolder}
                                />                                
                                
                                    {this.state.showDropdownOption ? 
                                        <CommonDropdown 
                                            data = {this.selectedOptionFunction.bind(this)}
                                            hideHeader = {true}
                                            headerName = {'Dropdown'}
                                            dropOptions = {this.state.mainDropdown}
                                            displayMenu = {this.state.showDropdownOption}
                                            isMultiSelect = {false}
                                            isDefaultDropdown = {this.state.isDefaultDropdown}
                                            styleTop={'fitContent'}
                                        /> 
                                    :
                                    null
                                    }

                                    {this.state.acccountSection ? 
                                        <CommonDropdown 
                                            data = {this.selectedAccountFunction.bind(this)}
                                            hideHeader = {true}
                                            headerName = {'Account'}
                                            dropOptions = {this.state.accountArray}
                                            displayMenu = {true}
                                            isMultiSelect = {true}
                                            isDefaultDropdown = {false}
                                            styleTop={'fitContent'}
                                        />
                                    :
                                    null
                                    }

									{this.state.statusSection ? 
                                        <CommonDropdown 
                                            data = {this.selectedChecksStatusFunction.bind(this)}
                                            hideHeader = {true}
                                            headerName = {'Checks Status'}
                                            dropOptions = {statusDropDown}
                                            displayMenu = {true}
                                            isMultiSelect = {true}
                                            isDefaultDropdown = {false}
                                            styleTop={'fitContent'}
                                        />
                                    :
                                    null
                                    }

									{this.state.issueStatusSection ? 
                                        <CommonDropdown 
                                            data = {this.selectedIssueStatusFunction.bind(this)}
                                            hideHeader = {true}
                                            headerName = {'Checks Status'}
                                            dropOptions = {issueStatusDropDown}
                                            displayMenu = {true}
                                            isMultiSelect = {true}
                                            isDefaultDropdown = {false}
                                            styleTop={'fitContent'}
                                        />
                                    :
                                    null
                                    }	

									{this.state.riskSection ? 
                                        <CommonDropdown 
                                            data = {this.selectedRiskFunction.bind(this)}
                                            hideHeader = {true}
                                            headerName = {'Risk'}
                                            dropOptions = {riskDropDown}
                                            displayMenu = {true}
                                            isMultiSelect = {true}
                                            isDefaultDropdown = {false}
                                            styleTop={'fitContent'}
                                        />
                                    :
                                    null
                                    }										

									{this.state.categorySection ? 
                                        <CommonDropdown 
                                            data = {this.selectedCategoryFunction.bind(this)}
                                            hideHeader = {true}
                                            headerName = {'Category'}
                                            dropOptions = {this.state.newCategoryArray}
                                            displayMenu = {true}
                                            isMultiSelect = {true}
                                            isDefaultDropdown = {false}
                                            styleTop={'fitContent'}
                                        />
                                    :
                                    null
									}
									
									{this.state.regionSection ? 
                                        <CommonDropdown 
                                            data = {this.selectedRegionFunction.bind(this)}
                                            hideHeader = {true}
                                            headerName = {'Region'}
                                            dropOptions = {this.state.regionArray}
                                            displayMenu = {true}
                                            isMultiSelect = {true}
                                            isDefaultDropdown = {false}
                                            styleTop={'fitContent'}
                                        />
                                    :
                                    null
									}

                                    {this.state.durationSection ? 
                                        <DurationFilterSection 
                                            data = {this.selectedDurationFunction.bind(this)}
                                            //sectionDetails = {''}
                                        />
                                    :
                                    null
                                    }

                                    {this.state.lifecycleSection ? 
                                        <div ref={this.LifecycleFilterSection}>
                                        <LifecycleFilterSection 
                                            data = {this.selectedLifecycleFunction.bind(this)}
                                            //sectionDetails = {''}
                                        />
                                        </div>
                                    : null
                                    }

                                    {this.state.accessedBySection ?	
                                        <AccessedByFilterSection 
                                            data = {this.selectedAccessedByFunction.bind(this)}
                                            selectedProvider = {this.state.selectedProvider.toLowerCase()}
                                            selectedAccount = {this.state.selectedAccount}
                                            //sectionDetails = {''}
                                        />
                                    : null
                                    }

                                    {this.state.monitoringSection ?	
                                        <MonitoringFilterSection 
                                            data = {this.selectedMonitoringFunction.bind(this)}
                                        />
                                    : null
                                    }

                                    {this.state.accessMethodSection ?	
                                        <AccessMethodFilterSection 
                                        />
                                    : null
                                    }

                                    {this.state.scoreSection ?	
                                        <ScoreFilterSection 
                                            data = {this.selectedScoreFunction.bind(this)}
                                        />
                                    : null
                                    }

                                    {this.state.locationSection ?	
                                        <LocationFilterSection 
                                            data = {this.selectedLocationFunction.bind(this)}
                                            selectedProvider = {this.state.selectedProvider.toLowerCase()}
                                            selectedAccount = {this.state.selectedAccount}
                                            //sectionDetails = {this.state.selectedDefaultOption}
                                        />
                                    : null
                                    }

                                    {this.state.serviceSection ?	
                                        <ServiceFilterSection 
                                            data = {this.selectedServiceFunction.bind(this)}
                                            //sectionDetails = {this.state.selectedDefaultOption}
                                        />
                                    : null
                                    }

									{this.state.resourcesSection ?	
                                        <ResourceFilterSection 
                                            data = {this.selectedResourcesFunction.bind(this)}
                                            //sectionDetails = {this.state.selectedDefaultOption}
                                        />
                                    : null
                                    }

									{this.state.ipAddressSection ?	
                                        <IPAddressFilterSection 
                                            data = {this.selectedIpAddressFunction.bind(this)}
                                            //sectionDetails = {this.state.selectedDefaultOption}
                                        />
                                    : null
                                    }

									{this.state.networkingSection ?	
                                        <NetworkingFilterSection 
                                            data = {this.selectedNetworkingFunction.bind(this)}
                                            //sectionDetails = {this.state.selectedDefaultOption}
                                        />
                                    : null
                                    }

									{this.state.complianceSection ?	
                                        <ComplianceFilterSection 
                                            data = {this.selectedComplianceFunction.bind(this)}
                                            //selectedAccount = {this.state.selectedAccount}
                                        />
                                    : null
                                    }
                                </div>
                            </div>
                            <div className={`ml-3 d-flex align-self-center ${this.state.filterValue.length ? '' : 'disabled'}`}>
                                <div className="btn-group" role="group" aria-label="status-builder">
                                    <button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> this.props.onSearch(this.state.filterValue)}>Search</button>
                                    <button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
                                </div>
                            </div>
                        </div>
                        <div className={`float-right mt-2`}>
                            <p className={`textInfo m-0 f13 text-right cursorPointer ${this.state.filterValue.length ? '' : 'displayNone'}`}
                                onClick={() => this.setState({ showSaveOption: true }) }>
                                Save Filter
                            </p>
                            
                            {this.props.saveButton && this.state.showSaveOption ?
                                <React.Fragment>
                                    {this.state.showMessage ?
                                        <p className={` ${this.state.responseStatus ? 'text-success' : 'text-danger' }`}>{this.state.responseMessage}</p> 
                                    : null }
                                    <div className="text-right">                                                
                                        <input type="text" 
                                            className={`form-control-sm mr-2 w-100`}
                                            onChange={e => {
                                                this.setState({ searchName: e.target.value })
                                            }}
                                            value={this.state.searchName}
                                            placeholder={'Search Name'}
                                        />
                                        <button
                                            className={`btn btn-sm btn-primary`}
                                            onClick={() => this.saveFilterItem()}
                                            disabled={this.state.showSaveLoading}
                                        >
                                            {this.state.showSaveLoading && !this.state.hasErrorInRuleForm ? (
                                                <React.Fragment>
                                                    {'Ok'}
                                                    <Spinner className='text-center ml-2' color='light' size='sm' />{' '}
                                                </React.Fragment>
                                            ) : 'Ok'
                                            }
                                        </button>
                                        {/* <div className="btn-group">
                                            <button type="button" className={`cursorPointer btn btn-primary border-right`} onClick={()=> this.saveFilterItem(this.state.searchName, this.state.filterValue)}>Save</button>
                                        </div> */}
                                    </div>
                                    {this.state.hasErrorInRuleForm && this.state.searchName === '' ? (
                                        <p className='text-danger f12 text-right'>{this.state.labelMessage}</p>
                                    ) : (
                                        ''
                                    )}
                                </React.Fragment>
                            : null
                            }
                        </div>
                        <div className="mt-2">
                            {this.state.filterValue.length && Object.entries(this.props.selectedTableRow).length ? 
                                <div className="mb-1">
                                    From&nbsp;saved&nbsp;filters&emsp;
                                    <span className="text-primary font-weight-bold">{this.props.selectedTableRow.search_name}</span>
                                    :&nbsp;
                                </div>
                            : ''}
                            {

                                this.state.filterValue.length ? 
                                    this.state.filterValue.map((row, index) =>
                                        <span className="badge badge-light f14 mr-1 mb-1" >
                                         {row.label +': ' + row.value} 
                                            <i onClick={ () => this.removefilter(index, row.label, row.value) } className='ml-1 fal fa-times cursorPointer'></i>
                                        </span>
                                    )
                                : null
                            }
                        </div>
                    </div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ChecksSearchBuilder.propTypes = {
    onSearch: PropTypes.func,
    resetFilter: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		providers: state.filters.providers,
		services: state.filters.services,
		regions: state.filters.regions.length ? state.filters.regions[0].provider_regions : [],
		accounts: state.filters.accounts,
	}
}

export default connect(mapStateToProps, {
	listAllProviders,
	listAllAccounts,
	listAllRegions,
	listAllCategory
})(withRouter(ChecksSearchBuilder))