/*************************************************
 * Tvastar
 * @exports
 * @file suppressorsAction.js
 * @author Rajasekar // on 26/12/2019
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import { API } from 'aws-amplify'

import { SUPPRESSOR, LIST_SUPPRESSION, INSERT_SUPPRESSION, DELETE_SUPPRESSION } from '../../config'
import { myLog } from '../../utils/utility'
import { ACTION_TYPES } from '../types'

/**
 * Action to get all supressors list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllSuppressors = (body, callback) => {
	return async dispatch => {
		try {
			const suppressorsList = await API.post(SUPPRESSOR, LIST_SUPPRESSION, { body })
			dispatch({
				type: ACTION_TYPES.SET_SUPPRESSION_LIST,
				payload: suppressorsList,
			})
			callback(suppressorsList)
		} catch (error) {
			myLog('supress', error)

			dispatch({
				type: ACTION_TYPES.SET_SUPPRESSION_LIST,
				payload: {},
			})
			callback(false)
		}
	}
}
/**
 * Action to create a new supressor
 * @param {Object} body
 * @param {Function} callback
 */
export const createSupression = (body, callback) => {
	return async () => {
		try {
			const result = await API.post(SUPPRESSOR, INSERT_SUPPRESSION, { body })
			myLog('insert supress', result)
			callback(result)
		} catch (error) {
			myLog('insert supress', error)
		}
	}
}
/**
 * Action to delete a supressor
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteSupression = (body, callback) => {
	return async () => {
		try {
			const result = await API.post(SUPPRESSOR, DELETE_SUPPRESSION, { body })
			myLog('delete supress', result)
			callback(true, result)
		} catch (error) {
			myLog('delete supress', error)
			callback(false)
		}
	}
}
