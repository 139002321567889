/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file DetailsSection.js
 * @author Rajasekar // on 09/01/2020
 * @copyright © 2019 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Chart from 'chart.js'
import ApexChart from 'react-apexcharts'
import moment from 'moment'
import _ from 'lodash'
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip';
import CustomTable from '../../designComponents/Table/customTable'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import BeforeEvent from '../../../assets/images/BeforeEvent.svg'
import AfterEvent from '../../../assets/images/AfterEvent.svg'

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap'
import { trimmedSplitUserName1, trimmedSplitUserName2, capitalizeFirstLetter, capitalizeAllLetter, momentDate, momentFullDateTime, dayDiffrence, getTimeDifference, stringLength, momentDateGivenFormat, momentConvertionUtcToLocalTime, addHours, convertBytes, convertSeconds, countFormater, convertBits } from '../../../utils/utility'
import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'

import {
	getDailyAssetAvailability
} from '../../../actions/detectors/detectorsAction'

let statusOption = [
	{value: "", label: "All"},
	{value: "pending", label: "Pending"},
	{value: "reoccured", label: "Reoccured"},
	{value: "Resolved", label: "Resolved"},
]

let monitoringDurationOptions = [
	{value: "2h", label: "Last 2 Hrs"},
	{value: "6h", label: "Last 6 Hrs"},
	{value: "12h", label: "Last 12 Hrs"},
	{value: "24h", label: "Last 24 Hrs"},
	{value: "7d", label: "Last 7 Days"},
	{value: "14d", label: "Last 14 Days"},
	{value: "30d", label: "Last Month"},
	{value: "60d", label: "Last 2 Months"},
	{value: "90d", label: "Last 3 Months"}
]

class DetailsSection extends Component {
	constructor(props) {
		super(props)

		this.tabRef = React.createRef()

		this.state = {
			showRelatedIssues: false,
			showSummaryDetails: false,
			showAssetDetails: true,
			showMonitoringDetails: false,
			showEvents: false,
			showBillingDetails: false,
			showAvailabilityDetails: false,
			isEventsDropdownOpen: false,
			tabItemsToShow: 0,
			monitoringDuration: '2h',
			eventsDuration: '1h',
			relatedIssueDurtaion: this.props.relatedIssueDurtaion,
			showDetailsAvailSectionLoading: false,
			availabilityData: [],
			filterAvailabilityData: [],
			selectedAvailabilityMonth: 0,
			disablePreviousBtn: true,
			disableNextBtn: true,
			cpuMetricsSeries: [],
			cpuMetricsOptions: [],
			networkInMetricsSeries: [],
			networkInMetricsOptions: [],
			networkOutMetricsSeries: [],
			networkOutMetricsOptions: [],
		}
	}

	componentDidMount = () => {		
		this.setState({ 
			tabItemsToShow: this.props.detailsTabNames.length, 
			monitoringDuration: this.props.selectedMonitoringDuration 
		},
			() => this.setTabs()
		)		
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			//this.setState({ relatedIssueDurtaion: this.props.selectedDuration })
			let showSummaryDetails = false;
			let showAssetDetails = false
			let showRelatedIssues = false
			let showEvents = false
			let showMonitoringDetails = false
			let showBillingDetails = false
			let showAvailabilityDetails = false
			if(this.props.selectedTab === 'summary_details') {
				showSummaryDetails = true
			} else if(this.props.selectedTab === 'asset_details') {
				showAssetDetails = true
			} else if(this.props.selectedTab === 'related_issues') {
				showRelatedIssues = true
			} else if(this.props.selectedTab === 'events') {
				showEvents = true				
			} else if(this.props.selectedTab === 'monitoring_details') {
				showMonitoringDetails = true	
				this.metricsListGraph()			
			} else if(this.props.selectedTab === 'billing') {
				showBillingDetails = true				
			} else if(this.props.selectedTab === 'availability') {
				showAvailabilityDetails = true				
			} 

			this.setState({ showSummaryDetails, showAssetDetails, showRelatedIssues, showEvents, showMonitoringDetails, showBillingDetails, showAvailabilityDetails, showDetailsAvailSectionLoading: false, monitoringDuration: this.props.selectedMonitoringDuration })
			
		}
		if (
			this.props.selectedRowBillingDetails !== prevProps.selectedRowBillingDetails &&
			this.state.showBillingDetails &&
			!this.props.showDetailsBillingSectionLoading
		) {
			// this.showBillingGraph()
		}
	}

	setTabs = () => {
		if (this.tabRef.current) {
			// myLog(this.tabRef.current.clientWidth)
			const maxTabWidth = this.tabRef.current.clientWidth - 40
			let reqTabWidth = 0
			let allowedTabLength = 0
			for (let i = 0; i < this.tabRef.current.childNodes.length; i++) {
				reqTabWidth += this.tabRef.current.childNodes[i].clientWidth
				if (reqTabWidth < maxTabWidth) {
					allowedTabLength++
				}
			}
			this.setState({ tabItemsToShow: allowedTabLength })
		}
	}

	getDailyAssetAvailability = () => {
		this.setState({ filterAvailabilityData: [] }) 
		let params = {}
		if(this.props.selectedDuration !== 'custom') {
			params.duration = this.props.selectedDuration
		} else {
			params.start_time = this.props.start_time
			params.end_time = this.props.end_time
		}
		this.props.getDailyAssetAvailability(params, (promise, dailyAssetAvailability) => {
			if (promise) {
				this.setState({ dailyAssetAvailability }, 
					() => this.dailyAssetAvailabilityFunction()
				)
			} else {
				this.setState({ dailyAssetAvailability: [] })
			}

			//this.props.toggleDetailsSectionLoading('availability', true)
		})

	}

	dailyAssetAvailabilityFunction = () => {
		let labels = this.state.dailyAssetAvailability.labels
		let values = this.state.dailyAssetAvailability.data
		let data = []
		for(let i=0; i<labels.length; i++) {
			let dataRow = {}
			const splitlabel = labels[i].split(' ');
			let date = splitlabel[0]
			dataRow.date = date
			dataRow.availability = values.availability[i]
			dataRow.cpu_average = values.cpu.avg[i]
			dataRow.cpu_min = values.cpu.min[i]
			dataRow.cpu_max = values.cpu.min[i]
			dataRow.cpu_average = values.cpu.avg[i]
			dataRow.cpu_min = values.cpu.min[i]
			dataRow.cpu_max = values.cpu.max[i]
			dataRow.memory_average = values.memory.avg[i]
			dataRow.memory_min = values.memory.min[i]
			dataRow.memory_max = values.memory.max[i]
			dataRow.network = values.network[i]
			dataRow.disk_usage = values.disk[i]
			data.push(dataRow)
		}

		let heatDuration = 1;
		if(this.props.selectedDuration === '7d' || this.props.selectedDuration === '+7d' || this.props.selectedDuration === '+14d' || this.props.selectedDuration === '+30d') {
			heatDuration = 1
			this.setState({ heatDuration })
		} else if(this.props.selectedDuration === '+60d' || this.props.selectedDuration === '+14d' || this.props.selectedDuration === '+30d') {
			heatDuration = 2
			this.setState({ heatDuration })
		} else {
			heatDuration = 3
			this.setState({ heatDuration })
		}

		let week = 0;
		let first = data[0].date;
		const splitDate = first.split('-');
		let year = splitDate[0]
		let month = splitDate[1]
		let date = moment([year, month - 1]);
		
		
		//let endDate = moment(startDate).endOf('month').toDate();
		let startDate = moment(date).subtract(1, 'days').toDate()
		let endDate = moment(startDate).add(heatDuration, 'month').toDate()
		endDate = moment(endDate).endOf('month').toDate();

		if(heatDuration === 1) {
			//to find the number of weeks in months start
			const dateFirst = moment(date).date(1)
			const dateLast = moment(date).date(date.daysInMonth())
			const startWeek = dateFirst.isoWeek()
			const endWeek = dateLast.isoWeek()
			if (endWeek < startWeek) {
				week = dateFirst.weeksInYear() - startWeek + 1 + endWeek
			} else {
				week = endWeek - startWeek + 1
			}
			//to find the number of weeks in months end
		}

		let last = data[data.length-1].date;
		const splitLastDate = last.split('-');
		let lastYear = splitLastDate[0]
		let lastMonth = splitLastDate[1]
		
		if(parseInt(lastMonth === month)) {
			this.setState({ disableNextBtn: true,  disablePreviousBtn: true })
		} else {
			this.setState({ disableNextBtn: false })
		}

		let monthName = moment(endDate).format('MMMM')
		
		this.setState({ showDetailsAvailSectionLoading: true, availabilityData: data, filterAvailabilityData: data, startDate, endDate, monthName, month, year, firstMonth: month, lastMonth, firstYear: year, lastYear, week})
	}

	filterAvailabilityDetails = (type) => {
		this.setState({ disableNextBtn: false, disablePreviousBtn: false })
		let startDate = ''
		let endDate = ''
		let month = ''
		let year = ''
		let date = ''
		let week = 0;
		if(type === 'next') {			
			startDate = this.state.endDate
			date = moment(startDate).subtract(-1, 'days')
			endDate = moment(startDate).add(this.state.heatDuration, 'month').toDate()
			endDate = moment(endDate).endOf('month').toDate();
			month = moment(endDate).format('MM')
			year = moment(endDate).format('YYYY')
			
			if(parseInt(this.state.lastMonth) <= parseInt(month)) {
				this.setState({ disableNextBtn: true, disablePreviousBtn: false })
			}
		} else {
			endDate = this.state.startDate
			startDate = moment(endDate).subtract(this.state.heatDuration, 'month').toDate()
			startDate = moment(startDate).endOf('month').toDate();
			date = moment(startDate).subtract(-1, 'days')
			month = moment(endDate).format('MM')
			year = moment(endDate).format('YYYY')
			if(this.state.heatDuration > 1) {
				if(parseInt(this.state.firstMonth) <= parseInt(month)) {
					this.setState({ disableNextBtn: false, disablePreviousBtn: true })
				}
			} else {
				if(parseInt(this.state.firstMonth) === parseInt(month)) {
					this.setState({ disableNextBtn: false, disablePreviousBtn: true })
				}
			}
		}

		if(this.state.heatDuration === 1) {
			//to find the number of weeks in months start
			const dateFirst = moment(date).date(1)
			const dateLast = moment(date).date(date.daysInMonth())
			const startWeek = dateFirst.isoWeek()
			const endWeek = dateLast.isoWeek()		
			if (endWeek < startWeek) {
			// cater to end of year (dec/jan)
				week = dateFirst.weeksInYear() - startWeek + 1 + endWeek
			} else {
				week = endWeek - startWeek + 1
			}
			//to find the number of weeks in months end
		}

		let monthName = moment(endDate).format('MMMM')

		this.setState({ filterAvailabilityData: this.state.availabilityData, startDate, endDate, monthName, month, year, week })

		
	}

	toClearSvgForNextMonth = () => {
		let formedMonth = this.state.selectedAvailabilityMonth
		let formedYear = this.state.firstYear
		let year = formedYear
		let month = formedMonth
		if(formedMonth > 12) {
			formedMonth = formedMonth - 12
			formedYear = parseInt(year) + parseInt(1)

			year = formedYear
			month = formedMonth
		} else if(formedMonth === 0) {
			month = 12
		}

		let availabilityData = this.state.availabilityData
		let data = []
		availabilityData.forEach(item => {
			let date = item.date;
			const splitDate = date.split('-');
			if(month === splitDate[1]) {
				data.push(item)
			}
		})

		if(parseInt(this.state.lastMonth) === parseInt(month)) {
			this.setState({ disableNextBtn: true, disablePreviousBtn: false })
		}

		if(parseInt(this.state.firstMonth) === parseInt(month)) {
			this.setState({ disableNextBtn: false, disablePreviousBtn: true })
		}

		let startDate = moment([year, month - 1]);
		let endDate = moment(startDate).endOf('month').toDate();
		startDate = moment(startDate).subtract(1, 'days').toDate()
		
		let monthName = moment(endDate).format('MMMM')

		this.setState({ filterAvailabilityData: data, startDate, endDate, monthName, month, year })
	}

	/**
	 * Method to show billing graph
	 */
	showBillingGraph = () => {
		const currentBillingGraph = this.currentBillingGraph
		const lastBillingGraph = this.lastBillingGraph
		Chart.defaults.scale.gridLines.display = false
		Chart.defaults.scale.display = true
		new Chart(currentBillingGraph, {
			type: 'line',
			data: {
				datasets: [
					{
						label: 'Cost',
						data: [3.13844, 5.13807, 8, 2, 5],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
					},
					{
						label: 'Cost',
						data: [5, 7, 2, 5, 7],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
					},
					{
						label: 'Cost',
						data: [9, 2, 5, 3, 7],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
					},
				],
				labels: ['ds', 'das', 'dser', 'dsf', 'qwe'],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false,
							},
						},
					],
					yAxes: [
						{
							ticks: {
								stepSize: 1,
							},
						},
					],
				},
				elements: {
					point: {
						radius: 0,
					},
				},
				legend: {
					display: false,
				},
			},
		})
		new Chart(lastBillingGraph, {
			type: 'line',
			data: {
				datasets: [
					{
						label: 'Cost',
						data: [3.13844, 5.13807, 8, 2, 5],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
					},
					{
						label: 'Cost',
						data: [5, 7, 2, 5, 7],
						borderColor: '#ccf3d6',
						backgroundColor: '#aadfb8',
						fill: false,
						borderWidth: 3,
					},
				],
				// labels: ['ds', 'das', 'dser', 'dsf', 'qwe'],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false,
							},
						},
					],
					yAxes: [
						{
							ticks: {
								stepSize: 1,
							},
						},
					],
				},
				elements: {
					point: {
						radius: 0,
					},
				},
				legend: {
					display: false,
				},
			},
		})
	}

	/**
	 * Returns fail count of the related issues
	 */
	getRelatedIssuesFailCount = () => {
		let failCount = 0
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			const failedArray = this.props.relatedIssuesData.filter(item => item.status === 'fail')
			failCount = failedArray.length
		}
		return failCount
	}

	/**
	 * Returns pass count of the related issues
	 */
	getRelatedStatusCount = from  => {
		let passCount = 0
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			let relatedRiskCount = []
			this.props.relatedIssuesData.length &&
			_.chain(this.props.relatedIssuesData)
			.groupBy('status')
			.map((value, key) => ({ label: key, sub: value }))
			.value()
			.forEach((risk, i) => {
				relatedRiskCount.push(risk)
				// risk.sub.forEach((item, i) => {
				// 	relatedRiskCount.push(item)
				// })
			})
			relatedRiskCount.forEach(item => {
				if(item.label === from) {
					passCount = item.sub.length
				}
			})
		}
		return passCount
	}
	getRelatedIssuesPassCount = () => {
		let passCount = 0
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			const passedArray = this.props.relatedIssuesData.filter(item => item.status === 'pass')
			passCount = passedArray.length
		}
		return passCount
	}

	/**
	 * To find risk factor of a issue
	 */

	findRisk = from => {
		let riskCount = 0
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			let filteredData = this.props.relatedIssuesData
			//filteredData = filteredData.filter(item => item.status !== 'NA')
			let relatedRiskCount = []
			filteredData.length &&
			_.chain(filteredData)
			.groupBy('risk')
			.map((value, key) => ({ label: key, sub: value }))
			.value()
			.forEach((risk, i) => {
				relatedRiskCount.push(risk)
				// risk.sub.forEach((item, i) => {
				// 	relatedRiskCount.push(item)
				// })
			})
			relatedRiskCount.forEach(item => {
				if(item.label === from) {
					riskCount = item.sub.length
				}
			})
			// const highArray = this.props.relatedIssuesData.filter(item => item.risk === from)
			// riskCount = highArray.length
		}
		return riskCount
	}
	/**
	 * Toggles events duration dropdown
	 */
	toggleEventsDropdown = () => {
		this.setState({ isEventsDropdownOpen: !this.state.isEventsDropdownOpen })
	}
	/**
	 * Get total count of billing for last month
	 */
	billingLastMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
		// this.props.selectedRowBillingDetails.forEach(item => {
		Object.keys(this.props.selectedRowBillingDetails).length &&
		Object.keys(this.props.selectedRowBillingDetails.last_month).length &&
		this.props.selectedRowBillingDetails.last_month
			? (cost = this.props.selectedRowBillingDetails.last_month.total_unblended_cost)
			: (cost = 0)
		// })
		return cost
	}
	/**
	 * Get total count of billing for last three months
	 */
	billingLastThreeMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
		// this.props.selectedRowBillingDetails.forEach(item => {
		Object.keys(this.props.selectedRowBillingDetails).length &&
		this.props.selectedRowBillingDetails.duration
			? (cost = this.props.selectedRowBillingDetails.duration.total_unblended_cost)
			: (cost = 0)
		// })
		return cost
	}
	/**
	 * Get total count of billing for current month
	 */
	billingCurrentMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
		// this.props.selectedRowBillingDetails.forEach(item => {
		Object.keys(this.props.selectedRowBillingDetails).length &&
		Object.keys(this.props.selectedRowBillingDetails.current_month).length &&
		this.props.selectedRowBillingDetails.current_month
			? (cost = this.props.selectedRowBillingDetails.current_month.total_unblended_cost)
			: (cost = 0)
		// })
		return cost
	}
	/**
	 * Method to find increase and decrease percentage
	 */
	findBillingPercentage = () => {
		let percentage = 0
		const diff = this.props.selectedRowBillingDetails.current_month - this.props.selectedRowBillingDetails.last_month
		if (this.props.selectedRowBillingDetails.last_month !== 0)
			percentage = (diff / this.props.selectedRowBillingDetails.last_month) * 100

		if (percentage) {
			return percentage
		} else {
			return 0
		}
	}	

	handleClickGroup = (val) => {
		this.setState({ selectedTableDrop: this.state.selectedTableDrop === val ? '' : val })
	}

	metricsListGraph = () => {
		let graphDetails = this.props.selectedRowMonitoringSnapshot && this.props.selectedRowMonitoringSnapshot.response ? this.props.selectedRowMonitoringSnapshot.response : []
		if(graphDetails && graphDetails.length) {
			graphDetails.forEach((graph, index) => {
				let graphData = graph.data
				Object.entries(graphData).forEach(([key, item], i) => {
					let array = []
					let series = []
					let options = {}
					if(item.values && item.values.length) {
						item.values.forEach((val, j) => {
							let arrayValues = []
							arrayValues.push(momentConvertionUtcToLocalTime(graph.dates[j], 'YYYY-MM-DD HH:mm:ss'))
							if(item.unit) {
								val = this.getGrapUnitValue(val, item.unit)
							}
							arrayValues.push(val)
							array.push(arrayValues)
						})

						if(array.length === 1) {
							let arrayValues = []
							arrayValues.push(addHours(momentConvertionUtcToLocalTime(graph.dates[0], 'YYYY-MM-DD HH:mm:ss'), 0.1))
							arrayValues.push(0)
							array.push(arrayValues)
						}

						series = [{
							data: array
						}]

						options = {
							chart: {
								// group: 'metrics_and_sre',
								// id:'metrics_'+i,
								type: 'area',
								width: '100%',
								toolbar: {
									show: false,
								},
								zoom: {
									enabled: false
								},
								sparkline: {
									enabled: false
								}
							},
							stroke: {
								show: true,
								curve: 'smooth',
								lineCap: 'butt',
								width: 2,
								dashArray: 0,      
							},
							colors: ['#775BA2'],
							fill: {
								type: "gradient",
								gradient: {
									// shadeIntensity: 1,
									// opacityFrom: 0.7,
									// opacityTo: 0.9,
									// stops: [0, 90, 100]
									gradientToColors: ['#775BA2'],
									shadeIntensity: 0,
									opacityFrom: .3,
									opacityTo: 1,
									stops: [30, 90]
								}
							},
							tooltip: {
								custom: function({series, seriesIndex, dataPointIndex, w}) {
									let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
									dateLable = dateLable.toString()
									dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
									let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
									return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
								},
								fixed: {
									enabled: false
								},
								x: {
									show: false,
								},
								y: {
									show: false
								},        
								marker: {
									show: false
								}
							},
							xaxis: {
								type: 'datetime',
								labels: {
									datetimeUTC: false,
									style: {
										colors: '#60687C'
									},
									// formatter: function (value) {
									//     return momentDateGivenFormat(value, 'HH:mm')
									// },
								},                            
								axisTicks: {
									show: false
								},
							},
							dataLabels: {
								enabled: false
							},
							yaxis: {
								show: true,
								axisTicks: {
									show: false,
								},
								axisBorder: {
									show: false,
									color: '#60687C'
								},
								tooltip: {
									enabled: false
								},
								// tickAmount: 2,
								min: 0,
								// max: yaxisMaxValue, 
								tickAmount: 1,
								labels: {
									offsetX: -10,
									// minWidth: 50,
									show: true,
									style: {
										fontSize: '9px',
										colors: '#60687C'
									},
									// formatter: (value) => { 
									//     if(Number(value) === value && value % 1 === 0) {
									//         return Math.ceil(value)
									//     } else {
									//         return Math.ceil(value)
									//     }
									// },
									// offsetX: 0,
									// offsetY: 0,
								}
							},
							grid: {
								show: true,
								borderColor: '#2D323E',
								strokeDashArray: 0,
								position: 'back',
								xaxis: {
									lines: {
										show: true
									},
									labels: {
										style: {
											colors: ['#FFF'],
										}
									},
									axisTicks: {
										show: false
									}
								},   
								yaxis: {
									lines: {
										show: false
									},
									labels: {
										style: {
											colors: ['#969AA7'],
										}
									}
								},                
								padding: {
									top: 0,
									right: 0,
									bottom: 0,
									left: 0
								},
							}
						}
					}

					this.setState({ [key+'_metrics_series_'+index+'_'+i]: series, [key+'_metrics_options_'+index+'_'+i]: options, loadingMonMetrics: false })
				})				
			})
		} else {
			this.setState({ loadingMonMetrics: false })
		}
	}

	getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }
	
	toggleState = (state) => {
		this.setState(prevState => ({
			[state]: !prevState[state],
		}))
	}

	render() {
		return (
			<div className="">
				<p className='p-3 m-0 text-white'>
					Category :{' '}
					{this.props.selectedTableRow.category && Array.isArray(this.props.selectedTableRow.category) ? 
						this.props.selectedTableRow.category.map((item, index) => {
							return (
								<span key={index} className='badge badge-pill badge-secondary ml-1 text-white'>
									{item}
								</span>
							)
						})
					: this.props.selectedTableRow.category ?
						<span key={this.props.selectedTableRow.category} className='badge badge-pill badge-secondary ml-1 text-white'>{this.props.selectedTableRow.category}</span>
					: null}
				</p>
				{this.props.selectedTableRow && this.props.selectedTableRow.tags ? (
					<React.Fragment>
						<p className='px-3 m-0 font-weight-bold'>Tags :</p>
						<ul className='tags my-1 px-3'>
							{this.props.selectedTableRow.tags.map((tag, index) => {
								const keys = tag.key.split(':')
								const values = tag.value.split('-')
								if (values.length > 1) delete values[values.length - 1]
								return (
									<li key={index}>
										<span className='tag'>
										{stringLength(keys[keys.length - 1]+': '+values.join('-'),25)}
										</span>
									</li>
								)
							})}
						</ul>
					</React.Fragment>
				) : 
					<p className='px-3 m-0 mb-2 font-weight-bold'>Tags : <small className="m-0 p-0 f12 text-white4">No Tags are associated for the Resource</small></p>
				}
				<ul ref={this.tabRef} className={`nav nav-tabs border-bottom bd-highlight bg-dark shadow-none ${
						this.props.showDetailsAssetSectionLoading ||
						this.props.showDetailsRelatedIssueLoading ||
						this.props.showDetailsMonitoringSectionLoading ||
						this.props.showDetailsBillingSectionLoading ||
						this.props.showSuppressListLoading
							? 'disabled'
							: ''
					}`} id='myTab' role='tablist'
				>
					{this.props.detailsTabNames &&
						this.props.detailsTabNames.length &&
						this.props.detailsTabNames.map((item, index) => {
							if (index < this.state.tabItemsToShow)
								return (
									<li className='nav-item' key={index}
										onClick={() => {
											
											if (item.value === 'summary_details') {
												this.props.toggleDetailsSectionLoading('summary_details', true)
												this.props.getEventInfo(this.props.selectedTableRow)
											} else if (item.value === 'asset_details') {
												this.props.toggleDetailsSectionLoading('asset_details', true)
												this.props.getAssetDetails(this.props.selectedTableRow)
											} else if (item.value === 'related_issues') {
												this.props.toggleDetailsSectionLoading('related_issues', true)
												this.props.getRelatedIssues(this.props.selectedTableRow, this.state.relatedIssueDurtaion)
											} else if (item.value === 'events') {
												this.props.toggleDetailsSectionLoading('events', true)
												this.props.getAssetEvents(this.props.selectedTableRow, this.state.eventsDuration)
											} else if (item.value === 'monitoring_details') {
												this.props.getMonitoringSnap(this.props.selectedTableRow, this.state.monitoringDuration)
												this.props.toggleDetailsSectionLoading('monitoring_details', true)
											} else if (item.value === 'billing') {
												this.props.getBillingSnap(this.props.selectedTableRow)
												this.props.toggleDetailsSectionLoading('billing', true)
											} else if (item.value === 'availability') {
												this.props.toggleDetailsSectionLoading('availability', true)
												this.getDailyAssetAvailability(this.props.selectedTableRow)
											}
										}}
									>
										<span className={`nav-link text-white cursorPointer ${this.props.selectedDetailsTab === index ? ' active' : ''}`} onClick={() => this.props.setSelectedDetailsTabIndex(index)}>{item.name}</span>

										{/* <Link
											className={`nav-link text-white cursorPointer custom-link ${this.props.selectedDetailsTab ===  ? 'active' : ''}`}
											id='assets-tab'
											data-toggle='tab'
											to=''
											role='tab'
											onClick={() => }
											aria-controls='assets'
											aria-selected='true'
										>
											
										</Link> */}
									</li>
								)
							return null
						})}

					<li className={`nav-item ml-auto bd-highlight align-self-center ${this.state.tabItemsToShow <= this.props.detailsTabNames.length ? '' : 'd-none'}`}>
						<ButtonDropdown
							isOpen={this.props.showDetailsTabListDropdown}
							toggle={() => this.props.toggleDetailsTabListDropdown()}
						>
							<DropdownToggle
								tag='a'
								className={`nav-link custom-link ${!this.props.detailsTabNames.length ? 'd-none' : ''}`}
							>
								<i className='fal fa-angle-double-right cursor-pointer' />
							</DropdownToggle>
							<DropdownMenu
								right
								modifiers={{
									setMaxHeight: {
										enabled: true,
										order: 890,
										fn: data => {
											return {
												...data,
												styles: {
													...data.styles,
													overflow: 'auto',
													maxHeight: 300,
												},
											}
										},
									},
								}}
							>
								{this.props.detailsTabNames &&
									this.props.detailsTabNames.map((item, index) => {
										if (index >= this.state.tabItemsToShow)
											return (
												<DropdownItem
													key={index}
													onClick={() => {
														this.setTabs()
														if (item.value === 'summary_details') {
															this.props.toggleDetailsSectionLoading('summary_details', true)
															this.props.getEventInfo(this.props.selectedTableRow)
														} else if (item.value === 'asset_details') {
															this.props.toggleDetailsSectionLoading('asset_details', true)
															this.props.getAssetDetails(this.props.selectedTableRow)
															// this.props.getBillingSnap(this.props.selectedTableRow)
														} else if (item.value === 'related_issues') {
															this.props.toggleDetailsSectionLoading('related_issues', true)
															this.props.getRelatedIssues(this.props.selectedTableRow, this.state.relatedIssueDurtaion)
															// this.props.getBillingSnap(this.props.selectedTableRow)
															// if (this.props.dayWiseCountGraphData.labels) this.showFailTrendGraph()
														} else if (item.value === 'events') {
															this.props.toggleDetailsSectionLoading('events', false)
															this.props.getAssetEvents(this.props.selectedTableRow, this.state.eventsDuration)
														} else if (item.value === 'monitoring_details') {
															this.props.toggleDetailsSectionLoading('monitoring_details', true)
															this.props.getMonitoringSnap(this.props.selectedTableRow, this.state.monitoringDuration)
														} else if (item.value === 'billing') {
															this.props.toggleDetailsSectionLoading('billing', true)
															this.props.getBillingSnap(this.props.selectedTableRow)
														} else if (item.value === 'availability') {
															this.props.toggleDetailsSectionLoading('availability', true)
															this.getDailyAssetAvailability(this.props.selectedTableRow)
														}
														this.props.onClickDetailsTabDropDownItem(item, index)
													}}
												>
													{item.name}
												</DropdownItem>
											)
										return null
									})}
							</DropdownMenu>
						</ButtonDropdown>
					</li>
				</ul>
				<div className='tab-content' id='myTabContent'>
					{!this.props.showDetailsSummarySectionLoading &&
					this.state.showSummaryDetails ? (
						<div className={`tab-pane fade show active`} id='assets' role='tabpanel' aria-labelledby='summary-tab' >
							<div className="p-1">
								<div className='bg-dark text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0'>
									{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.resource_id) ?
										<code className='p-2 text-white align-self-center f12'>
											{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
												<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
											: null}
											{this.props.selectedTableRow && this.props.selectedTableRow.resource_id ?
												<span>:&nbsp;{this.props.selectedTableRow.resource_id}</span>
											: null}
										</code>
									: null}
									<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
								</div>
								<div className="m-2 p-2 rounded bg-dark">
									<p className="f16 text-left text-white">Details</p>
									<p className="mb-1 f12 text-white4">{this.props.selectedTableRow.detail ? this.props.selectedTableRow.detail : ''}</p>
									<p className="m-0 f12 text-white4">{this.props.selectedTableRow.additional_details ? this.props.selectedTableRow.additional_details : ''}</p>
								</div>
								<div className="m-2 p-2 rounded bg-dark">
									<p className="f16 text-left text-white">Compliance</p>
									{this.props.selectedTableRow.compliance && this.props.selectedTableRow.compliance.length &&
									_.chain(this.props.selectedTableRow.compliance)
									.groupBy('complianceId')
									.map((value, key) => ({ label: key, sub: value }))
									.value()
									.map((compliance, i) => {
										//let array = []
										return (
											<div key={i} className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">{capitalizeAllLetter(compliance.label)}: </p>
													<p className="p-0 m-0 f12 align-self-center text-white4">
													{/* array.push(item.authoritySubControl ? item.authoritySubControl : item.authorityMinorControl ? item.authorityMinorControl : item.authorityMajorControl ? item.authorityMajorControl : '') */}
													{compliance.sub.map((item, index) => {
														let value = item.authoritySubControl ? item.authoritySubControl : item.authorityMinorControl ? item.authorityMinorControl : item.authorityMajorControl ? item.authorityMajorControl : ''
														return ( index ? ', '+value : value)
													})}
													</p>
												</div>
											</div>
										)
									})}	
									<div className="d-flex position-relative flex-wrap">
										<div className="text-left d-flex mb-1">
											<p className="mr-1 p-0 m-0 f12">AWS Well Architected Framework: </p>
											<p className="p-0 m-0 f12 align-self-center text-white4">{this.props.selectedTableRow.aws_well_architected && this.props.selectedTableRow.aws_well_architected ? this.props.selectedTableRow.aws_well_architected.join(",") : ''}</p>
										</div>
									</div>							
									
								</div>
								<div className="m-2 p-2 rounded bg-dark">
									<p className="f16 text-left text-white">KPI</p>
									<div className="d-flex position-relative flex-wrap">
										<div className="text-left d-flex mb-1">
											<p className="mr-1 p-0 m-0 f12">Issue Date: </p>
											<p className="p-0 m-0 f12 align-self-center text-white4">{momentConvertionUtcToLocalTime(this.props.selectedTableRow.timestamp, 'DD MMM YYYY HH:mm')}</p>
										</div>
									</div>
									<div className="d-flex position-relative flex-wrap">
										<div className="text-left d-flex mb-1">
											{/* <p className="mr-1 p-0 m-0 f12">Number of days issue is {this.props.selectedTableRow && this.props.selectedTableRow.resolution_status_info && this.props.selectedTableRow.resolution_status_info.sub_status ? capitalizeFirstLetter(this.props.selectedTableRow.resolution_status_info.status) : ""}: </p> */}
											{this.props.selectedTableRow.resolution_status_info ? this.props.selectedTableRow.resolution_status_info.status === 'resolved' ? 
												<React.Fragment>
													<p className="mr-1 p-0 m-0 f12">Resolved Date:</p>
													<p className="p-0 m-0 f12 align-self-center text-white4">{momentConvertionUtcToLocalTime(this.props.selectedTableRow.resolution_status_info.timestamp, 'DD MMM YYYY HH:mm')}</p>

												</React.Fragment>
												:
												<React.Fragment>
													<p className="mr-1 p-0 m-0 f12">Number of days issue is Pending: </p>
													<p className="p-0 m-0 f12 align-self-center text-white4">{dayDiffrence(this.props.selectedTableRow.resolution_status_info.timestamp)}</p>
												</React.Fragment>
											:
											<p className="mr-1 p-0 m-0 f12">Resolved Date: {momentConvertionUtcToLocalTime(this.props.selectedTableRow.timestamp, 'DD MMM YYYY HH:mm')}</p>
											}
										</div>
									</div>
								</div>
								<div className="m-2 p-2 rounded bg-dark">
									<p className="f16 text-left text-white">Event</p>
									{this.props.selectedRowEventInfo && Object.entries(this.props.selectedRowEventInfo).length ?
										<React.Fragment>
											<div className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">IAM Identity: {this.props.selectedRowEventInfo.user_name ? this.props.selectedRowEventInfo.user_name : ''}</p>
													<p className="p-0 m-0 f12 align-self-center"></p>
												</div>
											</div>
											<div className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">Event Triggered the issue: </p> 
													<p className="p-0 m-0 f12 align-self-center text-white4 d-flex">
														<span>{this.props.selectedRowEventInfo.event_name ? this.props.selectedRowEventInfo.event_name : ''}
														</span>
														<span className="ml-2">
															{momentConvertionUtcToLocalTime(this.props.selectedRowEventInfo.event_time, 'DD MMM YYYY HH:mm')}
														</span>
													</p>
												</div>
											</div>
											<div className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">Event IP: </p>
													<p className="p-0 m-0 f12 align-self-center text-white4">{this.props.selectedRowEventInfo.accessed_from && this.props.selectedRowEventInfo.accessed_from.ip ? this.props.selectedRowEventInfo.accessed_from.ip : ''}</p>
												</div>
											</div>
											<div className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">Access Method: </p>
													<p className="p-0 m-0 f12 align-self-center text-white4">{this.props.selectedRowEventInfo.accessed_from && this.props.selectedRowEventInfo.accessed_from.access_mode ? this.props.selectedRowEventInfo.accessed_from.access_mode : ''}</p>
												</div>
											</div>
										</React.Fragment>
									:
										<div className="position-relative flex-wrap">
											<div className="text-center mb-1">
												<p className="p-0 m-0 f12">No Events found</p>
											</div>
										</div>
									}
								</div>
								<div className="m-2 p-2 rounded bg-dark">
									<p className="f16 text-left text-white">Observations</p>
									<div className="d-flex position-relative flex-wrap">
										<div className="text-left d-flex mb-1">
											<p className="mr-1 p-0 m-0 f12">Complexity Score: </p>
											<p className="p-0 m-0 f12 align-self-center text-white4">{this.props.selectedTableRow.complexity ? this.props.selectedTableRow.complexity + (this.props.selectedTableRow.complexity > 6 ? ' Hard to fix' : this.props.selectedTableRow.complexity > 3 ? ' Medium to fix' : ' Easy to fix') : ''}</p>
										</div>
									</div>
								</div>
								<div className="m-2 p-2 rounded bg-dark">
									<p className="f16 text-left text-white">Solution</p>
									<div className="d-flex position-relative flex-wrap">
										<div className="text-left d-flex mb-1">
											<p className="p-0 m-0 f12 align-self-center text-white4"> - </p>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : this.props.showDetailsSummarySectionLoading &&
					  this.state.showSummaryDetails ? (
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center text-white' color='dark' size='lg' />
						</div>
					) : this.state.showSummaryDetails ? (
						<div className='d-flex justify-content-center m-4'>
							<p>No Summary details found</p>
						</div>
					) : null}

					{this.props.selectedRowAssetDetails && this.props.selectedRowAssetDetails.length &&
					!this.props.showDetailsAssetSectionLoading &&
					(this.state.showAssetDetails) ? (
						<div className={`tab-pane fade show active`} id='assets' role='tabpanel' aria-labelledby='assets-tab' >
							<div className="p-1">
								<div className='bg-dark text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0'>
									{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.resource_id) ?
										<code className='p-2 text-white align-self-center f12'>
											{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
												<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
											: null}
											{this.props.selectedTableRow && this.props.selectedTableRow.resource_id ?
												<span>:&nbsp;{this.props.selectedTableRow.resource_id}</span>
											: null}
										</code>
									: null}
									<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
								</div>
								<div className='m-2 p-2 bg-dark rounded'>
									<table className='table'>
										<thead className="text-white">
											<tr>
												<th colSpan={2}>Network</th>
											</tr>
										</thead>
										<tbody className="text-white4">
											<tr>
												<td className='text-primary-color ml-3'>Provider ID</td>
												<td>{this.props.selectedRowAssetDetails[0].provider ? this.props.selectedRowAssetDetails[0].provider : ''}</td>
											</tr>
											<tr>
												<td className='text-primary-color ml-3'>Service</td>
												<td>{this.props.selectedRowAssetDetails[0].service_name ? this.props.selectedRowAssetDetails[0].service_name : ''}</td>
											</tr>
											<tr>
												<td className='text-primary-color ml-3'>Resource Type</td>
												<td>{this.props.selectedRowAssetDetails[0].resource_type ? this.props.selectedRowAssetDetails[0].resource_type : ''}</td>
											</tr>
											{this.props.selectedRowAssetDetails[0].compliance ? (
												<tr>
													<td className='text-primary-color ml-3'>Compliance ID</td>
													<td>
														{this.props.selectedRowAssetDetails[0].compliance &&
														Array.isArray(this.props.selectedRowAssetDetails[0].compliance) ? (
															this.props.selectedRowAssetDetails[0].compliance.map((item, index) => {
																return (
																	<td key={index} className='text-dark'>
																		{item.complianceId}
																	</td>
																)
															})
														) : (
															<td className='text-dark'>
																{this.props.selectedRowAssetDetails[0].compliance &&
																	this.props.selectedRowAssetDetails[0].compliance.complianceId}
															</td>
														)}
													</td>
												</tr>
											) : null}
											<tr>
												<td className='text-primary-color ml-3'>Vpc ID</td>
												<td>{this.props.selectedRowAssetDetails[0].vpc_id ? this.props.selectedRowAssetDetails[0].vpc_id : ''}</td>
											</tr>
										</tbody>
									</table>
								</div>
								{this.props.selectedRowAssetDetails[0].action_by ? 
									<div className="m-2 p-2 rounded bg-dark">
										<div className='d-flex position-relative flex-wrap mt-2'>
											<div className='text-left flex-grow-1 fs-13 mb-2'>
												{this.props.selectedRowAssetDetails[0].action_by ?
													<p className='mb-1'>Created by 
														<span className='badge badge-outline-gray mx-2 text-white4'>{trimmedSplitUserName1(this.props.selectedRowAssetDetails[0].action_by)}</span> 
														<span class="text-info mx-2 font-weight-normal">{trimmedSplitUserName2(this.props.selectedRowAssetDetails[0].action_by)}</span> 
														on <span class="mx-2 font-weight-normal text-white4">{this.props.selectedRowAssetDetails[0].asset_time ? momentFullDateTime(this.props.selectedRowAssetDetails[0].asset_time) : ''}</span>
													</p> 
												: null}
											</div>
											<div className='text-left flex-grow-1 fs-13'>
												{this.props.selectedRowAssetDetails[0].action_by ? (
													<p className='mb-1'>Modified by 
														<span className='badge badge-outline-gray mx-2 text-white4'>{trimmedSplitUserName1(this.props.selectedRowAssetDetails[0].action_by)}</span> 
														<span class="text-info mx-2 font-weight-normal">{trimmedSplitUserName2(this.props.selectedRowAssetDetails[0].action_by)}</span> 
														on <span class="mx-2 font-weight-normal text-white4">{this.props.selectedRowAssetDetails[0].action_date ? momentFullDateTime(this.props.selectedRowAssetDetails[0].action_date) : ''}</span>
													</p>
												) : null}
											</div>
										</div>
									</div>
								: null}
							</div>
						</div>
					) : this.props.showDetailsAssetSectionLoading &&
					  this.state.showAssetDetails ? (
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center text-white' color='dark' size='lg' />
						</div>
					) : !this.props.showDetailsAssetSectionLoading &&
					  !this.props.selectedRowAssetDetails.length &&
					  this.state.showAssetDetails ? (
						<div className='d-flex justify-content-center m-4'>
							<p>No asset details found</p>
						</div>
					) : null}

					{!this.props.showDetailsRelatedIssueLoading &&
					this.state.showRelatedIssues &&
					!this.state.showSummaryDetails &&
					!this.state.showAssetDetails &&
					!this.state.showBillingDetails &&
					!this.state.showMonitoringDetails &&
					!this.state.showAvailabilityDetails &&
					!this.state.showEvents ? (
						<div className={`tab-pane related ${this.state.showRelatedIssues && !this.props.showDetailsRelatedIssueLoading ? 'show active' : '' } `} id='related' role='tabpanel' aria-labelledby='related-tab'>
							<div className="p-1">
								<div className='bg-dark text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0'>
									{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.resource_id) ?
										<code className='p-2 text-white align-self-center f12'>
											{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
												<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
											: null}
											{this.props.selectedTableRow && this.props.selectedTableRow.resource_id ?
												<span>:&nbsp;{this.props.selectedTableRow.resource_id}</span>
											: null}
										</code>
									: null}
									<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
								</div>
								<div className='d-flex justify-content-between p-2 mb-1'>
									<div className='w-65 float-left align-self-center pt-1'>
										<p className="mb-0 f12 text-white">Resource checks count grouped by status, risk
											{this.props.durationType !== '' ? 
												' Date : ' +this.props.durationType 
											: 
												DURATION_FILTER_OPTIONS.forEach((item, index) => {
													if(this.props.relatedIssueDurtaion === item.value) {
														return item.option
													}
												})
											}
										</p>
									</div>
									
									<div className="select-sm-bg curvedDropdown minWidth120" toggle={() => this.toggleState("issueDurationFilter")}>
										<div className="dropdown">
											<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueDurationFilter: !this.state.issueDurationFilter })}>
												{this.state.relatedIssueDurtaion ? DURATION_FILTER_OPTIONS.filter(e => e.value === this.state.relatedIssueDurtaion)[0].option : <span className="placeholder">Select</span>}
												<span className="caret"></span>
											</span>
											<ul className={`dropdown-menu p-0 ${this.state.issueDurationFilter ? "show" : ''}`}>
												{DURATION_FILTER_OPTIONS.map(item => {
													return (
														<li 
															onClick={() => this.setState({ 
																relatedIssueDurtaion: item.value, 
																issueDurationFilter: false
															}, 
																() => this.props.getRelatedIssues(this.props.selectedTableRow, item.value)
															)}
															className={`${this.state.relatedIssueDurtaion === item.value ? "selected" :  ""}`}
														>
															{item.option}
														</li>
													)
												})}
											</ul>
										</div>
									</div>
								</div>
								<div className="m-2">
									<div className='p-2 bg-dark rounded d-flex'>
										<div className='mr-2 mt-1 d-flex'>
											<div className='count border-warning ml-3 mr-3'>
												<h5 className='text-white m-0'>{this.getRelatedStatusCount('pass')}</h5>
												<p className='text-white m-0'>
													<small>Pass</small>
												</p>
											</div>
											<div className='count border-color-dark-theme-orange'>
												<h5 className='text-white m-0'>{this.getRelatedStatusCount('fail')}</h5>
												<p className='text-white m-0'>
													<small>Fail</small>
												</p>
											</div>
											{/* <div className='count border-warning'>
												<h5 className='text-dark m-0'>{this.getRelatedStatusCount('NA')}</h5>
												<p className='text-dark m-0'>
													<small>NA</small>
												</p>
											</div> */}
										</div>
										<div className='d-flex bd-highlight ml-auto align-self-center pr-3'>
											<div className='mr-2'>
												<span className='mb-0'>
													<p className='m-2'>
														<small className='d-flex align-items-center'>
															<span className={`badge risk-badge-low ${this.findRisk('Low') < 10 ? 'ml-2' : this.findRisk('Low') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('Low') }</span>
															Low
														</small>
													</p>
													<p className='m-2'>
														<small className='d-flex align-items-center'>
															<span className={`badge risk-badge-medium ${this.findRisk('Medium') < 10 ? 'ml-2' : this.findRisk('Medium') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('Medium') }</span>
															Medium
														</small>
													</p>
												</span>
											</div>
											<div>
												<span className='mb-0'>
													<p className='m-2'>
														<small className='d-flex align-items-center'>
															<span className={`badge risk-badge-high ${this.findRisk('High') < 10 ? 'ml-2' : this.findRisk('High') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('High') }</span>
															High
														</small>
													</p>
													<p className='m-2'>
														<small className='d-flex align-items-center'>
															<span className={`badge risk-badge-critical ${this.findRisk('Critical') < 10 ? 'ml-2' : this.findRisk('Critical') === 100 ? '' : 'ml-1'}  mr-1`}>{this.findRisk('Critical') }</span>
															Critical
														</small>
													</p>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className='my-3 mx-1'>
									<div className='p-2 bg-dark rounded'>
										<p className='mb-0 text-white'>Trends</p>
										<p className='mb-2 f12'>Resource level checks pass / fail trend</p>
										<div className='p-2 bg-muted rounded'>
											{this.props.recentSeries && this.props.recentSeries.length ? (
												<div className='mt-n4 mb-n4 transparentTooltip'>
													<ApexChart type="area" series={this.props.recentSeries} options={this.props.recentOptions} height={120}/>
												</div>
											) : null}
										</div>
									</div>
								</div>
							</div>
							{this.props.filteredRelatedIssuesData ? (
								<div className="m-2">
									<p className='mb-0 text-white'>Related Checks</p>
									{/* <p className='mb-2 f12'>Earum aut eos. Sed praesentium et hic voluptate ut.</p> */}
									<div className='w-100 d-flex mb-1 justify-content-between'>
										<div className='d-flex'>
											<div className="select-sm-bg curvedDropdown minWidth120" toggle={() => this.toggleState("issueStatusFilter")}>
												<div className="dropdown">
													<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueStatusFilter: !this.state.issueStatusFilter })}>
														{this.state.selectedResourceStatus ? statusOption.filter(e => e.value === this.state.selectedResourceStatus)[0].label : <span className="placeholder">Status</span>}
														<span className="caret"></span>
													</span>
													<ul className={`dropdown-menu p-0 ${this.state.issueStatusFilter ? "show" : ''}`}>
														{statusOption.map(item => {
															return (
																<li 
																	onClick={() => this.setState({ 
																		selectedResourceStatus: item.value, 
																		issueStatusFilter: false
																	}, 
																		() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
																	)}
																	className={`${this.state.selectedResourceStatus === item.value ? "selected" :  ""}`}
																>
																	{item.label}
																</li>
															)
														})}
													</ul>
												</div>
											</div>
											<div className="select-sm-bg curvedDropdown minWidth120 ml-2" toggle={() => this.toggleState("issueCategoryFilter")}>
												<div className="dropdown">
													<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ issueCategoryFilter: !this.state.issueCategoryFilter })}>
														{this.state.selectedCategory ? this.state.selectedCategory : <span className="placeholder">Category</span>}
														<span className="caret"></span>
													</span>
													<ul className={`dropdown-menu p-0 ${this.state.issueCategoryFilter ? "show" : ''}`}>
														<li 
															onClick={() => this.setState({ 
																selectedResourceStatus: "", 
																issueCategoryFilter: false
															}, 
																() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
															)}
															className={`${this.state.selectedResourceStatus === "" ? "selected" :  ""}`}
														>
															All
														</li>
														{this.props.categoryCounts &&
														this.props.categoryCounts.length &&
														this.props.categoryCounts.map((item, index) => {
															return (
																<li 
																	onClick={() => this.setState({ 
																		selectedCategory: item.category, 
																		issueCategoryFilter: false
																	}, 
																		() => this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus)
																	)}
																	className={`${this.state.selectedCategory === item.category ? "selected" :  ""}`}
																>
																	{item.category}
																</li>
															)
														})}
													</ul>
												</div>
											</div>
										</div>
										<div className='d-flex'>
											<div className="align-self-center">
												<span onClick={() => this.setState({ selectedStatus: this.state.selectedStatus === 'pass' ? '' : 'pass' },this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus === 'pass' ? '' : 'pass' )) } className={`cursor-pointer badge mr-2 ${this.state.selectedStatus === 'pass' ? ' badge-outline-pass' : ' badge-outline-pass'} `}>Pass</span>
											</div>
											<div className="align-self-center">
												<span onClick={() => this.setState({ selectedStatus: this.state.selectedStatus === 'fail' ? '' : 'fail' }, this.props.getRelatedTableData(this.state.selectedCategory, this.state.selectedResourceStatus, this.state.selectedStatus === 'fail' ? '' : 'fail' )) } className={`cursor-pointer badge mr-2 ${this.state.selectedStatus === 'fail' ? ' badge-outline-fail' : ' badge-outline-fail'}`}>Fail</span>
											</div>
										</div>
									</div>
									<div className="mt-3"> {/* checkRelatedIssue */}
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: ' ',
													accessor: 'risk',
													Cell: cellInfo => (
														<div className={`risk-line-no-position risk-bg-${cellInfo.row.original.risk ? cellInfo.row.original.risk.toLowerCase() : ''}`} />
													),
													width: 10
												},
												{
													Header: 'Date',
													accessor: 'timestamp',
													Cell: cellInfo => momentConvertionUtcToLocalTime(cellInfo.row.original.asset_time, 'DD MMM YYYY HH:mm'),
													width: 120
												},
												{
													Header: 'Title',
													accessor: 'title',
												},
												{
													Header: 'Status',
													accessor: 'status',
													Cell: cellInfo => (
														<React.Fragment>
															<span className='pl-2'>{cellInfo.row.original.resolution_status_info ? capitalizeFirstLetter(cellInfo.row.original.resolution_status_info.status) : capitalizeFirstLetter(cellInfo.row.original.status)}</span>
								
														</React.Fragment>
													),
													width: 80
												},
												{
													Header: 'Action',													
													Cell: cellInfo => (
													<div className="align-self-center">
														<div className={`btn-group dropleft ${this.state.selectedTableDrop === cellInfo.row.id ? "position-absolute" : ""}`}>
															<div className={`rounded-circle px-2 py-1 cursorPointer ${this.state.selectedTableDrop === cellInfo.row.id ? 'bg-muted' : 'bg-black6'} `} onClick={ () => this.handleClickGroup(cellInfo.row.id) }>
																<i className="fa fa-ellipsis-v text-secondary"></i>
															</div>
															<div className={`dropdown-menu ${this.state.selectedTableDrop === cellInfo.row.id ? 'show active' : ''} `}>
																<i className="fa fa-caret-up"></i>
																{cellInfo.row.original.resolution_status_info && cellInfo.row.original.resolution_status_info.status === 'pending' ?
																	<button className="dropdown-item" type="button" 
																		onClick={ () => this.setState({selectedTableDrop: '' },
																			() => this.props.openCloseModal(cellInfo.row.original, this.props.relatedIssueDurtaion) 
																		)}
																	>Close</button>
																:
																	<button className="dropdown-item" type="button">{cellInfo.row.original.resolution_status_info ? capitalizeFirstLetter(cellInfo.row.original.resolution_status_info.status) : capitalizeFirstLetter(cellInfo.row.original.status)}
																	</button>
																}
															</div>
														</div>
													</div>
													),
													width: 80
												},
											]}
											data={this.props.filteredRelatedIssuesData}
											tooltipFor={[1]}
											riskTooltip={[0]}
											dashboard={this.props.filteredRelatedIssuesData.length ? true : false }
											perPage={10}
											// rounded={'rounded'}
											tableSize={'table-xs'}
											// tableHead={'bg-primary'}
											// selectedColumn={'bg-light'}
											columnFontSize={'f10'}
											onClickRow={tableRow => {}}
										/>
									</div>
								</div>
							) : null}
						</div>
					) : this.props.showDetailsRelatedIssueLoading &&
					  this.state.showRelatedIssues &&
					  !this.state.showSummaryDetails &&
					  !this.state.showAssetDetails &&
					  !this.state.showBillingDetails &&
					  !this.state.showMonitoringDetails &&
					  !this.state.showAvailabilityDetails &&
					  !this.state.showEvents ? (
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center text-white' color='dark' size='lg' />
						</div>
					) : !this.props.showDetailsRelatedIssueLoading &&
					  !this.state.showSummaryDetails &&
					  !this.state.showAssetDetails &&
					  !this.state.showBillingDetails &&
					  !this.state.showMonitoringDetails &&
					  !this.state.showAvailabilityDetails &&
					  !this.state.showEvents &&
					  this.state.showRelatedIssues ? (
						<div className='d-flex justify-content-center m-4'>
							<p>No related issues found</p>
						</div>
					) : null}

					{!this.props.showDetailsEventsSectionLoading &&
					this.state.showEvents &&
					Object.entries(this.props.eventsData).length &&
					!this.state.showSummaryDetails &&
					!this.state.showAssetDetails &&
					!this.state.showBillingDetails &&
					!this.state.showMonitoringDetails &&
					!this.state.showAvailabilityDetails &&
					!this.state.showRelatedIssues ? (
						<div className={`tab-pane fade ${this.state.showEvents ? 'show active' : ''}`} id='events' role='tabpanel' aria-labelledby='events-tab'>
							<div className="p-1">
								<div className='bg-dark text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0'>
									{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.resource_id) ?
										<code className='p-2 text-white align-self-center f12'>
											{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
												<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
											: null}
											{this.props.selectedTableRow && this.props.selectedTableRow.resource_id ?
												<span>:&nbsp;{this.props.selectedTableRow.resource_id}</span>
											: null}
										</code>
									: null}
									<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
								</div>
								<p className="m-2"><small>Earum aut eos. Sed praesentium et hic voluptate itaque ut. Aut quia est aspernatur. Expedita unde vel accusantium deleniti ipsum fuga enim.</small></p>
								{this.props.eventsData && !Object.entries(this.props.eventsData).length ? (
									<React.Fragment>
										<div className="events-content m-2">
											<div className="d-flex">
												<div className="p-1 bg-dark3 d-flex">
													<div className="d-flex flex-column align-self-center">
														<span className="fal fa-chevron-double-up mt-2"></span>
														<img src={BeforeEvent} alt="" className="mb-2" />
													</div>
												</div>
												<div className={`flex-grow-1 ${this.props.eventsData.events_before && this.props.eventsData.events_before.length ? '' : 'align-self-center'}`}>
													{this.props.eventsData.events_before && this.props.eventsData.events_before.length ? 
														this.props.eventsData.events_before.slice(0,5).map((before, index) => {
															return (
															<div className={`d-flex p-2 before-${index}`}>
																<div>
																	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter(before.event_name)}</p>
																	<p className="m-0 small text-white4">{before.user_name}</p>
																</div>
																<div className="flex-grow-1 align-self-center text-right">
																	<span className="m-0 small text-white4">{momentConvertionUtcToLocalTime(before.event_time, 'DD MMM YYYY HH:mm')}</span>
																	<span className="badge badge-info text-white4 ml-1">{this.props.eventsData.event_detail && this.props.eventsData.event_detail.event_time ? getTimeDifference(before.event_time, this.props.eventsData.event_detail.event_time, 'before') : ' - '}</span>
																</div>
															</div>
															)
														})
													: 
														<div className="p-1">
															<p className="text-center m-auto">No Before Event</p>
														</div>
														// <React.Fragment>
														// <div className={`d-flex p-2 before-0`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">before.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-info text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// <div className={`d-flex p-2 before-1`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">before.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-info text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// <div className={`d-flex p-2 before-2`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">before.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-info text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// <div className={`d-flex p-2 before-3`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">before.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-info text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// <div className={`d-flex p-2 before-4`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">before.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-info text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// </React.Fragment>
													}
												</div>
											</div>
											<div className="d-flex bg-dark3 my-2 p-2 text-white4">
												<div>
													<h6 className="mb-2">{this.props.eventsData.event_detail && this.props.eventsData.event_detail.user_name ? this.props.eventsData.event_detail.user_name : 'user_name'}</h6>
													<p className="m-0 font-weight-bold d-flex">
														<span>
															{this.props.eventsData.event_detail && this.props.eventsData.event_detail.event_name ? this.props.eventsData.event_detail.event_name : ''}
														</span>
														<span className="ml-2">
															{(this.props.eventsData.event_detail && this.props.eventsData.event_detail.event_time ? momentConvertionUtcToLocalTime(this.props.eventsData.event_detail.event_time, 'DD MMM YYYY HH:mm') : '2021-12-21 12:00')}
														</span>
													</p>
												</div>
												<div className="text-right flex-grow-1">
													<h6 className="mb-2"><i className="fad fa-clock"></i> {this.props.eventsData.event_detail && this.props.eventsData.event_detail.accessed_from ? this.props.eventsData.event_detail.accessed_from.ip : '12:01:00:0'}</h6>
													<p className="m-0 font-weight-bold">{this.props.eventsData.event_detail && this.props.eventsData.event_detail.accessed_from ?this.props.eventsData.event_detail.accessed_from.access_mode : 'laptop'}</p>
												</div>
											</div>
											<div className="d-flex">
												<div className="p-1 bg-dark3 d-flex">
													<div className="d-flex flex-column align-self-center">
														<img src={AfterEvent} alt="" />
														<span className="fal fa-chevron-double-down mt-2 mb-2"></span>
													</div>
												</div>
												<div className={`flex-grow-1 ${this.props.eventsData.events_after && this.props.eventsData.events_after.length ? '' : 'align-self-center'}`}>
													{this.props.eventsData.events_after && this.props.eventsData.events_after.length ? this.props.eventsData.events_after.slice(0,5).map((after, index) => {
														return (
														<div className={`d-flex p-2 after-${index}`}>
															<div>
																<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter(after.event_name)}</p>
																<p className="m-0 small text-white4">{after.user_name}</p>
															</div>
															<div className="flex-grow-1 align-self-center text-right">
																<span className="m-0 small text-white4">{momentConvertionUtcToLocalTime(after.event_time, 'DD MMM YYYY HH:mm')}</span>
																<span className="badge badge-purple text-white4">{this.props.eventsData.event_detail && this.props.eventsData.event_detail.event_time ? getTimeDifference(after.event_time, this.props.eventsData.event_detail.event_time, 'after') : ''}</span>
															</div>
														</div>	
														)
													})
													: 
														<div className="p-2">
															<p className="text-center m-auto">No After Event</p>
														</div>
														// <React.Fragment>
														// <div className={`d-flex p-2 after-0`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">aftr.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-purple text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// <div className={`d-flex p-2 after-1`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">after.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-purple text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// <div className={`d-flex p-2 after-2`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">after.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-purple text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// <div className={`d-flex p-2 after-3`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">after.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-purple text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// <div className={`d-flex p-2 after-4`}>
														// 	<div>
														// 	<p className="m-0 font-weight-bold text-white4">{capitalizeFirstLetter('text')}</p>
														// 	<p className="m-0 small text-white4">after.user_name</p>
														// 	</div>
														// 	<div className="flex-grow-1 align-self-center text-right">
														// 		<span className="m-0 small text-white4">2021-12-29 12:00</span>
														// 		<span className="badge badge-purple text-white4 ml-1">12 hours</span>
														// 	</div>
														// </div>
														// </React.Fragment>
													}
												</div>
											</div>
										</div>
										<div className='mt-3 displayNone'>
											<b className='mb-1 mt-3'>Before Events</b>
										</div>
										<p className='mb-2 displayNone'>
											<small>Earum aut eos. Sed praesentium et hic voluptate itaque ut.</small>
										</p>
										<div className={`assetDetailsSection mr-1 displayNone`}>
											<CustomTable
												columns={[
													{
														Header: 'Event',
														accessor: 'event_name',
														
													},
													{
														Header: 'Date',
														accessor: 'event_time',
														Cell: cellInfo => momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm')
														//Cell: cellInfo => {momentDateTime(cellInfo.row.original.event_time)},
													},
													{
														Header: 'User',
														accessor: 'user_name',
													},
													{
														Header: 'Difference',
														accessor: d => getTimeDifference(d.event_time, this.props.eventsData.event_detail.event_time, 'before'),
														Cell: cellInfo => getTimeDifference(cellInfo.row.original.event_time, this.props.eventsData.event_detail.event_time, 'before')
													}
												]}
												data={this.props.eventsData.events_before ? this.props.eventsData.events_before : []}
												tooltipFor={[0,1]}	
												dashboard={this.props.eventsData.events_before && this.props.eventsData.events_before.length > 10 ? true : false }
												perPage={5}
												fontSize={9}
												borderSubSection=''
											/>
										</div>
										<div className="user-details fs-13 m-0 mb-3 mt-3 displayNone">
											<label className="font-weight-bold text-black text-left f14">Event</label>
											<div className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">IAM Identity: {this.props.eventsData.event_detail && this.props.eventsData.event_detail.user_name && this.props.eventsData.event_detail.user_name}</p>
													<p className="p-0 m-0 f12 align-self-center"></p>
												</div>
											</div>
											<div className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">Event Triggered the issue: {this.props.eventsData.event_detail && this.props.eventsData.event_detail.event_name && this.props.eventsData.event_detail.event_name + ' '+ momentConvertionUtcToLocalTime(this.props.eventsData.event_detail.event_time, 'DD MMM YYYY HH:mm')}</p>
													<p className="p-0 m-0 f12 align-self-center"></p>
												</div>
											</div>
											<div className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">Event IP: {this.props.eventsData.event_detail && this.props.eventsData.event_detail.accessed_from && this.props.eventsData.event_detail.accessed_from.ip}</p>
													<p className="p-0 m-0 f12 align-self-center"></p>
												</div>
											</div>
											<div className="d-flex position-relative flex-wrap">
												<div className="text-left d-flex mb-1">
													<p className="mr-1 p-0 m-0 f12">Access Method: {this.props.eventsData.event_detail && this.props.eventsData.event_detail.accessed_from && this.props.eventsData.event_detail.accessed_from.access_mode}</p>
													<p className="p-0 m-0 f12 align-self-center"></p>
												</div>
											</div>
										</div>
										<div className='mt-3 displayNone'>
											<b className='mb-1 mt-3'>After Events</b>
										</div>
										<p className='mb-2 displayNone'>
											<small>Earum aut eos. Sed praesentium et hic voluptate itaque ut.</small>
										</p>
										<div className={`assetDetailsSection mr-1 displayNone`}>
											<CustomTable
												columns={[
													{
														Header: 'Event',
														accessor: 'event_name',
														
													},
													{
														Header: 'Date',
														accessor: 'event_time',
														Cell: cellInfo => momentConvertionUtcToLocalTime(cellInfo.row.original.event_time, 'DD MMM YYYY HH:mm')
														//Cell: cellInfo => {momentDateTime(cellInfo.row.original.event_time)},
													},
													{
														Header: 'User',
														accessor: 'user_name',
													},
													{
														Header: 'Difference',
														accessor: d => getTimeDifference(d.event_time, this.props.eventsData.event_detail.event_time, 'after'),
														Cell: cellInfo => getTimeDifference(cellInfo.row.original.event_time, this.props.eventsData.event_detail.event_time, 'after')
													}
												]}
												data={this.props.eventsData.events_after ? this.props.eventsData.events_after : []}
												tooltipFor={[0,1]}
												dashboard={this.props.eventsData.events_after ? this.props.eventsData.events_after.length > 10 ? true : false : false }
												perPage={5}
												fontSize={9}
												borderSubSection=''
											/>
										</div>
									</React.Fragment>
								) : null}
							</div>
						</div>
					) : this.props.showDetailsEventsSectionLoading &&
					  this.state.showEvents &&
					  !this.state.showSummaryDetails &&
					  !this.state.showAssetDetails &&
					  !this.state.showBillingDetails &&
					  !this.state.showMonitoringDetails &&
					  !this.state.showAvailabilityDetails &&
					  !this.state.showRelatedIssues ? (
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center text-white' color='dark' size='lg' />
						</div>
					) : !this.props.showDetailsEventsSectionLoading &&
					  !Object.entries(this.props.eventsData).length &&
					  !this.state.showSummaryDetails &&
					  !this.state.showAssetDetails &&
					  !this.state.showBillingDetails &&
					  !this.state.showMonitoringDetails &&
					  !this.state.showRelatedIssues &&
					  !this.state.showAvailabilityDetails &&
					  this.state.showEvents ? (
						<div className='d-flex justify-content-center m-4'>
							<p>No events found</p>
						</div>
					) : null}

					{/* Billing Section */}
					{!this.props.showDetailsBillingSectionLoading &&
					this.state.showBillingDetails &&
					!this.state.showSummaryDetails &&
					!this.state.showAssetDetails &&
					!this.state.showEvents &&
					!this.state.showAvailabilityDetails &&
					!this.state.showMonitoringDetails &&
					!this.state.showRelatedIssues ? (
						<div
							className={`tab-pane fade ${this.state.showBillingDetails ? 'show active' : ''}`}
							id='billing'
							role='tabpanel'
							aria-labelledby='monitoring-tab'
						>
							<div className='badge badge-light text-wrap text-left p-0 my-1 mx-2 fs-13 d-flex rounded overflow-hidden flex-column border-0'>
								{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.resource_id) ?
									<code className='p-2 text-dark align-self-center f12'>
										{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
											<span className="badge-square bg-gray3 p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
										: null}
										{this.props.selectedTableRow && this.props.selectedTableRow.resource_id ?
											<span>:&nbsp;{this.props.selectedTableRow.resource_id}</span>
										: null}
									</code>
								: null 
								}
								<div className='bg-success p-2 f12 text-center text-white'>Active</div>
							</div>
							<div className='card mx-2 my-2 border-0'>
								<table className='table table-borderless billing-side-table mb-0'>
									{/* <thead>
											<tr>
												<th />
												<th />
											</tr>
										</thead> */}
									<tbody>
										<tr>
											<th colSpan='2' className='text-black align-middle'>
												Current Month
											</th>
											<td>
												<div className='d-flex justify-content-end'>
													<span className='align-self-center fs-13 mr-2'>$</span>
													<h3 className='m-0'>{this.billingCurrentMonthCost()}</h3>
													<span className='d-flex flex-column align-self-center ml-2 text-success'>
														<i className='far fa-angle-up align-self-center fa-1x' />
														{this.findBillingPercentage()}%
													</span>
												</div>
											</td>
										</tr>
										<tr>
											<th colSpan='2' className='text-black align-middle'>
												Last Month
											</th>
											<td>
												<div className='d-flex justify-content-end '>
													<span className='align-self-center fs-13 mr-2'>$</span>
													<h3 className='m-0'> {this.billingLastMonthCost()}</h3>
													{/* <span className='d-flex flex-column align-self-center ml-2 text-danger'>
															5%
															<i className='far fa-angle-down align-self-center fa-1x' />
														</span> */}
												</div>
											</td>
										</tr>
										<tr>
											<th colSpan='2' className='text-black align-middle'>
												Last 3 Months
											</th>
											<td>
												<div className='d-flex justify-content-end'>
													<span className='align-self-center fs-13 mr-2'>$</span>
													<h3 className='m-0'>{this.billingLastThreeMonthCost()}</h3>
													{/* <span className='d-flex flex-column align-self-center ml-2 text-danger'>
															5%
															<i className='far fa-angle-down align-self-center fa-1x' />
														</span> */}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							{/* <br />
								<b className='mb-1 mt-2 ml-3'>Current Month</b>
								<div
									className='mt-2'
									style={{
										height: '150px',
									}}
								>
									<canvas
										style={{ height: '150px' }}
										ref={currentBillingGraph => (this.currentBillingGraph = currentBillingGraph)}
									/>
								</div>
								<b className='mb-1 mt-2 ml-3'>Last Month</b>
								<div
									className='mt-2'
									style={{
										height: '150px',
									}}
								>
									<canvas
										style={{ height: '150px' }}
										ref={lastBillingGraph => (this.lastBillingGraph = lastBillingGraph)}
									/>
								</div> */}
						</div>
					) : this.props.showDetailsBillingSectionLoading &&
					  this.state.showBillingDetails &&
					  !this.state.showSummaryDetails &&
					  !this.state.showAssetDetails &&
					  !this.state.showEvents &&
					  !this.state.showMonitoringDetails &&
					  !this.state.showAvailabilityDetails &&
					  !this.state.showRelatedIssues ? (
						<div className='d-flex justify-content-center m-4'>
							<Spinner className='text-center text-white' color='dark' size='lg' />
						</div>
					) : !this.props.showDetailsBillingSectionLoading &&
					  !this.state.showSummaryDetails &&
					  !this.state.showAssetDetails &&
					  !this.state.showEvents &&
					  !this.state.showMonitoringDetails &&
					  !this.state.showRelatedIssues &&
					  !this.state.showAvailabilityDetails &&
					  this.state.showBillingDetails ? (
						<div className='d-flex justify-content-center m-4'>
							<p>No billing details found</p>
						</div>
					) : null}

					<div className={`tab-pane fade ${this.state.showMonitoringDetails ? 'show active' : ''}`} id='billing' role='tabpanel' aria-labelledby='billing-tab'>
						<div className="p-1">
							<div className='bg-dark text-wrap text-left p-0 m-2 fs-13 d-flex rounded overflow-hidden flex-column border-0'>
								{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.resource_id) ?
									<code className='p-2 text-white align-self-center f12'>
										{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
											<span className="badge-square bg-muted p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
										: null }
										{this.props.selectedTableRow && this.props.selectedTableRow.resource_id ?
											<span>:&nbsp;{this.props.selectedTableRow.resource_id}</span>
										: null }
									</code>
								: null}
								<div className='bg-dark-theme-green p-2 f12 text-center text-white'>Active</div>
							</div>
							<div className="p-3">
								{!this.props.showDetailsMonitoringSectionLoading && this.state.showMonitoringDetails && (
									<div className="d-flex justify-content-end">
										{this.state.showMonitoringDetails && this.props.selectedRowMonitoringSnapshot &&
											Object.entries(this.props.selectedRowMonitoringSnapshot).length ? (
												<div className='w-70 float-left align-self-center pt-1 text-info'>
													<small>
														From:{' '}
														<b className='mr-2'>{this.props.selectedRowMonitoringSnapshot.start_time ? momentConvertionUtcToLocalTime(this.props.selectedRowMonitoringSnapshot.start_time, 'DD MMM YYYY HH:mm') : ''}</b>{' '}
														To:{' '}
														<b>{this.props.selectedRowMonitoringSnapshot.end_time ? momentConvertionUtcToLocalTime(this.props.selectedRowMonitoringSnapshot.end_time, 'DD MMM YYYY HH:mm') : ''}</b>
													</small>
												</div>
											)
											: null
										}
										<div className="select-sm-bg curvedDropdown minWidth120" toggle={() => this.toggleState("monitDurationFilter")}>
											<div className="dropdown">
												<span className="dropdown-toggle d-flex justify-content-between px-2 text-white" onClick={() => this.setState({ monitDurationFilter: !this.state.monitDurationFilter })}>
													{this.state.monitoringDuration ? monitoringDurationOptions.filter(e => e.value === this.state.monitoringDuration)[0].label : <span className="placeholder">Duration</span>}
													<span className="caret"></span>
												</span>
												<ul className={`dropdown-menu p-0 ${this.state.monitDurationFilter ? "show" : ''}`}>
													{monitoringDurationOptions.map(item => {
														return (
															<li 
																onClick={() => this.setState({ 
																	monitoringDuration: item.value, 
																	monitDurationFilter: false
																}, 
																	() => this.props.getMonitoringSnap(this.props.selectedTableRow, this.state.monitoringDuration)
																)}
																className={`${this.state.monitoringDuration === item.value ? "selected" :  ""}`}
															>
																{item.label}
															</li>
														)
													})}
												</ul>
											</div>
										</div>
									</div>
								)}
								
								{!this.props.showDetailsMonitoringSectionLoading &&
								this.state.showMonitoringDetails && this.props.selectedRowMonitoringSnapshot &&
								Object.entries(this.props.selectedRowMonitoringSnapshot).length ? (
									<div className="p-3 rounded bg-dark3 mt-3">
										{this.props.selectedRowMonitoringSnapshot.response && this.props.selectedRowMonitoringSnapshot.response.length ?
											this.props.selectedRowMonitoringSnapshot.response.map((item, index) => {
												return (
													<div className="row">
														{Object.keys(item.data).map((key, i) => {
															return (
																// <div className={`col-sm-6 mb-3`}>
																<div className={`col-sm-12 mb-3`}>
																	<div class="p-3 border rounded">
																		<p class="text-white f16 p-1 mb-0">{key}</p>
																		{this.state[key+'_metrics_series_'+index+'_'+i] && this.state[key+'_metrics_series_'+index+'_'+i].length && this.state[key+'_metrics_options_'+index+'_'+i] ?
																			<div className="cursorPointer transparentTooltip mb-n4 mt-n4">
																				<ApexChart options={this.state[key+'_metrics_options_'+index+'_'+i]} series={this.state[key+'_metrics_series_'+index+'_'+i]} type="area" height={115}/>
																			</div>
																		: 
																			<div style={{minHeight:"85px"}} className="d-flex align-self-center justify-content-center">
																				<div className='align-self-center'>
																					No Data
																				</div>
																			</div>
																		}
																	</div>
																</div>
															)
														})}
													</div>
												)
											})
										: 
											<div className='w-100 d-flex justify-content-center m-4'>
												<p>No monitoring details found</p>
											</div>
										}
									</div>
								) : this.props.showDetailsMonitoringSectionLoading && this.state.showMonitoringDetails ? (
									<div className='d-flex justify-content-center m-4'>
										<Spinner className='text-center text-white' color='dark' size='lg' />
									</div>
								) : !this.props.showDetailsMonitoringSectionLoading &&
								!Object.entries(this.props.selectedRowMonitoringSnapshot).length &&
								!this.state.showSummaryDetails &&
								!this.state.showAssetDetails &&
								!this.state.showBillingDetails &&
								!this.state.showEvents &&
								!this.state.showAvailabilityDetails &&
								this.state.showMonitoringDetails ? (
									<div className='w-100 d-flex justify-content-center m-4 text-white'>
										<p>No monitoring details found</p>
									</div>
								) : null}		
							</div>				
						</div>
					</div>
					<div
						className={`tab-pane fade ${this.state.showAvailabilityDetails ? 'show active' : ''}`}
						id='availability'
						role='tabpanel'
						aria-labelledby='availability-tab'>
							
						{this.state.showDetailsAvailSectionLoading && 
						this.state.showAvailabilityDetails &&
						this.state.filterAvailabilityData.length &&
						!this.state.showSummaryDetails &&
						!this.state.showAssetDetails &&
						!this.state.showBillingDetails &&
						!this.state.showEvents &&
						!this.state.showMonitoringDetails &&
						!this.state.showRelatedIssues ? (													
							<React.Fragment>
								<div className='badge badge-light text-wrap text-left p-0 my-1 mx-2 fs-13 d-flex rounded overflow-hidden flex-column border-0'>
									{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.resource_id) ?
										<code className='p-2 text-dark align-self-center f12'>
											{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
												<span className="badge-square bg-gray3 p-2 text-wrap mr-2 f13"> {this.props.selectedTableRow.service_name}</span>
											: null
											}
											{this.props.selectedTableRow && this.props.selectedTableRow.resource_id ?
												<span>:&nbsp;{this.props.selectedTableRow.resource_id}</span>
											: null
											}
										</code>
									: null 
									}
									<div className='bg-success p-2 f12 text-center text-white'>Active</div>
								</div>
								<div>
									<div className='mt-3'>
										<b className='mb-1 ml-3 mt-3 text-black'>Heat Map</b>
									</div>
									<p className='mb-2 ml-3'>
										<small>Earum aut eos. Sed praesentium et hic voluptate itaque ut.</small>
									</p>
								</div>
								<div className='mt-3 mb-4 pl-2 pr-2 assetHeatMap'>
									<div className="d-flex justify-content-between f12 mt-3 position-relative mb-2">
										<div className={`cursorPointer ${this.state.disablePreviousBtn ? 'disabled text-muted': 'text-info'}`}onClick={() => this.setState({ filterAvailabilityData: [], selectedAvailabilityMonth:(parseInt(this.state.month)-parseInt(1)) }, () => this.filterAvailabilityDetails('previous'))}><span className="far fa-angle-double-left"></span> Previous </div>
										{/* <div className="react-Calendar-month-name">{this.state.monthName && this.state.monthName}</div> */}
										<div className={`cursorPointer ${this.state.disableNextBtn ? 'disabled text-muted': 'text-info'}`} onClick={() => this.setState({ filterAvailabilityData:[], selectedAvailabilityMonth:(parseInt(this.state.month)+parseInt(1)) }, () => this.filterAvailabilityDetails('next'))}>Next <span className="far fa-angle-double-right"></span></div>
									</div>
									{this.state.filterAvailabilityData && this.state.filterAvailabilityData.length ?
										<React.Fragment>
										<div className={`m-auto ${this.state.heatDuration === 1 ? this.state.week === 6 ? 'w-30' : 'w-25' : this.state.heatDuration === 2 ? 'w-55' : 'w-70' } `}>
											<CalendarHeatmap
												startDate={this.state.startDate}
												endDate={this.state.endDate}
												values={this.state.filterAvailabilityData}
												classForValue={value => {
													if (!value) {
														return 'text-fill-empty';
													} else if(value.availability <= 20) {
														return `text-fill-color-1`;
													} else if(value.availability > 20 &&value.availability <= 30) {
														return `text-fill-color-2`;
													} else if(value.availability > 30 &&value.availability <= 40) {
														return `text-fill-color-3`;
													} else if(value.availability > 40) {
														return `text-fill-color-4`;
													}
												}}
												tooltipDataAttrs={value => {
													if(value.date != null) {
														return {
															'data-tip': `<p style="width:100%;margin-bottom:5px"><b>Day</b>:&nbsp; ${momentDate(value.date, 'DD MMM YY')} </p>
															<p style="width:100%;margin-bottom:5px"><b>Availability</b>:&nbsp; ${value.availability}% </p>
															<p style="width:100%;margin-bottom:5px"><b>CPU</b>:&nbsp; Average - ${value.cpu_average}% &emsp; Min - ${value.cpu_min}% &emsp; Max - ${value.cpu_max}%  </p> 
															<p style="width:100%;margin-bottom:5px"><b>Memory</b>:&nbsp; Average - ${value.memory_average}% &emsp; Min - ${value.memory_min}% &emsp; Max - ${value.memory_max}% </p>
															<p style="width:100%;margin-bottom:5px"><b>Disk Usage</b>:&nbsp; ${value.disk_usage}% </p>`
														};
													} else {
														return {
															'data-tip': 'No data'
														};
													}
												}}
												//showOutOfRangeDays={true}
												horizontal={true}
												showMonthLabels={true}
												showWeekdayLabels={false}
												gutterSize={2}
											/>
											<ReactTooltip html={true}/>
											
										</div>
										<div className='assetHeatMapLegend'> 
											{/* <div className="assetHeatMapHeader">% Structural <br />Components in SGR</div> */}
											<ul>
												<li>
													<div className="assetHeatMapBox text-fill-empty"></div> No Data
												</li>
												<li>
													<div className="assetHeatMapBox text-fill-color-1"></div> &lt; 20
												</li>
												<li>
													<div className="assetHeatMapBox text-fill-color-2"></div> 21 - 30
												</li>
												<li>
													<div className="assetHeatMapBox text-fill-color-3"></div> 31 - 40
												</li>
												<li>
													<div className="assetHeatMapBox text-fill-color-4"></div> &gt; 40
												</li>
											</ul>
										</div>
										</React.Fragment>
									: !this.state.filterAvailabilityData.length &&
										<div className='d-flex justify-content-center m-4'>
											<Spinner className='text-center text-white' color='dark' size='lg' />
										</div> 
									}

								</div>
							</React.Fragment>
						) : !this.props.showDetailsAvailSectionLoading && this.state.showAvailabilityDetails && !this.state.filterAvailabilityData.length ? (
							<div className='d-flex justify-content-center m-4'>
								<Spinner className='text-center white' color='dark' size='lg' />
							</div>
						) :
							!this.state.showDetailsAvailSectionLoading && 
							this.state.showAvailabilityDetails &&
							!Object.entries(this.props.selectedRowMonitoringSnapshot).length &&
							!this.state.showSummaryDetails &&
							!this.state.showAssetDetails &&
							!this.state.showBillingDetails &&
							!this.state.showEvents &&
							!this.state.showMonitoringDetails &&this.state.filterAvailabilityData &&
							<div className='d-flex justify-content-center m-4'>
								<p>No Availability details found</p>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
DetailsSection.propTypes = {
	selectedTableRow: PropTypes.object,
	detailsTabNames: PropTypes.array,
	getEventInfo: PropTypes.func,
	getAssetDetails: PropTypes.func,
	getRelatedIssues: PropTypes.func,
	getBillingSnap: PropTypes.func,
	getMonitoringSnap: PropTypes.func,
	selectedDetailsTab: PropTypes.number,
	setSelectedDetailsTabIndex: PropTypes.func,
	showDetailsTabListDropdown: PropTypes.bool,
	toggleDetailsTabListDropdown: PropTypes.func,
	onClickDetailsTabDropDownItem: PropTypes.func,
	toggleDetailsSectionLoading: PropTypes.func,
	selectedRowAssetDetails: PropTypes.array,
	selectedRowBillingDetails: PropTypes.object,
	showDetailsMonitoringSectionLoading: PropTypes.bool,
	showDetailsAssetSectionLoading: PropTypes.bool,
	showDetailsRelatedIssueLoading: PropTypes.bool,
	showDetailsEventsSectionLoading: PropTypes.bool,
	showDetailsBillingSectionLoading: PropTypes.bool,
	showSuppressListLoading: PropTypes.bool,
	showRelatedIssues: PropTypes.bool,
	relatedIssuesData: PropTypes.array,
	selectedRowMonitoringSnapshot: PropTypes.object,
	dayWiseCountGraphData: PropTypes.object,
	recentEventsData: PropTypes.array,
	topEventsData: PropTypes.object,
	eventGraphData: PropTypes.object,
	getAssetEvents: PropTypes.func,
	selectedMonitoringDuration: PropTypes.string,
	getDailyAssetAvailability: PropTypes.func
}

//export default 

export default connect(null, {
	getDailyAssetAvailability
})(withRouter(DetailsSection))
