
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file CallAgent.js
 * @author Prakash // on 15/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import { Input } from 'reactstrap'
import { setAutomationPropsDetails } from '../../actions/commonAction'
import { onlyNumeric } from '../../utils/utility';

const credStore = [
    {label: "None", value: "None"},
    {label: "Parameter Store", value: "Parameter Store"},
    {label: "Secrets Manager", value: "Secrets Manager"}
]

// const queryOptions = ["Add Queries", "Generate multiple queries using template"]

const databases = [
    {name: "Database 1", source_port: "this.state.source_port", source_endpoint: "this.state.source_endpoint", cred_store: "Parameter Store", cred_key_id: "this.state.cred_key_id", cred_usr_key: "this.state.cred_usr_key", cred_pwd_key: "this.state.cred_pwd_key", cred_endpoint_key: "this.state.cred_endpoint_key"},
    {name: "Database 2", source_port: "this.state.source_port 2", source_endpoint: "this.state.source_endpoint 2", cred_store: "Parameter Store", cred_key_id: "this.state.cred_key_id 2", cred_usr_key: "this.state.cred_usr_key 2", cred_pwd_key: "this.state.cred_pwd_key 2", cred_endpoint_key: "this.state.cred_endpoint_key 2"},
    {name: "Database 3", source_port: "this.state.source_port 3", source_endpoint: "this.state.source_endpoint 3", cred_store: "Parameter Store", cred_key_id: "this.state.cred_key_id 3", cred_usr_key: "this.state.cred_usr_key 3", cred_pwd_key: "this.state.cred_pwd_key 3", cred_endpoint_key: "this.state.cred_endpoint_key 3"}
]

class CallAgent extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeSection: this.props.activeSection,
            source_port: "",
            commandArray: [0],
            databases: databases,
            searchDatabases: databases
        }
    }

    componentDidMount = () => {
        if(this.props.automationPropsDetails) {
            let obj = this.props.automationPropsDetails
            let exist = obj.actions.filter(e => e.section === this.state.activeSection)
            if(exist.length) {
                let input = exist[0].input ? exist[0].input : []
                input.forEach(item => {
                    if(item.key === "api_request.queries") {                        
                        let commandArray = []
                        item.value_object_list.forEach((list, i) => {
                            commandArray.push(i)
                        })
                        this.setState({ commandArray }, 
                            () => {
                                item.value_object_list.forEach((list, i) => {
                                    list.forEach(lst => {
                                        this.setState({
                                            [lst.key+"_"+i]: lst.value,
                                            ["dynamic_"+lst.key+"_"+i]: lst.dynamic
                                        },
                                            () => this.checkAllDataFilled()
                                        )
                                        if(lst.key === "args" || lst.key === "query") {
                                            this.onLoadDropFields(lst, i)
                                        }
                                    })
                                })                                
                            }
                        )
                    } else if(item.key === "api_request.source_endpoint") {
                        this.setState({ source_endpoint: item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else if(item.key === "api_request.source_port") {
                        this.setState({ source_port: item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else if(item.key === "database") {
                        this.setState({ selected_database: item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else if(item.key === "api_request.cred_store") {
                        let value = item.value
                        if(item.value === "") {
                            value = "None"
                        }
                        this.setState({ cred_store: value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else if(item.key === "api_request.cred_key_id") {
                        this.setState({ cred_key_id: item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else if(item.key === "api_request.cred_usr_key") {
                        this.setState({ cred_usr_key: item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else if(item.key === "api_request.cred_pwd_key") {
                        this.setState({ cred_pwd_key: item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else if(item.key === "api_request.cred_endpoint_key") {
                        this.setState({ cred_endpoint_key: item.value },
                            () => {
                                this.checkAllDataFilled()
                            }
                        )
                    } else if(item.key === "api_request.cred_port_key") {
                        this.setState({ cred_port_key: item.value })
                    }
                })                
            }
            let actions = this.props.automationPropsDetails.actions ? this.props.automationPropsDetails.actions : []
            let previousAutomations = actions.filter(e => e.section < this.state.activeSection)

            this.setState({ previousAutomations, previousAutomationsSearchResult: previousAutomations })
        }
    }

    componentDidUpdate = (prevProps) => {}

    onActionSelect = (action) => {
        this.setState({ 
            selectedAction: action, 
            showActionResults: false 
        },
            // () => this.formInputProps(["action_"+this.state.activeSection], "action", this.state.selectedAction)
        )
    }

    formInputProps = (section, field, value) => {
        let obj = this.props.automationPropsDetails
        let actions = obj.actions
        let objIndex = actions.findIndex((e => e.section === section))


        let dataRow = {}
        if(field === "name" || field === "query" || field === "args") {
            let arrayList = []
            this.state.commandArray.forEach(item => {
                let dataArray = [
                    {key: "name", value: this.state["name_"+item] ? this.state["name_"+item] : ""},
                    {key: "query", value: this.state["query_"+item] ? this.state["query_"+item] : "", dynamic: this.state["dynamic_query_"+item] ? true : false},
                    {key: "args", value: this.state["args_"+item] ? this.state["args_"+item] : "", dynamic: this.state["dynamic_args_"+item] ? true : false}
                ]

                arrayList.push(dataArray)
            })
            field = "api_request.queries"
            dataRow.key = field
            dataRow.value_object_list = arrayList            
        } else {
            if(field === "source_endpoint") {
                field = "api_request.source_endpoint"
            } else if(field === "source_port") {
                field = "api_request.source_port"
            } else if(field === "cred_store") {
                field = "api_request.cred_store"
                if(value === "None") {
                    value = ""
                }
            } else if(field === "cred_key_id") {
                field = "api_request.cred_key_id"
            } else if(field === "cred_usr_key") {
                field = "api_request.cred_usr_key"
            } else if(field === "cred_pwd_key") {
                field = "api_request.cred_pwd_key"
            } else if(field === "cred_endpoint_key") {
                field = "api_request.cred_endpoint_key"            
            } else if(field === "cred_port_key") {
                field = "api_request.cred_port_key"
            }
            dataRow.key = field
            dataRow.value = value
        }

        let credFields = ["api_request.cred_key_id", "api_request.cred_usr_key", "api_request.cred_pwd_key", "api_request.cred_endpoint_key", "cred_port_key"]

        let input = actions[objIndex].input ? actions[objIndex].input : []
        if(this.state.cred_store && this.state.cred_store === "None") {
            credFields.forEach(item => {
                input = input.filter(e => e.key !== item)
            })
        }

        let inputIndex = input.findIndex((e => e.key === field))
        if(inputIndex > -1) {
            input[inputIndex] = dataRow
        } else {
            input.push(dataRow)
        }

        //check for source port exist and if not exit hardcode the port value
        if(!input.filter(e => e.key === "api_request.source_port").length) {
            let dataRow = {}
            dataRow.key = "api_request.source_port"
            dataRow.value = this.state.source_port
            input.push(dataRow)
        }//end

        actions[objIndex].input = input
        obj.actions = actions
        this.props.setAutomationPropsDetails(obj)

        this.checkAllDataFilled()        
    }

    checkAllDataFilled = () => {
        let hasError = false
        // let errorMessage = ""
        // if( === "") {
        //     hasError = true
        //     errorMessage = "Please fill minimum one key value"
        // }
        this.setState({ hasError })
        let actionCompleted = true
        if(!hasError) {
            //!this.state.source_endpoint || this.state.source_endpoint === "" ||
            if(!this.state.source_port || this.state.source_port === "" || !this.state.cred_store) {
                actionCompleted = false
            }
            
            if(actionCompleted && this.state.cred_store !== "None") {
                if(!this.state.cred_key_id || this.state.cred_key_id === "" || !this.state.cred_usr_key || this.state.cred_usr_key === "" || !this.state.cred_pwd_key || this.state.cred_pwd_key === "" || !this.state.cred_endpoint_key || this.state.cred_endpoint_key === "") {
                    actionCompleted = false
                }
            }

            // actionCompleted && this.state.commandArray.forEach(index => {
            //     if(!this.state["name_"+index] || this.state["name_"+index] === "") {
            //         // || !this.state["query_"+index] || this.state["query_"+index] === "" || !this.state["args_"+index] || this.state["args_"+index] === "")) {
            //         actionCompleted = false
            //     }
            // })

        }
        this.props.actionCompleted(actionCompleted)
    }

    addSection = (array) => {
        let rowList = this.state[array];
        if(this.state[array]) {
            let value = this.state[array][this.state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        this.setState({[array]: rowList })
    }

    removeSection = (item, i) => {
        this.setState({ ["name_"+item]: "", ["query_"+item]: "", ["args_"+item]: "" },
            () => {
                let rowList = this.state.commandArray;
                rowList.splice(i, 1);
                this.setState({ commandArray: rowList })
            }
        );
    }

    selectedDatase = () => {
        let database = this.state.selected_database_details        
        this.setState({ 
            showDatabaseOptions: false,
            source_port: database.source_port ? database.source_port : "",
            source_endpoint: database.source_endpoint ? database.source_endpoint : "",
            cred_store: database.cred_store ? database.cred_store : "",
            cred_key_id: database.cred_key_id ? database.cred_key_id : "",
            cred_usr_key: database.cred_usr_key ? database.cred_usr_key : "",
            cred_pwd_key: database.cred_pwd_key ? database.cred_pwd_key : "",
            cred_endpoint_key: database.cred_endpoint_key ? database.cred_endpoint_key : "",
            cred_port_key: database.cred_port_key ? database.cred_port_key : "",
        },
            () => {
                this.formInputProps(this.state.activeSection, "source_port", this.state.source_port)
                this.formInputProps(this.state.activeSection, "source_endpoint", this.state.source_endpoint) 
                this.formInputProps(this.state.activeSection, "cred_store", this.state.cred_store)
                this.formInputProps(this.state.activeSection, "cred_key_id", this.state.cred_key_id)
                this.formInputProps(this.state.activeSection, "cred_usr_key", this.state.cred_usr_key) 
                this.formInputProps(this.state.activeSection, "cred_pwd_key", this.state.cred_pwd_key) 
                this.formInputProps(this.state.activeSection, "cred_endpoint_key", this.state.cred_endpoint_key)
                this.formInputProps(this.state.activeSection, "cred_port_key", this.state.cred_port_key)
                this.formInputProps(this.state.activeSection, "database", this.state.selected_database)
            }
        )
    }

    editDatabase = (database) => {
        this.props.showHelperSection("database", database)
        this.setState({ showDatabaseOptions: false })
    }

    searchDatabase = () => {
        let searchKey = this.state.searchKey ? this.state.searchKey : ""
        let newList = []
        if (searchKey !== "" && searchKey.length) {
            let currentList = this.state.databases
            newList =
                currentList &&
                currentList.filter(item => {
                    let isPresent = []
                    if(typeof item === "string") {
                        return item !== null
                            ? item
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(searchKey.toString().toLowerCase()) > -1
                            : false
                    } else {
                        isPresent = this.recursiveSearch(item, searchKey)
                            .flat()
                            .filter(bool => bool === true)
                        if (isPresent[0]) {
                            return true
                        } else {
                            return false
                        }
                    }
                    
                })
        } else if (searchKey === "") {
            newList = this.state.databases
            // this.setState({ ["dynamic_"+index]: false })
        }
        
        this.setState({ searchDatabases: newList, showDatabaseOptions: true })
    }

    checkEnteredCharacter = (e, val, field, index) => {
        // var editable = document.querySelectorAll('div[contentEditable]');
        let className = field+"_"+this.state.activeSection
        let fieldState = field
        if(index !== "") {
            fieldState = field+"_"+index
            className = field+"_"+this.state.activeSection+"_"+index
        }
        let innerHtml = document.getElementsByClassName(className)[0].innerHTML
        innerHtml = innerHtml.replaceAll('<span class="text-white">&nbsp;</span>', "")
        innerHtml = innerHtml.replaceAll('<span class="text-info">', "")
        innerHtml = innerHtml.replaceAll('<span class="text-white">', "")
        innerHtml = innerHtml.replaceAll('</span>', "")

        var re = /<div>(.*?)<\/div>/;
        var result = innerHtml.split(re);

        let stringToSave = ""
        if(result.length) {
            let visit = 0
            result.forEach((item, rIndex) => {
                if(item !== "") {
                    
                    let br = ""
                    // if(rIndex && visit) {
                    //     br = "/n"
                    // }
                    // if(result.length - 1 !== rIndex) {
                    //     br = "/n"
                    // }
                    if(item === "<br>") {
                        item = "/n"
                    } else if(rIndex && visit) {
                        br = "/n"
                    }
                    stringToSave += br+""+item
                    visit++;
                }
            })
        }
        
        let position = stringToSave.indexOf("$$");
        if(position >= 0) {
            this.setState({ ["show_"+field+"_drop_option_"+index]: true, [fieldState]: stringToSave })
        } else {
            this.setState({ ["show_"+field+"_drop_option_"+index]: false, [fieldState]: stringToSave },
                () => this.checkFieldDynamic(field, index)
            )
        }
        this.setState({ [fieldState]: stringToSave },
            () => this.formInputProps(this.state.activeSection, field, this.state[fieldState])
        )
    }

    selectedpreviousAutomation = (field, index, actionDetails, childIndex, childDetails) => {
        this.state.previousAutomations.forEach((item, i) => {
            this.setState({ ["showChild_"+i]: false })
        })

        //if there is child it will be confiigured when real data is available for child section
        // this.setState({ ["showChild_"+childIndex]: !this.state["showChild_"+childIndex] })

        //if there is no child
        this.selectedField(field, index, actionDetails, childIndex, childDetails)
    }

    selectedField = (field, index, actionDetails, childIndex, childDetails) => {
        let className = field+"_"+this.state.activeSection
        let fieldState = field
        if(index !== "") {
            fieldState = field+"_"+index
            className = field+"_"+this.state.activeSection+"_"+index
        }

        let stringToSave = this.state[fieldState]

        if(actionDetails !== "") {
            let res = "${"+actionDetails.name+".}"
            if(childDetails !== "") {
                res = "${"+actionDetails.name+"."+childDetails.name+"}"
            }
            stringToSave = stringToSave.replace("$$", res)
        }
        //form html from the string to show in the contenteditable        
        let innertHtmlData = ""
        let splitString = stringToSave.split("/n")
        let re = /{(.*?)}/;
        splitString.forEach(str => {
            innertHtmlData += "<div>"
            if(str !== "") {
                let result = str.split(re);
                let previousdata = ""
                result.forEach((item, i) => {
                    let lastTwoLetters = item.substring(item.length, item.length-2)
                    let lastLetter = item.charAt(item.length-1)
                    if(previousdata === "$") {
                        innertHtmlData += '<span class="text-info">${'+item+'}</span> <span class="text-white">&nbsp;</span>'
                    } else if(item !== "$") {
                        if(lastLetter === '$' && item !== "$$" && lastTwoLetters !== "$$") {
                            item = item.substring(0, item.length - 1)
                        }
                        innertHtmlData += '<span class="text-white">'+item+'</span>'
                    }

                    previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                })
            } else {
                innertHtmlData += "<br>"    
            }

            innertHtmlData += "</div>"
        })

        document.getElementsByClassName(className)[0].innerHTML = innertHtmlData

        this.setState({ ["show_"+field+"_drop_option_"+index]: false, [fieldState]: stringToSave },
            () => {
                this.formInputProps(this.state.activeSection, field, this.state[fieldState])
                this.checkFieldDynamic(field, index)
            }
        )
    }

    checkFieldDynamic = (field, index) => {
        let dynamic = false
        let fieldState = field
        let dynamicField = "dynamic_"+field 
        if(index !== "") {
            fieldState = field+"_"+index
            dynamicField = "dynamic_"+field+"_"+index
        }

        let stringToSave = this.state[fieldState]
        //form html from the string to show in the contenteditable
        let splitString = stringToSave.split("/n")
        let re = /{(.*?)}/;
        splitString.forEach(str => {
            if(str !== "") {
                let result = str.split(re);
                let previousdata = ""
                result.forEach((item, i) => {
                    let lastTwoLetters = item.substring(item.length, item.length-2)
                    let lastLetter = item.charAt(item.length-1)
                    if(previousdata === "$") {
                        dynamic = true
                    }
    
                    previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                })
            }
        })

        this.setState({ [dynamicField]: dynamic },
            () => this.formInputProps(this.state.activeSection, field, this.state.messageArray)    
        )
    }

    onLoadDropFields = (list, index) => {
        let className = list.key+"_"+this.state.activeSection
        if(index !== "") {
            className = list.key+"_"+this.state.activeSection+"_"+index
        }

        let stringToSave = list.value ? list.value : ""
        
        //form html from the string to show in the contenteditable
        if(stringToSave && stringToSave !== "") {
            let innertHtmlData = ""
            let splitString = stringToSave.split("/n")
            let re = /{(.*?)}/;
            splitString.forEach(str => {
                innertHtmlData += "<div>"
                if(str !== "") {
                    let result = str.split(re);
                    let previousdata = ""
                    result.forEach((item, i) => {
                        let lastTwoLetters = item.substring(item.length, item.length-2)
                        let lastLetter = item.charAt(item.length-1)
                        if(previousdata === "$") {
                            innertHtmlData += '<span class="text-info">${'+item+'}</span> <span class="text-white">&nbsp;</span>'
                        } else if(item !== "$") {
                            if(lastLetter === '$' && item !== "$$" && lastTwoLetters !== "$$") {
                                item = item.substring(0, item.length - 1)
                            }
                            innertHtmlData += '<span class="text-white">'+item+'</span>'
                        }

                        previousdata = lastTwoLetters === "$$" ? "" : lastLetter
                    })
                } else {
                    innertHtmlData += "<br>"
                }
                innertHtmlData += "</div>"
            })            

            document.getElementsByClassName(className)[0].innerHTML = innertHtmlData
        }
        // this.setState({ [fieldState]: stringToSave })

        // this.formInputProps(this.state.activeSection, field, stringToSave)
    }

    render() {
        return (
            <div className="">
                <div className="d-flex mt-3 displayNone">
                    <div className="position-relative d-inline-block w-50">
                        <label className="mb-1">Database</label>
                        <Input
                            type="text"
                            placeholder="Select Database"
                            className="inputTextbox"
                            value={this.state.selected_database ? this.state.selected_database : this.state.searchKey}
                            onChange={event => 
                                this.setState({ searchKey: event.target.value, showDatabaseOptions: true },
                                    () => {
                                        // this.formInputProps(this.state.activeSection, "multiply_with", this.state.multiply_with)
                                        // this.searchDatabase()
                                        // this.searchDatabase(this.state.searchKey)
                                    }
                                )
                            }
                            onClick={() => this.setState({ showDatabaseOptions: true })}
                        />

                        {this.state.showDatabaseOptions ? 
                            <div className={`dropdownOptions topAuto mt-2`}>
                                <button className="d-flex justify-content-between mb-3" onClick={() => this.setState({ selected_database: 'Add Database', showDatabaseOptions: false }, () => this.props.showHelperSection("database", "new"))}>
                                    <p className="mb-0 text-info">Add Database</p>
                                </button>
                                {this.state.searchDatabases && this.state.searchDatabases.length ? 
                                    this.state.searchDatabases.map(dat => {
                                        return (
                                            <button className="d-flex justify-content-between mb-3" onClick={() => this.setState({ selected_database: dat.name, showDatabaseOptions: false, searchKey: "", selected_database_details: dat },
                                                () => this.selectedDatase()    
                                            )}>
                                                <p className="mb-0 text-left">{dat.name}</p>
                                                <p className="mb-0 small btn btn-sm btn-primary" onClick={() => this.editDatabase(dat)}>Edit</p>
                                            </button>
                                        )
                                    })
                                : null}
                            </div>
                        : null}
                        
                        {/* <Select 
                            options={this.state.databases.map(item => ({
                                value: item,
                                label: item,
                            }))}
                            value={({
                                value: this.state.selected_database && this.state.selected_database,
                                label: this.state.selected_database ? this.state.selected_database : <span className="placeholder">Select Database</span>
                            })}
                            onChange={event => 
                                this.setState({ selected_database: event.value },
                                    () => {
                                        if(this.state.selected_database === "Add Database") {
                                            this.props.showHelperSection("database")
                                        }
                                        // this.formInputProps(this.state.activeSection, "database", this.state.selected_database)
                                    }
                                )
                            }
                        /> */}
                    </div>
                </div>

                <div className="d-flex mt-3">
                    <div className="w-50">
                        <label className="mb-1">Endpoint</label>
                        <Input
                            type="text"
                            placeholder="endpoint"
                            className="inputTextbox"
                            value={this.state.source_endpoint}
                            id={"source_endpoint"}
                            onChange={event =>
                                this.setState({ source_endpoint: event.target.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "source_endpoint", this.state.source_endpoint) 
                                    }
                                )
                            }
                        />
                    </div>
                    <div className="pl-3 w-50">
                        <label className="mb-1">Port</label>
                        <Input
                            type="text"
                            placeholder="port"
                            className="inputTextbox"
                            value={this.state.source_port}
                            id={"source_port"}
                            onChange={event =>
                                this.setState({ source_port: onlyNumeric(event.target.value) },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "source_port", this.state.source_port)
                                    }
                                )
                            }
                        />
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <div className="w-50">
                        <label className="mb-1">Storage in which credentials or endpoint details are stored</label>
                        <Select 
                            options={credStore.map(item => ({
                                value: item.value,
                                label: item.label,
                            }))}
                            value={({
                                value: this.state.cred_store && this.state.cred_store,
                                label: this.state.cred_store ? this.state.cred_store : <span className="placeholder">Select storage</span>
                            })}
                            onChange={event => 
                                this.setState({ cred_store: event.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "cred_store", this.state.cred_store)
                                    }
                                )
                            }
                        />
                    </div>
                    <div className={`pl-3 w-50 ${this.state.cred_store && this.state.cred_store !== "None" ? "" : "displayNone"}`}>
                        <label className="mb-1">Identity in credential store</label>
                        <Input
                            type="text"
                            placeholder="cred key"
                            className="inputTextbox"
                            value={this.state.cred_key_id}
                            id={"cred_key_id"}
                            onChange={event =>
                                this.setState({ cred_key_id: event.target.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "cred_key_id", this.state.cred_key_id) 
                                    }
                                )
                            }
                        />
                    </div>
                </div>

                <div className="d-flex mt-3">
                    <div className={`w-50 ${this.state.cred_store && this.state.cred_store !== "None" ? "" : "displayNone"}`}>
                        <label className="mb-1">Name of key in which endpoint is saved</label>
                        <Input
                            type="text"
                            placeholder="cred endpoint key"
                            className="inputTextbox"
                            value={this.state.cred_endpoint_key}
                            id={"cred_endpoint_key"}
                            onChange={event =>
                                this.setState({ cred_endpoint_key: event.target.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "cred_endpoint_key", this.state.cred_endpoint_key) 
                                    }
                                )
                            }
                        />
                    </div>
                    <div className={`pl-3 w-50 ${this.state.cred_store && this.state.cred_store !== "None" ? "" : "displayNone"}`}>
                        <label className="mb-1">Name of key in which port is saved</label>
                        <Input
                            type="text"
                            placeholder="cred port key"
                            className="inputTextbox"
                            value={this.state.cred_port_key}
                            id={"cred_port_key"}
                            onChange={event =>
                                this.setState({ cred_port_key: event.target.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "cred_port_key", this.state.cred_port_key) 
                                    }
                                )
                            }
                        />
                    </div>
                </div>

                <div className="d-flex mt-3">
                    <div className={`w-50 ${this.state.cred_store && this.state.cred_store !== "None" ? "" : "displayNone"}`}>
                        <label className="mb-1">Name of key in which username is saved</label>
                        <Input
                            type="text"
                            placeholder="cred user key"
                            className="inputTextbox"
                            value={this.state.cred_usr_key}
                            id={"cred_usr_key"}
                            onChange={event =>
                                this.setState({ cred_usr_key: event.target.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "cred_usr_key", this.state.cred_usr_key) 
                                    }
                                )
                            }
                        />
                    </div>
                    <div className={`pl-3 w-50 ${this.state.cred_store && this.state.cred_store !== "None" ? "" : "displayNone"}`}>
                        <label className="mb-1">Name of key in which password is saved</label>
                        <Input
                            type="text"
                            placeholder="cred password key"
                            className="inputTextbox"
                            value={this.state.cred_pwd_key}
                            id={"cred_pwd_key"}
                            onChange={event =>
                                this.setState({ cred_pwd_key: event.target.value },
                                    () => {
                                        this.formInputProps(this.state.activeSection, "cred_pwd_key", this.state.cred_pwd_key) 
                                    }
                                )
                            }
                        />
                    </div>
                </div>                

                <label className="mb-1 text-white mt-3 w-100">Commands to run</label>
                {this.state.commandArray.map((index, i) => {
                    return(
                        <React.Fragment>
                        <div className="p-3 bg-dark3 mt-2 rounded">
                            <div className="d-flex">
                                <div className="w-50">
                                    <label className="mb-1">Name of command</label>
                                    <Input
                                        type="text"
                                        placeholder="command name"
                                        className="inputTextbox"
                                        value={this.state["name_"+index]}
                                        onChange={event => 
                                            this.setState({ ["name_"+index]: event.target.value },
                                                () => this.formInputProps(this.state.activeSection, "name", this.state.commandArray)
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mt-2 d-flex">
                                <div className="w-100">
                                    <div className="position-relative d-inline-block w-100">
                                        <label className="mb-1">Query</label>
                                        <div contenteditable="true" 
                                            className={`form-control inputTextbox contentEditable h-auto f14 minHeight75px query_${this.state.activeSection}_${index}`}
                                            placeholder="query..."
                                            id={"query_"+index}
                                            onKeyUp={e => this.checkEnteredCharacter(e, e.currentTarget.textContent, "query", index)}
                                            // onInput={e => this.setState({ ["query_state_"+index]: e.currentTarget.textContent })}
                                        >
                                        </div>
                                        
                                        {this.state["show_query_drop_option_"+index] && this.state.previousAutomations && this.state.previousAutomations.length ? 
                                            <div className={`dropdownOptions topAuto mt-2`}>
                                                {this.state.previousAutomations.map((prevAct, j) => {
                                                    return (
                                                        <React.Fragment>
                                                            <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("query", index, prevAct, j, "")}>
                                                                <div className="d-flex justify-content-between w-100">
                                                                    <div>
                                                                        <p className="mb-0 text-left">{prevAct.name}</p>
                                                                        <p className="mb-0 small description">{prevAct.action_name}</p>
                                                                    </div>
                                                                    {/* if there is child it will be confiigured when real data is available for child section */}
                                                                    {/* {this.state["showChild_"+j] ? 
                                                                        <i className="fas fa-angle-up mr-2 f18"></i>
                                                                    :
                                                                        <i className="fas fa-angle-down mr-2 f18"></i>
                                                                    } */}
                                                                    
                                                                </div>
                                                            </button>
                                                            {/* {this.state["showChild_"+j] ? 
                                                                this.state.previousAutomations.map(child => {
                                                                    return(
                                                                    <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("template_data", index, prevAct, j, child)}>
                                                                        <p className="mb-0 f12 label">{child.action_name}</p>
                                                                    </button>
                                                                    )
                                                                })
                                                            : null} */}
                                                        </React.Fragment>
                                                    )   
                                                })}
                                            </div>
                                        : null}
                                    </div>
                                    {/* <Input
                                        type="text"
                                        placeholder="query"
                                        className="inputTextbox"
                                        value={this.state["query_"+index]}
                                        onChange={event => 
                                            this.setState({ ["query_"+index]: event.target.value },
                                                () => this.formInputProps(this.state.activeSection, "query", this.state.commandArray)
                                            )
                                        }
                                    /> */}
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="d-flex">
                                    <div className="position-relative d-inline-block w-100">
                                        <label className="mb-1">Arguments for command</label>
                                        <div className="position-relative d-inline-block w-100">
                                            <label className="mb-1">Data to use for query template</label>
                                            <div contenteditable="true" 
                                                className={`form-control inputTextbox contentEditable h-auto f14 minHeight75px args_${this.state.activeSection}_${index}`}
                                                placeholder="arguments..."
                                                id={"args_"+index}
                                                onKeyUp={e => this.checkEnteredCharacter(e, e.currentTarget.textContent, "args", index)}
                                                // onInput={e => this.setState({ ["args_"+index]: e.currentTarget.textContent })}
                                            >
                                            </div>                                            
                                            {this.state["show_args_drop_option_"+index] && this.state.previousAutomations && this.state.previousAutomations.length ? 
                                                <div className={`dropdownOptions topAuto mt-2`}>
                                                    {this.state.previousAutomations.map((prevAct, j) => {
                                                        return (
                                                            <React.Fragment>
                                                                <button className="d-flex flex-column mb-3" onClick={() => this.selectedpreviousAutomation("args", index, prevAct, j, "")}>
                                                                    <div className="d-flex justify-content-between w-100">
                                                                        <div>
                                                                            <p className="mb-0 text-left">{prevAct.name}</p>
                                                                            <p className="mb-0 small description">{prevAct.action_name}</p>
                                                                        </div>
                                                                        {/* if there is child it will be confiigured when real data is available for child section */}
                                                                        {/* {this.state["showChild_"+j] ? 
                                                                            <i className="fas fa-angle-up mr-2 f18"></i>
                                                                        :
                                                                            <i className="fas fa-angle-down mr-2 f18"></i>
                                                                        } */}
                                                                        
                                                                    </div>
                                                                </button>
                                                                {/* {this.state["showChild_"+j] ? 
                                                                    this.state.previousAutomations.map(child => {
                                                                        return(
                                                                        <button className={`d-flex flex-column ml-2 ${j === this.state.previousAutomations.length - 1 ? "mb-3" : "mb-2 mt-n2"} `}  onClick={() => this.selectedField("args", index, prevAct, j, child)}>
                                                                            <p className="mb-0 f12 label">{child.action_name}</p>
                                                                        </button>
                                                                        )
                                                                    })
                                                                : null} */}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            : null}
                                        </div>
                                        {/* <Input
                                            type="text"
                                            placeholder="arguments"
                                            className="inputTextbox"
                                            value={this.state["args_"+index]}
                                            onChange={event => 
                                                this.setState({ ["args_"+index]: event.target.value },
                                                    () => {
                                                        this.searchPreviousAutomations("show_drop_"+index, index, this.state["args_"+index] ? this.state["args_"+index] : "")
                                                        this.formInputProps(this.state.activeSection, "args", this.state.commandArray)
                                                    }
                                                )
                                            }
                                            onClick={() => this.onClickDropOption("show_drop_"+index, index, this.state["args_"+index] ? this.state["args_"+index] : "")}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.commandArray.length > 1 ?
                            <span className={`cursor-pointer f14 align-self-center ml-4 text-info mt-2`} onClick={() => this.removeSection(index, i)}> <i className="far fa-trash mr-1"></i> Delete</span>
                        : null}
                        {(this.state.commandArray.length - 1) ===  i ?
                            <span className={`cursor-pointer f14 align-self-center ml-4 text-info mt-2`} onClick={() => this.addSection("commandArray")}> <i className="far fa-plus mr-1"></i>Add</span>
                        : null}
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
CallAgent.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log("CallAgent",state)
    return {
        automationPropsDetails: state.filters.automationPropsDetails
    }
}

export default connect(mapStateToProps, {
    setAutomationPropsDetails,
})(withRouter(CallAgent))        