/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react'
import { setEditRuleDetails } from '../../../actions/governance/governanceAction'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Label } from 'reactstrap'

class ServiceAccessIpAddress extends Component {
	constructor(props) {
		super(props)
		this.state = {
			ipAddresses: {},
		}
	}
	componentDidMount = () => {
		if (this.props.parameters) {
			this.setState({ ipAddresses: this.props.parameters.condition })
		}
	}
	addIpToList = (type, ipAddress) => {
		const { ipAddresses } = this.state
		var list = ipAddresses[type]
		list.push(ipAddress)
		this.props.setEditRuleDetails('condition', { ...ipAddresses, [type]: list })
		this.setState({ ipAddresses: { ...ipAddresses, [type]: list } })
	}

	removeIpFromList = (type, ipAddress) => {
		const { ipAddresses } = this.state
		var list = ipAddresses[type]
		_.pull(list, ipAddress)
		this.props.setEditRuleDetails('condition', { ...ipAddresses, [type]: list })
		this.setState({ ipAddresses: { ...ipAddresses, [type]: list } })
	}

	handleChange = event => {
		const { ipAddresses } = this.state
		let type = event.target.name.split('@')[0]
		let index = event.target.name.split('@')[1]
		var list = ipAddresses[type]
		list.splice(index, 1, event.target.value)
		this.props.setEditRuleDetails('condition', { ...ipAddresses, [type]: list })
		this.setState({ ipAddresses: { ...ipAddresses, [type]: list } })
	}

	render() {
		const { ipAddresses } = this.state
		return (
			<div className='card border-0'>
				<div className='card-header p-0 border-0 bg-transparent' id='headingThree'>
					{ipAddresses.allow && ipAddresses.allow.length === 0 ? (
						<button className='btn btn-outline-primary mb-2' onClick={() => this.addIpToList('allow', '')}>
							Add Allowed IP Address
						</button>
					) : (
						<Label>Allowed IP Addresses:</Label>
					)}
				</div>
				{ipAddresses.allow && ipAddresses.allow.length
					? ipAddresses.allow.map((ip, index) => {
							return (
								<div className='d-flex mb-1' key={index}>
									<div className='mr-3'>
										<input
											type='text'
											className='form-control'
											onChange={e => this.handleChange(e)}
											name={'allow@' + index}
											id={index + 'key-input'}
											value={ip}
										/>
									</div>
									<div>
										<button className='btn btn-outline-success border-0' onClick={() => this.addIpToList('allow', '')}>
											<i className='far fa-plus-circle' />
										</button>
										<button
											className='btn btn-outline-danger border-0'
											onClick={() => this.removeIpFromList('allow', ip)}
										>
											<i className='far fa-trash-alt' />
										</button>
									</div>
								</div>
							)
					  })
					: null}

				<div className='card-header p-0 border-0 bg-transparent' id='headingThree'>
					{ipAddresses.deny && ipAddresses.deny.length === 0 ? (
						<button className='btn btn-outline-primary mb-2' onClick={() => this.addIpToList('deny', '')}>
							Add Blocked IP Address
						</button>
					) : (
						<Label>Blocked IP Addresses:</Label>
					)}
				</div>
				{ipAddresses.deny && ipAddresses.deny.length
					? ipAddresses.deny.map((ip, index) => {
							return (
								<div className='d-flex' key={index}>
									<div className='mr-3 mb-1'>
										<input
											type='text'
											className='form-control'
											onChange={e => this.handleChange(e)}
											name={'deny@' + index}
											id={index + 'key-input'}
											value={ip}
										/>
									</div>
									<div>
										<button className='btn btn-outline-success border-0' onClick={() => this.addIpToList('deny', '')}>
											<i className='far fa-plus-circle' />
										</button>
										<button
											className='btn btn-outline-danger border-0'
											onClick={() => this.removeIpFromList('deny', ip)}
										>
											<i className='far fa-trash-alt' />
										</button>
									</div>
								</div>
							)
					  })
					: null}
			</div>
		)
	}
}
/**
 * Type of the props used in the component
 */
ServiceAccessIpAddress.propTypes = {
	parameters: PropTypes.object,
	setEditRuleDetails: PropTypes.func,
	editRuleDetails: PropTypes.object,
}
/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	return {
		editRuleDetails: state.governance.editRuleDetails,
	}
}

export default connect(mapStateToProps, { setEditRuleDetails })(ServiceAccessIpAddress)
