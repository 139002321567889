/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Metrics.js
 * @author Prakash // on 03/03/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { getMonitoringMetricsByTrend, getAiopsDailyTrendByAssets } from '../../../actions/aiops/MetricsAction'

import EventMetricList from './EventMetricList'
import EventMetricDetails from './EventMetricDetails'

// import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

// import Spinner from 'reactstrap/lib/Spinner'
import { Spinner } from 'reactstrap'


class EventsRightSection extends Component {
    
    constructor(props) {
        super(props)
        this.scrolltoTop = React.createRef()

        this.state = {
            caseId: this.props.caseId,
            showMetricsDetails: this.props.showMetricsDetails ? true : false,
            activeSideTopTab: 'InstanceMetrics',
            showMetricsDeatils: false,
            showFilerSection: true,

            placeHolder: '',
            searchText: '',
            filterValue: [],
            showAlertTableLoading: false,

            showMetricsList: false,
        }
    }

    componentDidMount = () => {
        this.props.setAiopsPropsDetails('showEventMetricsList', true)
    } 

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsPropsDetails && Object.entries(this.props.aiopsPropsDetails).length) {
            if(prevProps.eventPageDetails)  {                
                if(this.props.eventPageDetails && this.props.eventPageDetails !== prevProps.eventPageDetails) {
                    this.setState({ 
                        expandCommonEventDetails: true,
                        expandUserIdentity: false, 
                        expandAccessDetails: false, 
                        expandTagDetails: false, 
                        expandPropsAssetDetails: false, 
                        expandEventAssetDetails: false, 
                        // details: this.props.tabOptions.details,
                        // merticsAssetNameInvolved: this.props.tabOptions.merticsAssetNameInvolved
                    })
                    
                    this.scrolltoTop.current.scrollIntoView()
                    if(this.props.eventPageDetails && this.props.eventPageDetails.assets) {
                        if(!this.props.eventMonitoringList.hasOwnProperty(this.props.eventPageDetails.assetName)) {
                            this.setState({ assetName: this.props.eventPageDetails.assetName, showMetrics: false, showMetricsList: false }, () => this.getMonitoringMetricsByTrend() )
                        } else if(this.props.eventMonitoringList.hasOwnProperty(this.props.eventPageDetails.assetName)) {
                            this.setState({ showMetricsList: true },  
                            )
                        }
                    }
                }
            }
        }
    }

    getMonitoringMetricsByTrend = () => {
        let params = {}
        params.provider = this.props.caseDetails && this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0] : this.props.caseDetails.provider) : 'aws'
        params.start_time = this.props.aiopsPropsDetails.event_slider_changes && this.props.aiopsPropsDetails.event_slider_changes.sliderStartDate
        params.end_time = this.props.aiopsPropsDetails.event_slider_changes && this.props.aiopsPropsDetails.event_slider_changes.sliderEndDate
        params.anomaly = true
        params.case_id = this.state.caseId

        
        if(this.props.eventPageDetails && this.props.eventPageDetails.assets) {
            let assetArray = []
            if(Array.isArray(this.props.eventPageDetails.assets)) {
                this.props.eventPageDetails.assets.forEach(item => {
                    assetArray.push(item.asset_name)
                })
            } else if(this.props.eventPageDetails.assets.asset_name) {
                assetArray.push(this.props.eventPageDetails.assets.asset_name)
            }
            params.asset_name = assetArray
        }
        params.service_name = this.props.eventPageDetails.serviceName

        let topItems = 6
        params.top = topItems

        if(params.asset_name) {
            this.props.getMonitoringMetricsByTrend(params, (promise, response) => {
                if(promise) {
                    let obj = this.props.eventMonitoringList
                    obj[this.state.assetName] = response
                    this.props.setAiopsPropsDetails('eventMonitoringList', obj)
                    // this.setState({ showMertricsSection: true, showMetricsList: true })
                }
            })
        }
    }
    
    getAiopsDailyTrendByAssets = () => {
        let params = {}
        params.alerts_case_id = this.state.caseId
        params.start_time = this.props.aiopsPropsDetails.event_slider_changes && this.props.aiopsPropsDetails.event_slider_changes.sliderStartDate
        params.end_time = this.props.aiopsPropsDetails.event_slider_changes && this.props.aiopsPropsDetails.event_slider_changes.sliderEndDate

        this.props.getAiopsDailyTrendByAssets(params, (promise, response) => {
            let alertEventTrend = response && response.results ? response.results : {}
            this.props.setAiopsPropsDetails('eventMonitroingTrendList', alertEventTrend)
        })
    }

    getMetricsDeatils = (assetId, assetName) => {
        this.setState({ selectedMetricAssetName: assetName, selectedMetricAssetId: assetId, showMetricsDetails: true },
            () => {
                this.props.setAiopsPropsDetails('showEventMetricsList', false)
            }    
        )
    }

	render() {
		return (
            <div className='row' ref={this.scrolltoTop}>
                <div className="col-sm-12 p-3">
                    {this.props.aiopsPropsDetails.showEventMetricsList ?
                        <div className="">
                            <div className="accordion" id="accordionExample">
                                {this.props.eventDetails && Object.entries(this.props.eventDetails).length && this.props.eventPageDetails && Object.entries(this.props.eventPageDetails).length ?
                                    <React.Fragment>
                                    {!Array.isArray(this.props.eventPageDetails.event_source) ?
                                        <div className={`card bg-dark border-0 mb-2`}>
                                            <div id={'heading_event'} onClick={() => this.setState({ expandCommonEventDetails: !this.state.expandCommonEventDetails })}>
                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                    <div className="text-white"><span className={`far ${this.state.expandCommonEventDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Event Details</div>
                                                    {/* <div className="text-info">Showing event details</div> */}
                                                </div>
                                            </div>
                                            <div id={'collapse_event'} className={`collapse ${this.state.expandCommonEventDetails ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Name:</p>
                                                            <p className="mb-0 text-white">{this.props.eventPageDetails.event_name ? this.props.eventPageDetails.event_name : ''}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Id:</p>
                                                            <p className="mb-0 text-white">{this.props.eventPageDetails.event_id ?this.props.eventPageDetails.event_id : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex pt-2 border-top">
                                                        <div className="py-1 w-50">
                                                            <p className="b-block mb-0">Source:</p>
                                                            <p className="mb-0 text-white">{ this.props.eventPageDetails.event_source ? (!Array.isArray(this.props.eventPageDetails.event_source) ? this.props.eventPageDetails.event_source : this.props.eventPageDetails.event_source) : ""}</p>
                                                        </div>
                                                        <div className="py-1 w-50 pl-3">
                                                            <p className="b-block mb-0">Time:</p>
                                                            <p className="mb-0 text-white">{this.props.eventPageDetails.event_time ? momentConvertionUtcToLocalTime(this.props.eventPageDetails.event_time, 'DD MMM YYYY HH:mm') : ''}</p>
                                                        </div>
                                                    </div>
                                                    {!Array.isArray(this.props.eventDetails.event_source)  ?
                                                        this.props.eventDetails.event_source === "aws.rds" ?
                                                            this.props.eventDetails.additional_details ?
                                                                <div className="d-flex pt-2 border-top">
                                                                    <div className="py-1 w-50">
                                                                        <p className="b-block mb-0">Event Code:</p>
                                                                        <p className="mb-0 text-white">{this.props.eventDetails.additional_details.event_id ? this.props.eventDetails.additional_details.event_id : ""}</p>
                                                                    </div>
                                                                    <div className="py-1 w-50 pl-3">
                                                                        <p className="b-block mb-0">Message:</p>
                                                                        <p className="mb-0 text-white">{this.props.eventDetails.additional_details.message ? this.props.eventDetails.additional_details.message : ''}</p>
                                                                    </div>
                                                                </div>
                                                            : null
                                                        : this.props.eventDetails.event_source === "aws.ec2" ?
                                                            this.props.eventDetails.additional_details ?
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                    <p className="mb-0 text-white">{value}</p>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            : null
                                                        : this.props.eventDetails.event_source === "aws.codecommit" ?
                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                            this.props.eventDetails.additional_details ?
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                    {key === "user" ?
                                                                                        <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    }
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            : null
                                                        : this.props.eventDetails.event_source === "aws.codedeploy" ?
                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                            this.props.eventDetails.additional_details ?
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                    {key === "artifacts" ?
                                                                                        <p className="mb-0 text-white">{value["location"] ? value["location"] : ""}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    }
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            : null
                                                        : this.props.eventDetails.event_source === "aws.codebuild" ?
                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                            this.props.eventDetails.additional_details ?
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                    {key === "artifacts" ?
                                                                                        <p className="mb-0 text-white">{value["location"] ? value["location"] : ""}</p>
                                                                                    : key === "commit_details" ?
                                                                                        <p className="mb-0 text-white">{value["commit_id"] ? value["commit_id"] : ""}</p>
                                                                                    : key === "user" ?
                                                                                        <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    }
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            : null
                                                        : this.props.eventDetails.event_source === "aws.codepipeline" ?
                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                            this.props.eventDetails.additional_details ?
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                    {key === "deploy_details" ?
                                                                                        <p className="mb-0 text-white">{value["deployment_id"] ? value["deployment_id"] : ""}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    }
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            : null
                                                        : this.props.eventDetails.event_source === "rds_audit_log" ?
                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                            this.props.eventDetails.additional_details ?
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                    {key === "user" ?
                                                                                        <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    }
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            : null
                                                        : this.props.eventDetails.event_source === "rds_slow_log" ?
                                                            //capitalizeTheFirstLetterOfEachWord(key.replaceAll("_", " "))
                                                            this.props.eventDetails.additional_details ?
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                    {Object.entries(this.props.eventDetails.additional_details).map(([key, value], addIdx) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                    {key === "user" ?
                                                                                        <p className="mb-0 text-white">{value["name"] ? value["name"] : ""}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    }
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            : null
                                                        : this.props.eventDetails.event_source === "config" ?
                                                            <React.Fragment>
                                                            {this.props.eventPageDetails.configuration_status ?
                                                                <div className="d-flex pt-2 border-top">
                                                                    <div className="py-1 w-50">
                                                                        <p className="b-block mb-0">Configuration Status:</p>
                                                                        <p className="mb-0 text-white">{this.props.eventPageDetails.configuration_status}</p>
                                                                    </div>
                                                                </div>
                                                            : null}
                                                            
                                                            {this.props.eventDetails.additional_details && Object.entries(this.props.eventDetails.additional_details).length && this.props.eventDetails.additional_details.changes ? 
                                                                <div className={`pt-2 border-top w-100`}>
                                                                    <p className="b-block mb-2">Changes:</p>
                                                                    {this.props.eventDetails.additional_details.changes.map(confCng => {
                                                                        return(
                                                                            <p className="mb-3 text-white"><span className="legend-circle mr-1 bg-info"></span>{confCng.description}:</p>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : null}
                                                            </React.Fragment>
                                                        : this.props.eventDetails.event_source === "checks" ?
                                                            <React.Fragment>
                                                            <div className="d-flex pt-2 border-top">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Title:</p>
                                                                    <p className="mb-0 text-white">{ this.props.eventPageDetails.title ? this.props.eventPageDetails.title : ""}</p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Detail:</p>
                                                                    <p className="mb-0 text-white">{ this.props.eventPageDetails.detail ? this.props.eventPageDetails.detail : ""}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex pt-2 border-top">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Severity:</p>
                                                                    {this.props.eventDetails.risk ?
                                                                        <small className={`mr-1 badge risk-badge-${this.props.eventDetails.risk.toLowerCase()}`}>
                                                                        {this.props.eventDetails.risk}
                                                                        </small>
                                                                    : null
                                                                    }
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Status:</p>
                                                                    {this.props.eventDetails.status ?
                                                                        <small className={`mr-1 badge ${this.props.eventDetails.status === 'pass' ? 'badge-pass' : 'badge-fail'}`}>
                                                                        {this.props.eventDetails.status}
                                                                        </small>
                                                                    : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            {this.props.eventDetails.resolution_status_info ?
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                    {Object.entries(this.props.eventDetails.resolution_status_info).map(([key, value], addIdx) => {
                                                                        return(
                                                                            <React.Fragment>
                                                                                <div className={`d-flex pt-2 border-top w-100 ${addIdx%2 ? "displayNone" : ""}`}></div>
                                                                                <div className={`py-1 w-50 mb-2 ${addIdx%2 ? "pl-3" : ""}`}>
                                                                                    <p className="b-block mb-0">{capitalizeFirstLetter(key)}:</p>
                                                                                    {key === "timestamp" ?
                                                                                        <p className="mb-0 text-white">{value ? momentConvertionUtcToLocalTime(value, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                                    :
                                                                                        <p className="mb-0 text-white">{value}</p>
                                                                                    }
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            : null}
                                                            </React.Fragment>
                                                        : null
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    : null}                                
    
                                    {this.props.eventPageDetails.event_source && !Array.isArray(this.props.eventPageDetails.event_source) && this.props.eventPageDetails.event_source === "cloudtrail" ?
                                        <React.Fragment>
                                            {this.props.eventDetails.user_identity ?
                                                <div className={`card bg-dark border-0 mb-2`}>
                                                    <div id={'heading_user'} onClick={() => this.setState({ expandUserIdentity: !this.state.expandUserIdentity })}>
                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_user'}aria-expanded="true" aria-controls={'collapse_user'}>
                                                            <div className="text-white"><span className={`far ${this.state.expandUserIdentity ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>User Details</div>
                                                            {/* <div className="text-info">Showing user details</div> */}
                                                        </div>
                                                    </div>
                                                    <div id={'collapse_user'} className={`collapse ${this.state.expandUserIdentity ? 'show' : ''}`} aria-labelledby={'heading_user'} data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            <div className="d-flex mb-2">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Name:</p>
                                                                    <p className="mb-0 text-white">{this.props.eventDetails.user_identity.name ? this.props.eventDetails.user_identity.name  : ''}</p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">Type:</p>
                                                                    <p className="mb-0 text-white">{this.props.eventDetails.user_identity.type ?this.props.eventDetails.user_identity.type : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                            
                                            {this.props.eventDetails.accessed_from ?
                                                <div className={`card bg-dark border-0 mb-2`}>
                                                    <div id={'heading_access'} onClick={() => this.setState({ expandAccessDetails: !this.state.expandAccessDetails })}>
                                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_access'}aria-expanded="true" aria-controls={'collapse_access'}>
                                                            <div className="text-white"><span className={`far ${this.state.expandAccessDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Access Details</div>
                                                            {/* <div className="text-info">Showing access details</div> */}
                                                        </div>
                                                    </div>
                                                    <div id={'collapse_access'} className={`collapse ${this.state.expandAccessDetails ? 'show' : ''}`} aria-labelledby={'heading_access'} data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            <div className="d-flex mb-2 border-bottom">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Access Mode:</p>
                                                                    <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.access_mode ? this.props.eventDetails.accessed_from.access_mode : ''}</p>
                                                                </div>
                                                                <div className="py-1 w-50 pl-3">
                                                                    <p className="b-block mb-0">ip:</p>
                                                                    <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.ip ?this.props.eventDetails.accessed_from.ip : ''}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mb-2">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">User Agent:</p>
                                                                    <p className="mb-0 text-white">{this.props.eventDetails.accessed_from.user_agent ? this.props.eventDetails.accessed_from.user_agent : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </React.Fragment>
                                    : null}
    
                                    {this.props.eventDetails.tags && this.props.eventDetails.tags.length ?
                                        <div className={`card bg-dark border-0 mb-2`}>
                                            <div id={'heading_config_tags'} onClick={() => this.setState({ expandTagDetails: !this.state.expandTagDetails })}>
                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_tags'}aria-expanded="true" aria-controls={'collapse_config_tags'}>
                                                    <div className="text-white"><span className={`far ${this.state.expandTagDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Tag Details</div>
                                                    {/* <div className="text-info">Showing tag details</div> */}
                                                </div>
                                            </div>
                                            <div id={'collapse_config_tags'} className={`collapse ${this.state.expandTagDetails ? 'show' : ''}`} aria-labelledby={'heading_config_tags'} data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <ul className='tags my-1 px-3'>
                                                        {this.props.eventDetails.tags.map((tag, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <span className='tag f14'>{tag.key+': '+tag.value}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    {/* <div className={`d-flex flex-wrap`}>
                                                        {this.props.eventDetails.tags.map(item => {
                                                            return (
                                                                <small className={`mr-1 mb-1 badge badge-light`}>{item.key+' : '+item.value}</small> 
                                                            )
                                                        })}
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    : null}
    
                                    {this.props.eventPageDetails.assets && this.props.eventPageDetails.assets.length ?
                                        <div className={`card bg-dark border-0 mb-2`}>
                                            <div id={'heading_config_assets'} onClick={() => this.setState({ expandPropsAssetDetails: !this.state.expandPropsAssetDetails })}>
                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                    <div className="text-white"><span className={`far ${this.state.expandPropsAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                    {/* <div className="text-info">Showing asset details</div> */}
                                                </div>
                                            </div>
                                            <div id={'collapse_config_assets'} className={`collapse ${this.state.expandPropsAssetDetails ? 'show' : ''}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                {this.props.eventPageDetails.assets.map(item => {
                                                    return(
                                                    <div className="card-body m-2 pt-2 rounded counts_threads">
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Name:</p>
                                                                <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Id:</p>
                                                                <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Resource:</p>
                                                                <p className="mb-0 text-white">
                                                                    {this.props.eventPageDetails.provider ? this.props.eventPageDetails.provider.toUpperCase() : ""}
                                                                    {this.props.eventPageDetails.account_id ? (" : ")+this.props.eventPageDetails.account_id : ""}
                                                                    {this.props.eventPageDetails.region ? (" : ")+this.props.eventPageDetails.region : ""}
                                                                </p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Services:</p>
                                                                <p className="mb-0 text-white">
                                                                    {item.resource_type ? item.resource_type : ''}
                                                                    {item.service_name ? (" : ")+item.service_name : ''}
                                                                </p>
                                                            </div>
                                                        </div>                                                        
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">ARN:</p>
                                                                <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : ''}</p>
                                                            </div>
                                                        </div>
                                                        {item.category ? 
                                                            <div className="d-flex mb-2 border-bottom">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">Category:</p>
                                                                    <div className={`d-flex flex-wrap`}>
                                                                        {item.category.map(item => {
                                                                            return (
                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null}
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    : this.props.eventDetails.assets && this.props.eventDetails.assets.length ?
                                        <div className={`card bg-dark border-0 mb-2`}>
                                            <div id={'heading_config_assets'} onClick={() => this.setState({ expandEventAssetDetails: !this.state.expandEventAssetDetails })}>
                                                <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                    <div className="text-white"><span className={`far ${this.state.expandEventAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                    {/* <div className="text-info">Showing asset details</div> */}
                                                </div>
                                            </div>                                        
                                            <div id={'collapse_config_assets'} className={`collapse ${this.state.expandEventAssetDetails ? 'show' : ''}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                {this.props.eventDetails.assets.map(item => {
                                                    return(
                                                    <div className="card-body m-2 pt-2 rounded counts_threads">
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Name:</p>
                                                                <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Id:</p>
                                                                <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-2">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Resource:</p>
                                                                <p className="mb-0 text-white">
                                                                    {this.props.eventPageDetails.provider ? this.props.eventPageDetails.provider.toUpperCase() : ""}
                                                                    {this.props.eventPageDetails.account_id ? (" : ")+this.props.eventPageDetails.account_id : ""}
                                                                    {this.props.eventPageDetails.region ? (" : ")+this.props.eventPageDetails.region : ""}
                                                                </p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Services:</p>
                                                                <p className="mb-0 text-white">
                                                                    {item.resource_type ? item.resource_type : ''}
                                                                    {item.service_name ? (" : ")+item.service_name : ''}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-2 border-bottom">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">ARN:</p>
                                                                <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : ''}</p>
                                                            </div>
                                                        </div>
                                                        {item.category ? 
                                                            <div className="d-flex mb-2 border-bottom">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">Category:</p>
                                                                    <div className={`d-flex flex-wrap`}>
                                                                        {item.category.map(item => {
                                                                            return (
                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null}
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    : null}
                                    </React.Fragment>
                                : this.props.eventDetails && !Object.entries(this.props.eventDetails).length ? 
                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                        <p>There are no data for the selected event. Please try adjusting your selection.</p>
                                    </div>
                                :  !this.props.eventDetails && !Object.entries(this.props.eventDetails).length ? 
                                    <div className='col-md-12 d-flex justify-content-center m-4'>
                                        <p>Please select the event to get the event detail.</p>
                                    </div>
                                : null}
                            </div>

                            <div className="mt-3">
                                {this.state.showMetricsList || (this.props.eventPageDetails && this.props.eventMonitoringList[this.props.eventPageDetails.assetName]) ?
                                    <EventMetricList
                                        from={'alertPage'}
                                        eventType={''}
                                        caseId={this.props.caseId}
                                        getMetricsDeatils={(assetId, assetName) => this.getMetricsDeatils(assetId, assetName)}
                                        textColor={'text-white'}
                                        backGroudColor={'bg-dark'}
                                        heading={'bg-muted text-info'}
                                    />
                                : (!this.props.eventPageDetails || !Object.entries(this.props.eventPageDetails).length) ?
                                    null
                                : !this.state.showMetricsList ?
                                    <div className='d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='white' size='lg' />
                                    </div>
                                : null}
                            </div>
                        </div>
                    :
                        <EventMetricDetails 
                            selectedMetricAssetName = {this.state.selectedMetricAssetName ? this.state.selectedMetricAssetName : ''}
                            selectedMetricAssetId = {this.state.selectedMetricAssetId ? this.state.selectedMetricAssetId : ''}
                            showMetricsDetails={(bool) => 
                                this.setState({ showMetricsDetails: bool },
                                    () => {
                                        this.props.setAiopsPropsDetails('showEventMetricsList', true)
                                        this.props.setAiopsPropsDetails('sliderDetails', {})
                                    }
                                )
                            }
                            backGroudColor={'bg-muted'}
                            heading={'bg-black text-info'}
                            textColor={'text-white'}
                        />
                    }
                </div>
            </div>
		)
	}
}
/**
 * Type of the props used in the component
 */
EventsRightSection.propTypes = {
    getMonitoringMetricsByTrend: PropTypes.func,
    getAiopsDailyTrendByAssets: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {	
    // console.log('right state', state)
	return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        aiopsPropsDetails: state.aiops.aiopsPropsDetails ? state.aiops.aiopsPropsDetails : {},
        eventPageDetails: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.event_page_details ? state.aiops.aiopsPropsDetails.event_page_details : {},

        assetsList: state.aiops.caseDetails.results && state.aiops.caseDetails.results.assets ? state.aiops.caseDetails.results.assets : [],
        eventDetails: state.aiops.eventDetails,

        eventMonitoringList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.eventMonitoringList ? state.aiops.aiopsPropsDetails.eventMonitoringList : {},
        eventMonitroingTrendList: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.eventMonitroingTrendList ? state.aiops.aiopsPropsDetails.eventMonitroingTrendList : {},
    }
}

export default connect(mapStateToProps, {
    getMonitoringMetricsByTrend,
    getAiopsDailyTrendByAssets,
    setAiopsPropsDetails
})(withRouter(EventsRightSection))