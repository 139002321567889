/*************************************************
 * Tvastar
 * @exports
 * @file ApesMutliyAxis  .js
 * @author Prakash // on 16/07/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, subDays, addHours, momentConvertionUtcToLocalTime, convertBytes, convertSeconds, countFormater, convertBits } from '../../../utils/utility'
import Chart from 'react-apexcharts'
import { getSreDetails } from '../../../actions/aiops/DiagnosticsAction'

import { getMonitoringMetricsByTrend } from '../../../actions/aiops/MetricsAction'
import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class MetricsSection extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: true,
            sreMetricsDetails: [],
            show_latency: true,
            show_error_rate: true,
            selectedAnnotationTypes: [],
            showAnnotation: true,
        }
    }
    
    componentDidMount = () => {
        if(this.props.metricsType !== 'SRE Observations') {
            if(!this.props.assetDetails || !this.props.assetDetails.resource_type || this.props.assetDetails.resource_type !== 'EC2') {
                if(Object.entries(this.props.rcSreMertrics).length) {
                    if(this.props.rcSreMertrics.hasOwnProperty(this.props.assetDetails.asset_name)) {
                        this.setState({ 
                            sreMetricsDetails: this.props.rcSreMertrics[this.props.assetDetails.asset_name],
                            showLoading: false
                        },
                            () => {
                                this.formSreMetricsLatencyGraph()
                                this.formSreMetricsRateGraph()
                            }
                        )
                    } else {
                        this.getSreDetails()
                    }
                } else {
                    this.getSreDetails()
                }
            }
        }
        if(Object.entries(this.props.rcMonitMertrics).length) {
            console.log('this.props.rcMonitMertrics', this.props.rcMonitMertrics)
            if(this.props.rcMonitMertrics.hasOwnProperty(this.props.assetDetails.asset_name)) {
                this.setState({ metricsByTrend: this.props.rcMonitMertrics[this.props.assetDetails.asset_name], showLoading: false },
                    () => {
                        this.metricsListGraph()
                    }
                )
            } else {
                this.getMonitoringMetricsByTrend()
            }
        } else {
            this.getMonitoringMetricsByTrend()
        }
    }

    getSreDetails = () => {
        let caseStartTime = this.props.caseDetails.created_at
        let caseEndTime = this.props.caseDetails.last_event
        if(caseEndTime < caseStartTime) {
            caseEndTime = caseStartTime
        }
        let updatedCaseStartTime = subDays(caseStartTime, 3)
        
        let params = {}
        params.case_id = this.props.caseDetails.case_id
        // params.start_time = "2021-07-18 06:30:50"
        // params.end_time = "2021-07-21 07:30:50"
        params.start_time = updatedCaseStartTime
        params.end_time = caseEndTime
        // params.asset_id = 'e8fefc37104642dfdce49f0a32b33e38'
        params.asset_id = this.props.assetDetails.asset_id

        this.props.getSreDetails(params, (promise, response) => {
            if(promise) {
                this.setState({ 
                    sreMetricsDetails: response,
                    showLoading: false
                },
                    () => {
                        this.formSreMetricsLatencyGraph()
                        this.formSreMetricsRateGraph()
                        let obj = this.props.rcSreMertrics
                        obj[this.props.assetDetails.asset_name] = response
                        this.props.setAiopsPropsDetails('rcSreMertrics', obj)
                    }
                )
            } else {
                this.setState({ sreMetricsDetails: [] })
            }
        })
    }

    formSreMetricsLatencyGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) {
            let filteredSreDetails = this.state.sreMetricsDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates
                
                let yDetails = []
                let series =[]
                let colorInc = 0
                graphData.latency &&  Object.entries(graphData.latency).forEach(([key, item], j) => {let yRow = {}
                    if(item.values && item.values.length) {
                        if(!colorInc) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        return value.toFixed(2)
                                    },
                                }
                            }
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        yDetails.push(yRow)
                        
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit && item.unit !== '') {
                                val = this.getGrapUnitValue(val, item.unit)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)

                        colorInc++;
                    }
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    xaxisAnnotation = [{
                        x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: '',
                        }
                    }
                    ]
                }
                    
                if(this.state.selectedAnnotationTypes && this.state.selectedAnnotationTypes.length) {
                    let borderColor = ''
                    let background = ''
                    let color = ''
                    let text = ''
                    let location = ''
                    this.state.selectedAnnotationTypes.forEach(item => {
                        text = item.value
                        if(item.value === 'C' || item.value === 'E') {
                            borderColor = '#24A597'
                            background = '#24A597'
                            color = '#fff'
                            location = new Date(momentConvertionUtcToLocalTime(graphLabels[180], 'YYYY-MM-DD HH:mm:ss')).getTime()                        

                            let dataRow = {
                                x: location,
                                strokeDashArray: 0,
                                borderColor: borderColor,
                                opacity: 1,
                                label: {
                                    borderColor: borderColor,
                                    orientation: 'horizontal',
                                    text: text,
                                    style: {
                                        color: color,
                                        background: background,
                                        fontSize: '10px',
                                        // width: '10px',
                                    },
                                }
                            }
                            xaxisAnnotation.push(dataRow)
                        }
                    })
                }
                
                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        type: 'line',
                        // id: 'chart_latency_'+index,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },                        
                        sparkline: {
                            enabled: false
                        },
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 3, 4],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    },
                    xaxis: {
                        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: this.props.xaxis,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        }
                    },
                    yaxis: yDetails,
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    console.log('w.globals.initialSeries[index].data[dataPointIndex]', w.globals.initialSeries[index].data[dataPointIndex])
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },                    
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                        // horizontalAlign: 'left',
                        // offsetX: 40
                    },
                    annotations: annotations
                }

                this.setState({ ['metrics_series_'+index]: series, ['metrics_options_'+index]: options })
            })
        }
    }

    formSreMetricsRateGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) {
            let filteredSreDetails = this.state.sreMetricsDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let series = []
                
                let  strokeWidth = []
                let x = 0
                
                let yDetails = []
                let yRow = {}
                Object.entries(graphData.error_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    if(item.values && item.values.length) {
                        if(!x) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                },
                                formatter: (value) => { 
                                    // console.log(value)
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value)
                                    } else {
                                        return value.toFixed(2)
                                    }
                                },
                            }
                            
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'column'
                        
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(item.values[i], item.unit)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[i])
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                    x++;
                })
                
                Object.entries(graphData.request_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    if(item.values && item.values.length) {
                        yRow = {
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            tickAmount: 2,
                            min: 0,
                            labels: {
                                offsetX: -10,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                            }
                        }
                        yDetails.push(yRow)
                        
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []

                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(item.values[i], item.unit)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[i])
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                    x++;
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    xaxisAnnotation = [{
                        x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        strokeDashArray: 0,
                        borderColor: '#FF6F00',
                        label: {
                            borderColor: '#FF6F00',
                            style: {
                                // fontSize: '12px',
                                color: '#fff',
                                background: '#FF6F00'
                            },
                            orientation: 'horizontal',
                            text: '',
                        }
                    }
                    ]
                }
                    
                if(this.state.selectedAnnotationTypes && this.state.selectedAnnotationTypes.length) {
                    let borderColor = ''
                    let background = ''
                    let color = ''
                    let text = ''
                    let location = ''
                    this.state.selectedAnnotationTypes.forEach(item => {
                        text = item.value
                        if(item.value === 'C' || item.value === 'E') {
                            borderColor = '#24A597'
                            background = '#24A597'
                            color = '#fff'
                            location = new Date(momentConvertionUtcToLocalTime(graphLabels[180], 'YYYY-MM-DD HH:mm:ss')).getTime()                        

                            let dataRow = {
                                x: location,
                                strokeDashArray: 0,
                                borderColor: borderColor,
                                opacity: 1,
                                label: {
                                    borderColor: borderColor,
                                    orientation: 'horizontal',
                                    text: text,
                                    style: {
                                        color: color,
                                        background: background,
                                        fontSize: '10px',
                                        // width: '10px',
                                    },
                                }
                            }
                            xaxisAnnotation.push(dataRow)
                        }
                    })
                }
                
                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        // id: 'chart_rate_'+index,
                        // redrawOnParentResize: true,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        },
                        type: "line",
                        animations: {
                            enabled: false
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        width: strokeWidth,
                        curve: 'smooth',
                    },
                    xaxis: {
                        show: this.props.xaxis,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: this.props.xaxis,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        },
                    },
                    yaxis: yDetails,
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    annotations: annotations
                }

                this.setState({ ['metrics_rate_series_'+index]: series, ['metrics_rate_options_'+index]: options })
            })

        }
    }

    getMonitoringMetricsByTrend = () => {
        let provider = this.props.caseDetails && this.props.caseDetails.provider ? (Array.isArray(this.props.caseDetails.provider) ? this.props.caseDetails.provider[0] : this.props.caseDetails.provider) : 'aws'
        
        let caseStartTime = this.props.caseDetails.created_at
        let caseEndTime = this.props.caseDetails.last_event
        if(caseEndTime < caseStartTime) {
            caseEndTime = caseStartTime
        }
        let updatedCaseStartTime = subDays(caseStartTime, 3)
        
        let params = {}
        params.provider = provider
        params.anomaly = true

        // params.asset_name = ["i-08739fdc412d73a4c"]
        // params.case_id = "1b72b9cf-b224-4fb0-8b2c-dd517dc0f482"
        params.asset_name = [this.props.assetDetails.asset_name]
        params.case_id = this.props.caseDetails.case_id

        let topMertrics = 3
        if(this.props.metricsType === 'SRE Observations' || (this.props.assetDetails && this.props.assetDetails.resource_type && this.props.assetDetails.resource_type === 'EC2')) {
            topMertrics = 6
        }
        params.top = topMertrics
        // params.service_name = "ec2"
        params.service_name = this.props.assetDetails.service_name
        // params.end_time = "2021-05-26 12:17:59"
        // params.start_time = "2021-05-23 11:17:59"
        params.start_time = updatedCaseStartTime
        params.end_time = caseEndTime
        
        
        this.props.getMonitoringMetricsByTrend(params, (promise, result) => {
            if(promise) {
                this.setState({ metricsByTrend: result, showLoading: false },
                    () => {
                        let obj = this.props.rcMonitMertrics
                        obj[this.props.assetDetails.asset_name] = result
                        this.props.setAiopsPropsDetails('rcMonitMertrics', obj)

                        this.metricsListGraph()
                    }
                )
            }
        })
    }

    metricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates
                            
                
                Object.entries(graphData).forEach(([key, item]) => {
                    let array = []
                    let graphLabels = []
                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit)
                        }
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]

                    // let markerSize = 2
                    // if(item.values.length >= 30) {
                    //     markerSize = 0
                    // }

                    let xaxisAnnotation = []
                    if(this.state.showAnnotation) {
                        xaxisAnnotation = [{
                            x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                            strokeDashArray: 0,
                            borderColor: '#FF6F00',
                            label: {
                                borderColor: '#FF6F00',
                                style: {
                                    // fontSize: '12px',
                                    color: '#fff',
                                    background: '#FF6F00'
                                },
                                orientation: 'horizontal',
                                text: '',
                            }
                        }
                        ]
                    }
                        
                    if(this.state.selectedAnnotationTypes && this.state.selectedAnnotationTypes.length) {
                        let borderColor = ''
                        let background = ''
                        let color = ''
                        let text = ''
                        let location = ''
                        this.state.selectedAnnotationTypes.forEach(item => {
                            text = item.value
                            if(item.value === 'C' || item.value === 'E') {
                                borderColor = '#24A597'
                                background = '#24A597'
                                color = '#fff'
                                location = new Date(momentConvertionUtcToLocalTime(graphLabels[180], 'YYYY-MM-DD HH:mm:ss')).getTime()                        

                                let dataRow = {
                                    x: location,
                                    strokeDashArray: 0,
                                    borderColor: borderColor,
                                    opacity: 1,
                                    label: {
                                        borderColor: borderColor,
                                        orientation: 'horizontal',
                                        text: text,
                                        style: {
                                            color: color,
                                            background: background,
                                            fontSize: '10px',
                                            // width: '10px',
                                        },
                                    }
                                }
                                xaxisAnnotation.push(dataRow)
                            }
                        })
                    }
                    
                    let annotations = {
                        xaxis: xaxisAnnotation
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'area',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                // shadeIntensity: 1,
                                // opacityFrom: 0.7,
                                // opacityTo: 0.9,
                                // stops: [0, 90, 100]
                                gradientToColors: ['#775BA2'],
                                shadeIntensity: 0,
                                opacityFrom: .3,
                                opacityTo: 1,
                                stops: [30, 90]
                            }
                        },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },                        
                        annotations: annotations,
                    }
                    this.setState({ [key+'_'+graph.asset_id]: series, [key+'_options_'+graph.asset_id]: options })
                })
            })
        }
    }
    
    getMultiSelectedCount = (type, array) => {
        if(array) {
            return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
        } else {
            return 'All'
        }
    }

    handleMultiSelectChange = (childKey, arrayValue) => {
        // let value = arrayValue.map(item => item.value)
        // let label = arrayValue.map(item => item.label)

        if(childKey === 'annotationType') {
            this.setState({ selectedAnnotationTypes: arrayValue, showMeticsChart: false },
                () => {
                    this.setState({ showMeticsChart: true },
                        () => { 
                            this.metricsListGraph()
                            this.formSreMetricsLatencyGraph()
                            this.formSreMetricsRateGraph()
                        }
                    )
                }    
            )
        }
    }
	
	handleClickOutside(event) {
        if (this.annotationTypeRef && !this.annotationTypeRef.current.contains(event.target)) {
            this.setState({ isannotationTypeOpen: false })
        } else {
            this.setState({ isannotationTypeOpen: true })
        }
    }

    getGrapUnitValue = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    render() {
        return (
            !this.state.showLoading ?
                <div class={`bg-dark rounded p-3`} onClick={ (event) => { this.handleClickOutside(event) } }>
                    <div class="d-flex justify-content-between">
                        <div className="">
                            <p class="f16 text-white mb-1">Metrics</p>
                            {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                <p class="f12 text-white mb-0">Key Metrics for {this.props.assetDetails.resource_type ? +' '+this.props.assetDetails.resource_type : ''} {this.props.assetDetails.asset_name ? +' '+this.props.assetDetails.asset_name : ''}</p>
                            : null}
                        </div>
                        <div className="d-flex">
                            <div className="d-flex">
                                <div className="d-flex mr-2 align-self-center">
                                    <p className="indicatoreBox bg-orange mb-0 mr-2"></p>
                                    <p className="f12 mb-0">Case Time</p>
                                </div>
                                <div className="d-flex mr-2 align-self-center displayNone">
                                    <p className="indicatoreBox bg-info mb-0 mr-2"></p>
                                    <p className="f12 mb-0">Alert</p>
                                </div>
                            </div>
                            <span className={`mr-2 align-self-center f12 badge badge-info mr-3`}>3D</span>                        
                            <div className={`multiSelection-fullWidth z999 removeDropdownSearchBar align-self-center position-relative`} ref={this.annotationTypeRef}>
                                <ReactMultiSelectCheckboxes						
                                    placeholderButtonLabel="All"
                                    getDropdownButtonLabel={() => this.getMultiSelectedCount('nodeTypes', this.state.selectedAnnotationTypes)}
                                    menuIsOpen ={this.state.isannotationTypeOpen}
                                    value={this.state.selectedAnnotationTypes.map(item => ({
                                        value: item.value,
                                        // label: <div className="d-flex"><span className="badge badge-secondary">{item.prefix}</span> <span className="badge badge-secondary">{item.label}</span></div>
                                    }))}
                                    onChange={arr => {
                                    this.handleMultiSelectChange('annotationType', arr ? arr : []) }}
                                    options={this.props.annotaionOptions && this.props.annotaionOptions.map(itm => ({
                                        value: itm.value,
                                        label: <span><span className={`badge-square font-weight-bold f11 bg-dark-theme-${(itm.prefix === 'C' || itm.prefix === 'E') ? 'green' : itm.prefix === 'CE' ? 'orange' : itm.prefix === 'CS' ? 'orange' : 'blue'}`}>{itm.prefix}</span>{itm.label}</span>,
                                    }))}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid mt-2">
                    {this.props.metricsType === 'SRE Observations' || (this.props.assetDetails && this.props.assetDetails.resource_type && this.props.assetDetails.resource_type === 'EC2') ?
                        <div className="">
                            <div class="row">
                                {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                    this.state.metricsByTrend.map((item, index) => {
                                        return(
                                        Object.keys(item.data).map((key, j) => {
                                            return (
                                                <div class={`mb-2 col-sm-6 ${j % 2 === 0 ? 'px-0' : ''}`}>
                                                    <div class="p-3 border rounded">
                                                        <p class="text-white f16 p-1 mb-0">{key}</p>
                                                        {/* <div class="rounded text-info bg-muted px-2 py-1 f16 d-table">{key}</div> */}
                                                        {/* <p class="f12 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                                        
                                                        {this.state[key+'_options_'+item.asset_id] && Object.keys(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="area" height={115} width={'100%'}/>
                                                            </div>
                                                        : null}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        )
                                    })
                                : 
                                    <div className='d-flex justify-content-center m-4'>
                                        No Metrics for the this criteria, Please adjust your filter to get the metric details.
                                    </div>
                                }
                            </div>
                        </div>
                    : this.props.metricsType !== 'SRE Observations' ?
                        (this.state.metricsByTrend && this.state.metricsByTrend.length) || (this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) ?
                            <div class="row">
                                <div class="mb-2 col-sm-6 px-0">
                                    {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                        this.state.metricsByTrend.map((item, index) => {
                                            return(
                                            Object.keys(item.data).map(key => {
                                                return (
                                                <div class="p-3 border rounded mb-3">
                                                    <p class="text-white f16 p-1 mb-0">{key}</p>
                                                    {/* <div class="rounded text-info bg-muted px-2 py-1 f16 d-table">{key}</div> */}
                                                    {/* <p class="f12 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                                    
                                                    {this.state[key+'_options_'+item.asset_id] && Object.keys(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                                        <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                            <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="area" height={115} width={'100%'}/>
                                                        </div>
                                                    : null}
                                                </div>
                                                )
                                            })
                                            )
                                        })
                                    : null}
                                </div>
                                <div class="mb-2 col-sm-6">
                                    {this.state.sreMetricsDetails && this.state.sreMetricsDetails.length ? 
                                        this.state.sreMetricsDetails.map((res, index) => {
                                            return(
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="mb-3 col-12 px-0">
                                                        <div class="p-3 border rounded">
                                                            <p class="f16 text-white m-0 p-1">Latency</p>
                                                            {this.state['metrics_series_'+index] ?
                                                                <div className={`${this.props.className}`}>
                                                                    <Chart options={this.state['metrics_options_'+index]} series={this.state['metrics_series_'+index]} type="line" height={this.props.height} />
                                                                </div>
                                                            : null}    
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="mb-3 col-12 px-0">
                                                        <div class="p-3 border rounded">
                                                            <p class="f16 text-white m-0 p-1">API Error/Request Rate</p>
                                                            {this.state['metrics_rate_series_'+index] ?
                                                                <div className={`${this.props.className}`}>
                                                                    <Chart options={this.state['metrics_rate_options_'+index]} series={this.state['metrics_rate_series_'+index]} type="line" height={this.props.height} />
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                No Metrics for the this criteria, Please adjust your filter to get the metric details.
                            </div>
                    : null}
                </div>
                </div>
            : 
                <div className={`bg-dark rounded p-3`}>
                    <p className={`mb-2 text-white`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                </div>
        )
    }
}

/**
* Type of the props used in the component
*/
MetricsSection.propTypes = {
    getSreDetails: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    setAiopsPropsDetails: PropTypes.func
}   

const mapStateToProps = state => {
    console.log('-------------',state)
    return {
        caseDetails: state.aiops.caseDetails.results && state.aiops.caseDetails.results.case_details ? state.aiops.caseDetails.results.case_details[0] : {},
        rcMonitMertrics: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.rcMonitMertrics ? state.aiops.aiopsPropsDetails.rcMonitMertrics : {},
        rcSreMertrics: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.rcSreMertrics ? state.aiops.aiopsPropsDetails.rcSreMertrics : {},
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    getMonitoringMetricsByTrend,
    setAiopsPropsDetails
})(withRouter(MetricsSection))