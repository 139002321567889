/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file Accounts.js
 * @author Prakash // on 13/10/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Spinner } from 'reactstrap'
import { 
    listCustomerAccountInfo,
    deleteCustomerAccountInfo
} from '../../../../actions/dlp/CustomerAccountAction'

import { momentConvertionUtcToLocalTime } from '../../../../utils/utility'

import Search from '../../../common/SearchComponent'

import { store as CommonNotification } from 'react-notifications-component';

import ResizeableTable from '../../../designComponents/Table/ResizeableTable'

class Accounts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAccountsInfoLoading: true
        }
    }
    
    componentDidMount = () => {	
        if(!this.props.dlpCustomerAccountInfoList || !this.props.dlpCustomerAccountInfoList.length) {
            this.listCustomerAccountInfo()
        } else {
            this.setState({ showAccountsInfoLoading: false })
        }
    }

    listCustomerAccountInfo = () => {
        this.setState({ showAccountsInfoLoading: true }, 
            () => { 
                let params = {}
                this.props.listCustomerAccountInfo(params, (promise, response) => {
                    this.setState({ showAccountsInfoLoading: false })
                })
            }
        )
    }

    deleteConfirmation = (doc_id, customer_name) => {
        if (window.confirm('Are you sure, you want to delete the account "'+customer_name+'" ?')) {
            this.setState({ showDeleteLoading: true },
                () => this.deleteCustomerAccountInfo(doc_id)
            )
        }
    }

    deleteCustomerAccountInfo = (doc_id) => {
        let params = {}
        params.doc_id = [doc_id]

        this.props.deleteCustomerAccountInfo(params, (promise, response) => {
            if(promise) {
                this.setState({ showDeleteLoading: false },
                    () => this.listCustomerAccountInfo()
                )
                let messageType = 'danger'		
                let message = 'Watermark deleted'
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                })
            }
        })
    }

    render() {
        return(
            !this.state.showAccountsInfoLoading ?
                <React.Fragment>
                    <div className="row"> 
                        <div className="col-sm-12">
                            <h6 className="font-weight-normal">Accounts</h6>
                            <p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <select className="custom-select w-auto mr-3">
                                <option selected>Filter</option>
                                {/* <option value="1">InActive</option>
                                <option value="2">Active</option> */}
                            </select>
                            {/* <select className="custom-select w-auto">
                                <option selected>Provider</option>
                                <option value="aws">AWS</option>
                                <option value="gcp">GCP</option>
                                <option value="azure">AZURE</option>
                            </select> */}
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end'>
                            {this.props.dlpCustomerAccountInfoList && this.props.dlpCustomerAccountInfoList.length ?
                                <div className="form-group mb-0">
                                    <Search
                                        data={this.props.dlpCustomerAccountInfoList}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-white'}
                                        searchClassName={'textboxBorder'}
                                        searchIconColor={'text-dark'}
                                        className={"bg-transparent textboxBorder"}
                                        filteredData={(filteredArray) => {
                                            this.setState({ filteredList: _.orderBy(filteredArray, ['date'], ['desc']) })
                                        }}
                                    />
                                </div>
                            : null}
                            <span className='btn btn-primary cursorPointer text-center ml-2 mb-2' onClick={() => this.props.onClickAction({}, 'create')}>Add Account</span>
                        </div>
                    </div>
                    {this.props.dlpCustomerAccountInfoList ?
                        <ResizeableTable
                            columns={[
                                {
                                    Header: 'Date',
                                    accessor: d => d.modified_at ? d.modified_at : d.created_at,
                                    Cell: cellInfo => (<span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm')  : '')}</span>)
                                },
                                {
                                    Header: 'Name',
                                    accessor: 'customer_name',
                                    width: 200	
                                },
                                // {
                                //     Header: 'Provider',
                                //     // accessor: 'data_association',
                                //     Cell: cellInfo => (<span>{cellInfo.row.original.provider && cellInfo.row.original.provider !== '' ? capitalizeFirstLetter(cellInfo.row.original.provider) : ''}</span>)
                                // },
                                // {
                                //     Header: 'Accounts',
                                //     // accessor: 'relationship',
                                //     Cell: cellInfo => (
                                //         cellInfo.row.original.accounts && cellInfo.row.original.accounts.map(item => {
                                //             return(
                                //             item.account_id+' : '+item.canonical_id
                                //             )
                                //         })
                                //     ),
                                //     width: 350
                                // },
                                {
                                    Header: 'Relationship',
                                    // accessor: 'sub_relationship',
                                    Cell: cellInfo => (
                                        cellInfo.row.original.relationship+(cellInfo.row.original.sub_relationship ? ' : '+cellInfo.row.original.sub_relationship : '')
                                    ),
                                    width: 300
                                },
                                {
                                    Header: 'Description',
                                    accessor: 'account_text',
                                    width: 500	
                                },
                                {
                                    Header: 'Action',
                                    Cell: cellInfo => (
                                        <div className="d-flex justify-content-between align-self-center">
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                <i className="fal fa-book-open" onClick={() => this.props.onClickAction(cellInfo.row.original, 'view')}></i>
                                            </p>
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                <i className="fal fa-user-edit" onClick={() => this.props.onClickAction(cellInfo.row.original, 'edit') }></i>
                                            </p>
                                            <p className="text-dark m-0 p-0 cursorPointer">
                                                {this.state.showDeleteLoading && cellInfo.row.original.doc_id === this.state.selectedWatermarkId ? 
                                                    <Spinner color='dark' size='sm' />
                                                :
                                                    <i className="fal fa-trash" onClick={() => this.setState({ selectedWatermarkId: cellInfo.row.original.doc_id }, () => this.deleteConfirmation(cellInfo.row.original.doc_id, cellInfo.row.original.customer_name))}></i>
                                                }
                                            </p>
                                    </div>
                                ),
                                width:120
                            }
                        ]}
                        data={this.state.filteredList ? this.state.filteredList : this.props.dlpCustomerAccountInfoList}
                        perPage={10}
                        dashboard={this.state.filteredList ? this.state.filteredList.length : this.props.dlpCustomerAccountInfoList.length}
                        sortByColumn={'Date'}
                        tooltipFor={[3]}
                        onClickRow={tableRow => {}}
                        tableSize={'table-striped'}
                    />
                    :
                        <div className='d-flex justify-content-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                </React.Fragment>
            : this.state.showAccountsInfoLoading ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner color='dark' size='lg' />
                </div>
            :
                <div className='d-flex justify-content-center m-4'>
                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                </div>
        )
    }
}
    

/**
 * Type of the props used in the component
 */
Accounts.propTypes = {
	listCustomerAccountInfo: PropTypes.func,
	deleteCustomerAccountInfo: PropTypes.func
}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
 const mapStateToProps = state => {
    return {
		dlpCustomerAccountInfoList: state.dlp.dlpCustomerAccountInfoList
	}
}
export default connect(mapStateToProps, {
	listCustomerAccountInfo,
	deleteCustomerAccountInfo
})(withRouter(Accounts))