/*************************************************
 * Tvastar
 * @exports
 * @file AddEdit.js
 * @author Prakash // on 11/10/2021
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from '../../../../utils/utility'

import { 
	listSecurityPolicies,
	listAthenaResources,
	insertSecurityPolicies,
	updateSecurityPolicies,
} from '../../../../actions/dlp/PoliciesAction'

import { Spinner } from 'reactstrap'
import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { store as CommonNotification } from 'react-notifications-component';

class AddEdit extends Component {
	hierarichalBucketBarChartRef = React.createRef()
	constructor(props) {
		super(props)
		this.props = props;
		this.state = {
			showLoading: false,
			selectedUsers: ['ALL'],
			yesNoOptions: [{value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}],
			athenaArray: [0],
			sources: ['s3', 'athena']
		}
	}

	componentDidMount = () => {
		if(this.props.pageType === 'edit' || this.props.pageType === 'view') {
			let policyDetails = this.props.policyDetails
			let name = policyDetails.name
			let description = policyDetails.description
			let selectedEntities = policyDetails.entities
			let selected_tags = policyDetails.tags ? policyDetails.tags : []
			let selectedSource = ''
			let bucket_name = ''
			let bucket_prefix_name = ''
			if(policyDetails.data_association) {
				let split = policyDetails.data_association[0].split(':')
				selectedSource = split[0] ? split[0] : ''
				if(selectedSource === 's3') {
					bucket_name = split[1] ? split[1] : ''
					bucket_prefix_name = split[2] ? split[2] : ''
				}
			}

			let athenaArray = []
			if(selectedSource === 'athena') {
				if(policyDetails.athena_data && policyDetails.athena_data.length) {
					this.onChangeSource(selectedSource)
					policyDetails.athena_data.forEach((item, i) => {
						athenaArray.push(i)
						this.setState({ 
							['catalog_name_'+i]: item.catalog_name ? item.catalog_name : '', 
							['database_name_'+i]: item.database_name ? item.database_name : '', 
							['table_'+i]: item.table ? item.table : [],
							['loadingDatabase_'+item]: item.catalog_name ? true : false,
							['loadingTables_'+i]: item.database_name ? true : false, 
						},
							() => {
								if(this.state['catalog_name_'+i] !== '') {
									this.getDatabaseList(i)
								}
								if(this.state['database_name_'+i] !== '') {
									this.getTables(i)
								}
							}
						)
					})
				} else {
					athenaArray = [0]
				}
			}

			let selectedUsers = ['ALL']
			if(policyDetails.iam_association) {
				if(policyDetails.iam_association[0] !== 'everyone'){
					selectedUsers = policyDetails.iam_association
				}
			}

			this.setState({ name, description, selected_tags, selectedEntities, selectedSource, bucket_name, bucket_prefix_name, selectedUsers  })
		}
	}		

    listSecurityPolicies = () => {
		let params = {}
		this.props.listSecurityPolicies(params)
    }

	handleMultiSelectChange = (field, choosen) => {
		if(field === 'selectedUsers') {
			let value = choosen.map(item => item.value)
			this.setState({ [field]: value })
		} else {
			let value = choosen.map(item => item.value)
			this.setState({ [field]: value })
		}
	}

	removeSelectedOption = (field, value) => {
		let filteredReslt = this.state[field]
		if(field === 'selectedUsers' || field === 'selected_tags') {
        	filteredReslt = this.state[field].filter(e => e !== value)
		}
		
		this.setState({ [field]: filteredReslt })

	}

	actionPorcess = () => {
		let hasError = false

		let nameErrorMessage = ''
		if(!this.state.name || this.state.name === '') {
			hasError = true
			nameErrorMessage = 'Please enter policy name'
		} else {
            //check policy name already exist
            if(this.props.pageType === 'create') {
                let checkNameExist = this.props.securityPoliciesList.filter(arr => arr.name === this.state.name)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    nameErrorMessage = 'Policy name already exist'
                }
            } else if(this.props.pageType === 'edit') {
                let checkNameExist = this.props.securityPoliciesList.filter(arr => arr.name === this.state.name && arr.policy_id !== this.props.policyDetails.policy_id)
                console.log(checkNameExist)
                if(checkNameExist && checkNameExist.length) {
                    hasError = true
                    nameErrorMessage = 'Policy name already exist'
                }
            }
        }
		let descriptionErrorMessage = ''
		// if(!this.state.description || this.state.description === '') {
		// 	hasError = true
		// 	descriptionErrorMessage = 'Please enter description'
		// } 

		let errorMessage = ''
		if(!this.state.selectedSource || this.state.selectedSource === '') {
			hasError = true
			errorMessage = 'Please select from'
		} else  if(this.state.selectedSource === 's3') {

			if(!this.state.bucket_name || this.state.bucket_name === '') {
				hasError = true
				errorMessage = 'Please enter bucket_name'
			}

		} else {
			let recordExist = 0
			if(this.state.athenaArray && this.state.athenaArray.length) {
				this.state.athenaArray.forEach(item  => {
					let dataRow = {}
					dataRow.catalog_name = this.state['catalog_name_'+item]
					
					if(this.state['catalog_name_'+item] && this.state['catalog_name_'+item] !== '') {
						recordExist++
					}					
				})
			}

			if(!recordExist) {
				hasError = true
				errorMessage = 'Please select data source'
			}
		}

		this.setState({ hasError, nameErrorMessage, descriptionErrorMessage, errorMessage, showActionLoading: !hasError })

		if(!hasError) {
			let params = {}
			
			params.policy_type = "deidentification"
			params.name = this.state.name
			params.description = this.state.description
			params.tags = this.state.selected_tags
			params.entities = this.state.selectedEntities ? this.state.selectedEntities : []
			
			let targetAttributes = []
			if(this.state.selectedEntities && this.state.selectedEntities.length) {
				this.state.selectedEntities.forEach(item => {
					targetAttributes.push(item)
				})
			}
			params.target_attributes = targetAttributes

			let text = 'From '+this.state.selectedSource

			let athenaData = []
			let dataAssociation = []
			let dataAssociationText = this.state.selectedSource
			if(this.state.selectedSource && this.state.selectedSource.toLowerCase() === 's3') {
				if(this.state.bucket_name && this.state.bucket_name !== '') {
					dataAssociationText += ':'+this.state.bucket_name

					text += ' bucket '+this.state.bucket_name
				}
				if(this.state.bucket_prefix_name && this.state.bucket_prefix_name !== '') {
					dataAssociationText += ':'+this.state.bucket_prefix_name

					text += ' path '+this.state.bucket_prefix_name
				}
				dataAssociation.push(dataAssociationText)
			} else if(this.state.selectedSource && this.state.selectedSource.toLowerCase() === 'athena') {
				if(this.state.athenaArray && this.state.athenaArray.length) {
					this.state.athenaArray.forEach(item  => {
						let dataRow = {}
						dataRow.catalog_name = this.state['catalog_name_'+item]
						dataRow.database_name = this.state['database_name_'+item]
						dataRow.table = this.state['table_'+item]
						athenaData.push(dataRow)
						dataAssociationText = this.state.selectedSource

						
						let catalog = ''
						if(this.state['catalog_name_'+item] && this.state['catalog_name_'+item] !== '') {
							catalog = this.state['catalog_name_'+item]
							dataAssociationText += ':'+this.state['catalog_name_'+item]

							text += ' data source '+ this.state['catalog_name_'+item]
						}
						let database = ''
						if(this.state['database_name_'+item] && this.state['database_name_'+item] !== '') {
							database = this.state['database_name_'+item]
							dataAssociationText += ':'+this.state['database_name_'+item]

							text += ' database '+ this.state['database_name_'+item]
						}
						if(this.state['table_'+item] && this.state['table_'+item].length) {
							text += ' table '+ this.state['table_'+item].join(', ')
							this.state['table_'+item].forEach(tab => {
								dataAssociationText = this.state.selectedSource+':'+catalog+':'+database+''+tab
								dataAssociation.push(dataAssociationText)

							})
						} else {
							dataAssociation.push(dataAssociationText)
						}

					})
				}
				params.athena_data = athenaData
			}
			
			params.data_association = dataAssociation
			
			let selectedUsers = this.state.selectedUsers[0] === 'ALL' ? ['everyone'] : this.state.selectedUsers
			params.iam_association = selectedUsers

			text += ' apply to '+this.state.selectedUsers.join(', ') + ' users / role '

			params.policy_text = text
			if(this.props.pageType === 'create') {
				this.props.insertSecurityPolicies(params, (promise, response) => {
					if(promise) {
						
						let messageType = 'danger'		
						let message = 'Check the data'
						if(response.status && response.status.message && response.status.message === 'inserted') {
							messageType = 'success'
							message = 'Saved Successfully'
						}
						CommonNotification.addNotification({
							message: message,
							type: messageType,
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});
						
						if(response.status && response.status.message && response.status.message === 'inserted') {
							this.setState({ showActionCompleted: true },
								() => {
									setTimeout(() => { this.listSecurityPolicies() }, 3000)
									setTimeout(() => { this.props.showListPage() }, 5000)
								}
							)
						} else {
							this.setState({ showActionLoading: false })
						}
					} else {
						this.setState({ showActionLoading: false })
					}
				})
			} else if(this.props.pageType === 'edit') {
				params.policy_id = this.props.policyDetails.policy_id
				this.props.updateSecurityPolicies(params, (promise, response) => {
					if(promise) {						
						let messageType = 'danger'		
						let message = 'Check the data'
						if(response.status && response.status.message && response.status.message === 'updated') {
							messageType = 'success'
							message = 'Updated Successfully'
						}
						CommonNotification.addNotification({
							message: message,
							type: messageType,
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});
						
						if(response.status && response.status.message && response.status.message === 'updated') {
							this.setState({ showActionCompleted: true },
								() => {
									setTimeout(() => { this.listSecurityPolicies() }, 3000)
									setTimeout(() => { this.props.showListPage() }, 4000)
								}
							)
						} else {
							this.setState({ showActionLoading: false })
						}
					} else {
						this.setState({ showActionLoading: false })
					}
				})
			}
		}
	}

	onChangeSource = (source) => {
		if((this.state.selectedSource && this.state.selectedSource === 'athena') || source) {
			let params = {}
			this.props.listAthenaResources(params, (promise, response) => {
				if(promise) {
					this.setState({ catalogs: response, loadingDataSource: false, disablePage: false  })
				}
			})
		} else {
			this.setState({ catalog_name: '', database_name: '', table: '', disablePage: false })
		}
	}

	getDatabaseList = (item) => {
		let params = {}
		params.catalog_name = this.state['catalog_name_'+item]
		this.props.listAthenaResources(params, (promise, response) => {
			if(promise) {
				this.setState({ ['database_'+item]: response, ['loadingDatabase_'+item]: false, disablePage: false })
			}
		})
	}

	getTables = (item) => {
		let params = {}
		params.catalog_name = this.state['catalog_name_'+item]
		params.database_name = this.state['database_name_'+item]
		this.props.listAthenaResources(params, (promise, response) => {
			this.setState({ ['tables_'+item]: response, ['loadingTables_'+item]: false, disablePage: false })
		})
	}	

    addSection = () => {
        let rowList = this.state.athenaArray;
        if(this.state.athenaArray) {
            let value = this.state.athenaArray[this.state.athenaArray.length - 1]
            value = value+1
            rowList.push(value);
        }
        
        this.setState({ athenaArray: rowList })
    }

    removeSection = (i) => {
        this.setState({ ['catalog_name'+i]: '', ['database_'+i]: [], ['database_name_'+i]: '', ['tables_'+i]: [], ['table_'+i]: '' },
            () => {
                let rowList = this.state.athenaArray;
                rowList.splice(i, 1);
                this.setState({ athenaArray: rowList })
            }
        );
    }

    onKeyDown = (e, state) => {
        let array = this.state['selected_'+state] ? this.state['selected_'+state] : []
		if (e.keyCode === 13 || e.keyCode === 9) {
            if(this.state[state+'_value'] !== '') {
                array.push(this.state[state+'_value'].trim())
                if(e.keyCode === 9) {
                    e.preventDefault();
                }
                this.setState({ ['selected_'+state]: array, [state+'_value']: '' })
            }
        }
    }

	render() {		
		return (
			!this.state.showLoading ?
			<div className={`mt-3 rounded ${(this.state.showActionLoading || this.state.disablePage) ? 'disabled' : ''}`}>
				<div className="row"> 
					<div className="col-sm-12">
						<div className="d-flex justify-content-between">
							<h6 className="font-weight-normal">{capitalizeFirstLetter(this.props.pageType)+' Security policy'}</h6>
							<i className="fas fa-arrow-left cursorPointer" onClick={() => this.props.showListPage()}> back</i>
						</div>
						<p>Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim.</p>
					</div>
				</div>
				<div className={`${this.props.pageType === 'view' ? 'disabled disableInputColor' : ''}`}>
					<div className={`form-group`}>
						<p className="m-0">Policy Name</p>
						<input 
							type="text" 
							className={`form-control col-sm-6 ${this.state.hasError && this.state.nameErrorMessage !== '' ? 'border-danger' : ''}`}
							value={this.state.name ? this.state.name : ''}
							onChange={e => this.setState({ name: e.target.value })}
						/>
						{this.state.hasError && this.state.nameErrorMessage !== '' ?
							<p className='m-0 text-danger align-self-end mb-1'>{this.state.nameErrorMessage}</p>
						: null}
					</div>
									
					<div className={`form-group`}>
						<p className="m-0">Description</p>
						<textarea
							readOnly={this.props.pageType === 'view' ? true : false}
							className="form-control border-black6"
							onChange={(event) => this.setState({ description: event.target.value })} 
							value={this.state.description}
							maxLength={200}
						/>
					</div>
					<div className={`form-group dlpDescriptionFormWhite mt-3`}>
						<React.Fragment>
							<div className="d-flex">
								<p className="mb-0 mr-2 align-self-end text-info mt-3">Tags</p>
								<input 
									type="text" 
									className={`inputField mt-3 w-40`}
									value={this.state.tags_value ? this.state.tags_value : ''}
									onChange={e => this.setState({ tags_value: e.target.value })}
									onKeyDown={e => this.onKeyDown(e, 'tags')}
								/>
							</div>
							<div className="d-flex flex-wrap mt-3">
								{this.state.selected_tags && this.state.selected_tags.map((item, index) => {
									return(
									<span key={index} className="badge-square badge-secondary f13 align-self-end p-1 mr-2 mt-2" >
									{item}
										<i onClick={ () => this.removeSelectedOption('selected_tags', item) } className='ml-1 fal fa-times cursorPointer'></i>
									</span>
									)
								})}
							</div>
						</React.Fragment>
					</div>
					<div className={`form-group dlpDescriptionFormWhite mt-3`}>
						<div className="d-flex flex-wrap">
							<React.Fragment>
								<p className="mb-0 mr-2 align-self-end text-info mt-3">From</p>
								<Select
									// menuIsOpen={true}
									placeholder={'Select'}
									components={{
										IndicatorSeparator: () => null
									}}
									isSearchable={false}
									className={`selectOption mr-2 mt-3 ${this.state.hasError && (!this.state.selectedSource || this.state.selectedSource === '') ? 'dottedDangerSelectLine'  : ''}`}
									value={({
										value: this.state.selectedSource ? this.state.selectedSource : 'Select',
										label: this.state.selectedSource ? this.state.selectedSource : 'Select'
									})}
									options={this.state.sources && this.state.sources.map(item => ({
										value: item,
										label: item,
									}))}
									onChange={event =>  
										this.setState({ selectedSource: event.value, loadingDataSource: true, disablePage: true }, 
											() => this.onChangeSource()
										)
									}
								/>
							</React.Fragment>
						</div>
						<div className="d-flex flex-wrap">
							{this.state.selectedSource && this.state.selectedSource.toLowerCase() === 's3' ?
								<React.Fragment>
								<p className="mb-0 mr-2 align-self-end text-info mt-3">Bucket</p>
								<input 
									type="text" 
									className={`inputField mt-3 w-40 ${this.state.hasError && (!this.state.bucket_name || this.state.bucket_name === '') ? 'dottedDangerTextboxLine' : ''}`}
									value={this.state.bucket_name ? this.state.bucket_name : ''}
									onChange={e => this.setState({ bucket_name: e.target.value })}
								/>
								<p className="mb-0 mx-2 align-self-end text-info mt-3">path</p>
								<input 
									type="text" 
									className="inputField mt-3 w-40"
									value={this.state.bucket_prefix_name ? this.state.bucket_prefix_name : ''}
									onChange={e => this.setState({ bucket_prefix_name: e.target.value })}
								/>
								</React.Fragment>
							: this.state.selectedSource && this.state.selectedSource.toLowerCase() === 'athena' ?
								<React.Fragment>													
								{this.state.athenaArray.map((item, i) => {
									return(
										<div className="w-100">
											<div className="d-flex flex-wrap">
												<p className="mb-0 mr-2 align-self-end text-info mt-3">
													{this.state.loadingDataSource ? <Spinner size='sm' className='mx-2' color='white' /> : ''} Data source
												</p>
												<Select
													// menuIsOpen={true}
													placeholder={'Select'}
													components={{
														IndicatorSeparator: () => null
													}}
													isSearchable={false}
													className={`selectOption mr-2 mt-3 w-30 ${this.state.hasError && (!this.state['catalog_name_'+item] || this.state['catalog_name_'+item] === '') ? 'dottedDangerSelectLine' : ''}`}
													value={({
														value: this.state['catalog_name_'+item] ? this.state['catalog_name_'+item] : 'Select',
														label: this.state['catalog_name_'+item] ? this.state['catalog_name_'+item] : 'Select'
													})}
													options={this.state.catalogs && this.state.catalogs.map(cat => ({
														value: cat,
														label: cat,
													}))}
													onChange={event =>  
														this.setState({ ['catalog_name_'+item]: event.value, ['loadingDatabase_'+item]: true, disablePage: true }, () => this.getDatabaseList(item) )
													}
												/>
												<p className="mb-0 mx-2 align-self-end text-info mt-3">
													{this.state['loadingDatabase_'+item] ? <Spinner size='sm' className='mx-2' color='white' /> : ''}
													database
												</p>
												<Select
													// menuIsOpen={true}
													placeholder={'Select'}
													components={{
														IndicatorSeparator: () => null
													}}
													isSearchable={true}
													className={`selectOption mr-2 mt-3 w-30`}
													value={({
														value: this.state['database_name_'+item] ? this.state['database_name_'+item] : 'Select',
														label: this.state['database_name_'+item] ? this.state['database_name_'+item] : 'Select'
													})}
													options={this.state['database_'+item] && this.state['database_'+item].map(dbs => ({
														value: dbs,
														label: dbs,
													}))}
													onChange={event =>  
														this.setState({ ['database_name_'+item]: event.value, ['loadingTables_'+item]: true, disablePage: true }, () => this.getTables(item) )
													}
												/>
												<p className="mb-0 mr-2 align-self-end text-info mt-3">
													{this.state['loadingTables_'+item] ? <Spinner size='sm' className='mx-2' color='white' /> : ''}
													Tables
												</p>
												{this.state['table_'+item] && this.state['table_'+item].map((value, index) => {
													return(
													<span key={index} className="badge-square badge-secondary f13 align-self-end p-1 mr-2" >
														{value}
														<i onClick={ () => this.removeSelectedOption(value, index, item) } className='ml-1 fal fa-times cursorPointer'></i>
													</span>
													)
												})}
												<div className="multiSelectOption align-self-end mt-3">
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="ALL"
														isSearchable={false}
														options={this.state['tables_'+item] && this.state['tables_'+item].map(tbt => ({
															value: tbt,
															label: tbt,
														}))}														
														onChange={event => {
															this.handleMultiSelectChange('table_'+item, event) }
														}
														value={this.state['table_'+item] && this.state['table_'+item].map(tbt => ({
															value: tbt,
															label: tbt
														}))}
													/>
												</div>
												
												{i ?
													<span className={`far fa-trash cursor-pointer f18 align-self-end ml-4`} onClick={() => this.removeSection(i)}></span>
												: null}
											</div>
										</div>
									)
								})}

								{this.state.selectedSource && this.state.selectedSource.toLowerCase() === 'athena' ?
									<div className="d-flex col-sm-12 justify-content-end">
										<span className='btn btn-secondary' onClick={() => this.addSection()}>Add</span>
									</div>
								: null}
								
								</React.Fragment>
							: null}
						</div>
						<div className="d-flex flex-wrap">
							{this.state.selectedSource ?
								<React.Fragment>
									<p className="mb-0 mr-2 align-self-end text-info mt-3">Apply to</p>
									{/* <p className="mb-0 mx-2 align-self-end mt-3 font-weight-bold">{this.state.selectedUsers}</p> */}
									{this.state.selectedUsers && this.state.selectedUsers.map((item, index) => {
										return(
										<span key={index} className="badge-square badge-secondary f13 align-self-end p-1 mr-2" >
										{item}
											<i onClick={ () => this.removeSelectedOption('selectedUsers', item) } className='ml-1 fal fa-times cursorPointer'></i>
										</span>
										)
									})}
									<div className="multiSelectOption align-self-end mt-3">
										<ReactMultiSelectCheckboxes						
											placeholderButtonLabel="ALL"
											isSearchable={false}
											options={this.state.users && this.state.users.map(item => ({
												value: item,
												label: item,
											}))}														
											onChange={event => {
												this.handleMultiSelectChange('selectedUsers', event) }
											}
											value={this.state.selectedUsers && this.state.selectedUsers.map(item => ({
												value: item,
												label: item
											}))}
										/>
									</div>
									<p className="mb-0 mx-2 align-self-end text-info mt-3">user/role</p>
								</React.Fragment>
							: null}
						</div>
					</div>
				</div>
				<div className={`border-top pt-2 ${this.state.hasError && this.state.errorMessage !== '' ? '' : 'mt-5'}`}>
					<button className='btn btn-light mr-1' onClick={() => this.props.showListPage()}>Cancel</button>
					{this.props.pageType.toLowerCase() !== 'view' ?
						!this.state.showActionCompleted ?
							this.state.showActionLoading ?
								<button className='btn btn-primary' onClick={() => this.actionPorcess()}>
									<Spinner size='sm' className='mx-2' color='white' /> {this.props.pageType.toLowerCase() === 'create' ? 'Creating ... ' : 'Updating ...' }
								</button>
							: 
								<button className='btn btn-primary' onClick={() => this.actionPorcess()}>{this.props.pageType.toLowerCase() === 'create' ? 'Create' : 'Update' }</button>
						:
							<button className='btn btn-primary disabled'>
								{this.props.pageType.toLowerCase() === 'create' ? 'Created' : 'Updated' }
							</button>
					: null}
				</div>
			</div>
		:
			<div className='text-center mt-5' >
				<Spinner color='dark' size='lg' />
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
AddEdit.propTypes = {
	listAthenaResources: PropTypes.func,
    insertSecurityPolicies: PropTypes.func,
	updateSecurityPolicies: PropTypes.func
}

const mapStateToProps = state => {
	return {
		securityPoliciesList: state.dlp.dlpSecurityPoliciesList,
	}
}

export default connect(mapStateToProps, {
	listAthenaResources,
	insertSecurityPolicies,
	updateSecurityPolicies,
	listSecurityPolicies
})(withRouter(AddEdit))



