/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file compilanceDasboard.js
 * @author Prakash // on 5/06/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { AppWrapper } from '../common/AppWrapper'
import PropTypes from 'prop-types'
import { URL_PATH } from '../../config/urlPath'
import { currentLocaltime, previousDay, subDays, momentDateGivenFormat, momentConvertionLocalToUtcTime, getPastDaysNew } from '../../utils/utility'
import { 
    listAllProviders, 
    listAllAccounts, 
    listAllRegions
} from '../../actions/commonAction'
import {
    getThreatDashboardData 
} from '../../actions/dlp/threatAction'

import Select from 'react-select'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

import Chart from 'react-apexcharts'
import StackedCircleBarChart from '../common/charts/StackedCircleBarChart'
import HorizontalLimitLollipopChart from '../common/charts/HorizontalLimitLollipopChart'
import ApexDonutChart from '../common/charts/ApexDonutChart'
import ApexAreaChart from '../common/charts/ApexAreaChart'

class DlpDashboard extends Component {
    accountRef = React.createRef()
    regionRef = React.createRef()
    serviceRef = React.createRef()

    radialStackedBarRef = React.createRef()
    uebaDivRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            
            providers: [],
            selectedProvider: '',
            accounts: [],
            accountArray: [],
            selectedAccount: [],
            selectedAccountName: [],
            isAccountOpen: false,

            services: [],
            parentServices: [],
            selectedService: [],

            regions: [],
            selectedRegion: [],
            selectedRegionName: [],
            isRegionOpen: false,
            selectedFilterPeriod: 7,
            
            selectedComplicance: 'dc v1.0',
        }

        this.handleStartTimeChange  = this.handleStartTimeChange.bind(this)
        this.handleEndTimeChange    = this.handleEndTimeChange.bind(this)
    }
    
    componentDidMount = () => {	
        if(this.props.providers && this.props.providers.length) {
			this.setState({ providers: this.props.providers, selectedProvider: this.props.providers[0].provider_name },
				() => {
					this.filterDlp('provider')
					this.sliderPeriod()	
				}
			)
		} else {
			this.props.listAllProviders((promise, providers) => {
				if (promise) {
					this.setState({ providers, selectedProvider: providers[0].provider_name },
						() => {
							this.filterDlp('provider')
							this.sliderPeriod()	
						}
					)
				} else {
					this.setState(
						{ providers: [],  }, 
						() => {
							this.filterDlp()
							this.sliderPeriod()	
						}
					)
				}
			})
		}
    }

    filterDlp = from => {
        let params = {}

        if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'all') {
            params.provider = this.state.selectedProvider.toLowerCase()
        }

        if (from === 'provider') {
			if(this.props.accounts && this.props.accounts.length) {
				let allAccounts = this.props.accounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
				if(this.props.accounts.length) {
					let accRow = {}
					accRow.account_id = "All"
					accRow.account_name = "All"
					accRow.date = null
					accRow.disabled = false
					accRow.provider = "All"
					allAccounts.unshift(accRow);
				}
				this.setState({ accounts: allAccounts })
			} else {
				this.props.listAllAccounts(params, (promise, allAccounts) => {
					if (promise) {						
						let filterdAccounts = allAccounts.filter(account => account.provider === this.state.selectedProvider.toLowerCase())
						if(filterdAccounts.length) {
							let accRow = {}
							accRow.account_id = "All"
							accRow.account_name = "All"
							accRow.date = null
							accRow.disabled = false
							accRow.provider = "All"
							filterdAccounts.unshift(accRow);
						}						
						this.setState({ accounts: filterdAccounts })
					}
				})
			}

			if(this.props.regions && this.props.regions.length) {
				let providerRegions = this.props.regions && this.props.regions[0] && this.props.regions[0].provider_regions
				this.setState({ regions: providerRegions })
			} else {
				this.props.listAllRegions(params, (promise, regions) => {
					if (promise) {
						let providerRegions = regions && regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						this.setState({ regions: providerRegions })
					} else {
						this.setState({ regions: [] })
					}
				})
			}
		}
        
    }

    onSearch = () => {
        let changesInFilter = this.state.changesInFilter;
        if(!this.state.previousSliderStartDate || (this.state.previousSliderStartDate !== this.state.sliderStartDate || this.state.previousSliderEndDate !== this.state.sliderEndDate)) {
            changesInFilter = true
            this.setState({ previousSliderStartDate: this.state.sliderStartDate, previousSliderEndDate: this.state.sliderEndDate })
        }
        if(changesInFilter) {
            let startTime = this.state.sliderStartDate
            let endTime = this.state.sliderEndDate
            if(this.state.sliderStartDate !== this.state.startSlice || this.state.sliderEndDate !== this.state.endSlice) {
                startTime = this.state.startSlice
                endTime = this.state.endSlice
            }

            this.setState({
                start_time: momentConvertionLocalToUtcTime(startTime, 'YYYY-MM-DD HH:mm:ss'),
                end_time: momentConvertionLocalToUtcTime(endTime, 'YYYY-MM-DD HH:mm:ss'),
				showLoading: true,
            },
                () => {
                    this.getThreatDashboardData()
                }
            )
        }

        this.setState({ changesInFilter: false })

        setTimeout(() => { this.setState({ showLoading: false }) }, 5000)
    }	

    onReset = () => {
        this.setState({
            selectedProvider: this.state.providers && this.state.providers.length ? this.state.providers[0].provider_name : '',
            selectedRegion: [],
            selectedRegionName: [],
            selectedAccount: [],
            sliderStartDate: this.state.initialStartDate,
            sliderEndDate: this.state.initialEndDate,
            changesInFilter: true
        },
            () => this.onSearch()
        )        
    }

	sliderPeriod = () => {
		let sliderEndDate = momentDateGivenFormat(currentLocaltime(), 'YYYY-MM-DD 23:59:59')		
		let brushInterval = 0
		let fullBar = 0
		let smallBar = 0
		let filterMonth = 0
		if(this.state.selectedFilterPeriod === 7) {
			fullBar = 10
			brushInterval = 7
			filterMonth = 90
		} else if(this.state.selectedFilterPeriod === 30) {
			fullBar = 5
			brushInterval = 30
			filterMonth = 180
		} else if(this.state.selectedFilterPeriod === 90) {
			fullBar = 10
			brushInterval = 90
			filterMonth = 365
		}
		// else if(this.state.selectedFilterPeriod === 180) {
		// 	fullBar = 15
		// 	smallBar = 3
		// 	brushInterval = 30
		// } else if(this.state.selectedFilterPeriod === 365) {
		// 	fullBar = 15
		// 	smallBar = 3
		// 	brushInterval = 60
		// }
		let sliderStartDate = subDays(sliderEndDate, brushInterval)
		let dateArray = []

		let y = 1
		let z = 1
		for(let x=1;x<=filterMonth;x++) {
			
			let date = subDays(sliderEndDate, (filterMonth-x))

			let value = smallBar ? 0 : 5
			if(smallBar === z) {
				value = 5
				z = 1
			}
			z++
			if(y === fullBar || y === 1) {
				value = 10
				y = 1
			}
			y++;
			if(new Date(date).getTime() <= new Date(sliderEndDate).getTime()) {
				dateArray.push([date, value])
			}
		}
		sliderStartDate = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD')
		sliderEndDate = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD')

		let startSlice = momentDateGivenFormat(sliderStartDate, 'YYYY-MM-DD 00:00:00');
		let endSlice = momentDateGivenFormat(sliderEndDate, 'YYYY-MM-DD 23:59:59');

		this.setState({ dateArray, brushSliderSeries: [], sliderStartDate, sliderEndDate, initialStartDate: sliderStartDate, initialEndDate: sliderEndDate, startSlice, endSlice },
			() => {
				this.brushSliderChart(dateArray, sliderStartDate, sliderEndDate)
				this.onSearch()
			}
		)
	}
	
	brushSliderChart = (dateArray, sliderStartDate, sliderEndDate) => {
		if(dateArray.length) {
			// let labels = []
			// let dataOneLength = dateArray.length
			// let lastDate = dateArray[dataOneLength-1][0]
			let brushSliderSeries = [{
				data: dateArray
			}]
			let dataArray = []
			dateArray.forEach(item => {
				dataArray.push(item[0])
			})

			let columnWidth = '5%'
			let brushSliderOptions = {
				plotOptions: {
					bar: {
						barHeight: '100%',
						columnWidth: columnWidth,
					},          
				},
				chart: {
					redrawOnParentResize: true,
					id: 'chart1',
					height: 130,
					type: 'bar',
					brush:{
						target: 'none',
						enabled: true,
					},
					zoom: {
						enabled: false,
					},
					selection: {
						enabled: true,
						fill: {
							color: "#039BE5",
							opacity: 1,
							// opacity: 0.6
						},  
						stroke: {
							show: false,
							curve: 'straight',
							lineCap: 'round',
							// colors: '#00A3CB',
							colors: '#039BE5',
							width: 0,
							dashArray: 0,      
						},
						xaxis: {
							min: new Date(sliderStartDate).getTime(),
							max: new Date(sliderEndDate).getTime(),
						},
						style: {
							fontSize: '28px',
							color: '#000000'
						}
					},
					events: {
						selection: (chartContext, { xaxis, yaxis }) => {
							var minDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD')
							var maxDate = momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD')

							let startSlice = momentDateGivenFormat(minDate, 'YYYY-MM-DD 00:00:00');
							let endSlice = momentDateGivenFormat(maxDate, 'YYYY-MM-DD 23:59:59');

							this.setState({ sliderStartDate: startSlice, sliderEndDate: endSlice, startSlice, endSlice })
							this.getAttribute()
						},
						click: (event, chartContext, config) => {
							this.onSearch()
						}
					}
				},
				colors: ['#515151'],
				xaxis: {
					tickAmount: 8,
					type: 'datetime',
					tooltip: {
						enabled: false
					},
					position: 'top',
					labels: {
						show: true,
						datetimeUTC: false,
						style: {
							colors: '#E0E0E0'
						},
						formatter: function(val) {
							return momentDateGivenFormat(new Date(val), "DD MMM");
						}
					},
					
				},
				yaxis: {
					show: false,
					tickAmount: 2,
					labels: {
						show: false
					},
				},
				grid: {
					xaxis: {
						lines: {
							show: false
						},
					},
					yaxis: {
						lines: {
							show: false
						}
					},
				}
			}
		
			this.setState({ datePickerSliderSeries: brushSliderSeries, datePickerSliderOptions: brushSliderOptions })
		}
	}
	
	getAttribute = () => {
		var attributes = document.getElementsByClassName("apexcharts-selection-rect") && document.getElementsByClassName("apexcharts-selection-rect")[0] &&document.getElementsByClassName("apexcharts-selection-rect")[0].attributes;
		if(attributes) {
			let width = attributes.width.value
			let x1 = parseInt(attributes.x.value) + parseInt(20)
			let x2 = parseInt(attributes.x.value) + parseInt(width) + parseInt(10)
			var d1 = document.getElementById('leftSliderSpan');
			d1.style.position = "absolute";
			d1.style.left = x1+'px';
			d1.style.top = '110px';
			d1.style.color = '#039BE5';

			var d2 = document.getElementById('rightSliderSpan');
			d2.style.position = "absolute";
			d2.style.left = x2+'px';
			d2.style.top = '110px';
			d2.style.color = '#039BE5';
		}
	}

	handleStartTimeChange(date) {
		let startSlice = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let endSlice = this.state.endSlice
		if(new Date(startSlice).getTime() > new Date(endSlice).getTime() ) {
			endSlice = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}

		this.setState({ startSlice, endSlice, changesInFilter: true })
	}

	handleEndTimeChange(date) {
		let startSlice = this.state.startSlice
		let endSlice = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(endSlice).getTime() > new Date(startSlice).getTime() ) {
			this.setState({ endSlice, changesInFilter: true })
		}
	}

    handleMultiSelectChange = (childKey, arrayValue) => {
		this.setState({ caches: true})
		let value = arrayValue.map(item => item.value)
		let label = arrayValue.map(item => item)	
		if(childKey === 'selectedAccount') {
			let prevState = this.state.selectedAccount
			let selectedAccountName = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.accounts.forEach(acc => {
						if(acc.account_id === item) {
							label = {}
							label.value = acc.account_id
							label.label = acc.account_name
							array.push(label)
						}
					})
				})
				selectedAccountName = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedAccountName = []
			}
			if(value.length && value.includes("All")) {
				let selectedAccount = []
				selectedAccountName = []
				this.state.accounts.forEach(acc => {
					label = {}
					label.value = acc.account_id
					label.label = acc.account_name

					selectedAccount.push(label.value)
					selectedAccountName.push(label)
				})
				this.setState({ selectedAccount, selectedAccountName, changesInFilter: true })
			} else {
				this.setState({ selectedAccount: value, selectedAccountName, changesInFilter: true })
			}
		} else if(childKey === 'selectedRegion') {
			let prevState = this.state.selectedRegion
			let selectedRegionName = arrayValue
			if(prevState.includes('All') && value.includes("All")) {
				const index = value.indexOf('All');
				if (index > -1) {
					value.splice(index, 1);
				}
				let array = []
				value.forEach(item => {
					this.state.regions.forEach(reg => {
						if(reg.region === item) {
							let label = {}
							label.value = reg.region
							label.label = reg.name
							array.push(label)
						}
					})
				})
				selectedRegionName = array
			} else if(prevState.includes('All') && !value.includes("All")) {
				value = []
				selectedRegionName = []
			}

			if(value.length && value.includes("All")) {
				let selectedRegion = []
				selectedRegionName = []
				this.state.regions.forEach(reg => {
					let label = {}
					label.value = reg.region
					label.label = reg.name

					selectedRegion.push(label.value)
					selectedRegionName.push(label)
				})
				this.setState({ selectedRegion, selectedRegionName, changesInFilter: true  })
			} else {
				this.setState({ selectedRegion: value, selectedRegionName, changesInFilter: true  })
			}
		}
	}	

	getMultiSelectedCount = (type, array) => {
		if(array) {
			return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		} else {
			return 'All'
		}
	}
        
    handleClickOutside(event) {
        
        if (this.accountRef && !this.accountRef.current.contains(event.target)) {
            this.setState({ isAccountOpen: false })
        } else {
            this.setState({ isAccountOpen: true })
        }

        if (this.regionRef && !this.regionRef.current.contains(event.target)) {
            this.setState({ isRegionOpen: false })
        } else {
            this.setState({ isRegionOpen: true })
        }
    }

	getThreatDashboardData = () => {
		let params = {}
		// params.compliance = [{"complianceId": "dc v1.0"}]
		params.provider = this.state.selectedProvider.toLowerCase()
		// if (this.state.selectedAccount.length && !this.state.selectedAccount.includes("All")) {
		// 	params.account_id = this.state.selectedAccount
        // }
		// if (this.state.selectedRegion.length && !this.state.selectedRegion.includes("All")) {
		// 	params.region = this.state.selectedRegion
        // }
		params.start_time = this.state.start_time
		params.end_time = this.state.end_time
	
		this.props.getThreatDashboardData(params, (promise, result) => {
            if(promise) {
			
                this.setState({ overallData: result, showLoading: false },
                    () => {
                        this.setState({ showOverallRadialChart: false }, 
                           () =>  this.renderOverallData()
                        )
                    }    
                )
			} else {
                this.setState({ showLoading: false })
            }
		})
	}

	renderOverallData = () => {
        let bucketData = this.state.overallData && this.state.overallData.issues_count ? this.state.overallData.issues_count.buckets : []
        let compliance = this.state.overallData && this.state.overallData.compliance ? this.state.overallData.compliance : []
        let threats = this.state.overallData && this.state.overallData.threats ? this.state.overallData.threats : []
        let ueba = this.state.overallData && this.state.overallData.ueba ? this.state.overallData.ueba : []
        let malware = this.state.overallData && this.state.overallData.virus ? this.state.overallData.virus : []
		
        let clickData = {}
        if(bucketData) {
            let keys = ['compliance', 'threats', 'ueba', 'malware']
            let overallArray = []
            let total = 0
            let totalOverall = 0
            bucketData.forEach((item, index) => {
                if(item.prefix_wise_count && item.prefix_wise_count.length) {
                    item.prefix_wise_count.forEach((pref,i) => {
                        let State = 'prefix_'+index+'_'+i
                        
                        let clickRow = {}
                        if(pref.ueba) {
                            clickRow.ueba_trend = pref.ueba.trend ? pref.ueba.trend : {}
                            clickRow.ueba_status = pref.ueba.status ? pref.ueba.status : []
                            clickRow.ueba_risk = pref.ueba.risks ? pref.ueba.risks : []
                            clickRow.ueba_issues = pref.ueba.issues ? pref.ueba.issues : []
                            clickRow.ueba_count = pref.ueba.count ? pref.ueba.count : 0
                            clickRow.ueba_week_text = pref.ueba.week_change ? pref.ueba.week_change : ''
                            clickRow.ueba_month_text = pref.ueba.month_change ? pref.ueba.month_change : ''
                        }
                        if(pref.threats) {
                            clickRow.threats_trend = pref.threats.trend ? pref.threats.trend : {}
                            clickRow.threats_status = pref.threats.status ? pref.threats.status : []
                            clickRow.threats_risk = pref.threats.risks ? pref.threats.risks : []
                            clickRow.threats_issues = pref.threats.issues ? pref.threats.issues : []
                            clickRow.threats_count = pref.threats.count ? pref.threats.count : 0
                            clickRow.threats_week_text = pref.threats.week_change ? pref.threats.week_change : ''
                            clickRow.threats_month_text = pref.threats.month_change ? pref.threats.month_change : ''
                        }
                        if(pref.virus) {
                            clickRow.malware_trend = pref.virus.trend ? pref.virus.trend : {}
                            clickRow.malware_compliances = pref.virus.compliances ? pref.virus.compliances : []
                            clickRow.malware_risk = pref.virus.risks ? pref.virus.risks : []
                            clickRow.malware_issues = pref.virus.issues ? pref.virus.issues : []
                            clickRow.malware_count = pref.virus.count ? pref.virus.count : 0
                            clickRow.malware_week_text = pref.virus.week_change ? pref.virus.week_change : ''
                            clickRow.malware_month_text = pref.virus.month_change ? pref.virus.month_change : ''
                        }
                        if(pref.compliance) {
                            clickRow.compliance_trend = pref.compliance.trend ? pref.compliance.trend : {}
                            clickRow.compliance_status = pref.compliance.compliances ? pref.compliance.compliances : []
                            clickRow.compliance_risk = pref.compliance.risks ? pref.compliance.risks : []
                            clickRow.compliance_issues = pref.compliance.issues ? pref.compliance.issues : []
                            clickRow.compliance_count = pref.compliance.count ? pref.compliance.count : 0
                            clickRow.compliance_week_text = pref.compliance.week_change ? pref.compliance.week_change : ''
                            clickRow.compliance_month_text = pref.compliance.month_change ? pref.compliance.month_change : ''
                        }
                        clickData[State] = clickRow
                        
    
                        // for staked circle bar
                        let dataRow = {}
                        dataRow.State = State
                        dataRow.page = 'Dashboard'
                        dataRow.name = (item.bucket_name ? item.bucket_name : '')+' '+ (pref.bucket_prefix ? pref.bucket_prefix : '')
                        dataRow.total = pref.count ? pref.count : 0
                        dataRow.compliance = pref.compliance ? pref.compliance.count : 0
                        dataRow.threats = pref.threats ? pref.threats.count : 0
                        dataRow.ueba = pref.ueba ? pref.ueba.count : 0
                        dataRow.malware = pref.virus ? pref.virus.count : 0
    
                        totalOverall += item.count
                        if(total < pref.count) {
                            total = pref.count
                        }
    
                        overallArray.push(dataRow)
                    })
                } else {
                    let State = 'bucket_'+index
                    
                    let clickRow = {}
                    if(item.ueba) {
                        clickRow.ueba_trend = item.ueba.trend ? item.ueba.trend : {}
                        clickRow.ueba_status = item.ueba.status ? item.ueba.status : []
                        clickRow.ueba_risk = item.ueba.risks ? item.ueba.risks : []
                        clickRow.ueba_issues = item.ueba.issues ? item.ueba.issues : []
                        clickRow.ueba_count = item.ueba.count ? item.ueba.count : 0
                        clickRow.ueba_week_text = item.ueba.week_change ? item.ueba.week_change : ''
                        clickRow.ueba_month_text = item.ueba.month_change ? item.ueba.month_change : ''
                    }
                    if(item.threats) {
                        clickRow.threats_trend = item.threats.trend ? item.threats.trend : {}
                        clickRow.threats_status = item.threats.status ? item.threats.status : []
                        clickRow.threats_risk = item.threats.risks ? item.threats.risks : []
                        clickRow.threats_issues = item.threats.issues ? item.threats.issues : []
                        clickRow.threats_count = item.threats.count ? item.threats.count : 0
                        clickRow.threats_week_text = item.threats.week_change ? item.threats.week_change : ''
                        clickRow.threats_month_text = item.threats.month_change ? item.threats.month_change : ''
                    }
                    if(item.virus) {
                        clickRow.malware_trend = item.virus.trend ? item.virus.trend : {}
                        clickRow.malware_compliances = item.virus.compliances ? item.virus.compliances : []
                        clickRow.malware_risk = item.virus.risks ? item.virus.risks : []
                        clickRow.malware_issues = item.virus.issues ? item.virus.issues : []
                        clickRow.malware_count = item.virus.count ? item.virus.count : 0
                        clickRow.malware_week_text = item.virus.week_change ? item.virus.week_change : ''
                        clickRow.malware_month_text = item.virus.month_change ? item.virus.month_change : ''
                    }
                    if(item.compliance) {
                        clickRow.compliance_trend = item.compliance.trend ? item.compliance.trend : {}
                        clickRow.compliance_status = item.compliance.compliances ? item.compliance.compliances : []
                        clickRow.compliance_risk = item.compliance.risks ? item.compliance.risks : []
                        clickRow.compliance_issues = item.compliance.issues ? item.compliance.issues : []
                        clickRow.compliance_count = item.compliance.count ? item.compliance.count : 0
                        clickRow.compliance_week_text = item.compliance.week_change ? item.compliance.week_change : ''
                        clickRow.compliance_month_text = item.compliance.month_change ? item.compliance.month_change : ''
                    }
                    clickData[State] = clickRow

                    // for staked circle bar
                    let dataRow = {}
                    dataRow.State = State
                    dataRow.page = 'Dashboard'
                    dataRow.name = item.bucket_name ? item.bucket_name : ''
                    dataRow.total = item.count ? item.count : 0
                    dataRow.compliance = item.compliance ? item.compliance.count : 0
                    dataRow.threats = item.threats ? item.threats.count : 0
                    dataRow.ueba = item.ueba ? item.ueba.count : 0
                    dataRow.malware = item.virus ? item.virus.count : 0
    
                    if(total < item.count) {
                        total = item.count
                    }
    
                    overallArray.push(dataRow)
                }
            })
            
            if(overallArray.length < 25) {			
                let length = overallArray.length
                for(let i=length; i<=25; i++) {
                    let dataRow = {}
                    dataRow.State = 'overall_'+i
                    dataRow.name = 'empty_data'
                    dataRow.total = 0
                    keys.forEach((key, j) => {
                        if(j===0) {
                            dataRow[key] = 1
                        } else {
                            dataRow[key] = 0
                        }
                    })
                    overallArray.push(dataRow)
                }
            }
    
            let totalOverallData = {}
            let data = {}
            data.name = 'Overall Data'
            keys.forEach((key, j) => {
                data[key] = this.count(overallArray, key)
            })
            totalOverallData.data = data
    
            this.setState({ overallArray, overallKeys: keys, highestOverall: total, totalOverallData, totalOverall, showOverallChart: true, showOverallRadialChart: true, clickData })
        }

        this.setState({ show_ueba_data: false, show_threats_data: false, show_malware_data: false, show_compliance_data: false }, 
            
            () => {
                if(ueba) {
                    this.setState({ ueba_trend: ueba.trend ? ueba.trend : {}, ueba_risk: ueba.risks ? ueba.risks : [], ueba_status: ueba.status ? ueba.status : [], ueba_count: ueba.count, ueba_week_text: ueba.week_change, ueba_month_text: ueba.month_change }, 
                        () => {
                            if(ueba.issues) {
                                this.formHorizontalLollipopThreatData('ueba', ueba.issues)
                            }
                        }
                    )
                }
                if(threats) {
                    this.setState({ threats_trend: threats.trend ? threats.trend : {}, threats_risk: threats.risks ? threats.risks : [], threats_status: threats.status ? threats.status : [], threats_count: threats.count, threats_week_text: threats.week_change, threats_month_text: threats.month_change }, 
                        () => {
                            if(threats.issues) {
                                this.formHorizontalLollipopThreatData('threats', threats.issues)
                            }
                        }
                    )
                }        
                if(compliance) {
                    this.setState({ compliance_trend: compliance.trend ? compliance.trend : {}, compliance_risk: compliance.risks ? compliance.risks : [], compliance_status: compliance.compliances ? compliance.compliances : [], compliance_count: compliance.count, compliance_week_text: compliance.week_change, compliance_month_text: compliance.month_change }, 
                        () => {
                            if(compliance.issues) {
                                this.formHorizontalLollipopThreatData('compliance', compliance.issues) 
                            }
                        }
                    )
                }        
                if(malware) {
                    this.setState({ malware_trend: malware.trend ? malware.trend : {}, malware_risk: malware.risks ? malware.risks : [], malware_compliances: malware.compliances ? malware.compliances : [], malware_count: malware.count, malware_week_text: malware.week_change, malware_month_text: malware.month_change }, 
                        () => {
                            if(malware.issues) {
                                this.formHorizontalLollipopThreatData('malware', malware.issues) 
                            }
                        }
                    )
                }
            }
        )
	}

    formHorizontalLollipopThreatData = (from, graphData) => {
        let dataObject = {}
        let data = []
        let highestCount = 0
        graphData.forEach(item => {
            let dataRow = {}
            dataRow.label = item.issue_name
            dataRow.item_name = item.issue_name
            dataRow.item_count = item.count
            data.push(dataRow)
            if(highestCount < item.count) {
                highestCount = item.count
            }
        })
        dataObject.count = highestCount
        dataObject.data = data
        this.setState({ [from+'_trend_data']: dataObject, ['show_'+from+'_data']: true });

    }

	count = (array, key) => {
		return array.reduce(function (r, a) {
			return r + a[key];
		}, 0);
	}

    redirect = (path) => {
        this.props.history.push({ 
            pathname: path,
            state: { prevPath: this.props.history.location.pathname, 
                sliderStartDate: this.state.sliderStartDate, 
                sliderEndDate: this.state.sliderEndDate, 
                startSlice: this.state.startSlice, 
                endSlice: this.state.endSlice, 
                selectedFilterPeriod: this.state.selectedFilterPeriod }
        })
    }

    renderOnclickdata = () => {
        if(this.state.selectedBucketState) {
            let selectedData = this.state.clickData[this.state.selectedBucketState]
            this.setState({ 
                showOverallRadialChart: true,
                ueba_trend: selectedData.ueba_trend,
                ueba_risk: selectedData.ueba_risk,
                ueba_status: selectedData.ueba_status,
                ueba_count: selectedData.ueba_count,
                ueba_week_text: selectedData.ueba_week_text,
                ueba_month_text: selectedData.ueba_month_text,

                threats_trend: selectedData.threats_trend,
                threats_risk: selectedData.threats_risk,
                threats_status: selectedData.threats_status,
                threats_count: selectedData.threats_count,
                threats_week_text: selectedData.threats_week_text,
                threats_month_text: selectedData.threats_month_text,

                malware_trend: selectedData.malware_trend,
                malware_risk: selectedData.malware_risk,
                malware_compliances: selectedData.malware_compliances,
                malware_count: selectedData.malware_count,
                malware_week_text: selectedData.malware_week_text,
                malware_month_text: selectedData.malware_month_text,

                compliance_trend: selectedData.compliance_trend,
                compliance_risk: selectedData.compliance_risk,
                compliance_status: selectedData.compliance_status,
                compliance_count: selectedData.compliance_count,
                compliance_week_text: selectedData.compliance_week_text,
                compliance_month_text: selectedData.compliance_month_text
            },
                () => {
                    this.formHorizontalLollipopThreatData('ueba', selectedData.ueba_issues)
                    this.formHorizontalLollipopThreatData('threats', selectedData.threats_issues)
                    this.formHorizontalLollipopThreatData('malware', selectedData.malware_issues)
                    this.formHorizontalLollipopThreatData('compliance', selectedData.compliance_issues)
                }
            )
        }
    }

    render() {
        return (
            <div className='container-fluid overflow-auto flex-grow-1' onClick={ (event) => { this.handleClickOutside(event) } }>
                <div className={`loaderOverlay ${this.state.showLoading ? '' : 'displayNone'}`}>
					<div className="overlayEqualizerLoader">
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
						<div className="spinner-item"></div>
					</div>
				</div>
				<div className='row h-100'>
					<div className='col-sm-12 p-0'>
						<div className='title-section p-2'>
							<div className="row">
								<div className="col-5 mt-0">
									<h6 className="text-white m-0">DLP Dashboard</h6>
									<p className="text-white m-0">Count board view of DLP on your Cloud</p>
									<div className="row mt-3">
                              			<div className={`col-md-12 d-md-flex bd-highlight ${this.state.showLoading ? 'disabled' : ''}`}>
											<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1`} ref={this.providerRef} style={{maxWidth: '150px' }}>
												<label className="mb-1">Provider</label>
												<Select
													placeholder={'All'}
													menuIsOpen={this.state.providerIsOpen}
													isSearchable={false}
													//onFocus={this.openProivderMenu}
													className='f13 p-0 colorBlack reactSelectFilterDrop'
													value={({
														value: this.state.selectedProvider,
														label: this.state.selectedProvider
													})}
													options={this.state.providers.map(item => ({
														value: item.provider_name,
														label: item.provider_name,	
													}))}
													onChange={event =>  
														this.setState(
															{
																selectedProvider: event.value,
																changesInFilter: true
															}
														)
													}
												/>
											</div>
											<div className={`form-group cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 zIndex1 ${!this.state.providers.length}`} ref={this.accountRef} style={{maxWidth: '150px' }}>
												<label className="mb-1">Account</label>
												<div className='dlpHeaderMultiSelection removeDropdownSearchBar'>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="All"
														menuIsOpen ={this.state.isAccountOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('account', this.state.selectedAccount)}
														value={this.state.selectedAccountName.map(user => ({
															value: user.value,
															label: user.label
														}))}
														onChange={arr => {
														this.handleMultiSelectChange('selectedAccount', arr ? arr : []) }}
														options={this.state.accounts.map(account => ({
															value: account.account_id,
															label: account.account_name,
														}))}
													/>
												</div>
											</div>
											<div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild mr-sm-3 ${!this.state.providers.length}`} ref={this.regionRef} style={{maxWidth: '150px'}}>
												<label className="mb-1">Region</label>
												<div className={`dlpHeaderMultiSelection ${this.state.regions && this.state.regions.length > 10 ? '' : 'removeDropdownSearchBar' } `}>
													<ReactMultiSelectCheckboxes						
														placeholderButtonLabel="Select"
														menuIsOpen ={this.state.isRegionOpen}
														getDropdownButtonLabel={() => this.getMultiSelectedCount('region', this.state.selectedRegion)}
														value={this.state.selectedRegionName.map(user => ({
															value: user.value,
															label: user.label
														}))}
														onChange={arr => {
														this.handleMultiSelectChange('selectedRegion', arr ? arr : []) }}
														options={this.state.regions && this.state.regions.map(region => ({
															value: region.region,
															label: region.name,
														}))}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-7 text-right">
									<div className={`d-flex justify-content-end align-self-center`}>
										<div className={`d-flex justify-content-between align-self-end`}>
											{/* <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 365 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 365 }, () => this.sliderPeriod())}>1 Year</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 180 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 180 }, () => this.sliderPeriod())}>6 Month</span> */}
										
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 90 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 90 }, () => this.sliderPeriod())}>3 Months</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 30 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 30 }, () => this.sliderPeriod())}>1 Month</span>
											<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${this.state.selectedFilterPeriod === 7 ? 'badge badge-primary text-white' : 'text-filterBlue'} `} onClick={() => this.setState({ selectedFilterPeriod: 7 }, () => this.sliderPeriod())}>1 Week</span>
										</div>
										<div className={`bg-filterBlue text-white align-self-end p-1 f12 ml-2`}>
											{getPastDaysNew(this.state.sliderStartDate, this.state.sliderEndDate)}
										</div>
										<div className="align-self-center ml-3">
											<Datetime 
												value={momentDateGivenFormat(this.state.startSlice, 'DD MMM YYYY hh:mm A')}
												onChange={this.handleStartTimeChange}
												dateFormat={'DD MMM YYYY'}
												timeFormat={'hh:mm A'}
												inputProps={{ readOnly: true, disabled: this.state.showLoading }}
												className="w-100 mr-1 dateSliderInputReszie"
												isValidDate={previousDay()}
												closeOnSelect={true}
											/>
											<Datetime
												value={momentDateGivenFormat(this.state.endSlice, 'DD MMM YYYY hh:mm A')}
												onChange={this.handleEndTimeChange}
												dateFormat={'DD MMM YYYY'}
												timeFormat={'hh:mm A'}
												inputProps={{ readOnly: true, disabled: this.state.showLoading }}
												className="w-100 dateSliderInputReszie"
												isValidDate={previousDay()}
												closeOnSelect={true}
											/>
										</div>
										<div className={`ml-2 align-self-center`}>
											<div className="btn-group" role="group" aria-label="dlp-dash">
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right`} onClick={() => this.onSearch() }>Search</button>
												<button type="button" className={`${this.state.showLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left`} onClick={() => this.onReset()}><i className='far fa-redo'></i></button>
											</div>
										</div>	
									</div>
								
									{this.state.datePickerSliderOptions && this.state.datePickerSliderSeries ?
										<div className={`w-100 mb-n3 ${this.state.showLoading ? 'disabled' : ''}`} >
											<Chart options={this.state.datePickerSliderOptions} series={this.state.datePickerSliderSeries} type="bar" height={80} />
										</div>
									: null}	
									<div className="d-flex">
										<div id="leftSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderStartDate, 'DD MMM')}</div>
										<div id="rightSliderSpan" className="f12">{momentDateGivenFormat(this.state.sliderEndDate, 'DD MMM')}</div>
									</div>
								</div>
							</div>
						</div>
                        <div className={`bg-background`}>
							<div className={`box p-3 rounded-0 shadow-none bg-transparent`}>
								<div className="row">
									<div className="col-sm-12">
                                        <div className="bg-muted p-3 rounded mb-3 ">
                                            <div className="row text-white">
                                                <div className="col-md-4">
                                                    <div className="bg-muted p-3 rounded counts_threads h-100">
                                                        <div className="row">
                                                            <div className="col-lg-12" ref={this.radialStackedBarRef}>
                                                                <p className="f16 mb-1" onClick={() => this.setState({ showOverallRadialChart: false, selectedBucketState: '' }, () => this.renderOverallData())}><b>Threats Count</b></p>
                                                                <p className="small text-secondary-50"><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</b></p>
                                                                {this.state.showOverallRadialChart ?
                                                                    <div className="row">
                                                                        <StackedCircleBarChart
                                                                            ID={'overallStackbarChart'}
                                                                            svgID={'svgOverallStackbarChart'}
                                                                            width={this.radialStackedBarRef.current.offsetWidth}
                                                                            data={this.state.overallArray}
                                                                            keys={this.state.overallKeys}
                                                                            highestValue={this.state.highestOverall}
                                                                            hoverData={this.state.totalOverallData}
                                                                            totalValue={this.state.totalOverall}
                                                                            selectedState={this.state.selectedBucketState ? this.state.selectedBucketState : ''}
                                                                            tableData={(data, State) => 
                                                                                {this.setState({ 
                                                                                    selectedBucketData: data, 
                                                                                    // selectedBucketState: State ? State : (this.state.selectedBucketState ? this.state.selectedBucketState : ''),
                                                                                },
                                                                                    () => {
                                                                                        if(State) {
                                                                                            this.setState({ 
                                                                                                showOverallRadialChart: false,
                                                                                                show_ueba_data: false,
                                                                                                show_threats_data: false,
                                                                                                show_malware_data: false,
                                                                                                show_compliance_data: false,
                                                                                                selectedBucketState: this.state.selectedBucketState !== State ? State : ''
                                                                                            },
                                                                                                () => {
                                                                                                    if(this.state.selectedBucketState) {
                                                                                                        this.renderOnclickdata()
                                                                                                    } else {
                                                                                                        this.renderOverallData()
                                                                                                    }
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                )}
                                                                            }
                                                                            legendText={'Threats'}
                                                                            // colors={["#24a597", "#009da7", "#0091b7", "#008abc"]}
                                                                            // ['#24a597', '#10a49a', '#00a29e', '#00a0a1', '#009fa4', '#009da7', '#009baa', '#0099ad', '#0098b0', '#0096b3', '#0093b5', '#0091b7', '#008fb9', '#008dba', '#008abc', '#0088bd', '#0085bd', '#0082bd', '#0080bd', '#1d7dbd', '#2d7abc', '#3977bb', '#4373b9', '#4d70b7', '#556db4', '#5d69b1', '#6466ae', '#6b62aa', '#715fa6', '#775ba2']	
                                                                            // backgroundColor={'#F5F5F5'}
                                                                            colors={["#039BE5", "#519cc2", "#6466ae", "#775ba2"]}
                                                                        />
                                                                    </div>
                                                                : null }
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 bg-dark-50 rounded py-3">
                                                            <div className="d-flex flex-column">
                                                                <div className="d-flex text-light-50">
                                                                    <h2 className="mr-2">394</h2>
                                                                    <p className="align-self-end"><b>Consectetur adipiscing elit, sed do</b></p>
                                                                </div>
                                                                <p className="small text-secondary-50"><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</b></p>
                                                                <div className='w-100 ml-n2'>
                                                                    <ApexAreaChart 
                                                                        graphData={'mockData'}
                                                                        name={'Threats'}
                                                                        xaxis={false}
                                                                        yaxis={true}
                                                                        yaxisLabe={false}
                                                                        markerSize={2}
                                                                        hideMarkerLength={45}
                                                                        gradient={true}
                                                                        gradientColor={['#9A77D1', '#fff']}
                                                                        colors={['#9A77D1']}
                                                                        sparkline={false}
                                                                        zoom={false}
                                                                        height={90}
                                                                        width={'100%'}
                                                                        grid={true}
                                                                        className={"transparentTooltip"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                        <div className="d-flex text-warning my-3">
                                                            <h4 className="mb-0 mr-3">0325</h4>
                                                            <h3 className="mb-0 mr-2 align-self-center"><i className="fad fa-map-marker-alt"></i>
                                                            </h3>
                                                            <h3 className="mb-0 mr-2 align-self-center"><i className="fad fa-map-marker-alt"></i>
                                                            </h3>
                                                            <h3 className="mb-0 mr-2 align-self-center"><i className="fad fa-map-marker-alt"></i>
                                                            </h3>
                                                            <div className="flex-row ml-1 align-self-center">
                                                                <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                                <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 pl-2">
                                                    <div className="row text-white mb-3">
                                                        <div className="col-md-6 pr-0">
                                                            <div className="bg-dark p-3 rounded mb-3">
                                                                <div className="col-md-12 p-0">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div>
                                                                                    <div className="d-flex">
                                                                                        <p className="mb-1 pr-3"><b>UEBA</b></p>
                                                                                        <p className="text-info cursorPointer" onClick={() => this.redirect(URL_PATH.DLP_UEBA)}>Details</p>
                                                                                    </div>
                                                                                    <p className={`small text-secondary-50 ${this.state.show_ueba_data ? 'mb-1' : ''}`}><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit</b></p>
                                                                                </div>
                                                                                {this.state.show_ueba_data ?
                                                                                    <div className="bg-muted p-1 rounded counts_threads">
                                                                                        <div className="d-flex">
                                                                                            <h5 className="mr-2 mb-0">{this.state.ueba_count ? this.state.ueba_count : ''}</h5>
                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <p className="mr-2 mb-0">
                                                                                                <span className="far fa-arrow-up text-success"></span>
                                                                                                <b className="small">{this.state.ueba_week_text ? this.state.ueba_week_text : ''}</b>
                                                                                            </p>
                                                                                            <p className="mb-0">
                                                                                                <span className="far fa-arrow-down text-danger"></span>
                                                                                                <b className="small">{this.state.ueba_month_text ? this.state.ueba_month_text : ''}</b>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                : null} 
                                                                            </div>
                                                                            {this.state.show_ueba_data && this.state.ueba_trend ? 
                                                                                <div className='w-100 ml-n2'>
                                                                                    <ApexAreaChart 
                                                                                        graphData={this.state.ueba_trend}
                                                                                        name={'Anomaly'}
                                                                                        xaxis={false}
                                                                                                 yaxis={true}
                                                                                        yaxisLabe={false}
                                                                                        markerSize={2}
                                                                                        hideMarkerLength={45}
                                                                                        gradient={true}
                                                                                        gradientColor={['#9A77D1', '#fff']}
                                                                                        colors={['#9A77D1']}
                                                                                        sparkline={false}
                                                                                        zoom={false}
                                                                                        height={90}
                                                                                        width={'100%'}
                                                                                        grid={true}
                                                                                        className={"transparentTooltip"}
                                                                                    />
                                                                                </div>
                                                                            : null}
                                                                            <div className="row mb-n4">
                                                                                <div className="col-md-8" ref={this.uebaDivRef}>
                                                                                    {this.state.show_ueba_data ?
                                                                                        <React.Fragment>
                                                                                            <p className="mb-2"><b>Anomaly</b></p>
                                                                                            <HorizontalLimitLollipopChart
                                                                                                width={this.uebaDivRef.current.offsetWidth - 20}
                                                                                                height={260}
                                                                                                ID={'uebaLollipop'}
                                                                                                graphData={this.state.ueba_trend_data ? this.state.ueba_trend_data : {}}
                                                                                                showEndPointCircle={false}
                                                                                                dashboardTooltipHeight={90}
                                                                                                colors={['#039BE5']}
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    : null}
                                                                                </div>
                                                                                <div className="col-md-4 d-flex justify-content-center">
                                                                                    
                                                                                    {this.state.show_ueba_data ?
                                                                                        <div className="">
                                                                                            <div className="mb-2">
                                                                                                <ApexDonutChart
                                                                                                    graphData={this.state.ueba_risk}
                                                                                                    legend={false}
                                                                                                    showTotal={true}
                                                                                                    showTotalLable={true}
                                                                                                    showGraphHeading={true}
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                    size={'70%'}
                                                                                                    gradient={false}
                                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                                    colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                                                                                    className={"transparentTooltip"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="">
                                                                                                <ApexDonutChart
                                                                                                    graphData={this.state.ueba_status}
                                                                                                    legend={false}
                                                                                                    showTotal={true}
                                                                                                    showTotalLable={true}
                                                                                                    showGraphHeading={true}
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                    size={'70%'}
                                                                                                    gradient={false}
                                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                                    colors={['#24A597', '#353C4C']}
                                                                                                    className={"transparentTooltip"}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="bg-dark p-3 rounded mb-3">
                                                                <div className="col-md-12 p-0">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div>
                                                                                    <div className="d-flex">
                                                                                        <p className="mb-1 pr-3"><b>Threats</b></p>
                                                                                        <p className="text-info cursorPointer" onClick={() => this.redirect(URL_PATH.DLP_THREATS)}>Details</p>
                                                                                    </div>
                                                                                    <p className={`small text-secondary-50 ${this.state.show_threats_data ? 'mb-1' : ''}`}><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit</b></p>
                                                                                </div>
                                                                                {this.state.show_threats_data ?
                                                                                    <div className="bg-muted p-1 rounded counts_threads">
                                                                                        <div className="d-flex">
                                                                                            <h5 className="mr-2 mb-0">{this.state.threats_count ? this.state.threats_count : ''}</h5>
                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <p className="mr-2 mb-0">
                                                                                                <span className="far fa-arrow-up text-success"></span>
                                                                                                <b className="small">{this.state.threats_week_text ? this.state.threats_week_text : ''}</b>
                                                                                            </p>
                                                                                            <p className="mb-0">
                                                                                                <span className="far fa-arrow-down text-danger"></span>
                                                                                                <b className="small">{this.state.threats_month_text ? this.state.threats_month_text : ''}</b>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                : null}
                                                                            </div>
                                                                            {this.state.show_threats_data && this.state.threats_trend ? 
                                                                                <div className='w-100 ml-n2'>
                                                                                    <ApexAreaChart 
                                                                                        graphData={this.state.threats_trend}
                                                                                        name={'Threats'}
                                                                                        xaxis={false}
                                                                                        yaxis={true}
                                                                                        yaxisLabe={false}
                                                                                        markerSize={2}
                                                                                        hideMarkerLength={45}
                                                                                        gradient={true}
                                                                                        gradientColor={['#9A77D1', '#fff']}
                                                                                        colors={['#9A77D1']}
                                                                                        sparkline={false}
                                                                                        zoom={false}
                                                                                        height={90}
                                                                                        width={'100%'}
                                                                                        grid={true}
                                                                                        className={"transparentTooltip"}
                                                                                    />
                                                                                </div>
                                                                            : null}
                                                                            
                                                                            <div className="row mb-n4">
                                                                                <div className="col-md-8" ref={this.uebaDivRef}>
                                                                                    {this.state.show_threats_data ?
                                                                                        <React.Fragment>
                                                                                            <p className="mb-2"><b>Threats</b></p>
                                                                                            <HorizontalLimitLollipopChart
                                                                                                width={this.uebaDivRef.current.offsetWidth - 20}
                                                                                                height={260}
                                                                                                ID={'threatsLollipop'}
                                                                                                graphData={this.state.threats_trend_data ? this.state.threats_trend_data : {}}
                                                                                                showEndPointCircle={false}
                                                                                                dashboardTooltipHeight={90}
                                                                                                colors={['#039BE5']}
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    : null}
                                                                                </div>
                                                                                <div className="col-md-4 d-flex justify-content-center">
                                                                                    {this.state.show_threats_data ?
                                                                                        <div className="">
                                                                                            <div className="mb-2">
                                                                                                <ApexDonutChart
                                                                                                    graphData={this.state.threats_risk}
                                                                                                    legend={false}
                                                                                                    showTotal={true}
                                                                                                    showTotalLable={true}
                                                                                                    showGraphHeading={true}
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                    size={'70%'}
                                                                                                    gradient={false}
                                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                                    colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                                                                                    className={"transparentTooltip"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="">
                                                                                                <ApexDonutChart
                                                                                                    graphData={this.state.threats_status}
                                                                                                    legend={false}
                                                                                                    showTotal={true}
                                                                                                    showTotalLable={true}
                                                                                                    showGraphHeading={true}
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                    size={'70%'}
                                                                                                    gradient={false}
                                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                                    colors={['#24A597', '#353C4C']}
                                                                                                    className={"transparentTooltip"}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 pr-0">
                                                            <div className="bg-dark p-3 rounded mb-3">
                                                                <div className="col-md-12 p-0">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div>
                                                                                    <div className="d-flex">
                                                                                        <p className="mb-1 pr-3"><b>Malware</b></p>
                                                                                        <p className="text-info cursorPointer" onClick={() => this.redirect(URL_PATH.DLP_MALWARE)}>Details</p>
                                                                                    </div>
                                                                                    <p className={`small text-secondary-50 ${this.state.show_malware_data ? 'mb-1' : ''}`}><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit</b></p>
                                                                                </div>
                                                                                {this.state.show_malware_data ?
                                                                                    <div className="bg-muted p-1 rounded counts_threads">
                                                                                        <div className="d-flex">
                                                                                            <h5 className="mr-2 mb-0">{this.state.malware_count ? this.state.malware_count : ''}</h5>
                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <p className="mr-2 mb-0">
                                                                                                <span className="far fa-arrow-up text-success"></span>
                                                                                                <b className="small">{this.state.malware_week_text ? this.state.malware_week_text : ''}</b>
                                                                                            </p>
                                                                                            <p className="mb-0">
                                                                                                <span className="far fa-arrow-down text-danger"></span>
                                                                                                <b className="small">{this.state.malware_month_text ? this.state.malware_month_text : ''}</b>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                : null}
                                                                            </div>
                                                                            {this.state.show_malware_data && this.state.malware_trend ? 
                                                                                <div className='w-100 ml-n2'>
                                                                                    <ApexAreaChart 
                                                                                        graphData={this.state.malware_trend}
                                                                                        name={'Malwares'}
                                                                                        xaxis={false}
                                                                                        yaxis={true}
                                                                                        yaxisLabe={false}
                                                                                        markerSize={2}
                                                                                        hideMarkerLength={45}
                                                                                        gradient={true}
                                                                                        gradientColor={['#9A77D1', '#fff']}
                                                                                        colors={['#9A77D1']}
                                                                                        sparkline={false}
                                                                                        zoom={false}
                                                                                        height={90}
                                                                                        width={'100%'}
                                                                                        grid={true}
                                                                                        className={"transparentTooltip"}
                                                                                    />
                                                                                </div>
                                                                            : null}
                                                                            <div className="row mb-n4">
                                                                                <div className="col-md-8" ref={this.uebaDivRef}>
                                                                                    {this.state.show_malware_data ?
                                                                                        <React.Fragment>
                                                                                            <p className="mb-2"><b>Malwares</b></p>
                                                                                            <HorizontalLimitLollipopChart
                                                                                                width={this.uebaDivRef.current.offsetWidth - 20}
                                                                                                height={260}
                                                                                                ID={'malwareLollipop'}
                                                                                                graphData={this.state.malware_trend_data ? this.state.malware_trend_data : {}}
                                                                                                showEndPointCircle={false}
                                                                                                dashboardTooltipHeight={90}
                                                                                                colors={['#FFD200']}
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    : null}
                                                                                </div>
                                                                                <div className="col-md-4 d-flex justify-content-center">
                                                                                    {this.state.show_malware_data ?
                                                                                        <div className="">
                                                                                            <div className="mb-2">
                                                                                                <ApexDonutChart
                                                                                                    graphData={this.state.malware_risk}
                                                                                                    legend={false}
                                                                                                    showTotal={true}
                                                                                                    showTotalLable={true}
                                                                                                    showGraphHeading={true}
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                    size={'70%'}
                                                                                                    gradient={false}
                                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                                    colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                                                                                    className={"transparentTooltip"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="">
                                                                                                <ApexDonutChart
                                                                                                    graphData={this.state.malware_compliances}
                                                                                                    legend={false}
                                                                                                    showTotal={true}
                                                                                                    showTotalLable={true}
                                                                                                    showGraphHeading={true}
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                    size={'70%'}
                                                                                                    gradient={false}
                                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                                    colors={['#24A597', '#353C4C']}
                                                                                                    className={"transparentTooltip"}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 pr-0">
                                                            <div className="bg-dark p-3 rounded mb-3">
                                                                <div className="col-md-12 p-0">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div>
                                                                                    <div className="d-flex">
                                                                                        <p className="mb-1 pr-3"><b>Compliance</b></p>
                                                                                        <p className="text-info cursorPointer" onClick={() => this.redirect(URL_PATH.DLP_COMPLIANCE)}>Details</p>
                                                                                    </div>
                                                                                    <p className={`small text-secondary-50 ${this.state.show_compliance_data ? 'mb-1' : ''}`}><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit</b></p>
                                                                                </div>
                                                                                {this.state.show_compliance_data ?
                                                                                    <div className="bg-muted p-1 rounded counts_threads">
                                                                                        <div className="d-flex">
                                                                                            <h5 className="mr-2 mb-0">{this.state.compliance_count ? this.state.compliance_count : ''}</h5>
                                                                                        </div>
                                                                                        <div className="d-flex">
                                                                                            <p className="mr-2 mb-0">
                                                                                                <span className="far fa-arrow-up text-success"></span>
                                                                                                <b className="small">{this.state.compliance_week_text ? this.state.compliance_week_text : ''}</b>
                                                                                            </p>
                                                                                            <p className="mb-0">
                                                                                                <span className="far fa-arrow-down text-danger"></span>
                                                                                                <b className="small">{this.state.compliance_month_text ? this.state.compliance_month_text : ''}</b>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                : null}
                                                                            </div>
                                                                            {this.state.show_compliance_data && this.state.compliance_trend ? 
                                                                                <div className='w-100 ml-n2'>
                                                                                    <ApexAreaChart 
                                                                                        graphData={this.state.compliance_trend}
                                                                                        name={'Violations'}
                                                                                        xaxis={false}
                                                                                        yaxis={true}
                                                                                        yaxisLabe={false}
                                                                                        markerSize={2}
                                                                                        hideMarkerLength={45}
                                                                                        gradient={true}
                                                                                        gradientColor={['#9A77D1', '#fff']}
                                                                                        colors={['#9A77D1']}
                                                                                        sparkline={false}
                                                                                        zoom={false}
                                                                                        height={90}
                                                                                        width={'100%'}
                                                                                        grid={true}
                                                                                        className={"transparentTooltip"}
                                                                                    />
                                                                                </div>
                                                                            : null}
                                                                            <div className="row mb-n4">
                                                                                <div className="col-md-8" ref={this.uebaDivRef}>
                                                                                    {this.state.show_compliance_data ?
                                                                                        <React.Fragment>
                                                                                            <p className="mb-2"><b>Violations</b></p>
                                                                                            <HorizontalLimitLollipopChart
                                                                                                width={this.uebaDivRef.current.offsetWidth - 20}
                                                                                                height={260}
                                                                                                ID={'complianceLollipop'}
                                                                                                graphData={this.state.compliance_trend_data ? this.state.compliance_trend_data : {}}
                                                                                                showEndPointCircle={false}
                                                                                                dashboardTooltipHeight={90}
                                                                                                colors={['#FFD200']}
                                                                                            />
                                                                                        </React.Fragment>
                                                                                    : null}
                                                                                </div>
                                                                                <div className="col-md-4 d-flex justify-content-center">
                                                                                    {this.state.show_compliance_data ?
                                                                                        <div className="">
                                                                                            <div className="mb-2">
                                                                                                <ApexDonutChart
                                                                                                    graphData={this.state.compliance_risk}
                                                                                                    legend={false}
                                                                                                    showTotal={true}
                                                                                                    showTotalLable={true}
                                                                                                    showGraphHeading={true}
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                    size={'70%'}
                                                                                                    gradient={false}
                                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                                    colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                                                                                    className={"transparentTooltip"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="">
                                                                                                <ApexDonutChart
                                                                                                    graphData={this.state.compliance_status}
                                                                                                    legend={false}
                                                                                                    showTotal={true}
                                                                                                    showTotalLable={true}
                                                                                                    showGraphHeading={true}
                                                                                                    height={100}
                                                                                                    width={100}
                                                                                                    size={'70%'}
                                                                                                    gradient={false}
                                                                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                                                                    colors={['#24A597', '#353C4C']}
                                                                                                    className={"transparentTooltip"}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/**
 * Type of the props used in the co mponent
 */
DlpDashboard.propTypes = {
    getThreatDashboardData: PropTypes.func,
}


/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    return {
		providers: state.filters.providers,
		accounts: state.filters.accounts,
		regions: state.filters.regions,
	}
}

export default AppWrapper(DlpDashboard, mapStateToProps, {
    listAllProviders,
    listAllAccounts,
    listAllRegions,
    getThreatDashboardData,
})