/*************************************************
 * Tvastar
 * @exports
 * @file ApexListBarChart.js
 * @author Prakash // on 12/04/2022
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime, unitTimeConvertionDetail } from '../../../../utils/utility'
import Chart from 'react-apexcharts'


class ApexListBarChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount = () => {
        let graphData = this.props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64,77,36,34,44,10,1,3,91,45,32,3,37,21,65,45,38,59,16,3,66,85,30,62,49]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        }
        if(graphData && Object.entries(graphData).length) {
            this.drawGraph(graphData)
        }
    }

    drawGraph = (graphData) => {
        let graphLables = graphData.labels
        let data = []
        for(let x=0;x<graphLables.length;x++) {
            let dataItems = {}
            if(this.props.xaxisFormat === 'datetime') {
                dataItems.x = momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y HH:mm')
            } else if(this.props.xaxisFormat === 'date') {
                dataItems.x = momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y')
            } else {
                dataItems.x = graphLables[x]
            }
            dataItems.y  = graphData.data[x]
            data.push(dataItems)
        }
        let series = [
            {
                name: this.props.graphData.name ? this.props.graphData.name : '',
                data: data,
                unit: this.props.graphData.unit ? this.props.graphData.unit : '',
                additionalTooltip: this.props.graphData.tooltipValues ? this.props.graphData.tooltipValues : '',
                convertTime: this.props.graphData.convertTime ? this.props.graphData.convertTime : '',
            }
        ]
        
        let fillData = {}
        if(this.props.gradient) {
            fillData = {
                colors:[this.props.gradientColor ? this.props.gradientColor[0] : "#26B68F"],
                type: 'gradient', // gradient
                gradient: {
                    type: this.props.horizontal ? 'horizontal' : 'vertical', // The gradient in the horizontal direction
                    gradientToColors: [this.props.gradientColor ? this.props.gradientColor[1] : '#bee9de'], // The color at the end of the gradient
                    opacityFrom: .9, // transparency
                    opacityTo: 1,
                    stops:[0, 120]
                }
            }
        }

        let options = {
            tooltip: {
                enabled: this.props.tooltip,
                style: {
                    fontSize: '9px',
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex]
                    let labelName = capitalizeTheFirstLetterOfEachWord(w.globals.initialSeries[seriesIndex].name.replace("_", " "))
                    let unit = w.globals.initialSeries[seriesIndex].unit !== '' ? w.globals.initialSeries[seriesIndex].unit : ''
                    let additionalTooltip = w.globals.initialSeries[seriesIndex].additionalTooltip !== '' ? w.globals.initialSeries[seriesIndex].additionalTooltip[dataPointIndex] : ''
                    let convertTime = w.globals.initialSeries[seriesIndex].convertTime
                    if(unit === "seconds" && convertTime) {
                        val = unitTimeConvertionDetail(val, unit)
                    } else {
                        val = w.globals.initialSeries[0].data[dataPointIndex].y
                    }
                    let retrunData = '<div class="arrow_box">'
                    if(additionalTooltip === '' && !Object.entries(additionalTooltip).length) {
                        retrunData += ' <span>' +  w.globals.initialSeries[0].data[dataPointIndex].x + '</span> '
                        if(labelName !== '') {
                            retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
                        } else {
                            retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
                        }
                        if(unit !== '' && !convertTime) {
                            retrunData += '<span> ' + unit + '</span>'
                        }
                    } else if(additionalTooltip !== '' && Object.entries(additionalTooltip).length) {
                        if(!!(val % 1)) {
                           val = val.toFixed(2)
                        }
                        retrunData += '<div class="small">'
                        retrunData += '<span class="small text-gray3">Name</span> : '+w.globals.initialSeries[0].data[dataPointIndex].x+ '</div>'
                        Object.entries(additionalTooltip).forEach(([key, value]) => {
                            if(value !== '') {
                                retrunData += '<div class="small"> <span class="small text-gray3">'+capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))+'</span> : '+value+'</div>'
                            }
                        })
                        retrunData += '</div>'
                    }
                    retrunData += '</div>'

                    return retrunData
                }
            },				
            chart: {
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: this.props.sparkline
                },
                animations: {
                    enabled: this.props.animations
                },
                type: 'bar',
                height: 150,
                stacked: this.props.stacked,
            },
            colors: this.props.colors ? this.props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
            plotOptions: {
                bar: {
                    horizontal: this.props.horizontal,
                    barHeight: this.props.barHeight,
                    columnWidth: this.props.columnWidth ? this.props.columnWidth : '60%',
                    distributed: this.props.chartDistributed ? true : false,
                            
                },       
            },
            dataLabels: {
                enabled: false
            },     
            fill: fillData,        
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: false
                    },
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: this.props.paddingLeft ? this.props.paddingLeft : 10
                },
            },
            xaxis: {
                show: this.props.xaxis,
                tooltip: {
                    enabled: false
                },
                type: this.props.xaxisFormat !== 'string' ? 'datetime' : 'category',
                axisBorder: {
                    show: true,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: this.props.xaxisLabel,
                    style: {
                        colors: this.props.axisLabelColor,
                        fontSize: this.props.axisLabelFontSize ?  this.props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    }
                },
                
                crosshairs: {
                    show: false,
                    width: 0,
                    position: 'back',
                    opacity: 0,
                    stroke: {
                        color: '#b6b6b6',
                        width: 0,
                        dashArray: 0,
                    },
                    fill: {
                        type: 'solid',
                        color: '#B1B9C4',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        },
                    },
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 1,
                        opacity: 0.4,
                    },
                },
            },
            yaxis: {
                show: this.props.yaxis,
                // this.props.tickAmount: this.props.yaxisTickAmont ? this.props.yaxisTickAmont : 4,
                axisBorder: {
                    show: true,
                    color: '#969AA7',
                },
                labels: {
                    show: this.props.yaxisLabel,
                    style: {
                        colors: this.props.axisLabelColor,
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    }
                }
            },
            legend: {
                show: this.props.legend,
            },
        }

        this.setState({ series, options })
    }

    render() {
        return(
            this.state.series ?
                <div className={`${this.props.className}`}>
                    <Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} width={this.props.width ? this.props.width : "100%"}/>
                </div>
            : null
        )
    }
}
 
/**
 * Type of the props used in the component
 */
ApexListBarChart.propTypes = {}

const mapStateToProps = state => {}

export default connect(mapStateToProps, {})(withRouter(ApexListBarChart))