/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file RightSection.js
 * @author Prakash // on 01/12/2021
 * @copyright © 2021 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'

import ErrorDetails from './errors/ErrorDetails'
import AlertDetails from './alerts/AlertDetails'
import EventDetails from './events/EventDetails'
import AnomalyDetails from './anomaly/AnomalyDetails'

import MetricsTab from './MetricsTab'

import ErrorsTab from './errors/ErrorsTab'
import AlertsTab from './alerts/AlertsTab'
import EventsTab from './events/EventsTab'
import AnomalyTab from './anomaly/AnomalyTab'

import ErrorAnalysis from './errors/ErrorAnalysis'
import AlertAnalysis from './alerts/AlertAnalysis'
import EventAnalysis from './events/EventAnalysis'
import AnomalyAnalysis from './anomaly/AnomalyAnalysis'

class RightSection extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            selectedTab: 'Details',
            details: {},
        }
    }

    componentDidMount = () => {}

    componentDidUpdate = (prevProps) => {
        if(this.props.aiopsCommonPageFilter && Object.entries(this.props.aiopsCommonPageFilter).length) {
            if(!_.isEqual(prevProps.aiopsCommonPageFilter, this.props.aiopsCommonPageFilter)) {
                this.setState({ 
                    start_time: this.props.aiopsCommonPageFilter.start_time,
                    end_time: this.props.aiopsCommonPageFilter.end_time,
                    brushStartTime: this.props.aiopsCommonPageFilter.brushStartTime ? this.props.aiopsCommonPageFilter.brushStartTime : this.props.aiopsCommonPageFilter.start_time,
                    brushEndTime: this.props.aiopsCommonPageFilter.brushEndTime ? this.props.aiopsCommonPageFilter.brushEndTime : this.props.aiopsCommonPageFilter.end_time,
                    selectedTab: ''
                },
                    () => this.setState({ selectedTab: 'Details' })
                )
            }
        }

        if(this.props.tabOptions && Object.entries(this.props.tabOptions).length) {
            if(!_.isEqual(prevProps.tabOptions, this.props.tabOptions)) {
                this.setState({ 
                    selectedTab: this.props.tabOptions.selectedTab,
                    details: this.props.tabOptions.details,
                    merticsAssetNameInvolved: this.props.tabOptions.merticsAssetNameInvolved
                })
            }
        }
    }

    render() {
        return (
            <div className="rounded bg-dark p-3 h-100">
                <ul className={`row nav nav-tabs border-bottom bg-dark shadow-none rounded justify-content-between`} id="myTab" role="tablist">
                    {this.state.selectedTab !== 'Analysis' ?
                        <React.Fragment>
                        <span className={`d-flex ${this.state.menuDisabled ? '' : '' }`}>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Details' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Details' })}>Details</span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Metrics' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Metrics', selectedAssetId: '', menuDisabled: true })}>Metrics</span>
                            </li>
                            {this.props.pageType !== 'Alerts' ?
                                <li className="nav-item">
                                    <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Alerts' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Alerts', selectedAssetId: '', menuDisabled: true })}>Alerts</span>
                                </li>
                            : null}
                            {this.props.pageType !== 'Events' ?
                                <li className="nav-item">
                                    <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Events' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Events', selectedAssetId: '', menuDisabled: true })}>Events</span>
                                </li>
                            : null}
                            {this.props.pageType !== 'Errors' ?
                                <li className="nav-item">
                                    <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Errors' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Errors', selectedAssetId: '', menuDisabled: true })}>Errors</span>
                                </li>
                            : null}
                            {this.props.pageType !== 'Anomalies' ?
                                <li className="nav-item">
                                    <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Anomalies' ? ' active' : ''}`} onClick={() => this.setState({ selectedTab: 'Anomalies', selectedAssetId: '', menuDisabled: true })}>Anomalies</span>
                                </li>
                            : null}
                        </span>
                        {/* <li>
                            <button className='align-self-center btn btn-sm bg-gray5 mt-2 mr-2 text-white' onClick={() => this.setState({ details: {}, selectedTab: 'Analysis' }, () => this.props.updateDetail())}>Back</button>
                        </li> */}
                        </React.Fragment>
                    :
                        <li className="nav-item">
                            <span className={`nav-link text-white cursorPointer ${this.state.selectedTab === 'Analysis' ? ' active' : ''}`} onClick={() => this.setState({ details: {}, selectedTab: 'Analysis' })}>Analysis</span>
                        </li>
                    } 
                </ul>
                {this.state.selectedTab === 'Analysis' ?
                    this.props.pageType === 'Errors' ?
                        <ErrorAnalysis />
                    : this.props.pageType === 'Alerts' ?
                        <AlertAnalysis />
                    : this.props.pageType === 'Events' ?
                        <EventAnalysis />
                    : this.props.pageType === 'Anomalies' ?
                        <AnomalyAnalysis />
                    : null                    
                : this.state.selectedTab === 'Details' ?
                    this.props.pageType === 'Errors' ?
                        <ErrorDetails 
                            details= {this.state.details}
                        />
                    : this.props.pageType === 'Alerts' ?
                        <AlertDetails 
                            details= {this.state.details}
                        />
                    : this.props.pageType === 'Events' ?
                        <EventDetails 
                            details= {this.state.details}
                        />
                    : this.props.pageType === 'Anomalies' ?
                        <AnomalyDetails 
                            details= {this.state.details}
                        />
                    : null
                : this.state.selectedTab === 'Metrics' ? 
                    <MetricsTab 
                        merticsAssetNameInvolved = {this.state.merticsAssetNameInvolved}
                        brushStartTime={this.state.brushStartTime}
                        brushEndTime={this.state.brushEndTime}
                        pageType={this.props.pageType}
                        details= {this.state.details}
                        // menuDisabled= {() => this.setState({ menuDisabled: false })}
                    />
                : this.state.selectedTab === 'Errors' ? 
                    <ErrorsTab 
                        details= {this.state.details}
                        menuDisabled= {() => this.setState({ menuDisabled: false })}
                        pageType= {this.props.pageType}
                    />
                : this.state.selectedTab === 'Alerts' ? 
                    <AlertsTab 
                        details= {this.state.details}
                        menuDisabled= {() => this.setState({ menuDisabled: false })}
                        pageType= {this.props.pageType}
                    />
                : this.state.selectedTab === 'Events' ? 
                    <EventsTab 
                        details= {this.state.details}
                        menuDisabled= {() => this.setState({ menuDisabled: false })}
                        pageType= {this.props.pageType}
                    />
                : this.state.selectedTab === 'Anomalies' ?
                    <AnomalyTab 
                        details= {this.state.details}
                        menuDisabled= {() => this.setState({ menuDisabled: false })}
                        pageType= {this.props.pageType}
                    />
                : null}
            </div>
        )
    }
}

/**
 * Type of the props used in the component
 */
RightSection.propTypes = {}

/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
    // console.log('errorDashboard',state)
    return {
        accounts: state.filters.accounts,
        aiopsCommonPageFilter: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.aiopsCommonPageFilter ? state.aiops.aiopsPropsDetails.aiopsCommonPageFilter : {},
        tabOptions: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.tabOptions ? state.aiops.aiopsPropsDetails.tabOptions : {},
        commonBrushChanges: state.aiops.aiopsPropsDetails && state.aiops.aiopsPropsDetails.commonBrushChanges ? state.aiops.aiopsPropsDetails.commonBrushChanges : {},
    }
}

export default connect(mapStateToProps, {})(withRouter(RightSection))