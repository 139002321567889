/*************************************************
 * Tvastar
 * @exports
 * @file SidePanel.js
 * @author Prakash // on 28/06/2020
 * @copyright © 2020 Tvastar. All rights reserved.
 *************************************************/
 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 import { withRouter } from 'react-router-dom'
 import PropTypes from 'prop-types'
 import * as d3 from 'd3'
 
 class StackedCircleBarChart extends Component {
    constructor(props) {
        super(props)
        // this.props = props;
        this.state = {}
    }

    componentDidMount = () => {
        let legendData = [this.props.legendText, this.props.totalValue]
        let selectedState = this.props.selectedState ? this.props.selectedState : ''
        let ID = '#'+this.props.ID
		let data = this.props.data
		let keys = this.props.keys
        // let hoverContent = ''
        let margin = { top: 0, right: 10, bottom: 0, left: 0, }
        const stackGenerator = d3.stack().keys(keys);
        const layers = stackGenerator(data);

        // This is our min/max values
        // const extent = [
		// 	0,
		// 	d3.max(layers, (layer) => d3.max(layer, (sequence) => sequence[1] + 0.2))
        // ];

		let yStroke = 3
		// if(this.props.highestValue > 100) {
		// 	yStroke = 3
		// }

		var width = this.props.width ? this.props.width - margin.right : 500
        // var width = this.radialStackedBarWidthRef.current.offsetWidth
        var height = width
        // var halfWidth =  parseInt(width / 2)
        var innerRadius = 60
        var outerRadius = Math.min(width, height) / 2

        var svg = d3.select(ID)
            .append("svg")
				.attr("id",this.props.svgID)
                .attr("width", width)
                .attr("height", height)
                .style('background-color', this.props.backgroundColor ? this.props.backgroundColor :  '');

        var g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");        

        svg.attr("transform", "translate(0,0)") //to center the chart
        let tooltip = d3.select(ID).append("div").attr("class", "d3tooltip").style("opacity", 0);


        var x = d3.scaleBand()
        .domain(data.map(d => d.State))
        .range([0, 2 * Math.PI])
        .align(0)

        let maxValue = this.props.highestValue
        if(!this.props.userHighestValue) {
            maxValue = d3.max(data, d => d.total)
        }

        var y = d3.scaleRadial()
        // .domain([0, d3.max(data, d => d.total)])
        .domain([0, maxValue])
        .range([innerRadius, outerRadius]);
        
        var z = d3.scaleOrdinal()
        .domain(data.map(d => d.state))
        // .range(['#A88CCC',	'#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7'])
        // .range(['#9200e6',	'#b533ff',	'#c766ff',	'#da99ff',	'#ecccff',	'#c850ba',	'#fb74eb',	'#fc93f0',	'#fdb2f4',	'#fed1f8'])
        //.range(["#7e24b3", "#9029cc", "#a22ee6", "#bc47ff", "#c35cff", "#cb70ff", "#d285ff", "#da99ff", "#c850ba", "#e15ad2", "#fb74eb", "#fb83ed", "#fc93f0", "#fca2f2", "#fdb2f4", "#fdc1f6"])
        .range(this.props.colors)
        x.domain(data.map(function(d) { return d.State; }));
        // y.domain([0, d3.max(data, function(d) { return d.total; })]);
        y.domain([0, maxValue]);
        z.domain(keys);

        g.append("g")
        .selectAll("g")
        .data(layers)
        .enter().append("g")
            .attr("fill", function(d) { return z(d.key); }) 
        .selectAll("path")
        .data(function(d) { return d; })
        .enter().append("path")
            .attr('id', function(d) { return d.data.State })
            .style('opacity', function(d) {
                if(selectedState && selectedState !== ''){
                    if(selectedState === d.data.State) {
                        return 1
                    } else {
                        return 0.5
                    }
                } else{
                    return 1
                }
            })
			.attr('class', function(d) {
				if(d.data.name === 'empty_data') {
					return 'radialStackedCirlceChartEmptySlot'
				} else {
					return 'radialStackedCirlceChartDataSlot'
				}
			})
            .attr("d", d3.arc()
                .innerRadius(function(d) { return y(d[0]); })
                .outerRadius(function(d) { return y(d[1]); })
                .startAngle(function(d) { return x(d.data.State); })
                .endAngle(function(d) { return x(d.data.State) + x.bandwidth(); })
                .padAngle(0.01)
                .padRadius(innerRadius))
        .on("mouseover", d => {
            // .style("transform", "translate("+xPosition+"px, "+yPosition+"px)")

            let hoverData = d.path && d.path[0].__data__
            let hoverState = ''
			let hoverName = ''
            if(hoverData && hoverData.data && Object.entries(hoverData.data).length) {
				hoverName = hoverData.data.name
                hoverState = hoverData.data.State
            }

			if(hoverName !== 'empty_data') {
				tooltip.transition()
                .duration(200)
                .style("opacity", .9);

				d3.select(ID)
					.selectAll(`path`)
					.style('opacity', 0.5);
                if(hoverState && hoverState !== "") {
                    d3.select(ID)
                        .selectAll(`path[id=${hoverState}]`)
                        .style('opacity', 1);
                }

                if(selectedState && selectedState !== '') {
                    d3.select(ID)
					.selectAll(`path[id=${selectedState}]`)
					.style('opacity', 1);
                }
			}
        })
        .on("mouseout", function() {
            tooltip.transition()
            .duration(500)
            .style("opacity", 0);

            
            if(selectedState && selectedState !== '') {
                d3.select(ID)
                .selectAll(`path`)
                .style('opacity', 0.5);

                d3.select(ID)
                .selectAll(`path[id=${selectedState}]`)
                .style('opacity', 1);
            } else {
                d3.select(ID)
                .selectAll(`path`)
                .style('opacity', 1);
            }

            // this.hideHoverData();
        })
        .on("mousemove", function(event, d) {
            let hoverData = d
            let hoverValue = hoverData[1]-hoverData[0]
            let hoverTotal = 0
            // let hoverLabel = ''
            let hoverSpan = ''
            let hoverName = ''
            if(hoverData.data && Object.entries(hoverData.data).length) {
                hoverName = hoverData.data.name
                hoverTotal = hoverData.data.total
                if(!hoverData.data.page && hoverName !== "empty_data") {
                    Object.entries(hoverData.data).forEach(([key, value]) => {
                        if(hoverValue === value) {
                            // hoverLabel = key
                            hoverSpan = '<div class="title">'+hoverName+'</div><div class="content" style="d-flex"><span class="text">'+key+': '+hoverValue+'</span></div>'
                        }
                    })
                } if(hoverData.data.page === "MultipleKeys" && hoverName !== "empty_data") {
                    let hoverString = ""
                    hoverString += '<div class="title">'+hoverName+': '+hoverTotal+'</div>'
                    Object.entries(hoverData.data).forEach(([key, value]) => {
                        if(key !== "State" && key !== "name" && key !=="page" && key !== "total") {
                            hoverString += '<div class="content" style="d-flex"><span class="text">'+key+': '+value+'</span></div>'
                        }
                    })
                    hoverSpan = hoverString
                }  else if(hoverData.data.page === 'Dashboard' && hoverName !== "empty_data") {
                    hoverSpan = '<div class="title">'+hoverName+'</div><div class="content" style="d-flex"><span class="text">UEBA: '+hoverData.data.ueba+'</span></div><div class="content" style="d-flex"><span class="text">Threats: '+hoverData.data.threats+'</span></div><div class="content" style="d-flex"><span class="text">Malware: '+hoverData.data.malware+'</span></div><div class="content" style="d-flex"><span class="text">Compliance: '+hoverData.data.compliance+'</span></div>'
                }
            }

            if(hoverName === "empty_data") {
                hoverSpan = '<div class="title">Empty</div>'
            }
            // hoverContent = hoverName+' '+hoverLabel+' '+hoverValue
            
            // let content = '<div class="title">'+hoverName+'</div><div class="content" style="d-flex"><span class="marker"></span><span class="text">Name: '+hoverLabel+'</span></div><div class="content" style="d-flex"><span class="marker"></span><span class="text">Count: '+hoverValue+'</span></div>'

            let content = hoverSpan
            tooltip.html(content).style('transform', `translate(${event.offsetX + 30}px, ${event.offsetY - (height + 30)}px)`);
            // tooltip.html(hoverContent).style("left", (event.offsetX + 30) + "px").style("top", (event.offsetX - 30) + "px");
                
        })
        .on("click", (event, d) => this.showData(event, d));
        // .on("click", function(event, d) {
        //     // console.log("d", d)
        //     // console.log("d", d[1], d["data"], d[2])
        //     // console.log("event", event)
        //     let hoverData = event.path && event.path.length ? event.path[0].__data__ : ""
        //     if(hoverData === "") {
        //         let e = event.target && event.target.length ? event.target[0].__data__ : ""
        //         hoverData = e["data"] ? e["data"] : ""
        //     }
        //     if(hoverData === "") {
        //         hoverData = d["data"] ? d["data"] : ""
        //     } 
        //     // console.log(d[2])
        //     // d.forEach(row => {
        //     //     Object.entries(row).forEach(([key, value]) => {
        //     //         console.log(key, value)
        //     //     })
        //     //     // console.log("row", row)
        //     //     // console.log(row, typeof row)
        //     //     // if(typeof row === 'object') {
        //     //     //     console.log(row)
        //     //     // }
        //     // })
        //     // let hoverData = d.data
        //     console.log("hoverData", hoverData)
        //     if(hoverData && hoverData.name !== 'empty_data') {
		// 		this.showData(hoverData)
		// 	}
        // });   
        
        // g.select("path")
        //     .transition()
        //     .zduration(1000)

        var yAxis = g.append("g")
        .attr("text-anchor", "middle");
    
        var yTick = yAxis
        .selectAll("g")
        .data(y.ticks(yStroke).slice(0))
        .enter().append("g");
    
        yTick.append("circle")
        .attr("fill", "none")
        .attr("stroke", "#ECECEC")
        .attr("r", y);
    
        // yTick.append("text")
        // .attr("y", function(d) { return -y(d); })
        // .attr("dy", "0.35em")
        // .attr("fill", "none")
        // .attr("stroke", "#f3f3f3")
        // .attr("stroke-width", 2)
        // .text(y.tickFormat(yStroke, "s"));
    
        yTick.append("text")
        .attr("y", function(d) { return -y(d); })
        .attr("dy", "0.35em")
        .attr("font-size", "12px")		
		.attr("fill", "#9B9898")
        .text(y.tickFormat(yStroke, "s"));

        // yAxis.append("text")
        // .attr("y", function(d) { return -y(y.ticks(2).pop()); })
        // .attr("dy", "-1em")
		// .attr("font-size", "14px")
		// .attr("font-weight", "bold")
        // .text("Top Hits in the Bucket");

		// var legend = g.append("g").selectAll("g")
		// 	.data('D')
		// 	.enter().append("g")
		// 		.attr("transform", "translate(-00," + 1 * 20 + ")");

		// legend.append("text")
		// 	// .attr("x", "-5%")
		// 	.attr("y", "9")
		// 	.attr("dy", "0.35em")
		// 	.attr("font-size", "14px")
		// 	.attr("font-weight", "bold")
		// 	.attr('fill', '#9B9898')
        //     .attr("text-anchor", "middle")
		// 	.text(this.props.legendText)

		// legend.append("text")
		// 	// .attr("x", "-5%")
		// 	.attr("y", "9")
		// 	.attr("dy", "0.35em")
		// 	.attr("font-size", "14px")
		// 	.attr("font-weight", "bold")
		// 	.attr('fill', '#9B9898')
        //     .attr("text-anchor", "middle")
		// 	.text(this.props.totalValue);

        var legend = g.append("g").selectAll("g")
            .data(legendData)
            .join("g")
                .attr("transform", (d, i) => `translate(-20, ${i * 10})`)
            //   .attr("transform", (d, i) => `translate(-40,${(i - (legendData - 1) / 2) * 20})`)
              .call(g => g.append("text")
                    .attr("x", 24)
                    .attr("y", (d, i) =>  ((i - (legendData.length - 1)) * 9))
                    .attr("dy", "0.35em")
                    .attr("font-size", "14px")
                    .attr("font-weight", "bold")
                    .attr('fill', '#9B9898')
                    .attr("text-anchor", "middle")
                    .text(d => d))

		this.showHoverData(this.props.hoverData)
    }

    showData = (event, d) => {
        let hoverData = event.path && event.path.length ? event.path[0].__data__ : ""
        if(hoverData === "") {
            let e = event.target && event.target.length ? event.target[0].__data__ : ""
            hoverData = e["data"] ? e["data"] : ""
        }
        if(hoverData === "") {
            hoverData = d["data"] ? d["data"] : ""
        }
        if(hoverData && hoverData.name !== 'empty_data') {
            let showData = hoverData
            if(Object.entries(hoverData).length) {
                let State = hoverData.State
                this.props.tableData(showData, State)
            }
        }
    }

	showHoverData = (hoverData, ID) => {
		let tableData = hoverData.data ? hoverData.data : {}
		if(hoverData !== '' && hoverData.data.name !== "empty_data" && hoverData.data && Object.entries(hoverData.data).length) {
            let State = hoverData.data.State
			this.setState({ tableData },
				() => {
					this.props.tableData(tableData, State)
				}	
			)
		}
	}
 
    render() {		
        return (
            <div id={this.props.ID} />
        )
    }
}
 
 /**
  * Type of the props used in the component
  */
 StackedCircleBarChart.propTypes = {
	tableData: PropTypes.func
 }
 
 const mapStateToProps = state => {}
 
 export default connect(mapStateToProps, {})(withRouter(StackedCircleBarChart))
 
 
 
 