/*************************************************
 * Tvastar
 * @exports
 * @file PrimaryMetricsSection  .js
 * @author Prakash // on 14/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, addHours, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertBytesWithoutUnit, convertSeconds, convertSecondsWithoutUnit, countFormater, countFormaterWithoutUnit, convertBits, convertBitsWithoutUnit, subDays, getDayFromSelectedDuration, subHours, twoDateDiffrenceDayHoursMinutes } from '../../../utils/utility'
import Chart from 'react-apexcharts'
import { getSreDetails } from '../../../actions/aiops/DiagnosticsAction'

import { getMonitoringMetricsByTrend, getMonitoringMetricsByTrendV2 } from '../../../actions/aiops/MetricsAction'
import { setDiagnosticsPropsDetails } from '../../../actions/aiops/DiagnosticsAction'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class PrimaryMetricsSection extends Component {
    constructor(props) {
        super(props)
        this.annotationTypeRef = React.createRef()
        this.state = {
            showLoading: true,
            sreMetricsDetails: [],
            show_latency: true,
            show_error_rate: true,
            showAnnotation: true,
            showPrimaryMertics: true,
            showSecondaryMertics: true,
        }
    }
    
    componentDidMount = () => {
        this.setState({ metricsStartTime: this.props.startTime, metricsEndTime: this.props.endTime },
            () => this.onLoad()    
        )
        
    }

    onLoad = () => {
        if(this.props.metricsType !== 'SRE Observations') {
            if(!this.props.assetDetails || !this.props.assetDetails.resource_type || this.props.assetDetails.resource_type !== 'EC2') {

                let savedSreMetrics = this.props.diagnosticRcaSreMertrics
                if(Object.entries(savedSreMetrics).length) {
                    if(savedSreMetrics.hasOwnProperty(this.props.assetDetails.asset_id)) {
                        this.setState({ 
                            sreMetricsDetails: savedSreMetrics[this.props.assetDetails.asset_id],
                            showLoading: false
                        },
                            () => {
                                this.formSreMetricsLatencyGraph()
                                this.formSreMetricsRateGraph()
                            }
                        )
                    } else {
                        this.getSreDetails()
                    }
                } else {
                    this.getSreDetails()
                }
            }
        }
        
        // let savedMonMetrics = this.props.diagnosticRcaMonitMertrics
        // if(Object.entries(savedMonMetrics).length) {
        //     if(savedMonMetrics.hasOwnProperty(this.props.assetDetails.asset_id)) {
        //         this.setState({ metricsByTrend: savedMonMetrics[this.props.assetDetails.asset_id], showLoading: false },
        //             () => {
        //                 this.metricsListGraph()
        //             }
        //         )
        //     } else {
        //         this.getMonitoringMetricsByTrend()
        //     }
        // } else {
        //     this.getMonitoringMetricsByTrend()
        // }

        // let secondaryMonMetrics = this.props.diagnosticRcaSecondaryMonitMertrics
        // if(Object.entries(secondaryMonMetrics).length) {
        //     if(secondaryMonMetrics.hasOwnProperty(this.props.assetDetails.asset_id)) {
        //         this.setState({ secondaryMetricsByTrend: secondaryMonMetrics[this.props.assetDetails.asset_id], showLoading: false },
        //             () => {
        //                 this.secondaryMetricsListGraph()
        //             }
        //         )
        //     } else {
        //         this.getSecondaryMonitoringMetricsByTrend()
        //     }
        // } else {
        //     this.getSecondaryMonitoringMetricsByTrend()
        // }
        this.getMonitoringMetricsByTrend()
        this.getSecondaryMonitoringMetricsByTrend()
        this.getProcessMonitoringMetrics()
    }

    getSreDetails = () => {
        if(this.props.assetDetails.asset_id) {
            let params = {}
            params.asset_id = this.props.assetDetails.asset_id
            params.start_time = this.state.metricsStartTime
            params.end_time = this.state.metricsEndTime


            this.props.getSreDetails(params, (promise, response) => {
                if(promise) {
                    this.setState({ 
                        sreMetricsDetails: response,
                        showLoading: false
                    },
                        () => {
                            this.formSreMetricsLatencyGraph()
                            this.formSreMetricsRateGraph()
                            let obj = {}
                            obj = this.props.diagnosticRcaSreMertrics
                            obj[this.props.assetDetails.asset_id] = response
                            this.props.setDiagnosticsPropsDetails('diagnosticRcaSreMertrics', obj)
                        }
                    )
                } else {
                    this.setState({ sreMetricsDetails: [], showLoading: false })                    
                }
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    formSreMetricsLatencyGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) {
            let filteredSreDetails = this.state.sreMetricsDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates
                
                let yDetails = []
                let series =[]
                let colorInc = 0
                graphData.latency &&  Object.entries(graphData.latency).forEach(([key, item], j) => {
                    let yRow = {}
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }
                    if(item.values && item.values.length) {
                        if(!colorInc) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    formatter: (value) => { 
                                        return value.toFixed(2)
                                    },
                                }
                            }
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        yDetails.push(yRow)
                        
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit && item.unit !== '') {
                                val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            }
                            dataArray.push(val)
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)

                        colorInc++;
                    }
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    // xaxisAnnotation = [{
                    //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                    //     strokeDashArray: 0,
                    //     borderColor: '#FF6F00',
                    //     label: {
                    //         borderColor: '#FF6F00',
                    //         style: {
                    //             // fontSize: '12px',
                    //             color: '#fff',
                    //             background: '#FF6F00'
                    //         },
                    //         orientation: 'horizontal',
                    //         text: '',
                    //     }
                    // }
                    // ]
                }
                
                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        type: 'line',
                        // id: 'chart_latency_'+index,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },                        
                        sparkline: {
                            enabled: false
                        },
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [2, 3, 4],
                        curve: 'smooth',
                        // dashArray: [0, 8, 5]
                    },
                    xaxis: {
                        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: this.props.xaxis,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        }
                    },
                    yaxis: yDetails,
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },
                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },                    
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                        // horizontalAlign: 'left',
                        // offsetX: 40
                    },
                    annotations: annotations
                }

                this.setState({ ['metrics_series_'+index]: series, ['metrics_options_'+index]: options })
            })
        }
    }

    formSreMetricsRateGraph = () => {
        let colors = ['#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC', '#24A597', '#775BA2', '#CBF2D5', '#693EBC']
        if(this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) {
            let filteredSreDetails = this.state.sreMetricsDetails
            filteredSreDetails.forEach((res, index) => {
                let graphData = res.data
                let graphLabels = res.dates

                let series = []
                
                let  strokeWidth = []
                let x = 0
                
                let yDetails = []
                let yRow = {}
                Object.entries(graphData.error_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }
                    if(item.values && item.values.length) {
                        if(!x) {
                            yRow = {
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                tickAmount: 2,
                                min: 0,
                                labels: {
                                    offsetX: -10,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                },
                                formatter: (value) => { 
                                    // console.log(value)
                                    if(Number(value) === value && value % 1 === 0) {
                                        return Math.ceil(value)
                                    } else {
                                        return value.toFixed(2)
                                    }
                                },
                            }
                            
                        } else {
                            yRow = {
                                show: false
                            }
                        }
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'column'
                        
                        let plotValue = []
                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[i])
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                    x++;
                })
                
                Object.entries(graphData.request_rate).forEach(([key, item], j) => {
                    strokeWidth.push(x+2)
                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }
                    if(item.values && item.values.length) {
                        yRow = {
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            tickAmount: 2,
                            min: 0,
                            labels: {
                                offsetX: -10,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                            }
                        }
                        yDetails.push(yRow)
                        
                        let dataRow = {}
                        dataRow.name = key
                        dataRow.type = 'line'
                        let plotValue = []

                        graphLabels.forEach((lab, i) => {
                            let dataArray = []
                            dataArray.push(momentConvertionUtcToLocalTime(lab, 'YYYY-MM-DD HH:mm:ss'))
                            let val = item.values[i]
                            if(item.unit) {
                                val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            }
                            dataArray.push(val)
                            // dataArray.push(item.values[i])
                            plotValue.push(dataArray)
                        })
                        dataRow.data = plotValue
                        series.push(dataRow)
                    }
                    x++;
                })

                let xaxisAnnotation = []
                if(this.state.showAnnotation) {
                    // xaxisAnnotation = [{
                    //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                    //     strokeDashArray: 0,
                    //     borderColor: '#FF6F00',
                    //     label: {
                    //         borderColor: '#FF6F00',
                    //         style: {
                    //             // fontSize: '12px',
                    //             color: '#fff',
                    //             background: '#FF6F00'
                    //         },
                    //         orientation: 'horizontal',
                    //         text: '',
                    //     }
                    // }
                    // ]
                }
                
                let annotations = {
                    xaxis: xaxisAnnotation
                }

                let options = {
                    chart: {
                        // group: 'metrics_sre',
                        // id: 'chart_rate_'+index,
                        // redrawOnParentResize: true,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: false
                        },
                        type: "line",
                        animations: {
                            enabled: false
                        }
                    },
                    colors: colors,
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            barHeight: '100%',
                            // borderRadius: 8,
                            startingShape: 'flat',
                            endingShape: 'flat',
                            columnWidth: '60%',
                            distributed: false,
                            rangeBarOverlap: false,
                        }
                    },
                    stroke: {
                        width: strokeWidth,
                        curve: 'smooth',
                    },
                    xaxis: {
                        show: this.props.xaxis,
                        type: 'datetime',
                        // categories: labels,
                        labels: {
                            datetimeUTC: false,
                            show: this.props.xaxis,
                            style: {
                                fontSize: '9px',
                                colors: this.props.axisLabelColor
                            },
                        },
                    },
                    yaxis: yDetails,
                    grid: {
                        show: true,
                        borderColor: '#2D323E',
                        strokeDashArray: 0,
                        position: 'back',
                        xaxis: {
                            lines: {
                                show: true
                            },
                            labels: {
                                style: {
                                    colors: ['#FFF'],
                                }
                            },
                            axisTicks: {
                                show: false
                            }
                        },   
                        yaxis: {
                            lines: {
                                show: false
                            },
                            labels: {
                                style: {
                                    colors: ['#969AA7'],
                                }
                            }
                        },                
                        padding: {
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 0
                        },
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            show: false,
                        },                        
                        x: {
                            show: false
                        },
                        marker: {
                            show: false
                        },
                        custom: function({series, seriesIndex, dataPointIndex, w}) {
                                // let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]
                                // let labelName = w.globals.initialSeries[seriesIndex].name
                                // // return '<div className="arrow_box"> '+ capitalizeFirstLetter(labelName) +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + '  '+ val + '</span> </div>'         
                                let returnData = ''
                                
                                if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                                    Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                                        if(!index) {
                                            returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+momentDateGivenFormat(w.globals.initialSeries[index].data[dataPointIndex][0],'DD MMM YYYY HH:mm')+'</div>'
                                        }
                                        returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active d-flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+w.globals.initialSeries[index].name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+w.globals.initialSeries[index].data[dataPointIndex][1]+'</span></div></div></div>'

                                    })
                                }

                                returnData += '</div>'
                                return returnData
                        }
                    },
                    annotations: annotations
                }

                this.setState({ ['metrics_rate_series_'+index]: series, ['metrics_rate_options_'+index]: options })
            })

        }
    }  

    getMonitoringMetricsByTrend = () => {
        if(this.props.assetDetails.asset_id) {
            let params = {}
            params.provider = this.props.assetDetails && this.props.assetDetails.provider ? this.props.assetDetails.provider : 'aws'
            params.anomaly = true
            if(this.props.assetDetails.asset_id) {
                params.asset_id = this.props.assetDetails.asset_id
            } else {
                params.asset_name = [this.props.assetDetails.asset_name]
                params.service_name = this.props.assetDetails.service_name
            }

            let topMertrics = 3
            if(this.props.metricsType === 'SRE Observations' || (this.props.assetDetails && this.props.assetDetails.resource_type && this.props.assetDetails.resource_type === 'EC2')) {
                topMertrics = 6
            }

            if(this.props.assetDetails && this.props.assetDetails.primary_metric_details && this.props.assetDetails.primary_metric_details.length) {
                params.additional_metrics = this.props.assetDetails.primary_metric_details
            }
            params.top = topMertrics

            params.start_time = this.state.metricsStartTime
            params.end_time = this.state.metricsEndTime
            
            this.props.getMonitoringMetricsByTrendV2(params, (promise, result) => {
                if(promise) {
                    this.setState({ metricsByTrend : [] },
                        () => {
                            this.setState({ metricsByTrend: result, showLoading: false },
                                () => {
                                    this.metricsListGraph()
                                    this.primaryMetricsListGraph()
                                    // let obj = {}
                                    // obj = this.props.diagnosticRcaMonitMertrics
                                    // obj[this.props.assetDetails.asset_id] = result
                                    // this.props.setDiagnosticsPropsDetails('diagnosticRcaMonitMertrics', obj)
                                }
                            )
                        }
                    )
                } else {
                    this.setState({ showLoading: false })
                }
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    primaryMetricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {

            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates

                Object.entries(graphData).forEach(([key, item]) => {
                    

                    let array = []
                    let graphLabels = []

                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }
                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                        }
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]
                    let xaxisAnnotation = []
                    if(this.state.showAnnotation && this.props.changeDetails && this.props.changeDetails.event_time && this.props.changeDetails.event_time !== "") {
                        xaxisAnnotation = [{
                            x: new Date(momentConvertionUtcToLocalTime(this.props.changeDetails.event_time, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                            strokeDashArray: 0,
                            borderColor: '#F75E3F',
                            label: {
                                borderColor: '#F75E3F',
                                style: {
                                    color: '#fff',
                                    background: '#F75E3F',
                                },
                                orientation: 'horizontal',
                                text: "C"
                            }
                        }]
                    }

                    let alertLocation = 20
                    let anomalyLoaction = 1
                    let errorLocation = 40
                    if(maxValue <= 2) {
                        alertLocation = 1
                        anomalyLoaction = 1
                        errorLocation = 1
                    }

                    let points = []

                    // let gandalfData = this.props.assetDetails && this.props.assetDetails.primary_metric_events && this.props.assetDetails.primary_metric_events.filter(e => e.event_name === key).length ? this.props.assetDetails.primary_metric_events.filter(e => e.event_name === key) : []

                    let events = this.props.changeDetails && this.props.changeDetails.events && this.props.changeDetails.events.length ? this.props.changeDetails.events : []

                    let gandalfData = events && events.filter(e => e.event_name === key).length ? events.filter(e => e.event_name === key) : []
                     
                    if(this.state["selectedMetricsLegend_"+key] === key && this.state["selectedLegendType_"+key] && this.state["selectedLegendType_"+key] !== "") {
                        gandalfData = gandalfData.filter(e => e.event_type === this.state["selectedLegendType_"+key]).length ? gandalfData.filter(e => e.event_type === this.state["selectedLegendType_"+key]) : []
                    }
                    
                    gandalfData.forEach(evnt => {
                        points.push({
                            x: new Date(momentConvertionUtcToLocalTime(evnt.event_time, "YYYY-MM-DD HH:mm:ss")).getTime(),
                            y: Math.floor(Math.random() * (maxValue - 1) + 1),
                            // mouseEnter: function(e) {
                            //     // do something on marker click
                            //     alert(1)
                            // },
                            marker: {
                                size: 6,
                                fillColor: evnt.event_type === "alerts" ? "#3F73AD" : evnt.event_type === "anomalies" ? "#775BA2" : evnt.event_type === "errors" ? "#24A597" : evnt.event_type === "issues" ? "#FF4560" : "#858B99",
                                strokeColor: evnt.event_type === "alerts" ? "#3F73AD" : evnt.event_type === "anomalies" ? "#775BA2" : evnt.event_type === "errors" ? "#24A597" : evnt.event_type === "issues" ? "#FF4560" : "#858B99",
                                radius: 2,
                                OffsetX: 0,
                                OffsetY: 0,
                                cssClass: '',
                            },
                            label: {
                                // borderColor: "#FF4560",
                                // offsetY: 0,
                                // style: {
                                //     color: "#fff",
                                //     background: "#FF4560"
                                // },                
                                // text: ""
                            }
                        })
                    })
                    
                    let annotations = {
                        xaxis: xaxisAnnotation,
                        // position: 'back',
                        points: points
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'area',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                // shadeIntensity: 1,
                                // opacityFrom: 0.7,
                                // opacityTo: 0.9,
                                // stops: [0, 90, 100]
                                gradientToColors: ['#775BA2'],
                                shadeIntensity: 0,
                                opacityFrom: .3,
                                opacityTo: 1,
                                stops: [30, 90]
                            }
                        },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        annotations: annotations,
                    }
                    this.setState({ [key+'_primary_'+graph.asset_id]: series, [key+'_primary_options_'+graph.asset_id]: options })
                })
            })
        }
    }

    metricsListGraph = () => {
        let graphDetails = this.state.metricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates
                            
                
                Object.entries(graphData).forEach(([key, item]) => {
                    let array = []
                    let graphLabels = []

                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }

                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                        }
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]
                    let xaxisAnnotation = []
                    if(this.state.showAnnotation) {
                        // xaxisAnnotation = [{
                        //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        //     strokeDashArray: 0,
                        //     borderColor: '#FF6F00',
                        //     label: {
                        //         borderColor: '#FF6F00',
                        //         style: {
                        //             // fontSize: '12px',
                        //             color: '#fff',
                        //             background: '#FF6F00'
                        //         },
                        //         orientation: 'horizontal',
                        //         text: '',
                        //     }
                        // }
                        // ]
                    }
                    
                    let annotations = {
                        xaxis: xaxisAnnotation
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'area',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                // shadeIntensity: 1,
                                // opacityFrom: 0.7,
                                // opacityTo: 0.9,
                                // stops: [0, 90, 100]
                                gradientToColors: ['#775BA2'],
                                shadeIntensity: 0,
                                opacityFrom: .3,
                                opacityTo: 1,
                                stops: [30, 90]
                            }
                        },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        annotations: annotations,
                    }
                    this.setState({ [key+'_'+graph.asset_id]: series, [key+'_options_'+graph.asset_id]: options })
                })
            })
        }
    }

    getSecondaryMonitoringMetricsByTrend = () => {
        if(this.props.selectedEvent && this.props.selectedEvent.asset_id && this.props.selectedEvent.request_metric_details && Object.entries(this.props.selectedEvent.request_metric_details).length) {
            let params = this.props.selectedEvent.request_metric_details
            if(this.props.selectedEvent.request_metric_details && !this.props.selectedEvent.request_metric_details.asset_id) {
                params.asset_id = this.props.selectedEvent.asset_id
            }
            if(this.props.selectedEvent.request_metric_details && !this.props.selectedEvent.request_metric_details.service_name && this.props.selectedEvent.service_name) {
                params.service_name = this.props.selectedEvent.service_name
            }

            if(this.props.selectedEvent.request_metric_details && this.props.selectedEvent.request_metric_details.resource_type && this.props.selectedEvent.resource_type) {
                params.resource_type = this.props.selectedEvent.resource_type
            }
            params.start_time = this.props.startTime
            params.end_time = this.props.endTime
            
            this.props.getMonitoringMetricsByTrendV2(params, (promise, result) => {
                if(promise) {
                    this.setState({ secondaryMetricsByTrend : [] },
                        () => {
                            this.setState({ secondaryMetricsByTrend: result, showLoading: false },
                                () => {
                                    this.secondaryMetricsListGraph()
                                    // let obj = {}
                                    // obj = this.props.diagnosticRcaSecondaryMonitMertrics
                                    // obj[this.props.assetDetails.asset_id] = result
                                    // this.props.setDiagnosticsPropsDetails('diagnosticRcaSecondaryMonitMertrics', obj)
                                }
                            )
                        }
                    )
                } else {
                    this.setState({ showLoading: false })
                }
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    secondaryMetricsListGraph = () => {
        let graphDetails = this.state.secondaryMetricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates
                            
                
                Object.entries(graphData).forEach(([key, item]) => {
                    let array = []
                    let graphLabels = []

                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }

                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                        }
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]
                    let xaxisAnnotation = []
                    if(this.state.showAnnotation) {
                        // xaxisAnnotation = [{
                        //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        //     strokeDashArray: 0,
                        //     borderColor: '#FF6F00',
                        //     label: {
                        //         borderColor: '#FF6F00',
                        //         style: {
                        //             // fontSize: '12px',
                        //             color: '#fff',
                        //             background: '#FF6F00'
                        //         },
                        //         orientation: 'horizontal',
                        //         text: '',
                        //     }
                        // }
                        // ]
                    }
                    
                    let annotations = {
                        xaxis: xaxisAnnotation
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'area',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                // shadeIntensity: 1,
                                // opacityFrom: 0.7,
                                // opacityTo: 0.9,
                                // stops: [0, 90, 100]
                                gradientToColors: ['#775BA2'],
                                shadeIntensity: 0,
                                opacityFrom: .3,
                                opacityTo: 1,
                                stops: [30, 90]
                            }
                        },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        annotations: annotations,
                    }
                    this.setState({ [key+'_secondary_'+graph.asset_id]: series, [key+'_secondary_options_'+graph.asset_id]: options })
                })
            })
        }
    }

    getProcessMonitoringMetrics = () => {
        if(this.props.selectedEvent.process_metric_details && Object.entries(this.props.selectedEvent.process_metric_details).length) {
            let params = this.props.selectedEvent.process_metric_details
            if(this.props.selectedEvent.process_metric_details && !this.props.selectedEvent.process_metric_details.asset_id) {
                params.asset_id = this.props.selectedEvent.asset_id
            }
            if(this.props.selectedEvent.process_metric_details && !this.props.selectedEvent.process_metric_details.service_name && this.props.selectedEvent.service_name) {
                params.service_name = this.props.selectedEvent.service_name
            }

            if(this.props.selectedEvent.process_metric_details && this.props.selectedEvent.process_metric_details.resource_type && this.props.selectedEvent.resource_type) {
                params.resource_type = this.props.selectedEvent.resource_type
            }
            params.start_time = this.props.startTime
            params.end_time = this.props.endTime
            
            this.props.getMonitoringMetricsByTrendV2(params, (promise, result) => {
                if(promise) {
                    this.setState({ processMetricsByTrend : [] },
                        () => {
                            this.setState({ processMetricsByTrend: result, showLoading: false },
                                () => {
                                    this.processMetricsListGraph()
                                    // let obj = {}
                                    // obj = this.props.diagnosticRcaSecondaryMonitMertrics
                                    // obj[this.props.assetDetails.asset_id] = result
                                    // this.props.setDiagnosticsPropsDetails('diagnosticRcaSecondaryMonitMertrics', obj)
                                }
                            )
                        }
                    )
                } else {
                    this.setState({ showLoading: false })
                }
            })
        } else {
            this.setState({ showLoading: false })
        }
    }

    processMetricsListGraph = () => {
        let graphDetails = this.state.processMetricsByTrend
        if(graphDetails) {
            graphDetails.forEach((graph, index) => {
                let graphData = graph.data
                let graphDates = graph.dates
                            
                
                Object.entries(graphData).forEach(([key, item]) => {
                    let array = []
                    let graphLabels = []

                    let maxValue = item.statistics && item.statistics.max ? item.statistics.max : Math.max(...item.values)
                    let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                    let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                    
                    
                    let graphConvertTo = ""

                    let maxRandomNumber = maxValue
                    let minRandomNumber = minValue
                    if(item.unit) {
                        graphConvertTo = this.getConvertedUnit(maxValue, item.unit)
                        maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                        minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                    }

                    item.values.forEach((val, j) => {
                        graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        let arrayValues = []
                        arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                        if(item.unit) {
                            val = this.getGrapUnitValue(val, item.unit, "", graphConvertTo)
                        }
                        arrayValues.push(val)
                        array.push(arrayValues)
                    })

                    if(array.length === 1) {
                        let arrayValues = []
                        arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                        arrayValues.push(0)
                        array.push(arrayValues)
                    }

                    let series = [{
                        data: array,
                        name: key,
                    }]
                    let xaxisAnnotation = []
                    if(this.state.showAnnotation) {
                        // xaxisAnnotation = [{
                        //     x: new Date(momentConvertionUtcToLocalTime(this.props.caseDetails.created_at, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                        //     strokeDashArray: 0,
                        //     borderColor: '#FF6F00',
                        //     label: {
                        //         borderColor: '#FF6F00',
                        //         style: {
                        //             // fontSize: '12px',
                        //             color: '#fff',
                        //             background: '#FF6F00'
                        //         },
                        //         orientation: 'horizontal',
                        //         text: '',
                        //     }
                        // }
                        // ]
                    }
                    
                    let annotations = {
                        xaxis: xaxisAnnotation
                    }

                    let options = {
                        chart: {
                            // group: 'metrics_and_sre',
                            // id:'metrics_'+i,
                            type: 'area',
                            width: '100%',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false
                            },
                            sparkline: {
                                enabled: false
                            }
                        },
                        stroke: {
                            show: true,
                            curve: 'smooth',
                            lineCap: 'butt',
                            width: 2,
                            dashArray: 0,      
                        },
                        colors: ['#775BA2'],
                        fill: {
                            type: "gradient",
                            gradient: {
                                // shadeIntensity: 1,
                                // opacityFrom: 0.7,
                                // opacityTo: 0.9,
                                // stops: [0, 90, 100]
                                gradientToColors: ['#775BA2'],
                                shadeIntensity: 0,
                                opacityFrom: .3,
                                opacityTo: 1,
                                stops: [30, 90]
                            }
                        },
                        tooltip: {
                            custom: function({series, seriesIndex, dataPointIndex, w}) {
                                let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                dateLable = dateLable.toString()
                                dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                            },
                            fixed: {
                                enabled: false
                            },
                            x: {
                                show: false,
                            },
                            y: {
                                show: false
                            },        
                            marker: {
                                show: false
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                            labels: {
                                datetimeUTC: false,
                                style: {
                                    colors: '#60687C'
                                },
                                // formatter: function (value) {
                                //     return momentDateGivenFormat(value, 'HH:mm')
                                // },
                            },                            
                            axisTicks: {
                                show: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            show: true,
                            axisTicks: {
                                show: false,
                            },
                            axisBorder: {
                                show: false,
                                color: '#60687C'
                            },
                            tooltip: {
                                enabled: false
                            },
                            // tickAmount: 2,
                            min: 0,
                            // max: yaxisMaxValue, 
                            tickAmount: 1,
                            labels: {
                                offsetX: -10,
                                // minWidth: 50,
                                show: true,
                                style: {
                                    fontSize: '9px',
                                    colors: '#60687C'
                                },
                                // formatter: (value) => { 
                                //     if(Number(value) === value && value % 1 === 0) {
                                //         return Math.ceil(value)
                                //     } else {
                                //         return Math.ceil(value)
                                //     }
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                            }
                        },
                        grid: {
                            show: true,
                            borderColor: '#2D323E',
                            strokeDashArray: 0,
                            position: 'back',
                            xaxis: {
                                lines: {
                                    show: true
                                },
                                labels: {
                                    style: {
                                        colors: ['#FFF'],
                                    }
                                },
                                axisTicks: {
                                    show: false
                                }
                            },   
                            yaxis: {
                                lines: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        colors: ['#969AA7'],
                                    }
                                }
                            },                
                            padding: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                        },
                        annotations: annotations,
                    }
                    this.setState({ [key+'_process_'+graph.asset_id]: series, [key+'_process_options_'+graph.asset_id]: options, [key+'_process_graph_value_'+graph.asset_id]: graphConvertTo })
                })
            })
        }
    }
    
    getMultiSelectedCount = (type, array) => {
        if(array) {
            return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
        } else {
            return 'All'
        }
    }
	
	handleClickOutside(event) {
        // if (this.annotationTypeRef && !this.annotationTypeRef.current.contains(event.target)) {
        //     this.setState({ isannotationTypeOpen: false })
        // } else {
        //     this.setState({ isannotationTypeOpen: true })
        // }
    }

    getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytesWithoutUnit(val, unit, valueType)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSecondsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBitsWithoutUnit(val, unit, valueType)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormaterWithoutUnit(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
        }
        return val
    }

    onClickPrimaryElipMenus = (data, key, i) => {
        Object.keys(data).forEach((item, index) => {
            if(key !== item) {
                this.setState({ ["showElipMenu_"+item]: false })
            } else {
                this.setState({ ["showElipMenu_"+item]: !this.state["showElipMenu_"+item] })
            }
        })
    }

    onClickLegend = (type, key, i) => {
        this.setState({ ["selectedLegendType_"+key]: type, ["selectedMetricsLegend_"+key]: key, ["showElipMenu_"+key]: false },
            () => this.primaryMetricsListGraph()    
        )
    }

    render() {
        return (
            !this.state.showLoading ?
                <div className="" onClick={ (event) => { this.handleClickOutside(event) } }>
                    {this.props.assetDetails && this.props.assetDetails.primary_metric_details && this.props.assetDetails.primary_metric_details.length && this.state.metricsByTrend && this.state.metricsByTrend.length ?
                        <div className={`card bg-dark border-0 p-3`}>
                            <div className={`${this.state.showPrimaryMertics ? "mb-1" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showPrimaryMertics: !this.state.showPrimaryMertics })}>
                                <div className="text-white"><span className={`far ${this.state.showPrimaryMertics ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Primary Metrics</div>
                                {/* <div className="text-info">Showing event details</div> */}
                                <div className="d-flex">
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                                </div>
                            </div>
                            {/* <p className={`${this.state.showPrimaryMertics ? "mb-2" : "mb-1"}`}>Probable top-level cause for the asset {this.props.assetDetails.asset_name} (service: {this.props.assetDetails.service_name}) is {this.props.assetDetails.rca_type} with a probability of {this.props.assetDetails.probability} having - anomalies, - alerts, and - errors in the last {twoDateDiffrenceDayHoursMinutes(this.props.endTime, this.props.startTime)}.</p> */}
                            <p className={`${this.state.showPrimaryMertics ? "mb-2" : "mb-1"}`}>{this.props.assetDetails.summary ? this.props.assetDetails.summary : ""}</p>
                            <div className={`collapse ${this.state.showPrimaryMertics ? 'show' : ''}`}>
                                {this.state.metricsByTrend.map((item, index) => {
                                    return (
                                        Object.keys(item.data).map((key, i) => {
                                            return (
                                                this.props.assetDetails.primary_metric_details.includes(key) ?
                                                    <div className="mb-3">
                                                        <div class="p-3 rounded bg-dark3">
                                                            <div className="d-flex justify-content-between">
                                                                <p class="text-white f16 p-1 mb-0">{key}</p>
                                                                {this.props.changeDetails && Object.entries(this.props.changeDetails).length ?
                                                                    <div className="btn-group ellipsisDropDownBlack dropleft">
                                                                        <div className={`cursorPointer`} onClick={() => this.onClickPrimaryElipMenus(item.data, key, i)}>
                                                                            <i className="fa fa-ellipsis-v text-secondary"></i>
                                                                        </div>
                                                                        <div className={`dropdown-menu ${this.state["showElipMenu_"+key] ? "show active" : ""}`}>
                                                                            <div>
                                                                                <i className="fa fa-caret-up"></i>
                                                                                <button className="dropdown-item" type="button"
                                                                                onClick={() => this.onClickLegend("", key, i)}>All</button>
                                                                            </div>
                                                                            <div>
                                                                                <i className="fa fa-caret-up"></i>
                                                                                <button className="dropdown-item" type="button"
                                                                                onClick={() => this.onClickLegend("alerts", key, i)}>Alerts</button>
                                                                            </div>
                                                                            <div>
                                                                                <i className="fa fa-caret-up"></i>
                                                                                <button className="dropdown-item" type="button"
                                                                                onClick={() => this.onClickLegend("anomalies", key, i)}>Anomalies</button>
                                                                            </div>
                                                                            <div>
                                                                                <i className="fa fa-caret-up"></i>
                                                                                <button className="dropdown-item" type="button"
                                                                                onClick={() => this.onClickLegend("errors", key, i)}>Errors</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            {this.state[key+'_primary_options_'+item.asset_id] && Object.keys(this.state[key+'_primary_options_'+item.asset_id]).length && this.state[key+'_primary_'+item.asset_id] && this.state[key+'_primary_'+item.asset_id].length ?
                                                                <div className="cursorPointer transparentTooltip mb-n4 mt-n3">
                                                                    <Chart options={this.state[key+'_primary_options_'+item.asset_id]} series={this.state[key+'_primary_'+item.asset_id]} type="area" height={120} width={'100%'}/>
                                                                    {this.props.changeDetails && Object.entries(this.props.changeDetails).length ?
                                                                        <ul className="stock-legend mt-n2 d-flex justify-content-center">
                                                                            <li><span className="legend-circle bg-alerts"></span> <span className="text-muted d-inline-block"> &nbsp;Alerts {this.props.changeDetails && this.props.changeDetails.events && this.props.changeDetails.events.length && this.props.changeDetails.events.filter(e => e.event_name === key && e.event_type === "alerts").length ? this.props.changeDetails.events.filter(e => e.event_name === key && e.event_type === "alerts").length : ""}</span></li> 
                                                                            <li><span className="legend-circle bg-anomalies"></span> <span className="text-muted d-inline-block"> &nbsp; Anomalies {this.props.changeDetails && this.props.changeDetails.events && this.props.changeDetails.events.length && this.props.changeDetails.events.filter(e => e.event_name === key && e.event_type === "anomalies").length ? this.props.changeDetails.events.filter(e => e.event_name === key && e.event_type === "anomalies").length : ""}</span></li> 
                                                                            <li><span className="legend-circle bg-errors"></span> <span className="text-muted d-inline-block"> &nbsp; Errors {this.props.changeDetails && this.props.changeDetails.events && this.props.changeDetails.events.length && this.props.changeDetails.events.filter(e => e.event_name === key && e.event_type === "errors").length ? this.props.changeDetails.events.filter(e => e.event_name === key && e.event_type === "errors").length : ""}</span></li>
                                                                            {/* <li><span className="legend-circle bg-issues"></span> <span className="text-muted d-inline-block"> &nbsp; Issues</span></li> */}
                                                                        </ul>
                                                                    : null}
                                                                </div>
                                                            :
                                                                <div style={{minHeight:"90px"}} className="d-flex align-self-center justify-content-center">
                                                                    <div className='align-self-center'>
                                                                        No Data
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                : null
                                            )
                                        })
                                    )
                                })}
                            </div>
                        </div>
                    : null}
                    {this.props.selectedEvent.request_metric_details && Object.entries(this.props.selectedEvent.request_metric_details).length && this.state.secondaryMetricsByTrend && this.state.secondaryMetricsByTrend.length ?
                        <div className={`card bg-dark border-0 p-3 mt-3`}>
                            <div className={`${this.state.showSecondaryMertics ? "mb-2" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showSecondaryMertics: !this.state.showSecondaryMertics })}>
                                <div className="text-white"><span className={`far ${this.state.showSecondaryMertics ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Secondary Metrics</div>
                                {/* <div className="text-info">Showing event details</div> */}
                                <div className="d-flex">
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                                </div>
                            </div>
                            <div className={`collapse ${this.state.showSecondaryMertics ? 'show' : ''}`}>
                                {this.state.secondaryMetricsByTrend.map((item, index) => {
                                    return (
                                        Object.keys(item.data).map((key, i) => {
                                            return (
                                                <div className="mb-3">
                                                    <div class="p-3 rounded bg-dark3">
                                                        <p class="text-white f16 py-1 mb-0">{key}</p>     
                                                        {this.props.selectedEvent.secondary_summary && Object.entries(this.props.selectedEvent.secondary_summary).length && this.props.selectedEvent.secondary_summary.hasOwnProperty(key) ?
                                                            <p className={`mb-2`}>{this.props.selectedEvent.secondary_summary[key]}</p>   
                                                        : null}                                          
                                                        {this.state[key+'_secondary_options_'+item.asset_id] && Object.keys(this.state[key+'_secondary_options_'+item.asset_id]).length && this.state[key+'_secondary_'+item.asset_id] && this.state[key+'_secondary_'+item.asset_id].length ?
                                                            <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                <Chart options={this.state[key+'_secondary_options_'+item.asset_id]} series={this.state[key+'_secondary_'+item.asset_id]} type="area" height={120} width={'100%'}/>
                                                            </div>
                                                        :
                                                            <div style={{minHeight:"90px"}} className="d-flex align-self-center justify-content-center">
                                                                <div className='align-self-center'>
                                                                    No Data
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                })}

                                {this.state.processMetricsByTrend ? 
                                    this.state.processMetricsByTrend.map((item, index) => {
                                        return (
                                            Object.keys(item.data).map((key, i) => {
                                                return (
                                                    <div className="mb-3">
                                                        <div class="p-3 rounded bg-dark3">
                                                            <p class="text-white f16 py-1 mb-0">{key} <span className="text-primary-color f12 displayNone">{this.state[key+'_process_graph_value_'+item.asset_id] ? this.state[key+'_process_graph_value_'+item.asset_id] : ""}</span></p>     
                                                            {/* {this.props.selectedEvent.secondary_summary && Object.entries(this.props.selectedEvent.secondary_summary).length && this.props.selectedEvent.secondary_summary.hasOwnProperty(key) ?
                                                                <p className={`mb-2`}>{this.props.selectedEvent.secondary_summary[key]}</p>   
                                                            : null}                                           */}
                                                            {this.state[key+'_process_graph_value_'+item.asset_id] && Object.keys(this.state[key+'_process_options_'+item.asset_id]).length && this.state[key+'_process_'+item.asset_id] && this.state[key+'_process_'+item.asset_id].length ?
                                                                <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                    <Chart options={this.state[key+'_process_options_'+item.asset_id]} series={this.state[key+'_process_'+item.asset_id]} type="area" height={120} width={'100%'}/>
                                                                </div>
                                                            :
                                                                <div style={{minHeight:"90px"}} className="d-flex align-self-center justify-content-center">
                                                                    <div className='align-self-center'>
                                                                        No Data
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    })
                                : null}
                            </div>
                        </div>
                    : null}
                    <div className={`card bg-dark border-0 p-3 mt-3`}>
                        <div className={`${this.state.showCoreMertics ? "mb-1" : ""} d-flex justify-content-between`} onClick={() => this.setState({ showCoreMertics: !this.state.showCoreMertics })}>
                            <div className="">
                                <div className="text-white"><span className={`far ${this.state.showCoreMertics ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Metrics</div>
                                {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                    <div className="d-flex">
                                        <p class="f12 text-white mb-0">Key Metrics for {this.props.assetDetails.resource_type ? this.props.assetDetails.resource_type : ''}</p>
                                        <p class="f12 text-white mb-0 ml-2">{this.props.assetDetails.asset_name ? this.props.assetDetails.asset_name : ''}</p>
                                    </div>
                                : null}
                            </div>
                            <div className="d-flex">
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{"("+momentConvertionUtcToLocalTime(this.state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(this.state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                            </div>
                        </div>
                        <div className={`collapse ${this.state.showCoreMertics ? 'show' : ''}`}>
                            <div class="container-fluid mt-2">
                                {this.props.metricsType === 'SRE Observations' || (this.props.assetDetails && this.props.assetDetails.resource_type && this.props.assetDetails.resource_type === 'EC2') ?
                                    <div className="">
                                        <div class="row">
                                            {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                                this.state.metricsByTrend.map((item, index) => {
                                                    return(
                                                    Object.keys(item.data).map((key, j) => {
                                                        return (
                                                            <div class={`mb-2 col-sm-6 ${j % 2 === 0 ? 'px-0' : ''}`}>
                                                                <div class="p-3 border rounded">
                                                                    <p class="text-white f16 p-1 mb-0">{key}</p>
                                                                    {/* <div class="rounded text-info bg-muted px-2 py-1 f16 d-table">{key}</div> */}
                                                                    {/* <p class="f12 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                                                    
                                                                    {this.state[key+'_options_'+item.asset_id] && Object.keys(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                                                        <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                            <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="area" height={115} width={'100%'}/>
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    )
                                                })
                                            : 
                                                <div className='d-flex justify-content-center m-4'>
                                                    No Metrics for the this criteria, Please adjust your filter to get the metric details.
                                                </div>
                                            }
                                        </div>
                                    </div>
                                : this.props.metricsType !== 'SRE Observations' ?
                                    (this.state.metricsByTrend && this.state.metricsByTrend.length) || (this.state.sreMetricsDetails && this.state.sreMetricsDetails.length) ?
                                        <div class="row">
                                            <div class="mb-2 col-sm-6 px-0">
                                                {this.state.metricsByTrend && this.state.metricsByTrend.length ?
                                                    this.state.metricsByTrend.map((item, index) => {
                                                        return(
                                                        Object.keys(item.data).map(key => {
                                                            return (
                                                            <div class="p-3 border bg-dark3 rounded mb-3">
                                                                <p class="text-white f16 p-1 mb-0">{key}</p>
                                                                {/* <div class="rounded text-info bg-muted px-2 py-1 f16 d-table">{key}</div> */}
                                                                {/* <p class="f12 mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing.</p> */}
                                                                
                                                                {this.state[key+'_options_'+item.asset_id] && Object.keys(this.state[key+'_options_'+item.asset_id]).length && this.state[key+'_'+item.asset_id] && this.state[key+'_'+item.asset_id].length ?
                                                                    <div className="cursorPointer transparentTooltip mb-n4 mt-n4">
                                                                        <Chart options={this.state[key+'_options_'+item.asset_id]} series={this.state[key+'_'+item.asset_id]} type="area" height={115} width={'100%'}/>
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            )
                                                        })
                                                        )
                                                    })
                                                : null}
                                            </div>
                                            <div class="mb-2 col-sm-6">
                                                {this.state.sreMetricsDetails && this.state.sreMetricsDetails.length ? 
                                                    this.state.sreMetricsDetails.map((res, index) => {
                                                        return(
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <div class="mb-3 col-12 px-0">
                                                                    <div class="p-3 border bg-dark3 rounded">
                                                                        <p class="f16 text-white m-0 p-1">Latency</p>
                                                                        {this.state['metrics_series_'+index] ?
                                                                            <div className={`${this.props.className}`}>
                                                                                <Chart options={this.state['metrics_options_'+index]} series={this.state['metrics_series_'+index]} type="line" height={this.props.height} />
                                                                            </div>
                                                                        : null}    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="mb-3 col-12 px-0">
                                                                    <div class="p-3 border rounded">
                                                                        <p class="f16 text-white m-0 p-1">API Error/Request Rate</p>
                                                                        {this.state['metrics_rate_series_'+index] ?
                                                                            <div className={`${this.props.className}`}>
                                                                                <Chart options={this.state['metrics_rate_options_'+index]} series={this.state['metrics_rate_series_'+index]} type="line" height={this.props.height} />
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                    : 
                                        <div className='d-flex justify-content-center m-4'>
                                            No Metrics for the this criteria, Please adjust your filter to get the metric details.
                                        </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            : 
                <div className={`bg-dark rounded p-3`}>
                    <p className={`mb-2 text-white`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        <Spinner className='text-center' color='white' size='lg' />
                    </div>
                </div>
        )
    }
}

/**
* Type of the props used in the component
*/
PrimaryMetricsSection.propTypes = {
    getSreDetails: PropTypes.func,
    getMonitoringMetricsByTrend: PropTypes.func,
    setDiagnosticsPropsDetails: PropTypes.func
}   

const mapStateToProps = state => {
    return {
        diagnosticMonitMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticMonitMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticMonitMertrics : {},

        diagnosticSreMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticSreMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticSreMertrics : {},

        diagnosticRcaMonitMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticRcaMonitMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticRcaMonitMertrics : {},

        diagnosticRcaSreMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticRcaSreMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticRcaSreMertrics : {},

        diagnosticRcaSecondaryMonitMertrics: state.aiops.diagnosticsPropsDetails && state.aiops.diagnosticsPropsDetails.diagnosticRcaSecondaryMonitMertrics ? state.aiops.diagnosticsPropsDetails.diagnosticRcaSecondaryMonitMertrics : {},

        diagnosticsDetails: state.aiops.aiopsDiagnosticsDetails && state.aiops.aiopsDiagnosticsDetails.result ? state.aiops.aiopsDiagnosticsDetails.result : [],
    }
}

export default connect(mapStateToProps, {
    getSreDetails,
    getMonitoringMetricsByTrend,
    getMonitoringMetricsByTrendV2,
    setDiagnosticsPropsDetails
})(withRouter(PrimaryMetricsSection))